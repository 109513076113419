import $dataStr from 'fxdom/es/dataStr.js';
import { html, isNil, strMap } from 'fxjs/es';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { MShopStudioPbProductTmplS } from '../../../PbProduct/S/Tmpl/module/MShopStudioPbProductTmplS.js';
import { MShopStudioTokenGateStoresProductTmplS } from '../../../TokenGate/StoresProduct/S/Tmpl/module/MShopStudioTokenGateStoresProductTmplS.js';
import { LICENSE_MAP, LICENSE_MESSAGE } from '../Constant/digitalproductcreator.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../Constant/module/MShopStudioDigitalProductCreatorConstantS.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;

export const makeCateMenuHtml = (cate_item) => {
  return html`
    <div class="category" data-fx-json="${$dataStr(cate_item.id)}">
      <img
        src="${MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[cate_item.id].category_image}"
        alt="${MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[cate_item.id].cate_view_name}"
      />
      <div class="category-name">
        ${MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[cate_item.id].cate_view_name}
      </div>
    </div>
  `;
};

export const creatorBottomButtons = (product_id) => {
  if (!product_id) {
    return html`
      <div class="digital-product-creator__form-button-container" is_open="false">
        <button
          type="button"
          class="digital-product-creator__btn-submit digital-product-creator__btn-submit--disabled"
        >
          ${T(messages.digital_products.btn_register)}
        </button>
      </div>
    `;
  }
  return html`
    <div class="digital-product-creator__form-buttons-container">
      <button type="button" class="digital-product-creator__btn-cancel">${T('취소')}</button>
      <button type="button" class="digital-product-creator__btn-submit">${T('완료')}</button>
    </div>
  `;
};

export const makeIsPublicInputHtml = (is_public) => {
  return html`
    <div class="digital-product-creator-form__input-title">
      상품 공개 여부&nbsp;<span class="digital-product-creator-form__required">*</span>
    </div>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.is_public}"
        value="true"
        ${is_public ? 'checked' : ''}
      />
      공개
    </label>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.is_public}"
        value="false"
        ${is_public ? '' : 'checked'}
      />
      비공개
    </label>
  `;
};

export const makeProductNameInputHtml = ({ product_name_kr, cate_name, name_placeholder } = '') => {
  return html`
    <div class="digital-product-creator-form__input-title">
      ${cate_name} 상품명&nbsp;<span class="digital-product-creator-form__required">*</span>
      <span style="display: none" class="digital-product-creator-form__input-title__sub"
        >다국어 지원 (선택)</span
      >
    </div>
    <div class="digital-product-creator-form__product_names digital-product-creator-form__input-wrapper">
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/00f27a52219caedbdf9fa580f9bf49cd140b0f001.png"
        alt="korea flag icon"
        class="digital-product-creator-form__flag-icon"
        style="display: none"
      />
      <input
        type="text"
        name="${digital_product_prop.product_name_kr}"
        class="digital-product-creator-form__text-input"
        value="${UtilS.escape(product_name_kr) || ''}"
        placeholder="${name_placeholder}"
      />
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/ad591822bed406ceef5294ebba575da4d293ac7e1.png"
        alt="america flag icon"
        class="digital-product-creator-form__flag-icon dpc-styl__ml-20px"
        style="display: none"
      />
      <input
        type="text"
        name="${digital_product_prop.product_name_en}"
        class="digital-product-creator-form__text-input dpc-styl__ml-10px"
        value=""
        placeholder="English"
        style="display: none"
      />
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/e499abd9e12547aa0c2a5c53b1cfd71d362a1ae11.png"
        alt="japan flag icon"
        class="digital-product-creator-form__flag-icon dpc-styl__ml-20px"
        style="display: none"
      />
      <input
        type="text"
        name="${digital_product_prop.product_name_jp}"
        class="digital-product-creator-form__text-input dpc-styl__ml-10px"
        value=""
        placeholder="日本語"
        style="display: none"
      />
      <div class="digital-product-creator-form__letter-number">${product_name_kr?.length || 0}/100</div>
    </div>
  `;
};

export const makeProductPriceInputHtml = ({ price, cate_name, price_placeholder }) => {
  return html`
    <div class="digital-product-creator-form__input-title">
      ${cate_name} 상품 가격
      <span class="digital-product-creator-form__required">*</span>
    </div>
    <div class="digital-product-creator-form__number-input-wrap digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.price}"
        class="digital-product-creator-form__text-input"
        placeholder="${price_placeholder}"
        value="${isNil(price) ? '' : UtilS.commify(price)}"
      />
      <div class="digital-product-creator-form__won">₩</div>
    </div>
  `;
};

export const makeSetLicenseRangeInputHtml = (license_type = LICENSE_MAP.PRIVATE) => {
  return html`
    <div class="digital-product-creator-form__input-title">
      라이센스 범위 설정
      <span class="digital-product-creator-form__required">*</span>
    </div>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.license_type}"
        value="${LICENSE_MAP.PRIVATE}"
        ${license_type === LICENSE_MAP.PRIVATE ? 'checked' : ''}
      />
      개인 소장
    </label>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.license_type}"
        value="${LICENSE_MAP.COMMERCIAL}"
        ${license_type === LICENSE_MAP.COMMERCIAL ? 'checked' : ''}
      />
      상업적 활용 가능
    </label>
    <div class="digital-product-creator-form__desc dpc-styl__mt-12px">${LICENSE_MESSAGE[license_type]}</div>
  `;
};

export const makeTokenGateInputHtml = (stores_products_token_gates) => {
  return html`
    <div class="digital-product-creator-form__input-title dpc-styl__flex dpc-styl__items-center">
      ${T('tokenGate::tg017')}
      <span class="tooltip-icon2 tooltip-icon2--question dpc-styl__ml-4px"
        >${UtilTmplS.tooltipIcon2El()}</span
      >
      <div class="tooltip-container">
        <div class="editor-tooltip-box">${MShopStudioPbProductTmplS.pdTypeTooltipHTML('token_gating')}</div>
      </div>
    </div>
    <div class="digital-product-creator-form__setting-token-gate setting-token-gate">
      ${MShopStudioTokenGateStoresProductTmplS.edit(stores_products_token_gates || [])}
    </div>
  `;
};

export const makeInformationHtml = ({ title, contents, required = false, custom_html = '', is_hide }) => {
  if (is_hide) return '';

  return html`
    <div class="digital-product-creator-form__information">
      <div class="digital-product-creator-form__input-title">
        ${title} ${required ? html`<span class="digital-product-creator-form__required">*</span>` : ''}
      </div>
      <div class="digital-product-creator-form__information-contents">
        ${strMap((content) => {
          return html` <div class="digital-product-creator-form__information-content">${content}</div> `;
        }, contents)}
        ${custom_html}
      </div>
    </div>
  `;
};

export const makeUploadResultsDashboardHtml = ({ files_total_count, init_count = 0 }) => {
  return html`
    <div class="digital-product-creator-form__upload-results-dashboard dpc-styl__mt-10px">
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/c7aa50ed22f6baee7128faff5984e03d18d5c2271.png"
        alt=""
        class="digital-product-creator-form__spinner-icon dpc-styl__ml-20px"
      />
      <div class="digital-product-creator-form__complete-circle none dpc-styl__ml-20px">
        <div class="digital-product-creator-form__complete-checkmark"></div>
        <div class="digital-product-creator-form__complete-checkmark-screen"></div>
      </div>
      <div class="digital-product-creator-form__upload-info dpc-styl__mr-auto">
        <span
          class="digital-product-creator-form__required-message digital-product-creator-form__upload-count"
          >${init_count}</span
        >
        /
        <span class="digital-product-creator-form__upload-results-dashboard__files-total-count text-fade"
          >${files_total_count}개</span
        >
        <span class="digital-product-creator-form__upload-results-dashboard__upload-message text-fade"
          >업로드 진행 중</span
        >
        <span class="digital-product-creator-form__upload-results-dashboard__fail none">
          (실패 <span class="digital-product-creator-form__upload-results-dashboard__fail-count">0</span>개)
        </span>
      </div>
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/fc0453ef26298f592a32ab2d2f3ea0dd73e78a5a1.png"
        alt=""
        class="digital-product-creator-form__upload-results-dashboard__toggle-button digital-product-creator-form__toggle dpc-styl__mr-28px"
      />
    </div>
  `;
};

export const makeSetQnaTypeInputHtml = ({
  qna_type = MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.BOARD,
  qna_link = '',
}) => {
  return html`
    <div class="digital-product-creator-form__input-title">
      일대일 문의 방법 설정 <span class="digital-product-creator-form__required">*</span>
      <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
      <div class="tooltip-container">
        <div class="editor-tooltip-box">${MShopStudioPbProductTmplS.pdTypeTooltipHTML('qna')}</div>
      </div>
    </div>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.qna_type}"
        value="${MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.BOARD}"
        ${qna_type === MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.BOARD ? 'checked' : ''}
      />
      마플샵 1:1 문의
    </label>
    <label class="digital-product-creator-form__radio-label">
      <input
        type="radio"
        name="${digital_product_prop.qna_type}"
        value="${MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS}"
        ${qna_type === MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS ? 'checked' : ''}
      />
      개인 SNS를 통한 문의
    </label>
    <div
      class="digital-product-creator-form__qna-type-mp ${qna_type ===
      MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS
        ? 'digital-product-creator-form__qna-type-mp--hide'
        : ''}"
    >
      <div class="digital-product-creator-form__inquiry-precautions">
        <ul>
          <li>
            ·&nbsp;&nbsp;마플샵 스튜디오 > 고객 문의 관리를 통해 구매자와 메시지를 주고 받을 수 있습니다.
          </li>
          <li>·&nbsp;&nbsp;구매자의 문의를 7일 이상 미 답변시 7일 동안 판매중지 상태가 될 수 있습니다.</li>
        </ul>
      </div>
    </div>

    <div
      class="digital-product-creator-form__qna-type-sns ${qna_type ===
      MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS
        ? ''
        : 'digital-product-creator-form__qna-type-sns--hide'}"
    >
      <div class="digital-product-creator-form__control dpc-styl__mt-16px">
        <div class="digital-product-creator-form__input-wrapper">
          <input
            type="text"
            name="${digital_product_prop.qna_link}"
            class="digital-product-creator-form__text-input"
            value="${UtilS.escape(qna_link) || ''}"
            placeholder="예) https://www.instagram.com/marpple.shop/"
          />
        </div>
      </div>
      <p class="digital-product-creator-form__qna-info">
        크리에이터의 개인 SNS를 통해 메세지를 주고 받습니다.
      </p>
    </div>
  `;
};

export const makeEbookWriterInputHtml = (ebook_writer) => {
  const i18n = {
    ebook_writer_placeholder: T('studio::digital_products::ebook_writer_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">
      글&nbsp;
      <span class="digital-product-creator-form__required">*</span>
    </div>
    <div class="digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.ebook_writer}"
        class="digital-product-creator-form__text-input"
        placeholder="${i18n.ebook_writer_placeholder}"
        value="${UtilS.escape(ebook_writer) || ''}"
      />
    </div>
  `;
};

export const makeEbookIllustratorInputHtml = (ebook_illustrator) => {
  const i18n = {
    ebook_illustrator_placeholder: T('studio::digital_products::ebook_illustrator_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">그림</div>
    <div class="digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.ebook_illustrator}"
        class="digital-product-creator-form__text-input"
        placeholder="${i18n.ebook_illustrator_placeholder}"
        value="${UtilS.escape(ebook_illustrator) || ''}"
      />
    </div>
  `;
};

export const makeEbookTranslatorInputHtml = (ebook_translator) => {
  const i18n = {
    ebook_translator_placeholder: T('studio::digital_products::ebook_translator_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">번역</div>
    <div class="digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.ebook_translator}"
        class="digital-product-creator-form__text-input"
        placeholder="${i18n.ebook_translator_placeholder}"
        value="${UtilS.escape(ebook_translator) || ''}"
      />
    </div>
  `;
};

export const makeEbookPublisherInputHtml = (ebook_publisher) => {
  const i18n = {
    ebook_publisher_placeholder: T('studio::digital_products::ebook_publisher_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">출판사</div>
    <div class="digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.ebook_publisher}"
        class="digital-product-creator-form__text-input"
        placeholder="${i18n.ebook_publisher_placeholder}"
        value="${UtilS.escape(ebook_publisher) || ''}"
      />
    </div>
  `;
};

export const makeEbookPublicationDateInputHtml = (ebook_publication_date) => {
  const i18n = {
    ebook_publication_date_placeholder: T('studio::digital_products::ebook_publication_date_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">출간일</div>
    <div class="digital-product-creator-form__input-wrapper">
      <input
        type="text"
        name="${digital_product_prop.ebook_publication_date}"
        class="digital-product-creator-form__text-input"
        placeholder="${i18n.ebook_publication_date_placeholder}"
        value="${UtilS.escape(ebook_publication_date) || ''}"
      />
    </div>
  `;
};

export const makeEbookCateSelectInputHtml = ({ ebook_categories, selected_id }) => {
  const texts = {
    cate_ebook_placeholder: T('studio::digital_products::cate_ebook_placeholder'),
  };

  return html`
    <div class="digital-product-creator-form__input-title">
      전자책 카테고리&nbsp;<span class="digital-product-creator-form__required">*</span>
    </div>
    <div class="digital-product-creator-form__input-wrapper">
      <select
        name="${digital_product_prop.ebook_category_id}"
        class="digital-product-creator-form__select-input ${selected_id ? 'active' : ''}"
      >
        <option value="" ${selected_id ? '' : 'selected'} disabled>${texts.cate_ebook_placeholder}</option>
        ${strMap(
          (category) => html`
            <option value="${category.id}" ${category.id == selected_id ? ' selected' : ''}>
              ${category.name}
            </option>
          `,
          ebook_categories,
        )}
      </select>
    </div>
  `;
};
