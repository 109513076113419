const ChainKey = {
  1: `EthereumMainnet`,
  5: `Goerli`,
  137: `PolygonMainnet`,
  8001: `Mumbai`,
};

const Eip712DomainBasicMeta = [
  { name: `name`, type: `string` },
  { name: `version`, type: `string` },
  { name: `chainId`, type: `uint256` },
];

export const signatureMessage = ({ chainId, address, nonce }) => {
  return {
    types: {
      EIP712Domain: Eip712DomainBasicMeta,
      SignInMessage: [
        { name: `address`, type: `string` },
        { name: `chain`, type: `string` },
        { name: `message`, type: `string` },
        { name: `nonce`, type: `uint256` },
      ],
    },

    primaryType: `SignInMessage`,

    domain: {
      name: `MarppleShop sign in signature`,
      version: `1`,
      chainId,
    },

    message: {
      address,
      chain: ChainKey[chainId],
      message: `Welcome to MarppleShop!\nThis signature will not cost any gas fees.`,
      nonce,
    },
  };
};
