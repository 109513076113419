import $dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { typo } from '../../../../../../../services/MarppleShop/shared/typography/typo.js';

export const mocommenteditor = ({ store_id, article }) => html`
  <div class="article-comment-editor" data-store_id="${store_id}" data-fx-json="${$dataStr(article)}">
    <div class="article-comment-write">
      <textarea
        class="article-comment-write__textarea ${typo('14_medium')}"
        name="comment"
        cols="30"
        rows="1"
        placeholder="${ET('community::comment_place_holder')}"
      ></textarea>
      <button class="article-comment-write__btn_submit ${typo('14_medium')}" type="button">
        ${ET('community::input')}
      </button>
    </div>
  </div>
`;
