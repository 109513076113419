import { delay } from 'fxjs/es';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { OMPCoreUtilF } from '../../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';

/**
 ### .ness-custom-maker-frame 을 사용하는 프레임
 - 등장 및 사라짐 애니메이션
 - 이벤트 및 스크롤 바리게이트 제공
 */
export const nessCustomMakerFrameLifeCycle = {
  showing: (frame_el$) => {
    frame_el$.classList.remove('slide-out');
    frame_el$.classList.add('slide-in');
    if (UtilF.isLegacyMobile()) $.on_prevent_default(frame_el$);
    OMPCoreUtilF.bodyFixed$(true);
  },
  hiding: async (frame_el$) => {
    frame_el$.classList.add('slide-out');
    await delay(500)();
    if (UtilF.isLegacyMobile()) $.off_prevent_default(frame_el$);
  },
  closed: (frame_el$) => {
    OMPCoreUtilF.bodyFixed$(false);
  },
};
