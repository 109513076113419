import { go, html } from 'fxjs/es';
import { NewMakerConditionAgreeTextF } from '../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { changeLineSticker } from '../image.js';
import { makeCvImageHtml } from '../CvImage/tmpl.js';
import { changeColorOfCvImage, cvImageObjForTmpl } from '../CvImage/fs.js';
import { makePatternEvent } from '../CvPattern/fs.js';
import { MarstagramEditorF } from '../../../MarstagramEditor/F/Function/module/MarstagramEditorF.js';
import { NewMakerCvObjectCvImageF } from '../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { NewMakerFitPrintWidthF } from '../../../NewMaker/FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { SimpleModalMuiF } from '../../../SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { $delegate } from 'fxdom/es';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';
import { OMPDosuF } from '../../../OMP/Dosu/F/Function/module/OMPDosuF.js';

const event = (el) =>
  go(
    el,
    MarstagramEditorF.imageEditorOpenBtnsEvent('.marstagram_editor .buttons'),
    makePatternEvent,
    $.on('click', '.image_color_item', async function (e) {
      try {
        const currentTarget = e.currentTarget;
        const image_color = box.sel(currentTarget);
        await changeColorOfCvImage(image_color);
        if (T.lang === 'kr')
          _go(
            $1('#image_color_app .color_name'),
            $.text(image_color['press_color_name' + _en] || T('maker::Unknown')),
          );
        $.selectable2(currentTarget);
      } catch (e) {
        if (e === 'not_available_color') return $.alert(T('선택된 색상으로 패턴을 만들수 없습니다.'));
        throw e;
      }
    }),
    $.on('click', '.image_editor_ev .dosu-colors .color-picker', OMPDosuF.handleDosuColorPicker.image),
    $.on('click', '.image_editor_ev .request_bg_removal >.head', function (e) {
      // $.toggle_class(e.currentTarget, 'checked');
      // NewMakerCvObjectCvImageF.setRequestBgRemoval(
      //   G.mp.maker.active(),
      //   $.has_class(e.currentTarget, 'checked'),
      // );
      const checked = $.has_class(e.currentTarget, 'checked');
      if (checked) {
        $.toggle_class(e.currentTarget, 'checked');
        NewMakerCvObjectCvImageF.setRequestBgRemoval(
          G.mp.maker.active(),
          $.has_class(e.currentTarget, 'checked'),
        );
        return;
      }
      MuiF.openFrame(SimpleModalMuiF.frame, async (frame, page, [tab]) => {
        frame.is_modal = !UtilF.isLegacyMobile();
        tab.appended = (tab_el) =>
          go(
            tab_el,
            $delegate('click', '.done', () => {
              $.toggle_class(e.currentTarget, 'checked');
              NewMakerCvObjectCvImageF.setRequestBgRemoval(
                G.mp.maker.active(),
                $.has_class(e.currentTarget, 'checked'),
              );
              MuiF.closeFrame();
            }),
            $delegate('click', '.cancel', () => {
              MuiF.closeFrame();
            }),
          );
        tab.makeData = () => {
          return {
            header: '배경 지움 안내',
            body: html`
              <div class="bg_removal_body">
                <img
                  src="//s3.marpple.co/files/u_193535/2022/6/original/8119ba7be245678e095beb014695b2db1ddfae582.jpg"
                  alt=""
                />
                <div class="content">실제 사진의 경우 배경 지움이 불가합니다.</div>
              </div>
            `,
            footer: html`
              <button type="button" class="cancel">${T('취소')}</button>
              <button type="button" class="done" style="${NewMakerUtilF.getCssStyle()}">
                ${T('Background Remove Request')}
              </button>
            `,
          };
        };
      });
    }),
    $.on('click', '.image_editor_ev .fit_to_print_width >.head', function (e) {
      $.toggle_class(e.currentTarget, 'checked');
      const is_fitted_to_print_width = $.has_class(e.currentTarget, 'checked');
      if (is_fitted_to_print_width) {
        const cv_obj = G.mp.maker.active();
        NewMakerFitPrintWidthF.activateFitToPrintWidth(cv_obj, cv_obj.canvas);
        cv_obj.canvas.renderAll();
      } else NewMakerFitPrintWidthF.cancelFitToPrintWidth(G.mp.maker.active());
    }),
    $.on('click', '.image_editor_ev .change_img', changeLineSticker),

    (el) => NewMakerConditionAgreeTextF.clickEvent(el, '.condition_agree_text'),
  );

export async function marpplizerImage() {
  const cv_object = G.mp.maker.active();
  const obj = await cvImageObjForTmpl();
  return {
    name: 'image',
    app: 'base2',
    html: html` <div class="activity">${makeCvImageHtml(obj, cv_object)}</div> `,
    event,
  };
}
