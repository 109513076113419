import { html, strMap } from 'fxjs/es';
import { makeProdLikeHtml } from '../../../../ProductDetail/S/Tmpl/partials/productDetailForm.js';
import { MShopAppTokenGateStoresProductConstantS } from '../Constant/module/MShopAppTokenGateStoresProductConstantS.js';

export const verifyList = (storesProductsTokenGates) =>
  strMap(
    ({
      status = 'question',
      _: {
        ether_contract: { name, chain, address, symbol },
      },
    }) => html`
      <div class="token-gate-item">
        <div class="token-gate-item__chain">
          <div class="token-gate-item__chain-icon">
            ${MShopAppTokenGateStoresProductConstantS.CONTRACT_CHAIN_ICON[chain]}
          </div>
          ${chain.replace('Mainnet', '')}
        </div>
        <div class="token-gate-item__name">
          <a
            href="${MShopAppTokenGateStoresProductConstantS.CHANNEL_ADDRESS_LINK[chain]}${address}"
            target="_blank"
            >${name && symbol ? `${name} (${symbol})` : 'Unknown(Click to Check)'}</a
          >
        </div>
        <div class="token-gate-item__icon" data-status="${status}">
          <img src="${MShopAppTokenGateStoresProductConstantS.PASS_FAIL_ICON[status] || ''}" alt="" />
        </div>
      </div>
    `,
    storesProductsTokenGates,
  );

export const btnHtml = () => html`<button
  class="token-gate__btn token-gate__btn-colorful"
  type="button"
  data-action="nft"
>
  ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG}
  <span class="token-gate__btn-title token-gate__btn-title--first">${T('tokenGate::tg009')}</span>
  <span class="token-gate__btn-title token-gate__btn-title--second">${T('tokenGate::tg010')}</span>
</button>`;

export const noPassButton = () => {
  return html`<button class="pd-submit__btn pd-submit__btn token-gate__btn-colorful" disabled type="button">
    ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('tokenGate::tg011')}
  </button>`;
};

export const pcSubmitTokenGate = ({ btn_text, stores_products_token_gates }) => {
  return html` <div class="token-gate">
      <div class="token-gate__flex">
        <div class="pd-field__label token-gate__label">${T('tokenGate::tg010_1')}</div>
        <div class="token-gate__flex-1">
          <div class="token-gate-list">${verifyList(stores_products_token_gates)}</div>
          ${stores_products_token_gates.length > 1
            ? html`<div class="token-gate-info">
                ${T(
                  MShopAppTokenGateStoresProductConstantS.TOKEN_GATE_INFO[
                    stores_products_token_gates[0].condition
                  ],
                )}
              </div>`
            : ''}
        </div>
      </div>
    </div>
    <div class="pd-submit">
      <button class="token-gate__btn token-gate__btn-colorful" type="button" data-action="nft">
        ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('tokenGate::tg009')}
      </button>
      <div class="pd-submit__btns pd-submit__btns--hide">
        <button
          class="pd-submit__btn pd-submit__btn--cart token-gate__btn-border-colorful"
          type="button"
          data-action="cart"
        >
          ${btn_text}
        </button>
        <button class="pd-submit__btn token-gate__btn-colorful" type="button" data-action="checkout">
          ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('translation::Buy Now 2')}
        </button>
      </div>
    </div>`;
};

export const mobileSubmitTokenGate = ({ btn_text, product_detail }) => {
  return html`
    <div class="pd-submit">
      <div class="pd__action">${makeProdLikeHtml({ product_detail })}</div>
      <button class="token-gate__btn token-gate__btn--form token-gate__btn-colorful" type="button">
        ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('tokenGate::tg009')}
      </button>
      <button
        style="display: none;"
        class="token-gate__btn token-gate__btn-colorful"
        type="button"
        data-action="nft"
      >
        ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('tokenGate::tg010')}
      </button>
      <div class="pd-submit__btns pd-submit__btns--hide">
        <button class="pd-submit__btn pd-submit__btn--form token-gate__btn-colorful" type="button">
          ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('Buy')}
        </button>
        <button
          style="display: none;"
          class="pd-submit__btn pd-submit__btn--cart token-gate__btn-border-colorful"
          type="button"
          data-action="cart"
        >
          ${btn_text}
        </button>
        <button
          style="display: none;"
          class="pd-submit__btn token-gate__btn-colorful"
          type="button"
          data-action="checkout"
        >
          ${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('translation::Buy Now 2')}
        </button>
      </div>
    </div>
  `;
};

export const fn = ({ stores_products_token_gates }) => {
  return html`<div class="token-gate">
    <div class="token-gate__flex">
      <div class="pd-field__label token-gate__label">${T('tokenGate::tg010_1')}</div>
      <div class="token-gate__flex-1">
        <div class="token-gate-list">${verifyList(stores_products_token_gates)}</div>
        ${stores_products_token_gates.length > 1
          ? html`<div class="token-gate-info">
              ${T(
                MShopAppTokenGateStoresProductConstantS.TOKEN_GATE_INFO[
                  stores_products_token_gates[0].condition
                ],
              )}
            </div>`
          : ''}
      </div>
    </div>
  </div>`;
};
