import { html, match, sel } from 'fxjs/es';
import { typo, typoPcMo } from '../../../../../../../services/MarppleShop/shared/typography/typo.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';

export const questionCreateHTML = ({ user, question, item_order_id, product_id, store_id, is_mobile }) => {
  const i18n = {
    file_placeholder: ET('mps2::question:: 문의와 관련된 파일을 첨부해 주세요.'),
    agree_personal_info: ET('mps2::question::개인정보수집 ・ 이용 동의'),
    user_info: ET('mps2::question::사용자 정보'),
    required: ET('mps2::question::필수 입력사항'),
    name: ET('mps2::question::이름'),
    name_placeholder: ET('mps2::question::이름을 입력해 주세요.'),
    error: ET('mps2::question::error'),
    creator_tel: ET('mps2::question::연락처'),
    creator_tel_placeholder: ET('mps2::question::없이 연락처를 입력해 주세요.'),
    email: ET('mps2::question::이메일'),
    email_placeholder: ET('mps2::question::답변 받으실 이메일 주소를 입력해 주세요.'),
    title: ET('mps2::question::제목'),
    title_placeholder: ET('mps2::question::제목을 입력해 주세요.'),
    qna_header: ET('mps2::question::문의 내용'),
    qna_placeholder: ET('mps2::question::문의 내용을 자세하게 작성해 주세요.'),
    qna_error: ET('mps2::question::문의 내용을 입력해 주세요.'),
    qna_info: ET(
      'mps2::question::문의 내용은 최대 2000bytes까지 입력 가능합니다.<br>(한글 1000자, 영문 2000자)',
    ),
    qna_file: ET('mps2::question::파일 첨부'),
    qna_file_info: ET(
      'mps2::question::jpg, png, zip, pdf 확장자만 첨부 가능하며, 10MB까지 가능합니다.<br />(여러 사진을 첨부해야 한다면 zip 파일로 첨부해 주세요.)',
    ),
    qna_view: ET('mps2::question::보기'),
    cancel: ET('mps2::question::취소'),
    submit: ET('mps2::question::문의 접수'),
  };

  return html`
    <div
      class="store-qna-editor"
      data-item_order_id="${item_order_id}"
      data-product_id="${product_id}"
      data-store_id="${store_id}"
    >
      <div class="store-qna-editor__header">
        <h2>${i18n.user_info}</h2>
        <p><span class="store-qna-editor__required">*</span> ${i18n.required}</p>
      </div>
      <div class="store-qna-editor__controls">
        <div class="store-qna-editor__control">
          <label class="store-qna-editor__label">
            <div
              class="store-qna-editor__label-txt ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '14_bold',
              })}"
            >
              ${i18n.name} <span class="store-qna-editor__required">*</span>
            </div>
            <div class="store-qna-editor__body">
              <input
                type="text"
                name="name"
                value="${question?.name || user?.name || ''}"
                class="store-qna-editor__body-input ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
                placeholder="${i18n.name_placeholder}"
              />
            </div>
          </label>
          <p class="store-qna-editor__error-msg">${i18n.error}</p>
        </div>

        <div class="store-qna-editor__control">
          <label class="store-qna-editor__label">
            <div
              class="store-qna-editor__label-txt ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '14_bold',
              })}"
            >
              ${i18n.creator_tel} <span class="store-qna-editor__required">*</span>
            </div>
            <div class="store-qna-editor__body">
              <input
                type="text"
                name="mobile"
                value="${question?.mobile || user?.mobile || ''}"
                class="store-qna-editor__body-input ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
                placeholder="${i18n.creator_tel_placeholder}"
              />
            </div>
          </label>
          <p class="store-qna-editor__error-msg">${i18n.error}</p>
        </div>

        <div class="store-qna-editor__control">
          <label class="store-qna-editor__label">
            <div
              class="store-qna-editor__label-txt ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '14_bold',
              })}"
            >
              ${i18n.email} <span class="store-qna-editor__required">*</span>
            </div>
            <div class="store-qna-editor__body">
              <input
                type="text"
                name="email"
                value="${question?.email || user?.email || ''}"
                class="store-qna-editor__body-input ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
                placeholder="${i18n.email_placeholder}"
              />
            </div>
          </label>
          <p class="store-qna-editor__error-msg">${i18n.error}</p>
        </div>
      </div>

      <hr />

      <div class="store-qna-editor__header">
        <h2>${i18n.qna_header}</h2>
      </div>

      <div class="store-qna-editor__controls">
        <div class="store-qna-editor__control">
          <label class="store-qna-editor__label">
            <div
              class="store-qna-editor__label-txt ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '14_bold',
              })}"
            >
              ${i18n.title} <span class="store-qna-editor__required">*</span>
            </div>
            <div class="store-qna-editor__body">
              <input
                type="text"
                name="title"
                value="${question?.title}"
                class="store-qna-editor__body-input ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
                placeholder="${i18n.title_placeholder}"
              />
            </div>
          </label>
          <p class="store-qna-editor__error-msg">${i18n.error}</p>
        </div>

        <div class="store-qna-editor__control">
          <label class="store-qna-editor__label">
            <div
              class="store-qna-editor__label-txt ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '14_bold',
              })}"
            >
              ${i18n.qna_header} <span class="store-qna-editor__required">*</span>
            </div>
            <div class="store-qna-editor__body store-qna-editor__body--textarea">
              <textarea
                name="body"
                cols="30"
                rows="10"
                class="store-qna-editor__body-textarea ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
                placeholder="${i18n.qna_placeholder}"
              >
${question?.body || ''}</textarea
              >
              <div class="store-qna-editor__body-textarea-length ${typo('12_medium')}">
                <span
                  class="store-qna-editor__body-textarea-length-txt store-qna-editor__body-textarea-length-txt"
                  >${UtilS.checkTextLength(sel('body', question) || '')}</span
                >/2000bytes
              </div>
            </div>
          </label>
          <p class="store-qna-editor__error-msg">${i18n.qna_error}</p>
          <p class="store-qna-editor__txt1 ${typo('12_medium')}">${i18n.qna_info}</p>
        </div>

        <div class="store-qna-editor__control store-qna-editor__control--file">
          <div class="store-qna-editor__control-wrap">
            <div class="store-qna-editor__body store-qna-editor__body--file" can_cancel="false">
              <div
                class="store-qna-editor__body-file-text ${typoPcMo({
                  is_mobile,
                  pc: '16_medium',
                  mo: '14_medium',
                })}"
              >
                ${i18n.file_placeholder}
              </div>
              <div class="store-qna-editor__body-file-del">
                <img
                  src="//s3.marpple.co/files/u_218933/2022/8/original/750ae6dba9f55f2f7064c33c489394713c8454e71.png"
                  alt="X"
                />
              </div>
            </div>
            <div class="store-qna-editor__btn-file-area">
              <label>
                <input
                  type="file"
                  class="store-qna-editor__input-file"
                  accept="image/jpeg,image/png,image/pjpeg,application/zip,.pdf"
                />
                <button
                  type="button"
                  class="store-qna-editor__btn-file ${typoPcMo({
                    is_mobile,
                    pc: '16_bold',
                    mo: '14_bold',
                  })}"
                >
                  ${i18n.qna_file}
                </button>
              </label>
            </div>
          </div>
          <p class="store-qna-editor__txt1 store-qna-editor__txt1--icon1">
            <span class="store-qna-editor__icon-important store-qna-editor__icon-important--ml0">
              <img
                src="//s3.marpple.co/files/u_2308668/2023/11/original/e6a67dbea9e9de8b6c52ffcb4d43029d3cd9838b1.png"
                alt="!"
              />
            </span>
            <span class="${typo('12_medium')}"> ${i18n.qna_file_info} </span>
          </p>
        </div>

        <div class="store-qna-editor__control">
          <div
            class="store-qna-editor__body store-qna-editor__body--check ${typoPcMo({
              is_mobile,
              pc: '16_medium',
              mo: '14_medium',
            })}"
          >
            ${MShopCoreTmplS.checkbox({
              name: 'is_agree',
              title: match(ET.lang)
                .case('kr')(() => i18n.agree_personal_info)
                .case('en')(
                  () => html`
                    <span
                      >${i18n.agree_personal_info}&nbsp;<a
                        href="/@/privacy"
                        class="go_privacy store-qna-editor__agree-underline"
                        target="_blank"
                        >Privacy Policy</a
                      >,&nbsp;<a
                        href="/@/terms"
                        class="go_terms store-qna-editor__agree-underline"
                        target="_blank"
                        >Terms & Conditions.</a
                      ></span
                    >
                  `,
                )
                .case('jp')(
                  () => html`
                    <span
                      >${i18n.agree_personal_info}&nbsp;(
                      <a
                        href="/@/privacy"
                        class="go_privacy store-qna-editor__agree-underline"
                        target="_blank"
                      >
                        プライバシーポリシー </a
                      >,&nbsp;<a
                        href="/@/terms"
                        class="go_terms store-qna-editor__agree-underline"
                        target="_blank"
                        >利用規約</a
                      >)</span
                    >
                  `,
                )
                .else(() => ''),
            })}
            <span
              class="store-qna-editor__agree-view ${typoPcMo({
                is_mobile,
                pc: '16_regular',
                mo: '14_medium',
              })}"
              >${i18n.qna_view}</span
            >
          </div>
        </div>
      </div>

      <div class="store-qna-editor__buttons">
        <button type="button" class="store-qna-editor__btn-cancel ${typo('16_bold')}">${i18n.cancel}</button>
        <button type="button" class="store-qna-editor__btn-submit ${typo('16_bold')}">${i18n.submit}</button>
      </div>
    </div>
  `;
};

export const questionAcceptedFileTypesHtml = () => {
  const i18n = {
    title: ET('mps2::question::file_modal_title'),
    content: ET('mps2::question::file_modal_content'),
    desc: ET('mps2::question::file_modal_content_desc'),
  };

  return html`
    <div class="ecommerce-info__alert">
      <h3>${i18n.title}</h3>
      <p style="text-align: center;">
        ${i18n.content}<br />
        ${i18n.desc}
      </p>
    </div>
  `;
};

export const questionPrivacyHtml = () => {
  return html`
    <div class="body terms_body">
      <div class="title">
        <h2>개인 정보 수집 및 이용 동의</h2>
      </div>
      <div class="body text_body question-privacy-frame__body">
        <div class="paragraph">
          <table class="question-privacy-frame__table">
            <thead>
              <tr>
                <th>제공받는 자</th>
                <th>수집 목적</th>
                <th>수집 항목</th>
                <th>보유 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>문의 상품 보유 크리에이터</td>
                <td>고객 상담 및 불만 처리</td>
                <td>이름, 연락처, 이메일주소</td>
                <td>
                  서비스 처리 완료 후<br />
                  180일간 보관 후 파기
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            개인정보 제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 서비스 이용이 제한될수 있습니다.</br>
            마플코퍼레이션은 크리에이터 배송 사품에 관해서는 통신판매중개자이며 통신판매의 당사자가 아닙니다.</br>
            따라서 마플코퍼레이션은 상품 거래정보 및 거래에 대하여 책임을 지지 않습니다.
          </p>
        </div>
      </div>
      <div class="option">
        <button class="done">확인</button>
      </div>
    </div>
  `;
};
