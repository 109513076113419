import { each, find, go, html, juxt, map, partition, sel, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import {
  $addClass,
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $qs,
  $remove,
  $removeClass,
  $replaceWith,
  $setAttr,
  $setText,
  $text,
  $toggleClass,
  $trigger,
} from 'fxdom/es';
import { BpOptionConstantS } from '../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { STORE_PRODUCT_CUSTOM_LEVEL } from '../../../../Creator/Product/S/constant.js';
import { fcanvasChanging } from '../../../../Maker/F/util.js';
import { getBpcTitle, getCurrentBpcName } from '../../../../Maker/F/Product/bpc_fs.js';
import {
  getBaseProductColorsInMaker,
  getCurrentBpcId,
  getProductColorInMaker,
} from '../../../../Maker/F/getSth.js';
import { NewMakerComponentsImageDropBoxF } from '../../../../NewMaker/Components/ImageDropBox/F/Function/module/NewMakerComponentsImageDropBoxF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { requiringBorder } from '../../../../Maker/F/Product/bpc_tmpl.js';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { BpOptionTmplS } from '../../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import { setPriceKeyringAndAcryl } from '../../../../Maker/S/product.js';
import { NessCustomMakerCoreConstantS } from '../../../Core/S/Constant/module/NessCustomMakerCoreConstantS.js';
import { makePricingAndCartSectionHtml } from '../../../S/Tmpl/nesscustommaker.js';
import { NessCustomMakerCoreF } from '../../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { NessCustomMakerCoreS } from '../../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { NessCustomMakerMarpplizerF } from './module/NessCustomMakerMarpplizerF.js';
import { marpplizerProxy } from '../../../../Maker/F/Marpplizer/marpplizer.js';
import { UtilTmplS } from '../../../../Util/S/Tmpl/module/UtilTmplS.js';

const makeImgSelectBpOption = (bp_options) =>
  go(
    bp_options,
    map((bp_option) => {
      const { selectors: { img, selected_img } = { selectors: { img: '', selected_img: '' } } } =
        bp_option.preview_meta || {};
      return {
        id: bp_option.id,
        name: bp_option['name' + G._en],
        img,
        selected_img,
        price: bp_option.price,
      };
    }),
  );

/**
 * select box를 가장 위로 올려주기 위한 함수입니다.
 * 부모, 조상 element의 overflow: hidden 속성 때문에 overflow 되지 않아서 값을 unset
 * 하위에 있어야하는 element(general_option_buttons)의 z-index 를 -1로 설정
 * @function
 * @param {HTMLElement} target_el - marpplizer 내부 element
 */
const bringToFront = ({ target_el }) => {
  const marpplizer = go(target_el, $closest('#marpplizer'));
  const activity = go(marpplizer, $find('.activity'));
  const iscroll_wrapper = go(marpplizer, $find('.iscroll_wrapper'));
  const general_option_buttons = go(marpplizer, $find('.general_option_buttons'));
  const select_box_options_el = go(marpplizer, $find('.select_box__options'));

  const select_box_options_rect = select_box_options_el.getBoundingClientRect();
  const general_option_buttons_rect = general_option_buttons.getBoundingClientRect();

  const ness_product__title_el = go(marpplizer, $find('.ness_product__title'));
  const ness_product__selection_el = go(marpplizer, $find('.ness_product__selection'));
  const product_editor_el = go(marpplizer, $find('.product_editor'));

  const height =
    $.outerHeight(ness_product__title_el) +
    $.outerHeight(ness_product__selection_el) +
    $.outerHeight(select_box_options_el) +
    80; /* 80: flex gap*/

  // 겹친 경우만 처리
  const is_overlapping = !(
    general_option_buttons_rect.bottom < select_box_options_rect.top ||
    general_option_buttons_rect.top > select_box_options_rect.bottom
  );

  if (is_overlapping) {
    product_editor_el.style.setProperty('height', `${height - 2}px`);
    product_editor_el.style.setProperty('overflow', `hidden`);
    activity.style.setProperty('overflow', 'unset', 'important');
    iscroll_wrapper.style.setProperty('overflow', 'unset', 'important');
    general_option_buttons.style.setProperty('z-index', -1);
  }
};

/**
 * select box가 닫혔을 때 다시 밑으로 내려주기 위한 함수입니다.
 * bringToFront 함수로 인해 변경된 값을 원상 복구
 * @function
 * @param {HTMLElement} target_el - marpplizer 내부 element
 */
export const sendToBack = ({ target_el }) => {
  const marpplizer = go(target_el, $closest('#marpplizer'));
  const activity = go(marpplizer, $find('.activity'));
  const iscroll_wrapper = go(marpplizer, $find('.iscroll_wrapper'));
  const general_option_buttons = go(marpplizer, $find('.general_option_buttons'));
  const product_editor_el = go(marpplizer, $find('.product_editor'));

  product_editor_el.style.removeProperty('height');
  product_editor_el.style.removeProperty('overflow');
  activity.style.removeProperty('overflow');
  iscroll_wrapper.style.removeProperty('overflow');
  general_option_buttons.style.removeProperty('z-index');
};

const toggleSelectBox = async (e) => {
  const { currentTarget } = e;
  const scroll = $qs('.activity');
  scroll?.myScroll?.disable();

  const { option_group, ui_type } = $data(currentTarget);

  if (ui_type === BpOptionConstantS.UI_TYPE.IMAGE_SELECT) {
    const current_id = go(currentTarget, $find('.current_option')).dataset.id;

    const afterSelection = async (selected_bp_option_id_from_other_select) => {
      const current_option_name = go(
        option_group._.bp_options,
        find(({ id }) => id == selected_bp_option_id_from_other_select),
        sel('name'),
      );

      const container = go(currentTarget, $closest('.ness_product'));

      go(
        container,
        $find('.current_option'),
        juxt($setAttr({ 'data-id': selected_bp_option_id_from_other_select }), $setText(current_option_name)),
      );

      $trigger(
        'click',
        go(
          container,
          $find(`.select_box__options .option[data-id="${selected_bp_option_id_from_other_select}"]`),
        ),
      );
    };

    await NewMakerComponentsImageDropBoxF.openImageDropBox(
      {
        selected_bp_option_id: current_id,
        options: makeImgSelectBpOption(option_group._.bp_options),
        element: currentTarget,
        color: '#00BAFF',
        option_group_id: option_group.id,
      },
      afterSelection,
    );

    const element = $qs('.maker-image-selector');
    const currentTop = parseInt(window.getComputedStyle(element).top);
    element.style.top = currentTop + 2 + 'px';

    const { bottom: marpplizer_bottom } = $qs('#marpplizer').getBoundingClientRect();
    const { bottom } = $qs('.current_option').getBoundingClientRect();
    element.style.maxHeight = marpplizer_bottom - bottom - 20 + 'px';
  }

  go(currentTarget, $closest('.ness_product__selection'), $find('.selection_wrap'), (el) => {
    if (ui_type !== BpOptionConstantS.UI_TYPE.IMAGE_SELECT) {
      $toggleClass('open', el);
      const open = go(el, $hasClass('open'));
      if (open) {
        bringToFront({ target_el: el });
      } else {
        sendToBack({ target_el: el });
      }
    }
    // 가장 맨 위에 표시되기 때문에 높이 조정 필요 없어보임!
    // adjustOptionHeight(el);
  });
};

const adjustOptionHeight = (el) => {
  if (!el.classList.contains('open')) return;

  const { bottom: marpplizer_bottom } = $qs('#marpplizer .iscroll_wrapper').getBoundingClientRect();
  const { bottom } = go(el, $find('.select_box')).getBoundingClientRect();
  const adjust_height = Math.min(Math.max(marpplizer_bottom - bottom - 30), 280);
  $qs('.select_box__options').style.maxHeight = `${adjust_height}px`;
};

const handleChangeSize = async ({ currentTarget }) => {
  $.don_loader_start();
  const { size } = currentTarget.dataset;

  const base_product_size_id = currentTarget.value;

  await NessCustomMakerCoreF.changeProductBaseProductSize(Number(base_product_size_id));

  go(
    currentTarget,
    $closest('.ness_product__size'),
    $find('.bp_size_name'),
    juxt($setText(size), (el) => {
      if (size === T('mshop::ProductStatus::out_of_stock')) {
        if (!$hasClass('sold_out', el)) {
          $addClass('sold_out', el);
        }
        NessCustomMakerMarpplizerF.setButtonState($qs('#marpplizer'));
      } else {
        $removeClass('sold_out', el);
      }
    }),
  );

  $.don_loader_end();
};

const handleSelectBox = async ({ currentTarget }) => {
  sendToBack({ target_el: currentTarget });

  const base_product = box().maker.product_color._.base_product;
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(base_product)?.length;

  const { id } = currentTarget.dataset;
  const option_name = go(currentTarget, $closest('.ness_product__selection'), $find('.head'), $text);
  $.don_loader_start();

  if (has_bp_option_groups) {
    await go(
      currentTarget,
      $closest('.ness_product'),
      $find(`.bp_option_group[data-name="${option_name}"] select`),
      async (el) => {
        const quantity_el = go(currentTarget, $closest('#marpplizer'), $find('[name="quantity"]'));
        await NessCustomMakerCoreF.bpSelectOptionHandling(el, id);
        $trigger('change', quantity_el);
      },
    );

    box().maker.maker_props.is_condition_agree = undefined;

    // toggleButtonActive(false);
    await marpplizerProxy();
  } else {
    await NessCustomMakerCoreF.changePhoneCaseBpByBpId(id);
  }
  $.don_loader_end();
};

const event = (el) =>
  go(
    el,
    $delegate('change', '.ness_size_radio', handleChangeSize),
    $delegate('click', '.ness_product__selection .select_box', toggleSelectBox),
    $delegate('click', '.ness_product__selection .select_box__options .option', handleSelectBox),
    $delegate(
      'click',
      '.product_editor_ev .color_item:not(.selected)',
      fcanvasChanging(async function (e) {
        $.don_loader_start();
        G.mp.maker.unselect_all();
        const ct = e.currentTarget;
        // await eventProductBpcA(e.currentTarget);
        $.selectable3(ct);
        const bpc = box.sel(ct);
        await NessCustomMakerCoreF.changeProductBaseProductColor(bpc);
        // if ($1('#marpplizer .product_editor .size.section')) {
        //   $1('#marpplizer .product_editor .size.section').outerHTML = makeBpsSizeItemHtml(
        //     ...NessCustomMakerCoreS.getBaseProductSizes(getProductColorInMaker()),
        //   );
        // }
        $.don_loader_end();
      }),
    ),
  );

const nessTitleTmpl = (product_color) => {
  const product_name = NessCustomMakerCoreS.getProductName(product_color, G._en);

  const price = go(NessCustomMakerCoreS.getProductPrice(product_color), (price) =>
    UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang),
  );
  const { custom_level } = box().product._.stores_product;
  return html` <div class="ness_product__title">
    ${custom_level && custom_level != STORE_PRODUCT_CUSTOM_LEVEL.STATIC
      ? html`
          <div class="ness_product__title__tag">
            <div class="ness_product__title__tag-box">${ET('ness::product::custom')}</div>
          </div>
        `
      : ''}
    <div class="ness_product__title__name">${product_name}</div>
    <div class="ness_product__title__price">${price}</div>
  </div>`;
};

const nessSelectableBps = ({ bp_options }) => {
  return html`
    <div class="select_box__options">
      ${strMap(({ id, name, price }) => {
        const _price = `${price}`;
        const c_price = UtilS.commify(_price.startsWith('-') ? _price : '+' + _price);
        return html`
          <div class="option" data-id="${id}" data-name="${name}">
            ${name} ${UtilS.htmlIf(price, html` <div class="additional_price">${c_price}</div>`)}
          </div>
        `;
      }, bp_options)}
    </div>
  `;
};

const nessBpSelectTmpl = ({ option_group_name, bp_options, option_group, selected_id, ui_type }) => {
  const data = {
    option_group,
    ui_type,
    selected_id,
    option_group_name,
    bp_options,
  };
  const { id, name } = go(
    bp_options,
    find((option) => option.id === selected_id),
  );

  return html` <div class="ness_product__selection">
    <div class="head">${option_group_name}</div>
    <div class="selection_wrap">
      <div class="select_box" data-fx-json="${$dataStr(data)}">
        <div class="current_option" data-id="${id}" data-name="${name}">${name}</div>
      </div>
      ${nessSelectableBps({ bp_options })}
    </div>
  </div>`;
};

function colorItemTmpl(bpc, current_bpc_id) {
  const selected = bpc.id == current_bpc_id ? 'selected' : '';
  return html`
    <div
      class="color_item"
      _id="${bpc.id}"
      _sel="./(#${bpc.id})"
      color_code="${bpc.color_code}"
      need_ccc=${requiringBorder([bpc.color_code, bpc.color_code2])}
    >
      <div class="color_item__chip  ${selected}">
        ${bpc.color_code2
          ? `<span style="background: linear-gradient(135deg, ${bpc.color_code} 50%, ${
              bpc.color_code2 === '#ffffff' || bpc.color_code2 === '#fefefe' ? '#f8f8f8' : bpc.color_code2
            } 50%)"></span>`
          : `<span style="background: ${bpc.color_code}"></span>`}
      </div>
      <div class="color_name">${bpc.name}</div>
    </div>
  `;
}

function nessBpcTmpl(bpcs, color_name, title, current_bpc_id) {
  if (!color_name) return ``;
  return html`
    <div class="ness_product__color">
      <div class="head">
        ${title}
        <span class="bp_color_name">
          ${go(
            bpcs,
            find(({ id }) => id === current_bpc_id),
            ({ name, name2 }) => (name !== name2 && name2 ? `${name} / ${name2}` : `${name}`),
          )}
        </span>
      </div>
      <div
        class="ness_product__color__wrap"
        _sel="maker->product_color->_->base_product->_->base_product_colors"
      >
        ${strMap((bpc) => colorItemTmpl(bpc, current_bpc_id), bpcs)}
      </div>
    </div>
  `;
}

function nessBpsTmpl(bpss, base_product_size_id, sold_out_size_ids) {
  const size_name = go(
    bpss,
    find((bp) => bp.id == base_product_size_id),
    (bp) =>
      sold_out_size_ids.includes(bp.id) ? T('mshop::ProductStatus::out_of_stock') : bp['short_name' + _en],
  );
  const sold_out = (id) => sold_out_size_ids.includes(id);

  return html`
    <div class="ness_product__size">
      <div class="head">
        ${T('Size')}
        <span class="bp_size_name ${sold_out(base_product_size_id) ? 'sold_out' : ''}">${size_name}</span>
      </div>
      ${UtilS.htmlIf(
        bpss.length > 1,
        html` <div class="ness_product__size__wrap">
          ${strMap((bps) => {
            const selected = bpss.length === 1 || bps.id == base_product_size_id ? 'selected' : '';

            const short_name = bps['short_name' + _en];

            return html`<label>
              <input
                type="radio"
                class="ness_size_radio"
                ${selected ? 'checked' : ''}
                name="size"
                value="${bps.id}"
                data-size="${sold_out(bps.id) ? T('mshop::ProductStatus::out_of_stock') : short_name}"
                ${UtilTmplS.disableIf(sold_out(bps.id))}
              />
              <span class="size_container ${sold_out(bps.id) ? 'sold_out' : ''}"
                ><span>${short_name}</span></span
              >
              ${UtilS.htmlIf(
                bps.price,
                `<span class="size_container-price" title="${bps.price}">+${bps.price}</span>`,
              )}
            </label>`;
          }, bpss)}
        </div>`,
      )}
    </div>
  `;
}

const makeBpOptionGroupsHtml = ({ option_group, ui_type, selected_id }) => {
  const {
    name: option_group_name,
    _: { bp_options },
  } = option_group;

  if (ui_type === NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.FIXED) {
    const name = go(
      option_group._.bp_options,
      find(({ id }) => id == selected_id),
      sel('name'),
    );
    return html` <div class="ness_product__size" data-id="${selected_id}">
      <div class="head">${option_group_name}<span>${name}</span></div>
    </div>`;
  } else {
    return nessBpSelectTmpl({
      option_group_name,
      option_group,
      bp_options,
      selected_id,
      ui_type,
    });
  }
};

const nessProductTmpl = ({ product_color }) => {
  const base_product = product_color._.base_product;
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(base_product)?.length;

  const is_bpc_show = !base_product.is_bpc_hidden;
  const is_bps_show = !has_bp_option_groups && !base_product.is_bps_hidden;
  const phone_cases = box.sel('maker->phone_cases');

  return html`
    <div class="ness_product">
      ${nessTitleTmpl(product_color)}
      ${phone_cases?.length
        ? nessBpSelectTmpl({
            option_group_name: '기종',
            bp_options: phone_cases,
            selected_id: product_color._.base_product.id,
          })
        : ``}
      ${UtilS.htmlIf(
        is_bpc_show,
        nessBpcTmpl(getBaseProductColorsInMaker(), getCurrentBpcName(), getBpcTitle(), getCurrentBpcId()),
      )}
      ${UtilS.htmlIf(
        is_bps_show && !phone_cases.length,
        nessBpsTmpl(...NessCustomMakerCoreS.getBaseProductSizes(getProductColorInMaker())),
      )}
      ${go(
        NessCustomMakerCoreS.filteredBpOptionGroupsForUi(product_color, false),
        partition(({ ui_type }) => ui_type !== NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.FIXED),
        strMap((bp_option_group) =>
          strMap(
            ({ ui_type, option_group, selected_id }) =>
              makeBpOptionGroupsHtml({
                ui_type,
                option_group,
                selected_id,
              }),
            bp_option_group,
          ),
        ),
      )}
      ${UtilS.htmlIf(
        has_bp_option_groups,
        html`
          <div class="section maker-product-info__bp-option-groups">
            ${BpOptionTmplS.bpAllOptions({
              product: product_color,
            })}
          </div>
        `,
      )}
    </div>
  `;
};

const setConditionAgreeStatus = (marpplizer_el, is_condition_agree) =>
  go(marpplizer_el, $find('input[name="warning_checkbox"]'), (el) => {
    if (el) el.checked = is_condition_agree || false;
  });

const refreshGeneralOptionButtons = (marpplizer_el, product_color) => {
  const { maker_props = {} } = box().maker;

  $replaceWith(
    $el(makePricingAndCartSectionHtml(product_color, maker_props)),
    go(marpplizer_el, $find('.ness_option_wrap')),
  );

  NessCustomMakerCoreF.registerNoticeTippy(marpplizer_el, {
    placement: 'top-end',
  });
  setConditionAgreeStatus(marpplizer_el, maker_props?.is_condition_agree);
};

export const nessHome = function () {
  const product_color = box().maker.product_color;
  const id = 'marpplizer_home';
  setPriceKeyringAndAcryl(product_color);

  const html_body = html`
    <div class="main_head">Product</div>
    ${nessProductTmpl({ product_color })}
  `;
  return {
    name: 'home',
    app: 'base1',
    html: html`
      <div class="activity" id="${id}">
        <div class="body product_editor product_editor_ev marpplizer_contents_wrapper">${html_body}</div>
      </div>
    `,
    remove: (el) => {
      go(el, $findAll(`#${id}`), each($remove));

      refreshGeneralOptionButtons($closest('#marpplizer', el), product_color);
    },
    event,
  };
};
