import { strMap, html } from 'fxjs/es';
import { MShopUtilConstantS } from '../../../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { CreatorEditorButtonS } from '../Function/module/CreatorEditorButtonS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { NessOutboundConstantS } from '../../../../../Ness/Outbound/S/Constant/module/NessOutboundConstantS.js';

export const makeColorPallteHtml = ({
  color,
  component_name = 'editor-color-pallete',
  transparent = false,
  checked,
} = {}) => {
  const is_white = CreatorEditorButtonS.colorValueToName(color) == 'white';

  return html`
    <label class="${component_name}__item">
      <input
        class="${component_name}__input"
        name="color"
        type="radio"
        value="${color}"
        ${UtilTmplS.checkIf(checked)}
      />
      <div
        class="${component_name}__preview ${is_white ? `${component_name}__preview--white"` : ''}
          ${transparent ? `${component_name}__preview--transparent` : ''}"
        style="background-color: ${color};"
      ></div>
    </label>
  `;
};

/**
 *
 * @param {string} [content]
 * @param {string} link
 * @param {string} [color_name]
 * @return {string}
 */
const makeButtonHtml = ({ content = '버튼', link, color_name = 'black' } = {}) => {
  return html`
    <a target="_blank" class="editor-button" ${link ? `href="${UtilS.escape(link)}"` : ''}
      ><div class="editor-button__content editor-button__content--${color_name}">
        ${UtilS.escape(content) || '버튼'}
      </div></a
    >
  `;
};

export const makeColorRadio = ({ transparent = false, prev_color } = {}) => html`
  <div class="editor-color-pallete">
    ${strMap(
      (color) => makeColorPallteHtml({ color, transparent, checked: color == prev_color }),
      UtilS.isNessApp() ? [NessOutboundConstantS.colors.blue] : MShopUtilConstantS.color_pallete_list,
    )}
  </div>
`;

export const makeButtonEditHtml = ({ content, link, color }) => {
  const component = `editor-button-edit-container`;

  return html`
    <div class="${component}">
      <div class="${component}__property">
        <p class="${component}__property_title">버튼 텍스트</p>
        <input class="${component}__content" name="content" type="text" value="${content}" />
      </div>

      <div class="${component}__property">
        <h2 class="${component}__property_title">URL</h2>
        <input class="${component}__link" name="link" type="text" value="${UtilS.escape(link)}" />
      </div>

      <div class="${component}__property">
        <h2 class="${component}__property_title">컬러</h2>
        ${makeColorRadio()}
      </div>

      <div class="${component}__property">
        <h2 class="${component}__property_title">미리보기</h2>
        <div class="editor-button-container" contenteditable="false">
          ${makeButtonHtml({
            content,
            color_name: UtilS.isNessApp() ? 'ness-blue' : color,
            link,
          })}
        </div>
      </div>

      <div class="editor-button-edit-footer">
        <button class="btn btn-close editor-button-edit-footer__button">취소하기</button>
        <button class="btn btn-submit editor-button-edit-footer__button">저장하기</button>
      </div>
    </div>
  `;
};
