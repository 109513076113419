export const N_OPTION_INFO_STATUS = {
  NORMAL: 'NORMAL',
  NO_STOCK: 'NO_STOCK',
  OPTION_CHANGED: 'OPTION_CHANGED',
  NOT_ENOUGH_STOCK: 'NOT_ENOUGH_STOCK',
  LIMIT_EXPIRED: 'LIMIT_EXPIRED',
  DISCONTINUED: 'DISCONTINUED',
};

export const WARNING_MESSAGE = {
  get OPTION_CHANGED() {
    return ET('mps2::cart::option_changed');
  },
  get NO_STOCK() {
    return ET('mps2::cart::no_stock');
  },
  get NOT_ENOUGH_STOCK() {
    return ET('mps2::cart::not_enough_stock');
  },
  get DISCONTINUED() {
    return ET('mps2::cart::discontinued');
  },
};
