import { type Html, html, UnsafeHtml, View } from 'rune-ts';
import klass from './Confirm.module.scss';
import { Modal } from '../../atoms/Modal/Modal';
import { ButtonAction } from '../../atoms/ButtonAction/ButtonAction';
import { typo } from '../../../typography/typo';

export interface ConfirmProps {
  backdrop?: boolean;
  backdrop_not_close?: boolean;
  appendTo?: string | Element;
  title?: string;
  message?: string | Html | UnsafeHtml;
  confirmText?: string;
  cancelText?: string;
}

export class Confirm extends View<ConfirmProps> {
  override template(data: ConfirmProps) {
    return html`
      <div>
        ${new Modal({
          isOpen: false,
          backdrop: data.backdrop,
          backdrop_not_close: data.backdrop_not_close,
          size: 'free',
          body: html`
            <div class="${klass.alert}">
              <div class="${klass.text}">
                <div class="${klass.title} ${typo('20_bold')}">${data.title ?? ''}</div>
                <div class="${klass.message} ${typo('14_medium')}">${data.message ?? ''}</div>
              </div>
              <div class="${klass.button}">
                ${new ButtonAction({
                  klass: 'cancel_btn',
                  label: data.cancelText || ET('mps2::modal::취소'),
                  type: 'line',
                  size: 'large',
                })}
                ${new ButtonAction({
                  klass: 'confirm_btn',
                  label: data.confirmText || ET('mps2::modal::확인'),
                  type: 'blue',
                  size: 'large',
                })}
              </div>
            </div>
          `,
        })}
      </div>
    `;
  }

  getModal() {
    const modal = this.subViewsFromTemplate[0] as Modal;
    return modal;
  }

  protected async show() {
    if (this.data.appendTo) {
      let target = this.data.appendTo as Element | null;
      if (typeof this.data.appendTo === 'string') {
        target = document.body.querySelector(this.data.appendTo);
      }
      if (target) target.appendChild(this.render());
    } else {
      document.body.appendChild(this.render());
    }

    this.getModal().open();
    this.element().focus();

    return new Promise((res, rej) => {
      const confirm_btn = this.element()?.querySelector('.confirm_btn');
      const cancel_btn = this.element()?.querySelector('.cancel_btn');
      const handleOK = () => {
        detach();
        this.close();
        res(true);
      };
      const handleCancel = () => {
        detach();
        this.close();
        res(false);
      };
      const detach = () => {
        confirm_btn?.removeEventListener('click', handleOK);
        cancel_btn?.removeEventListener('click', handleCancel);
      };

      confirm_btn?.addEventListener('click', handleOK);
      cancel_btn?.addEventListener('click', handleCancel);
    });
  }

  close() {
    this.getModal().close();
  }

  static async open(props: ConfirmProps) {
    const confirm = new Confirm(props);
    return confirm.show();
  }
}
