export const thumbnail_img =
  '//s3.marpple.co/files/u_218933/2024/7/original/66f2110040004cfe41d0a821b61d3e74e34582ce1.png';

export const banner_img =
  '//s3.marpple.co/files/u_218933/2024/6/original/6c4a81ee38c57b64c1f4c8522461276abb243ca41.png';

export const default_creator_img =
  '//s3.marpple.co/files/u_1165777/2024/4/original/8ff2b4e88ce4d2eabce1474c26a20de0220d2d981.png';

export const default_plus_img =
  '//s3.marpple.co/files/u_1165777/2024/4/original/8ff2b4e88ce4d2eabce1474c26a20de0220d2d981.png';

export const handmade_seller_filter_type_id = 67;
