import { each, filter, find, go, identity, map, rest, take, take1 } from 'fxjs/es';
import { MShopAppProductPbOptionTmplS } from '../../S/Tmpl/module/MShopAppProductPbOptionTmplS.js';
import { SpoSpoOptionF } from '../../../../../../Spo/SpoOption/F/Function/module/SpoSpoOptionF.js';
import { renderSelectOptionNone, renderSelectOptionNoneNess } from './renderSelectOptionNone.js';

export const renderSelectOption = ({
  spo_items,
  selected_spo_option_i,
  spo_options,
  spo_option_els,
  disabled,
}) => {
  const remaining_spo_options = SpoSpoOptionF.pGetRemainingSpoOptions({
    selected_spo_option_value_ids: go(
      spo_option_els,
      map((option_el) => parseInt(option_el.value)),
      take(selected_spo_option_i + 1),
      filter(identity),
    ),
    spo_items,
    spo_options,
  });
  go(
    remaining_spo_options,
    take1,
    each((remaining_spo_option) => {
      const remaining_spo_option_el = go(
        spo_option_els,
        find((spo_option_el) => {
          return spo_option_el.dataset.spo_option_id == remaining_spo_option.id;
        }),
      );
      remaining_spo_option_el.outerHTML = MShopAppProductPbOptionTmplS.selectOption(
        remaining_spo_option,
        disabled,
      );
    }),
  );
  go(remaining_spo_options, rest, (rest_remaining_spo_options) =>
    renderSelectOptionNone({ spo_options: rest_remaining_spo_options, spo_option_els, disabled }),
  );
};

export const renderSelectOptionNess = ({ spo_items, selected_spo_option_i, spo_options, spo_option_els }) => {
  const remaining_spo_options = SpoSpoOptionF.pGetRemainingSpoOptions({
    selected_spo_option_value_ids: go(
      spo_option_els,
      map((option_el) => parseInt(option_el.value)),
      take(selected_spo_option_i + 1),
      filter(identity),
    ),
    spo_items,
    spo_options,
  });
  go(
    remaining_spo_options,
    take1,
    each((remaining_spo_option) => {
      const remaining_spo_option_el = go(
        spo_option_els,
        find((spo_option_el) => {
          return spo_option_el.dataset.spo_option_id == remaining_spo_option.id;
        }),
      );
      remaining_spo_option_el.outerHTML = MShopAppProductPbOptionTmplS.selectOptionNess(remaining_spo_option);
    }),
  );
  go(remaining_spo_options, rest, (rest_remaining_spo_options) =>
    renderSelectOptionNoneNess({ spo_options: rest_remaining_spo_options, spo_option_els }),
  );
};
