import { OrderOrderProcessConstantS } from '../../../../../Order/OrderProcess/S/Constant/module/OrderOrderProcessConstantS.js';

/*
 * item_order.order_process_step_id
 * up_c_s.quantity
 * return number
 * */
export const pMakeQuantity = (order_process_step_id, quantity) => {
  if (!Number.isFinite(quantity)) throw new Error('quantity is not number');
  return order_process_step_id === OrderOrderProcessConstantS.CANCEL_STEP.CANCELED ? 0 : quantity;
};
