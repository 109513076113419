import axios from 'axios';
import { requestAnimFrame } from 'chart.js/helpers';
import {
  $addClass,
  $append,
  $appendTo,
  $attr,
  $closest,
  $data,
  $delegate,
  $el,
  $els,
  $find,
  $findAll,
  $hasClass,
  $insertAfter,
  $insertBefore,
  $outerHeight,
  $prependTo,
  $qs,
  $remove,
  $removeClass,
  $replaceWith,
  $setAttr,
  $setCss,
  $setHTML,
  $setScrollTop,
  $setText,
  $trigger,
  $val,
} from 'fxdom/es';
import {
  add,
  each,
  filter,
  go,
  head,
  html,
  identity,
  last,
  map,
  reduce,
  sel,
  sortByDesc,
  strMap,
  throttle,
} from 'fxjs/es';
import { Confirm } from '../../../../../../../services/MarppleShop/renderApp/components/cells/Confirm/Confirm.ts';
import { ptr } from '../../../../../../../services/MarppleShop/shared/util/index.ts';
import { preventEscape } from '../../../../../../../services/MarppleShop/shared/util/preventEscape.ts';
import { pushLoginStack } from '../../../../../../Creator/Login/F/fs.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { makeApiUrl } from '../../../../../../Util/S/Function/util.js';
import { MShopShareFramePopUpF } from '../../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppCommunityMoCommentEditorMuiF } from '../../../MoCommentEditor/F/Mui/module/MShopAppCommunityMoCommentEditorMuiF.js';
import { MShopAppCommunityMoDetailMuiF } from '../../../MoDetail/F/Mui/module/MShopAppCommunityMoDetailMuiF.js';
import { MShopAppCommunityConstantS } from '../../../S/Constant/module/MShopAppCommunityConstantS.js';
import { photoLeft } from '../../S/Tmpl/detail.js';
import { MShopAppCommunityDetailTmplS } from '../../S/Tmpl/module/MShopAppCommunityDetailTmplS.js';
import { nessReviewUser, nessRightReview } from '../../S/Tmpl/nessDetail.js';

export const androidIncreaseCommentCount = (tab_el) => {
  if (MShopUtilF.isCreatorAndroidApp()) {
    $setText(
      $findAll('.article-detail-comments__item', tab_el).length,
      $find('.article-detail-comments__count', tab_el),
    );
  }
};

export const postComment = throttle(async (e) => {
  try {
    const article_detail_el = $find('.article-detail', e.delegateTarget);
    const is_store = $attr('is_store', article_detail_el) == 'true';
    const store_id = $attr('data-store_id', article_detail_el);
    const article_id = $attr('data-article_id', article_detail_el);
    const body = $val($find('.article-comment-write__textarea', e.delegateTarget));
    const article = $data(article_detail_el);
    const is_mobile = MShopUtilF.isMobile();

    if (!box.sel('is_user->id')) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else if (box.sel('store_url')) {
          location.href = `${box.sel('store_url')}/login?url=${box.sel(
            'store_url',
          )}/community?a_id=${article_id}`;
        } else {
          location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
        }
      }
      return;
    }

    if (!body || !body.length) return MShopUtilF.popToastMsg(T('community::내용을 입력해 주세요'), 'error');
    if ($find('.article-detail-comments__more', article_detail_el)) await appendGetComments(e);

    const post_comment_url = makeApiUrl(`/@api/stores/:store_id/articles/:article_id/comment`, {
      store_id,
      article_id,
    });

    return go(
      $.post(post_comment_url, {
        body,
      }),
      (data) => {
        if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');

        $append(
          $el(
            MShopAppCommunityDetailTmplS.commentItem({
              comment: data.comment,
              is_owner: true,
              is_store,
              has_store: sel('_.user._.store.id', data.comment),
              is_store_owner: sel('_.store.user_id', article) == box.sel('is_user->id'),
            }),
          ),
          $find('.article-detail-comments__list', e.delegateTarget),
        ).scrollIntoView();

        MShopUtilF.popToastMsg(T('mshop::댓글이 등록되었습니다.'), 'confirm');

        const textarea_el = $find('.article-comment-write__textarea', e.delegateTarget);
        /* article-detail-comments__count 클래스는 i18n에 존재 */
        $setText(data.count, $find('.article-detail-comments__count', e.delegateTarget));
        androidIncreaseCommentCount(e.delegateTarget);
        textarea_el.value = '';

        $setCss({ height: 'auto' }, textarea_el);
        $setCss({ height: textarea_el.scrollHeight + 2 }, textarea_el);

        if (is_mobile) {
          $setScrollTop($outerHeight(e.delegateTarget) + 10000, $qs('html'));
        } else {
          $setScrollTop(
            e.delegateTarget.scrollHeight + 10000,
            $find('.article-detail-cont__scroll', e.delegateTarget),
          );
        }
      },
    );
  } catch (_e) {
    MShopUtilF.popToastMsg(T('community::실패'), 'error');
  }
}, 1000);

export const articleLikeInProductReview = async (e) => {
  // 커뮤니티 글, 리뷰 글 좋아요
  try {
    const is_like = $attr('is_like', e.currentTarget) == 'true';
    const article_detail_el = $find('.article-detail', e.delegateTarget);
    const store_id = $attr('data-store_id', article_detail_el);
    const article_id = $attr('data-article_id', article_detail_el);

    if (!box.sel('is_user->id')) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else if (box.sel('store_url')) {
          location.href = `${box.sel('store_url')}/login?url=${box.sel(
            'store_url',
          )}/community?a_id=${article_id}`;
        } else {
          location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
        }
      }
      return;
    }

    const post_url = makeApiUrl(`/@api/stores/:store_id/like`, {
      store_id,
    });

    return go(
      $.post(post_url, {
        is_like: !is_like,
        attached_id: article_id,
        attached_type: 'articles',
      }),
      (data) => {
        if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');

        if (data.like.is_hidden) {
          $setAttr({ is_like: false }, e.currentTarget);
        } else {
          $setAttr({ is_like: true }, e.currentTarget);
        }
        $setAttr({ is_like: !data.like.is_hidden }, e.currentTarget);
        $setText(data.count, e.currentTarget);
      },
    );
  } catch (_e) {}
};

export const appendGetComments = async (e) => {
  const article_detail_el = $find('.article-detail', e.delegateTarget);
  const store_id = $attr('data-store_id', article_detail_el);
  const article_id = $attr('data-article_id', article_detail_el);
  const is_store = $attr('is_store', article_detail_el) == 'true';
  const article = $data(article_detail_el);

  return go(
    axios.get(`/@api/stores/${store_id}/articles/${article_id}/comments`, {
      params: { offset: MShopAppCommunityConstantS.COMMENT_LIMIT_OFFSET },
    }),
    ({ data }) => {
      if (!data || !data.length) return MShopUtilF.popToastMsg(T('community::실패'), 'error');

      return go(
        data,
        strMap((comment) =>
          MShopAppCommunityDetailTmplS.commentItem({
            comment,
            is_owner: comment.user_id == box.sel('is_user->id'),
            has_store: sel('_.store.user_id', article) == comment.user_id ? sel('_.store', article) : null,
            is_store_owner: sel('_.store.user_id', article) == box.sel('is_user->id'),
            is_store,
          }),
        ),
        $els,
        each((el) => {
          $append(el, $find('.article-detail-comments__list', article_detail_el));
        }),
        () => {
          $remove($find('.article-detail-comments__more', article_detail_el));
        },
      );
    },
  );
};

export const deleteArticle = async (e) => {
  if (!box.sel('is_user->id')) {
    return MShopUtilF.popToastMsg(ET('로그인 후 이용해 주세요.'), 'error');
  }

  if (
    !(await MShopShareFramePopUpF.confirm({
      title: ET('review::remove'),
      body: ET('review::remove_desc'),
      ok: ET('ness::confirm::ok'),
      cancel: ET('ness::confirm::cancel'),
    }))
  ) {
    return;
  }

  const article_id = $attr('item_id', e.currentTarget);
  const store_id = $attr('store_id', e.currentTarget);

  return go(
    axios.delete(`/@api/stores/${store_id}/article/delete`, { data: { article_id } }),
    async ({ data }) => {
      if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');
      $.frame.extend_state(void 0, null, window.location.pathname, 'replace');
      location.reload();
    },
  );
};

export const deleteComment = async (e) => {
  try {
    if (!box.sel('is_user->id')) return MShopUtilF.popToastMsg(ET('로그인 후 이용해 주세요.'), 'error');
    if (
      !(await MShopShareFramePopUpF.confirm({
        title: ET('review::remove_comment'),
        body: ET('review::remove_comment_desc'),
        ok: ET('ness::confirm::ok'),
        cancel: ET('ness::confirm::cancel'),
      }))
    ) {
      return;
    }

    const article_detail_el = $find('.article-detail', e.delegateTarget);
    const store_id = $attr('data-store_id', article_detail_el);
    const comment_el = $closest('.article-detail-comments__item', e.currentTarget);
    const comment_id = $attr('data-comment_id', comment_el);

    return go(
      axios.delete(`/@api/stores/${store_id}/comment/delete`, {
        data: {
          comment_id,
        },
      }),
      ({ data: { count, result } }) => {
        if (!result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');

        $setText(count, $find('.article-detail-comments__count', e.delegateTarget));
        $remove(comment_el);
        androidIncreaseCommentCount(e.delegateTarget);
      },
    );
  } catch (_e) {
    MShopUtilF.popToastMsg(T('community::실패'), 'error');
  }
};

export const keyDownTextarea = (e) => {
  if (e.keyCode == 13) return e.originalEvent.preventDefault();
};

export const keyUpTextarea = (e) => {
  if (e.keyCode == 13) {
    $trigger('click', $find('.article-comment-write__btn_submit', e.delegateTarget));
    $removeClass('article-comment-write__textarea--on', e.currentTarget);
  }
};

const createValueTracker = (initial) => {
  let prev_value = initial;

  return (new_value) => {
    const result = {
      prev_value,
      new_value,
    };
    prev_value = new_value;
    return result;
  };
};

const valueTracker = createValueTracker(0);

export const inputTextarea =
  ({ review_right_height, header_height, right_photos_height, is_mobile }) =>
  (e) => {
    if ($val(e.currentTarget).length && !$hasClass('article-comment-write__textarea--on', e.currentTarget)) {
      $addClass('article-comment-write__textarea--on', e.currentTarget);
      $addClass(
        'article-comment-write__btn_submit--on',
        $find('.article-comment-write__btn_submit', e.delegateTarget),
      );
    } else if (
      !$val(e.currentTarget).length &&
      $hasClass('article-comment-write__textarea--on', e.currentTarget)
    ) {
      $removeClass('article-comment-write__textarea--on', e.currentTarget);
      $removeClass(
        'article-comment-write__btn_submit--on',
        $find('.article-comment-write__btn_submit', e.delegateTarget),
      );
    }

    if (!is_mobile) {
      /* 현재 textarea 높이에 따라 detail-right 스크롤 영역 높이 조정 */
      const comment_write_height = $qs('.article-comment-write').clientHeight;
      const { prev_value, new_value } = valueTracker(comment_write_height);

      if (prev_value !== new_value) {
        const review_right_scroll_height = ptr(
          review_right_height - header_height - right_photos_height - comment_write_height,
          14,
        );

        $setCss({ height: review_right_scroll_height }, $qs('.article-detail-right__scroll'));
      }
    }

    $setCss({ height: 'auto' }, e.currentTarget);
    $setCss({ height: e.currentTarget.scrollHeight }, e.currentTarget);
    $setCss({ 'overflow-y': e.currentTarget.scrollHeight > 80 ? 'auto' : 'hidden' }, e.currentTarget);
  };

export const commentLike = async (e) => {
  try {
    const is_like = $attr('is_like', e.currentTarget) == 'true';
    const article_detail_el = $find('.article-detail', e.delegateTarget);
    const store_id = $attr('data-store_id', article_detail_el);
    const comment_el = $closest('.article-detail-comments__item', e.currentTarget);
    const comment_id = $attr('data-comment_id', comment_el);
    const article_id = $attr('data-article_id', article_detail_el);

    if (!box.sel('is_user->id')) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else if (box.sel('store_url')) {
          location.href = `${box.sel('store_url')}/login?url=${box.sel(
            'store_url',
          )}/community?a_id=${article_id}`;
        } else {
          location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
        }
      }
      return;
    }

    return go(
      axios.post(`/@api/stores/${store_id}/like`, {
        is_like: !is_like,
        attached_id: comment_id,
        attached_type: 'comments',
      }),
      ({ data: { result, count } }) => {
        if (!result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');
        $setAttr({ is_like: !is_like }, e.currentTarget);
        $setText(count, $find('.article-detail-comments__btn_like', comment_el));
      },
    );
  } catch (_e) {
    MShopUtilF.popToastMsg(T('community::실패'), 'error');
  }
};

export const commentMore = async (e) => {
  try {
    await appendGetComments(e);
  } catch (_e) {
    MShopUtilF.popToastMsg(T('community::실패'), 'error');
  }
};

export const openMobilePhoto = ({ photos }) => {
  try {
    const is_mobile = MShopUtilF.isMobile();
    if (!is_mobile || !photos || !photos.length) return;

    MuiF.openFrame(MShopAppCommunityMoDetailMuiF.frame, async (f, p, [t]) => {
      t.makeData = () => {
        return { photos };
      };
      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
    });
  } catch (err) {
    $.alert(T('community::불러오기 실패 다시 클릭해 주세요.'));
  }
};

export const openCommentEditor = async ({ store_id, article }) => {
  return new Promise((rs) => {
    try {
      const is_mobile = MShopUtilF.isMobile();
      if (!is_mobile) return;

      MuiF.openFrame(MShopAppCommunityMoCommentEditorMuiF.frame, async (f, p, [t]) => {
        t.makeData = () => {
          return { store_id, article };
        };
        f.always_remove = true;
        f.hide_frame_button_position = 'RIGHT';
        f.hide_frame_button_type = 'X';

        t.removing = (el, v) => {
          if (!v) return rs();
          rs(v);
        };
      });
    } catch (err) {
      rs({ result: false });
    }
  });
};

export const changeTab = ({ is_list, article_detail_photo_list_el, article_detail_el }) => {
  const article_detail_tab_el = $find('.article-detail__tab', article_detail_el);
  $setHTML(MShopAppCommunityDetailTmplS.reviewDetailTabIcon(is_list), article_detail_tab_el);
  $setAttr({ 'data-is_list': is_list }, article_detail_el);
  $setAttr({ 'data-is_list': is_list }, article_detail_photo_list_el);
};

export const resetDetailEl = async ({ article_detail_url, is_mobile, is_ness, article_detail_el }) => {
  const {
    data: { result, article },
  } = await axios.get(article_detail_url);

  if (!result) {
    location.replace(location.pathname);
    await Promise.reject(new Error('no data'));
  }

  const new_article_detail_el = $el(
    MShopAppCommunityDetailTmplS.nessPhotoReview({
      article,
      store_id: article.store_id,
      login_user: box.sel('is_user'),
      store_user_id: sel('_.store.user_id', article),
      is_mobile,
      is_android: MShopUtilF.isCreatorAndroidApp(),
      is_ness,
      is_product_review: true,
      is_list: false,
    }),
  );

  /* article 상세 내용 변경 */
  $replaceWith(new_article_detail_el, article_detail_el);

  return { new_article_detail_el };
};

export const resetDetailLeftRight = async ({
  article_detail_url,
  is_mobile,
  article_right_el,
  article_left_el,
}) => {
  const {
    data: { result, article },
  } = await axios.get(article_detail_url);

  if (!result) {
    location.replace(location.pathname);
    await Promise.reject(new Error('no data'));
  }

  $setHTML(
    `${photoLeft({ photos: sel('_.review.files', article) })}
        ${
          is_mobile ? html` <div class="article-detail__left-user">${nessReviewUser({ article })}</div> ` : ''
        }`,
    article_left_el,
  );

  $setHTML(
    ` ${nessRightReview({
      article,
      login_user: box.sel('is_user'),
      store_user_id: sel('_.store.user_id', article),
      is_mobile,
      photos: sel('_.review.files', article),
      is_product_review: true,
    })}`,
    article_right_el,
  );
};

export const articleDetailSwiperInit = (tab_el) => {
  requestAnimFrame(() => {
    const swiper = new Swiper($find('.swiper-container', tab_el), {
      slidesPerView: MShopUtilF.isMobile() ? 1 : 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
    });

    $setCss({ opacity: 1 }, $find('.article-detail__photos', tab_el));

    swiper.on('slideChange', () => {
      const idx = swiper.activeIndex;
      go($qs('.article-detail-review__right-photo.active'), $removeClass('active'));
      go($qs(`.article-detail-review__right-photo[data-idx="${idx}"]`), $addClass('active'));
    });
  });
};

export const articleDetailSwiperInitNess = (tab_el) => {
  requestAnimFrame(() => {
    const swiper = new Swiper($find('.swiper-container', tab_el), {
      slidesPerView: MShopUtilF.isMobile() ? 1 : 'auto',
      spaceBetween: 8,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
    });

    $setCss({ opacity: 1 }, $find('.article-detail__left', tab_el));

    swiper.on('slideChange', () => {
      const idx = swiper.activeIndex;
      go($qs('.article-detail-review__right-photo.active'), $removeClass('active'));
      go($qs(`.article-detail-review__right-photo[data-idx="${idx}"]`), $addClass('active'));
    });
  });
};

const initPhotosButton = (container_el) => {
  const is_last_prev = container_el.scrollLeft === 0;
  const is_last_next = container_el.scrollLeft + container_el.clientWidth === container_el.scrollWidth;

  const prev_button = $find('.article-detail__horizontal-photos-button.prev', $qs('body'));
  if (is_last_prev) {
    $addClass('disabled', prev_button);
    go(
      container_el,
      $findAll('.article-detail__horizontal-photos__photo'),
      head,
      $setAttr({
        is_last_prev: true,
      }),
    );
  } else {
    $removeClass('disabled', prev_button);
  }

  const next_button = $find('.article-detail__horizontal-photos-button.next', $qs('body'));
  if (is_last_next) {
    $addClass('disabled', next_button);
    go(
      container_el,
      $findAll('.article-detail__horizontal-photos__photo'),
      last,
      $setAttr({
        is_last_next: true,
      }),
    );
  } else {
    $removeClass('disabled', next_button);
  }
};

const photosButtonHandler = (container_el) => async (e) => {
  if (e.currentTarget.dataset.is_fetch === 'true') {
    return;
  }

  const is_prev = $hasClass('prev', e.currentTarget);
  const prev_button_el = $find('.article-detail__horizontal-photos-button.prev', $qs('body'));
  const next_button_el = $find('.article-detail__horizontal-photos-button.next', $qs('body'));
  const is_not_full_item =
    (!is_prev && $hasClass('disabled', next_button_el)) || (is_prev && $hasClass('disabled', prev_button_el));

  if (is_not_full_item) {
    return;
  }

  await container_el.scrollBy({ left: is_prev ? -780 : 780, behavior: 'smooth' });

  const is_next_scroll_end =
    container_el.scrollWidth - (container_el.scrollLeft + container_el.clientWidth) <= 780;

  const is_prev_scroll_end = container_el.scrollLeft <= 780;

  if (is_prev) {
    $removeClass('disabled', next_button_el);

    if (is_prev_scroll_end) {
      const is_last_prev = go(
        container_el,
        $find('.article-detail__horizontal-photos__photo'),
        $attr('is_last_prev'),
      );
      is_last_prev && $addClass('disabled', prev_button_el);
    }
  } else {
    $removeClass('disabled', prev_button_el);

    if (is_next_scroll_end) {
      const is_last_next = go(
        container_el,
        $findAll('.article-detail__horizontal-photos__photo'),
        last,
        $attr('is_last_next'),
      );
      is_last_next && $addClass('disabled', next_button_el);
    }
  }
};
const enabledHorizontalButtons = (button_els) => {
  each((button) => {
    button.dataset.is_fetch = false;
  }, button_els);
};

const disabledHorizontalButtons = (button_els) => {
  each((button) => {
    button.dataset.is_fetch = true;
  }, button_els);
};

/**
 * @typedef {Object} InfiniteCursorReturnType
 * @property {string} has_next - 다음 요소 존재 여부
 * @property {any[]} results - 응답 데이터
 */

/**
 * @typedef {Object} InfiniteCursorParamsType
 * @property {string} api_url
 * @property {number} limit
 * @prop {number|string} [cursor]
 * @prop {'next'|'prev'} [direction] - 정방향 혹은 역방향 선택
 * @prop {boolean} [is_first] - 첫 커서를 지정하고 싶은 경우 넣어주어야 한다.
 */

/**
 * @callback InfiniteCursorApiFunc
 * @param {InfiniteCursorParamsType} params_obj
 * @return {InfiniteCursorReturnType}
 */

/**
 * - 우선 ness product review에서만 쓰임
 * - horizontal scroll 중간 지점부터 양방향 무한스크롤이 필요한 경우 사용
 * - margin 같은 스타일링은 따로 해주지 않음
 * - query로 cursor, direction(무한스크롤 진행 방향)을 받고, { has_next, results } 객체를 반환하는 api를 넣어야한다.
 * - TODO 범용적으로 쓰일 것 같으면 추상화 하기...
 *
 * @param {HTMLElement} container_el
 * @param {string} api_url
 * @param {number} limit
 * @param {string} html_item
 * @param {InfiniteCursorApiFunc} infiniteCursorFunc
 * @param {Object} [observer_options] - intersection observer option
 * @param {number|string} [first_cursor]
 * @param {number} [first_item_offset] - 첫번째 load 후 item offset 조정
 * @return void
 */
export async function initCursorInfiniteScroll({
  container_el = $qs('body'),
  infinite_cursor_api,
  limit,
  html_item,
  infiniteCursorFunc,
  observer_options,
  first_cursor,
  first_item_offset,
}) {
  // 기존에 존재하는 요소를 비워주고 시작
  $setHTML('', container_el);

  const prev_observed_el = go(
    `<div style="width: 28px; height: 100%; flex-shrink: 0" class="infinite-prev-observed"></div>`,
    $el,
    $prependTo(container_el),
  );
  const next_observed_el = go(
    `<div style="width: 28px; height: 100%; flex-shrink: 0" class="infinite-next-observed"></div>`,
    $el,
    $appendTo(container_el),
  );

  const root_margin = 780;
  const intersection_observer_options = observer_options || {
    root: container_el,
    rootMargin: `${root_margin}px`,
    threshold: [0],
  };

  let prev_cursor = first_cursor;
  let next_cursor = first_cursor;
  let is_first = true;
  const first_process_check_arr = [];

  const toFragment = (els) => {
    return reduce((frag, el) => (frag.appendChild(el), frag), document.createDocumentFragment(), els);
  };

  const isPairFirstProcess = (arr) => {
    if (arr.length === 1) {
      first_process_check_arr.push(true);
      return true;
    }

    if (arr.length <= 2) {
      first_process_check_arr.push(true);
    }

    return false;
  };

  const infiniteProcess = async function infiniteProcess({
    entry,
    observer,
    observed_el,
    cursor,
    direction,
  }) {
    const button_els = $findAll('.article-detail__horizontal-photos-button', $qs('body'));
    const is_prev = direction === MShopAppCommunityConstantS.INFINITE_DIRECTION.prev;
    const is_next = direction === MShopAppCommunityConstantS.INFINITE_DIRECTION.next;

    if (entry.isIntersecting) {
      disabledHorizontalButtons(button_els);
      // observe 되는 순간 중지
      observer.unobserve(observed_el);

      // (return 형식만 강제해서 getItem으로 추상화?)
      const { results, has_next } = await infiniteCursorFunc({
        api_url: infinite_cursor_api,
        cursor,
        limit,
        direction,
        is_first: is_next ? is_first : false,
      });

      const sorted_results = sortByDesc(({ id }) => id, results);

      const new_els = go(sorted_results, map(html_item), map($el), filter(identity));
      // add element
      go(new_els, toFragment, (new_item_el) => {
        is_next ? $insertBefore(observed_el, new_item_el) : $insertAfter(observed_el, new_item_el);
      });

      // item이 추가된 후 좌측으로 스크롤 했을 때, 강제로 스크롤이 띄워지도록 한다.
      if (is_prev) {
        const item_total_width = go(
          new_els,
          map((el) => {
            return el.clientWidth + 8;
          }),
          reduce(add),
        );

        container_el.scrollBy({ left: item_total_width });
      }

      // prev next observer 모두 처음 실행되어 아이템이 모두 붙은 순간 (onInitialAppend hook으로 추상화?)
      if (isPairFirstProcess(first_process_check_arr)) {
        const first_cursor_el = $find(`[data-cursor_id="${first_cursor}"]`, container_el);
        $addClass('active', first_cursor_el);
        // 첫번째 cursor item으로 스크롤 이동
        container_el.scrollLeft = first_cursor_el.offsetLeft - (first_item_offset || 62);

        // 아이템이 모두 append 된 후 next prev 버튼 disabled 결정
        initPhotosButton(container_el);

        /* next, prev button event */
        const article_detail_el = $qs('.article-detail');

        go(
          article_detail_el,
          $delegate(
            'click',
            '.article-detail__horizontal-photos-button',
            throttle(photosButtonHandler(container_el), 500),
          ),
          $delegate('wheel', '.article-detail__horizontal-photos', (e) => {
            if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
              // 가로 스크롤 방향으로의 스크롤인 경우 이벤트를 취소
              e.originalEvent.preventDefault();
            }
          }),
        );
      }

      is_first = false;
      enabledHorizontalButtons(button_els);

      // item이 없을 경우 observer 중지 (onComplete hook으로 추상화?)
      if (!has_next) {
        if (is_prev) {
          const last_prev_el = head(new_els);

          if (last_prev_el) {
            /* prev의 마지막 element에 표식 부여 */
            $setAttr(
              {
                is_last_prev: true,
              },
              last_prev_el,
            );
          }
        } else {
          const last_next_el = last(new_els);
          if (last_next_el) {
            /* next의 마지막 element에 표식 부여 */
            $setAttr(
              {
                is_last_next: true,
              },
              last_next_el,
            );
          }
        }

        observer.disconnect();
        return;
      }

      // update_cursor
      if (is_next) {
        next_cursor = last(sorted_results).id;
      }
      if (is_prev) {
        prev_cursor = head(sorted_results).id;
      }

      // observer 재가동
      observer.observe(observed_el);
    }
  };

  const next_observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      await infiniteProcess({
        entry,
        observer: next_observer,
        observed_el: next_observed_el,
        cursor: next_cursor,
        direction: MShopAppCommunityConstantS.INFINITE_DIRECTION.next,
      });
    });
  }, intersection_observer_options);
  next_observer.observe(next_observed_el);

  const prev_observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      await infiniteProcess({
        entry,
        observer: prev_observer,
        observed_el: prev_observed_el,
        cursor: prev_cursor,
        direction: MShopAppCommunityConstantS.INFINITE_DIRECTION.prev,
      });
    });
  }, intersection_observer_options);
  prev_observer.observe(prev_observed_el);
}
