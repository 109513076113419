import axios from 'axios';
import { equals, go, match, sel, html } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { EMBED_SERVICE } from './constant.js';

export const marppleProductRegex = /marpple\.shop\/.+\/products\/(\d+)/;
export const nessProductRegex = /essential\.nexon\.com\/.+\/products\/(\d+)/;
export const marppleShopDomainRegex = /^(http:\/\/|https:\/\/)?(www.)?(\w+\.)?marpple.shop/;

export const isMarppleShopDomain = (url) => marppleShopDomainRegex.test(url);

export const isMarppleshopProduct = (url) => marppleProductRegex.test(url);
export const isNessProduct = (url) => nessProductRegex.test(url);

const isServer = () => {
  try {
    return !window;
  } catch (e) {
    return true;
  }
};

const getEmbedHtmlFromServer = (url) => {
  if (isServer()) return '';
  const getEmbedApi = UtilS.makeApiUrl(`/:lang/@api/editor/embed?url=${url}`, {
    lang: T.lang,
  });
  return go(axios.get(getEmbedApi), sel('data.html'), (s) => (s && s.trim()) || '');
};

export const makeOGCard = ({ url, title, description, image, url_format, site_name }) => legacyHtml`
  <div class="og_embed og_card" contenteditable="false">
    <div class="og_image">
      <a href="${url}" target="_blank">
        <img src="${image || ''}" alt="" />
      </a>
    </div>
    <div class="content">
      <p class="og_title">${title || ''}</p>
      <p class="og_description">${description || ''}</p>
      <div class="site">
        <img class="icon" src="${url_format.protocol + '//' + url_format.hostname + '/favicon.ico'}" alt="" />
        <a href="${url}" target="_blank">
          <span class="og_sitename"> ${site_name || url_format.host}</span>
        </a>
      </div>
    </div>
  </div>
`;

export const makeOGsimpleCard = ({
  has_image,
  url,
  title,
  description,
  image,
  url_format,
  site_name,
  site_title,
}) =>
  legacyHtml`
    <div class="og_embed og_simple_card" contenteditable="false">
      <div class="og_image" style="${!has_image ? 'display:none;' : ''}">
        <a href="${url}" target="_blank"> ${has_image ? `<img src="${image || ''}" alt="">` : ''} </a>
      </div>
      <div class="content">
        <p class="og_title">${title || ''}</p>
        <p class="og_description">${description || ''}</p>
        <div class="site">
          <img
            class="icon"
            src="${url_format.protocol + '//' + url_format.hostname + '/favicon.ico'}"
            alt=""
          />
          <a href="${url}" target="_blank">
            <span class="og_sitename"> ${site_name || url_format.host}</span>
          </a>
        </div>
      </div>
    </div>
  `;

export const getFBEmbedHtml = (url) => `<div class="fb-post" data-href="${url}"></div>`;

export const getEmbedService = (url) => {
  return match(url)
    .case(isRenewalMarppleEmbed)(() => EMBED_SERVICE.renewal_marpple)
    .case(isPopupStoreEmbed)(() => EMBED_SERVICE.popup_store_products)
    .case((url) => /youtube\.com|youtu.be/.test(url))(() => EMBED_SERVICE.youtube)
    .case((url) => /vimeo\.com/.test(url))(() => EMBED_SERVICE.vimeo)
    .case((url) => /instagram\.com/.test(url))(() => EMBED_SERVICE.instagram)
    .case((url) => /x\.com/.test(url))(() => EMBED_SERVICE.twitter)
    .case((url) => /facebook\.com/.test(url))(() => EMBED_SERVICE.facebook)
    .case(isMarppleshopProduct)(() => EMBED_SERVICE.marpple)
    .case(isNessProduct)(() => EMBED_SERVICE.ness)
    .else(() => '');
};

export const getTwitterEmbedHtml = async (url) => {
  try {
    return await go(
      axios.get(`https://publish.twitter.com/oembed?omit_script=true&url=${url}`),
      sel('data.html'),
    );
  } catch (err) {
    return getEmbedHtmlFromServer(url);
  }
};

const makeYoutubeEmbedUrl = (url = '') => {
  const embed_url = 'https://www.youtube.com/embed/';
  const embed_regexp = /embed\/+/;
  const match_regexp = /watch\?v=([^&]+)/;
  const short_regexp = /shorts\/([^&|?]+)/;

  if (embed_regexp.test(url)) {
    return url;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp);
    return embed_url + youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp);
    return embed_url + youtube_id;
  }
  return (
    embed_url +
    url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '')
  );
};

export const replaceBasicEmbedUrl = (url, service) => {
  if (service !== 'youtube' && service !== 'vimeo') return url;
  if (service == 'youtube') return makeYoutubeEmbedUrl(url);

  // vimeo
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const parseUrl = regExp.exec(url);
  return `https://player.vimeo.com/video/${parseUrl[5]}`;
};

export const isBasicIframe = (src, service) => ['youtube', 'vimeo'].includes(service || getEmbedService(src));

export const getBasicEmbedHtml = (url) => legacyHtml`
  <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;">
    <iframe
      src="${url || ''}"
      style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;"
      frameborder="0"
      scrolling="no"
      allow="encrypted-media accelerometer; gyroscope; picture-in-picture"
    >
    </iframe>
  </div>
`;

export const getEmbedHtml = async (
  url,
  service,
  {
    only_content,
    handleOGembed = getEmbedHtmlFromServer,
    handleMarppleEmbed = getEmbedHtmlFromServer,
    handleInstaEmbed = getEmbedHtmlFromServer,
    handleNessEmbed = getEmbedHtmlFromServer,
  } = {},
) => {
  if (!url || !url.trim().length) return '';
  try {
    service = service || getEmbedService(url);
    const content_html = await match(service)
      .case((s) => isBasicIframe(null, s))(() => getBasicEmbedHtml(url))
      .case(equals('facebook'))(() => getFBEmbedHtml(url))
      .case(equals('twitter'))(() => getTwitterEmbedHtml(url))
      .case(equals('instagram'))(() =>
        handleInstaEmbed(url, {
          omitscript: true,
          hidecaption: true,
        }),
      )
      .case(equals('marpple'))(() => handleMarppleEmbed(url))
      .case(equals('ness'))(() => handleNessEmbed(url))
      .else(() => handleOGembed(url));

    if (!content_html) return '';

    const len = url.split(',').length;
    const klass = `${len < 4 ? 'embed-one-row' : ''} ${len < 2 ? 'embed-one-row-mobile' : ''}`;

    return only_content ? content_html : wrapEmbedShow(content_html, service == 'marpple', klass);
  } catch (err) {
    return '';
  }
};

export const wrapEmbedShow = (html_string, is_marpple, klass) =>
  html`
    <div class="embed_show ${is_marpple ? 'marpple_embed_show' : ''} ${klass}" contenteditable="false">
      ${html_string}
    </div>
  `;

export const wrapMarppleEmbed = (html_string) =>
  html` <div class="marpple_embed" contenteditable="false">${html_string}</div> `;

const renewal_marpple_embed_prefix = 'marpple_products::';
const popupstore_embed_prefix = 'popupstore_products::';
export const isRenewalMarppleEmbed = (text) => {
  return text.startsWith(renewal_marpple_embed_prefix);
};

export const parseRenewalMarppleEmbedInner = (text) => {
  return text.replace(renewal_marpple_embed_prefix, '').split(',');
};

export const makeRenewalMarppleEmbedInner = (product_ids) => {
  return renewal_marpple_embed_prefix + product_ids.join(',');
};

export const isPopupStoreEmbed = (text) => {
  return text.startsWith(popupstore_embed_prefix);
};

export const parseRenewalPopupStoreEmbedInner = (text) => {
  const parse = JSON.parse(text.replace(popupstore_embed_prefix, ''));
  return {
    product_ids: parse.ids,
    text: {
      section_title: parse.text.title,
      section_title_en: parse.text.title_en,
      section_title_jp: parse.text.title_jp,
      section_description: parse.text.description,
      section_description_en: parse.text.description_en,
      section_description_jp: parse.text.description_jp,
    },
  };
};

export const makeRenewalPopupStoreEmbedInner = (data) => {
  const _data = {
    ids: data.product_ids,
    text: {
      title: data.text_data.section_title,
      title_en: data.text_data.section_title_en,
      title_jp: data.text_data.section_title_jp,
      description: data.text_data.section_description,
      description_en: data.text_data.section_description_en,
      description_jp: data.text_data.section_description_jp,
    },
  };
  return popupstore_embed_prefix + JSON.stringify(_data);
};
