import { drop, go, html, ifElse, strMap, take } from 'fxjs/es';
import { makeTodayItem } from '../../../Today/S/Tmpl/item.js';
import { makeTodayListItem } from '../../../Today/S/Tmpl/listItem.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

export const today = (title, today_list, is_mobile, is_all_crew) => {
  const post_message_option = {
    screen: {
      type: 'util',
      title: T('mshop::Contents'),
      uri: `/${T.lang}/@/todays_frame`,
    },
  };

  return !today_list.length
    ? ''
    : html`
        <div class="app-home-today">
          ${MShopAppHomeTmplS.homeTitle({
            title,
            sub_title: T('mshop::Contents'),
            link: post_message_option,
          })}

          <div class="home-today-container home-today-item-container">
            <div class="home-today-list">
              ${is_all_crew
                ? html`<div class="home-today-item" data-show="${!is_mobile}">
                      <div class="home-today-item_padding">
                        ${makeTodayItem(today_list[0], {
                          not_lazy: true,
                        })}
                      </div>
                    </div>
                    <div class="home-today-item" data-show="${!is_mobile}">
                      <div class="home-today-item_padding">
                        ${makeTodayItem(today_list[1], {
                          not_lazy: true,
                        })}
                      </div>
                    </div>
                    <div class="home-today-items" data-show="${is_mobile}">
                      ${go(
                        today_list,
                        take(3),
                        strMap((t) => makeTodayItem(t, { not_lazy: true })),
                      )}
                    </div>
                    <div class="home-today-list-items">
                      ${go(
                        today_list,
                        ifElse(() => is_mobile, drop(3), drop(2)),
                        ifElse(() => is_mobile, take(2), take(4)),
                        strMap((t) => makeTodayListItem(t, { not_lazy: true })),
                      )}
                    </div>`
                : html`<div class="home-today-item" data-show="${!is_mobile}">
                      <div class="home-today-item_padding">
                        ${makeTodayItem(today_list[0], {
                          not_lazy: true,
                          without_category: true,
                        })}
                      </div>
                    </div>

                    <div
                      class="home-today-item"
                      data-show="${!is_mobile}"
                      style="visibility: ${today_list[1] ? 'visible' : 'hidden'}"
                    >
                      <div class="home-today-item_padding">
                        ${today_list[1] &&
                        makeTodayItem(today_list[1], {
                          not_lazy: true,
                          without_category: true,
                        })}
                      </div>
                    </div>

                    <div
                      class="home-today-item"
                      data-show="${!is_mobile}"
                      style="visibility: ${today_list[2] ? 'visible' : 'hidden'}"
                    >
                      <div class="home-today-item_padding">
                        ${today_list[2] &&
                        makeTodayItem(today_list[2], {
                          not_lazy: true,
                          without_category: true,
                        })}
                      </div>
                    </div>

                    <div class="home-today-items" data-show="${is_mobile}">
                      ${go(
                        today_list,
                        take(3),
                        strMap((t) => makeTodayItem(t, { not_lazy: true, without_category: true })),
                      )}
                    </div>`}
            </div>
            <div class="home-today-features">
              <a href="/${T.lang}/@/todays_frame" data-show="${!!is_mobile}" class="btn home-today-see-more"
                >${T('mshop::더보기')}</a
              >
            </div>
          </div>
        </div>
      `;
};
