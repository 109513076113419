import { html, sel } from 'fxjs/es';
import { GoodsTypeConstantS } from '../../../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { GoodsTypeS } from '../../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { isNeedPreview } from '../../../../../../Maker/S/categorize.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductPbOptionTmplS } from '../../../../Product/PbOption/S/Tmpl/module/MShopAppProductPbOptionTmplS.js';
import { MShopAppProductDetailCustomSelectOptionConstantS } from '../../../CustomSelectOption/S/Constant/module/MShopAppProductDetailCustomSelectOptionConstantS.js';
import { isMultiOption } from '../../../S/fs.js';
import {
  makeProductQuantityHtmlNess,
  makeSizeOptionsHtml,
  makeTotalPriceHtml,
  makeTotalPriceInMobileFormHtml,
} from '../../../S/Tmpl/partials/productDetailForm.js';
import { HOTEL_KEYRING_ID, MULTI_OPTION_WRAP_KLASS } from '../../../S/constant.js';

const pbg = (args) => {
  if (UtilS.isNessApp()) {
    return pbgNess(args);
  }

  const { product_detail, spo, stores_delivery_company } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  const is_multi_option = isMultiOption(product_detail);

  return html`
    <div class="pd-form-modal__options">
      ${MShopAppProductDetailCustomSelectOptionConstantS.set_1.spo_ids.includes(spo.id)
        ? html`<div class="pd-form__pb_custom_options">
            ${MShopAppProductPbOptionTmplS.customOptionTmpl(spo)}
          </div>`
        : html`<div class="pd-form__pb_options">${MShopAppProductPbOptionTmplS.optionTmpl(spo)}</div>`}
      ${UtilS.htmlIf2(is_multi_option, () => html`<div class="${MULTI_OPTION_WRAP_KLASS}"></div>`)}
      ${makeTotalPriceInMobileFormHtml(
        { product_detail },
        {
          max_purchase_per_user,
          possible_quantity,
          is_hide: stores_delivery_company?.is_separate_shipping,
          is_multi_option,
        },
      )}
    </div>
  `;
};

const pbgNess = (args) => {
  const { product_detail, spo, stores_delivery_company } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  return html`
    ${MShopAppProductPbOptionTmplS.optionTmpl(spo)}
    ${makeProductQuantityHtmlNess(
      possible_quantity,
      max_purchase_per_user,
      stores_delivery_company?.is_separate_shipping,
    )}
    ${makeTotalPriceHtml({ product_detail })}
  `;
};

const tpl = (args) => {
  if (UtilS.isNessApp()) {
    return tplNess(args);
  }

  const { product_detail, spo, stores_delivery_company } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  const is_multi_option = isMultiOption(product_detail);

  return html`
    <div class="pd-form-modal__options">
      ${MShopAppProductDetailCustomSelectOptionConstantS.set_1.spo_ids.includes(spo.id)
        ? html`<div class="pd-form__pb_custom_options">
            ${MShopAppProductPbOptionTmplS.customOptionTmpl(spo)}
          </div>`
        : html`<div class="pd-form__pb_options">${MShopAppProductPbOptionTmplS.optionTmpl(spo)}</div>`}
      ${UtilS.htmlIf2(is_multi_option, () => html`<div class="${MULTI_OPTION_WRAP_KLASS}"></div>`)}
      ${makeTotalPriceInMobileFormHtml(
        { product_detail },
        {
          max_purchase_per_user,
          possible_quantity,
          is_hide: stores_delivery_company?.is_separate_shipping,
          is_multi_option,
        },
      )}
    </div>
  `;
};

const tplNess = (args) => {
  const { product_detail, spo } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  return html`
    ${MShopAppProductPbOptionTmplS.optionTmpl(spo)}
    ${makeProductQuantityHtmlNess(possible_quantity, max_purchase_per_user)}
    ${makeTotalPriceHtml({ product_detail })}
  `;
};

const pod = (args) => {
  if (UtilS.isNessApp()) {
    return podNess(args);
  }

  const {
    product_detail,
    selected_bpc,
    selected_device,
    devices = [],
    has_color_options = false,
    stores_delivery_company,
  } = args;
  const base_product = sel('_.base_product', product_detail);
  const goods_type_id = sel('_.stores_product.goods_type_id', product_detail);
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  const is_sticker = product_detail.is_sticker;
  const is_hotel_keyring = base_product.id == HOTEL_KEYRING_ID;
  const use_size_option =
    GoodsTypeS.isMps(goods_type_id) && !devices.length && (!is_sticker || is_hotel_keyring);
  const { is_composite_publish } = product_detail._.base_product;

  return html`
    ${UtilS.htmlIf2(
      selected_device && !is_composite_publish,
      () => html`
        <div class="pd-field device">
          <div class="pd-field__label">${ET('product_detail::options::device')}</div>
          <div class="pd-field__text">${selected_device.name}</div>
        </div>
      `,
    )}
    ${UtilS.htmlIf2(
      has_color_options && !isNeedPreview(base_product),
      () => html`
        <div class="pd-field color">
          <div class="pd-field__label">${ET('product_detail::options::color')}</div>
          <div class="pd-field__text">
            <div class="color_chip" style="background: ${selected_bpc.color_code}"></div>
            ${selected_bpc['name' + _en]}
          </div>
        </div>
      `,
    )}
    ${makeSizeOptionsHtml({ use_size_option, selected_bpc })}
    ${makeTotalPriceInMobileFormHtml(
      { product_detail },
      { max_purchase_per_user, possible_quantity, is_hide: stores_delivery_company?.is_separate_shipping },
    )}
  `;
};

const podNess = (args) => {
  const { product_detail, selected_bpc, selected_device, devices = [], has_color_options = false } = args;
  const base_product = sel('_.base_product', product_detail);
  const goods_type_id = sel('_.stores_product.goods_type_id', product_detail);
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  const is_sticker = product_detail.is_sticker;
  const use_size_option = GoodsTypeS.isMps(goods_type_id) && !devices.length && !is_sticker;
  const { is_composite_publish } = product_detail._.base_product;

  return html`
    ${UtilS.htmlIf2(
      selected_device && !is_composite_publish,
      () => html`
        <div class="pd-field device">
          <div class="pd-field__label">${ET('product_detail::options::device')}</div>
          <div class="pd-field__text">${selected_device.name}</div>
        </div>
      `,
    )}
    ${UtilS.htmlIf2(
      has_color_options && !isNeedPreview(base_product),
      () => html`
        <div class="pd-field color">
          <div class="pd-field__label">${ET('product_detail::options::color')}</div>
          <div class="pd-field__text">${selected_bpc['name' + _en]}</div>
        </div>
      `,
    )}
    ${makeSizeOptionsHtml({ use_size_option, selected_bpc })}
    ${makeProductQuantityHtmlNess(possible_quantity, max_purchase_per_user)}
    ${makeTotalPriceHtml({ product_detail })}
  `;
};

const dtg = (args) => {
  if (UtilS.isNessApp()) {
    return dtgNess(args);
  }

  const { product_detail, spo, stores_delivery_company } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);

  // ${makeProductQuantityHtml(possible_quantity, max_purchase_per_user, true)}

  return html`
    ${MShopAppProductPbOptionTmplS.optionTmpl(spo)}
    ${makeTotalPriceInMobileFormHtml(
      { product_detail, is_dtg: true },
      { max_purchase_per_user, possible_quantity, is_hide: stores_delivery_company?.is_separate_shipping },
    )}
  `;
};

const dtgNess = (args) => {
  const { product_detail, spo } = args;
  const max_purchase_per_user = sel('_.stores_product.max_purchase_per_user', product_detail);
  const possible_quantity = sel('_.stores_product.possible_quantity', product_detail);
  return html`
    ${MShopAppProductPbOptionTmplS.optionTmpl(spo)}
    ${makeProductQuantityHtmlNess(possible_quantity, max_purchase_per_user, true)}
    ${makeTotalPriceHtml({ product_detail })}
  `;
};

export const fn = (args) => {
  switch (sel('_.stores_product.goods_type_id', args.product_detail)) {
    case GoodsTypeConstantS.MPS.id:
      return pod(args);
    case GoodsTypeConstantS.PBG.id:
      return pbg(args);
    case GoodsTypeConstantS.TPL.id:
      return tpl(args);
    case GoodsTypeConstantS.DTG.id:
      return dtg(args);
  }
};
