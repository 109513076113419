import $dataStr from 'fxdom/es/dataStr.js';
import { go, sumBy, compact, html, sel, strMap, last } from 'fxjs/es';
import { GoodsTypeS } from '../../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MpShopProductTmplS } from '../../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { NessOutboundTmplS } from '../../../../../../Ness/Outbound/S/Tmpl/module/NessOutboundTmplS.js';
import { creatorPriceOfProduct } from '../../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopStudioPreDiscountProductS } from '../../../../../Studio/PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppAdultTmplS } from '../../../../Adult/S/Tmpl/module/MShopAppAdultTmplS.js';
import { MShopAppProductItemS } from '../Function/module/MShopAppProductItemS.js';
import { MShopUtilS } from '../../../../../Util/S/Function/module/MShopUtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { CUSTOM_IMAGE_FOLDER_NAME, PRODUCT_BADGE_TYPE } from '../Constant/item.js';
import { makeProductBadge, makeProductBottomBadge } from './badge.js';
import { returnIf } from '../../../../../Util/S/Function/util.js';
import { makeComingSoonHtmlInProductThumbnail } from './coming_soon.js';
import { makeProductPriceHtml } from './price.js';
import { isStoreProductCustom } from '../../../../../../Creator/Product/S/fs.js';

export const makeBpName = (name, title) => {
  if (!name) return '';
  return name + (title ? ` - ${title}` : '');
};

export const item =
  (sub_class = ['v1'], cus_class = '', data_src, is_mobile = false) =>
  (product) => {
    const {
      id,
      need_adult_check,
      thumbnails,
      name: _name = '',
      rank,
      is_custom_hide,
      ordered_at,
      _: {
        spo: { _: { spo_item_stocks = [] } = {} } = {},
        selected_option_group: { title } = {},
        stores_product: {
          custom_level,
          goods_type_id,
          sell_start_at,
          sell_end_at,
          public_at,
          quantity,
          is_quantity_public,
          order_count,
          id: stores_product_id,
          _: {
            stores_products_token_gates = [],
            stores_products_like = {},
            store: { domain_name = '', name: store_name = '', logo_img } = {},
          } = {},
        } = {},
        base_product,
        base_product: { thumbnail_ratio, base_product_color_face_url, name: base_product_name } = {},
        pre_discount_product: { pre_discount_price } = {},
      } = {},
    } = product || { _: {} };

    const component = `app-product-item`;

    const origin_price = creatorPriceOfProduct(product);
    const pre_discount_rate = pre_discount_price
      ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
      : null;

    const is_custom = isStoreProductCustom(custom_level);
    const name = UtilS.trim(_name);
    const bp_name = makeBpName(base_product_name, title) || '';
    const badge = MShopAppProductItemS.setBadge(
      {
        spo_item_stocks,
        sell_start_at,
        sell_end_at,
        public_at,
        quantity,
        order_count,
        ordered_at,
        is_quantity_public,
        is_sptg: !!stores_products_token_gates.length,
        rank: UtilS.isNessApp() ? rank : null,
        pre_discount_rate,
        is_custom,
      },
      false,
    );
    const product_post_msg = {
      screen: {
        type: 'util',
        title: T('mshop::상품상세'),
        uri: `/${T.lang}/${domain_name}/products/${id}`,
      },
    };
    const store_post_msg = {
      screen: {
        type: 'util',
        title: store_name,
        titleImage: logo_img,
        uri: `/${T.lang}/${domain_name}`,
      },
    };
    const cate_item_name = sel('name' + G._en)(base_product?._?.cate_list);

    const texts = {
      review_score: ET('product::review::score'),
      review_count:
        UtilS.isNessApp() || UtilS.isMarppleShopApp()
          ? // plural 이라 warning 뜨는데 무시해도 됨 ..
            ET('ness::review::count', { count: parseInt(product.review_count), lng: 'en' })
          : ET('product::review::count'),
    };

    const has_review = !isNaN(Number(product.review_count)) && product.review_count != 0;

    const isProductSoldOut = () => {
      const is_no_spo_item_stock =
        spo_item_stocks?.length &&
        go(
          spo_item_stocks,
          sumBy(({ quantity }) => quantity),
        ) <= 0;
      const is_no_quantity = quantity > 0 && quantity <= order_count;
      const is_sale_ended = sell_start_at && sell_end_at && new Date(sell_end_at) < new Date();
      return Boolean(is_no_spo_item_stock || is_no_quantity || is_sale_ended);
    };

    const img_alt = go(void 0, () => {
      const _name = name || base_product_name;
      if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
        return `${store_name} ${cate_item_name}, ${_name} 굿즈, 굿즈 판매, 굿즈샵`;
      } else {
        return `${store_name} ${cate_item_name}, ${_name}`;
      }
    });
    const makeThumbnailHtml = () => {
      return need_adult_check
        ? html`<span class="mp-product-img-bg-wrap" style="background-color: #f6f6f6">
            ${MShopAppAdultTmplS.adultItem()}
          </span>`
        : MpShopProductTmplS.thumbnail(
            { thumbnail_ratio, thumbnails, base_product_color_face_url },
            {
              width: 600,
              data_src,
              img_alt,
            },
          );
    };

    return html`
      <div
        class="${component} ${strMap((sc) => `${component}--${sc} `, compact(sub_class))} ${cus_class}"
        data-is_sold_out="${isProductSoldOut(product)}"
        data-is_custom="${is_custom}"
        ${is_custom ? `data-${CUSTOM_IMAGE_FOLDER_NAME}="${last(product?.image_folders ?? [])?.name}"` : ``}
        ${is_custom_hide ? 'style="display:none;"' : ''}
      >
        <div class="${component}__top-info">
          <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
            <div
              class="${component}__img ${badge?.type == PRODUCT_BADGE_TYPE.sold_out
                ? `${component}__img--soldout`
                : ''}"
            >
              ${makeThumbnailHtml()}
            </div>
            ${UtilS.isNessApp() || UtilS.isMarppleShopApp()
              ? NessOutboundTmplS.makeNessProductBadgeTmpl(badge, component, is_mobile)
              : makeProductBadge(badge, component)}

            <!-- only NESS, Coming Soon -->
            ${UtilS.isNessApp() ? makeComingSoonHtmlInProductThumbnail(sell_start_at) : ''}
          </a>

          ${MShopUtilS.returnIf(
            !need_adult_check && !UtilS.isNessApp() && !UtilS.isMarppleShopApp(),
            html`
              <button
                class="${component}__like"
                data-stores_product_id="${stores_product_id}"
                data-already_like="${!!stores_products_like?.id}"
              >
                ${MShopCoreTmplS.heartIcon({
                  is_on: !!stores_products_like?.id,
                  klass: `${component}__heart`,
                  type: 'solid',
                })}
              </button>
            `,
          )}
        </div>
        <div class="${component}__bottom-info">
          ${MShopUtilS.returnIf(
            !need_adult_check && (UtilS.isNessApp() || UtilS.isMarppleShopApp()),
            html`
              <button
                class="${component}__like"
                data-stores_product_id="${stores_product_id}"
                data-already_like="${!!stores_products_like?.id}"
              >
                ${NessOutboundTmplS.nessHeartIcon()}
              </button>
            `,
          )}
          ${MShopUtilS.returnIf(
            rank,
            `
              <a
                href="${store_post_msg.screen.uri}"
                data-post-message="${$dataStr(store_post_msg)}"
                class='${component}__rank'
              >
                <span>${rank}</span>
              </a>
            `,
          )}
          <a
            href="${store_post_msg.screen.uri}"
            data-post-message="${$dataStr(store_post_msg)}"
            class="${component}__store-name"
          >
            ${store_name}
          </a>
          <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
            <div class="${component}__p-name">
              ${GoodsTypeS.isMps(goods_type_id)
                ? (!need_adult_check && name) || bp_name || '&nbsp;'
                : name || '&nbsp;'}
            </div>
            <div class="${component}__category-name">${cate_item_name}</div>
            ${makeProductPriceHtml(product, component)}
          </a>
          <div
            class="${component}__review-info ${returnIf(!has_review, `${component}__review-info--hidden`)}"
          >
            <span class="${component}__review-score">
              <span class="${component}__review-title">${texts.review_score}</span>
              <span class="${component}__review-number ${MShopUtilConstantS.UNICA_KLASS}"
                >${product.review_score || 0}</span
              >
            </span>
            <span class="${component}__review-count">
              <span class="${component}__review-title">${texts.review_count}</span>
              <span class="${component}__review-number ${MShopUtilConstantS.UNICA_KLASS}">
                ${UtilS.commaThousands(product.review_count || 0)}
              </span>
            </span>
          </div>
          ${makeProductBottomBadge(badge, component)}
        </div>
      </div>
    `;
  };
