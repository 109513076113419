import $dataStr from 'fxdom/es/dataStr.js';
import { go, head, html, strMap } from 'fxjs/es';
import { zipWithIndex } from 'fxjs/es/Lazy';
import { CreatorCrewBannerEditorS } from '../../../../../Creator/Crew/BannerEditor/S/Function/module/CreatorCrewBannerEditorS.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { MShopCoreConstantS } from '../../../../Core/S/Constant/module/MShopCoreConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const keyvisualItem = (banner) => {
  const { title, is_mobile, url, navigate, href, target = '_self', banner_background } = banner;
  const img_alt = go(void 0, () => {
    if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
      return `굿즈 추천, 굿즈 디자인, 굿즈 제작, ${title}`;
    } else {
      return title;
    }
  });
  return html`
    <div class="keyvisual__slide swiper-slide" style="background-color: ${banner_background || 'white'}">
      <div class="keyvisual__item" data-fx-json="${$dataStr(banner)}">
        <a
          href="${UtilS.escape(href)}"
          class="keyvisual__anchor"
          target="${target}"
          ${navigate ? `data-post-message="${$dataStr({ navigate })}"` : ''}
        >
          <div class="keyvisual__image-box">
            <img
              class="keyvisual__image"
              src="${UtilImageS.getResizedUrl({
                url,
                format: 'webp',
                ...(is_mobile
                  ? {
                      width: 750,
                    }
                  : {
                      height: 1440,
                    }),
              })}"
              alt="${img_alt}"
            />
          </div>
        </a>
      </div>
    </div>
  `;
};

export const keyvisualTextBox = ({
  is_all_crew,
  title,
  sub_title,
  color,
  navigate,
  href,
  target = '_self',
}) => html` <a
  href="${href}"
  target="${target}"
  ${navigate ? `data-post-message="${$dataStr({ navigate })}"` : ''}
  class="keyvisual__text-box"
  style="color: ${is_all_crew ? '#111' : color};"
>
  ${sub_title ? html` <div class="keyvisual__description">${sub_title}</div>` : ''}
  <div class="keyvisual__title">${CreatorCrewBannerEditorS.replaceBreakLine(title)}</div>
</a>`;

export const kv = ({ banners, is_mobile, is_blur, is_all_crew }) => {
  const banner_first = head(banners);
  return html`
    <div class="keyvisual">
      <div class="keyvisual__swiper swiper-container">
        <div class="keyvisual__wrapper swiper-wrapper">
          ${strMap(
            (banner) =>
              keyvisualItem({
                is_all_crew,
                is_mobile,
                is_blur,
                ...banner,
              }),
            banners,
          )}
        </div>
        ${banners.length ? keyvisualTextBox(banner_first) : ''}
      </div>
      ${is_mobile && banners.length > 1
        ? html` <div
            class="keyvisual__pagination__wrapper"
            data-item_color="${banner_first.banner_theme || 'black'}"
          >
            <div class="keyvisual__pagination__info mshop_campton_klass">
              <b class="keyvisual__pagination__info__active_index">1</b><span>/</span
              ><span>${banners.length}</span>
            </div>
          </div>`
        : ''}
      ${is_mobile || banners.length <= 1
        ? ''
        : html`
            <div
              class="keyvisual__pagination__wrapper"
              data-item_color="${banner_first.banner_theme || 'black'}"
            >
              <div class="keyvisual__pagination__info mshop_campton_klass">
                <b class="keyvisual__pagination__info__active_index">1</b><span>/</span
                ><span>${banners.length}</span>
              </div>
              <div class="keyvisual__controller">
                <button class="keyvisual__button keyvisual__button--left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M15.8667 9.33398L11.2 14.0007L15.8667 18.6673"
                      stroke="${banner_first.banner_theme || 'black'}"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button class="keyvisual__button keyvisual__button--right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M12.1333 18.666L16.8 13.9993L12.1333 9.33268"
                      stroke="${banner_first.banner_theme || 'black'}"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="keyvisual__pagination">
                ${strMap(([i]) => {
                  return html`<span
                    class="keyvisual__pagination__bullet ${i === 0
                      ? 'keyvisual__pagination__bullet--active'
                      : ''}"
                  ></span>`;
                }, zipWithIndex(banners))}
              </div>
              <div class="keyvisual__pagination__line">
                <div class="keyvisual__pagination__line__value"></div>
              </div>
            </div>
          `}
    </div>
    ${is_mobile
      ? html`
          <div class="keyvisual__navigation">
            <div class="keyvisual__navigation__menus">
              ${go(
                MShopCoreConstantS.plus_main_menus[ET.lang],
                zipWithIndex,
                strMap(
                  ([i, { name, url }]) =>
                    html`
                      <a href="${url}" class="keyvisual__navigation__menu" data-active="${i === 0}"
                        >${name}</a
                      >
                    `,
                ),
              )}
            </div>
          </div>
        `
      : ''}
  `;
};
