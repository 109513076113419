import { html, on } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { WriteIcon } from '../../../atoms/Icon';
import { makeFloatingButtonTemplate, toggleButtonAtScrollRelativeToViewport } from '../util';
import { MShopUtilF } from '../../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { pushLoginStack } from '../../../../../../../modules/Creator/Login/F/fs';
import { MShopAppStoreCommunityF } from '../../../../../../../modules/MShop/App/Store/Community/F/Function/module/MShopAppStoreCommunityF';
import { Confirm } from '../../Confirm/Confirm';
import { FloatingButtonBase } from './FloatingButtonBase';

export type FloatingButtonCheerupData = {
  store_id: number;
  user_id?: number;
};

export class FloatingButton_cheerup extends FloatingButtonBase<
  FloatingButtonCheerupData & { show: boolean }
> {
  constructor(data: FloatingButtonCheerupData) {
    super({ ...data, show: false });
  }

  override onMount() {
    super.onMount();

    toggleButtonAtScrollRelativeToViewport(this, 1);
  }

  @on('click')
  override async handleClick() {
    super.handleClick();
    if (!this.data.user_id) {
      const result = await Confirm.open({
        title: '',
        message: html.preventEscape(T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?')),
      });

      if (!result) return;

      return MShopUtilF.isApp()
        ? pushLoginStack()
        : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`);
    }

    MShopAppStoreCommunityF.openEditor({
      store_id: this.data.store_id,
      type: 'community_photo',
      title: ET('mps2::cheerup::title1'),
      article: undefined,
    });
  }

  override template() {
    return makeFloatingButtonTemplate(
      html`<div class="${klass.floating_button} ${klass.bg_blue}">
        <span class="${klass.button_icon}"> ${WriteIcon} </span>
      </div>`,
      this.state.show_itself,
    );
  }
}
