export const kakao = () => {};

export const signUp = () => {
  try {
    if (T.lang == 'kr' && typeof kakaoPixel == 'function') {
      kakaoPixel('2648073228351915297').completeRegistration();
    }
  } catch (e) {}
};

export const bizGoodsTalk = () => {
  try {
    if (T.lang == 'kr' && typeof kakaoPixel == 'function') {
      kakaoPixel('2648073228351915297').signUp();
    }
  } catch (e) {}
};

export const nessAddToCart = () => {
  try {
    if (typeof kakaoPixel == 'function') {
      kakaoPixel('3296277278790172301').addToCart();
    }
  } catch (e) {}
};

export const nessPurchase = (kakao_purchase) => {
  try {
    if (typeof kakaoPixel == 'function') {
      kakaoPixel('3296277278790172301').purchase(kakao_purchase);
    }
  } catch (e) {}
};
