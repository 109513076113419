import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, ippL, sel, strMap, take } from 'fxjs/es';
import { makeUtilScreenNavigate } from '../../../../../../../services/MarppleShop/shared/app/navigate.ts';
import { staticTypo, typo } from '../../../../../../../services/MarppleShop/shared/typography/typo.js';
import { formatTimeElapsed } from '../../../../../../../services/MarppleShop/shared/util/date.ts';
import { bottomTabBarSafetyAreaHtml } from '../../../../../../../services/MarppleShop/shared/util/helper.ts';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { ARTICLE_TYPES } from '../../../../Store/Community/S/Constant/community.js';
import { MShopAppStoreCommunityTmplS } from '../../../../Store/Community/S/Tmpl/module/MShopAppStoreCommunityTmplS.js';
import { MShopAppCommunityConstantS } from '../../../S/Constant/module/MShopAppCommunityConstantS.js';
import { getCreatorShopUrl, getUserName, getUserProfileUrl } from '../../../S/Function/community.js';
import { MShopAppCommunityTmplS } from '../../../S/Tmpl/module/MShopAppCommunityTmplS.js';
import { nessPhotoReview } from './nessDetail.js';

export const detail = (data) => {
  if (sel('is_ness', data) && sel('type', data) === ARTICLE_TYPES.review_photo) return nessPhotoReview(data);
  if (sel('type', data) === ARTICLE_TYPES.photo) return articlePhoto(data);
  if (sel('type', data) === ARTICLE_TYPES.gallery_photo) return articlePhoto(data);
  if (sel('type', data) === ARTICLE_TYPES.review_photo) return reviewPhoto(data);
  if (sel('type', data) === ARTICLE_TYPES.community_photo) return cheerUpArticlePhoto(data);
  if (sel('type', data) === ARTICLE_TYPES.community_text) return cheerUpArticleText(data);

  return '';
};

const makeReviewMoreHtml = ({ is_my, is_android, article, review, texts }) => {
  /* 자기 자신이 작성한 리뷰면 삭제 가능 */
  if (is_my) {
    return html`
      <div class="article-detail__date-more-wrap article-detail-review__date-more-wrap">
        <div class="article-detail__date article-detail-review__date ${typo('12_medium')}">
          ${formatTimeElapsed(article.created_at)}
        </div>
        <div class="article-detail__more article-detail-review__more">
          <span class="article-detail__more-icon article-detail-review__more-icon"></span>
          <div class="article-detail__more-list article-detail-review__more-list">
            <div
              class="article-detail__more-item article-detail__more-item--delete article-detail-review__more-item article-detail-review__more-item--delete ${typo(
                '14_medium',
              )}"
              data-review_id="${review.id}"
            >
              ${texts.delete}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  /* 자기 자신이 작성한 리뷰가 아니고, 안드로이드면 신고 가능 */
  if (!is_my && is_android) {
    return html`
      <div class="article-detail__date-more-wrap article-detail-review__date-more-wrap">
        <div class="article-detail__date article-detail-review__date ${typo('12_medium')}">
          ${formatTimeElapsed(article.created_at)}
        </div>
        <div class="article-detail__more article-detail-review__more">
          <span class="article-detail__more-icon article-detail-review__more-icon"></span>
          <div class="article-detail__more-list article-detail-review__more-list">
            <div
              class="article-detail__more-item article-detail__more-item--report article-detail-review__more-item article-detail-review__more-item--report ${typo(
                '14_medium',
              )}"
              data-id="${article.id}"
            >
              ${texts.report}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  return html`
    <div class="article-detail__date-more-wrap article-detail-review__date-more-wrap">
      <div class="article-detail__date article-detail-review__date ${typo('12_medium')}">
        ${formatTimeElapsed(article.created_at)}
      </div>
    </div>
  `;
};

const makeCheerUpMoreHtml = ({ is_my, is_android, article, texts }) => {
  /* 자기 자신이 작성한 응원하기면 삭제 가능 */
  if (is_my) {
    return html`
      <div class="article-detail-cheer-up__date-wrap">
        <div class="article-detail-cheer-up__date ${typo('12_medium')}">
          ${formatTimeElapsed(article.created_at)}
        </div>
        <div class="article-detail__more article-detail-cheer-up__more">
          <span class="article-detail__more-icon article-detail-cheer-up__more-icon"></span>
          <div class="article-detail__more-list article-detail-cheer-up__more-list">
            <div
              class="article-detail__more-item article-detail__more-item--delete article-detail-cheer-up__more-item article-detail-cheer-up__more-item--delete ${typo(
                '14_medium',
              )}"
              data-article_id="${article.id}"
              data-store_id="${article.store_id}"
            >
              ${texts.delete}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  /* 자기 자신이 작성한 응원하기가 아니고, 안드로이드면 신고 가능 */
  if (!is_my && is_android) {
    return html`
      <div class="article-detail-cheer-up__date-wrap">
        <div class="article-detail-cheer-up__date ${typo('12_medium')}">
          ${formatTimeElapsed(article.created_at)}
        </div>
        <div class="article-detail__more article-detail-cheer-up__more">
          <span class="article-detail__more-icon article-detail-cheer-up__more-icon"></span>
          <div class="article-detail__more-list article-detail-cheer-up__more-list">
            <div
              class="article-detail__more-item--report article-detail-cheer-up__more-item article-detail-cheer-up__more-item--report article-detail__more-item--report ${typo(
                '14_medium',
              )}"
              data-id="${article.id}"
            >
              ${texts.report}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  return html`
    <div class="article-detail-cheer-up__date-wrap">
      <div class="article-detail-cheer-up__date ${typo('12_medium')}">
        ${formatTimeElapsed(article.created_at)}
      </div>
    </div>
  `;
};

export const cheerUpArticleText = ({
  article,
  type,
  store_id,
  title,
  store_name,
  login_user,
  store_user_id,
  is_mobile,
  is_store,
  is_ie,
  is_android,
  layout_options,
}) => {
  const _title = ET('mps2::cheerup::title1');

  return html`
    <div
      class="article-detail"
      is_store="${is_store || 'false'}"
      data-store_id="${store_id}"
      data-article_type="${type}"
      data-article_id="${article.id}"
      data-fx-json="${$dataStr(article)}"
    >
      ${is_mobile
        ? html`<div class="article-detail__mobile-header">
            <div class="article-detail__mobile-header__noop"></div>
            <h2 class="article-detail__title ${typo('16_bold')}">${title || _title}</h2>
            <div class="article-detail__close"></div>
          </div>`
        : html` <h2 class="article-detail__title">${title || _title}</h2> `}
      <div class="article-detail__right article-detail__right-type-text">
        ${rightCheerUp({
          article,
          login_user,
          store_name,
          store_user_id,
          is_mobile,
          is_store,
          is_ie,
          is_android,
          layout_options,
        })}
      </div>
      ${bottomTabBarSafetyAreaHtml(layout_options)}
    </div>
  `;
};

export const cheerUpArticlePhoto = ({
  article,
  title,
  store_id,
  store_name,
  login_user,
  store_user_id,
  is_mobile,
  is_store,
  is_android,
  layout_options,
}) => {
  const login_user_id = sel('id', login_user);
  const is_my = sel('user_id', article) == login_user_id;
  const right_photo_limit = 6;
  const right_photos = go(sel('photos', article), ippL, take(right_photo_limit));

  return html`
    <div
      class="article-detail"
      is_store="${is_store || 'false'}"
      data-store_id="${store_id}"
      data-article_type="${article.type}"
      data-article_id="${article.id}"
      data-fx-json="${$dataStr(article)}"
    >
      ${is_mobile
        ? html`<div class="article-detail__mobile-header">
            <div class="article-detail__mobile-header__noop"></div>
            <h2 class="article-detail__title ${typo('16_bold')}">${title || T('community::리뷰 상세')}</h2>
            <div class="article-detail__close"></div>
          </div>`
        : ''}

      <div class="article-detail__left">
        ${photoLeft({ photos: sel('photos', article) })}
        ${is_mobile
          ? html`
              <div class="article-detail-review__right-photos">
                ${strMap(([idx, photo]) => {
                  return html`
                    <div
                      class="article-detail-review__right-photo ${idx === 0 ? 'active' : ''}"
                      data-idx="${idx}"
                    >
                      <img
                        src="${UtilImageS.getResizedUrl({ url: photo.url, width: 100 })}"
                        alt="review photo"
                      />
                    </div>
                  `;
                }, right_photos)}
              </div>
            `
          : ''}
      </div>
      <div class="article-detail__right">
        ${is_mobile
          ? ''
          : html`<h2 class="article-detail__title ${typo('16_bold')}">
              ${title || T('community::리뷰 상세')}
            </h2>`}
        ${rightCheerUp({
          article,
          login_user,
          store_name,
          store_user_id,
          is_mobile,
          is_store,
          is_android,
          photos: sel('photos', article),
          right_photos,
          layout_options,
        })}
      </div>
      ${bottomTabBarSafetyAreaHtml(layout_options)}
    </div>
  `;
};

export const articlePhoto = ({
  article,
  type,
  store_id,
  title,
  store_name,
  login_user,
  store_user_id,
  is_mobile,
  is_store,
  is_ie,
}) => html`
  <div
    class="article-detail"
    is_store="${is_store || 'false'}"
    data-store_id="${store_id}"
    data-article_type="${type}"
    data-article_id="${article.id}"
    data-fx-json="${$dataStr(article)}"
  >
    <h2 class="article-detail__title">${title || T('community::커뮤니티 상세')}</h2>
    <div class="article-detail__left">${photoLeft({ photos: sel('photos', article) })}</div>
    <div class="article-detail__right">
      ${rightArticle({
        article,
        login_user,
        store_name,
        store_user_id,
        is_mobile,
        is_store,
        is_ie,
      })}
    </div>
    ${is_mobile ? html` <div class="article-detail__close"></div> ` : ''}
  </div>
`;

export const reviewPhoto = ({
  article,
  title,
  store_id,
  store_name,
  login_user,
  store_user_id,
  is_mobile,
  is_store,
  is_android,
  layout_options,
}) => {
  const review = sel('_.review', article);
  const login_user_id = sel('id', login_user);
  const is_my = sel('user_id', review) == login_user_id;
  const right_photo_limit = 6;
  const right_photos = go(sel('_.review.files', article), ippL, take(right_photo_limit));

  return html`
    <div
      class="article-detail"
      is_store="${is_store || 'false'}"
      data-store_id="${store_id}"
      data-article_type="${article.type}"
      data-article_id="${article.id}"
      data-fx-json="${$dataStr(article)}"
    >
      ${is_mobile
        ? html`<div class="article-detail__mobile-header">
            <div class="article-detail__mobile-header__noop"></div>
            <h2 class="article-detail__title ${typo('16_bold')}">${title || T('community::리뷰 상세')}</h2>
            <div class="article-detail__close"></div>
          </div>`
        : ''}

      <div class="article-detail__left">
        ${photoLeft({ photos: sel('_.review.files', article) })}
        ${is_mobile
          ? html`
              <div class="article-detail-review__right-photos">
                ${strMap(([idx, photo]) => {
                  return html`
                    <div
                      class="article-detail-review__right-photo ${idx === 0 ? 'active' : ''}"
                      data-idx="${idx}"
                    >
                      <img
                        src="${UtilImageS.getResizedUrl({ url: photo.url, width: 100 })}"
                        alt="review photo"
                      />
                    </div>
                  `;
                }, right_photos)}
              </div>
              <div class="article-detail__left-user">
                ${reviewUser({ article, is_android, review, is_my })}
              </div>
            `
          : ''}
      </div>
      <div class="article-detail__right">
        ${is_mobile
          ? ''
          : html`<h2 class="article-detail__title ${typo('16_bold')}">
              ${title || T('community::리뷰 상세')}
            </h2>`}
        ${rightReview({
          article,
          login_user,
          store_name,
          store_user_id,
          is_mobile,
          is_store,
          is_android,
          photos: sel('_.review.files', article),
          right_photos,
          layout_options,
        })}
      </div>
      ${bottomTabBarSafetyAreaHtml(layout_options)}
    </div>
  `;
};

export const photoLeft = ({ photos = [] }) => {
  if (!photos?.length) return '';
  return html`
    <div class="article-detail__photos swiper-container" data-length="${sel('length', photos)}">
      <div class="swiper-wrapper" style="position: relative;">
        ${strMap(
          (photo) => `
            <div class='swiper-slide article-detail__photo'>
              <img class="article-detail__photo-background" src="${photo.url}" alt="">
              <img class="article-detail__photo-main" src="${photo.url}" alt="" />
            </div>
          `,
          photos,
        )}
      </div>
      <div class="swiper-button-next swiper-button article-detail__next"></div>
      <div class="swiper-button-prev swiper-button article-detail__prev"></div>
      <div class="swiper-pagination article-detail__pagination ${MShopUtilConstantS.UNICA_KLASS}"></div>
    </div>
  `;
};

export const reviewUser = ({ article, is_android, review, is_my }) => {
  const uri = getCreatorShopUrl(article);
  const user_profile = MShopCoreTmplS.userProfile(getUserProfileUrl(article), uri);
  const texts = {
    report: T('community::신고'),
    delete: ET('mps2::review::delete'),
  };
  const is_creator = sel('_.user._.store.id', article);
  return html`
    <div class="article-detail-review__user">
      <div class="article-detail-review__user-profile-wrap">${user_profile}</div>
      <div class="article-detail-review__name-score-wrap">
        <div class="article-detail-review__user_name ${typo('14_bold')}">
          ${is_creator
            ? html`
                <a
                  class="${typo('14_bold')}"
                  href="${uri}"
                  data-post-message="${$dataStr(makeUtilScreenNavigate(uri))}"
                >
                  <span>${getUserName(article)}</span>
                  <img
                    src="//s3.marpple.co/files/u_1504988/2024/2/original/1ed71b2ae0f93a8ba3d8d7db76e331946f2cb37d1.svg"
                    alt=""
                  />
                </a>
              `
            : html`<span>${getUserName(article)}</span>`}
        </div>
        <div class="article-detail-review__score">
          ${MShopAppCommunityTmplS.reviewScore(sel('_.review.score', article))}
        </div>
      </div>
      ${makeReviewMoreHtml({ is_my, is_android, article, review, texts })}
    </div>
  `;
};

export const cheerUpUser = ({ article, is_android, is_my }) => {
  const uri = getCreatorShopUrl(article);
  const user_profile = MShopCoreTmplS.userProfile(getUserProfileUrl(article), uri);
  const texts = {
    report: T('community::신고'),
    delete: ET('mps2::review::delete'),
  };
  const is_creator = sel('_.user._.store.id', article);

  return html`
    <div class="article-detail-cheer-up__user">
      <div class="article-detail-cheer-up__user-profile-wrap">${user_profile}</div>
      <div class="article-detail-cheer-up__name-score-wrap">
        <div class="article-detail-cheer-up__user_name ${typo('14_bold')}">
          ${is_creator
            ? html`
                <a
                  class="${typo('14_bold')}"
                  href="${uri}"
                  data-post-message="${$dataStr(makeUtilScreenNavigate(uri))}"
                >
                  <span>${getUserName(article)}</span>
                  <img
                    src="//s3.marpple.co/files/u_1504988/2024/2/original/1ed71b2ae0f93a8ba3d8d7db76e331946f2cb37d1.svg"
                    alt=""
                  />
                </a>
              `
            : html`<span>${getUserName(article)}</span>`}
        </div>
      </div>
      ${makeCheerUpMoreHtml({ is_my, is_android, article, texts })}
    </div>
  `;
};

export const rightCheerUp = ({
  article,
  login_user,
  store_user_id,
  is_store,
  is_mobile,
  is_android,
  right_photos,
  layout_options,
}) => {
  if (!article) return '';
  const texts = {
    sns: ET('mps2::article::sns'),
    comment_count: ET('mps2::review::comment_count', { count: article.comment_count }),
  };
  const login_user_id = sel('id', login_user);

  const is_my = sel('user_id', article) == login_user_id;

  return html`
    <div class="article-detail-cheer-up">
      <div
        class="article-detail-cheer-up__scroll article-detail-right__scroll"
        data-article_type="${article.type}"
      >
        <div class="article-detail-cheer-up__wrap">
          ${cheerUpUser({ article, is_android, is_my, store_user_id })}
          <div class="article-detail-cheer-up__body">
            <div class="article-detail-cheer-up__comment ${typo('14_medium')}">
              ${articleListComment(article)}
            </div>
          </div>
          <div class="sns_url_wrap" is_sns_url="${!!article.sns_url}">
            <a href="${reviewListSnsHref(article.sns_url)}" class="sns_url" target="_blank">
              <span class="sns_info" sns_info="${reviewListSnsUrl(article.sns_url)}"></span>
              <span class="${typo('12_medium')}">${texts.sns}</span>
            </a>
          </div>
          <div class="article-detail-comments">
            <div
              class="article-detail-info__btn-comment article-detail-comments__count-wrap ${typo(
                '12_medium',
              )}"
            >
              ${texts.comment_count}
            </div>
            <ul class="article-detail-comments__list">
              ${strMap(
                (c) =>
                  commentItem({
                    comment: c,
                    has_store: sel('_.user._.store.id', c),
                    is_owner: c.user_id == sel('id', login_user),
                    is_store_owner: store_user_id == sel('id', login_user),
                    is_store,
                    is_android,
                  }),
                sel('_.comments', article),
              )}
            </ul>
            ${article.comment_count > MShopAppCommunityConstantS.COMMENT_LIMIT_OFFSET
              ? `<div class='article-detail-comments__more'><span class="${typo('14_bold')}">${T(
                  'community::더 보기',
                )}</span></div>`
              : ''}
          </div>
        </div>
      </div>
      ${commentWrite({ is_mobile, article, right_photos, layout_options })}
    </div>
  `;
};

export const rightReview = ({
  article,
  login_user,
  store_user_id,
  is_store,
  is_mobile,
  is_android,
  right_photos,
  layout_options,
}) => {
  if (!article) return '';
  const review = sel('_.review', article);
  const texts = {
    sns_review: ET('mps2::review::sns_review'),
    comment_count: ET('mps2::review::comment_count', { count: article.comment_count }),
  };
  const login_user_id = sel('id', login_user);
  const is_my = sel('user_id', review) == login_user_id;

  return html`
    <div class="article-detail-review">
      <div class="article-detail-review__scroll article-detail-right__scroll">
        <div class="article-detail-review__wrap">
          ${!is_mobile ? reviewUser({ article, is_android, review, is_my }) : ''}
          <div class="article-detail-review__body">
            <div class="article-detail-review__comment ${typo('14_medium')}">
              ${reviewListComment(review)}
            </div>
          </div>
          <div class="sns_url_wrap" is_sns_url="${!!review.sns_url}">
            <a href="${reviewListSnsHref(review.sns_url)}" class="sns_url" target="_blank">
              <span class="sns_info" sns_info="${reviewListSnsUrl(review.sns_url)}"></span>
              <span class="${typo('12_medium')}">${texts.sns_review}</span>
            </a>
          </div>
          <div class="article-detail-review__product">
            ${MShopAppCommunityTmplS.articleProduct({
              review,
              is_store,
              created_at: article.created_at,
              is_detail: true,
            })}
          </div>
          <div class="article-detail-comments">
            <div
              class="article-detail-info__btn-comment article-detail-comments__count-wrap ${typo(
                '12_medium',
              )}"
            >
              ${texts.comment_count}
            </div>
            <ul class="article-detail-comments__list">
              ${strMap(
                (c) =>
                  commentItem({
                    comment: c,
                    has_store: sel('_.user._.store.id', c),
                    is_owner: c.user_id == sel('id', login_user),
                    is_store_owner: store_user_id == sel('id', login_user),
                    is_store,
                    is_android,
                  }),
                sel('_.comments', article),
              )}
            </ul>
            ${article.comment_count > MShopAppCommunityConstantS.COMMENT_LIMIT_OFFSET
              ? `<div class='article-detail-comments__more'><span class="${typo('14_bold')}">${T(
                  'community::더 보기',
                )}</span></div>`
              : ''}
          </div>
        </div>
      </div>
      ${commentWrite({ is_mobile, article, right_photos, layout_options })}
    </div>
  `;
};

const commentWrite = ({ is_mobile, article, right_photos, layout_options }) => {
  const texts = {
    input: ET('community::input'),
    comment_place_holder: ET('community::comment_place_holder'),
  };

  return html`
    <div
      class="article-detail__comment_write ${article.type == 'community_text'
        ? 'article-detail__comment_write-type-text'
        : ''}"
    >
      ${!is_mobile && right_photos?.length
        ? html`
            <div class="article-detail-review__right-photos">
              ${strMap(([idx, photo]) => {
                return html`
                  <div
                    class="article-detail-review__right-photo ${idx === 0 ? 'active' : ''}"
                    data-idx="${idx}"
                  >
                    <img
                      src="${UtilImageS.getResizedUrl({ url: photo.url, width: 100 })}"
                      alt="review photo"
                    />
                  </div>
                `;
              }, right_photos)}
            </div>
          `
        : ''}
      <div class="article-comment-write">
        <div
          class="article-detail-info__btn_like ${staticTypo('unica_10_regular')}"
          is_like="${sel('_.like.id', article) ? 'true' : 'false'}"
        >
          ${article?.like_count}
        </div>
        ${is_mobile
          ? html`
              <div class="article-comment-write__btn_open_editor ${typo('14_medium')}">
                <span class="article-comment-write__btn_open_editor__place-holder">
                  ${texts.comment_place_holder}
                </span>
                <span class="article-comment-write__btn_open_editor__input">${texts.input}</span>
              </div>
            `
          : html`
              <textarea
                class="article-comment-write__textarea ${typo('14_medium')}"
                name="comment"
                cols="30"
                rows="1"
                placeholder="${texts.comment_place_holder}"
              ></textarea>
              <button class="article-comment-write__btn_submit ${typo('14_medium')}" type="button">
                ${texts.input}
              </button>
            `}
      </div>
      ${bottomTabBarSafetyAreaHtml({
        ...layout_options,
        has_bottom_tab_bar: layout_options.app_info ? layout_options.has_bottom_tab_bar : false, // 웹에서 프레임 뜰때는 바텀 탭바 덮어버리고, 앱 일때는 위로 떠서 앱일때만 필요함
      })}
    </div>
  `;
};

export const articleListComment = (article) => UtilS.linkify(article.body);

export const reviewListComment = (review) =>
  UtilS.linkify(review['comment' + G._en] || review.comment || review.comment_en || review.comment_jp);

export const reviewListSnsHref = function (url) {
  if (!url || !linkify.test(url)) return '';
  return 'http://' + url.replace(/^http:\/\/|^https:\/\/|^\/\//, '');
};

export const reviewListSnsUrl = function (sns_url) {
  if (!sns_url) return 'sns';
  else if (sns_url.match(/instagram\.com/i)) return 'instagram';
  else if (sns_url.match(/facebook\.com/i)) return 'facebook';
  else if (sns_url.match(/twitter\.com/i)) return 'twitter';
  else if (sns_url.match(/naver\.com/i)) return 'naver';
  else if (sns_url.match(/youtube.com|youtu.be/i)) return 'youtube';
  else if (sns_url.match(/tiktok\.com/i)) return 'tiktok';
  else return 'sns';
};

export const rightArticle = ({ article, login_user, is_ie, store_user_id, is_store, is_mobile }) => {
  if (!article) return '';

  return html`
    <div class="article-detail-cont">
      <div class="article-detail-cont__scroll article-detail-right__scroll">
        <div class="article-detail-cont__wrap">
          <div class="article-detail-cont__user">
            ${MShopAppStoreCommunityTmplS.sellerProfile({
              store: sel('_.store', article),
              is_ie,
              klass: 'seller-profile__name--detail',
            })}
            ${article.user_id == sel('id', login_user)
              ? html`
                  <div class="article-detail-cont__control">
                    <img
                      src="//s3.marpple.co/files/u_218933/2021/5/original/04fd58bdfb3e2809c9f843f65ba565c8d3b6f91e1.svg"
                      alt="options"
                    />
                  </div>
                `
              : ''}
          </div>
          <div class="article-detail-cont__body">
            <div class="article-detail-cont__comment">${UtilS.linkify(article.body)}</div>
          </div>
          <div class="article-detail-info">
            <div
              class="article-detail-info__btn_like"
              is_like="${sel('_.like.id', article) ? 'true' : 'false'}"
            >
              ${article.like_count}
            </div>
            <div class="article-detail-info__date">${formatTimeElapsed(article.created_at)}</div>
          </div>
          <div class="article-detail-comments">
            <div class="article-detail-comments__info">
              ${T('community::댓글')}
              <span class="article-detail-comments__count">${article.comment_count}</span>${T.lang == 'kr'
                ? '개'
                : ''}
            </div>
            <ul class="article-detail-comments__list">
              ${strMap(
                (c) =>
                  commentItem({
                    comment: c,
                    has_store: sel('_.user._.store.id', c),
                    is_owner: c.user_id == sel('id', login_user),
                    is_store_owner: store_user_id == sel('id', login_user),
                    is_store,
                  }),
                sel('_.comments', article),
              )}
            </ul>
            ${article.comment_count > MShopAppCommunityConstantS.COMMENT_LIMIT_OFFSET
              ? `<div class='article-detail-comments__more'><span>${T('community::더 보기')}</span></div>`
              : ''}
          </div>
        </div>
      </div>
      ${commentWrite({ is_mobile })}
    </div>
  `;
};

export const commentItem = ({ comment, has_store, is_owner, is_store_owner, is_android }) => {
  const uri = getCreatorShopUrl(comment);
  const user_profile = MShopCoreTmplS.userProfile(getUserProfileUrl(comment), uri);

  return html`
    <li
      class="article-detail-comments__item${has_store ? ' is_store_owner' : ''}"
      data-comment_id="${comment.id}"
    >
      <div class="article-detail-comments__left">
        <div class="article-detail-comments__user-profile-wrap">${user_profile}</div>
      </div>
      <div class="article-detail-comments__right">
        <div class="article-detail-comments__right-top">
          <div class="article-detail-comments__user_info">
            <span class="article-detail-comments__user ${typo('14_bold')}">
              ${has_store
                ? html`
                    <a
                      class="${typo('14_bold')}"
                      href="${uri}"
                      data-post-message="${$dataStr(makeUtilScreenNavigate(uri))}"
                    >
                      <span>${getUserName(comment)}</span>
                      <img
                        src="//s3.marpple.co/files/u_1504988/2024/2/original/1ed71b2ae0f93a8ba3d8d7db76e331946f2cb37d1.svg"
                        alt=""
                      />
                    </a>
                  `
                : html`<span>${getUserName(comment)}</span>`}
            </span>
          </div>
          <div class="article-detail-comments__date-wrap">
            <span class="article-detail-comments__date ${typo('12_medium')}"
              >${formatTimeElapsed(comment.created_at)}</span
            >
          </div>
        </div>
        <div class="article-detail-comments__right-middle">
          <div class="article-detail-comments__txt ${typo('14_medium')}">${UtilS.linkify(comment.body)}</div>
        </div>
        <div class="article-detail-comments__right-bottom">
          ${is_store_owner || is_owner
            ? html`
                <div class="article-detail-comments__etc">
                  <div
                    class="article-detail-comments__btn_like ${staticTypo('unica_14_medium')}"
                    is_like="${sel('_.like.id', comment) ? 'true' : 'false'}"
                  >
                    ${comment.like_count}
                  </div>
                  ${is_android
                    ? html`
                        <div
                          class="article-detail-comments__btn_report ${typo('14_medium')}"
                          data-id="${comment.id}"
                        >
                          ${T('community::신고')}
                        </div>
                      `
                    : ''}
                  <span class="article-detail-comments__btn_del ${typo('12_medium')}"
                    >${T('community::삭제')}</span
                  >
                </div>
              `
            : html`
                <div class="article-detail-comments__etc">
                  <div
                    class="article-detail-comments__btn_like ${staticTypo('unica_14_medium')}"
                    is_like="${sel('_.like.id', comment) ? 'true' : 'false'}"
                  >
                    ${comment.like_count}
                  </div>
                </div>
              `}
        </div>
      </div>
    </li>
  `;
};

export const articleControlLayer = ({ item, store_id, type, can_edit }) => html`
  <div class="detail-control-layer">
    <div class="detail-control-layer__back"></div>
    <div class="detail-control-layer__controls">
      ${type == 'article' && can_edit
        ? `
        <button type='button' _type='${type}' item_id='${item.id}' 
          store_id='${store_id}' class='detail-control-layer__btn_modify'
          data-fx-json="${$dataStr(item)}"
        >
          ${T('community::수정')}
        </button>
      `
        : ''}
      <button
        type="button"
        _type="${type}"
        item_id="${item.id}"
        store_id="${store_id}"
        class="detail-control-layer__btn_del"
      >
        ${T('community::삭제')}
      </button>
      <button
        type="button"
        _type="${type}"
        item_id="${item.id}"
        store_id="${store_id}"
        class="detail-control-layer__btn_cancel"
      >
        ${T('취소')}
      </button>
    </div>
  </div>
`;
