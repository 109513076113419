import {
  $addClass,
  $closest,
  $height,
  $on,
  $qs,
  $removeClass,
  $scrollTop,
  $setAttr,
  $setCss,
  $setScrollTop,
} from 'fxdom/es';
import { each, go, throttle } from 'fxjs/es';
import UAParser from 'ua-parser-js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const util = () => {};
const parser = new UAParser();
const isIpadForNessCustomMakerPage = () => {
  if (typeof window === 'undefined') return;
  return window.is_session_ipad;
};
const is_mobile_cache =
  ['mobile', 'tablet'].includes(parser.getDevice().type) || isIpadForNessCustomMakerPage();
export const isWhale = () => parser.getBrowser()?.name?.toLowerCase() === 'whale';
export const isMobile = () => is_mobile_cache;
export const isIOS = () => /IOS/i.test(parser.getOS()?.name);
export const isAndroid = () => /Android/i.test(parser.getOS()?.name);
export const isWindows = () => /Windows/i.test(parser.getOS()?.name);

export const getOSVersion = () => parser.getOS()?.version;
export const isApp = () =>
  !!window.ReactNativeWebView && window.navigator.userAgent.indexOf('MarppleShopApp') > -1;

export const isCreatorApp = () => isApp() && G.collabo_type === 'creator';
export const isCreatorAndroidApp = () => isApp() && G.collabo_type === 'creator' && isAndroid();

export const shuffle = (array) => {
  const result = [...array];

  for (let index = result.length - 1; index > 0; index--) {
    const randomPosition = Math.floor(Math.random() * (index + 1));

    const temporary = result[index];
    result[index] = result[randomPosition];
    result[randomPosition] = temporary;
  }

  return result;
};

const full_uri_reg = /^(\/\/|https:\/\/|http:\/\/)/;
const makeOriginURI = (data) => {
  if (data?.screen?.uri && !full_uri_reg.test(data.screen.uri))
    data.screen.uri = `${location.origin}${data.screen.uri}`;
  else
    go(
      data,
      Object.assign,
      each(([, { params = {} }]) => {
        if (params?.uri && !full_uri_reg.test(params.uri)) params.uri = `${location.origin}${params.uri}`;
      }),
    );

  return data;
};

export const postMessage = throttle(
  (data) =>
    go(data, makeOriginURI, (msg) =>
      isCreatorApp()
        ? window.ReactNativeWebView.postMessage(JSON.stringify(msg))
        : ENV.is_prod
        ? undefined
        : console.log(msg),
    ),
  200,
);

export const getCurrentCrewDomain = () => {
  const result = /(.+)\..+\..+/.exec(location.host);

  if (!result) return 'all';
  return result[1] || 'all';
};

/**
 *
 * @param {boolean} be_fix
 * @param {{ no_cover: boolean }} option
 */
export const bodyFixed$ = (be_fix, option = {}) => {
  if (be_fix) {
    if (!isMobile() && $height(document.body) > window.innerHeight)
      $setCss({ 'overflow-y': 'scroll' }, document.body);
    document.body.fixed_top = $scrollTop(window);
    go(document.body, $addClass('body-fixed'), $setCss({ top: -document.body.fixed_top }));
    if (option.no_cover) {
      document.body.classList.add('body-fixed--no_cover');
    }
  } else {
    go(document.body, $removeClass('body-fixed'), $setCss({ top: '', 'overflow-y': '' }));
    $setScrollTop(document.body.fixed_top, window);
    document.body.fixed_top = void 0;
    if (option.no_cover) {
      document.body.classList.remove('body-fixed--no_cover');
    }
  }
};

export const moveScrollToTop = (duration = 300) =>
  anime({ targets: 'html, body', scrollTop: 0, duration, easing: 'easeInQuad' }).finished;

export const clipboard = (text) => {
  const _clipboard = (function (window, document, navigator) {
    let textArea;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }

    return {
      copy: (text) => {
        createTextArea(text);
        selectText();
        copyToClipboard();
      },
    };
  })(window, document, navigator);

  _clipboard.copy(text);
};

export const loginHref = () =>
  (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`);
export const toggleChannelTalk = (is_view) => {
  const ch_plugin = $qs('#ch-plugin');
  if (ch_plugin) {
    const style = is_view ? 'z-index: 10000000 !important' : 'display: none !important';
    $setAttr({ style }, ch_plugin);
  }
};
export const channelTalkOn = () => {
  toggleChannelTalk(true);
};

export const channelTalkOff = () => {
  toggleChannelTalk(false);
};

const _isWheelNumberBlurFn = ({ target, selector }) => {
  const scroll_el = $closest(selector, target);
  if (!scroll_el || scroll_el.____is_wheel_number_blur) return;
  scroll_el.____is_wheel_number_blur = true;
  $on('wheel', () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  })(scroll_el);
};

export const isWheelNumberBlur = (selectors = []) => {
  document.addEventListener('wheel', () => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  document.addEventListener('click', ({ target }) => {
    each((selector) => _isWheelNumberBlurFn({ target, selector }), [...selectors, '.is-wheel-number-blur']);
  });
};

/**
 * @dev tablet, mobile 에서 orientation (= 가로 세로 보기) 가 달라질 경우 리스너를 호출해주는 헬퍼 함수
 * @param {(orientation: 'portrait' | 'landscape') => void} listener
 */
export const onOrientationChange = (listener) => {
  const getCurrentOrientation = () =>
    window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';

  // Function to handle orientation change
  let prev_orientation = getCurrentOrientation();
  function handleOrientationChange() {
    const current_orientation = getCurrentOrientation();

    if (prev_orientation == current_orientation) return;
    prev_orientation = current_orientation;

    listener(current_orientation);
  }

  // Add an event listener for resize (which is triggered on orientation change)
  window.addEventListener('resize', UtilF.throttleAnimationFrameLevel(handleOrientationChange));
};

export const isTouchDevice = () => {
  return window.navigator?.maxTouchPoints > 0;
};
