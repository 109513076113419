import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, indexBy, ippL, isNil, sel, strMap } from 'fxjs/es';
import { CREW_DOMAIN_NAME } from '../../../../../Creator/Crew/S/constant.js';
import { makeProductStoryEditorHtml } from '../../../../../Creator/ProductStoryEditor/S/tmpl.js';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopStudioPreDiscountProductTmplS } from '../../../PreDiscountProduct/S/Tmpl/module/MShopStudioPreDiscountProductTmplS.js';
import { MShopStudioPbProductConstantS } from '../Constant/module/MShopStudioPbProductConstantS.js';
import { MShopStudioPbProductS } from '../Function/module/MShopStudioPbProductS.js';
import { MShopStudioPbProductTmplS } from './module/MShopStudioPbProductTmplS.js';
import { GlobalProductInfoTextarea, makeOptionDescription, makeSkuToggleHtml } from './shippingGlobalHtml.js';

const selectStoreDeliveryCompany = ({ tpl_fee, has_permission_tpl }) => {
  return html`
    <div class="pb-editor-form__control">
      <div class="pb-editor-form__label-txt">
        배송 정책 설정 <span class="pb-editor-form__required">*</span>
        <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
        <div class="tooltip-container">
          <div class="editor-tooltip-box">
            ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('stores_delivery_companies')}
          </div>
        </div>
      </div>
      <div class="pb-editor-form__body">
        <div class="pb-editor-form__select">
          <select name="stores_delivery_company_id" class="pb-editor-form__select--only-mps" disabled>
            <option value="" selected>${T('order::마플샵배송')}</option>
          </select>
        </div>
      </div>
      <div class="pb-editor-form__txt1 pb-editor-form__selected-stores-delivery-company">
        ${T('마플샵에 입고 완료된 상품으로 마플샵에서 배송합니다.')}
      </div>
    </div>
    <div class="pb-editor-form__control">
      <label class="pb-editor-form__label">
        <div class="pb-editor-form__label-txt">
          입고 수수료 <span class="pb-editor-form__required">*</span>
        </div>
        <div class="pb-editor-form__body">
          <input
            data-before_ratio="${UtilS.escape(tpl_fee.ratio)}"
            type="number"
            name="tpl_fee"
            value="${UtilS.escape(tpl_fee.ratio) || 0}"
            class="pb-editor-form__body-input pb-editor-form__body-input-tpl-fee"
            ${/* TODO 입고 상품 권한 */ !has_permission_tpl ? 'disabled' : ''}
            min="0"
            max="100"
          />
          <div class="pb-editor-form__body-right">%</div>
        </div>
      </label>
    </div>
  `;
};

export const editorTpl = ({
  stores_product,
  cate_lists,
  cate_items,
  product_types,
  store_ecommerce_info,
  crew_domain_name,
  stores_delivery_companies,
  action,
  thumbnails,
  store_url,
  goods_type_id,
  is_studio_worker_admin,
}) => {
  const {
    total_spo_item_stock,
    product_story,
    product,
    tpl_fee,
    skus = [],
    has_up,
    spo_text_options,
    spo_options,
    spo_items,
    spo_producing_period,
    show_spo_producing_period,
    spo,
    product_type_id,
    hs_code,
  } = MShopStudioPbProductS.getProps({ stores_product, product_types, stores_delivery_companies });

  const is_use_creator_en = sel('_.base_product.is_use_creator_en', product) || false;
  const is_global = is_use_creator_en;

  const cate_items_indexby_id = go(
    cate_items,
    indexBy((item) => item.id),
  );
  return html`
    <div class="pb-editor" data-goods_type_id="${goods_type_id}" data-is_global=${is_global}>
      <div class="pb-editor__wrapper">
        <div class="pb-editor__left">
          <div class="pb-editor__head">
            <div class="pb-editor__tip">
              <div class="pb-editor__tip-img">
                <img
                  src="//s3.marpple.co/files/u_218933/2023/1/original/c27897e66cc407a1526fe51f88377995a856aa311.svg"
                  alt=""
                />
              </div>
            </div>
            <h2 class="pb-editor__title">
              ${stores_product?.id ? T('question::상품 수정하기') : T('question::상품 등록하기')}
            </h2>
            <p class="pb-editor__desc">
              <span class="pb-editor-form__required">*</span> 표시는 필수로 입력해야 할 항목입니다.
            </p>
          </div>
          <div class="pb-editor__thumbnails-area">
            ${MShopStudioPbProductTmplS.makePbThumbnailEditorHtml(thumbnails)}
          </div>
          <!-- div class="pb-editor__tooltip"></div -->
        </div>
        <div class="pb-editor__right is-wheel-number-blur">
          <div class="pb-editor__form-buttons pb-editor__form-buttons--top">
            <!-- button type="button">상품 일괄 등록</button -->
            <!-- button type="button" class="pb-editor-form__btn-preview">미리보기</button -->
            ${MShopStudioPbProductTmplS.editorHeaderButtons({
              ...action,
              is_prohibited: stores_product?.is_prohibited,
              is_solution: stores_product?.is_solution,
              product_id: product?.id,
              store_url,
              goods_type_id,
            })}
          </div>

          <div class="pb-editor__form-area">
            <div
              class="pb-editor-form"
              data-stock_type="${spo?.stock_type || SpoSpoConstantS.pSpoStockType.NONE}"
            >
              <div
                class="pb-editor-form__control pb-editor-form__control-is-public"
                data-fx-json="${$dataStr(skus)}"
              >
                <div class="pb-editor-form__label-txt">
                  상품 공개 여부 <span class="pb-editor-form__required">*</span>
                </div>
                <div class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--is_public">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input type="radio" name="public" value="true" ${product?.is_public ? 'checked' : ''} />
                    공개
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input type="radio" name="public" value="false" ${!product?.is_public ? 'checked' : ''} />
                    비공개
                  </label>
                </div>
              </div>

              ${MShopStudioPbProductTmplS.makeIsGlobalInputRadioHtml(is_global)}
              <div class="pb-editor-form__control ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    HS CODE <span class="pb-editor-form__required">*</span>
                    <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                    <div class="tooltip-container">
                      <div class="editor-tooltip-box">
                        ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('hs_code')}
                      </div>
                    </div>
                  </div>
                  <div class="pb-editor-form__body">
                    <input
                      data-before_ratio="${UtilS.escape(tpl_fee.ratio)}"
                      type="text"
                      name="hs_code"
                      value="${UtilS.escape(hs_code) || ''}"
                      placeholder="숫자 10자리를 입력해 주세요."
                      class="pb-editor-form__body-input pb-editor-form__body-input-hs-code"
                      ${/* TODO 입고 상품 권한 */ !action.has_permission_tpl ? 'disabled' : ''}
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>

              <hr class="pb-editor-form__pd-type-end-hr" />

              ${MShopStudioPbProductTmplS.makeGlobalProductNameInputHtml({
                name: sel('_.product.name', stores_product) || '',
                name_en: sel('_.product.name_en', stores_product) || '',
                name_jp: sel('_.product.name_jp', stores_product) || '',
              })}

              <hr
                class="pb-editor-form__pd-type-end-hr ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
              />

              <div class="pb-editor-form__control pb-editor-form__profit">
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    상품 가격 <span class="pb-editor-form__required">*</span>
                  </div>
                  <div class="pb-editor-form__body">
                    <input
                      type="text"
                      name="profit"
                      value="${isNil(product?.profit) ? '' : UtilS.commify(product?.profit)}"
                      class="pb-editor-form__body-input pb-editor-form__body-input--won"
                      placeholder="상품 가격을 입력해 주세요"
                    />
                    <div class="pb-editor-form__body-right">₩</div>
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.only_number.text}
                </p>
              </div>

              ${UtilS.htmlIf2(
                MShopStudioPreDiscountProductS.isPreProductDiscountSupported(),
                () =>
                  html`
                    <div class="pb-editor-form__control pb-editor-form__pre-discount-price">
                      ${MShopStudioPreDiscountProductTmplS.makePreDiscountPriceTmpl({
                        pre_discount_price: product?.pre_discount_product?.pre_discount_price,
                        price: product?.profit,
                      })}
                    </div>
                  `,
              )}
              ${UtilS.htmlIf2(crew_domain_name !== CREW_DOMAIN_NAME.MARPPLE, () =>
                MShopStudioPbProductTmplS.makeProfitShareFormItemHtml(
                  sel('seller_profit_share', stores_product),
                  product?.is_public || has_up,
                ),
              )}

              <div class="pb-editor-form__control pb-editor-form__category">
                <div class="pb-editor-form__label-txt">
                  상품 카테고리 <span class="pb-editor-form__required">*</span>
                </div>
                <div class="pb-editor-form__body-area pb-editor-form__body-area--cols2">
                  <div class="pb-editor-form__body pb-editor-form__body--category">
                    <div class="pb-editor-form__select pb-editor-form__cate-lists">
                      <div class="pb-editor-form__select-area">
                        ${MShopStudioPbProductTmplS.selectCateLists(
                          cate_lists,
                          stores_product?.sp_cate_list_id,
                          !!skus?.length,
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="pb-editor-form__body pb-editor-form__body--category">
                    <div class="pb-editor-form__select pb-editor-form__cate-items">
                      <div class="pb-editor-form__select-area">
                        ${MShopStudioPbProductTmplS.selectCateItems(
                          cate_items,
                          stores_product?.sp_cate_item_id,
                          !!skus?.length,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.only_number.text}
                </p>
              </div>
              <div
                class="pb-editor-form__control ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME} pb-editor-form__material ${cate_items_indexby_id[
                  stores_product?.sp_cate_item_id
                ]?.is_cloth
                  ? ''
                  : 'pb-tpl-invisible'}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    의류 상품 소재 <span class="pb-editor-form__required">*</span>
                  </div>
                  <div class="pb-editor-form__body">
                    <input
                      type="text"
                      name="material"
                      maxlength="50"
                      value="${stores_product?.material || ''}"
                      placeholder="의류 상품 소재를 영문으로 입력해 주세요."
                      class="pb-editor-form__body-input pb-editor-form__body-input-material"
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.check_material.text}
                </p>
              </div>

              <hr />

              <div class="pb-editor-form__control pb-editor-form__pd-type">
                <div class="pb-editor-form__label-txt">
                  상품 타입 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('pd_type')}
                    </div>
                  </div>
                </div>
                <div class="pb-editor-form__body">
                  <div class="pb-editor-form__select">
                    <select name="product_type">
                      ${strMap(
                        (product_type) => html`
                          <option
                            value="${product_type.id}"
                            ${product_type_id == product_type.id ? ' selected' : ''}
                          >
                            ${product_type.name}
                          </option>
                        `,
                        product_types,
                      )}
                    </select>
                  </div>
                </div>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>

              ${show_spo_producing_period
                ? MShopStudioPbProductTmplS.producingPeriodInput(product_type_id, spo_producing_period)
                : ''}

              <div
                class="pb-editor-form__control pb-editor-form__control-limited ${!stores_product?.product_type_id ||
                stores_product?.product_type_id == MShopStudioPbProductConstantS.PB_PRODUCT_TYPES.always
                  ? 'pb-editor-form__control-limited--hide'
                  : ''}"
              >
                <div class="pb-editor-form__label-txt">
                  한정 판매 기간&amp;수량 설정
                  <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('limited')}
                    </div>
                  </div>
                </div>
                <div class="pb-editor-form__body pb-editor-form__body--check">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3"
                  >
                    <input
                      type="checkbox"
                      name="check_sales_period"
                      value=""
                      ${stores_product?.sell_start_at ? 'checked' : ''}
                    />
                    한정 판매 기간
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10"
                  >
                    <input
                      type="checkbox"
                      name="check_quantity"
                      value=""
                      ${stores_product?.quantity ? 'checked' : ''}
                    />
                    한정 판매 수량
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10"
                  >
                    <input
                      type="checkbox"
                      name="check_max_purchase_per_user"
                      value=""
                      ${stores_product?.max_purchase_per_user ? 'checked' : ''}
                    />
                    1인당 구매 가능 수량
                  </label>
                </div>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-sales-period${stores_product?.sell_start_at
                  ? ' pb-editor-form__control-sales-period--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">한정 판매 기간</div>
                  <div class="pb-editor-form__body">
                    <input
                      name="sell_at"
                      class="pb-editor-form__body-input pb-editor-form__body-input--sell-at"
                    />
                    <input name="sell_start_at" type="hidden" value="" />
                    <input name="sell_end_at" type="hidden" value="" />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-quantity${stores_product?.quantity
                  ? ' pb-editor-form__control-quantity--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">한정 판매 수량</div>
                  <div class="pb-editor-form__body">
                    <input
                      type="number"
                      class="pb-editor-form__body-input"
                      name="quantity"
                      min="1"
                      max="10000"
                      step="1"
                      value="${UtilS.escape(stores_product?.quantity) || 100}"
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>

                <div style="margin-top: 10px;">
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="is_quantity_public"
                      value="true"
                      ${stores_product?.is_quantity_public ? ' checked' : ''}
                    />
                    수량 공개
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="is_quantity_public"
                      value="false"
                      ${!stores_product?.is_quantity_public ? ' checked' : ''}
                    />
                    수량 비공개
                  </label>
                </div>
              </div>

              <div
                class="pb-editor-form__control pb-editor-form__control-max_purchase_per_user${stores_product?.max_purchase_per_user
                  ? ' pb-editor-form__control-max_purchase_per_user--show'
                  : ''}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">1인당 구매 가능 수량</div>
                  <div class="pb-editor-form__body">
                    <input
                      type="number"
                      class="pb-editor-form__body-input"
                      name="max_purchase_per_user"
                      min="1"
                      max="10000"
                      step="1"
                      value="${stores_product?.max_purchase_per_user || 1}"
                    />
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>
              <div
                class="pb-editor-form__control"
                style="display: ${stores_product?.order_count > 0 ? 'block' : 'none'};"
              >
                <div class="pb-editor-form__label-txt">판매된 수량</div>
                <div class="pb-editor-form__txt1 " data-order_count="${stores_product?.order_count}">
                  ${UtilS.commify(stores_product?.order_count)}
                </div>
              </div>
              <div class="pb-editor-form__control pb-editor-form__control-stock">
                <div class="pb-editor-form__label-txt">
                  재고 관리 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('stock')}
                    </div>
                  </div>
                </div>

                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.NONE}"
                    ${!sel('_.spo.stock_type', product) ||
                    sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.NONE
                      ? 'checked'
                      : ''}
                    ${skus?.length ? 'disabled' : ''}
                  />
                  무제한 관리
                </label>
                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.TOTAL_STOCK}"
                    ${sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.TOTAL_STOCK
                      ? 'checked'
                      : ''}
                    ${skus?.length ? 'disabled' : ''}
                  />
                  총 수량 관리
                </label>
                <label
                  class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                >
                  <input
                    type="radio"
                    name="stock_type"
                    value="${SpoSpoConstantS.pSpoStockType.OPTION_STOCK}"
                    ${sel('_.spo.stock_type', product) == SpoSpoConstantS.pSpoStockType.OPTION_STOCK
                      ? 'checked'
                      : ''}
                    ${skus?.length ? 'disabled' : ''}
                  />
                  옵션별 수량 관리
                </label>
              </div>

              ${MShopStudioPbProductTmplS.totalStockInput({
                total_spo_item_stock,
                is_tpl: true,
                skus,
                is_studio_worker_admin,
              })}

              <hr class="pb-editor-form__pd-type-end-hr" />

              <div class="pb-editor-form__control pb-editor-form__control-option">
                <div class="pb-editor-form__label-txt">
                  옵션
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('option')}
                    </div>
                  </div>
                </div>
                ${makeOptionDescription({ skus })}
              </div>

              ${skus?.length
                ? ''
                : html`<div class="pb-editor-form__options">
                      ${MShopStudioPbProductTmplS.globalOptionControls(spo_options)}
                    </div>
                    <div class="pb-editor-form__set-option">
                      <button type="button" class="pb-editor-form__btn-set-op-list-tpl">
                        <span>옵션 목록으로 적용하기</span>
                      </button>
                    </div> `}

              <div class="pb-editor-form__options-area">
                ${MShopStudioPbProductTmplS.globalSpoItemsArea({
                  spo_options,
                  spo_items,
                  stock_type: sel('_.spo.stock_type', product),
                  skus,
                  is_studio_worker_admin,
                })}
                <p class="pb-editor-form__error-msg">
                  ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                </p>
              </div>
              ${makeSkuToggleHtml({ skus })}
              ${skus?.length
                ? ''
                : html`<div class="pb-editor-form__op-direct-input-area">
                    ${go(spo_text_options, ippL, strMap(MShopStudioPbProductTmplS.globalOptionDirectInput))}
                  </div>`}

              <div class="pb-editor-form__story">
                ${makeProductStoryEditorHtml(product_story, crew_domain_name)}
              </div>

              <hr />

              ${GlobalProductInfoTextarea({
                product_basic_info: stores_product?.product_basic_info,
                product_basic_info_en: stores_product?.product_basic_info_en,
                product_basic_info_jp: stores_product?.product_basic_info_jp,
              })}

              <div
                class="pb-editor-form__control"
                data-is_certification_info="${!!stores_product?.is_kc_cert_checked}"
              >
                <label class="pb-editor-form__label">
                  <div class="pb-editor-form__label-txt">
                    KC 및 기타 인증 <span class="pb-editor-form__required">*</span>
                    <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                    <div class="tooltip-container">
                      <div class="editor-tooltip-box">
                        ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('certification_info')}
                      </div>
                    </div>
                  </div>
                  <span
                    class="pb-editor-form__kc-radio ${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}"
                  >
                    <label class="pb-editor-form__radio-label">
                      <input
                        type="radio"
                        name="kc-certification"
                        value="show"
                        ${stores_product?.is_kc_cert_checked ? 'checked' : ''}
                      />
                      KC 인증 있음
                    </label>
                    <label class="pb-editor-form__label--lm10 pb-editor-form__radio-label">
                      <input
                        type="radio"
                        name="kc-certification"
                        value="hide"
                        ${!stores_product?.is_kc_cert_checked ? 'checked' : ''}
                      />
                      해당 없음
                    </label>
                  </span>
                  <p class="pb-editor-form__txt1">
                    품목에 따른 KC 및 기타 인증 정보를 필수로 표기해주세요. 판매 과정에서 판매자가 현행법에
                    저촉하는 일이 없도록 KC 인증 및 별도 허가증 등 정보를 기재하여야 합니다.
                  </p>
                  <div class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--certification_info">
                    <div>
                      ${MShopStudioPbProductTmplS.makeKrTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info"
                          value="${stores_product?.certification_info || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>

                    <div class="${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
                      ${MShopStudioPbProductTmplS.makeEnTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info_en"
                          value="${stores_product?.certification_info_en || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>

                    <div class="${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
                      ${MShopStudioPbProductTmplS.makeJpTextHtml()}
                      <div
                        class="pb-editor-form__body pb-editor-form__kc-body ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}${stores_product?.is_kc_cert_checked
                          ? ` pb-editor-form__kc-body--show`
                          : ''}"
                      >
                        <input
                          class="pb-editor-form__body-input"
                          name="certification_info_jp"
                          value="${stores_product?.certification_info_jp || ''}"
                          placeholder="인증기준 / 인증기관 / 인증번호 등 (미입력시 KC 마크만 노출됩니다)"
                        />
                      </div>
                    </div>
                  </div>
                </label>
                <p class="pb-editor-form__error-msg">숫자를 입력해 주세요.</p>
              </div>

              <div class="pb-editor-form__control">
                <div class="pb-editor-form__label-txt">
                  일대일 문의 방법 설정 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('qna')}
                    </div>
                  </div>
                </div>

                <div
                  class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--min-height-none"
                >
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="qna_type"
                      value="${MShopStudioPbProductConstantS.QNA_TYPES.BOARD}"
                      ${(stores_product?.qna_type || store_ecommerce_info?.qna_type) !=
                      MShopStudioPbProductConstantS.QNA_TYPES.SNS
                        ? 'checked'
                        : ''}
                    />
                    마플샵 일대일 문의
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="qna_type"
                      value="${MShopStudioPbProductConstantS.QNA_TYPES.SNS}"
                      ${(stores_product?.qna_type || store_ecommerce_info?.qna_type) ==
                      MShopStudioPbProductConstantS.QNA_TYPES.SNS
                        ? ' checked'
                        : ''}
                    />
                    개인 SNS를 통한 문의
                  </label>
                </div>

                <div
                  class="pb-editor-form__qna-type-mp ${(stores_product?.qna_type ||
                    store_ecommerce_info.qna_type) == MShopStudioPbProductConstantS.QNA_TYPES.SNS
                    ? 'pb-editor-form__qna-type-mp--hide'
                    : ''}"
                >
                  <p class="pb-editor-form__txt1">
                    마플샵 스튜디오 > 고객 문의 관리를 통해 구매자와 메시지를 주고 받을 수 있습니다.
                  </p>

                  <div class="pb-editor-form__inquiry-precautions">
                    <p class="pb-editor-form__inquiry-precautions__txt1">
                      <span class="pb-editor-form__icon-important pb-editor-form__icon-important--ml0"></span>
                      마플샵 일대일 문의 안내
                    </p>
                    <ul>
                      <li>
                        - 마플샵 스튜디오 > 고객 문의 관리를 통해 구매자와 메시지를 주고 받을 수 있습니다.
                      </li>
                      <li>- 구매자의 문의를 7일 이상 미 답변시 7일 동안 판매중지 상태가 될 수 있습니다.</li>
                    </ul>
                  </div>
                </div>

                <div
                  class="pb-editor-form__qna-type-sns ${(stores_product?.qna_type ||
                    store_ecommerce_info?.qna_type) == MShopStudioPbProductConstantS.QNA_TYPES.SNS
                    ? ''
                    : 'pb-editor-form__qna-type-sns--hide'}"
                >
                  <div class="pb-editor-form__control">
                    <label class="pb-editor-form__label">
                      <div class="pb-editor-form__body">
                        <input
                          type="text"
                          name="qna_link"
                          class="pb-editor-form__body-input"
                          value="${stores_product?.qna_link || store_ecommerce_info?.qna_link || ''}"
                          placeholder="예) https://www.instagram.com/marpple.shop/"
                        />
                      </div>
                    </label>
                    <p class="pb-editor-form__error-msg">
                      ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                    </p>
                  </div>
                  <p class="pb-editor-form__txt1">크리에이터의 개인 SNS를 통해 메세지를 주고 받습니다.</p>
                </div>
              </div>

              <hr />
              ${selectStoreDeliveryCompany({
                tpl_fee,
                has_permission_tpl: action.has_permission_tpl,
              })}
              ${is_studio_worker_admin
                ? html`
                    <div class="pb-editor-form__control pb-editor-shipping_date_visibility">
                      <div class="pb-editor-form__label-txt">${T('translation::배송 출발일 노출')}</div>
                      <div
                        class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--min-height-none"
                      >
                        <label
                          class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                        >
                          <input
                            type="radio"
                            name="shipping_date_hidden"
                            value="false"
                            ${!stores_product.shipping_date_hidden ? ' checked' : ''}
                          />
                          ${T('translation::공개')}
                        </label>
                        <label
                          class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                        >
                          <input
                            type="radio"
                            name="shipping_date_hidden"
                            value="true"
                            ${stores_product.shipping_date_hidden ? ' checked' : ''}
                          />
                          ${T('translation::비공개')}
                        </label>
                      </div>
                    </div>
                  `
                : ''}
              <div
                class="pb-editor-form__control pb-editor-form__control--refund-info"
                refund_info_type="${stores_product?.refund_info_type ||
                MShopStudioPbProductConstantS.REFUND_INFO_TYPES.normal}"
              >
                <div class="pb-editor-form__label-txt">
                  교환/환불 안내 <span class="pb-editor-form__required">*</span>
                  <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                  <div class="tooltip-container">
                    <div class="editor-tooltip-box">
                      ${MShopStudioPbProductTmplS.pdTypeTooltipHTML('refund_info')}
                    </div>
                  </div>
                </div>
                <div
                  class="pb-editor-form__body pb-editor-form__body--radio pb-editor-form__body--min-height-none"
                >
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm-minus3 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="refund_info_type"
                      value="${MShopStudioPbProductConstantS.REFUND_INFO_TYPES.normal}"
                      ${stores_product?.refund_info_type !=
                      MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand
                        ? ' checked'
                        : ''}
                    />
                    일반 상품 (교환 가능)
                  </label>
                  <label
                    class="pb-editor-form__label pb-editor-form__label--inline pb-editor-form__label--lm10 pb-editor-form__radio-label"
                  >
                    <input
                      type="radio"
                      name="refund_info_type"
                      value="${MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand}"
                      ${stores_product?.refund_info_type ==
                      MShopStudioPbProductConstantS.REFUND_INFO_TYPES.print_on_demand
                        ? ' checked'
                        : ''}
                    />
                    주문 제작 상품 (조건 확인)
                  </label>
                </div>

                <div class="pb-editor-form__refund-info-normal">
                  <p class="pb-editor-form__txt1">
                    단순 변심, 주문 착오 등 고객 사유 반품이 가능한 일반 상품일 때 선택합니다. 내용 추가, 수정
                    가능합니다.
                  </p>
                </div>
                <div class="pb-editor-form__refund-info-print-on-demand">
                  <p class="pb-editor-form__txt1">
                    단순 변심, 주문 착오 등 고객 사유 반품이 불가한 주문 제작 상품일 때 선택합니다. 내용 추가,
                    수정 가능합니다.
                  </p>
                </div>
                <div class="pb-editor-form__control ${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}">
                  <div class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info">
                    ${MShopStudioPbProductTmplS.makeKrTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea name="refund_info" class="pb-editor-form__body-textarea" cols="30" rows="10">
${stores_product?.refund_info || MShopStudioPbProductTmplS.refundInfoNormalEx}</textarea
                      >
                    </div>
                  </div>
                  <div class="mb-18"></div>
                  <div
                    class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
                  >
                    ${MShopStudioPbProductTmplS.makeEnTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea
                        name="refund_info_en"
                        class="pb-editor-form__body-textarea"
                        cols="30"
                        rows="10"
                      >
${stores_product?.refund_info_en || MShopStudioPbProductTmplS.refundInfoNormalExEn}</textarea
                      >
                    </div>
                  </div>
                  <div class="mb-18"></div>
                  <div
                    class="${MShopStudioPbProductConstantS.GLOBAL_WRAP_CLASSNAME}--refund-info ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}"
                  >
                    ${MShopStudioPbProductTmplS.makeJpTextHtml()}
                    <div class="pb-editor-form__body pb-editor-form__body--textarea">
                      <textarea
                        name="refund_info_jp"
                        class="pb-editor-form__body-textarea"
                        cols="30"
                        rows="10"
                      >
${stores_product?.refund_info_jp || MShopStudioPbProductTmplS.refundInfoNormalExJp}</textarea
                      >
                    </div>
                  </div>
                  <p class="pb-editor-form__error-msg">
                    ${MShopStudioPbProductS.pb_product_editor_error.required.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-editor__form-buttons">
            ${stores_product?.id
              ? html`<button type="button" class="pb-editor-form__btn-cancel">${T('취소')}</button>`
              : html`<button type="button" class="pb-editor-form__btn-temp-save">
                  ${T('question::임시저장')}
                </button>`}
            <button
              type="button"
              class="pb-editor-form__btn-submit${stores_product?.id
                ? ''
                : ' pb-editor-form__btn-submit--disabled'}"
            >
              ${stores_product?.id ? T('완료') : T('상품 등록하기')}
            </button>
          </div>
        </div>
      </div>
    </div>
  `;
};
