import { head, html, last, map, sel, strMap } from 'fxjs/es';
import { getAvailableColors } from '../../../../../../Creator/Setting/S/fs.js';
import { makeSelectOptions } from './productDetailForm.js';

const makeTShirtName = (t) =>
  t['name' + G._en] ||
  (t['name' + G._en] || '').replace(/\s(티셔츠|t shirt)_/i, ' ') ||
  t._.base_product['name' + G._en];

const makePromotionalProductName = (name) => name || last(name.split('_'));

export const makeTShirtSizesHtml = (selected_t_shirt, bps_id = '') => {
  const t_shirt_colors = getAvailableColors(
    selected_t_shirt._.base_product._.base_product_colors,
    selected_t_shirt._.stores_product._.store_product_colors,
  );
  const selected_t_shirt_color = head(t_shirt_colors);
  const { base_product_sizes2 } = sel('_', selected_t_shirt_color);

  const select_options = map(
    (item) => ({
      label: makeTShirtName(item),
      value: item.id,
      thumb: item.model_thumb_url,
      selected: item.id == bps_id,
    }),
    base_product_sizes2,
  );
  return makeSelectOptions('t_size', select_options);
};

export const makeTShirtsHtml = (t_shirt_product) => {
  const [title, t_shirts] = t_shirt_product;
  const selected_t_shirt = head(t_shirts);
  const select_options = map(
    (item) => ({
      label: makeTShirtName(item),
      value: item.id,
      thumb: item.model_thumb_url,
      selected: item.id === selected_t_shirt.id,
    }),
    t_shirts,
  );

  return html`
    <div class="pd-field" id="t_shirt_product">
      <div class="pd-field__label">${title}(택1)</div>
      ${makeSelectOptions('t_shirts', select_options)}
      <div id="t_shirts_sizes">${makeTShirtSizesHtml(selected_t_shirt)}</div>
    </div>
  `;
};

export const makePromotionalProductsHtml = (promotional_products) => html`
  <div id="promotional_products">
    ${strMap(([title, promotional_product_colors]) => {
      const selected_ppc = head(promotional_product_colors);
      const select_options = map(
        (item) => ({
          label: makePromotionalProductName(item['name' + _en]),
          value: item.id,
          thumb: item.model_thumb_url,
          selected: item.id === selected_ppc.id,
        }),
        promotional_product_colors,
      );

      return html`
        <div class="pd-field">
          <div class="pd-field__label">${title}(택1)</div>
          ${makeSelectOptions(title, select_options)}
        </div>
      `;
    }, promotional_products)}
  </div>
`;
