import axios from 'axios';
import { $append, $el, $qs, $setCss, $setHTML, $setVal, $val } from 'fxdom/es';
import { go, last, sel } from 'fxjs/es';
import { makeFacebookEmbedHtml, makeYoutubeEmbedHtml } from '../../ProductStory/S/tmpl.js';
import { makeInstagramEmbedHtml, makeTwitterEmbedHtml } from '../S/tmpl.js';

const snsWarning = ($input, $wrap, description) =>
  go(
    $setHTML('', $wrap),
    () => $.alert(T(description)),
    () => $setVal('', $input),
    () => $input.focus(),
  );

const snsInitEditor = (e, sns, regexp, description, executeFunc) => {
  const $input = $qs(`#${sns}-section input`);
  const embed = $val($input);
  const $wrap = $qs(`#${sns}-section .sns-wrap`);
  if (!embed) return $setHTML('', $wrap);
  if (e && embed.search(regexp) == -1) return snsWarning($input, $wrap, description);
  return executeFunc($input, $wrap, description, embed);
};

export const snsInitYoutubeEditor = (e) =>
  snsInitEditor(
    e,
    'youtube',
    'youtu',
    '유튜브 비디오의 링크를 입력해 주세요.',
    ($input, $wrap, description, embed) => $setHTML(makeYoutubeEmbedHtml(embed), $wrap),
  );

export const snsInitInstagramEditor = async (e) => {
  return snsInitEditor(
    e,
    'instagram',
    'instagram',
    '인스타그램 글의 링크를 입력해 주세요.',
    async ($input, $wrap, description, embed) => {
      $setHTML(makeInstagramEmbedHtml(embed), $wrap);
      if (window.instgrm) {
        $setCss(
          {
            visibility: 'hidden',
          },
          $wrap,
        );
        try {
          await go(
            axios.get(`/${T.lang}/@api/insta/embed`, {
              params: {
                url: embed,
              },
            }),
            sel('data'),
            (html) => $append($el(html), $wrap),
            () => instgrm.Embeds.process(),
          );
        } catch (e) {
          return snsWarning($input, $wrap, e.response?.data || e.message);
        }
        setTimeout(() => {
          $setCss(
            {
              visibility: '',
            },
            $wrap,
          );
        }, 1000);
      }
    },
  );
};

export const snsInitTwitterEditor = async (e) =>
  snsInitEditor(
    e,
    'twitter',
    /twitter|x/,
    '트위터 글의 링크를 입력해 주세요.',
    async ($input, $wrap, description, embed) => {
      $setHTML(makeTwitterEmbedHtml(embed), $wrap);
      if (window.twttr)
        try {
          await twttr.widgets
            .createTweet(last(embed.split('/'))?.replace(/\?.+|#.+/, ''), $wrap)
            .then(function ($el) {
              if (!$el) return snsWarning($input, $wrap, description);
            });
        } catch (e) {
          return snsWarning($input, $wrap, description);
        }
    },
  );

export const snsInitFacebookEditor = (e) =>
  snsInitEditor(
    e,
    'facebook',
    'facebook',
    '페이스북 글의 링크를 입력해 주세요.',
    async ($input, $wrap, description, embed) => {
      $setHTML(makeFacebookEmbedHtml(embed), $wrap);
      if (window.FB) return new Promise((rs) => FB.XFBML.parse(void 0, rs));
    },
  );
