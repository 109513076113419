import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { sellerItem2, mallBrandItem, mallItem, mallImgItem } from './seller_item.js';

export const allList = ({ sellers, chosung = '가', count }) => html`
  <div class="mall-seller-list mall-seller-list-all'">
    <div class="mall-seller-list__header">
      <h3 class="mall-seller-list__title">
        ${T('mshop::Seller::명', {
          count: `<span class="mall-seller-list__count">${UtilS.commify(count) || 0}</span>`,
        })}
      </h3>
      <div class="mall-seller-list__btn_align">
        <span class="mall-seller-list__btn_align_icon">${T('mshop::Menu::가나다')}</span>
      </div>
    </div>
    <div class="mall-seller-list-all__abc">
      <ul class="mall-seller-list-all__abc_wrap">
        <li
          data-chosung="가"
          class="mall-seller-list-all__abc_item${chosung === '가'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          가
        </li>
        <li
          data-chosung="나"
          class="mall-seller-list-all__abc_item${chosung === '나'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          나
        </li>
        <li
          data-chosung="다"
          class="mall-seller-list-all__abc_item${chosung === '다'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          다
        </li>
        <li
          data-chosung="라"
          class="mall-seller-list-all__abc_item${chosung === '라'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          라
        </li>
        <li
          data-chosung="마"
          class="mall-seller-list-all__abc_item${chosung === '마'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          마
        </li>
        <li
          data-chosung="바"
          class="mall-seller-list-all__abc_item${chosung === '바'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          바
        </li>
        <li
          data-chosung="사"
          class="mall-seller-list-all__abc_item${chosung === '사'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          사
        </li>
        <li
          data-chosung="아"
          class="mall-seller-list-all__abc_item${chosung === '아'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          아
        </li>
        <li
          data-chosung="자"
          class="mall-seller-list-all__abc_item${chosung === '자'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          자
        </li>
        <li
          data-chosung="차"
          class="mall-seller-list-all__abc_item${chosung === '차'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          차
        </li>
        <li
          data-chosung="카"
          class="mall-seller-list-all__abc_item${chosung === '카'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          카
        </li>
        <li
          data-chosung="타"
          class="mall-seller-list-all__abc_item${chosung === '타'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          타
        </li>
        <li
          data-chosung="파"
          class="mall-seller-list-all__abc_item${chosung === '파'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          파
        </li>
        <li
          data-chosung="하"
          class="mall-seller-list-all__abc_item${chosung === '하'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          하
        </li>
        <li
          data-chosung="123"
          class="mall-seller-list-all__abc_item${chosung === '123'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          123
        </li>
        <li
          data-chosung="A-Z"
          class="mall-seller-list-all__abc_item${chosung === 'A-Z'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          A-Z
        </li>
        <li
          data-chosung="기타"
          class="mall-seller-list-all__abc_item${chosung === '기타'
            ? ' mall-seller-list-all__abc_item--on'
            : ''}"
        >
          ${T('mshop::Seller::기타')}
        </li>
      </ul>
    </div>
    <div class="mall-seller-list__body mall-seller-list-all__body">${strMap(mallItem, sellers)}</div>
  </div>
`;

export const sellerList = ({ sellers, count, so_id }) => html`
  <div class="mall-seller-list mall-seller-list-seller">
    <div class="mall-seller-list__header">
      <h3 class="mall-seller-list__title" style="display: none;">
        ${T('mshop::Seller::명', {
          count: `<span class="mall-seller-list__count">${UtilS.commify(count) || 0}</span>`,
        })}
      </h3>
      <div class="mall-seller-list__btn_align">
        <span class="mall-seller-list__btn_align_icon">${T('mshop::Menu::인기 순')}</span>
      </div>
    </div>
    <div
      class="mall-seller-list__body mall-seller-list-seller__body${so_id
        ? ' mall-seller-list-seller__body--official'
        : ''}"
    >
      ${strMap(so_id ? mallImgItem : sellerItem2, sellers)}
    </div>
  </div>
`;

export const brandList = ({ crews, count, so_id }) => html`
  <div class="mall-seller-list mall-seller-list-brand">
    <div class="mall-seller-list__header">
      <h3 class="mall-seller-list__title" style="display: none;">
        ${T('mshop::Seller::건', {
          count: `<span class="mall-seller-list__count">${UtilS.commify(count) || 0}</span>`,
        })}
      </h3>
      <div class="mall-seller-list__btn_align">
        <span class="mall-seller-list__btn_align_icon">${T('mshop::Menu::인기 순')}</span>
      </div>
    </div>
    <div
      class="mall-seller-list__body mall-seller-list-brand__body${so_id
        ? ' mall-seller-list-brand__body--official'
        : ''}"
    >
      ${strMap((crew) => mallBrandItem({ crew }), crews)}
    </div>
  </div>
`;
