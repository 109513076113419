import { go, map, filter, find, head } from 'fxjs/es';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';

export const getBpOptionGroups = (product) => {
  return go(
    product._.base_product._.base_products_bp_option_groups,
    map((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group),
  );
};
export const getBpOptionGroupByCategory = ({ product, category }) => {
  return go(product, getBpOptionGroups, (bp_option_groups) =>
    BpOptionS.getBpOptionGroupByCategory({ bp_option_groups, category }),
  );
};

export const getSelectedOptionGroupBpOptions = ({ product, category }) => {
  const selected_option_group = product._.selected_option_group;
  return go(
    product,
    getBpOptionGroups,
    (bp_option_groups) => BpOptionS.getBpOptionGroupByCategory({ bp_option_groups, category })._.bp_options,
    // flatMap((base_products_bp_option_group) => base_products_bp_option_group._.bp_options),
    filter((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
    // take(1),
  );
};

export const isUnavailableSize = (product) => {
  const bps_id = product.base_product_size_id;
  return go(
    product._.base_product_color._.base_product_sizes2,
    find((bps) => bps.id == bps_id),
    (bps) => !bps || bps._is_discontinued || bps._is_not_stock,
  );
};

export const getAvailableBaseProductSize = (product) => {
  return go(
    product._.base_product._.base_product_color._.base_product_sizes2,
    filter((bps) => !bps._is_discontinued && !bps._is_not_stock),
    head,
  );
};
