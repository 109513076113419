import $dataStr from 'fxdom/es/dataStr.js';
import { flat, go, html, strMap, zipWithIndexL } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
// import { commify } from '../../../../../Util/S/util.js';
import { MShopAppProductItemTmplS } from '../../../Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { MShopAppSellerS } from '../../../Seller/S/Function/module/MShopAppSellerS.js';
import { MShopAppHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppHomeTmplS.js';

export const live = (title, live, sec_header = '', liveTab) => html`
  ${MShopAppHomeTmplS.homeTitle({
    title,
    sub_title: sec_header || T('mshop::채널ON'),
  })}
  <section class="creator-live">
    ${go(
      live,
      zipWithIndexL,
      strMap(([index, { title, description, product, platform, iframe_url, stream_url }]) => {
        const isAutoPlay = liveTab ? index == 0 : true;
        const queryString = iframe_url.includes('?') ? '&' : '?';

        return html`
          <div class="creator-live__row">
            <div class="creator-live__column">
              <div class="creator-live__iframe-box">
                ${platform === 'youtube'
                  ? html` <iframe
                      data-src="${iframe_url}${queryString}mute=1&autoplay=${isAutoPlay ? 1 : 0}"
                      class="creator-live__iframe don_lazy"
                      width="100%"
                      height="100%"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen="false"
                    ></iframe>`
                  : platform === 'afreecatv'
                  ? html` <iframe
                      data-src="${iframe_url}${queryString}autoplay=${isAutoPlay}"
                      class="creator-live__iframe don_lazy"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      allowfullscreen="false"
                    ></iframe>`
                  : html` <iframe
                      data-src="${iframe_url}${queryString}muted=true&autoplay=${isAutoPlay}"
                      class="creator-live__iframe don_lazy"
                      frameborder="0"
                      allowfullscreen="false"
                      scrolling="no"
                      height="100%"
                      width="100%"
                    ></iframe>`}
              </div>
            </div>
            <div class="creator-live__column creator-live__column--space-between">
              <div class="creator-live__inner-column">
                <a href="${stream_url}" class="creator-live__link" target="_blank">
                  <h1 class="creator-live__title">${title}</h1>
                  <p class="creator-live__description">${description}</p>
                </a>
                <div class="creator-live__products">
                  ${MShopAppProductItemTmplS.item(['v2', 'horizontal'])(product)}
                </div>
              </div>
              <a
                href="/${T.lang}/${product._.stores_product._.store.domain_name}"
                class="creator-live__anchor"
                data-post-message="${$dataStr({
                  screen: {
                    title: product._.stores_product._.store?.name,
                    titleImage: product._.stores_product._.store?.logo_img,
                    type: 'util',
                    uri: `/${T.lang}/${product._.stores_product._.store.domain_name}`,
                  },
                })}"
              >
                ${T('mshop::마플샵 바로가기', { name: product._.stores_product._.store.name })}
              </a>
            </div>
          </div>
        `;
      }),
    )}
  </section>
`;

export const nboxLive = (title, live, sec_header = '', liveTab, is_nbox, is_mobile) => {
  return html`
    ${MShopAppHomeTmplS.homeTitle({
      title,
      sub_title: sec_header || T('mshop::채널ON'),
    })}
    <section class="creator-live">
      ${go(
        live,
        zipWithIndexL,
        strMap(([index, { title, description, product, platform, iframe_url, stream_url }]) => {
          const isAutoPlay = liveTab ? index == 0 : true;
          const queryString = iframe_url.includes('?') ? '&' : '?';

          return html`
            <div class="creator-live__row">
              <div class="creator-live__column">
                <div class="creator-live__iframe-box">
                  ${platform === 'youtube'
                    ? html` <iframe
                        data-src="${iframe_url}${queryString}mute=1&autoplay=${isAutoPlay ? 1 : 0}"
                        class="creator-live__iframe don_lazy"
                        width="100%"
                        height="100%"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="false"
                      ></iframe>`
                    : platform === 'afreecatv'
                    ? html` <iframe
                        data-src="${iframe_url}${queryString}autoplay=${isAutoPlay}"
                        class="creator-live__iframe don_lazy"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        allowfullscreen="false"
                      ></iframe>`
                    : html` <iframe
                        data-src="${iframe_url}${queryString}muted=true&autoplay=${isAutoPlay}"
                        class="creator-live__iframe don_lazy"
                        frameborder="0"
                        allowfullscreen="false"
                        scrolling="no"
                        height="100%"
                        width="100%"
                      ></iframe>`}
                </div>
              </div>
              <div class="creator-live__column creator-live__column--space-between">
                <div class="creator-live__inner-column">
                  <a href="${stream_url}" class="creator-live__link" target="_blank">
                    <h1 class="creator-live__title">${title}</h1>
                  </a>
                  <div class="creator-live__products">
                    ${MShopAppProductItemTmplS.item(
                      flat(['v2', is_nbox ? 'nbox' : [], is_mobile ? 'horizontal' : [], 'live']),
                    )(product)}
                  </div>
                </div>
              </div>
            </div>
          `;
        }),
      )}
    </section>
  `;
};
export const popular_seller = ({ seller, current_rank, last_rank = 0 }) => {
  const changed_rank = last_rank - current_rank;
  const store_data = MShopAppSellerS.setSnsData(seller.sns_url || '');
  const post_msg = {
    screen: {
      type: 'util',
      title: seller.name.trim() || 'No name',
      titleImage: seller.logo_img,
      uri: `/${T.lang}/${seller.domain_name}`,
    },
  };

  return html`
    <div class="ts">
      <div class="ts__thumb">
        <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}">
          <div
            class="ts__thumb-img"
            style="background-image: url(${UtilImageS.getResizedUrl({
              url:
                seller.thumbnail_img ||
                '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png',
              quality: 70,
              width: 300,
              format: 'jpg',
            })});"
          ></div>
        </a>
        ${seller.sns_url
          ? html`
              <div class="ts__sns">
                <a href="${store_data.link}" target="_blank"
                  ><img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt=""
                /></a>
              </div>
            `
          : ''}
      </div>
      <div class="ts__info">
        <div>
          <div class="ts__rank app-product-item__rank"><span>${current_rank}</span></div>
          <div class="ts__name">
            <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}"> ${seller.name} </a>
          </div>
        </div>
        <div>
          <div
            class="ts__grown ${!last_rank ? 'new' : changed_rank > 0 ? 'up' : changed_rank < 0 ? 'down' : ''}"
          >
            ${!last_rank ? T('NEW') : changed_rank == 0 ? '-' : Math.abs(changed_rank)}
          </div>
        </div>
      </div>
    </div>
  `;
};

export const review_king = ({ rank, store }) => {
  const store_data = MShopAppSellerS.setSnsData(store.sns_url || '');
  const post_msg = {
    screen: {
      type: 'util',
      title: store.name.trim() || 'No name',
      titleImage: store.logo_img,
      uri: `/${T.lang}/${store.domain_name}/review`,
    },
  };

  /*
  <span class="trs__review-txt">${T('리뷰')}&nbsp;</span>
  <span class="trs__review-cnt">${commify(parseInt(store.count))}</span>
  */

  return html`
    <div class="trs">
      <div class="trs__thumb">
        <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}">
          <div
            class="trs__thumb-img"
            style="background-image: url(${UtilImageS.getResizedUrl({
              url:
                store.thumbnail_img ||
                '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png',
              quality: 70,
              width: 300,
              format: 'jpg',
            })});"
          ></div>
        </a>
        ${store.sns_url
          ? html`
              <div class="trs__sns">
                <a href="${store_data.link}" target="_blank"
                  ><img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt=""
                /></a>
              </div>
            `
          : ''}
      </div>
      <div>
        <div class="trs__rank app-product-item__rank"><span>${rank}</span></div>
        <div class="trs__name">
          <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}">${store.name}</a>
        </div>
        <div class="trs__info">
          <span class="trs__star">
            <img
              src="//s3.marpple.co/files/u_218933/2020/7/original/18721440c0afd9a23ab9b40af691518b383cfaa69b12121.png"
              alt=""
            />
          </span>
          <span class="trs__avg">${parseFloat(store.avg).toFixed(1)}</span>
        </div>
      </div>
    </div>
  `;
};

export const communityReviewKing = ({ rank, store }) => {
  const store_data = MShopAppSellerS.setSnsData(store.sns_url || '');
  const post_msg = {
    screen: {
      type: 'util',
      title: store.name.trim() || 'No name',
      titleImage: store.logo_img,
      uri: `/${T.lang}/${store.domain_name}/review`,
    },
  };

  /*
  <span class="trs__review-txt">${T('리뷰')}&nbsp;</span>
  <span class="trs__review-cnt">${commify(parseInt(store.count))}</span>
  */

  return html`
    <div class="community-review-king-trs">
      <div class="community-review-king-trs__thumb">
        <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}">
          <div
            class="community-review-king-trs__thumb-img"
            style="background-image: url(${UtilImageS.getResizedUrl({
              url:
                store.thumbnail_img ||
                '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png',
              quality: 70,
              width: 300,
              format: 'jpg',
            })});"
          ></div>
        </a>
        ${store.sns_url
          ? html`
              <div class="community-review-king-trs__sns">
                <a href="${store_data.link}" target="_blank"
                  ><img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt=""
                /></a>
              </div>
            `
          : ''}
      </div>
      <div>
        <div class="community-review-king-trs__rank app-product-item__rank"><span>${rank}</span></div>
        <div class="community-review-king-trs__name">
          <a href="${post_msg.screen.uri}" data-post-message="${$dataStr(post_msg)}">${store.name}</a>
        </div>
        <div class="community-review-king-trs__info">
          <span class="community-review-king-trs__star">
            <img
              src="//s3.marpple.co/files/u_218933/2020/7/original/18721440c0afd9a23ab9b40af691518b383cfaa69b12121.png"
              alt=""
            />
          </span>
          <span class="community-review-king-trs__avg">${parseFloat(store.avg).toFixed(1)}</span>
        </div>
      </div>
    </div>
  `;
};

/*
** YOUTUBE
<iframe
  src="https://www.youtube.com/embed/-PjnffX0iXM"
  class="creator-live__iframe"
  width="100%"
  height="100%"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen="false"
  style="display: none;"
></iframe>
*
** AFREECA TV
<iframe
  src="https://player.twitch.tv/?channel=saddummy&parent=marpple.shop"
  class="creator-live__iframe"
  frameborder="0"
  allowfullscreen="false"
  scrolling="no"
  height="100%"
  width="100%"
  style="display: none;"
></iframe>
* */
