export const QUESTION_TYPES = {
  question: 'QUESTION',
  answer: 'ANSWER',
  add_question: 'ADD_QUESTION',
  add_answer: 'ADD_ANSWER',
  complete: 'COMPLETE',
};

export const QUESTION_ERRORS = {
  required: {
    value: 'required',
    get text() {
      return ET('mps2::question::필수 입력 사항입니다.');
    },
  },
  mobile_check: {
    value: 'mobile_check',
    get text() {
      return ET('mps2::question::정확한 연락처를 입력해주세요.');
    },
  },

  email_check: {
    value: 'email_check',
    get text() {
      return ET('mps2::question::정확한 이메일을 입력해 주세요.');
    },
  },

  text_length_100: {
    value: 'text_length_100',
    get text() {
      return ET('mps2::question::100자 이하로 입력해주세요.');
    },
  },

  text_length_255: {
    value: 'text_length_255',
    get text() {
      return ET('mps2::question::250자 이하로 입력해주세요.');
    },
  },

  text_length_300: {
    value: 'text_length_300',
    get text() {
      return ET('mps2::question::300자 이하로 입력해주세요.');
    },
  },

  text_length_2000bytes: {
    value: 'text_length_2000',
    get text() {
      return ET('mps2::question::2000bytes 이하로 입력해주세요.');
    },
  },

  text_empty: {
    value: 'text_empty',
    get text() {
      return ET('mps2::question::문의 내용을 입력해 주세요.');
    },
  },

  restrict_character: {
    value: 'restrict_character',
    get text() {
      return ET('mps2::question::특수문자를 제외하고 입력해주세요.');
    },
  },

  only_number: {
    value: 'only_number',
    get text() {
      return ET('mps2::question::숫자만 입력해 주세요.');
    },
  },

  is_agree: {
    value: 'is_agree',
    get text() {
      return ET('mps2::question::개인 정보 수집 및 이용 동의후 접수해주세요.');
    },
  },
};
