import { legacyHtml } from '../../../Util/S/Function/util.js';
import { UtilF } from '../../../Util/F/Function/module/UtilF.js';

function makeControlNumberHtml(pattern_data) {
  return legacyHtml`
    <div class="controls number">
      <div class="head">${T(`${!UtilF.isLegacyMobile() ? 'pattern' : 'pattern_mobile'}::Copies`)}</div>
      <div class="body">
        <div class="control horizontal number">
          <input type="number" value="${pattern_data.x}" disabled number_name="horizontal" min="1" max="40" />
          <div class="minus operator" operator="-"></div>
          <div class="plus operator" operator="+"></div>
        </div>
        <div class="control locking" locked="${pattern_data.x == pattern_data.y}"></div>
        <div class="control vertical number">
          <input type="number" value="${pattern_data.y}" disabled number_name="vertical" min="1" max="40" />
          <div class="minus operator" operator="-"></div>
          <div class="plus operator" operator="+"></div>
        </div>
      </div>
      <div class="option block"></div>
    </div>
  `;
}

function makeControlSizeHtml(pattern_data, is_disabled) {
  return legacyHtml`
    <div class="controls size range_style">
      <div class="head">${T(`${!UtilF.isLegacyMobile() ? 'pattern' : 'pattern_mobile'}::Pattern-Size`)}</div>
      <div class="body">
        <div class="control range">
          <input type="range" step="1" min="1" max="100" value="${pattern_data.size}" />
          <div class="ho_man"></div>
        </div>
        <div class="control number">
          <input
            type="number"
            class="value"
            value="${pattern_data.size}"
            number_name="size"
            min="1"
            max="100"
            ${is_disabled ? 'disabled' : ''}
          />
        </div>
      </div>
    </div>
  `;
}

function makeControlTranslateXHtml(pattern_data, is_disabled) {
  return legacyHtml`
    <div class="controls translateX range_style">
      <div class="head">${T(`${!UtilF.isLegacyMobile() ? 'pattern' : 'pattern_mobile'}::Pattern-Move`)}</div>
      <div class="body">
        <div class="control range">
          <input type="range" step="1" min="0" max="100" value="${pattern_data.translateX}" />
          <div class="ho_man"></div>
        </div>
        <div class="control number">
          <input
            type="number"
            class="value"
            value="${pattern_data.translateX}"
            number_name="translateX"
            min="1"
            max="100"
            ${is_disabled ? 'disabled' : ''}
          />
        </div>
      </div>
    </div>
  `;
}

function makeControlTypeHtml(pattern_data) {
  function selected(pattern_data, type) {
    return pattern_data.style === type ? 'selected' : '';
  }
  return legacyHtml`
    <div class="controls type">
      <div class="head">${T(`${!UtilF.isLegacyMobile() ? 'pattern' : 'pattern_mobile'}::Pattern-Type`)}</div>
      <div class="body control type btn">
        <button class="repeat ${selected(pattern_data, 'repeat')}" type="button" pattern_style="repeat">
          ${T('pattern::Repeat')}
        </button>
        <button class="grid_1 ${selected(pattern_data, 'grid_1')}" type="button" pattern_style="grid_1">
          ${T('pattern::Lattice1')}
        </button>
        <button class="grid_2 ${selected(pattern_data, 'grid_2')}" type="button" pattern_style="grid_2">
          ${T('pattern::Lattice2')}
        </button>
      </div>
    </div>
  `;
}
export function makeCvPatternHtml(pattern_data) {
  const is_one_by_one = pattern_data.x == 1 && pattern_data.y == 1;
  const checked = is_one_by_one ? '' : 'checked';
  const default_pattern = is_one_by_one ? 'default_pattern' : '';
  return legacyHtml`
    <div class="m_image_pattern section ready ${default_pattern}">
      <div class="head ${checked}">
        <div class="option check_box"></div>
        <div class="title">${T('Pattern')}</div>
      </div>
      <div class="body">
        ${makeControlNumberHtml(pattern_data)} ${makeControlSizeHtml(pattern_data)}
        ${makeControlTranslateXHtml(pattern_data)} ${makeControlTypeHtml(pattern_data)}
      </div>
    </div>
  `;
}

export function makeCvPatternMobileHtml(pattern_data) {
  const is_one_by_one = pattern_data.x == 1 && pattern_data.y == 1;
  const default_pattern = is_one_by_one ? 'default_pattern' : '';
  return legacyHtml`
    <div class="m_image_pattern section image_editor_ev ${default_pattern}">
      ${makeControlNumberHtml(pattern_data)} ${makeControlSizeHtml(pattern_data, true)}
      ${makeControlTranslateXHtml(pattern_data, true)} ${makeControlTypeHtml(pattern_data)}
    </div>
  `;
}
