import { html, sel, strMap, take } from 'fxjs/es';
import { MShopAppCommunityTmplS } from '../../../Community/S/Tmpl/module/MShopAppCommunityTmplS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

export const community = ({ title, sub_title, communities, is_all_crew, is_mobile }) => {
  const community_post_message = is_all_crew
    ? {
        tabNavigate: 'CommunityStack',
      }
    : {
        screen: {
          type: 'util',
          title: T('mshop::Community'),
          uri: `/${T.lang}/@/communities`,
        },
      };

  return html`
    <div class="app-home-community">
      ${MShopAppHomeTmplS.homeTitle({
        title,
        sub_title,
        href: `/${T.lang}/@/communities`,
        link: community_post_message,
      })}
      <div class="app-home-community-list">
        <div class="app-home-community-list__container">
          ${strMap(
            (article) =>
              MShopAppCommunityTmplS.reviewItem({
                article,
                store: sel('_.store', article),
              }),
            take(4, communities),
          )}
        </div>
      </div>
    </div>
  `;
};
