import klass from '../../F/Style/feed_detail_admin.module.scss';

export const klasses = {
  detail_admin: klass.feed_detail_admin,
  related_product_add_modal: 'related_product_add_modal',
  embed_products_add_modal: 'embed_products_add_modal',
};

export const selectors = {
  detail_admin_klass: `.${klass.feed_detail_admin}`,
};

export const event_fields = [
  'content',
  'thumbnail',
  'mobile_thumbnail',
  'type',
  'title',
  'title_en',
  'title_jp',
  'description',
  'description_en',
  'description_jp',
  'is_public',
  'show_home',
  'main_order',
  'feed_category_id',
  'thumb_ratio',
  'thumb_preview_products',
  'detail_kv',
  'cate_list_id',
  'tags',
  'seo_description',
  'seo_title',
  'slug',
  'numbering',
  'creator_name',
  'sub_title_intl',
  'show_start_at',
  'show_end_at',
  'mps_type',
  'product_ids',
  'related_link_text_intl',
  'related_link',
];

export const ness_event_fields = [
  'content',
  'thumbnail',
  'mobile_thumbnail',
  'type',
  'title',
  'title_en',
  'title_jp',
  'description',
  'description_en',
  'description_jp',
  'is_public',
  'show_home',
  'main_order',
  'feed_category_id',
  'thumb_ratio',
  'thumb_preview_products',
  'detail_kv',
  'cate_list_id',
  'tags',
  'seo_description',
  'seo_title',
  'slug',
];
