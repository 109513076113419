import { iamportPhoneCertification2 as IamportCertificationFIamportPhoneCertification2 } from '../../../../Iamport/Certification/F/fs.js';
import { loadIamport as MPayCheckoutFLoadIamport } from '../../../../MPay/Checkout/F/fs.js';
import { openDetailNess as MShopAppCommunityFFunctionOpenDetailNess } from '../../../../MShop/App/Community/F/Function/community.js';
import { alert as MShopShareFramePopUpFFunctionAlert } from '../../../../MShop/ShareFrame/PopUp/F/Function/alert.js';
import { confirm as MShopShareFramePopUpFFunctionConfirm } from '../../../../MShop/ShareFrame/PopUp/F/Function/confirm.js';
import { isMobile as MShopUtilFFunctionIsMobile } from '../../../../MShop/Util/F/Function/util.js';
import { delegateUpdateLike as MShopAppProductItemFFunctionDelegateUpdateLike } from '../../../../MShop/App/Product/Item/F/Function/item.js';

export const isMobile = MShopUtilFFunctionIsMobile;
export const openReviewDetailNess = MShopAppCommunityFFunctionOpenDetailNess;
export const confirm = MShopShareFramePopUpFFunctionConfirm;
export const alert = MShopShareFramePopUpFFunctionAlert;

export const iamportPhoneCertification = IamportCertificationFIamportPhoneCertification2;
export const loadIamport = MPayCheckoutFLoadIamport;

export const delegateUpdateProductLike = MShopAppProductItemFFunctionDelegateUpdateLike;
export { initCheckbox } from '../../../../MShop/Core/F/Function/checkbox.js';
