import { log } from './config.js';

export const initLoadObserver = (f, { target_el, direction, loop = false }) => {
  let intersect = false;
  let on_fetch = false;

  const multiplier = window.innerWidth < 1024 ? 2 : 1;
  const vh = multiplier * window.innerHeight;

  const function_loop = async (entry, observer) => {
    if (!intersect) return;
    log('%c [start get]', 'color: green');
    on_fetch = true;
    const result = await f(entry, observer);
    log('%c [end get]', 'color: green');
    on_fetch = false;

    if (intersect && loop && result !== false) {
      log(
        `%c [loop] due to ${intersect ? 'intersect' : ''} ${result !== false ? 'result' : ''} [${direction}]`,
        'color: orange',
      );
      function_loop(entry, observer);
    }
  };

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (!entry.isIntersecting || entry.intersectionRatio == 0) {
        log(`%c -------------------------intersect out [${direction}]`, 'color: red');
        return (intersect = false);
      }

      intersect = true;
      log(`%c ++++++++++++++++++++++++++in [${direction}]`, 'color: blue');
      if (on_fetch) return;

      intersect = true;
      function_loop(entry, observer);
    },
    {
      rootMargin: `${vh}px 0px ${vh}px 0px`,
    },
  );

  observer.observe(target_el);

  const isIntersect = () => intersect;
  return isIntersect;
};
