import { html, on } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { ShareIcon } from '../../../atoms/Icon';
import { MShopUtilF } from '../../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { popToastMsg } from '../../../../../../../modules/MShop/Util/F/Function/toast';
import { runNavigatorShare } from '../../../../../shared/client/navigator';
import { FloatingButtonBase } from './FloatingButtonBase';
import { makeFloatingButtonTemplate } from '../util';

export type FloatingButtonShareData = {
  title: string;
  description: string;
};

type FloatingButtonShareOptions = {
  is_mobile: boolean;
};

export class FloatingButton_share extends FloatingButtonBase<FloatingButtonShareData & { show?: boolean }> {
  is_mobile: boolean;

  constructor(data: FloatingButtonShareData, options: FloatingButtonShareOptions) {
    super(data);

    this.is_mobile = options.is_mobile;
  }

  @on('click')
  override async handleClick() {
    super.handleClick();

    const copy_url = window.location.href;

    const copyToClipboard = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(copy_url);
      } else {
        MShopUtilF.clipboard(copy_url);
      }

      popToastMsg(T('s_about::url_copied2'), 'info');
    };

    const is_app = MShopUtilF.isApp();

    if (this.is_mobile && 'share' in navigator) {
      try {
        await runNavigatorShare({
          title: this.data.title,
          text: this.data.description,
          url: copy_url,
        });
      } catch (error) {
        copyToClipboard();
      }
    } else if (is_app) {
      return MShopUtilF.postMessage({
        share: {
          title: `${this.data.title} ${this.data.description}`,
          url: copy_url,
        },
      });
    } else {
      copyToClipboard();
    }
  }

  override template() {
    return makeFloatingButtonTemplate(
      html`<div class="${klass.floating_button}">
        <span class="${klass.button_icon}"> ${ShareIcon} </span>
      </div> `,
      this.state.show_itself,
    );
  }
}
