import { html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { MShopUtilS } from '../../../../Util/S/Function/module/MShopUtilS.js';
import { MShopAppSellerTmplS } from '../../../Seller/S/Tmpl/module/MShopAppSellerTmplS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

export const newSeller = (title, new_sellers, is_mobile, crew) => {
  const post_message = {
    screen: {
      title: `${T('mshop::신규')} ${MShopUtilS.getSellerWording(crew, true)}`,
      type: 'util',
      uri: `/${T.lang}/@/sellers/sub?type=new`,
    },
  };
  return html`
    <div class="app-home-new-seller">
      ${MShopAppHomeTmplS.homeTitle({
        title,
        sub_title: post_message.screen.title,
        link: post_message,
      })}
      <div class="mall-main-seller-list" data-length="${new_sellers.length}">
        ${strMap(MShopAppSellerTmplS.mallMainSellerItem, new_sellers)}
      </div>
      ${is_mobile
        ? html`
            <div class="app-home-new-seller__more">
              <a
                class="app-home__more-box"
                href="${post_message.screen.uri}"
                data-post-message="${$dataStr(post_message)}"
                >${T('mshop::더보기')}</a
              >
            </div>
          `
        : ''}
    </div>
  `;
};
