import dataStr from 'fxdom/es/dataStr.js';
import { equals, html, match, strMap } from 'fxjs/es';
import { Breadcrumbs } from '../../../../../../services/MarppleShop/renderApp/components/cells/BreadCrumbs/Breadcrumbs.ts';
import { shareIcon } from '../../../../Core/S/Tmpl/icon.js';
import format from 'date-fns/format/index.js';
import {
  ArrowDownIcon,
  ArrowUpicon,
  MShopDefaultIcon,
} from '../../../../../../services/MarppleShop/renderApp/components/atoms/Icon/index.ts';
import { MPS_TYPE } from '../Constant/today.js';
import { staticTypo, typo } from '../../../../../../services/MarppleShop/shared/typography/typo.js';

const component = 'today-body';
const relates_component = 'today-relates';

const makePrevNext = (prev_today, next_today, feed_category_id) => {
  const prev_link = prev_today && makeTodayLink(prev_today, feed_category_id);
  const next_link = next_today && makeTodayLink(next_today, feed_category_id);

  const makePostMessage = (uri) => ({
    replaceNavigate: {
      type: 'util',
      title: T('mshop::Content Detail'),
      uri,
    },
  });

  return html`
    <div class="${component}__navigate_container">
      ${!prev_today
        ? ''
        : html`<div class="${component}__navigate">
            <a
              class="${component}__navigate__link"
              href="${prev_link}"
              data-post-message="${dataStr(makePostMessage(prev_link))}"
            >
              <span class="${component}__navigate__icon ${staticTypo('unica_14_medium')}">
                ${ArrowUpicon({ htmlFn: html })} NEXT
              </span>
              <span class="${component}__navigate__title ${typo('14_bold')}"
                >${prev_today.category
                  ? html`<span class="${staticTypo('bebas_16_bold')} ${component}__navigate-category"
                      >${prev_today.category.toUpperCase()}</span
                    >`
                  : ''}
                ${prev_today.title}</span
              >
            </a>
          </div>`}
      ${!next_today || !prev_today ? '' : html`<hr />`}
      ${!next_today
        ? ''
        : html`<div class="${component}__navigate">
            <a
              class="${component}__navigate__link"
              href="${next_link}"
              data-post-message="${dataStr(makePostMessage(next_link))}"
            >
              <span class="${component}__navigate__icon ${staticTypo('unica_14_medium')}">
                ${ArrowDownIcon({ htmlFn: html })} PREV
              </span>
              <span class="${component}__navigate__title ${typo('14_bold')}"
                >${next_today.category
                  ? html`<span class="${staticTypo('bebas_16_bold')} ${component}__navigate-category"
                      >${next_today.category.toUpperCase()}</span
                    >`
                  : ''}
                ${next_today.title}</span
              >
            </a>
          </div>`}
    </div>
  `;
};

const makeBaseListLink = (feed) => {
  return match(feed.mps_type)
    .case(equals('CREATOR_VIEW'))(() => '/@/creator_views')
    .case(equals('CURATION'))(() => `/@/curations`)
    .case(equals('PLUS'))(() => '/@/todays')
    .else(() => '');
};

const makeTodayLink = (feed, feed_category_id) => {
  const base_url = makeBaseListLink(feed);

  return `/${T.lang}${base_url}/${feed.id}${feed_category_id ? `?category=${feed_category_id}` : ''}`;
};

export const main_detail = ({
  is_mobile,
  is_sharable,
  today,
  related_todays_html,
  products_html,
  prev_today,
  next_today,
  feed_category_id,
  related_section_title,
  show_related_see_more, // boolean
  steps,
}) => {
  const category_html =
    today.mps_type === MPS_TYPE.plus
      ? '' // plus content
      : today.mps_type === MPS_TYPE.curation
      ? html`<span class="${component}__title-upper ${staticTypo('bebas_24_bold')}">${today.category}</span>` // curation
      : html`<span class="${component}__title-upper ${staticTypo('bebas_24_bold')}"
          >VOL.${today.numbering}</span
        >`; // creator view

  return html`
    <div class="${component} ${today.mps_type.toLowerCase()}" data-mps_type="${today.mps_type.toLowerCase()}">
      ${is_mobile || !steps
        ? ''
        : html`<div class="${component}__breadcrumb">
            ${steps ? new Breadcrumbs({ steps }).toHtml() : ''}
          </div>`}

      <div class="${component}__container">
        <div class="${component}__left_sidebar sticky">
          <div class="${component}__meta-container">
            <div class="${component}__title-container">
              <div class="${component}__title-text-container">
                ${category_html}
                <h1 class="${component}__title ${typo(is_mobile ? '32_bold' : '36_bold')}">
                  ${today.title?.replace(/\n/g, '<br/>') || ''}
                </h1>
              </div>
              ${is_mobile
                ? ''
                : html` <div class="${component}__share-container">
                    ${is_sharable ? html`<span class="share-btn"> ${shareIcon()} </span>` : ''}
                  </div>`}
            </div>
            <div class="${component}__description ${typo(is_mobile ? '14_bold' : '16_bold')}">
              ${today.description?.replace(/\n/g, '<br/>') || ''}
            </div>
            <div class="${component}__publication-date ${staticTypo('unica_16_medium')}">
              ${format(new Date(today.public_at), 'yyyy.MM.dd')}
            </div>
            ${today.related_link_text && today.related_link
              ? html`<a
                  href="${today.related_link}"
                  class="${component}__related-link-btn ${typo('16_bold')}"
                >
                  ${today.related_link_text} ${MShopDefaultIcon({ htmlFn: html })}
                </a>`
              : ''}
          </div>

          ${is_mobile ? '' : makePrevNext(prev_today, next_today, feed_category_id)}
        </div>
        <div class="${component}__content">
          <div class="editor ${typo('16_medium')}">${today.content}</div>
          ${today.mps_type === MPS_TYPE.curation && today.tags?.length > 0
            ? html` <div class="${component}__tags ${typo('14_medium')}">
                ${strMap((tag) => html`<span class="${component}__tag">#${tag}</span>`, today.tags)}
              </div>`
            : ''}
          ${products_html
            ? html`
                <h3 class="${component}__products-title ${staticTypo('bebas_32_bold')}">
                  CREATOR’S PRODUCTS
                </h3>
                ${products_html}
              `
            : ''}
        </div>
      </div>
    </div>
    ${related_todays_html
      ? html` <div class="${relates_component}" data-mps_type="${today.mps_type.toLowerCase()}">
          <h2 class="${relates_component}__title ${staticTypo('bebas_32_bold')}">
            ${is_mobile ? 'RELATED' : related_section_title}
            ${show_related_see_more
              ? html`<a
                  href="${makeBaseListLink(today)}${today.category_id
                    ? `?category=${today.category_id}`
                    : ''}"
                  class="${relates_component}__see-more ${staticTypo(
                    is_mobile ? 'unica_12_medium' : 'unica_16_regular',
                  )}"
                >
                  See More
                </a>`
              : ''}
          </h2>

          <div class="${relates_component}__items swiper">
            <div class="swiper-wrapper">${related_todays_html}</div>
          </div>
        </div>`
      : ''}
  `;
};

// export const main_detail = ({
//                               is_mobile,
//                               is_sharable,
//                               today,
//                               related_todays_html,
//                               prev_today,
//                               next_today,
//                               feed_category_id,
//                               related_section_title,
//                             }) => {
//   return html`
//     <div class="${component}">
//       <div class="${component}__left_sidebar sticky">
//         <div class="${component}__meta-container">
//           <div class="${component}__title-container">
//             <div class="${component}__title-text-container">
//               <span class="${staticTypo('bebas_24_bold')}">${today.category}</span>
//               <h1 class="${component}__title ${typo('36_bold')}">
//                 ${today.title?.replace(/\n/g, '<br/>') || ''}
//               </h1>
//             </div>
//             ${is_mobile
//     ? ''
//     : html`<div class="${component}__share-container">
//                   ${is_sharable ? html`<span class="share-btn"> ${shareIcon()} </span>` : ''}
//                 </div>`}
//           </div>
//           <div class="${component}__description ${typo('16_bold')}">${today.description || ''}</div>
//           <div class="${component}__publication-date ${staticTypo('unica_16_medium')}">
//             ${format(new Date(today.public_at), 'yyyy.MM.dd')}
//           </div>
//           ${today.related_link_text_intl?.[T.lang]
//     ? html`<button class="${component}__related-link-btn ${typo('16_bold')}">
//                 ${today.related_link_text_intl[T.lang]} ${MShopDefaultIcon({ htmlFn: html })}
//               </button>`
//     : ''}
//         </div>
//
//         ${is_mobile ? '' : makePrevNext(prev_today, next_today, feed_category_id)}
//       </div>
//       <div class="${component}__content">
//         <div class="editor ${typo('16_medium')}">${today.content}</div>
//
//         <div class="${component}__footer">
//           ${is_mobile ? makePrevNext(prev_today, next_today, feed_category_id) : ''}
//         </div>
//       </div>
//     </div>
//     <div class="${component}__content__relates">
//       <h2 class="${component}__content__relates__title ${staticTypo('bebas_32_bold')}">
//         ${related_section_title}
//       </h2>
//       <div class="${component}__content__relates__items">${related_todays_html}</div>
//     </div>
//   `;
// };
