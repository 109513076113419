import { go } from 'fxjs/es';
import { $addClass, $el, $prependTo, $delegate, $remove, $appendTo, $removeClass, $closest } from 'fxdom/es';
import { NessUtilTmplS } from '../../S/Tmpl/module/NessUtilTmplS.js';

const end_class = 'toast-message--end';
const toast_message_types = ['error', 'info', 'confirm'];
let toast_container_el;

const checkToastMessageType = (type) => {
  if (!toast_message_types.includes(type)) throw new Error('토스트 메세지 타입이 올바르지 않습니다.');
};

const removeToastMessage = (message_el) => {
  $remove(message_el);
  $removeClass(end_class, message_el);
  if (!toast_container_el.children.length) {
    $remove(toast_container_el);
  }
};

const initToastContainer = () =>
  go(
    $el(NessUtilTmplS.createToastContainer()),
    $delegate('animationend', '.toast-message', (e) => {
      if (e.animationName !== 'toastOut') return;
      removeToastMessage(e.currentTarget);
    }),
    $delegate('click', '.toast-message__close', (e) => {
      removeToastMessage($closest('.toast-message', e.target));
    }),
  );

export const createToastMsg = (type, inner) => {
  checkToastMessageType(type);

  return $el(NessUtilTmplS.createToastItem(inner, { type }));
};

export const popToastMsg = (input_el, type) => {
  if (!toast_container_el) toast_container_el = initToastContainer();
  if (!toast_container_el.parentNode) $appendTo(document.body, toast_container_el);

  const item_el = input_el instanceof HTMLElement ? input_el : createToastMsg(type, input_el);

  $prependTo(toast_container_el, item_el);
  setTimeout(() => {
    $addClass(end_class, item_el);
  }, 2500);
};
