import { html, strMap, take } from 'fxjs/es';
import { MShopAppProductItemTmplS } from '../../../Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { MShopAppProductSubConstantS } from '../../../Product/Sub/S/Constant/module/MShopAppProductSubConstantS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

const { title } = MShopAppProductSubConstantS.SUB_TYPE.limit;
const msg_data = {
  screen: {
    title,
    type: 'util',
    uri: `/${T.lang}/@/product/list/limit`,
  },
};

export const limitProduct = ({ title, sub_title, limit_products, is_mobile }) =>
  html`
    <div class="app-home-limit-product__wrapper">
      <div class="app-home-limit-product" data-length="${limit_products?.length || 3}">
        ${MShopAppHomeTmplS.homeTitle({
          title,
          sub_title,
          link: limit_products?.length < 3 ? null : msg_data,
          theme: 'white',
          align: limit_products?.length < 3 ? 'center' : 'left',
        })}
        <div class="app-home-limit-product__list">
          ${strMap(
            MShopAppProductItemTmplS.item(['v2', 'nbox', 'home-limit'], '', true, is_mobile),
            take(3, limit_products),
          )}
        </div>
      </div>
    </div>
  `;
