import { html } from 'fxjs/es';
import { MShopAppTodayTmplS } from './module/MShopAppTodayTmplS.js';

export const main_page = (today_tags, selected_tag, is_mobile, is_all_crew) =>
  html`
    <div class="app-today">
      ${!is_all_crew
        ? ''
        : MShopAppTodayTmplS.makeCategorySlider(today_tags, {
            klass: 'app-today__container',
            selected: selected_tag,
            is_mobile,
          })}
      <div class="app-today__infinite__scroll app-today__container"></div>
    </div>
  `;
