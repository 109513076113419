import { html as runeHtml } from 'rune-ts';
import { html as fxjsHtml } from 'fxjs/es';

type IconProps = {
  html?: typeof runeHtml | typeof fxjsHtml;
  klass?: string;
};

export const makeArrowWithoutLineIcon = ({ html = runeHtml, klass = '' }: IconProps = {}) => html`
  <svg
    class="${klass}"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.333 11.2533C13.1863 11.2533 13.033 11.2 12.9197 11.08L7.99967 6.16L3.07967 11.08C2.85301 11.3067 2.47967 11.3067 2.25301 11.08C2.02634 10.8533 2.02634 10.48 2.25301 10.2533L7.58634 4.92C7.81301 4.69333 8.18634 4.69333 8.41301 4.92L13.7463 10.2533C13.973 10.48 13.973 10.8533 13.7463 11.08C13.633 11.1933 13.4797 11.2533 13.333 11.2533Z"
      fill="currentColor"
    />
  </svg>
`;

export const makeCheckedIcon = ({ html = runeHtml, klass = '' }: IconProps = {}) => html`
  <svg
    class="${klass}"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.87374 7.17495C3.53185 6.83305 2.97754 6.83306 2.63565 7.17495C2.29376 7.51684 2.29376 8.07115 2.63565 8.41304L5.98852 11.7659C6.30189 12.0793 6.80997 12.0793 7.12335 11.7659L13.3655 5.5238C13.7073 5.18191 13.7073 4.62759 13.3655 4.2857C13.0236 3.94381 12.4693 3.94381 12.1274 4.2857L6.96859 9.44448C6.74068 9.67238 6.37118 9.67238 6.14328 9.44448L3.87374 7.17495Z"
      fill="currentColor"
    />
  </svg>
`;

export const makeInstagramIcon = ({ html = runeHtml, klass = '' }: IconProps = {}) => html`
  <svg
    class="${klass}"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_7195_40709)">
      <path
        d="M16.6159 2H7.38511C5.95749 2.00153 4.58878 2.56932 3.5793 3.57881C2.56982 4.58829 2.00202 5.95699 2.00049 7.38462V16.6154C2.00202 18.043 2.56981 19.4117 3.5793 20.4212C4.58878 21.4307 5.95748 21.9985 7.38511 22H16.6159C18.0435 21.9985 19.4122 21.4307 20.4217 20.4212C21.4312 19.4117 21.999 18.043 22.0005 16.6154V7.38462C21.999 5.957 21.4312 4.58829 20.4217 3.57881C19.4122 2.56933 18.0435 2.00153 16.6159 2ZM12.0005 16.6154C11.0876 16.6154 10.1953 16.3447 9.43634 15.8375C8.67734 15.3304 8.08572 14.6096 7.73642 13.7662C7.38711 12.9228 7.29571 11.9948 7.4738 11.0996C7.65188 10.2043 8.09141 9.38191 8.73695 8.73645C9.38241 8.09091 10.2048 7.65137 11.1001 7.4733C11.9953 7.29521 12.9233 7.38661 13.7667 7.73592C14.6101 8.08523 15.3309 8.67684 15.838 9.43584C16.3452 10.1948 16.6159 11.0872 16.6159 12C16.6146 13.2237 16.128 14.3969 15.2626 15.2621C14.3974 16.1274 13.2242 16.6141 12.0005 16.6154ZM17.7697 7.38462C17.5415 7.38462 17.3184 7.31695 17.1286 7.19016C16.9389 7.06338 16.791 6.88317 16.7037 6.67234C16.6164 6.4615 16.5935 6.2295 16.638 6.00567C16.6826 5.78185 16.7925 5.57625 16.9538 5.41489C17.1152 5.25352 17.3208 5.14363 17.5446 5.09911C17.7684 5.05459 18.0004 5.07744 18.2113 5.16477C18.4221 5.2521 18.6023 5.39999 18.7291 5.58974C18.8559 5.77949 18.9236 6.00258 18.9236 6.23079C18.9236 6.53681 18.802 6.8303 18.5857 7.04668C18.3693 7.26306 18.0757 7.38462 17.7697 7.38462ZM15.0774 12C15.0774 12.6085 14.8969 13.2035 14.5589 13.7095C14.2208 14.2155 13.7402 14.6098 13.1779 14.8427C12.6157 15.0756 11.9971 15.1365 11.4002 15.0178C10.8033 14.8991 10.2551 14.606 9.8248 14.1757C9.39449 13.7454 9.10142 13.1972 8.98272 12.6003C8.86395 12.0034 8.92487 11.3848 9.1578 10.8225C9.39065 10.2603 9.78503 9.77969 10.291 9.44162C10.797 9.10354 11.3919 8.92308 12.0005 8.92308C12.8166 8.92308 13.5992 9.24723 14.1762 9.82431C14.7533 10.4013 15.0774 11.1839 15.0774 12Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7195_40709">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

export const makeMegaphoneIcon = ({ html = runeHtml, klass = '' }: IconProps = {}) => html`
  <svg
    class="${klass}"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_7198_25468)">
      <path
        d="M20.2314 8.21974L5.23047 3.63478C4.46436 3.40138 3.65625 3.54005 3.01221 4.01759C2.36914 4.49415 2.00049 5.22657 2.00049 6.02638V17.9737C2.00049 18.7735 2.36914 19.5059 3.01221 19.9824C3.45117 20.3076 3.9668 20.4756 4.49366 20.4756C4.73927 20.4756 4.98731 20.4385 5.23145 20.3643L12.1665 18.245V19.0742C12.1665 19.7774 12.7383 20.3496 13.4414 20.3496H16.8916C17.5947 20.3496 18.1665 19.7774 18.1665 19.0742V16.4113L20.2314 15.7803C21.2896 15.458 22.0005 14.4971 22.0005 13.3897V10.6104C22.0005 9.50295 21.2891 8.542 20.2314 8.21974ZM16.4165 18.5996H13.9165V17.7101L16.4165 16.9461V18.5996ZM20.0005 13.3897C20.0005 13.6113 19.8584 13.8037 19.6475 13.8682L13.9165 15.619V8.38057L19.6475 10.1319C19.8584 10.1963 20.0005 10.3887 20.0005 10.6104V13.3897Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7198_25468">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

export const makeBookMarkIcon = ({ html = runeHtml, klass = '' }: IconProps = {}) => html`
  <svg
    class="${klass}"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.24 2.23C20.1 2.09 19.9 2 19.7 2H6.62004C5.80004 2 5.02004 2.32 4.44004 2.9C3.86004 3.48 3.54004 4.26 3.54004 5.08V20.47C3.54004 21.32 4.23004 22.01 5.08004 22.01H15.91C16.39 22.01 16.79 21.62 16.79 21.13C16.79 20.64 16.4 20.25 15.91 20.25H5.81004C6.39004 19.34 7.41004 18.73 8.57004 18.73H18.93C19.78 18.73 20.47 18.04 20.47 17.19V2.77C20.47 2.57 20.39 2.37 20.24 2.23ZM17.38 11.23L14.92 9.38C14.85 9.33 14.77 9.3 14.69 9.3C14.61 9.3 14.53 9.33 14.46 9.38L12 11.23V3.54H17.38V11.23Z"
      fill="currentColor"
    />
  </svg>
`;
