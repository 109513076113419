import { go, omit, rest, map, take1 } from 'fxjs/es';

export const isSpoOptions = (spo) => {
  const spo_options =
    spo._?.spo_options.length > 0
      ? go(spo._.spo_options, take1).concat(go(spo._.spo_options, rest, map(omit('_'))))
      : [];
  const spo_text_options = spo._?.spo_text_options;

  return !!spo_options?.length || !!spo_text_options?.length;
};
