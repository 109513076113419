import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { apiCall } from './apiCalls.js';
import { getUsedImageIdsFromPf2 } from '../../../ProductColor/S/fs.js';
import { VectorEditorF } from '../../../VectorEditor/F/Function/module/VectorEditorF.js';

/**
 * @define 프로덕트에서 사용된 image_ids 를 찾아서 folder 데이터까지 반환
 * @param {object} product CANVAS 메이커 product_color
 * @param {object} svg_product_materials SVG 메이커 결과물 변수
 * @returns {Promise<object[]>} folder_data
 * */
const image_folders = new Map();
export const getUsedImagesFolder = async ({ product, svg_el }) => {
  const image_ids = await getUsedImageIdsFromEditor({
    product_color: product,
    svg_el,
  });
  if (UtilS.isEmpty(image_ids)) return [];
  const key = image_ids.sort().join(',');
  if (!image_folders.get(key)) {
    image_folders.set(key, await apiCall.getUsedImageFolders({ image_ids }));
  }
  return image_folders.get(key);
};

export const getUsedImageIdsFromEditor = ({ product_color, svg_el }) => {
  if (UtilS.isEmpty(product_color) && !(svg_el instanceof SVGElement)) return [];

  const is_vector_editor = !!svg_el || !!product_color?.svg_product_id;

  // svg_product_materials 이 없는 경우: SVG 에디터를 한번도 open 하지 않은 상태 -> 원본 product 의 image_ids 를 그대로 복사
  if (is_vector_editor) {
    if (!svg_el) {
      return product_color?.image_ids ?? [];
    }
    // 벡터 에디터 (SVG 이미지 요소의 attribute 로부터 사용한 image_ids 추출)
    return VectorEditorF.getUsedImageIdsFromSvgEditor(svg_el);
  } else {
    // 캔버스 에디터 (PF2 로부터 사용한 image_ids 추출)
    return getUsedImageIdsFromPf2(product_color.product_faces2);
  }
};
