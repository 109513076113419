import dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { MShopStudioDigitalProductCreatorConstantS } from '../Constant/module/MShopStudioDigitalProductCreatorConstantS.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;
export const makeProgressHtml = ({
  file_size,
  file_name,
  is_preview = false,
  signed_url_key,
  digital_product_resource_id,
}) => {
  return html`
    <div class="digital-product-creator-form__upload-result__file-name dpc-styl__ml-12px">${file_name}</div>
    <div class="digital-product-creator-form__upload-result__file-size dpc-styl__ml-8px dpc-styl__mr-auto">
      ${file_size}
    </div>
    <img
      src="//s3.marpple.co/files/u_2308668/2023/4/original/9a93c24e7912f879f05df4004dde2e2cd602dc241.png"
      alt="download button"
      class="digital-product-creator-form__upload-result__download digital-product-creator-form__upload-result__icon dpc-styl__ml-12px"
      data-fx-json="${dataStr({ Key: signed_url_key })}"
    />
    <img
      src="//s3.marpple.co/files/u_2308668/2023/4/original/a2bcf8ef290b4c9b09c05af2f472238e5d2360871.png"
      alt="delete button"
      class="digital-product-creator-form__upload-result__delete${is_preview
        ? '-preview'
        : ''} digital-product-creator-form__upload-result__icon dpc-styl__ml-12px"
      data-fx-json="${dataStr({ digital_product_resource_id })}"
    />
    <progress
      class="digital-product-creator-form__upload-result__progress progress"
      value="0"
      max="100"
    ></progress>
    <div class="digital-product-creator-form__upload-result__progress-view progress">&nbsp;&nbsp;0%</div>
  `;
};

export const makeImageUploadResultHtml = ({
  sp_cate_item_id,
  file_name,
  file_size,
  signed_url_key,
  digital_product_resource_id,
}) => {
  const front_data = MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[sp_cate_item_id];
  return html`
    <div class="digital-product-creator-form__upload-result">
      <div class="digital-product-creator-form__decorate-image-background">
        <img
          src="${front_data.decorate_image}"
          alt="download image replace icon"
          class="digital-product-creator-form__decorate-image-icon"
        />
      </div>
      ${makeProgressHtml({ file_size, file_name, signed_url_key, digital_product_resource_id })}
    </div>
  `;
};

export const makeImageUploadResultFailHtml = ({ file_name, message }) => {
  return html`
    <div
      class="digital-product-creator-form__upload-result digital-product-creator-form__upload-result--fail"
    >
      <div class="digital-product-creator-form__decorate-image-background fail">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/5/original/0f00207ed51f1d62d800872a0e7e8d0417055e7a1.png"
          alt="download image replace icon"
          class="digital-product-creator-form__decorate-image-icon"
        />
      </div>
      <div class="digital-product-creator-form__upload-result__fail-wrapper dpc-styl__mr-auto">
        <div class="digital-product-creator-form__upload-result__file-name dpc-styl__ml-12px">
          ${file_name}
        </div>
        <div
          class="digital-product-creator-form__upload-result__file-size dpc-styl__ml-8px dpc-styl__mr-auto"
          style="display: none"
        ></div>
        <div class="digital-product-creator-form__upload-result__fail-message dpc-styl__ml-8px">
          ${message}
        </div>
      </div>
      <img
        src="//s3.marpple.co/files/u_2308668/2023/4/original/a2bcf8ef290b4c9b09c05af2f472238e5d2360871.png"
        alt="delete button"
        class="digital-product-creator-form__upload-result__fail-delete digital-product-creator-form__upload-result__icon dpc-styl__ml-12px"
      />
    </div>
  `;
};
export const makeUploadFileHtml = ({ guide_main, guide_sub, accept, files_count }) => {
  return html`
    <div
      class="digital-product-creator-form__upload-box"
      data-fx-json="${dataStr({ files_total_count: files_count || 0 })}"
    >
      <div class="digital-product-creator-form__upload-box__icon-background">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/4/original/c526dab94fe395550fc9e4deacca4bfbf426893a1.png"
          alt="upload icon"
          class="digital-product-creator-form__upload-box__icon"
        />
      </div>
      <div class="digital-product-creator-form__upload-box__guides">
        <div class="digital-product-creator-form__upload-box__guide-main">${guide_main}</div>
        <div class="digital-product-creator-form__upload-box__guide-sub">${guide_sub}</div>
      </div>
    </div>
    <input
      type="file"
      class="digital-product-creator-form__input-file"
      name="${MShopStudioDigitalProductCreatorConstantS.PROPERTY.signed_url_keys}"
      accept="${accept}"
      multiple
    />
  `;
};

export const makePreviewUploadFileHtml = ({ guide_main, guide_sub, accept }) => {
  return html`
    <div class="digital-product-creator-form__preview-upload-box">
      <div class="digital-product-creator-form__upload-box__icon-background">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/4/original/c526dab94fe395550fc9e4deacca4bfbf426893a1.png"
          alt="upload icon"
          class="digital-product-creator-form__upload-box__icon"
        />
      </div>
      <div class="digital-product-creator-form__upload-box__guides">
        <div class="digital-product-creator-form__upload-box__guide-main">${guide_main}</div>
        <div class="digital-product-creator-form__upload-box__guide-sub">${guide_sub}</div>
      </div>
    </div>
    <input type="file" class="digital-product-creator-form__preview_input-file" accept="${accept}" />
  `;
};

export const makeIsHyperLinkInputHtml = (is_hyper_link) => {
  return html`
    <div class="digital-product-creator-form__control">
      <div class="digital-product-creator-form__input-title">
        하이퍼링크
        <span class="digital-product-creator-form__required">*</span>
      </div>
      <label class="digital-product-creator-form__radio-label">
        <input
          type="radio"
          name="${digital_product_prop.is_hyper_link}"
          value="true"
          ${is_hyper_link ? 'checked' : ''}
        />
        있음
      </label>
      <label class="digital-product-creator-form__radio-label">
        <input
          type="radio"
          name="${digital_product_prop.is_hyper_link}"
          value="false"
          ${is_hyper_link ? '' : 'checked'}
        />
        없음
      </label>
    </div>
  `;
};
