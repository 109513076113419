import { equals, flatMapL, go, some, strMap, takeL, reject } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import { messages } from '../../Setting/S/messages_id.js';
import { MShopUtilS } from '../../../MShop/Util/S/Function/module/MShopUtilS.js';
import { StudioHeader } from '../../../../services/MarppleShop/marppleShopStudioApp/components/cells/Layout/StudioTitle.ts';

export const makeHelpSettingsBodyHtml = (data) => makeHelpBodyHtml(data.help_data, 'settings');

const makeHelpHeaderHtml = ({ help_groups, help_group_selected, type }) => legacyHtml`
  <div class="store-help-header" help-group-selected="${help_group_selected}">
    ${(() => {
      if (
        type &&
        some(equals(type.toUpperCase()), [
          'CREATOR_MALL_FAQ_KR',
          'CREATOR_MALL_CUSTOMER_FAQ_KR',
          'CREATOR_MALL_FAQ_JP',
          'CREATOR_MALL_CUSTOMER_FAQ_JP',
          'CREATOR_MALL_FAQ',
          'CREATOR_MALL_CUSTOMER_FAQ',
        ])
      ) {
        return legacyHtml` <h2>${T('crew_mall::고객센터')}</h2> `;
      } else {
        return legacyHtml` <h2>${T('store_help::customer_service')}</h2> `;
      }
    })()}
    <h3 data-help-group-selected="${help_group_selected}">
      <img
        class="back"
        src="https://s3.marpple.co/files/u_14355/2018/10/original/f_12273_1540822577016_rOdC4M9h7pwB1nK6kgvy.png" alt=""
      />
      <span> ${strMap((hg) => hg.name, takeL(1, help_groups))} </span>
    </h3>
    ${(() => {
      if (!type) return '';
      if (
        some(equals(type.toUpperCase()), ['CREATOR_MALL_FAQ_KR', 'CREATOR_MALL_CUSTOMER_FAQ_KR']) &&
        T.lang == 'kr'
      ) {
        return legacyHtml`
          <div class="help_tab">
            <ul>
              <li><a href="/kr/@/help?type=creator_mall_faq_kr">셀러 FAQ</a></li>
              <li><a href="/kr/@/help?type=creator_mall_customer_faq_kr">고객 FAQ</a></li>
            </ul>
          </div>
        `;
      } else if (
        some(equals(type.toUpperCase()), ['CREATOR_MALL_FAQ_JP', 'CREATOR_MALL_CUSTOMER_FAQ_JP']) &&
        T.lang == 'jp'
      ) {
        return legacyHtml`
          <div class="help_tab" style="display: none;">
            <ul>
              <li><a href="/kr/@/help?type=creator_mall_faq_kr">売り手</a></li>
              <li><a href="/kr/@/help?type=creator_mall_customer_faq_kr">お客様</a></li>
            </ul>
          </div>
        `;
      } else if (
        some(equals(type.toUpperCase()), ['CREATOR_MALL_FAQ', 'CREATOR_MALL_CUSTOMER_FAQ']) &&
        T.lang == 'en'
      ) {
        return legacyHtml`
          <div class="help_tab" style="display: none;">
            <ul>
              <li><a href="/kr/@/help?type=creator_mall_faq_kr">CREATOR</a></li>
              <li><a href="/kr/@/help?type=creator_mall_customer_faq_kr">CUSTOMER</a></li>
            </ul>
          </div>
        `;
      }

      return '';
    })()}
  </div>
`;

const makeHelpGroupLinkHtml = ({ store_name, type, id, name, is_settings }) => legacyHtml`
  <a href="/${T.lang}/${UtilS.escape(store_name)}/${
  is_settings ? `${is_settings}/` : ''
}help?type=${type}&hg_id=${id}">
    <span title="${name}">${name}</span>
    <img
      src="//s3.marpple.co/files/u_1187077/2020/1/original/14241556fd6a7c5422eea3b9f827f04372aa3e4f04bc750.svg" alt=""
    />
  </a>
`;
const makeHelpGroupsHtml = ({
  type,
  help_group_selected,
  help_group_id,
  store_name,
  groups,
  is_settings,
  is_mobile,
  crew,
}) => legacyHtml`
  <div class="store-help-body-left">
    <ul class="store-help-body-groups" data-help-group-selected="${help_group_selected}">
      ${strMap(
        ({ id, name }) =>
          legacyHtml`
            <li data-selected="${help_group_id == id}">
              ${makeHelpGroupLinkHtml({ store_name, type, id, name, is_settings })}
            </li>
          `,
        groups,
      )}
    </ul>
    ${go(void 0, () => {
      if (
        !is_mobile &&
        type &&
        some(equals(type.toUpperCase()), ['CREATOR_MALL_FAQ_KR', 'CREATOR_MALL_CUSTOMER_FAQ_KR'])
      ) {
        return legacyHtml`
          <div class="cs_menu">
            <ul>
              <li class="email"><a href="mailto:${
                MShopUtilS.getCustomerServiceInfo(crew.id).email
              }">이메일 상담</a></li>
              <li class="chat"><span class="live_chat">1:1 실시간 상담</span></li>
              <li class="solution"><a href="/kr/@/solution">신고 접수 서비스</a></li>
            </ul>
          </div>
        `;
      }
      return '';
    })}
  </div>
`;

const makeHelpPagesHtml = ({ help_groups, help_group_selected, help_group_id, help_id, crew }) => legacyHtml`
  <ul class="store-help-body-pages" data-help-group-selected="${help_group_selected}">
    ${go(
      help_groups,
      takeL(1),
      flatMapL((hg) => hg._.helps),
      reject((help) => crew?.without_bonus_profit && help.id == 519),
      strMap(
        ({ id, title, body_html }) =>
          legacyHtml`
            <li
              class="help_item"
              data-selected="${id == help_id}"
              data-help-group-id="${help_group_id}"
              data-help-page-id="${id}"
            >
              <h4 class="question" title="${title}">
                <span>${title}</span>
                <img
                  src="//s3.marpple.co/files/u_1187077/2020/1/original/14241556fd6a7c5422eea3b9f827f04372aa3e4f04bc750.svg" alt=""
                />
              </h4>
              <div class="answer">${legacyHtml` ${body_html} `}</div>
            </li>
          `,
      ),
    )}
  </ul>
`;

export const makeHelpBodyHtml = (
  [type, help_group_id, help_id, store_name, is_mobile, groups, help_groups, crew],
  is_settings,
) => {
  const help_group_selected = !!help_group_id;
  return legacyHtml`
    ${new StudioHeader({
      title: UtilS.isMarppleShopApp() ? ET('mps2::studio::page::faq') : T(messages.menus.faq),
      description: UtilS.isMarppleShopApp() ? ET('mps2::studio::page::faq_guide') : '',
    }).toHtml()}
    <div class="store-help">
      ${makeHelpHeaderHtml({ help_groups, help_group_selected })}
      <div class="store-help-body">
        ${makeHelpGroupsHtml({
          type,
          help_group_selected,
          help_group_id,
          store_name,
          groups,
          is_settings,
          crew,
        })}
        ${makeHelpPagesHtml({ help_groups, help_group_selected, help_group_id, help_id, crew })}
      </div>
    </div>
  `;
};
