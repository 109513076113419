import axios from 'axios';
import {
  $addClass,
  $closest,
  $data,
  $el,
  $find,
  $findAll,
  $hasClass,
  $next,
  $prev,
  $removeClass,
  $setData,
  $setText,
  $setVal,
  $toggleClass,
  $trigger,
  $val,
} from 'fxdom/es';
import { defaults, delay, each, go, head, identity, ifElse, mapC, reject, tap } from 'fxjs/es';
import { getFormValues } from '../../../../../Creator/Store/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { checkHasUp } from '../../../../../Ups/F/fs.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../../Util/S/Function/util.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { UPLOAD_FILE_NUMBER_LIMIT, UPLOAD_MESSAGE } from '../../S/Constant/digitalproductcreator.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorS } from '../../S/Function/module/MShopStudioDigitalProductCreatorS.js';
import { makeImageUploadResultHtml } from '../../S/Tmpl/makeEachCategoryHtml.js';
import {
  afterDeleteEl,
  afterFailDeleteEl,
  changeDashboardFailState,
  completeEachUploadProgress,
  completeProgress,
  disableUploadBox,
  enableUploadBox,
  getUploadResults,
  revertElForPreview,
  setProgressElForPreview,
  setResultsDashboard,
  startPreviewUploadProgress,
  startUploadProgress,
  updateResultsDashboard,
} from './elementControl.js';
import {
  checkAllInput,
  disableButtons,
  errorPass,
  getAllState,
  getDataForBackEnd,
  getDownloadedFile,
  getFileInfoByKeyPath,
  getSignedUrlInformation,
  hashFileName,
  setState,
  showError,
  showErrors,
  upLoadFileToS3,
  validateFile,
  validatePreviewFile,
} from './fn.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';
import { getValidatedFiles } from './validation.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;

export const handleRadioInput = (key) => (e) => {
  const value = go(e.delegateTarget, $find(`input[name="${key}"]:checked`), $val, (val) =>
    val === ('true' || 'false') ? UtilS.toBoolean(val) : val,
  );
  setState({ key, value });
  checkAllInput(e.delegateTarget);
};

export const handleLicenseRadioInput = (key) => async (e) => {
  const value = go(e.delegateTarget, $find(`input[name="${key}"]:checked`), $val, (val) =>
    val === ('true' || 'false') ? UtilS.toBoolean(val) : val,
  );
  go(
    e.delegateTarget,
    $find('.digital-product-creator-form__desc'),
    $setText(await MShopStudioDigitalProductCreatorConstantS.LICENSE_MESSAGE[value]),
  );
  setState({ key, value });
  checkAllInput(e.delegateTarget);
};

export const handleTextInput = (key) => (e) => {
  const validationFn = MShopStudioDigitalProductCreatorS.inputValidationResult[key];
  const el = e.currentTarget || e;
  const value = $val(el).trim();
  const err_check_result = validationFn(value);

  setState({ key, value });
  showError(err_check_result);
  checkAllInput(e.delegateTarget);
};

export const handlePriceInput = (key) => (e) => {
  const el = e.currentTarget || e;
  const validationFn = MShopStudioDigitalProductCreatorS.inputValidationResult[key];
  const value = PriceS.price($val(el));
  const err_check_result = validationFn(value);

  setState({ key, value });
  showError(err_check_result);
  checkAllInput(e.delegateTarget);
};

export const convertPrice = (e) => {
  const el_value = $val(e.currentTarget);
  const text_value = UtilS.commify(el_value.replace(/[^0-9]/g, '').replace(/(^0+)/, ''));
  const value = PriceS.price(text_value);
  $setVal(text_value, e.currentTarget);
  setState({ key: digital_product_prop.price, value });
};

export const handleSelectEbookCate = (e) => {
  $addClass('active', e.currentTarget);
  const validationFn =
    MShopStudioDigitalProductCreatorS.inputValidationResult[digital_product_prop.ebook_category_id];
  const value = e.currentTarget.value;
  const err_check_result = validationFn(value);

  setState({ key: digital_product_prop.ebook_category_id, value });
  showError(err_check_result);
  checkAllInput(e.delegateTarget);
};

export const handleCheckLetterLen = (e) => {
  const letter_length = $val(e.currentTarget).length;
  const apply_text = `${letter_length}/100`;
  go(
    e.currentTarget,
    $closest('.digital-product-creator-form__product_names'),
    $find('.digital-product-creator-form__letter-number'),
    $setText(apply_text),
  );
};

export const clickDigitalProductUploadButton = async (e) => {
  const file_input$ = $next(e.currentTarget);
  const all_state = getAllState();
  const can_not_modify =
    all_state[digital_product_prop.product_id] &&
    (await checkHasUp(all_state[digital_product_prop.product_id]));

  if (can_not_modify) {
    return await MShopShareFramePopUpF.alert({
      title: '파일 수정 불가',
      body: '구매되었거나 장바구니에 담긴 상품은 수정하거나<br>삭제할 수 없습니다. 파일을 수정하고 싶을 경우<br>새로운 상품을 등록해 주세요.',
    });
  }

  setTimeout(() => {
    $trigger('click', file_input$);
  }, 1);
};

export const uploadDigitalProduct = async (e) => {
  try {
    const { uploadFiles, files_total_count } = getValidatedFiles(e);

    e.target.value = '';

    if (!uploadFiles.length) return;

    if (files_total_count > UPLOAD_FILE_NUMBER_LIMIT) {
      return await MShopShareFramePopUpF.alert({
        title: '파일 개수 제한',
        body: `판매용 파일은 최대 ${UPLOAD_FILE_NUMBER_LIMIT}개까지 등록할 수 있습니다.<br> ${UPLOAD_FILE_NUMBER_LIMIT}개 이하로 업로드해 주세요.`,
      });
    }

    disableButtons(e);

    $setData({ files_total_count }, go(e.delegateTarget, $find('.digital-product-creator-form__upload-box')));

    const $results_dashboard = go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $find('.digital-product-creator-form__upload-results-dashboard'),
    );

    const all_state = getAllState();
    let i = 0;
    await go(
      uploadFiles,
      tap(async () => {
        await setResultsDashboard({ e, $results_dashboard, files_total_count });
      }),
      mapC(async (file) => {
        const $upload_results = getUploadResults(e);

        if (validateFile({ e, file, $upload_results })) {
          return;
        }

        const $upload_result = $el(
          makeImageUploadResultHtml({
            sp_cate_item_id: all_state[digital_product_prop.sp_cate_item_id],
            file_name: file?.name,
            file_size: MShopStudioDigitalProductCreatorS.byteConverter(file?.size),
          }),
        );
        const file_ext = encodeURIComponent(
          MShopStudioDigitalProductCreatorS.getExtensionOfFilename(file?.name),
        );

        const Key = `${box.sel('store_id')}/${all_state[digital_product_prop.sp_cate_item_id]}/${hashFileName(
          file?.name + new Date().getTime() + i++,
        )}${file_ext}`;

        const signed_url = await getSignedUrlInformation({
          Key,
          operation: 'putObject',
          file_name: file?.name,
          file_size: file?.size,
          file_ext,
        });
        startUploadProgress({ $upload_result, $upload_results });
        await upLoadFileToS3({ signed_url, file, currentTarget: $upload_result });
        await completeEachUploadProgress({ e, $upload_result });

        const $download_button = $find(
          '.digital-product-creator-form__upload-result__download',
          $upload_result,
        );
        $setData({ Key }, $download_button);
        all_state[digital_product_prop.signed_url_keys] = [
          ...all_state[digital_product_prop.signed_url_keys],
          Key,
        ];

        if (all_state[digital_product_prop.digital_product_id]) {
          await axios.post(
            `/${T.lang}/@api/stores/${box.sel('store_id')}/digital_products/insert_resource/${
              all_state[digital_product_prop.store_product_id]
            }`,
            {
              resource_url: Key,
              digital_product_id: all_state[digital_product_prop.digital_product_id],
              is_preview: false,
            },
          );
        }
      }),
      tap(async () => {
        await updateResultsDashboard({ e, message: UPLOAD_MESSAGE.upload_complete });
        setState({
          key: digital_product_prop.signed_url_keys,
          value: all_state[digital_product_prop.signed_url_keys],
        });
        errorPass(e.currentTarget);
        checkAllInput(e.delegateTarget);
      }),
    );
  } catch (e) {
    console.error(e);
    await $.alert(e.response?.data?.message || e.message);
  }
};

export const upLoadPreviewFile = async (e) => {
  const $btn_submit_els = $findAll('.digital-product-creator__btn-submit', e.delegateTarget);
  try {
    each($addClass('digital-product-creator__btn-submit--disabled'), $btn_submit_els);

    const state = getAllState();
    const $upload_box = go(e.currentTarget, $prev);
    const file = head(e.currentTarget.files);
    const { sp_cate_item_id } = state;
    const file_ext = MShopStudioDigitalProductCreatorS.getExtensionOfFilename(file?.name);

    const Key = `${box.sel('store_id')}/${sp_cate_item_id}/preview/${new Date().getTime()}/${hashFileName(
      file?.name,
    )}${file_ext}`;

    e.target.value = '';

    if (await validatePreviewFile({ file })) return;

    setProgressElForPreview({
      $upload_box,
      file_name: file?.name,
      file_size: MShopStudioDigitalProductCreatorS.byteConverter(file?.size),
    });
    startPreviewUploadProgress($upload_box);

    $upload_box.removeEventListener('click', clickDigitalProductUploadButton);
    disableUploadBox($upload_box);

    const signed_url = await getSignedUrlInformation({
      Key,
      operation: 'putObject',
      file_name: file?.name,
      file_size: file?.size,
      file_ext,
    });

    await upLoadFileToS3({ signed_url, file, currentTarget: $upload_box });
    await completeProgress($upload_box);

    const $download_button = $find('.digital-product-creator-form__upload-result__download', $upload_box);
    $setData({ Key }, $download_button);
    const all_state = getAllState();
    if (all_state[digital_product_prop.digital_product_id]) {
      await axios.post(
        `/${T.lang}/@api/stores/${box.sel('store_id')}/digital_products/insert_resource/${
          all_state[digital_product_prop.store_product_id]
        }`,
        {
          resource_url: Key,
          digital_product_id: all_state[digital_product_prop.digital_product_id],
          is_preview: true,
        },
      );
    }
    setState({ key: digital_product_prop.preview_key, value: Key });
  } catch (e) {
    await $.alert(e.response?.data?.message || e.message);
  } finally {
    checkAllInput(e.delegateTarget);
  }
};

export const downloadDigitalProduct = async (e) => {
  try {
    $.don_loader_start();
    const { Key } = go(e.currentTarget, $data);

    if (Key.includes('s3.marpple')) {
      return await getDownloadedFile({ signed_url: Key, file_name: 'wallpaper.png' });
    }

    const { file_name, file_size, file_ext } = await getFileInfoByKeyPath(Key);
    const signed_url = await getSignedUrlInformation({
      Key,
      operation: 'getObject',
      file_size,
      file_ext,
      file_name,
    });
    await getDownloadedFile({ signed_url, file_name });
  } catch (e) {
    await $.alert(e.response?.data?.message || e.message);
  } finally {
    $.don_loader_end();
  }
};

export const deleteDigitalProduct = async (e) => {
  try {
    const all_state = getAllState();
    if (
      all_state[digital_product_prop.product_id] &&
      (await checkHasUp(all_state[digital_product_prop.product_id]))
    ) {
      return await MShopShareFramePopUpF.alert({
        title: '파일 수정 불가',
        body: '구매되었거나 장바구니에 담긴 상품은 수정하거나<br>삭제할 수 없습니다. 파일을 수정하고 싶을 경우<br>새로운 상품을 등록해 주세요.',
      });
    }
    const { digital_product_resource_id } = go(e.currentTarget, $data);
    const { Key: deleted_key } = go(e.currentTarget, $prev, $data);

    const deleted_signed_keys = go(
      all_state[digital_product_prop.signed_url_keys],
      reject((key) => key === deleted_key),
    );
    const fail_file_len = go(
      e.currentTarget,
      $closest('.digital-product-creator-form__control'),
      $findAll('.digital-product-creator-form__upload-result--fail'),
    ).length;

    setState({ key: digital_product_prop.signed_url_keys, value: deleted_signed_keys });
    afterDeleteEl(e, deleted_signed_keys.length + fail_file_len);

    checkAllInput(e.delegateTarget);
    if (digital_product_resource_id) {
      await axios.delete(
        `/${T.lang}/@api/stores/${box.sel('store_id')}/digital_products/${
          all_state[digital_product_prop.store_product_id]
        }/${digital_product_resource_id}`,
      );
    }
  } catch (e) {
    await $.alert(e.response?.data?.message || e.message);
  }
};

export const handleToggleUploadResults = (e) => {
  go(
    e.currentTarget,
    $closest('.digital-product-creator-form__control'),
    $findAll('.digital-product-creator-form__toggle'),
    mapC($toggleClass('close')),
  );

  go(
    e.currentTarget,
    ifElse(() => $hasClass('close', e.currentTarget), identity, delay(200)),
    $toggleClass('close'),
  );
};

export const deleteFailDigitalProduct = async (e) => {
  try {
    const all_state = getAllState();
    const fail_file_len =
      go(
        e.currentTarget,
        $closest('.digital-product-creator-form__control'),
        $findAll('.digital-product-creator-form__upload-result--fail'),
      ).length - 1;
    const file_len = all_state[digital_product_prop.signed_url_keys].length + fail_file_len;

    changeDashboardFailState({ e, action: 'delete' });
    afterFailDeleteEl(e, file_len);
    checkAllInput(e.delegateTarget);
  } catch (e) {
    await $.alert(e.response?.data?.message || e.message);
  }
};

export const deletePreviewDigitalProduct = async (e) => {
  const all_state = getAllState();
  if (
    all_state[digital_product_prop.product_id] &&
    (await checkHasUp(all_state[digital_product_prop.product_id]))
  ) {
    return $.alert(T('구매되었거나 장바구니에 담긴 상품은 <br> 파일을 수정 할 수 없습니다.'));
  }

  const { digital_product_resource_id } = go(e.currentTarget, $data);
  const $upload_box = go(e.currentTarget, $closest('.digital-product-creator-form__preview-upload-box'));
  $upload_box.addEventListener('click', clickDigitalProductUploadButton);
  revertElForPreview($upload_box);
  enableUploadBox($upload_box);
  setState({ key: digital_product_prop.preview_key, value: null });
  if (digital_product_resource_id) {
    await axios.delete(
      `/${T.lang}/@api/stores/${box.sel('store_id')}/digital_products/${
        all_state[digital_product_prop.store_product_id]
      }/${digital_product_resource_id}`,
    );
  }
};

export const changeQnaType = (key) => (e) => {
  const value = $val(e.currentTarget);

  if (value === MShopStudioDigitalProductCreatorConstantS.QNA_TYPES.SNS) {
    const $sns_el = $find('.digital-product-creator-form__qna-type-sns--hide', e.delegateTarget);
    if (!$sns_el) return;
    $removeClass('digital-product-creator-form__qna-type-sns--hide', $sns_el);

    const $board_el = $find('.digital-product-creator-form__qna-type-mp', e.delegateTarget);
    $addClass('digital-product-creator-form__qna-type-mp--hide', $board_el);

    setState({
      key: digital_product_prop.qna_link,
      value: $val($find(`input[name=${digital_product_prop.qna_link}]`, $sns_el)),
    });
  } else {
    const $board_el = $find('.digital-product-creator-form__qna-type-mp--hide', e.delegateTarget);
    if (!$board_el) return;
    $removeClass('digital-product-creator-form__qna-type-mp--hide', $board_el);

    const $sns_el = $find('.digital-product-creator-form__qna-type-sns', e.delegateTarget);
    $addClass('digital-product-creator-form__qna-type-sns--hide', $sns_el);
  }

  setState({ key, value });
  checkAllInput(e.delegateTarget);
};

export const handleAgreeCopyright = (key) => (e) => {
  const value = e.currentTarget.checked;
  setState({ key, value });
  checkAllInput(e.delegateTarget);
};

export const patchStoreProductById = (key) => async () => {
  try {
    $.don_loader_start();
    const store_product = getAllState();
    await $.patch(
      UtilS.makeApiUrl('/@api/stores/:store_id/store_products/:id', {
        store_id: box.sel('store_id'),
        id: store_product.store_product_id,
      }),
      {
        [key]: !store_product[key],
      },
    );
    MuiF.closeFrame({ refresh: true });
  } catch (e) {
    console.error(e);
  } finally {
    $.don_loader_end();
  }
};

export const deleteStoreProduct = async () => {
  const { product_id } = getAllState();
  if (await checkHasUp(product_id)) {
    return $.alert(
      T('구매되었거나 장바구니에 담긴 상품은<br>삭제 할 수 없습니다.<br>대신 공개 설정을 이용해보세요.'),
    );
  }

  const yes = await $.confirm(T('삭제 하시겠습니까?'));
  if (!yes) return;

  try {
    await $.delete(
      UtilS.makeApiUrl('/@api/stores/:store_id/products/:id', {
        store_id: box.sel('store_id'),
        id: product_id,
      }),
    );
    MuiF.closeFrame({ refresh: true });
  } catch (_err) {
    console.error(_err);
    $.alert('삭제되지 않았습니다.');
  }
};

export const submit = async (e) => {
  try {
    const all_state = getAllState();
    const is_update = !!all_state[digital_product_prop.store_product_id];
    /**
     * validate
     * */
    const errors = MShopStudioDigitalProductCreatorS.getDataErrors(
      all_state,
      box.sel('digital_product_thumbnail_photos'),
    );

    if (errors.length) {
      showErrors(errors, e.delegateTarget);
      return;
    }

    const fail_files = $findAll('.digital-product-creator-form__upload-result--fail', e.delegateTarget);
    const $progress_els = $findAll('progress', e.delegateTarget);
    const is_uploading = !!go($progress_els, reject($hasClass('none'))).length;

    if (fail_files.length || is_uploading) {
      MShopStudioDigitalProductCreatorF.moveScrollToError(
        { key: digital_product_prop.signed_url_keys },
        e.delegateTarget,
      );
      return;
    }

    /**
     * create start
     * */
    const {
      stores_product,
      product,
      digital_product,
      digital_product_resources,
      stores_products_token_gates,
      ebook_infos,
    } = getDataForBackEnd(all_state, e.delegateTarget);

    const store_id = box.sel('store_id');
    $.don_loader_start();

    await go(
      $.upload_exif(
        {
          files: [...box.sel('digital_product_thumbnail_files'), ...box.sel('product_story_image_files')],
        },
        {
          method: is_update ? 'PUT' : 'POST',
          url: is_update
            ? makeApiUrl('/:lang/@api/stores/:store_id/digital_products/:stores_product_id/update', {
                lang: T.lang,
                store_id,
                stores_product_id: all_state[digital_product_prop.store_product_id],
              })
            : makeApiUrl('/:lang/@api/stores/:store_id/digital_products/create', {
                lang: T.lang,
                store_id,
              }),
          max_resize: 1000,
          data: {
            stores_product: JSON.stringify(stores_product),
            product: JSON.stringify(product),
            digital_product: JSON.stringify(digital_product),
            digital_product_resources: JSON.stringify(digital_product_resources),
            ebook_infos: JSON.stringify(ebook_infos),
            stores_products_token_gates: JSON.stringify(stores_products_token_gates),
            product_story_image_no: JSON.stringify(box.sel('product_story_image_no')),
            product_story: JSON.stringify(
              defaults(getFormValues($1('#product-story-editor')), {
                title: null,
                description: null,
                fb_embed: null,
                youtube_embed: null,
                tw_embed: null,
                ins_embed: null,
              }),
            ),
            product_story_image_files_length: box.sel('product_story_image_files').length,
            remove_product_story_image_files: JSON.stringify(box.sel('remove_product_story_image_files')),
          },
        },
      ),
      () => {
        MuiF.closeFrame(true);
      },
    );
  } catch (_err) {
    try {
      const data = _err.response && JSON.parse(_err.response);
      MShopShareFramePopUpF.alert(data?.message || _err.message);
    } catch (e) {
      MShopShareFramePopUpF.alert('문제가 발생했습니다.');
    }
    console.error(_err);
  } finally {
    $.don_loader_end();
  }
};
