import 'intersection-observer';
import '../../lib/impetus.js';
import '../../../../../../services/MarppleShop/shared/style/global.scss';
import '../../custom-event.js';
import '../../sticky.js';
import '../../component.js';
import '../../lazyload.js';
import '../../resize_sensor.js';
import './auth.js';
import '../common.js';
import '../../component_like.js';
import '../../base_modules.js';
import './scripts1.js';
import './ad.js';
import '../../mp/entry/maker.js';
import '../../../../sh/ttl-pug.es6.js'; // privacy, terms 템플릿 때문에 일단 불러옴
import './start_frame.js';
import './scripts2.js';
import './sentry.js';

if (box.sel('is_studio') || box.sel('is_plus_admin')) {
  await import('flatpickr/dist/flatpickr.min.css');
}
