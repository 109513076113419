import axios from 'axios';
import { $find, $on } from 'fxdom/es';
import { entriesL, go, object, rejectL } from 'fxjs/es';
import querystring from 'querystring';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppReportMuiF } from '../Mui/module/MShopAppReportMuiF.js';

const report_complete = async () => {
  await $.alert(T('mshop::report::complete'));
  go(
    querystring.parse(location.search.slice(1)),
    entriesL,
    rejectL(([k]) => k == 'a_id'),
    object,
    querystring.stringify,
    (qs) => `${window.location.pathname}?${qs}`,
    (path) => MuiF.extendHistoryState(null, null, path, true),
  );
  window.location.reload();
};

const resportUserId = (type, id) =>
  axios.post(`/${T.lang}/@api/report/user_id`, {
    [type + '_id']: id,
  });

export const submit = (tab_el) =>
  $on('submit', async (e) => {
    e.preventDefault();

    const type = e.target.type.value;
    const id = e.target.id.value;
    const description = e.target.description?.value;
    const is_blocking = e.target.is_blocking?.checked;

    try {
      await axios.post(`/${T.lang}/@api/report/${type}`, {
        description,
        [type + '_id']: id,
      });
      if (is_blocking) await resportUserId(type, id);

      return report_complete();
    } catch (e) {
      await $.alert(e.response?.data?.message);
    }
  })($find('.app-report__form', tab_el));

export const close = (tab_el) =>
  $on('click', () => MuiF.closeFrame())($find('.app-report__button--cancel', tab_el));

export const openFrame = (type) => async (e) => {
  const { id } = e.currentTarget.dataset;

  if (!box.sel('is_user->id')) {
    return go(
      $.confirm(T('로그인 후 이용해 주세요.')),
      (is_confirm) =>
        is_confirm &&
        MShopUtilF.postMessage({
          navigate: {
            sel: 'AuthStackNavigation.AuthEmptyScreen',
            params: {
              uri: `/${T.lang}/login`,
            },
          },
        }),
    );
  }

  return MuiF.openFrame(MShopAppReportMuiF.frame, async (f, p, [t]) => {
    t.makeData = () => ({ id, type });
    f.title = T('mshop::report::title');
    f.header_height = MShopUtilF.isMobile() ? 84 : 98;
  });
};
