import { ComponentsSlideSelectorTmplS } from '../../../../../Components/SlideSelector/S/Tmpl/module/ComponentsSlideSelectorTmplS.js';
import { chunk, go, map, ifElse } from 'fxjs/es';

export const makeCategorySlider = (today_tags, { selected, klass, is_mobile } = {}) => {
  const today_tag_rows = go(
    [{ name: T('mshop::Menu::All'), id: 0, icon: '🌐', selected: !selected }, ...today_tags],
    map((tag) => ({
      content: tag.name,
      value: tag.id,
      icon: tag.icon,
      selected: tag.selected || selected == tag.id,
    })),
    ifElse(
      () => is_mobile && today_tags.length > 4,
      chunk(Math.ceil((today_tags.length + 1) / 2)),
      (tags) => [tags],
    ),
  );

  return ComponentsSlideSelectorTmplS.make(today_tag_rows, {
    klass,
    component: 'today-tag',
    min: 1,
  });
};
