import { creatorDiscountedPriceOfProduct } from '../../../../ProductColorPrice/S/fs.js';
import {
  each,
  extend,
  filter,
  find,
  go,
  map,
  omit,
  pluck,
  sortBy,
  every,
  reject,
  sortByDesc,
  uniq,
} from 'fxjs/es';
import { discontinuedSizeIds, soldOutSizeIds } from '../../../../ProductColor/S/fs.js';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { NessCustomMakerCoreConstantS } from '../Constant/module/NessCustomMakerCoreConstantS.js';
import { BpOptionConstantS } from '../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { UtilObjS } from '../../../../Util/Object/S/Function/module/UtilObjS.js';

export const getProductPrice = (product, quantity = 1, base_product_size_id) => {
  base_product_size_id = base_product_size_id || product.base_product_size_id;
  return creatorDiscountedPriceOfProduct(product, quantity, base_product_size_id);
};

export const syncProductDetail = (product, product_detail) => {
  go(product, omit('_'), (product_obj) => extend(product_detail.product_color, product_obj));
  const bps_id = product.base_product_size_id;
  product_detail.base_product._.base_product_colors = [...product._.base_product._.base_product_colors];
  go(
    product_detail.base_product._.base_product_colors,
    each((bpc) => {
      bpc._.select_size_id = bps_id;
    }),
  );
};

export const getBaseProductSizes = (product) => {
  const discontinued_size_ids = discontinuedSizeIds(product, product._.base_product._.base_product_colors);
  return [
    product._.base_product._.base_product_sizes,
    product.base_product_size_id,
    uniq([
      ...soldOutSizeIds(product, product._.base_product._.base_product_colors),
      ...discontinued_size_ids,
    ]),
  ];
};

const legacy_bp_options = [
  // {
  //   bp_option_id: 946,
  //   price: 400,
  //   price_en: 0.36,
  //   price_jp: 40,
  // },
  // {
  //   bp_option_id: 945,
  //   price: 1600,
  //   price_en: 1.46,
  //   price_jp: 157,
  // },
  // {
  //   bp_option_id: 944,
  //   price: 1000,
  //   price_en: 0.91,
  //   price_jp: 98,
  // },
  // {
  //   bp_option_id: 943,
  //   price: 1400,
  //   price_en: 1.27,
  //   price_jp: 137,
  // },
  // {
  //   bp_option_id: 768,
  //   price: 1000,
  //   price_en: 0.91,
  //   price_jp: 98,
  // },
];

export const filteredBpOptionGroupsForUi = (product, is_mobile) => {
  if (!product._.base_product._.base_products_bp_option_groups?.length) return [];
  const selected_bp_option_ids = product._.selected_option_group?.bp_option_ids;
  const bp_option_groups = go(
    BpOptionS.getBpBogWithoutDosu(product),
    filter((base_products_bp_option_group) => {
      const bp_option_group = base_products_bp_option_group._.bp_option_group;
      return (
        bp_option_group.is_visibility &&
        (!bp_option_group.is_virtual || bp_option_group.category === '사이즈') &&
        BpOptionConstantS.UI_TYPE.CUSTOM !== bp_option_group.ui_type_pc &&
        BpOptionConstantS.UI_TYPE.CUSTOM !== bp_option_group.ui_type_mobile
      );
    }),
    sortBy((base_products_bp_option_group) => base_products_bp_option_group._.bp_option_group.no),
    map((base_products_bp_option_group) => {
      const selected_bp_option_id =
        (selected_bp_option_ids?.length &&
          go(
            base_products_bp_option_group._.bp_option_group._.bp_options,
            find((bp_option) => selected_bp_option_ids.includes(parseInt(bp_option.id))),
            (bp_option) => bp_option?.id,
          )) ||
        base_products_bp_option_group.default_bp_option_id;

      const _bp_option_group = base_products_bp_option_group._.bp_option_group;
      const bp_option_group = UtilObjS.deepClone(_bp_option_group);

      const lowestPriceOption = go(
        bp_option_group._.bp_options,
        each((bp_option) => {
          bp_option.price = bp_option.payment_price;
          bp_option.price_en = bp_option.payment_price_en;
          bp_option.price_jp = bp_option.payment_price_jp;
        }),
        sortBy((bp_option) => bp_option.price),
        (sortedOptions) => sortedOptions[0].price,
      );
      if (lowestPriceOption < 0) {
        const adjustment = Math.abs(lowestPriceOption);
        go(
          bp_option_group._.bp_options,
          each((bp_option) => {
            bp_option.price += adjustment;
          }),
        );
      }

      go(
        bp_option_group._.bp_options,
        each((bp_option) => {
          const gori_bp_option = go(
            legacy_bp_options,
            find((_bp_option) => _bp_option.bp_option_id == bp_option.id),
          );
          if (gori_bp_option) {
            extend(bp_option, gori_bp_option);
          }
        }),
      );

      /* BpOptionConstantS.UI_TYPE 참고 */
      return {
        selected_id: selected_bp_option_id,
        option_group: bp_option_group,
        ui_type: go(is_mobile ? bp_option_group.ui_type_mobile : bp_option_group.ui_type_pc, (ui_type) => {
          if (bp_option_group.is_lockable) return NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.FIXED;
          if (
            ![
              NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.IMAGE_SELECT,
              NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.SELECT,
            ].includes(ui_type)
          ) {
            return NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.SELECT;
          }
          return ui_type;
        }),
      };
    }),
  );

  return bp_option_groups;
};

export const selectedBpOption = (option) => {
  const bp_options = option.option_group._.bp_options;
  const selected_id = option.selected_id;

  return go(
    bp_options,
    find((bp_option) => parseInt(bp_option.id) === parseInt(selected_id)),
    (bp_option) => bp_option,
  );
};

export const getBpOptionByOptionGroupId = (product, is_mobile, optionGroupId) => {
  return go(
    filteredBpOptionGroupsForUi(product, is_mobile),
    find((optionGroup) => optionGroup.option_group.id == optionGroupId),
  );
};

export const getProductName = (product, _en) => {
  return product['name' + _en] || product._.base_product['name' + _en];
};

export const setBaseProductColorsByStoreProductColors = (product, store_product_colors) => {
  if (!isNeedPreview(product._.base_product)) {
    const public_bpc_names = go(
      store_product_colors,
      filter((spc) => {
        return spc.enabled && !spc.is_hidden && !spc.prohibited;
      }),
      map((spc) => spc._.base_product_color.name),
    );
    product._.base_product._.base_product_colors = go(
      product._.base_product._.base_product_colors,
      filter((bpc) => public_bpc_names.includes(bpc.name)),
      reject((bpc) =>
        go(
          bpc._.base_product_sizes2,
          every((bps) => bps._is_discontinued),
        ),
      ),
      sortBy((bpc) => bpc.no),
      sortByDesc((bpc) => bpc.id === product.base_product_color_id),
    );
  }

  product._.base_product_color = go(
    product._.base_product._.base_product_colors,
    find((bpc) => bpc.id === product.base_product_color_id),
  );
  if (!product._.base_product_color) {
    const bpc = product._.base_product._.base_product_colors[0];
    product._.base_product_color = bpc;
    product.base_product_color_id = bpc.id;
  }
};

export const makeFilteredBaseProductColorsByBpcs = (
  target_base_product_colors,
  source_base_product_colors,
) => {
  const bpc_names = go(
    source_base_product_colors,
    pluck('name'),
    map((name) => name?.trim()),
  );
  return go(
    target_base_product_colors,
    filter((bpc) => bpc_names.includes(bpc.name?.trim())),
    sortBy((bpc) => bpc.name),
  );
};
