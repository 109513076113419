import { go, html, strMap } from 'fxjs/es';
import { UtilStringS } from '../../../../Util/String/S/Function/module/UtilStringS.js';

export const customProductHeaderTmpl = (custom_data, custom_level, is_mobile) => {
  const { menu_name_en, custom_banners } = custom_data;

  return html`<div class="custom__header">
    <h1>${menu_name_en}</h1>
    <div class="custom__header__type-menus">
      ${go(
        custom_banners,
        strMap(({ title, link_url, is_disabled, custom_level: item_custom_level }) => {
          const is_selected = UtilStringS.compareStrings(item_custom_level, custom_level);
          return html`<div
            class="custom__header__type-menus__item ${is_selected ? 'selected' : ''}"
            data-custom_level="${item_custom_level}"
          >
            ${is_disabled
              ? html`<div class="custom__header__type-menus__item__disabled">
                  <span class="custom__header__type-menus__item__link">${title}</span>
                  ${is_mobile ? OPEN_EXPECTED_BALOON_SVG_HARDCODED_MO : OPEN_EXPECTED_BALOON_SVG_HARDCODED_PC}
                </div>`
              : is_selected
              ? html`<div class="custom__header__type-menus__item__link">${title}</div>`
              : html`<a href="${link_url}" class="custom__header__type-menus__item__link">${title}</a>`}
          </div>`;
        }),
      )}
    </div>
  </div>`;
};

const OPEN_EXPECTED_BALOON_SVG_HARDCODED_PC = html`<svg
  width="6.25rem"
  height="1.625rem"
  viewBox="0 0 100 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="position: absolute; top: -1.625rem; left: 50%; transform: translateX(-50%);"
>
  <path
    d="M89.5 0H10.5C4.70101 0 0 4.65125 0 10.3888C0 16.1264 4.70101 20.7777 10.5 20.7777H43.5L48.6434 25.4752C49.4096 26.1749 50.5904 26.1749 51.3566 25.4752L56.5 20.7777H89.5C95.299 20.7777 100 16.1264 100 10.3888C100 4.65125 95.299 0 89.5 0Z"
    fill="#00BAFF"
  />
  <text
    x="50"
    y="14"
    text-anchor="middle"
    style="font-family: Pretendard; font-size: 12px; font-weight: 700; line-height: 140%; fill: #000; text-align: center;"
  >
    25년 1월 Open
  </text>
</svg>`;

const OPEN_EXPECTED_BALOON_SVG_HARDCODED_MO = html`<svg
  width="92px"
  height="20px"
  viewBox="0 0 101 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="position: absolute; top: -1.625rem; left: 50%; transform: translateX(-50%);"
>
  <rect width="100%" height="100%" rx="10" fill="#00BAFF" />
  <text
    x="50"
    y="14"
    text-anchor="middle"
    style="font-size: 10px; font-weight: 700; line-height: 120%; fill: #000; text-align: center;"
  >
    25년 1월 Open
  </text>
</svg> `;
