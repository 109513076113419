import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict/index.js';
import en from 'date-fns/locale/en-US/index.js';
import jp from 'date-fns/locale/ja/index.js';
import kr from 'date-fns/locale/ko/index.js';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { UtilConstantS } from '../../../../modules/Util/S/Constant/module/UtilConstantS';
import differenceInHours from 'date-fns/differenceInHours/index.js';
import format from 'date-fns/format/index.js';

const locale = ET.lang == 'kr' ? kr : ET.lang == 'jp' ? jp : en;
const suffix = ET.lang == 'kr' ? ' 전' : ET.lang == 'jp' ? '前' : ' ago';

export function ago(date) {
  return `${formatDistanceToNowStrict(date, { locale })}${suffix}`;
}

export function dateFromNow(date: string | Date) {
  return ago(new Date(date));
}

export function getUTCToZoneTime(date: string | Date, country: string = UtilConstantS.TZ_SEOUL) {
  return utcToZonedTime(date, country);
}

export function formatTimeElapsed(date: string | Date, thresholdHours = 168) {
  /* 현재 시간과 받은 시간의 차이가 thresholdHours 넘어가면 기본 날짜 표기 */
  if (differenceInHours(new Date(), new Date(date)) > thresholdHours) {
    return format(new Date(date), 'yyyy.MM.dd');
  }

  /* thresholdHours를 넘어가지 않으면 ~ 분 전으로 표기 */
  return dateFromNow(date);
}
