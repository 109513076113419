import { go, html, each } from 'fxjs/es';
import { NewMakerPropertyBaseProductS } from '../../../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { NewMakerMenuTmplS } from '../../../../NewMaker/Menu/S/Tmpl/module/NewMakerMenuTmplS.js';
import { BpOptionUploadTypeS } from '../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { NewMakerConditionTooltipMoTmplS } from '../../../../NewMaker/ConditionTooltipMo/S/Tmpl/module/NewMakerConditionTooltipMoTmplS.js';
import {
  makeBtnTipTmpl,
  makeCanvasContainersTmpl,
  makeFrontBlankHtml,
  makeImageUploadDescHtml,
  makeLocOptDescHtml,
  makeMarppleLayerTmpl,
  makeSelectFaceHtml,
  makeUnderCanvassOptionTmpl,
} from '../../../../Maker/F/maker_tmpl.js';
import {
  marpplizerMode,
  setCanvasContainerSizeAndMpMakerHeight,
} from '../../../../Maker/F/f_canvas_setting.js';
import { $closest, $height, $qs, $setCss } from 'fxdom/es';
import { SVGEditorProductDetailF } from '../../../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { getProductColorInMaker, getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { eventMpMaker, initAllForMpMaker } from '../../../../Maker/F/mp_maker.js';
import { NessCustomMakerCoreF } from '../../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { NessCustomMakerMarpplizerF } from '../../../Marpplizer/F/Function/module/NessCustomMakerMarpplizerF.js';
import { SVGEditorTempSvgProductsF } from '../../../../SVGEditor/TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { resetUserCounts } from '../../../../MPay/Cart/F/fs.js';
import { MShopShareFramePopUpF } from '../../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { setPriceKeyringAndAcryl } from '../../../../Maker/S/product.js';
import { NessCustomMakerConstantS } from '../../..//S/Constant/module/NessCustomMakerConstantS.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { NessCustomMakerF } from '../module/NessCustomMakerF.js';
import { BaseProductAdditionalPriceRateS } from '../../../../BaseProduct/AdditionalPriceRate/S/Function/module/BaseProductAdditionalPriceRateS.js';
import { NewMakerProductS } from '../../../../NewMaker/Product/S/Function/module/NewMakerProductS.js';
import { getFcanvasFlattenCvObjs } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { NewMakerCanvasEditorF } from '../../../../NewMaker/CanvasEditor/F/Function/module/NewMakerCanvasEditorF.js';
import { NewMakerCanvasEditorSameDesignCheckerF } from '../../../../NewMaker/CanvasEditor/SameDesignChecker/F/Function/module/NewMakerCanvasEditorSameDesignCheckerF.js';

const locOptimizationIndicatorHtml = legacyHtml`
    <div class="loc_optimization_indicator">보편적 위치 맞춤 요청</div>
  `;

export const conditionalTooltip = () => {
  if (UtilF.isLegacyMobile()) return html``;

  return html`
    <div class="condition_tooltip" style="display: none;">
      <div class="condition_tooltip_text">
        윗면, 아랫면, 옆면 인쇄를 원하시면 <br />
        <strong>이미지를 점선까지 채워주세요.</strong>
        <img
          class="toggle_img"
          src="//s3.marpple.co/files/u_2798351/2024/11/original/98a684f6cec2b9d68ba2e454917710e381bfafdd1.svg"
          alt="question_mark"
        />
        <div class="show_detail">자세히 보기</div>
      </div>
      <div class="condition_tooltip_img">
        <img
          src="//s3.marpple.co/files/u_2798351/2024/10/original/2bd6bd2faade706167d56dcc11947975a6e1bb3e1.png"
          alt="tooltip_img"
        />
      </div>
    </div>
  `;
};

const makeNessMpMakerHtml = (product_color, my_image_count, is_printable, mobile_main_icon_menu_tmpl) => {
  const bpcf = product_color._.base_product_color._.base_product_color_faces;

  const is_mp_maker_display_none =
    NewMakerPropertyBaseProductS.bpOptionPreviews.getObject(product_color._.base_product) &&
    UtilF.isLegacyMobile();

  return legacyHtml`
      <div
        class="mp_maker"
        id="maker_frame"
        faces_length="${bpcf.length}"  style="${is_mp_maker_display_none ? 'display:none;' : ''}"}
      >
        ${NewMakerMenuTmplS.menu}
        <div id="maker">
      <div class="board">
        ${makeImageUploadDescHtml()}
        ${makeLocOptDescHtml()}
        ${makeFrontBlankHtml(product_color._.base_product_color._.base_product_color_faces)}
        <div class="canvass">${makeCanvasContainersTmpl()} ${makeUnderCanvassOptionTmpl()}</div>
        <ul class="decoration_menu_for_pc">
          <li class="ness-custom-maker__asset-library circle_icon">
            <div class="icon"></div>
            <div class="text">커스텀 에셋</div>
          </li>
          <li class="circle_icon ness-custom-maker__share">
            <div class="icon"></div>
            <div class="text">${T('line_maker::Share')}</div>
          </li>
        </ul>
        ${makeMarppleLayerTmpl()}
        ${BpOptionUploadTypeS.UPLOAD_TYPE.html.boardHtml(box().maker.product_color)}
      </div>
      ${is_printable ? locOptimizationIndicatorHtml : ''}
      ${makeBtnTipTmpl(product_color)}
      ${conditionalTooltip()}
      ${NewMakerConditionTooltipMoTmplS.mo_tooltip()}
      ${mobile_main_icon_menu_tmpl ?? ``}
      ${makeSelectFaceHtml()}
      ${UtilF.isLegacyMobile() ? bottomMenuHtml() : ''}
      ${NessCustomMakerMarpplizerF.makeNessMarpplizerHtml(product_color)}
      <video
        src=""
        id="marpple_video"
        muted="muted"
        loop="loop"
        playsinline="playsinline"
        controls="controls"
        crossorigin="anonymous"
        style="position: fixed; top: -10000px;"
      ></video>
    </div>
      </div>
  `;
};

function bottomMenuHtml() {
  return html`
    <div class="btm_menus">
      <div class="btm_menu image_editor">
        <div class="fit_to_print_width circle_icon">
          <div class="icon">
            <div class="option check_box"></div>
          </div>
          <div class="text">${T('maker::양옆 채우기')}</div>
        </div>
        <div class="img_pattern circle_icon">
          <div class="icon"></div>
          <div class="text">${T('Pattern')}</div>
        </div>
      </div>
      <div class="btm_menu image_editor _only_one">
        <div class="fit_to_print_width circle_icon">
          <div class="icon">
            <div class="option check_box"></div>
          </div>
          <div class="text">${T('maker::양옆 채우기')}</div>
        </div>
        <div class="img_pattern circle_icon">
          <div class="icon"></div>
          <div class="text">${T('Pattern')}</div>
        </div>
      </div>
      <div class="btm_menu image_editor _from_library">
        <div class="fit_to_print_width circle_icon">
          <div class="icon">
            <div class="option check_box"></div>
          </div>
          <div class="text">${T('maker::양옆 채우기')}</div>
        </div>
        <div class="img_pattern circle_icon">
          <div class="icon"></div>
          <div class="text">${T('Pattern')}</div>
        </div>
      </div>
    </div>
  `;
}

function makeWarningImg() {
  const fabric_warning_img = new Image();
  fabric_warning_img.crossOrigin = 'Anonymous';
  fabric_warning_img.src =
    '//s3.marpple.co/files/u_193535/2024/10/original/7588a011b870087c0678ef7be1d58fa758dbe4cf1.png?canvas=v1';
  return fabric_warning_img;
}

export const initNessMpMaker = async (target_el, is_printable = false, mobile_main_icon_menu_tmpl) => {
  try {
    const product_color = box.sel('maker->product_color');
    const my_image_count = box.sel('user_counts->my_image');
    const maker_props = box().maker.maker_props || {};
    box().maker.maker_props = maker_props;
    maker_props.shipping_price = box().product_detail.stores_delivery_company.shipping_price;
    G.mp.maker.is_off_alert_image_quality = true;
    G.mp.maker.reset_box_data_post_process = (product) => {
      BaseProductAdditionalPriceRateS.setProfit(product);
    };
    if (product_color._.base_product._.confirm_popups?.length) {
      product_color._.base_product._.confirm_popups = [NessCustomMakerConstantS.NESS_POPUP_PROPERTY];
    }
    G.mp.maker.is_mobile = MShopUtilF.isMobile();
    G.mp.maker.priceAlertBySize = NessCustomMakerCoreF.priceAlertBySize;
    G.mp.maker.is_asset_studio = true;
    fabric.warning_img = makeWarningImg();
    G.mp.maker.default_header_height = go($qs('.ness-default-header'), $height);
    G.mp.maker.mainColor = (o) => `rgba(0, 186, 255, ${o})`;
    G.mp.maker.exitTempSvgApplication = NessCustomMakerF.refreshCustomProductDetailHead;
    G.mp.maker.is_activated = true;

    setPriceKeyringAndAcryl(product_color);
    await go(
      makeNessMpMakerHtml(product_color, my_image_count, is_printable, mobile_main_icon_menu_tmpl),
      $.el,
      $.prepend_to(target_el),
    );
    eventMpMaker();
    $setCss({ opacity: 0 })($qs('.mp_maker .canvass'));
    NessCustomMakerMarpplizerF.marpplizerInit();

    marpplizerMode('basic');
    setCanvasContainerSizeAndMpMakerHeight();
    G.mp.maker.SameDesignChecker = new NewMakerCanvasEditorSameDesignCheckerF.SameDesignChecker(box().maker);
    await initAllForMpMaker(true);
    go(
      getRealFcanvass(),
      each((fcanvas) =>
        go(fcanvas, getFcanvasFlattenCvObjs, each(NewMakerCanvasEditorF.service.setResizedImageToFit)),
      ),
    );

    const maker_frame_el = $qs('#maker_frame');
    if (maker_frame_el?.dataset?.is_vector === 'true') {
      const bp_option_groups_el = $qs('.bp_option_groups');
      await SVGEditorProductDetailF.vectorEditorPreviewInit(bp_option_groups_el);
      SVGEditorTempSvgProductsF.applyTempSvgProduct({
        tab_el: $closest('.don_tab')(maker_frame_el),
        user_id: box.sel('is_user->id'),
        product: box().maker.product_color,
      });
    }
    $setCss({ opacity: 1 })($qs('.mp_maker .canvass'));
    $.remove_class([$1('html'), $1('body')], 'mp_maker_not_ready');

    // $.don_loader_end();
    const urlParams = new URLSearchParams(window.location.search);
    const isRedirectFromLogin = urlParams.get('is_redirect_from_login') === 'true';
    if (isRedirectFromLogin) {
      resetUserCounts();
      const url = new URL(window.location.href);
      MShopShareFramePopUpF.confirm({
        title: '상품을 장바구니에 담았습니다.',
        body: '장바구니를 확인하시겠어요?',
        cancel: '계속 커스텀하기',
        ok: '장바구니로 이동',
      }).then((res) => {
        url.searchParams.delete('is_redirect_from_login');
        const newUrl = url.pathname + '?' + url.searchParams.toString();
        window.history.replaceState({}, '', newUrl);

        if (res) {
          window.location.href = `/${T.lang}/@/cart`;
          $.don_loader_start();
          setTimeout(() => {
            $.don_loader_end();
          }, 5000);
        }
      });
    }
    // NessCustomMakerCoreF.setInitialDesigns(product_color);
    NessCustomMakerCoreF.adjustBpTab(product_color._.base_product);

    const checkButtonActivation = (product_color, maker_props) => {
      const is_size_unavailable = NewMakerProductS.isUnavailableSize(product_color);
      if (is_size_unavailable) {
        return false;
      }
      if (maker_props.is_condition_agree !== undefined) {
        return maker_props.is_condition_agree;
      }

      return true;
    };

    const is_button_disabled = checkButtonActivation(product_color, maker_props);

    NessCustomMakerMarpplizerF.toggleButtonActive(is_button_disabled);
  } catch (e) {
    console.error(e);
    MakerDebugF.api.MakerDebugPost(getProductColorInMaker(), e, MakerDebugConstantS.Category.MakerInit);
  }
};
