import anime from 'animejs';
import { $, html, View } from 'rune-ts';
import { MShopDefaultIcon } from '../../../../renderApp/components/atoms/Icon';
import { staticTypo } from '../../../typography/typo';
import klass from './Progress.module.scss';

export interface ProgressData {
  /** @example 0 - 100 */
  percent: number;
}

export interface ProgressOption {
  klass?: string;
  bar_color?: 'blue' | 'white';
  size?: 'normal' | 'small';
}

export class Progress extends View<ProgressData> {
  private _state = {
    circumference: 2 * Math.PI * 54,
    offset: 0,
    progress: 0,
  };

  constructor(data: ProgressData, public option: ProgressOption = {}) {
    if (data.percent < 0) data.percent = 0;
    if (data.percent > 100) data.percent = 100;
    super(data);

    this._state.progress = this.data.percent / 100;
    this._state.offset = this._state.circumference * (1 - this._state.progress);
  }

  override template() {
    return html`
      <div
        class="${klass.progress} ${this.option.klass}"
        data-size="${this.option.size ?? 'normal'}"
        data-bar_color="${this.option.bar_color ?? 'blue'}"
      >
        <svg class="${klass.circle_progress}" width="100%" height="100%" viewBox="0 0 120 120">
          <circle class="${klass.frame}" cx="60" cy="60" r="54" stroke-width="12" />
          <circle
            class="${klass.bar}"
            stroke-dashoffset="${this._state.offset}"
            stroke-dasharray="${this._state.circumference}"
            cx="60"
            cy="60"
            r="54"
            stroke-width="12"
          />
        </svg>
        <span class="${klass.value}">
          ${this.option.size === 'small'
            ? html`<span class="${klass.icon}">${MShopDefaultIcon()}</span>`
            : html` <span class="percent ${staticTypo('bebas_24_bold')}"> ${this.data.percent} </span>
                <span class="${staticTypo('bebas_24_bold')}">% </span>`}
        </span>
      </div>
    `;
  }

  changePercent(percent: number) {
    this.data.percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;
    this._state.progress = this.data.percent / 100;
    this._state.offset = this._state.circumference * (1 - this._state.progress);
    const el$ = $(this.element());

    if (this.option.size === 'small') {
      anime({
        easing: 'easeInOutSine',
        duration: 1000,
        targets: el$.find(`.${klass.bar}`)?.element(),
        strokeDashoffset: this._state.offset,
      });
    } else {
      anime
        .timeline({
          easing: 'easeInOutSine',
          duration: 1000,
        })
        .add({
          targets: el$.find(`.${klass.bar}`)?.element(),
          strokeDashoffset: this._state.offset,
        })
        .add(
          {
            targets: el$.find(`.percent`)?.element(),
            round: 1,
            innerHTML: this.data.percent,
          },
          '-=1000',
        );
    }
  }
}
