import { go, html, minBy, reduce, strMap } from 'fxjs/es';
import { MShopAppTodayTmplS } from './module/MShopAppTodayTmplS.js';

export const makeGridHtml = (
  todays,
  category_query,
  { klass, columns = 3, replace_stack, without_category } = {},
) => {
  const groups = reduce(
    (cols, today) => {
      const match_col = minBy((col) => col.height, cols);
      match_col.height = match_col.height + today.thumb_ratio;
      match_col.push(today);
      return cols;
    },
    Array(columns)
      .fill(1)
      .map(() => [])
      .map((x) => ((x.height = 0), x)),
    todays,
  );

  const makeGridItemHtml = (item) =>
    html`<div class="today-static-grid__item">
      ${MShopAppTodayTmplS.makeTodayItem(item, {
        not_lazy: true,
        category_query,
        klass,
        replace_stack,
        without_category,
      })}
    </div> `;

  return html`
    <div class="today-static-grid">
      ${go(
        groups,
        strMap(
          (items) => html`<div class="today-static-grid__column">${strMap(makeGridItemHtml, items)}</div>`,
        ),
      )}
    </div>
  `;
};
