import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { QUESTION_ERRORS } from '../Constant/constant.js';

export const checkName = (name) => {
  if (!name?.length) return { result: false, error: QUESTION_ERRORS.required, type: 'name' };
  if (UtilS.checkTextLength(name) > 255)
    return { result: false, error: QUESTION_ERRORS.text_length_255, type: 'name' };
  if (UtilS.is_special_str(name))
    return { result: false, error: QUESTION_ERRORS.restrict_character, type: 'name' };

  return { result: true };
};

export const checkMobile = (mobile) => {
  if (!mobile?.length) return { result: false, error: QUESTION_ERRORS.required, type: 'mobile' };
  if (!UtilS.is_number(mobile)) return { result: false, error: QUESTION_ERRORS.only_number, type: 'mobile' };
  if (!UtilS.isKrPhoneNumber(mobile))
    return { result: false, error: QUESTION_ERRORS.mobile_check, type: 'mobile' };

  return { result: true };
};

export const checkEmail = (email) => {
  if (!email?.length) return { result: false, error: QUESTION_ERRORS.required, type: 'email' };
  if (!UtilS.isValidEmail(email)) return { result: false, error: QUESTION_ERRORS.email_check, type: 'email' };

  return { result: true };
};

export const checkTitle = (title) => {
  if (!title?.length) return { result: false, error: QUESTION_ERRORS.required, type: 'title' };
  if (UtilS.checkTextLength(title) > 255)
    return { result: false, error: QUESTION_ERRORS.text_length_255, type: 'title' };

  return { result: true };
};

export const checkBody = (body) => {
  if (!body?.length) return { result: false, error: QUESTION_ERRORS.required, type: 'body' };
  if (UtilS.checkTextLength(body) > 2000)
    return { result: false, error: QUESTION_ERRORS.text_length_2000bytes, type: 'body' };

  return { result: true };
};
