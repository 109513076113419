import { $attr, $data, $delegate, $find, $val } from 'fxdom/es';
import { go, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppCommunityDetailF } from '../../../Detail/F/Function/module/MShopAppCommunityDetailF.js';
import { MShopAppCommunityMoCommentEditorMuiS } from '../../S/Mui/module/MShopAppCommunityMoCommentEditorMuiS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...MShopAppCommunityMoCommentEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    const is_mobile = MShopUtilF.isMobile();
    go(
      tab_el,
      tap($find('.article-comment-write__textarea'), (el) => setTimeout(() => el.focus(), 300)),
      $delegate('keydown', '.article-comment-write__textarea', MShopAppCommunityDetailF.keyDownTextarea),
      $delegate('keyup', '.article-comment-write__textarea', MShopAppCommunityDetailF.keyUpTextarea),
      $delegate(
        'input',
        '.article-comment-write__textarea',
        MShopAppCommunityDetailF.inputTextarea({ is_mobile }),
      ),
      $delegate('click', '.article-comment-write__btn_submit', async (e) => {
        try {
          const container_el = $find('.article-comment-editor', e.delegateTarget);
          const store_id = $attr('data-store_id', container_el);
          const article = $data(container_el);
          const article_id = article.id;
          const body = $val($find('.article-comment-write__textarea', e.delegateTarget));

          if (!body || !body.length)
            return MShopUtilF.popToastMsg(T('community::내용을 입력해 주세요'), 'error');

          return go(
            $.post(`/@api/stores/${store_id}/articles/${article_id}/comment`, {
              body,
            }),
            (data) => {
              if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');
              MuiF.closeFrame(data);
            },
          );
        } catch (_e) {
          MShopUtilF.popToastMsg(T('community::실패'), 'error');
        }
      }),
    );
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
