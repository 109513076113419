import { NessProductListType } from '../../App/Plus/Product/S/Constant/product.js';
import { makeMenuHref } from '../../Util/S/Function/util.js';
import { STORE_PRODUCT_CUSTOM_LEVEL } from '../../../Creator/Product/S/constant.js';

export const NESS_CUSTOM_PRODUCT_LIST_URL = makeMenuHref(NessProductListType.custom, 'kr');
export const NESS_CUSTOM_ASSET_PRODUCT_LIST_URL = `${NESS_CUSTOM_PRODUCT_LIST_URL}?custom_level=asset`;
export const NESS_CUSTOM_USER_PRODUCT_LIST_URL = `${NESS_CUSTOM_PRODUCT_LIST_URL}?custom_level=user`;

export const NESS_CUSTOM_HARDCODING = {
  maplestory: {
    menu_name_kr: '마이 메이플스토리',
    menu_name_en: 'My MapleStory',
    is_show_home_list: false,
    is_show_banner: true,
    custom_banners: [
      {
        title: 'Custom Studio',
        custom_level: STORE_PRODUCT_CUSTOM_LEVEL.ASSET,
        img_url:
          '//s3.marpple.co/files/u_29089/2024/11/original/8d9ea8fa2bbbde0032a7a662d28256d1133c3ece2.png',
        img_url_mo:
          '//s3.marpple.co/files/u_29089/2024/11/original/7a8cda84056764f031274d22719dd76e451b62ce1.png',
        link_url: NESS_CUSTOM_ASSET_PRODUCT_LIST_URL,
        is_disabled: false,
      },
      {
        title: 'Labs',
        custom_level: STORE_PRODUCT_CUSTOM_LEVEL.USER,
        img_url:
          '//s3.marpple.co/files/u_29089/2024/12/original/48d8734eb8596e0995b148dc47fb77c1ee1e49882.png',
        img_url_mo:
          '//s3.marpple.co/files/u_29089/2024/12/original/ec60d08ee61c4ad256d07a2a7e003928512f9e001.png',
        link_url: NESS_CUSTOM_USER_PRODUCT_LIST_URL,
        is_disabled: true,
      },
    ],
  },
};
