import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { makeProductStoryEditorHtml } from '../../../../../Creator/ProductStoryEditor/S/tmpl.js';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopStudioPreDiscountProductTmplS } from '../../../PreDiscountProduct/S/Tmpl/module/MShopStudioPreDiscountProductTmplS.js';
import { ONLY_FRONT_END_DATA } from '../Constant/digitalproductcreator.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { isEbookByCateItemId } from '../Function/util.js';
import {
  makeImageUploadResultHtml,
  makeIsHyperLinkInputHtml,
  makePreviewUploadFileHtml,
  makeUploadFileHtml,
} from './makeEachCategoryHtml.js';
import { makeThumbnailRegister } from './makeThumbnailRegister.js';
import {
  creatorBottomButtons,
  makeEbookCateSelectInputHtml,
  makeEbookIllustratorInputHtml,
  makeEbookPublicationDateInputHtml,
  makeEbookPublisherInputHtml,
  makeEbookTranslatorInputHtml,
  makeEbookWriterInputHtml,
  makeInformationHtml,
  makeIsPublicInputHtml,
  makeProductNameInputHtml,
  makeProductPriceInputHtml,
  makeSetLicenseRangeInputHtml,
  makeSetQnaTypeInputHtml,
  makeTokenGateInputHtml,
  makeUploadResultsDashboardHtml,
} from './share.js';

const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;

const creatorButtons = ({
  has_permission_to_delete,
  has_permission_to_prohibit,
  is_prohibited,
  is_solution,
  product_id,
  store_url,
} = {}) => {
  if (!product_id) {
    return html`
      <div class="digital-product-creator__form-buttons-container" is_open="false">
        <button
          type="button"
          class="digital-product-creator__btn-submit digital-product-creator__btn-submit--disabled"
        >
          ${T(messages.digital_products.btn_register)}
        </button>
      </div>
    `;
  }

  return html`
    <div class="digital-product-creator__form-buttons-container" is_open="false">
      <button type="button" class="digital-product-creator__btn-cancel">${T('취소')}</button>
      <button type="button" class="digital-product-creator__btn-submit">${T('완료')}</button>
      <button class="digital-product-creator__btn-more">
        <img
          src="//s3.marpple.co/files/u_1093672/2020/9/original/8498917a4be98ac032b5d267e4e4de0f217c5ef21.png"
        />
      </button>
      <div class="digital-product-creator__more-buttons more-buttons">
        <a href="${store_url}/products/${product_id}" target="_blank">${T('미리 보기')}</a>
        ${UtilS.htmlIf2(
          has_permission_to_delete,
          () =>
            html` <button class="digital-product-creator__btn-delete btn-delete">${T('상품 삭제')}</button> `,
        )}
        ${UtilS.htmlIf2(
          has_permission_to_prohibit,
          () =>
            html`
              <button class="digital-product-creator__btn-prohibit btn-prohibit">
                ${is_prohibited ? T('판매 보류 취소') : T('판매 보류')}
              </button>
              <button class="digital-product-creator__btn-is_solution btn-is_solution">
                ${is_solution ? T('신고 상품 취소') : T('신고 상품')}
              </button>
            `,
        )}
      </div>
    </div>
  `;
};

export const baseCreatorPc = async ({
  digital_product,
  action,
  store_ecommerce_info,
  store_url,
  crew_domain_name,
  ebook_categories,
}) => {
  const qna_type = digital_product?.qna_type || store_ecommerce_info?.qna_type;
  const front_data = ONLY_FRONT_END_DATA[digital_product?.sp_cate_item_id];
  /* T함수의 결과가 promise라 일단 이렇게.. */
  const cate_name = await front_data.cate_view_name;
  const i18n = {
    create_title: T('studio::digital_products::header', { cate_name }),
    modify_title: T('studio::digital_products::header_modify', {
      cate_name,
    }),
  };

  return html`
    <div class="digital-product-creator" data-fx-json="${$dataStr(digital_product)}">
      <div class="digital-product-creator__left">
        <div class="digital-product-creator__left-container">
          <div class="digital-product-creator__head">
            <h2 class="digital-product-creator__title">
              ${digital_product.store_product_id ? i18n.modify_title : i18n.create_title}
            </h2>
            <p class="digital-product-creator__desc">
              <span class="digital-product-creator-form__required">*</span>
              ${T(messages.digital_products.required)}
            </p>
          </div>
          <div class="digital-product-creator__thumbnails-area">
            ${makeThumbnailRegister(digital_product?.thumbnails)}
          </div>
        </div>
      </div>
      <div class="digital-product-creator__right is-wheel-number-blur">
        <div class="digital-product-creator__form-buttons digital-product-creator__form-buttons">
          ${creatorButtons({
            ...action,
            is_prohibited: digital_product?.is_prohibited,
            is_solution: digital_product?.is_solution,
            product_id: digital_product?.product_id,
            store_url,
          })}
        </div>
        <div class="digital-product-creator__form-area">
          <div class="digital-product-creator-form">
            <div class="digital-product-creator-form__control">
              ${makeIsPublicInputHtml(digital_product?.is_public)}
            </div>
            <div class="digital-product-creator-form__control dpc-styl__mt-24px">
              ${makeProductNameInputHtml({
                product_name_kr: digital_product?.product_name_kr,
                cate_name,
                name_placeholder: front_data.name_placeholder,
              })}
            </div>
            <div class="digital-product-creator-form__control dpc-styl__mt-24px">
              ${makeProductPriceInputHtml({
                price: digital_product?.price,
                cate_name,
                price_placeholder: front_data.price_placeholder,
              })}
            </div>
            ${UtilS.htmlIf2(
              MShopStudioPreDiscountProductS.isPreProductDiscountSupported(),
              () => html`<div class="digital-product-creator-form__control dpc-styl__mt-24px">
                ${MShopStudioPreDiscountProductTmplS.makePreDiscountPriceTmpl({
                  pre_discount_price: digital_product?.pre_discount_price,
                  price: digital_product?.price,
                })}
              </div>`,
            )}
            ${UtilS.htmlIf2(
              isEbookByCateItemId(digital_product?.sp_cate_item_id),
              () => html`
                <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                  ${makeEbookWriterInputHtml(digital_product.ebook_writer)}
                </div>
                <div class="digital-product-creator-form__input-wrap">
                  <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                    ${makeEbookIllustratorInputHtml(digital_product.ebook_illustrator)}
                  </div>
                  <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                    ${makeEbookTranslatorInputHtml(digital_product.ebook_translator)}
                  </div>
                </div>
                <div class="digital-product-creator-form__input-wrap">
                  <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                    ${makeEbookPublisherInputHtml(digital_product.ebook_publisher)}
                  </div>
                  <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                    ${makeEbookPublicationDateInputHtml(digital_product.ebook_publication_date)}
                  </div>
                </div>
                <div class="digital-product-creator-form__control dpc-styl__mt-24px">
                  ${makeEbookCateSelectInputHtml({
                    ebook_categories,
                    selected_id: digital_product.ebook_category_id,
                  })}
                </div>
              `,
            )}
            <div class="digital-product-creator-form__control dpc-styl__mt-24px">
              ${makeSetLicenseRangeInputHtml(digital_product?.license_type)}
            </div>

            <hr />

            ${
              /* TODO 카테고리 하드코딩 */ digital_product?.sp_cate_item_id ===
              MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE
                ? makeIsHyperLinkInputHtml(digital_product?.is_hyper_link)
                : ''
            }

            <div class="digital-product-creator-form__control">
              <div class="digital-product-creator-form__input-title">
                ${front_data.upload_input_title}
                <span class="digital-product-creator-form__required">*</span>
              </div>
              ${makeUploadFileHtml({
                guide_main: front_data.upload_guide_main_message,
                guide_sub: front_data.upload_guide_sub_message,
                accept: front_data.accept,
                files_count: digital_product?.signed_url_keys?.length,
              })}
              ${digital_product?.files_infos?.length
                ? html`
                    ${makeUploadResultsDashboardHtml({
                      files_total_count: digital_product?.files_infos?.length,
                      init_count: digital_product?.files_infos?.length,
                    })}
                    <div
                      class="digital-product-creator-form__upload-results digital-product-creator-form__toggle"
                    >
                      ${strMap(makeImageUploadResultHtml, digital_product?.files_infos)}
                    </div>
                  `
                : ''}
            </div>

            ${
              /* TODO 카테고리 하드코딩 */ digital_product?.sp_cate_item_id ===
              MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.VOICE_PACK
                ? html` <div class="digital-product-creator-form__control">
                    <div class="digital-product-creator-form__input-title">미리듣기 파일 업로드</div>

                    ${makePreviewUploadFileHtml({
                      guide_main: '미리듣기 파일을 업로드해 주세요.',
                      guide_sub: '업로드 가능 형식 : MP3, ACC / 파일당 1GB 이하 / 최대 60초 업로드 가능',
                      accept: '.mp3,.acc,.m4a',
                    })}
                  </div>`
                : ''
            }

            <hr />

            <div class="digital-product-creator-form__control">
              ${makeTokenGateInputHtml(digital_product?.stores_products_token_gates)}
            </div>

            <div class="digital-product-creator-form__story dpc-styl__mt-40px">
              ${makeProductStoryEditorHtml(digital_product?.product_story, crew_domain_name)}
            </div>

            <div class="digital-product-creator-form__control dpc-styl__mt-24px">
              ${makeSetQnaTypeInputHtml({
                qna_type,
                qna_link: digital_product?.qna_link || store_ecommerce_info?.qna_link,
              })}
            </div>

            <div class="digital-product-creator-form__information-list dpc-styl__mt-24px">
              ${makeInformationHtml({
                title: '디지털 상품 판매 안내',
                contents: [
                  '·&nbsp;&nbsp;구매자는 결제 완료 후, 구매내역에서 7일 간 다운로드 받을 수 있습니다. (기한 이후 다운로드를 원할 경우 재구매가 필요합니다.)',
                  '·&nbsp;&nbsp;디지털 상품 특성 상 전자상거래 법 제 17조에 의거하여 구매자가 구매한 상품에 문제가 없다면 어떠한 사유로도 교환 및 환불이 불가합니다.',
                  '·&nbsp;&nbsp;구매되었거나 장바구니에 담긴 상품은 수정하거나 삭제할 수 없습니다. 파일을 수정하고 싶을 경우 새로운 상품을 등록해 주세요.',
                ],
              })}
              ${makeInformationHtml({
                is_hide: true,
                title: '판매 수수료 정보',
                contents: [
                  '·&nbsp;&nbsp;디지털 상품 판매 수수료는 1건당 400원이며 아래 비용을 포함하고 있습니다.',
                  '&nbsp;&nbsp;&nbsp;(클라우드 스토리지 비용 업로드 / 다운로드 트래픽 비용 / 구매 문자, 알림톡 및 이메일 전송 비용 / 저작권 보호를 위한 구매 고객 휴대폰 인증 비용)',
                ],
              })}
              ${makeInformationHtml({
                title: '저작권 및 콘텐츠 정책',
                contents: [
                  '·&nbsp;&nbsp;개인 소장 및 다운로드용 라이센스 판매입니다.',
                  '·&nbsp;&nbsp;이미지/디자인은 크리에이터 본인이 직접 촬영/창작한 작업물이며 저작권/소유권이 명확히 있는 콘텐츠의 판매입니다.',
                  '·&nbsp;&nbsp;업로드하는 모든 콘텐츠는 저작권법을 준수해야 하며, 타인의 저작권을 침해해서는 안 됩니다. 허위 정보나 불법 콘텐츠는 등록이 제한될 수 있습니다.',
                  '·&nbsp;&nbsp;저작권, 재산권, 초상권 위반 발견 시 해당 샵은 계정 운영 정지될 수 있으며, 관련 모든 법적 분쟁에 대한 책임은 크리에이터 본인에게 있습니다.',
                ],
              })}
              <label class="digital-product-creator-form__check-box-label">
                <input
                  type="checkbox"
                  name="${digital_product_prop.agree_copyright}"
                  ${digital_product?.agree_copyright && 'checked'}
                />
                저작권 및 콘텐츠 정책에 동의합니다.
                <span class="digital-product-creator-form__required-message">(필수)</span>
              </label>
            </div>
          </div>
        </div>
        <div class="digital-product-creator__form-buttons digital-product-creator__form-buttons">
          ${creatorBottomButtons(digital_product.product_id)}
        </div>
      </div>
    </div>
  `;
};
