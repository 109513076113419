import { MShopStudioDigitalProductCreatorConstantS } from '../../../Studio/DigitalProductCreator/S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { messages } from './message_id.js';

export const PRODUCT_STATUS = {
  PROHIBITED: 'prohibited',
  CHECKING: 'checking',
  PUBLIC: 'public',
  PRIVATE: 'private',
  READY_CONFIRM: 'ready_confirm',
  NOT_CONFIRMED: 'not_confirmed',
  NOT_USE_PRODUCT: 'not_use_product',
  PB_ME: 'pb_me',
};

export const PRODUCT_STATUS_TEXT = {
  [PRODUCT_STATUS.PROHIBITED]: T(messages.status.prohibited),
  [PRODUCT_STATUS.CHECKING]: T(messages.status.checking),
  [PRODUCT_STATUS.PUBLIC]: T(messages.status.public),
  [PRODUCT_STATUS.PRIVATE]: T(messages.status.private),
  [PRODUCT_STATUS.READY_CONFIRM]: T(messages.status.ready_confirm),
  [PRODUCT_STATUS.NOT_CONFIRMED]: T(messages.status.not_confirmed),
  [PRODUCT_STATUS.NOT_USE_PRODUCT]: T(messages.status.not_use_product),
  [PRODUCT_STATUS.PB_ME]: T('mshop::ProductStatus::pb_me'),
};

export const DTG_LICENSE_DESCRIPTION = {
  [MShopStudioDigitalProductCreatorConstantS.LICENSE_MAP.PRIVATE]: T(
    '구매자만 소장 가능한 파일로 무단 복제, 배포, 전송할 경우 법적 책임이 따를 수 있습니다.',
  ),
  [MShopStudioDigitalProductCreatorConstantS.LICENSE_MAP.COMMERCIAL]: T(
    '저작권 침해가 되지 않는 범위 내에서 상업적인 목적으로 활용할 수 있습니다.',
  ),
};

export const DTG_LICENSE_MESSAGE = {
  [MShopStudioDigitalProductCreatorConstantS.LICENSE_MAP.PRIVATE]: T('개인 소장'),
  [MShopStudioDigitalProductCreatorConstantS.LICENSE_MAP.COMMERCIAL]: T('상업적 활용 가능'),
};

export const ECOMMERCE_TYPE = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY',
};

export const is_view_company_info = ({ company_info_public = false, type }) => {
  return company_info_public && ECOMMERCE_TYPE[type] === ECOMMERCE_TYPE.COMPANY;
};

export const HOTEL_KEYRING_ID = 7106;

export const MULTI_OPTION_WRAP_KLASS = 'pd-form__pb-multi-options';
