import { defaultTo, go, html, map, sel, strMap, sumBy, zipWithIndexL } from 'fxjs/es';
import { ProductBadgeList } from '../../../../../../../services/MarppleShop/renderApp/components/cells/ProductBadgeList/ProductBadgeList.ts';
import { MpShopProductTmplS } from '../../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { NessOutboundTmplS } from '../../../../../../Ness/Outbound/S/Tmpl/module/NessOutboundTmplS.js';
import { creatorPriceOfProduct } from '../../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopStudioPreDiscountProductS } from '../../../../../Studio/PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppProductItemS } from '../../../../Product/Item/S/Function/module/MShopAppProductItemS.js';
import { makeBaseProductInfoHtml } from './baseProductInfo.js';
import { makeDtgFaqHtml } from './dtgFaq.js';
import { makeDtgPurchaseInfoHtml } from './dtgPurchaseInfo.js';
import { makeFaqHtml } from './faq.js';
import { makePbFaqHtml } from './pbFaq.js';
import { makePbInfoHtml } from './pbInfo.js';
import { makePbPurchaseInfoHtml } from './pbPurchaseInfo.js';
import { makeProductStoryHtml } from './productStory.js';
import { makePurchaseInfoHtml } from './purchaseInfo.js';
import { makeTplFaqHtml } from './tplFaq.js';
import { makeTplPurchaseInfoHtml } from './tplPurchaseInfo.js';
import { messages } from '../../message_id.js';

const func = (item) => (typeof item === 'function' ? item : () => item);

const makeProductDetailBadgesHtml = ({ is_mobile, badge_list_data }) => {
  return new ProductBadgeList(badge_list_data, {
    is_mobile,
    klass: 'pd-preview__badges',
    is_lazy_timer: true,
  }).toHtmlSSR();
};

const makeBadgeListData = (product_detail) => {
  const is_public = sel('is_public', product_detail);
  const stores_product = sel('_.stores_product', product_detail);
  const sell_start_at = sel('sell_start_at', stores_product);
  const sell_end_at = sel('sell_end_at', stores_product);
  const quantity = sel('quantity', stores_product);
  const order_count = sel('order_count', stores_product);
  const is_quantity_public = sel('is_quantity_public', stores_product);
  const is_token_gate = !!sel('_.stores_products_token_gates', stores_product).length;
  const spo_item_stocks = go(
    product_detail._.spo?._?.spo_items,
    map((spo_item) => spo_item?._?.spo_item_stock),
  );
  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) <= 0;

  const start_at = new Date(sell_start_at);
  const end_at = new Date(sell_end_at);

  const out_of_stock =
    (quantity > 0 && quantity <= order_count) ||
    (sell_start_at && end_at <= new Date()) ||
    is_no_spo_item_stock;

  return {
    is_public,
    sell_start_at: start_at,
    sell_end_at,
    quantity,
    order_count,
    is_quantity_public,
    out_of_stock,
    is_token_gate,
  };
};

// 상품 이미지 슬라이드
export const makeProdImageSlideHtml = ({ product_detail, is_mobile }) => {
  if (UtilS.isNessApp()) {
    return makeProdImageSlideHtmlNess({ product_detail });
  }

  let thumbnails = go(product_detail, sel('thumbnails.value'), defaultTo([]));
  const thumb_index = thumbnails.findIndex((a) => a.is_thumb);
  const main_index = thumb_index === -1 ? 0 : thumb_index;

  thumbnails = [...thumbnails.slice(main_index), ...thumbnails.slice(0, main_index)];

  const img_alt = go(void 0, () => {
    if (T.lang == 'kr') {
      return `${product_detail['name' + _en]}, 마플샵 굿즈`;
    } else {
      return `${product_detail['name' + _en]}, MARPPLESHOP GOODS`;
    }
  });

  const badge_list_data = makeBadgeListData(product_detail);

  return html`
    <div class="pd-preview swiper-container" data-len="${thumbnails.length}" data-main-index="${main_index}">
      ${makeProductDetailBadgesHtml({ is_mobile, badge_list_data })}

      <div class="pd-preview__list swiper-wrapper">
        ${strMap(
          (thumbnail_image) => `
            <div class="pd-preview__item swiper-slide">
              ${MpShopProductTmplS.thumbnail(product_detail, {
                thumbnail_image,
                width: 1480,
                img_alt,
              })}
            </div>
          `,
          thumbnails,
        )}
      </div>

      ${UtilS.htmlIf2(
        is_mobile,
        func(`<div class='swiper-pagination'></div>`),
        func(`
          <div class='swiper-pagination'></div>
          <div class="swiper-button-prev custom">${MShopUtilConstantS.svg.swiper_prev}</div>
          <div class="swiper-button-next custom">${MShopUtilConstantS.svg.swiper_next}</div>
        `),
      )}
    </div>
  `;
};

export const makeProdImageSlideHtmlNess = ({ product_detail }) => {
  let thumbnails = go(product_detail, sel('thumbnails.value'), defaultTo([]));
  const thumb_index = thumbnails.findIndex((a) => a.is_thumb);
  const main_index = thumb_index === -1 ? 0 : thumb_index;
  const sp = sel('_.stores_product', product_detail);
  const spo_item_stocks = go(
    product_detail._.spo?._?.spo_items,
    map((spo_item) => spo_item?._?.spo_item_stock),
  );

  const pre_discount_price = sel('_.pre_discount_product.pre_discount_price', product_detail);
  const origin_price = creatorPriceOfProduct(product_detail);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
    : null;

  const badge = MShopAppProductItemS.setBadge(
    {
      spo_item_stocks,
      is_sptg: !!sp?._?.stores_products_token_gates.length,
      ...sp,
      pre_discount_rate,
    },
    false,
  );
  const component = `app-product-item`;
  const ness_item_class = 'app-product-item--NESS';

  thumbnails = [...thumbnails.slice(main_index), ...thumbnails.slice(0, main_index)];

  const img_alt = go(void 0, () => {
    return `${product_detail['name' + _en]}`;
  });

  return html`
    <div class="pd-preview swiper-container" data-len="${thumbnails.length}" data-main-index="${main_index}">
      <div class="pd-preview__list swiper-wrapper">
        ${strMap(
          (thumbnail_image) => `
            <div class="pd-preview__item swiper-slide">
              ${MpShopProductTmplS.thumbnail(product_detail, {
                thumbnail_image,
                width: 1480,
                img_alt,
              })}
            </div>
          `,
          thumbnails,
        )}
      </div>
      <div class="pd-preview__progress swiper-pagination"></div>
      ${UtilS.htmlIf2(
        badge,
        () => `
          <div class="pd-preview__badges ${ness_item_class}">
            ${NessOutboundTmplS.makeNessProductBadgeTmpl(badge, component)}
          </div>`,
      )}
    </div>
  `;
};

// 탭
export const makeTabsHtml = (tabs = []) => {
  return html`
    <div class="pd-tabs">
      ${strMap(
        (tab) =>
          tab.visible
            ? `<div data-key="${tab.key}" class="pd-tabs__item ${tab.active ? 'active' : ''}">
                ${tab.label}
              </div>`
            : '',
        tabs,
      )}
    </div>
  `;
};

const makeNessReviewImageItemTmpl = (prod_id) => (review_image) => {
  return html`
    <div
      class="pd-review-image-item"
      data-article-id="${review_image.id}"
      data-review-id="${review_image._.review.id}"
      data-prod-id="${prod_id}"
    >
      <img src="${review_image._.review.files[0].url}" alt="review image" />
    </div>
  `;
};

const makeProductReviewHtml = ({ review_count }) => {
  return html`
    <div id="pd-review" class="pd-review">
      <h2 class="pd-review__title">${T(messages.tabs.review)} ${review_count || ''}</h2>
      <div class="pd-review__list"></div>
    </div>
  `;
};

const makeNessProductReviewHtml = ({ review_images, prod_id, is_mobile }) => {
  const max_count = is_mobile ? 4 : 7;
  const sliced_list = review_images.slice(0, max_count);

  return html`
    <div id="pd-review" class="pd-review">
      ${review_images.length
        ? `
            <div class="pd-review__images">
              ${strMap(makeNessReviewImageItemTmpl(prod_id), sliced_list)}
              ${
                review_images.length > max_count
                  ? `<button type="button" class="pd-review__images-more" data-prod-id="${prod_id}">
                      ${ET('review::more')}
                    </button>`
                  : ''
              }
            </div>`
        : ''}
      <div class="pd-review__list"></div>
    </div>
  `;
};

// 탭 콘텐츠
export const makeMPSTabContentHtml = ({ plus_crew, product_detail, is_mobile, store, review_images }) => {
  const product_story = sel('_.product_story', product_detail);
  const base_product = sel('_.base_product', product_detail);

  if (UtilS.isNessApp()) {
    return html`
      <div tab_name="story">${makeProductStoryHtml(product_story)}</div>
      <div tab_name="bp">${makeBaseProductInfoHtml(base_product, is_mobile)}</div>
      <div tab_name="purchaseInfo">
        ${makePurchaseInfoHtml({ plus_crew, product_detail, store, is_mobile, is_pod: true })}
        ${makeFaqHtml({ plus_crew, is_pod: true })}
      </div>
      <div tab_name="review">
        ${makeNessProductReviewHtml({ review_images, prod_id: product_detail.id, is_mobile })}
      </div>
    `;
  }
  return html`
    <div class="pd-contents__group pd-info-group" tab_name="product_info">
      <div class="info_el">${makeProductStoryHtml(product_story)}</div>
      <div class="info_el">${makeBaseProductInfoHtml(base_product, is_mobile)}</div>
      <div class="pd-info-group__hide">
        <button type="button" class="pd-info-group__btn">${ET('product_detail::unfold_btn')}</button>
      </div>
    </div>
    <div tab_name="purchaseInfo" class="pd-contents__group">
      ${makePurchaseInfoHtml({ plus_crew, product_detail, store, is_mobile, is_pod: true })}
    </div>
    <div tab_name="review" class="pd-contents__group">
      ${makeProductReviewHtml({
        review_count: product_detail.review_meta.count,
      })}
    </div>
  `;
};

export const makePBGTabContentHtml = ({
  product_detail,
  is_empty_story,
  store,
  plus_crew,
  review_images,
  is_mobile,
}) => {
  const product_story = sel('_.product_story', product_detail);
  const {
    _: { stores_product, spo },
    stores_delivery_company,
  } = product_detail;
  const spo_producing_period = spo?._?.spo_producing_period;

  // 브랜드에서 배송을 보내는 경우 예외처리
  const brand_shipping_store_ids = [
    107618, // 크리노브 커머스 > 시골김
    98093, // 샌드박스 > 테라브레스
    103419, // 샌드박스 > 샌드박스커머스2
  ];
  const is_brand_shipping = brand_shipping_store_ids.includes(product_detail.store_id);

  if (UtilS.isNessApp()) {
    return html`
      <div tab_name="story">
        ${is_empty_story ? '' : html`<div class="pd-default-story__dotted"></div>`}
        ${makeProductStoryHtml(product_story)}
      </div>
      <div tab_name="pb_product_info">${makePbInfoHtml(stores_product)}</div>
      <div tab_name="pb_purchaseInfo">
        ${makePbPurchaseInfoHtml({
          plus_crew,
          store,
          stores_product,
          stores_delivery_company,
          spo_producing_period,
        })}
        ${makePbFaqHtml({ store, plus_crew })}
      </div>
      <div tab_name="review">
        ${makeNessProductReviewHtml({ review_images, prod_id: product_detail.id, is_mobile })}
      </div>
    `;
  }

  const i18n = {
    brand_delivery: ET('product_detail::brand_delivery'),
    brand_delivery_desc: ET('product_detail::brand_delivery_desc'),
    creator_delivery: ET('product_detail::creator_delivery'),
    creator_delivery_desc: ET('product_detail::creator_delivery_desc'),
  };

  return html`
    <div class="pd-contents__group pd-info-group" tab_name="product_info">
      <div class="info_el">
        <div class="pd-default-story pd-default-story--pbg">
          <div class="pd-default-story__header">
            ${MShopCoreTmplS.infoCircleIcon()}
            ${is_brand_shipping ? i18n.brand_delivery : i18n.creator_delivery}
          </div>
          <div class="pd-default-story__body pd-default-story__body--pbg">
            ${is_brand_shipping ? i18n.brand_delivery_desc : i18n.creator_delivery_desc}
          </div>
        </div>
        ${is_empty_story ? '' : html`<div class="pd-default-story__dotted"></div>`}
        ${makeProductStoryHtml(product_story)}
      </div>
      <div class="info_el" tab_name="pb_product_info">
        ${makePbInfoHtml({
          product_basic_info: stores_product['product_basic_info' + G._en],
          certification_info: stores_product['certification_info' + G._en],
          is_kc_cert_checked: stores_product.is_kc_cert_checked,
        })}
      </div>
      <div class="pd-info-group__hide">
        <button type="button" class="pd-info-group__btn">${ET('product_detail::unfold_btn')}</button>
      </div>
    </div>

    <div tab_name="pb_purchaseInfo" class="pd-contents__group">
      ${makePbPurchaseInfoHtml({
        plus_crew,
        store,
        stores_product,
        stores_delivery_company,
        spo_producing_period,
      })}
    </div>

    <div tab_name="review" class="pd-contents__group">
      ${makeProductReviewHtml({
        review_count: product_detail.review_meta.count,
      })}
    </div>
  `;
};

export const makeTPLTabContentHtml = ({ product_detail, store, plus_crew, review_images, is_mobile }) => {
  const product_story = sel('_.product_story', product_detail);
  const {
    _: { stores_product },
  } = product_detail;

  if (UtilS.isNessApp()) {
    return html`
      <div tab_name="story">${makeProductStoryHtml(product_story)}</div>
      <div tab_name="pb_product_info">
        ${makePbInfoHtml({
          product_basic_info: stores_product['product_basic_info' + G._en],
          certification_info: stores_product['certification_info' + G._en],
          is_kc_cert_checked: stores_product.is_kc_cert_checked,
        })}
      </div>
      <div tab_name="pb_purchaseInfo">
        ${makeTplPurchaseInfoHtml({ is_mobile, plus_crew, store, product_detail })}
        ${makeTplFaqHtml({ store, plus_crew })}
      </div>
      <div tab_name="review">
        ${makeNessProductReviewHtml({ review_images, prod_id: product_detail.id, is_mobile })}
      </div>
    `;
  }
  return html`
    <div class="pd-contents__group pd-info-group" tab_name="product_info">
      <div class="info_el">${makeProductStoryHtml(product_story)}</div>
      <div class="info_el">
        ${makePbInfoHtml({
          product_basic_info: stores_product['product_basic_info' + G._en],
          certification_info: stores_product['certification_info' + G._en],
          is_kc_cert_checked: stores_product.is_kc_cert_checked,
        })}
      </div>
      <div class="pd-info-group__hide">
        <button type="button" class="pd-info-group__btn">${ET('product_detail::unfold_btn')}</button>
      </div>
    </div>
    <div tab_name="pb_purchaseInfo" class="pd-contents__group">
      ${makeTplPurchaseInfoHtml({ is_mobile, plus_crew, store, product_detail })}
    </div>
    <div tab_name="review" class="pd-contents__group">
      ${makeProductReviewHtml({
        review_count: product_detail.review_meta.count,
      })}
    </div>
  `;
};

export const makeDTGTabContentHtml = ({
  product_detail,
  is_empty_story,
  store,
  plus_crew,
  review_images,
  is_mobile,
}) => {
  const product_story = sel('_.product_story', product_detail);
  const {
    _: { stores_product },
    stores_delivery_company,
  } = product_detail;

  if (UtilS.isNessApp()) {
    return html`
      <div tab_name="story">
        <div class="pd-default-story">
          <div class="pd-default-story__header">${T('product_detail::디지털 상품 구매 안내')}</div>
          <div class="pd-default-story__body">
            <div class="pd-default-story__li">
              ${T(
                'product_detail::결제 완료 후, 구매내역에서 7일 간 다운로드 받으실 수 있습니다. (기한 이후 다운로드를 원하실 경우 재구매를 해주셔야 합니다.)',
              )}
            </div>
            <div class="pd-default-story__li">
              ${T(
                'product_detail::디지털 상품 특성 상 전자상거래법 제 17조에 의거하여 구매 이후에는 어떠한 사유로도 교환 및 환불이 불가합니다.',
              )}
            </div>
          </div>
        </div>
        ${is_empty_story ? '' : html`<div class="pd-default-story__dotted"></div>`}
        ${makeProductStoryHtml(product_story)}
      </div>
      <div tab_name="pb_purchaseInfo">
        ${makeDtgPurchaseInfoHtml({ plus_crew, store, stores_product, stores_delivery_company })}
        ${makeDtgFaqHtml({ store, plus_crew })}
      </div>
      <div tab_name="review">
        ${makeNessProductReviewHtml({ review_images, prod_id: product_detail.id, is_mobile })}
      </div>
    `;
  }

  return html`
    <div class="pd-contents__group pd-info-group" tab_name="product_info">
      <div class="info_el">
        <div class="pd-default-story">
          <div class="pd-default-story__header">
            ${MShopCoreTmplS.infoCircleIcon()} ${T('product_detail::디지털 상품 구매 안내')}
          </div>
          <p class="pd-default-story__body">
            결제 완료 후, 구매내역에서 7일 간 다운로드 받으실 수 있습니다. (기한 이후 다운로드를 원하실 경우
            재구매를 해주셔야 합니다.)<br />
            디지털 상품 특성 상 전자상거래법 제 17조에 의거하여 구매 이후에는 어떠한 사유로도 교환 및 환불이
            불가합니다.
          </p>
        </div>
      </div>
      <div class="info_el">${makeProductStoryHtml(product_story)}</div>
      <div class="pd-info-group__hide">
        <button type="button" class="pd-info-group__btn">${ET('product_detail::unfold_btn')}</button>
      </div>
    </div>
    <div tab_name="pb_purchaseInfo" class="pd-contents__group">
      ${makeDtgPurchaseInfoHtml({ plus_crew, store, stores_product, stores_delivery_company })}
    </div>
    <div tab_name="review" class="pd-contents__group">
      ${makeProductReviewHtml({
        review_count: product_detail.review_meta.count,
      })}
    </div>
  `;
};

export const makeThumbSwiperHtml = ({ product_detail }) => {
  if (UtilS.isNessApp()) {
    return makeThumbSwiperHtmlNess({ product_detail });
  }

  const thumbnails = go(product_detail, sel('thumbnails.value'), (thumbs = []) => {
    const thumb_index = thumbs.findIndex((a) => a.is_thumb);
    const main_index = thumb_index === -1 ? 0 : thumb_index;
    return [...thumbs.slice(main_index), ...thumbs.slice(0, main_index)];
  });

  const sp = sel('_.stores_product', product_detail);
  const spo_item_stocks = go(
    product_detail._.spo?._?.spo_items,
    map((spo_item) => spo_item?._?.spo_item_stock),
  );

  // 마케팅 가격
  const pre_discount_price = sel('_.pre_discount_product.pre_discount_price', product_detail);
  const origin_price = creatorPriceOfProduct(product_detail);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
    : null;

  const badges = MShopAppProductItemS.setBadge(
    {
      spo_item_stocks,
      is_sptg: !!sp?._?.stores_products_token_gates.length,
      ...sp,
      pre_discount_rate,
      need_all: true,
    },
    false,
  );

  const component = `app-product-item`;
  const ness_item_class = UtilS.isNessApp() ? 'app-product-item--NESS' : '';
  const badge_list_data = makeBadgeListData(product_detail);

  return html`
    <div class="pd-preview">
      <div class="pd-preview__thumbs">
        ${go(
          thumbnails,
          zipWithIndexL,
          strMap(
            ([index, thumbnail_image]) => `
            <button class="pd-preview__thumbs-btn ${index === 0 ? 'active' : ''}" data-index="${index}">
              ${MpShopProductTmplS.thumbnail(product_detail, { thumbnail_image, width: 156 })}
            </button>`,
          ),
        )}
      </div>

      <div class="pd-preview__swiper swiper-container">
        ${makeProductDetailBadgesHtml({
          badges,
          component,
          is_mobile: false,
          ness_item_class,
          badge_list_data,
        })}

        <div class="swiper-wrapper">
          ${go(
            thumbnails,
            zipWithIndexL,
            strMap(
              ([index, thumbnail_image]) => `
            <div class="swiper-slide ${index === 0 ? 'active' : ''}">
              ${MpShopProductTmplS.thumbnail(product_detail, { thumbnail_image, width: 1600 })}
            </div>
          `,
            ),
          )}
        </div>
      </div>
    </div>
  `;
};

export const makeThumbSwiperHtmlNess = ({ product_detail }) => {
  const thumbnails = go(product_detail, sel('thumbnails.value'), (thumbs = []) => {
    const thumb_index = thumbs.findIndex((a) => a.is_thumb);
    const main_index = thumb_index === -1 ? 0 : thumb_index;
    return [...thumbs.slice(main_index), ...thumbs.slice(0, main_index)];
  });

  const sp = sel('_.stores_product', product_detail);
  const spo_item_stocks = go(
    product_detail._.spo?._?.spo_items,
    map((spo_item) => spo_item?._?.spo_item_stock),
  );

  // 마케팅 가격
  const pre_discount_price = sel('_.pre_discount_product.pre_discount_price', product_detail);
  const origin_price = creatorPriceOfProduct(product_detail);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
    : null;

  const badge = MShopAppProductItemS.setBadge(
    {
      spo_item_stocks,
      is_sptg: !!sp?._?.stores_products_token_gates.length,
      ...sp,
      pre_discount_rate,
    },
    false,
  );

  const component = `app-product-item`;
  const ness_item_class = 'app-product-item--NESS';

  return html`
    <div class="pd-preview">
      <div class="pd-preview__thumbs">
        ${go(
          thumbnails,
          zipWithIndexL,
          strMap(
            ([index, thumbnail_image]) => `
            <button class="pd-preview__thumbs-btn ${index === 0 ? 'active' : ''}" data-index="${index}">
              ${MpShopProductTmplS.thumbnail(product_detail, { thumbnail_image, width: 156 })}
            </button>`,
          ),
        )}
      </div>

      <div class="pd-preview__swiper swiper-container">
        ${badge
          ? `
            <div class="pd-preview__badges ${ness_item_class}">
            ${NessOutboundTmplS.makeNessProductBadgeTmpl(badge, component)}
            </div>`
          : ''}

        <div class="swiper-wrapper">
          ${go(
            thumbnails,
            zipWithIndexL,
            strMap(
              ([index, thumbnail_image]) => `
            <div class="swiper-slide ${index === 0 ? 'active' : ''}">
              ${MpShopProductTmplS.thumbnail(product_detail, { thumbnail_image, width: 1600 })}
            </div>
          `,
            ),
          )}
        </div>
      </div>
    </div>
  `;
};
