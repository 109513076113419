import { $, type Html, html, View } from 'rune-ts';
import klass from './ProductOptionQuantitySelector.module.scss';
import { staticTypoPcMo, typo, typoPcMo } from '../../../../shared/typography/typo';
import { ButtonClose } from '../../atoms/ButtonClose/ButtonClose';
import { each, find, map, pipe, reject, toArray } from '@fxts/core';
import { ButtonCount, ChangeCountEvent } from '../../../../shared/components/atoms/ButtonCount/ButtonCount';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { AddProductOptionSetReturnType, CONSTANT } from './type';
import { classes } from '../../../../shared/util';

export class DeleteOptionSetEvent extends CustomEvent<never> {}

interface OptionValue {
  value_type?: string;
  value_id?: number;
  value_name: string;
}

/**
 * 상품 개별 옵션이 아닌 개별 옵션을 선택한 최종 결과물, 즉 상품 단위와 동일한 옵션이다.
 * 자동차(빨간색,2000cc,6기통,후방카메라) 여기서 ProductOption = (빨간색,2000cc,6기통,후방카메라)
 */
interface ProductOption {
  id: number;
  option_values: OptionValue[];
  price: number;
  quantity: number;
  max_quantity?: number;
  is_disabled?: boolean;
  extra_info?: unknown;
}

export interface ProductMultiOptionSelectorData {
  product_options: ProductOption[]; // FIXME
}

export interface ProductMultiOptionSelectorOption {
  klass?: string;
  is_mobile: boolean;
  max_total_quantity?: number;
}

const makeProductOptionName = (product_option: ProductOption) => {
  return product_option.option_values.map(({ value_name }) => value_name).join(', ');
};

export class OptionSetView extends View<ProductOption> {
  private buttonCloseView: ButtonClose = new ButtonClose({
    hide: false,
    type: 'button',
    tag: 'span',
    transparent: true,
  });

  private buttonCountView: ButtonCount = new ButtonCount({
    count: this.data.quantity ?? 1,
    min: 1,
    max: this.data.max_quantity,
  });

  protected override template(data: ProductOption): Html {
    return html`
      <div class="${klass.option}" data-id="${data.id}">
        <div class="${klass.option_text}">
          <span class="${typo('14_medium')}"> ${makeProductOptionName(data)} </span>
        </div>
        <div class="${klass.button_close_wrap}">${this.buttonCloseView}</div>
        <div
          class="${classes(klass.button_count_wrap, {
            [klass.none]: data.is_disabled,
          })} ${klass.button_count_wrap}"
        >
          ${this.buttonCountView}
        </div>
      </div>
    `;
  }

  protected override onRender() {
    if (this.isDisabled()) {
      this.setButtonDisabled();
    }
  }

  isDisabled() {
    return this.data.is_disabled;
  }

  setPlusButtonDisabled(is_disabled: boolean) {
    this.buttonCountView.setButtonDisabled(is_disabled, 'plus');
  }

  setButtonDisabled() {
    this.buttonCountView.setDisabled();
  }

  getExtraInfo() {
    return this.data.extra_info;
  }
}

/**
 * 상품 상세에서 멀티 옵션을 제어하기 위한 컴포넌트
 * 여러가지 최종옵션(interface ProductOption)의 수량과 총 가격을 제어한다.
 *
 * 예시)
 * 상품: 자동차
 * ProductMultiOptionSelector
 * product_option: 빨강, 2000cc, 5인승
 * product_option: 파랑, 2500cc, 6인승
 */
export class ProductMultiOptionSelector extends View<ProductMultiOptionSelectorData> {
  private readonly state: {
    klass?: string;
    is_mobile: boolean;
    max_total_quantity: number;
    product_options: ProductOption[];
  };

  constructor(
    data: ProductMultiOptionSelectorData,
    option: ProductMultiOptionSelectorOption = { is_mobile: false },
  ) {
    super(data);

    this.state = {
      klass: option.klass,
      is_mobile: option.is_mobile,
      max_total_quantity: option.max_total_quantity ?? 9999,
      product_options: data.product_options,
    };
  }

  override template() {
    const i18n = {
      total_price: ET('product_detail::total_price'),
    };

    if (!this.getProductOptions().length) {
      return html`<div class="${this.state.klass} ${klass.product_option_quantity_selector}"></div>`;
    }

    return html`
      <div class="${this.state.klass} ${klass.product_option_quantity_selector}">
        <div class="${klass.option_container}">
          ${this.getProductOptions().map((product_option) => {
            return html`${new OptionSetView({
              ...product_option,
              max_quantity: this.state.max_total_quantity,
            })}`;
          })}
        </div>
        <div class="${klass.total_price_container}">
          <span
            class="${klass.total_price_text} ${typoPcMo({
              is_mobile: this.state.is_mobile,
              pc: '14_bold',
              mo: '12_bold',
            })}"
            >${i18n.total_price}</span
          >
          <span
            class="${klass.total_price} ${staticTypoPcMo({
              is_mobile: this.state.is_mobile,
              pc: 'unica_24_bold',
              mo: 'unica_20_bold',
            })} ${typoPcMo({
              is_mobile: this.state.is_mobile,
              pc: '24_bold',
              mo: '20_bold',
            })}"
          >
            ${ET.lang === 'kr'
              ? html`<span
                    class="price-number ${staticTypoPcMo({
                      is_mobile: this.state.is_mobile,
                      pc: 'unica_24_bold',
                      mo: 'unica_20_bold',
                    })}"
                    >${UtilS.commify(this.getTotalPrice())}</span
                  ><span
                    class="${typoPcMo({
                      is_mobile: this.state.is_mobile,
                      pc: '24_bold',
                      mo: '20_bold',
                    })}"
                    >${UtilS.getCurrency()}</span
                  >`
              : html`<span
                    class="${typoPcMo({
                      is_mobile: this.state.is_mobile,
                      pc: '24_bold',
                      mo: '20_bold',
                    })}"
                    >${UtilS.getCurrency()}</span
                  ><span
                    class="price-number ${staticTypoPcMo({
                      is_mobile: this.state.is_mobile,
                      pc: 'unica_24_bold',
                      mo: 'unica_20_bold',
                    })}"
                    >${UtilS.commify(this.getTotalPrice())}</span
                  >`}
          </span>
        </div>
      </div>
    `;
  }

  protected override onRender() {
    this.delegate('click', ButtonClose, (e: Event) => {
      const id = $(e.currentTarget).closest(`.${klass.option}`)?.getAttribute('data-id');

      this.setStateOptionSet(
        pipe(
          this.getProductOptions(),
          reject((option: ProductOption) => option.id === Number(id)),
          toArray,
        ),
      )
        .redraw()
        .dispatchEvent(DeleteOptionSetEvent, { bubbles: true });
    }).delegate(ChangeCountEvent, ButtonCount, (e, buttonCountView) => {
      const current_id = $(e.currentTarget).closest(`.${klass.option}`)?.getAttribute('data-id');
      const current_count = buttonCountView.getStateCount();
      const target_product_option = this.getProductOptions().find(({ id }) => id === Number(current_id));

      if (target_product_option) {
        const updated_product_option: ProductOption = {
          ...target_product_option,
          quantity: current_count,
        };

        this.setStateOptionSet(
          this.getProductOptions().map((option) =>
            option.id === updated_product_option.id ? updated_product_option : option,
          ),
        );

        this.redraw();
      } else {
        throw new Error('카운트를 조정한 상품 옵션 세트를 찾을 수 없습니다.');
      }
    });
  }

  override redraw(): this {
    const option_els = $(this.element())
      .findAll(`.${klass.option}`)
      .map((el) => el.element());
    const option_states = this.getProductOptions();

    /* Dom Check, State에 존재하지 않는 요소 제거 */
    option_els
      .filter(($el) => !option_states.map(({ id }) => id).includes(Number($el.dataset.id)))
      .forEach((el) => {
        el.remove();
      });

    /* State Check, Dom에 존재하지 않는 요소 추가 */
    pipe(
      option_states,
      reject((option: ProductOption) => option_els.map((el) => Number(el.dataset.id)).includes(option.id)),
      each((option: ProductOption) => {
        const option_container_el = this.element().querySelector(`.${klass.option_container}`);
        if (option_container_el) {
          $(option_container_el).append(new OptionSetView(option).render());
        }
      }),
    );

    /* 엘리먼트 추가 후 DOM트리에 추가된 엘리먼트가 반영되지 않는 경우 잘 동작하지 않는 문제를 방지하기 위함 */
    requestAnimationFrame(() => {
      this.controlButtonCounterDisabled();
      $(this.element()).find('.price-number')?.setTextContent(UtilS.commify(this.getTotalPrice()));
    });

    return this;
  }

  static getConstant(): CONSTANT {
    return CONSTANT;
  }

  private setStateOptionSet(product_options: ProductOption[]): this {
    this.state.product_options = product_options;
    return this;
  }

  private isMaxQuantityReached() {
    return this.state.max_total_quantity <= this.getTotalQuantity();
  }

  private controlButtonCounterDisabled() {
    if (this.isMaxQuantityReached()) {
      this.subViews(OptionSetView).forEach((view) => {
        view.setPlusButtonDisabled(true);
      });
    } else {
      this.subViews(OptionSetView).forEach((view) => {
        if (view.isDisabled()) {
          return;
        }

        view.setPlusButtonDisabled(false);
      });
    }
  }

  public getTotalPrice() {
    if (!this.getProductOptions().length) {
      return 0;
    }

    return this.getProductOptions()
      .map(({ price, quantity }) => price * quantity)
      .reduce((a, b) => a + b);
  }

  public getTotalQuantity() {
    if (!this.getProductOptions().length) {
      return 0;
    }

    return this.getProductOptions()
      .map(({ quantity }) => quantity)
      .reduce((a, b) => a + b);
  }

  public getProductOptions() {
    return this.state.product_options;
  }

  public addProductOptionSet(product_option: ProductOption): AddProductOptionSetReturnType {
    if (this.isMaxQuantityReached()) {
      return { is_success: false, type: ProductMultiOptionSelector.getConstant().QUANTITY };
    }

    // 중복되는 요소가 있을 경우 option을 추가하지 않고, false 반환
    if (
      pipe(
        this.getProductOptions(),
        map(makeProductOptionName),
        find((option) => option === makeProductOptionName(product_option)),
      )
    ) {
      return { is_success: false, type: ProductMultiOptionSelector.getConstant().DUPLICATION };
    }

    this.setStateOptionSet([...this.state.product_options, product_option]);
    this.redraw();
    return { is_success: true };
  }

  public getAllProductOptionExtraInfos() {
    return this.subViews(OptionSetView).map((view) => view.getExtraInfo());
  }

  public setTotalMaxQuantity(max_total_quantity: number): this {
    this.state.max_total_quantity = max_total_quantity;

    /* 받은 max_total_quantity가 option들의 총 수량보다 작으면 옵션을 초기화 시킨다.  */
    if (this.state.max_total_quantity < this.getTotalQuantity()) {
      this.setStateOptionSet([]);
    }
    this.redraw();
    return this;
  }
}
