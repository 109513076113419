import { CustomEventWithoutDetail, type Html, html, View } from 'rune-ts';
import { Tooltip } from '../../../../renderApp/components/atoms/Tooltip/Tooltip';
import { shareFont } from '../../../../shared/typography';
import { htmlIf } from '../../../../shared/util';
import { makeTipsIcon } from '../../atoms/Icon';
import klass from './StudioTitle.module.scss';

type StudioHeaderSuffix =
  | {
      type: 'tooltip';
      tooltip_content: string | Html | View;
    }
  | {
      type: 'guide';

      // invalid property
      tooltip_content?: undefined;
    };

export interface StudioHeaderData {
  title: string;
  description?: string;
  right_content?: string | Html | View | View[];
  with_padding?: boolean;
  with_margin?: boolean;
  block?: boolean;
  suffix?: StudioHeaderSuffix;
}

class StudioHeaderSuffixTriggerEvent extends CustomEventWithoutDetail {}

export class StudioHeader extends View<StudioHeaderData> {
  static StudioHeaderSuffixTriggerEvent = StudioHeaderSuffixTriggerEvent;

  override template() {
    const {
      title,
      description,
      right_content,
      with_padding = false,
      with_margin = true,
      block,
      suffix,
    } = this.data;

    return html`
      <div
        class="${klass.header} ${htmlIf(klass.block, !!block)} ${htmlIf(
          klass.with_padding,
          with_padding,
        )} ${htmlIf(klass.with_margin, with_margin)}"
      >
        <span class="${klass.title_container}">
          ${new StudioHeading1(title)}
          ${htmlIf(html`<span class="${klass.suffix}">${this.getSuffixHtml()}</span>`, !!suffix)}
        </span>
        ${htmlIf(
          html`<span class="${klass.description} ${shareFont('14_medium')}">${description}</span>`,
          !!description,
        )}
        ${htmlIf(html`<div class="${klass.right_content}">${right_content}</div>`, !!right_content)}
      </div>
    `;
  }

  private getSuffixHtml(): string | Html | View {
    const { suffix } = this.data;
    if (!suffix) {
      return html``;
    } else if (suffix.type === 'tooltip') {
      return new Tooltip({
        id: `tooltip_${this}`,
        content: suffix.tooltip_content,
        triggerClass: klass.tooltip_trigger,
        contentClass: klass.tooltip_content,
        size: 'small',
      });
    } else if (suffix.type === 'guide') {
      return html`<span class="${klass.guide_icon_wrapper}">${makeTipsIcon()}</span>`;
    }
    return html``;
  }

  override onRender() {
    if (this.data.suffix) {
      this.delegate('click', `.${klass.suffix}`, () => {
        this.dispatchEvent(StudioHeader.StudioHeaderSuffixTriggerEvent, { bubbles: true });
      });
    }
  }
}

export class StudioHeading1 extends View<Readonly<{ title: string }>> {
  constructor(title: string) {
    super({ title });
  }

  override template() {
    return html`<h1 class="${shareFont('28_bold')} ${klass.studio_h1}">${this.data.title}</h1>`;
  }
}

export class StudioHeading2 extends View<Readonly<{ title: string }>> {
  constructor(title: string) {
    super({ title });
  }

  override template() {
    return html`<h2 class="${shareFont('20_bold')}">${this.data.title}</h2>`;
  }
}

export class StudioHeading3 extends View<Readonly<{ title: string }>> {
  constructor(title: string) {
    super({ title });
  }

  override template() {
    return html`<h3 class="${klass.studio_h3} ${shareFont('14_bold')}">${this.data.title}</h3>`;
  }
}
