import axios from 'axios';
import {
  $addClass,
  $attr,
  $closest,
  $delegate,
  $els,
  $find,
  $findAll,
  $hasClass,
  $prepend,
  $remove,
  $removeClass,
  $trigger,
  $val,
} from 'fxdom/es';
import { each, go, map, sel, strMap } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../../../Util/F/Function/module/MShopUtilF.js';
import { ARTICLE_TYPES, ARTICLE_PHOTO_TYPES } from '../../../S/Constant/community.js';
import { MShopAppStoreCommunityEditorMuiS } from '../../S/Mui/module/MShopAppStoreCommunityEditorMuiS.js';
import { MShopAppStoreCommunityEditorTmplS } from '../../S/Tmpl/module/MShopAppStoreCommunityEditorTmplS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */

const setType = (type, photo_length) => {
  // 커뮤니티 타입인데 이미지가 없을 경우 community_text로 타입 변경
  if (type == ARTICLE_TYPES.community_photo && !photo_length) {
    type = ARTICLE_TYPES.community_text;
  }
  if (type == ARTICLE_TYPES.community_text && photo_length) {
    type = ARTICLE_TYPES.community_photo;
  }
  return type;
};

export const tab = {
  ...MShopAppStoreCommunityEditorMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    return go(
      tab_el,
      $delegate('click', '.article-editor__cancel', (e) => {
        MuiF.closeFrame();
      }),
      $delegate('click', '.article-editor__submit', async (e) => {
        $.don_loader_start();

        try {
          const article_editor_el = $find('.article-editor', e.delegateTarget);
          const store_id = $attr('data-store_id', article_editor_el);
          const body = $val($find('.article-editor__textarea', e.delegateTarget));
          const article_id = $attr('data-article_id', article_editor_el);
          const photo_length = sel('length', $findAll('.article-editor__upload_item', e.delegateTarget));
          const type = setType($attr('data-article_type', article_editor_el), photo_length);

          if (!body || !body.length) {
            $.don_loader_end();
            return MShopUtilF.popToastMsg(T('community::내용을 입력해 주세요'), 'error');
          }

          const url = `/@api/stores/${store_id}/community`;

          if (ARTICLE_PHOTO_TYPES.includes(type) && !photo_length) {
            $.don_loader_end();
            return MShopUtilF.popToastMsg(T('community::이미지를 등록해 주세요.'), 'error');
          }

          await go(
            axios.post(url, {
              article_id,
              type,
              body,
              photos: go(
                e.delegateTarget,
                $findAll('.article-editor__upload_item'),
                map((photo_el) => {
                  return {
                    id: $attr('data-file_id', photo_el),
                    url: $attr('data-url', photo_el),
                    width: $attr('data-width', photo_el),
                    height: $attr('data-height', photo_el),
                  };
                }),
              ),
            }),
            ({ data }) => {
              $.don_loader_end();
              if (!data.result) {
                return MShopUtilF.popToastMsg(data.msg || T('req_msg::failed please retry'), 'error');
              }

              MuiF.closeFrame({ reload: true });
              // location.reload();
            },
          );
        } catch (_e) {
          $.don_loader_end();
        }
      }),
      $delegate('click', '.article-editor__btn_add', (e) => {
        $trigger('click', $find('.article-editor__file_input', e.delegateTarget));
      }),
      $delegate('change', '.article-editor__file_input', async (e) => {
        $.don_loader_start();
        const upload_items = $findAll('.article-editor__upload_item', e.delegateTarget);
        if (upload_items.length + e.currentTarget.files.length > 6) {
          const txt3 = ET('mps2::cheerup::txt3');
          MShopUtilF.popToastMsg(txt3, 'error');
          $.don_loader_end();
          return;
        }

        try {
          const photos_el = $find('.article-editor__upload_list', e.delegateTarget);
          await go(
            $.upload(e.currentTarget, {
              data: { attached_type: 'articles' },
            }),
            strMap(MShopAppStoreCommunityEditorTmplS.uploadItem),
            $els,
            each((el) => {
              $prepend(el, photos_el);
            }),
            () => {
              $.don_loader_end();
            },
          );
        } catch (_e) {
          $.don_loader_end();
          if (sel('responseText.length', _e) && sel('responseText', _e).indexOf('File too large') != -1) {
            MShopUtilF.popToastMsg(T('File size is too large'), 'error');
          } else {
            MShopUtilF.popToastMsg(T('community::파일 업로드 실패했습니다.'), 'error');
          }
        }
      }),
      $delegate('click', '.article-editor__upload_item_del', (e) => {
        go(e.currentTarget, $closest('.article-editor__upload_item'), $remove);
      }),
      $delegate('keyup', '.article-editor__textarea', (e) => {
        if ($val(e.currentTarget).length && !$hasClass('article-editor__textarea--on', e.currentTarget)) {
          $addClass('article-editor__textarea--on', e.currentTarget);
        } else if (
          !$val(e.currentTarget).length &&
          $hasClass('article-editor__textarea--on', e.currentTarget)
        ) {
          $removeClass('article-editor__textarea--on', e.currentTarget);
        }
      }),
    );
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
