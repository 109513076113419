import { html, View } from 'rune-ts';
import klass from './FloatingBox.module.scss';
import { typo } from '../../../../shared/typography/typo';

import { ButtonShowChangeEvent, FloatingButtonBase } from './FloatingButtons/FloatingButtonBase';
import { classes } from '../../../../shared/util';
import { FloatingButton_cheerup, FloatingButtonCheerupData } from './FloatingButtons/FloatingButton_cheerup';
import { FloatingButton_filter, FloatingButtonFilterData } from './FloatingButtons/FloatingButton_filter';
import {
  FloatingButton_recent_product,
  FloatingButtonRecentProductData,
} from './FloatingButtons/FloatingButton_recent_product';
import { FloatingButton_share, FloatingButtonShareData } from './FloatingButtons/FloatingButton_share';
import { FloatingButton_up } from './FloatingButtons/FloatingButton_up';

export type FloatingBoxData = {
  cheerup?: FloatingButtonCheerupData;
  filter?: FloatingButtonFilterData;
  recent_product?: FloatingButtonRecentProductData;
  share?: FloatingButtonShareData;
  // shop: {};
  up?: boolean;
  is_mobile: boolean;
};

interface FloatingBoxOption {
  klass?: string;
  button_orders?: (keyof FloatingBoxData)[];
}

export class FloatingBox extends View<FloatingBoxData> {
  state: FloatingBoxOption & {
    button_orders: (keyof FloatingBoxData)[];
    display: boolean;
  };

  toggleDisplayRaf: number | null; // rAF id

  buttons: FloatingButtonBase<any>[];

  protected override onMount() {
    this.prepareBoxToggleAnimation();

    this.addEventListener(ButtonShowChangeEvent, () => {
      this.reserveToggleDisplay();
    });
  }

  predicateDisplayStatus() {
    return this.buttons.some((button) => button.state.show);
  }

  reserveToggleDisplay() {
    if (this.toggleDisplayRaf) return;

    this.toggleDisplayRaf = requestAnimationFrame(() => {
      this.toggleDisplay();
      this.toggleDisplayRaf = null;
    });
  }

  prepareBoxToggleAnimation() {
    const this_element = this.element();
    this_element.addEventListener('transitionend', () => {
      // 가릴 때만 hidden 달아주기
      if (!this.state.display) {
        this_element.classList.add(klass.hidden);
      }
    });
  }

  // prepareBoxToggleAnimation 확인
  // box 자체의 display 도 조정하고, button 들의 display 도 조절
  toggleDisplay() {
    const box_display = this.predicateDisplayStatus();
    const this_element = this.element();

    // box animation
    if (this.state.display !== box_display) {
      this.state.display = box_display;

      if (this.state.display) {
        this_element.classList.remove(klass.hidden);
        this_element.offsetHeight; // force layout
        this_element.classList.remove(klass.transparent);
      } else {
        this_element.classList.add(klass.transparent);
      }
    } else {
      // button animation
      this.buttons.forEach((button) => {
        button.toggleDisplay(button.state.show);
      });
    }
  }

  constructor(data: FloatingBoxData, option: FloatingBoxOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
      button_orders: option.button_orders || ['cheerup', 'filter', 'recent_product', 'share', 'up'],
      display: false, // temporary value, 뒤에 buttons 만들고 나서 다시 할당함
    };

    this.toggleDisplayRaf = null;

    this.buttons = this.state.button_orders
      .map((key) => {
        if (key == 'cheerup') {
          const key_data = data[key];
          if (!key_data) return undefined;
          return new FloatingButton_cheerup(key_data);
        }

        if (key == 'filter' && data[key]) {
          const key_data = data[key];
          if (!key_data) return undefined;
          return new FloatingButton_filter(key_data);
        }

        if (key == 'recent_product') {
          const key_data = data[key];
          if (!key_data) return undefined;
          return new FloatingButton_recent_product(key_data);
        }

        if (key == 'share') {
          const key_data = data[key];
          if (!key_data) return undefined;
          return new FloatingButton_share(key_data, { is_mobile: data.is_mobile });
        }

        if (key == 'up' && data[key]) {
          return new FloatingButton_up();
        }

        return undefined;
      })
      .filter((a): a is Exclude<typeof a, undefined> => !!a);

    this.state.display = this.predicateDisplayStatus();

    // box display 가 false 이면, 내부 버튼들은 다 보여주기
    if (!this.state.display) {
      this.buttons.forEach((button) => (button.state.show_itself = true));
    }
  }

  override template() {
    return html`<div
      class="${this.state.klass} ${klass.floating_box} ${typo('14_medium')} ${classes({
        [klass.hidden]: !this.state.display,
        [klass.transparent]: !this.state.display,
      })}"
    >
      ${this.buttons}
    </div> `;
  }
}
