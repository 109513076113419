import { html, View } from 'rune-ts';
import { makeMainUrl } from '../../../../shared/util/url';
import klass from './BottomTabBar.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { HomeFillIcon, PopupStoreIcon, ShopIcon, SmileFillIcon, UserFillIcon } from '../../atoms/Icon';
import { htmlIf } from '../../../../shared/util';

export interface BottomTabBarData {
  selected_tab_idx: number;
  is_logged_in: boolean;
}

interface BottomTabBarOption {
  klass?: string;
}

export class BottomTabBar extends View<BottomTabBarData> {
  state: BottomTabBarOption & {
    // FIXME
  };

  static getHeight() {
    return 62;
  }

  constructor(data: BottomTabBarData, option: BottomTabBarOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
    };
  }

  override template() {
    const { selected_tab_idx, is_logged_in } = this.data;

    const getClassWhenSelected = (idx: number) => htmlIf(klass.selected, selected_tab_idx == idx);

    return html`<div class="${this.state.klass} ${klass.bottom_tab_bar} ${typo('10_medium')}">
      <div class="${klass.tab_container}">
        <a href="/${ET.lang}/" class="${klass.tab} ${getClassWhenSelected(0)}">
          <span class="${klass.icon}">${HomeFillIcon}</span>
          <span>Home</span>
        </a>
        <a href="${makeMainUrl(`/${ET.lang}/@/sellers`)}" class="${klass.tab} ${getClassWhenSelected(1)}">
          <span class="${klass.icon}">${SmileFillIcon}</span>
          <span>Creator</span>
        </a>
        <a href="${makeMainUrl(`/${ET.lang}/@/popup-store`)}" class="${klass.tab} ${getClassWhenSelected(2)}">
          <span class="${klass.icon}">${PopupStoreIcon}</span>
          <span>Pop-up</span>
        </a>
        <a href="${makeMainUrl(`/${ET.lang}/@/products`)}" class="${klass.tab} ${getClassWhenSelected(3)}">
          <span class="${klass.icon}">${ShopIcon}</span>
          <span>Shop</span>
        </a>
        <a href="/${ET.lang}/@/mypage" class="${klass.tab} ${getClassWhenSelected(4)}">
          <span class="${klass.icon}">${UserFillIcon}</span>
          <span>${is_logged_in ? 'My Page' : 'Login'}</span>
        </a>
      </div>
    </div> `;
  }
}
