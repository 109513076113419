export const SUB_TYPE = {
  popular: {
    title: T('mshop::#난리났네_난리났어_인기상품'),
  },
  limit: {
    title: ET('limit_product::limit_product_01'),
  },
  recent: {
    title: T('mshop::#신상은_타이밍!'),
  },
  all: {
    title: T('mshop::상품목록'),
  },
};

export const SORT_LIST = {
  popular: ET('product::sort::인기순'),
  recent: ET('product::sort::최신순'),
  low_price: ET('product::sort::낮은 가격 순'),
  high_price: ET('product::sort::높은 가격 순'),
  review: ET('product::sort::리뷰 많은 순'),
};
