import $dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { ComponentsSlideSelectorTmplS } from '../../../../../Components/SlideSelector/S/Tmpl/module/ComponentsSlideSelectorTmplS.js';
import { MShopAppHomeTmplS } from '../../../Home/S/Tmpl/module/MShopAppHomeTmplS.js';
import { MShopAppSellerTmplS } from './module/MShopAppSellerTmplS.js';

export const mallList = ({ filters, officials, official_filters, banners, is_mobile, is_plus, so_id }) => {
  const banner_data_msg = {
    screen: {
      title: T('mshop::마플샵 팝업스토어'),
      type: 'util',
      uri: `/${T.lang}/@/sellers/banner`,
    },
  };
  return html`
    <div class="mall-sellers">
      ${is_plus
        ? html` <div class="mshop-seller"><div class="mall-sellers__cont"></div></div> `
        : html`
            ${banners?.length
              ? html`
                  <a
                    class="app-home__more-anchor app-home__more-anchor--arrow"
                    href="${banner_data_msg.screen.uri}"
                    data-post-message="${$dataStr(banner_data_msg)}"
                    ><div>${T('View all')}</div></a
                  >
                  <h2 class="mall-sellers__title">${banner_data_msg.screen.title}</h2>
                  <div class="app-home__section app-home__section--kv" data-section-hide="${!banners.length}">
                    ${MShopAppHomeTmplS.kv({ banners, is_mobile, is_all_crew: true })}
                  </div>
                `
              : ''}
            <div class="mshop-official">
              <h2 class="mall-sellers__title mshop-official__title">
                ${T('mshop::팬심으로 보고, 사심으로 사는 공식굿즈', {
                  br: is_mobile ? '<br>' : ' ',
                })}
              </h2>
              ${official_filters?.length
                ? html`
                    <div class="custom-slide-selector-wrapper custom-slide-selector-wrapper__official">
                      ${ComponentsSlideSelectorTmplS.make(official_filters, { basic: false })}
                    </div>
                  `
                : ''}
              <div class="mall-sellers__official">
                ${(() => {
                  if (so_id == 'plus') {
                    // 브랜드
                    return MShopAppSellerTmplS.brandList({
                      crews: officials,
                      is_mobile,
                      so_id,
                    });
                  } else {
                    return MShopAppSellerTmplS.sellerList({
                      so_id,
                      sellers: officials,
                    });
                  }
                })()}
              </div>
              <div class="mshop-official__more">
                <button type="button" class="mshop-official__more-btn">모두 보기</button>
              </div>
            </div>
            <div class="mshop-seller">
              <h2 class="mall-sellers__title">${T('mshop::세상 모든 크리에이터가 여기에!')}</h2>
              ${filters?.length
                ? html`
                    <div class="custom-slide-selector-wrapper custom-slide-selector-wrapper__seller">
                      ${ComponentsSlideSelectorTmplS.make(filters, { basic: false })}
                    </div>
                  `
                : ''}
              <div class="mall-sellers__cont"></div>
            </div>
          `}
    </div>
  `;
};
