import { go, html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { NessCustomMakerFrameShareTmplS } from '../../S/Tmpl/module/NessCustomMakerFrameShareTmplS.js';
import { NessCustomMakerFrameShareF } from '../Function/module/NessCustomMakerFrameShareF.js';
import { $find, $setHTML } from 'fxdom/es';
import { requiringBorder } from '../../../../../Maker/F/Product/bpc_tmpl.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';

const mui_ref_path = '/modules/NessCustomMaker/Frame/Share/F/Mui/bp_color_selector_tab.js';

const makeData = (tab) => {
  return {
    selected_bpc_id: box().maker.product_color.base_product_color_id,
    base_product_colors: box().maker.product_color._.base_product._.base_product_colors,
  };
};

const template = ({ selected_bpc_id, base_product_colors }) => {
  return NessCustomMakerFrameShareTmplS.iscroll_wrapper_tmpl(
    go(
      base_product_colors,
      strMap((base_product_color) => bp_selector_item(selected_bpc_id, base_product_color)),
    ),
  );
};

/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const bp_color_selector_tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '색상', // tab button의 title
  selected: true, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)

  makeData,
  template,
  showing(tab_el) {
    go(tab_el, $find('.don_wrapper'), $setHTML(template(makeData(tab_el))));
  },
  showed(tab) {
    NessCustomMakerFrameShareF.iscroll_showed(tab);
  },
  hiding(tab) {
    NessCustomMakerFrameShareF.iscroll_hiding(tab);
  },
  appended(tab) {
    return _pipe(
      $.on3(
        'click',
        '.selector_item.color_item:not(.sold_out)',
        NessCustomMakerFrameShareF.bpColorSelectEvent,
      ),
    )(tab);
  },
};

const bp_selector_item = (selected_bpc_id, base_product_color) => {
  const { name, name2 } = base_product_color;
  return html`
    <li
      _id="${base_product_color.id}"
      class="selector_item color_item i_item ${UtilTmplS.selectIf(selected_bpc_id === base_product_color.id)}"
      data-fx-json="${$dataStr(base_product_color)}"
      color_code="${base_product_color.color_code}"
      need_ccc="${requiringBorder([base_product_color.color_code, base_product_color.color_code2])}"
    >
      <div
        class="code"
        style="background: ${base_product_color.color_code2
          ? 'linear-gradient(135deg, ' +
            base_product_color.color_code +
            ' 50%, ' +
            base_product_color.color_code2 +
            ' 50%)'
          : base_product_color.color_code}"
      ></div>
      <div class="name">${name !== name2 && name2 ? `${name} / ${name2}` : `${name}`}</div>
    </li>
  `;
};
