export const STYLE_SETTINGS_FRAME = '@FRAME/Creator/Setting/style_settings';
export const STYLE_SETTINGS_PAGE = '@PAGE/Creator/Setting/style_settings';
export const STYLE_SETTINGS_TAB = '@TAB/Creator/Setting/style_settings';

export const STYLE_EDITING_FRAME = '@FRAME/Creator/Setting/style_editing';
export const STYLE_EDITING_PAGE = '@PAGE/Creator/Setting/style_editing';
export const STYLE_EDITING_TAB = '@TAB/Creator/Setting/style_editing';

export const PRODUCT_SETTINGS_FRAME = '@FRAME/Creator/Setting/product_settings';
export const PRODUCT_SETTINGS_PAGE = '@PAGE/Creator/Setting/product_settings';
export const PRODUCT_SETTINGS_TAB = '@TAB/Creator/Setting/product_settings';

export const PRODUCT_EDITING_FRAME = '@FRAME/Creator/Setting/product_editing';
export const PRODUCT_EDITING_PAGE = '@PAGE/Creator/Setting/product_editing';
export const PRODUCT_EDITING_TAB = '@TAB/Creator/Setting/product_editing';

export const PRODUCT_EDITING_FRAME_WITH_PRE_DISCOUNT = '@FRAME/Creator/Setting/product_editing_pre_discount';
export const PRODUCT_EDITING_PAGE_WITH_PRE_DISCOUNT = '@PAGE/Creator/Setting/product_editing_pre_discount';

export const PRODUCT_CREATION_FRAME = '@FRAME/Creator/Setting/product_creation';
export const PRODUCT_CREATION_PAGE = '@PAGE/Creator/Setting/product_creation';
export const PRODUCT_CREATION_TAB = '@TAB/Creator/Setting/product_creation';

export const CATEGORY_SELECTION_PAGE = '@PAGE/Creator/Setting/category_selection';
export const CATEGORY_SELECTION_TAB = '@TAB/Creator/Setting/category_selection';

export const BASE_PRODUCT_SELECTION_PAGE = '@PAGE/Creator/Setting/base_product_selection';
export const BASE_PRODUCT_SELECTION_TAB = '@TAB/Creator/Setting/base_product_selection';

export const ACCOUNT_SETTINGS_FRAME = '@FRAME/Creator/Setting/account_settings';
export const ACCOUNT_SETTINGS_PAGE = '@PAGE/Creator/Setting/account_settings';
export const ACCOUNT_SETTINGS_TAB = '@TAB/Creator/Setting/account_settings';

export const MARKETING_SETTINGS_FRAME = '@FRAME/Creator/Setting/marketing_settings';
export const MARKETING_SETTINGS_PAGE = '@PAGE/Creator/Setting/marketing_settings';
export const MARKETING_SETTINGS_TAB = '@TAB/Creator/Setting/marketing_settings';

export const INVITE_SETTINGS_FRAME = '@FRAME/Creator/Setting/invite_settings';
export const INVITE_SETTINGS_PAGE = '@PAGE/Creator/Setting/invite_settings';
export const INVITE_SETTINGS_TAB = '@TAB/Creator/Setting/invite_settings';

export const BANK_SETTINGS_FRAME = '@FRAME/Creator/Setting/bank_settings';
export const BANK_SETTINGS_PAGE = '@PAGE/Creator/Setting/bank_settings';
export const BANK_SETTINGS_TAB = '@TAB/Creator/Setting/bank_settings';

export const TERMS_SETTINGS_FRAME = '@FRAME/Creator/Setting/terms_settings';
export const TERMS_SETTINGS_PAGE = '@PAGE/Creator/Setting/terms_settings';
export const TERMS_SETTINGS_TAB = '@TAB/Creator/Setting/terms_settings';

export const INVITE_INFO_SETTINGS_FRAME = '@FRAME/Creator/Setting/invite_info_settings';
export const INVITE_INFO_SETTINGS_PAGE = '@PAGE/Creator/Setting/invite_info_settings';
export const INVITE_INFO_SETTINGS_TAB = '@TAB/Creator/Setting/invite_info_settings';

export const REDESIGN_PRODUCT_COLUMNS = [
  'thumbnails',
  'price',
  'price_en',
  'price_jp',
  'price_info',
  'og_image_url',
  'base_product_id',
  'product_faces2',
  'product_faces',
  'overflow_sizes',
  'base_product_color_id',
  'base_product_size_id',
  'svg_product_id',
  'selected_option_group_id',
  'image_ids',
];

export const STUDIO_UPDATE_NOTE_URL =
  'https://updatenote.marpplecorp.com/04610790-7f94-4d72-91bb-db2b84b61e6a';

const do_su = [
  6951, 6952, 7246, 7251, 7252, 7262, 7264, 7266, 7267, 7414, 7415, 7416, 7417, 7418, 7419 /* 1도 */,
];
const pu = [
  4726, 4727, 4718, 4719, 4729, 4728, 4724, 4717, 4720, 4721, 4725, 4722, 4723 /* PU 가죽 글씨만 가능 */,
];
const jasoo = [
  6938, 6939, 6940, 6941, 6942, 6945, 7313, 7314, 7315, 7316, 7416, 7417, 7418, 7419 /* 자수만 가능 */,
];
const carrier = [4055 /* 캐리어 커버 cs 불안*/];
const sticker = [
  4797, 4798, 4799, 4800, 4801, 4802, 4803, 4804, 4837, 4838, 4839, 4840, 4841, 4842, 4843, 4844, 4845, 4846,
  4887, 4888, 4889, 4890, 4891, 4892, 4893, 4894, 4895, 4896, 4897, 4898, 4911, 4912, 4913, 4914, 4915, 4916,
  4917, 4918, 4919, 4920, 4921, 4922, 4942, 4943, 4944, 4945, 4946, 4947, 4951, 4952, 4953, 4954, 4955, 4956,
  4957, 4958, 4959, 4960, 4961, 4962, 5024, 5025, 5026, 5027, 5028, 5029, 5030, 5031, 5032, 5033, 5034, 5035,
  5036, 5037, 5116, 5117, 5118, 5119, 5120, 5121, 5122, 5123, 5124, 5125, 5126, 5127, 5128, 5129, 5130, 5131,
  5224, 5225, 5226, 5227, 5228, 5229, 5230, 5231, 5232, 5233, 5234, 5235, 5236, 5237, 5238, 5239, 5283, 5284,
  5285, 5286, 5287, 5288, 5289, 5290, 5291, 5292, 5293, 5294, 5295, 5296, 5297, 5298, 5301, 5302, 5303, 5304,
  5305, 5306, 5307, 5308, 5309, 5310, 5311, 5312, 5313, 5314, 5315, 5316, 5374, 5375, 5376, 5377, 5378, 5379,
  5380, 5395, 5396, 5398, 5399, 5400, 5401, 5402, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454,
  5455, 5456, 5457, 5458, 5459, 5460,
  /* 스티커 */
];
// 스튜디오에서 커스텀 상품 생성시 노출되면 안되는 목록
export const CUSTOM_PRODUCT_CATEGORY_BLACK_LIST = {
  // 개인화 블랙리스트
  ASSET: {
    cate_item_ids: [269, 294, 259, 498],
    base_product_ids: [...do_su, ...pu, ...jasoo, ...carrier, ...sticker],
  },
  // 초개인화 블랙리스터
  USER: {
    cate_item_ids: [269, 294],
    base_product_ids: [...do_su, ...pu, ...jasoo, ...carrier, ...sticker],
  },
};

export const STUDIO_CREATOR_GUIDE_URL = (lang) => `https://${lang}.marppleshop.help/`;
