import { $after, $before, $closest, $el, $find, $findAll, $is, $next, $on, $remove } from 'fxdom/es';
import { any, map, pipe, sel } from 'fxjs/es';
import { getCursorEl } from '../medium-editor.js';
import {
  getEmbedHtml,
  getEmbedService,
  isMarppleshopProduct,
  replaceBasicEmbedUrl,
} from '../../../S/util.js';
import { initEmbedToolbar } from '../toolbar/medium-editor-embed-toolbar.js';
import { hideInsert } from './index.js';
import { createActionStack } from '../features/bug_tracker.js';

const required_manual_reloads = ['instagram', 'facebook', 'twitter'];

const makeFeatureBtnEl = () =>
  $el(`<div class="feature_btn embed_button">
          <i class="lnr lnr-link"></i>
          <span class="feature-name">링크</span>
        </div>`);

export const manualReloadEmbed = (url, service) => {
  if (!required_manual_reloads.includes(service)) return;
  service == 'instagram'
    ? instgrm.Embeds.process()
    : service == 'twitter'
    ? twttr.widgets.load()
    : FB.XFBML.parse();
};

const checkMarppleUrl = (url) => {
  const urls = url.split(',').map((s) => s.trim());
  const is_marpples = map(isMarppleshopProduct, urls);
  if (any(is_marpples)) return "마플샵 상품 임베딩은 '상품' 버튼으로 이용 가능합니다.";

  // if (!all(is_marpples) && any(is_marpples)) return '마플샵 임베딩은 다른 서비스와 함께 쓸 수 없습니다.';
  // if (urls.length > 4) return '마플샵 임베딩은 한 줄에 최대 4개입니다.';
  return '';
};

const createEmbed = async (e) => {
  if (e.key !== 'Enter') return;

  const $container = $closest('.embed_container', e.target);
  if (!$is('.embed_container', $container)) return;

  const url = e.target.value;
  const service = getEmbedService(url);
  const replaced_url = replaceBasicEmbedUrl(url, service);
  const $embed_el = $el(`<div class="embed" contenteditable="false">${replaced_url}</div>`);

  const error = checkMarppleUrl(replaced_url);
  if (error) return $.alert(error);

  try {
    const html = await getEmbedHtml(replaced_url);
    if (!html) return $.alert('url 이 올바르지 않습니다.');
    $after($el(html), $container);
  } catch (err) {
    const message = sel('response.data.message', err);
    e.target.blur();
    return message && $.alert(message);
  }

  $before($embed_el, $container);
  G.don_lazy();
  e.target.blur();
  // facebook, instagram
  manualReloadEmbed(url, service);

  createActionStack('create embed', { $embed_el });
};

const removeEmbedBox = (e) => {
  if (!e.target) return;

  const container = $closest('.embed_container', e.target);
  $next(container).click();
  $remove(container);
};

const createEmbedInput = (editor) => (e) => {
  const $embed_box = $el(`
    <div class="embed_container">
      <input class="embed_input" type="text" placeholder="Paste a YouTube, Vimeo, Facebook, Twitter or Instagram link and press Enter">
    </div>
  `);

  const $embed_input = $find('.embed_input', $embed_box);
  $before($embed_box, getCursorEl(editor));
  $embed_input.focus();

  $on('keydown', createEmbed)($embed_input);
  $on('blur', removeEmbedBox)($embed_input);
};

export const initEmbedPlugin = (registerBtnToInsertBox, editor) => {
  initEmbedToolbar(editor);

  // register btn
  const btn_el = makeFeatureBtnEl();
  registerBtnToInsertBox(btn_el);

  const clickHandler = createEmbedInput(editor);
  $on('click', () => {
    clickHandler();
    hideInsert();
  })(btn_el);
};

export const removeRemainEmbedUrl = pipe($findAll('.embed_show'), map($remove));
