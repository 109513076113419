import { $delegate, $find, $trigger } from 'fxdom/es';
import { go, debounce } from 'fxjs/es';
import { createAgreeModal } from '../../../../../../../services/MarppleShop/shared/client/agreeModal.js';
import { PrivacyPopUpTmplS } from '../../../../../../Privacy/PopUp/S/Tmpl/module/PrivacyPopUpTmplS.js';
import { TermsConditionsTmplS } from '../../../../../../Terms/Conditions/S/Tmpl/module/TermsConditionsTmplS.js';
import { MShopAppStoreQuestionF } from './module/MShopAppStoreQuestionF.js';

export const events = (container_el) => {
  const openPrivacyModal =
    ET.lang !== 'kr' &&
    createAgreeModal({
      title: T('Privacy Policy'),
      body:
        box.sel('is_user->type') == 'TEMP'
          ? PrivacyPopUpTmplS.signUpCheckout()
          : PrivacyPopUpTmplS.loginCheckout(),
      body_klass: ET.lang === 'kr' ? 'privacy-popup' : 'terms_body',
      style_klass: ET.lang === 'kr' ? '' : 'terms_modal_style terms_style',
      container_klass: 'question-rune-modal',
      parent_selector: '.don_tab[tab_name="/modules/MShop/App/Store/Question/S/Mui/tab.js"] > .don_wrapper',
      skip_body_fix: true,
    });

  const openTermsModal =
    ET.lang !== 'kr' &&
    createAgreeModal({
      title: T('Terms & Conditions'),
      body: TermsConditionsTmplS.termsHtml({ lang: T.lang }),
      body_klass: 'terms_body',
      style_klass: 'terms_modal_style terms_style',
      container_klass: 'question-rune-modal',
      parent_selector: '.don_tab[tab_name="/modules/MShop/App/Store/Question/S/Mui/tab.js"] > .don_wrapper',
      skip_body_fix: true,
    });

  go(
    container_el,
    $delegate('click', '.store-qna-editor__btn-file', (e) => {
      $trigger('click', $find('.store-qna-editor__input-file', e.delegateTarget));
    }),
    $delegate(
      'focusout',
      '.store-qna-editor__body-input[name="name"]',
      MShopAppStoreQuestionF.focusoutNameInput,
    ),
    $delegate(
      'focusout',
      '.store-qna-editor__body-input[name="mobile"]',
      MShopAppStoreQuestionF.focusoutMobileInput,
    ),
    $delegate(
      'focusout',
      '.store-qna-editor__body-input[name="email"]',
      MShopAppStoreQuestionF.focusoutEmailInput,
    ),
    $delegate(
      'focusout',
      '.store-qna-editor__body-input[name="title"]',
      MShopAppStoreQuestionF.focusoutTitleInput,
    ),
    $delegate('focusout', '.store-qna-editor__body-textarea', MShopAppStoreQuestionF.focusoutBodyTextarea),
    $delegate('keyup', '.store-qna-editor__body-textarea', MShopAppStoreQuestionF.keyUpBodyTextarea),
    $.on3('change', '.store-qna-editor__input-file', MShopAppStoreQuestionF.changeFile),
    $delegate('click', '.store-qna-editor__body-file-del', MShopAppStoreQuestionF.clickDelFile),
    $delegate('click', '.store-qna-editor__agree-view', MShopAppStoreQuestionF.openPrivacy),
    $delegate('click', '.store-qna-editor__btn-submit', MShopAppStoreQuestionF.clickSubmit),
    $delegate('click', '.store-qna-editor__btn-cancel', () => {
      $.frame.close();
    }),
    $delegate(
      'input',
      [
        '.store-qna-editor__body-input[name="name"]',
        '.store-qna-editor__body-input[name="mobile"]',
        '.store-qna-editor__body-input[name="email"]',
        '.store-qna-editor__body-input[name="title"]',
        '.store-qna-editor__body-textarea',
        '.mshop-core__checkbox__input[name="is_agree"]',
      ].join(','),
      debounce(MShopAppStoreQuestionF.controlButtonState, 100),
    ),
    $delegate('click', '.go_privacy', (e) => {
      e.originalEvent.preventDefault();
      if (openPrivacyModal) {
        openPrivacyModal();
      }
    }),
    $delegate('click', '.go_terms', (e) => {
      e.originalEvent.preventDefault();
      if (openPrivacyModal) {
        openTermsModal();
      }
    }),
  );
};
