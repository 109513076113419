import format from 'date-fns/format/index.js';
import { html as runeHtml } from 'rune-ts';

import { html } from 'fxjs/es';
import { GoodsTypeTmplS } from '../../../GoodsType/S/Tmpl/module/GoodsTypeTmplS.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { messages } from '../../Setting/S/messages_id.js';
import { makeSellerStudioTable } from '../../Setting/S/table.js';
import { sumAdjustments } from './util.js';
import { StudioHeader } from '../../../../services/MarppleShop/marppleShopStudioApp/components/cells/Layout/StudioTitle.ts';

const makeSellerAdjustmentTable = ({ adjustments, crew }) => {
  const { quantity, profit, bonus_profit, total_profit, without_fee_amount, pg_amount } =
    sumAdjustments(adjustments);

  const bonus_profit_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T(messages.table.th7)}</h4>
      <p>${T(messages.tooltip.t1)}</p>
    </div>
  `;

  const pg_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">
      <h4>${T('studio::tooltip::PG사 수수료')}</h4>
      <p>${T('studio::tooltip::PG사 수수료 3.2%에 10% 부가가치세를 더한 가격입니다.')}</p>
    </div>
  `;

  const without_fee_amount_tooltip_content = html`
    <span class="lnr tooltip-icon tooltip-question"></span>
    <div class="tooltip-container">${T(messages.tooltip.t2)}</div>
  `;

  return makeSellerStudioTable({
    col_rates: !crew.without_bonus_profit
      ? [
          { rate: 11, align: 'left' },
          { rate: 25, align: 'center', klass: 'seller-studio-table__td--text-overflow' },
          { rate: 10 },
          { rate: 10 },
          {
            rate: 12,
            klass: 'bonus-profit-service',
            tool_tip: bonus_profit_tooltip_content,
          },
          { rate: 10 },
          {
            rate: 12,
            tool_tip: pg_amount_tooltip_content,
          },
          {
            rate: 12,
            tool_tip: without_fee_amount_tooltip_content,
          },
          { rate: 10, align: 'center' },
        ]
      : [
          { rate: 11, align: 'left' },
          { rate: 25, align: 'center' },
          { rate: 16 },
          { rate: 16 },
          null,
          null,
          {
            rate: 16,
            tool_tip: without_fee_amount_tooltip_content,
          },
          { rate: 16, align: 'center' },
        ],
    headers: [
      T(messages.table.th15),
      T(messages.table.th12),
      T(messages.table.th4),
      T(messages.table.th6),
      T(messages.table.th7),
      T(messages.table.th8),
      T(messages.table.th16_1),
      T(messages.table.th16),
      T(messages.table.th17),
    ],
    bodys: adjustments.map(
      ({
        created_at,
        type,
        p_name,
        bp_name,
        quantity,
        profit,
        bonus_profit,
        without_fee_amount,
        pg_amount,
      }) => {
        return [
          format(created_at, 'yyyy-MM-dd'),
          p_name || bp_name,
          UtilS.commify(quantity),
          UtilS.commify(profit),
          UtilS.commify(bonus_profit),
          UtilS.commify(profit + bonus_profit),
          UtilS.commify(pg_amount),
          UtilS.commify(without_fee_amount),
          T(messages.adjustments[type]),
        ];
      },
    ),
    totals: [
      T(messages.table.th18),
      '',
      UtilS.commify(quantity),
      UtilS.commify(profit),
      UtilS.commify(bonus_profit),
      UtilS.commify(total_profit),
      UtilS.commify(pg_amount),
      UtilS.commify(without_fee_amount),
      '-',
    ],
  });
};

export const makeAdjustmentsSettingsBodyHtml = ({
  adjustments,
  date,
  crew,
  goods_type_id,
  has_permission_tpl,
  domain_name,
}) => {
  const is_afreeca = crew.id == MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;
  const preparing_adjustments = !!adjustments?.length;
  return html`
    <div class="header flex">
      <div class="left">
        ${new StudioHeader({
          title: UtilS.isMarppleShopApp()
            ? ET('mps2::studio::page::adjustments', {
                date: format(date, T(messages.adjustments.ym_locale)),
              })
            : `${T(messages.menus.adjustments)} - ${format(date, T(messages.adjustments.ym_locale))}`,
          description: UtilS.isMarppleShopApp()
            ? ET('mps2::studio::page::adjustments_guide')
            : T(messages.adjustments.p1),
          with_margin: false,
          suffix: {
            type: 'tooltip',
            tooltip_content: runeHtml`<p>
                ${runeHtml.preventEscape(
                  UtilS.isMarppleShopApp()
                    ? ET('mps2::studio::tooltip::adjustments', {
                        tpl_adjustments: has_permission_tpl ? ` & ${T(messages.menus.tpl_adjustments)} ` : '',
                      })
                    : T(messages.tooltip.h2, {
                        tpl_adjustments: has_permission_tpl ? ` & ${T(messages.menus.tpl_adjustments)} ` : '',
                      }),
                )}
              </p>`,
          },
        }).toHtmlSSR()}
      </div>
      <div class="right">
        <button
          type="button"
          class="download-excel${preparing_adjustments ? '' : ' download-excel--disabled'}"
          ${preparing_adjustments ? '' : 'disabled'}
        >
          <img
            src="${preparing_adjustments
              ? '//s3.marpple.co/files/u_29089/2023/2/original/8e3f4cc22c165c2cdfa3a9fc5af59b753975d1ac1.webp'
              : '//s3.marpple.co/files/u_29089/2023/2/original/9794c9df1bd62bfa1d2467dd05c1e924beb989851.webp'}"
            alt=""
            style="pointer-events:${preparing_adjustments ? 'auto' : 'none'}"
          />
          ${T('item_order_excel::정산 내역 내려받기')}
        </button>
        <button
          class="btn btn-blue"
          name="date"
          type="button"
          value="${format(date, T(messages.adjustments.ym_digit))}"
        >
          <i class="lnr lnr-calendar-full"></i>
          ${T(messages.adjustments.btn1)}
        </button>
      </div>
    </div>
    ${!is_afreeca
      ? GoodsTypeTmplS.adjustmentGoodsTypeHref({
          has_permission_tpl,
          goods_type_id,
          domain_name,
        })
      : ''}
    <div class="body">${makeSellerAdjustmentTable({ adjustments, crew })}</div>
  `;
};
