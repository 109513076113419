import {
  $addClass,
  $attr,
  $closest,
  $data,
  $delegate,
  $find,
  $focus,
  $parent,
  $qs,
  $removeClass,
  $setAttr,
  $setHTML,
  $setText,
  $setVal,
  $val,
} from 'fxdom/es';
import { go, last, sel } from 'fxjs/es';
import { MuiF } from '../../../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilF } from '../../../../../../Util/F/Function/module/MShopUtilF.js';
import { svg } from '../../../../../../Util/S/Constant/svg.js';
import { MShopAppStoreAboutEditModalMuiS } from '../../S/Mui/module/MShopAppStoreAboutEditModalMuiS.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...MShopAppStoreAboutEditModalMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,
  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    go(
      tab_el,
      $delegate('change', '[name="thumb"]', handleChangeThumb),
      $delegate('change', '[name="sns_url"]', handleChangeSNS),
      $delegate('keyup', '[name="description"]', handleKeyupDesc),
      $delegate('change', '[name="description"]', handleChangeDesc),
      $delegate('change', '[name="about_img"]', handleChangeImage),
      $delegate('change', '[name="about_video_url"]', handleChangeVideo),
      $delegate('click', '.remove-img-btn', handleRemoveImageOrVideo),
      $delegate('click', '.about-form__opt-btn', handleClickRepOptions),
      $delegate('submit', '.about-form', handleSubmitForm),
    );
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수
  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수
  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};

async function handleChangeThumb(e) {
  const $wrapper = $closest('.about-form__thumb', e.currentTarget);
  const $img = $find('.about-form__thumb-img img', $wrapper);
  const $input = $find('[name=thumbnail_img]', $wrapper);

  try {
    $.don_loader_start();
    const file = await $.upload(e.currentTarget, {
      url: '/@api/mp_shop/app/file_upload',
    });
    $.don_loader_end();
    $setVal(file.url, $input);
    $setAttr({ src: file.url }, $img);
  } catch (e) {
    $.don_loader_end();
    if (sel('responseText.length', e) && sel('responseText', e).indexOf('File too large') != -1) {
      $.alert(T('ad_res::파일 용량이 너무 큽니다'));
    } else {
      $.alert(T('ad_res::파일업로드가 실패했습니다.'));
    }
  }
}

async function handleChangeSNS(e) {
  const url = e.currentTarget.value.trim();
  if (url && !UtilS.isUrl(url)) {
    await $.alert(T('ad_res::올바른 URL 형식을 입력해 주세요'));
    go(e.currentTarget, $focus);
  }
}

async function handleKeyupDesc(e) {
  const val = e.currentTarget.value.trim();
  const length_count_el = $find('.about-form__desc-length-count', e.delegateTarget);
  $setText(UtilS.checkStringLength(val), length_count_el);
}

async function handleChangeDesc(e) {
  const val = e.currentTarget.value.trim();

  if (UtilS.checkStringLength(val) > 150) {
    await $.alert(T('ad_res::150자 이하로 입력해 주세요'));
    return go(e.currentTarget, $focus);
  }
}

function handleClickRepOptions(e) {
  const $wrapper = $closest('.about-form__rep', e.currentTarget);
  const type = e.currentTarget.dataset.type;
  const other = type === 'image' ? 'video' : 'image';
  go($wrapper, $setAttr({ current: type }), $removeClass(other), $addClass(type));

  if (type === 'image') {
    $find('[name=about_img]', $wrapper).click();
  }
}

async function handleChangeImage(e) {
  const $wrapper = $closest('.about-form__rep', e.currentTarget);
  const $preview = $find('.about-form__image-preview', $wrapper);
  const $input = $find('[name=about_img_url]', $wrapper);
  const tmpl = (image_url) => `
    <img src="${image_url}" />
    <button type='button' class='remove-img-btn'>${svg.close}</button>
  `;

  try {
    $.don_loader_start();
    const file = await $.upload(e.currentTarget, {
      url: '/@api/mp_shop/app/file_upload',
    });
    $.don_loader_end();
    $setVal(file.url, $input);
    $setHTML(tmpl(file.url), $preview);
  } catch (e) {
    $.don_loader_end();
    if (sel('responseText.length', e) && sel('responseText', e).indexOf('File too large') != -1) {
      $.alert(T('ad_res::파일 용량이 너무 큽니다'));
    } else {
      $.alert(T('ad_res::파일업로드가 실패했습니다.'));
    }
  }
}

function handleRemoveImageOrVideo(e) {
  const $wrapper = $closest('.about-form__rep', e.currentTarget);
  const type = $attr('current', $wrapper);
  const $preview = $parent(e.currentTarget);
  const $input = $find(`[name=about_${type === 'image' ? 'img' : 'video'}_url]`, $wrapper);

  $removeClass(type, $wrapper);
  $setHTML('', $preview);
  $setVal('', $input);
}

const makeYoutubeEmbedUrl = (url = '') => {
  const embed_url = 'https://www.youtube.com/embed/';
  const embed_regexp = /embed\/+/;
  const match_regexp = /watch\?v=([^&]+)/;
  const short_regexp = /shorts\/([^&|?]+)/;

  if (embed_regexp.test(url)) {
    return url;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp);
    return embed_url + youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp);
    return embed_url + youtube_id;
  }
  return (
    embed_url +
    url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '')
  );
};

const iframeToUrl = (iframe) => {
  // const isUrl = (url) => /^https:\/\//g.test(url);
  const replaceTwitchUrl = (url) =>
    url.replace('www.example.com', ENV.is_prod ? 'marpple.shop' : location.hostname);
  const result_url = last(iframe.match(/src\s*=\s*"(.+?)"/)) || iframe;
  // result_url = result_url.replace(/^\/\//, 'https://');

  if (/youtu.be/.test(result_url)) return makeYoutubeEmbedUrl(result_url);
  else if (/youtube.com/.test(result_url)) return makeYoutubeEmbedUrl(result_url);
  else if (/www.twitch.tv/.test(result_url)) return '';
  else if (/twitch.tv/.test(result_url)) return replaceTwitchUrl(result_url);
  else if (/^http(s?):\/\/|\/\/vod./g.test(result_url)) return result_url;
  return '';
};

async function handleChangeVideo(e) {
  const url = e.currentTarget.value.trim();
  const $preview = go(e.currentTarget, $closest('.about-form__rep'), $find('.about-form__video-preview'));
  const converted_url = iframeToUrl(url);

  if (!url) return;
  if (!converted_url) {
    await $.alert(T('s_about::wrong_url'));
    go(
      e.currentTarget,
      $setVal(''),
      $closest('.about-form__rep'),
      $find('.about-form__video-preview'),
      $setHTML(''),
    );
    return;
  }

  const iframe_tmpl = `
    <iframe
      src="${converted_url}"
      width="100%"
      height="100%"
      frameborder="0"
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="false"
    ></iframe>
    <button type='button' class='remove-img-btn'>${svg.close}</button>
  `;

  $setVal(converted_url, e.currentTarget);
  $setHTML(iframe_tmpl, $preview);
}

async function handleSubmitForm(e) {
  e.originalEvent.preventDefault();

  const store = $data($qs('.about-form'));
  const form = document.forms['about-form'];

  const data = {
    thumbnail_img: $val(form.thumbnail_img),
    sns_url: $val(form.sns_url),
    description: $val(form.description),
  };

  if (data.sns_url && !UtilS.isUrl(data.sns_url)) {
    await $.alert(T('ad_res::올바른 URL 형식을 입력해 주세요'));
    return go(form.sns_url, $focus);
    // } else if (!data.description) {
    //   await $.alert(T('ad_res::소개 문구를 입력해 주세요'));
    //   return go(form.description, $setVal(''), $focus);
  } else if (data.description && UtilS.checkStringLength(data.description) > 150) {
    await $.alert(T('ad_res::150자 이하로 입력해 주세요'));
    return go(form.description, $focus);
  }

  $.don_loader_start();
  try {
    go($.patch(`/@api/stores/${store.id}`, { ...store, ...data }), (store) => {
      MShopUtilF.popToastMsg(T('s_about::completed'), 'info');
      MuiF.closeFrame(store);
      $.don_loader_end();
    });
  } catch (e) {
    $.alert(T('s_about::failed'));
    $.don_loader_end();
  }

  // 각 밸리데이션 한번씩 더 체크하고, 유요한 데이터 말아서, api 실행 후 팝업 닫아주고,
  // 어바웃 바닥 페이지에 있는 정보 업데이트 하는 방법 모색
}
