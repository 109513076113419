import { type Html, html, View } from 'rune-ts';
import klass from './PageTitle.module.scss';
import { staticTypoPcMo, typoPcMo } from '../../../../shared/typography/typo';
import type { Tabs } from '../../cells/Tabs/Tabs';
import { Tooltip } from '../Tooltip/Tooltip';
import { classes, htmlIf } from '../../../../shared/util';

export interface PageTitleProps {
  title: string | Html | Tabs;
  subTitle: string;
  image?: string;
  tooltip?: Tooltip;
  color?: string;
  sub_title_color?: string;
  additionalView?: View;
  is_mobile: boolean;
  custom_klass?: string;
}

export class PageTitle extends View<PageTitleProps> {
  protected override template(data: PageTitleProps) {
    const {
      image,
      subTitle,
      title,
      additionalView,
      color,
      sub_title_color,
      tooltip,
      is_mobile,
      custom_klass,
    } = data;

    const has_image = !!image;

    return html`
      <div class="${klass.page_title} ${custom_klass}">
        <div class="${klass.group} ${classes({ [klass.has_image]: has_image })}">
          ${htmlIf(
            html`<div class="${klass.image}">
              <img src="${image!}" />
            </div>`,
            has_image,
          )}

          <div class="${klass.text}">
            <p
              class="${klass.sub_title} ${typoPcMo({ is_mobile, pc: '16_medium', mo: '14_medium' })}"
              style="color: ${sub_title_color};"
            >
              ${subTitle}
            </p>
            <div class="${klass.title}" style="color: ${color};">
              <h2 class="${staticTypoPcMo({ is_mobile, pc: 'bebas_40_bold', mo: 'bebas_32_bold' })}">
                ${title} ${tooltip || ''}
              </h2>
            </div>
          </div>
        </div>

        ${additionalView || ''}
      </div>
    `;
  }
}
