import {
  $addClass,
  $closest,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $on,
  $qs,
  $qsa,
  $removeClass,
  $setText,
  $trigger,
} from 'fxdom/es';
import { each, go, juxt } from 'fxjs/es';
import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { NessCustomMakerF } from '../../../F/Function/module/NessCustomMakerF.js';
import { NessCustomMakerTmplS } from '../../../S/Tmpl/module/NessCustomMakerTmplS.js';
import { NewMakerConditionAgreeTextF } from '../../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { NessCustomMakerCoreS } from '../../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { NessCustomMakerCoreF } from '../../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { NessCustomMakerMarpplizerF } from './module/NessCustomMakerMarpplizerF.js';

export const makeNessMarpplizerHtml = (product_color) => {
  const { maker_props, is_phone_cases } = box().maker;

  return legacyHtml`
    <div id="marpplizer">
      <div class="wrapper iscroll_wrapper">
      
        <div class="marpplizer_wrapper iscroll_ul_wrapper">
          <div class="home"></div>
          <div class="activitys" style="opacity:0; transform: translateX(20%);"></div>
        </div>
      </div>
      <div class="general_option_buttons">
      <div class="condition_agree_text" data-route="home" style="${is_phone_cases ? '' : 'display : none'}">
      ${NewMakerConditionAgreeTextF.fn(null, true)}</div>
      ${NessCustomMakerTmplS.makePricingAndCartSectionHtml(product_color, maker_props)}
      </div>
    </div>
  `;
};

export const generalOptionEvents = (el) =>
  go(
    el,
    $delegate('click', '.counter__button', handleChangeQuantity),
    $delegate('change', '[name="quantity"]', handleChangePrice),
    $delegate('change', `.condition_agree_text input[name="warning_checkbox"]`, handleConditionalCheck),
    $delegate('click', `.ness_buttons button`, async ({ currentTarget }) => {
      const button_name = currentTarget?.className;
      if (!button_name) return;
      if (!(await NessCustomMakerCoreF.isMakerPass())) return;
      await NessCustomMakerCoreF.apiCreateUpProduct({
        quantity: box().maker?.maker_props?.quantity || 1,
        is_cart: button_name.trim() === 'ness_add_to_cart',
      });
    }),
    $delegate('click', '#to_vector_editor', async (e) => {
      const tab_el = $closest('.don_tab', e.delegateTarget);
      /* NESS 커스텀하는 방법 모달  */
      NessCustomMakerF.openMakerTipModal(() => {
        return !!$qs('.don_frame.vector-editor__single-editor.is_show');
      });
      await NessCustomMakerCoreF.toVectorEditor(tab_el);
    }),
    $on('mouseenter', async () => {
      if (G.mp.maker.notice_tippy_1) {
        G.mp.maker.notice_tippy_1.setContent(await NessCustomMakerCoreF.noticeContentHtml());
      }
    }),
    $on('click', (e) => {
      const select_box = $find('.selection_wrap .select_box', el);

      if (select_box && !select_box.contains(e.target)) {
        $removeClass('open', $find('.selection_wrap', el));
        const scroll = $qs('.activity');
        scroll?.myScroll?.enable();
        NessCustomMakerMarpplizerF.sendToBack({ target_el: el });
      }
    }),
  );

const handleConditionalCheck = (e) => {
  const maker_props = {
    ...box().maker.maker_props,
    is_condition_agree: e.target.checked,
  };

  box().maker.maker_props = maker_props;
  toggleButtonActive(e.target.checked);
};

export const toggleButtonActive = (active) => {
  go(
    $qsa('.ness_buttons button'),
    each((el) => (active ? $removeClass('disabled', el) : $addClass('disabled', el))),
  );
};

const handleChangeQuantity = ({ currentTarget }) => {
  const counter = $closest('.counter', currentTarget);
  const input = $find('.counter__quantity', counter);
  const { value = '1' } = input;

  if ($hasClass('plus', currentTarget)) {
    const next_val = parseInt(value, 10) + 1;
    input.value = next_val;
  } else {
    const next_val = parseInt(value, 10) - 1;
    input.value = next_val < 1 ? 1 : next_val;
  }

  const quantity_el = go(currentTarget, $closest('.ness_option_wrap'), $find('[name="quantity"]'));
  $trigger('change', quantity_el);
};

const handleChangePrice = ({ currentTarget }) => {
  const quantity = currentTarget.value;
  const price = NessCustomMakerCoreS.getProductPrice(getProductColorInMaker(), quantity);

  const maker_props = {
    ...box().maker.maker_props,
    quantity,
  };

  box().maker.maker_props = maker_props;

  const total_container = go(currentTarget, $closest('.ness_option_wrap'), $find('.ness_total_price'));
  go(total_container, juxt($find('.count'), $find('.price')), ([count_el, price_el]) => {
    $setText(quantity, count_el);
    $setText(UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang), price_el);
  });
};

export const setButtonState = (el) => {
  const size = go(el, $find('.ness_size_radio:checked'));
  if (!size) return;

  if (size.dataset.size === T('mshop::ProductStatus::out_of_stock')) {
    go(
      $qs('.ness_buttons'),
      $findAll('button'),
      each((el) => {
        $addClass('disabled', el);
      }),
    );
  }
};
