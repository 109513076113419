export class EventLogError extends Error {
  data: { title: string; message: string; code: string };

  constructor(_data: { title: string; message: string }, public option?: { cause?: unknown }) {
    super(_data.title, option);
    this.data = {
      code: this.constructor.name,
      ..._data,
    };
    Error.captureStackTrace(this, this.constructor);
  }
}

export const isEventLogError = (e: unknown) => {
  return e instanceof EventLogError;
};

export class InvalidEventLogData extends EventLogError {
  constructor(option?: { cause?: unknown }) {
    super(
      { title: '데이터 형식 오류', message: 'event_log 테이블에 들어갈 수 있는 데이터 형식이 아닙니다.' },
      option,
    );
  }
}

export class InvalidEventLogWhereCondition extends EventLogError {
  constructor(option?: { cause?: unknown }) {
    super({ title: '데이터 형식 오류', message: 'event_log 테이블 조회할 수 있는 조건이 아닙니다.' }, option);
  }
}
