import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import $dataStr from 'fxdom/es/dataStr.js';

export const makeTodayListItem = (
  today,
  { not_lazy, klass, category_query, target = '_self', replace_stack } = {},
) => {
  const link = `/${T.lang}/@/todays/${today.id}${category_query ? `?category=${category_query}` : ''}`;

  const image_url = today.thumbnail.includes('data:image')
    ? html`<today className="thumbnail"></today>`
    : UtilImageS.getResizedUrl({
        url: today.thumbnail,
        width: 500,
        format: 'webp',
      });

  const post_message = {
    [replace_stack ? 'replaceNavigate' : 'screen']: {
      type: 'util',
      title: T('mshop::Content Detail'),
      uri: link,
    },
  };

  return html`
    <a
      class="today-list-item ${klass}"
      href="${link}"
      target="${target}"
      data-post-message="${$dataStr(post_message)}"
    >
      <img
        class="${!not_lazy ? 'lazy-src' : ''} today-list-item__thumb"
        ${!not_lazy ? 'data-src' : 'src'}="${image_url}"
      />

      <div class="today-list-item__content">
        <div class="today-list-item__content_category">${today.category || 'feature'}</div>
        <p class="today-list-item__content_title">${(today.title && today.title.replace('\n', ' ')) || ''}</p>
        <p class="today-list-item__content_description">
          ${(today.description && today.description.replace('\n', '<br/>')) || ''}
        </p>
      </div>
    </a>
  `;
};
