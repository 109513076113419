import { go } from 'fxjs/es';
import { $closest, $findAll } from 'fxdom/es';
import { renderSelectOption, renderSelectOptionNess } from './renderSelectOption.js';

export const pSelectEvent = (spo, changed_option_el) => {
  changed_option_el.dataset.is_default_selected = changed_option_el.value === '';
  const spo_option_els = go(changed_option_el, $closest('.pd-form__pb_options'), $findAll('select'));

  renderSelectOption({
    spo_items: spo._.spo_items,
    spo_options: spo._.spo_options,
    selected_spo_option_i: Array.from(spo_option_els).findIndex(
      (spo_option_el) => spo_option_el === changed_option_el,
    ),
    spo_option_els,
  });
};

export const pSelectEventNess = (spo, changed_option_el) => {
  changed_option_el.dataset.is_default_selected = changed_option_el.value === '';
  const spo_option_els = go(changed_option_el, $closest('.pb-options__options'), $findAll('select'));
  renderSelectOptionNess({
    spo_items: spo._.spo_items,
    spo_options: spo._.spo_options,
    selected_spo_option_i: Array.from(spo_option_els).findIndex(
      (spo_option_el) => spo_option_el === changed_option_el,
    ),
    spo_option_els,
  });
};

// @share_ness
export const pCustomSelectEvent = (spo, changed_option_el) => {
  changed_option_el.dataset.is_default_selected = changed_option_el.value === '';
};
