export const switchSelected = ({ className = 'selected', callback }, currentTarget) => {
  if (currentTarget == null) throw new Error('currentTarget is required');
  if (currentTarget.classList.contains(className)) return;

  const parentElement = currentTarget.parentElement;

  if (parentElement) {
    const selected_el = parentElement.querySelector('.' + className);
    if (selected_el) {
      selected_el.classList.remove(className);
      currentTarget.classList.add(className);
      if (typeof callback === 'function') {
        callback(currentTarget);
      }
    }
  }
};
