import format from 'date-fns/format/index.js';
import addDays from 'date-fns/fp/addDays/index.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { find, findIndex, go, html, map, reject, sel, sortBy, strMap, zipWithIndexL } from 'fxjs/es';
import { typoPcMo } from '../../../../../../../services/MarppleShop/shared/typography/typo.js';
import { getAvailableColors, getProductStatus } from '../../../../../../Creator/Setting/S/fs.js';
import { DeliveryTypeS } from '../../../../../../DeliveryType/S/Function/module/DeliveryTypeS.js';
import { GoodsTypeS } from '../../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { isNeedPreview } from '../../../../../../Maker/S/categorize.js';
import { PBStoresDeliveryCompanyConstantS } from '../../../../../../PB/StoresDeliveryCompany/S/Constant/module/PBStoresDeliveryCompanyConstantS.js';
import { PriceS } from '../../../../../../Price/S/Function/module/PriceS.js';
import { calcSizePrice, creatorPriceOfProduct } from '../../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import {
  addAttrIf,
  commify,
  differenceTimeInUnits,
  htmlIf2,
} from '../../../../../../Util/S/Function/util.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopStudioPreDiscountProductS } from '../../../../../Studio/PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { isEbookByCateItemId } from '../../../../../Studio/DigitalProductCreator/S/Function/util.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppCommunityTmplS } from '../../../../Community/S/Tmpl/module/MShopAppCommunityTmplS.js';
import { MShopAppTokenGateStoresProductTmplS } from '../../../../TokenGate/StoresProduct/S/Tmpl/module/MShopAppTokenGateStoresProductTmplS.js';
import { PRODUCT_STATUS, PRODUCT_STATUS_TEXT } from '../../constant.js';
import { checkIsDisabled, checkProductSaleStatus } from '../../fs.js';
import { messages } from '../../message_id.js';
import { makePromotionalProductsHtml, makeTShirtsHtml } from './hyundai_n.js';
import { MShopCoreConstantS } from '../../../../../Core/S/Constant/module/MShopCoreConstantS.js';

const getByLang = (key, obj) => sel(key + _en, obj);

// 상품 기본 정보
export const makeProductInfoHtml = ({ product_detail, store, is_seller, is_mobile = false }) => {
  if (UtilS.isNessApp()) {
    return makeProductInfoHtmlNess({ product_detail, is_seller, is_mobile });
  }

  const {
    _: { base_product, stores_product, selected_option_group },
    is_public,
    is_sticker,
    ['name' + _en]: prod_name,
  } = product_detail;

  const { is_use_creator, is_use_creator_en, is_use_creator_jp } = base_product;
  const base_name = base_product['name_creator' + G._en] ?? base_product['name' + G._en];
  const {
    is_prohibited,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    product_type_id,
    goods_type_id,
    is_pb_mg,
    sp_cate_item_id,
    _: { digital_product },
  } = stores_product;
  const ebook_category_name = isEbookByCateItemId(sp_cate_item_id)
    ? `&nbsp;>&nbsp;${digital_product._.ebook_info._.ebook_category.name_intl[ET.lang]}`
    : '';
  const cate_list_name = sel('name' + G._en)(base_product._?.cate_list);
  const cate_item_name = sel('name' + G._en)(base_product._?.cate_item);
  const category_name = `${cate_list_name}&nbsp;>&nbsp;${cate_item_name}${ebook_category_name}`;

  const product_status = getProductStatus({
    is_prohibited,
    is_public,
    is_confirmed,
    is_confirm_req,
    is_digital_product: !!digital_product?.id,
    is_check_design,
    is_use_creator,
    is_use_creator_en,
    is_use_creator_jp,
    is_pb_mg,
  });

  const status_text = [PRODUCT_STATUS.PUBLIC, PRODUCT_STATUS.PB_ME].includes(product_status)
    ? ''
    : `(${T(PRODUCT_STATUS_TEXT[product_status])})`;

  const creator_price = creatorPriceOfProduct(product_detail);
  const selected_opt_name = selected_option_group ? selected_option_group['title' + _en] : '';
  const bp_name = base_name + (is_sticker && selected_opt_name ? ` - ${selected_opt_name}` : '');
  const goods_type_name = {
    MPS: ET('goods_types::mps'),
    PBG: ET('goods_types::pbg'),
    TPL: ET('goods_types::tpl'),
    DTG: ET('goods_types::dtg'),
  }[goods_type_id];

  const product_type_name = {
    CUSTOM: ET('product_types::custom'),
    HAND_MADE: ET('product_types::handmade'),
    PRINT_ON_DEMAND: ET('product_types::pod'),
  }[String(product_type_id).toUpperCase()];

  isEbookByCateItemId(sp_cate_item_id);

  return html`
    <div class="pd-product">
      <div class="pd-product__seller">
        ${sellerHtml(store)}
        <div class="pd-product__action">
          <!-- 공유버튼 -->
          ${makeProdShareHtml()}
          <!-- 좋아요 버튼 -->
          ${!is_mobile ? makeProdLikeHtml({ product_detail }) : ''}
        </div>
      </div>

      <div class="pd-product__title_area">
        <div class="pd-product__badges">
          ${product_type_name ? html`<div class="pd-product__product_type">${product_type_name}</div>` : ''}
          <div class="pd-product__goods_type ${goods_type_id.toLowerCase()}">${goods_type_name}</div>
        </div>
        ${is_seller && GoodsTypeS.isMps(goods_type_id)
          ? html`<h2 class="pd-product__name">[샘플주문]</h2>`
          : ''}
        <h2 class="pd-product__name pd-product__name--${T.lang}">
          ${status_text ? `<span class='pd-product__status'>${status_text}</span>` : ''}
          ${prod_name || bp_name}
        </h2>
        ${htmlIf2(
          isEbookByCateItemId(sp_cate_item_id),
          () => html`<div
            class="pd-product__ebook-writer ${typoPcMo({ is_mobile, pc: '20_medium', mo: '16_medium' })}"
          >
            ${digital_product._.ebook_info.writer}
          </div>`,
        )}
        <p class="pd-product__bp-name">${GoodsTypeS.isMps(goods_type_id) ? bp_name : category_name}</p>
      </div>

      <p class="pd-product__price">
        <input type="hidden" name="price" value="${creator_price}" />
        <span class="pd-product__price mp_currency ${MShopUtilConstantS.UNICA_KLASS}">
          ${UtilS.commify(creator_price)}
        </span>
      </p>

      ${product_detail.review_meta.count
        ? html`
            <div class="pd-product__review">
              <div class="pd-product__review__star">
                ${MShopAppCommunityTmplS.reviewScore(product_detail.review_meta.score)}
              </div>
              <div class="pd-product__review__count">
                ${ET('product_detail::review::show_more', { count: product_detail.review_meta.count })}
              </div>
            </div>
          `
        : ''}
    </div>
  `;
};

const makeProductInfoHtmlNess = ({ product_detail, is_seller, is_mobile = false }) => {
  const {
    _: { base_product, stores_product, selected_option_group },
    is_public,
    ['name' + _en]: prod_name,
  } = product_detail;

  const { is_use_creator, is_use_creator_en, is_use_creator_jp } = base_product;
  const base_name = base_product['name_creator' + G._en] ?? base_product['name' + G._en];
  const {
    is_prohibited,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    goods_type_id,
    is_pb_mg,
    _: { digital_product },
  } = stores_product;

  const product_status = getProductStatus({
    is_prohibited,
    is_public,
    is_confirmed,
    is_confirm_req,
    is_digital_product: !!digital_product?.id,
    is_check_design,
    is_use_creator,
    is_use_creator_en,
    is_use_creator_jp,
    is_pb_mg,
  });

  const status_text = [PRODUCT_STATUS.PUBLIC, PRODUCT_STATUS.PB_ME].includes(product_status)
    ? ''
    : `(${T(PRODUCT_STATUS_TEXT[product_status])})`;

  const creator_price = creatorPriceOfProduct(product_detail);
  const selected_opt_name = selected_option_group ? selected_option_group['title' + _en] : '';
  const bp_name = base_name + (selected_opt_name ? ` - ${selected_opt_name}` : '');

  // 할인금액
  const pre_discount_price = sel('_.pre_discount_product.pre_discount_price', product_detail);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, creator_price)
    : null;

  // 할인률 없을 때
  const no_discount_price = () => {
    if (MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
      return `<span class="pd-product__price-number mp_currency">${UtilS.commify(creator_price)}</span>`;
    }
    return `
      <span class="pd-product__price-number">
        ${UtilS.commify(creator_price)}
      </span>
    `;
  };

  // 할인률 있을 때
  const discount_price = () => {
    return `
      <span class="pd-product__discount-rate">${pre_discount_rate}%</span>
      <span class="pd-product__price-number mp_currency">${UtilS.commify(creator_price)}</span>
      <span class="pd-product__price-number--deprecated mp_currency">
        <span>${UtilS.commify(pre_discount_price)}</span>
      </span>
    `;
  };

  return html`
    <div class="pd-product">
      <div class="pd-product__seller">
        <div class="pd-product__seller-top">
          <h2 class="pd-product__name pd-product__name--${T.lang}">
            ${status_text ? `<span class='pd-product__status'>${status_text}</span>` : ''}
            ${prod_name || base_name}
          </h2>
          <div class="pd-product__action">
            ${makeProdShareHtml()} ${!is_mobile ? makeProdLikeHtml({ product_detail }) : ''}
          </div>
        </div>
        ${UtilS.htmlIf2(selected_option_group?.title, () => `<p class="pd-product__bp-name">${bp_name}</p>`)}
      </div>
      ${is_seller && GoodsTypeS.isMps(goods_type_id)
        ? html`<h2 class="pd-product__name">[샘플주문]</h2>`
        : ''}
      <div class="pd-product__price">
        <input type="hidden" name="price" value="${creator_price}" />
        ${pre_discount_rate ? discount_price() : no_discount_price()}
      </div>
    </div>
  `;
};

export const sellerHtml = (seller) => {
  if (UtilS.isNessApp()) {
    return sellerHtmlNess(seller);
  }

  /* 여기부터 마플샵 */
  const seller_url = `/${T.lang}/${seller?.domain_name}`;

  return html`
    <div class="pd-seller">
      <div class="pd-seller__info">
        <a
          href="${seller_url}"
          data-post-message="${$dataStr({
            screen: {
              type: 'util',
              title: seller.name,
              titleImage: seller.logo_img,
              uri: seller_url,
            },
          })}"
        >
          <h3 class="pd-seller__name" data-seller_name="${seller.name}">
            ${seller.name} ${MShopCoreTmplS.marppleNewLogoIcon()}
          </h3>
        </a>
      </div>
    </div>
  `;
};

export const sellerHtmlNess = (seller) => {
  const seller_url = `/${T.lang}/${seller?.domain_name}`;

  return html`
    <div class="pd-seller">
      <div class="pd-seller__info">
        <a href="${seller_url}"
           data-post-message="${$dataStr({
             screen: {
               type: 'util',
               title: seller.name,
               titleImage: seller.logo_img,
               uri: seller_url,
             },
           })}"
        >
          <h3 class="pd-seller__name" data-seller_name="${seller.name}">
            <span>${seller.name}</span>
            ${MShopCoreTmplS.arrowRightIcon()}
          </h3>
        </a>
      </div>
      </a>
    </div>
  `;
};

// share 버튼
export const makeProdShareHtml = (is_hidden) => {
  if (UtilS.isNessApp()) {
    return makeProdShareHtmlNess(is_hidden);
  }

  if (is_hidden) {
    return '';
  }
  return html` <button type="button" class="pd__action-btn share">${MShopCoreTmplS.shareIcon()}</button> `;
};

const makeProdShareHtmlNess = (is_hidden) => {
  if (is_hidden) return '';

  return html`
    <button type="button" class="pd__action-btn share">${MShopCoreTmplS.nessShareIcon()}</button>
  `;
};

// 좋아요 버튼
export const makeProdLikeHtml = ({ product_detail, is_mobile_bottom }) => {
  if (UtilS.isNessApp()) {
    return makeProdLikeHtmlNess({ product_detail });
  }
  const {
    _: { stores_products_like },
    id: stores_product_id,
  } = sel('_.stores_product', product_detail);
  const already_like = !!stores_products_like?.id;

  return html`
    <button
      type="button"
      class="pd__action-btn like ${already_like ? 'active' : ''} ${is_mobile_bottom ? 'in_mobile_form' : ''}"
      data-stores_product_id="${stores_product_id}"
    >
      ${MShopCoreTmplS.heartIconV2()}
    </button>
  `;
};

const makeProdLikeHtmlNess = ({ product_detail }) => {
  const {
    _: { stores_products_like },
    id: stores_product_id,
  } = sel('_.stores_product', product_detail);
  const already_like = !!stores_products_like?.id;

  return html`
    <button
      type="button"
      class="pd__action-btn like${already_like ? ' active' : ''}"
      data-stores_product_id="${stores_product_id}"
    >
      ${MShopCoreTmplS.nessLikeIcon()}
    </button>
  `;
};

// 라디오 타입 옵션
export const makeRadioOptions = ({ name, type = 'value', options, disabled }) => {
  if (UtilS.isNessApp()) {
    return makeRadioOptionsNess({ name, type, options });
  }

  const getBorderColor = () => {
    return `style="border-color: ${MShopCoreConstantS.colors.blue}"`;
  };
  const checkDisabled = (item) => (item.disabled ? 'disabled' : '');
  const getFaceContent = (item) =>
    (type == 'preview' &&
      `<img src="${item.preview}" alt="${item.label}" class="pd-radio__face--preview" />`) ||
    (type == 'color' &&
      `<span style="background: ${
        item.color2
          ? `linear-gradient(135deg, ${
              item.color == '#ffffff' || item.color == '#fefefe' ? '#f8f8f8' : item.color
            } 50%, ${item.color2 == '#ffffff' || item.color2 == '#fefefe' ? '#f8f8f8' : item.color2} 50%)`
          : item.color
      }" class="pd-radio__face--color"></span>`) ||
    `<span class="pd-radio__face--value">${item.name}</span>`;

  return html`
    <div class="pd-radio-group ${type}">
      ${go(
        options,
        zipWithIndexL,
        strMap(([, item]) => {
          return item.is_public
            ? html`
                <label class="pd-radio ${type} ${checkDisabled(item)}" ${getBorderColor(item)}>
                  <input
                    type="radio"
                    name="${name}"
                    ${disabled ? 'disabled' : ''}
                    ${checkDisabled(item)}
                    ${item.is_active ? 'checked' : ''}
                    data-price="${item.price || 0}"
                    value="${item.value}"
                  />
                  <span class="pd-radio__face ${type} ${checkDisabled(item)}" title="${item.label}">
                    ${getFaceContent(item)}
                  </span>
                  <span class="pd-radio__text" title="${item.label}">${item.label}</span>
                </label>
              `
            : '';
        }),
      )}
      ${type === 'color'
        ? html`
            <button type="button" class="expand-btn pd-radio" ${disabled ? 'disabled' : ''}>
              <span class="pd-radio__face">
                <span class="pd-radio__face--color"></span>
              </span>
              <span class="pd-radio__text">${ET('mps2::gnb::more')}</span>
            </button>
          `
        : ''}
    </div>
  `;
};

const makeRadioOptionsNess = ({ name, type = 'value', options }) => {
  const getBorderColor = (item) => {
    if (!item.color) return '';
    let border_color = item.color;
    if (item.color.indexOf('gradient') > -1) {
      border_color = item.color.match(/(#[a-z0-9])\w+/g)[0];
    }
    return `style="border-color: ${border_color}"`;
  };
  const checkDisabled = (item) => (item.disabled ? 'disabled' : '');
  const getFaceContent = (item) =>
    (type == 'preview' &&
      `<img src="${item.preview}" alt="${item.label}" class="pd-radio__face--preview" />`) ||
    (type == 'color' &&
      `<span style="background: ${
        item.color2
          ? `linear-gradient(135deg, ${
              item.color == '#ffffff' || item.color == '#fefefe' ? '#f8f8f8' : item.color
            } 50%, ${item.color2 == '#ffffff' || item.color2 == '#fefefe' ? '#f8f8f8' : item.color2} 50%)`
          : item.color
      }" class="pd-radio__face--color"></span>`) ||
    `<span class="pd-radio__face--value">${item.name}</span>`;

  return html`
    <div class="pd-radio-group ${type}">
      ${strMap(
        (item) =>
          item.is_public
            ? html`
                <label class="pd-radio ${type} ${checkDisabled(item)}" ${getBorderColor(item)}>
                  <input
                    type="radio"
                    name="${name}"
                    ${checkDisabled(item)}
                    ${item.is_active ? 'checked' : ''}
                    data-price="${item.price || 0}"
                    value="${item.value}"
                  />
                  <span class="pd-radio__face ${type} ${checkDisabled(item)}" title="${item.label}">
                    ${getFaceContent(item)}
                  </span>
                  ${UtilS.htmlIf(
                    item.label,
                    `<span class="pd-radio__text" title="${item.label}">${item.label}</span>`,
                  )}
                </label>
              `
            : '',
        options,
      )}
    </div>
  `;
};

// 셀렉트 타입 옵션
export const makeSelectOptions = (type, options, disabled) => {
  return html`
    <select name="${type}" class="pd-select" ${disabled ? 'disabled' : ''}>
      ${strMap(
        (opt) => `
        <option value='${opt.value}' ${addAttrIf(opt.selected, 'selected')}>${opt.label}</option>
      `,
        options,
      )}
    </select>
  `;
};

// 색상 옵션
export const makeProdColorOptionsHtml = ({ product_detail, selected_bpc, is_mobile }) => {
  if (UtilS.isNessApp()) {
    return makeProdColorOptionsHtmlNess({ product_detail, selected_bpc });
  }

  const base_product = sel('_.base_product', product_detail);
  const base_product_colors = sel('_.base_product._.base_product_colors', product_detail);
  const store_product_colors = sel('_.stores_product._.store_product_colors', product_detail);
  const selected_bpc_id = selected_bpc?.id;
  const colors = getAvailableColors(base_product_colors, store_product_colors);
  const options = go(
    colors,
    map((item) => ({
      value: item.id,
      label: item['name' + _en],
      color: item.color_code,
      color2: item.color_code2,
      is_public: item.is_public,
      is_active: item.id === selected_bpc_id,
    })),
    sortBy((item) => !item.is_active),
  );

  const use_label = isNeedPreview(base_product);
  const option_label = use_label ? messages.preview_by_device : messages.color;
  const max_count = is_mobile ? 8 : 16;

  return html`
    <div class="pd-field colors ${colors.length > max_count ? 'fold' : ''}">
      <div class="pd-field__label ${use_label ? 'visible' : ''}">${T(option_label)}</div>
      <div class="dynamic-html">${makeRadioOptions({ name: 'color', type: 'color', options })}</div>
    </div>
  `;
};

const makeProdColorOptionsHtmlNess = ({ product_detail, selected_bpc }) => {
  const base_product = sel('_.base_product', product_detail);
  const base_product_colors = sel('_.base_product._.base_product_colors', product_detail);
  const store_product_colors = sel('_.stores_product._.store_product_colors', product_detail);
  const selected_bpc_id = selected_bpc.id;

  const colors = getAvailableColors(base_product_colors, store_product_colors);
  const options = go(
    colors,
    map((item) => ({
      value: item.id,
      label: item['name' + _en],
      color: item.color_code,
      color2: item.color_code2,
      is_public: item.is_public,
      is_active: item.id === selected_bpc_id,
    })),
    sortBy((item) => !item.is_active),
  );

  const use_label = isNeedPreview(base_product);
  const option_label = use_label
    ? ET('product_detail::options::preview_by_device')
    : ET('product_detail::options::color');

  return html`
    <div class="pd-field colors">
      <div class="pd-field__label ${use_label ? 'visible' : ''}">${option_label}</div>
      <div class="dynamic-html folded">
        ${makeRadioOptions({ name: 'color', type: 'color', options })}
        <button type="button" class="expand-btn"></button>
      </div>
    </div>
  `;
};

// 사이즈 선택 버튼
export const makeSizeOptionsHtml = ({ use_size_option, selected_bpc, disabled, is_multi_option }) => {
  if (UtilS.isNessApp()) {
    return makeSizeOptionsHtmlNess({ use_size_option, selected_bpc });
  }

  if (!use_size_option) return '';

  const { base_product_sizes2, select_size_id = '' } = sel('_', selected_bpc) || {};

  const options = map((item) => {
    const price = getByLang('price', item);
    const label = item._is_not_stock
      ? T(messages.sold_out)
      : PriceS.price(price)
      ? `+${PriceS.price(getByLang('price', item))}`
      : '';
    return {
      label,
      value: item.id,
      name: getByLang('name', item),
      price: getByLang('price', item),
      disabled: item._is_not_stock,
      is_public: item.is_public,
      is_active: is_multi_option ? false : select_size_id == item.id, // 멀티 옵션인 경우 active 처리 하지 않는다.
    };
  }, base_product_sizes2);

  if (!find((opt) => opt.is_active, options)) {
    const index = findIndex((opt) => !opt.disabled, options);
    if (options[index] && !is_multi_option) options[index].is_active = true; // 멀티 옵션인 경우 active 처리 하지 않는다.
  }

  if (UtilS.isNessApp()) {
    return html`
      <div class="pd-field size">
        <div class="pd-field__label">${ET('product_detail::options::size')}</div>
        <div>
          <div class="dynamic-html">${makeRadioOptions({ name: 'size', options })}</div>
          ${UtilS.htmlIf2(use_size_option, makeSizeGuideHtml)}
        </div>
      </div>
    `;
  }

  return html`
    <div class="pd-field size">
      <div class="pd-field__label">${T(messages.size)}</div>
      <div>
        <div class="dynamic-html">${makeRadioOptions({ name: 'size', options, disabled })}</div>
      </div>
    </div>
  `;
};

export const makeSizeOptionsHtmlNess = ({ use_size_option, selected_bpc }) => {
  if (!use_size_option) return '';
  const { base_product_sizes2, select_size_id = '' } = sel('_', selected_bpc) || {};
  const options = map((item) => {
    const price = getByLang('price', item);
    const label = item._is_not_stock
      ? ET('common::sold_out')
      : PriceS.price(price)
      ? `+${PriceS.price(getByLang('price', item))}`
      : '';
    return {
      label,
      value: item.id,
      name: getByLang('name', item),
      price: getByLang('price', item),
      disabled: item._is_not_stock,
      is_public: item.is_public,
      is_active: select_size_id == item.id,
    };
  }, base_product_sizes2);

  if (!find((opt) => opt.is_active, options)) {
    const index = findIndex((opt) => !opt.disabled, options);
    if (options[index]) options[index].is_active = true;
  }

  return html`
    <div class="pd-field size">
      <div class="pd-field__label">${ET('product_detail::options::size')}</div>
      <div>
        <div class="dynamic-html">${makeRadioOptions({ name: 'size', options })}</div>
        ${UtilS.htmlIf2(use_size_option, makeSizeGuideHtml)}
      </div>
    </div>
  `;
};

// 디바이스 선택
export const makeDeviceSelectorHtml = (devices, default_id) => {
  const select_options = go(
    devices,
    reject(({ is_composite_publish }) => is_composite_publish),
    map((item) => ({
      label: item.name,
      value: item.id,
      thumb: item.model_thumb_url,
      selected: item.id === default_id,
    })),
  );

  return html`
    <div class="pd-field">
      <div class="pd-field__label">${T(messages.device)}</div>
      ${makeSelectOptions('device', select_options)}
    </div>
  `;
};

// 사이즈 가이드
export const makeSizeGuideHtml = () => {
  const is_ness = UtilS.isNessApp();
  return html`
    <div class="pd-guide ruler">
      <span class="pd-guide__icon">${MShopUtilConstantS.svg.ruler}</span>
      <a class="pd-guide__text toggle-size-modal">${T(messages.check_size_guide)}</a>
      ${UtilS.htmlIf2(
        !is_ness,
        () => html`<a class="pd-guide__anchor toggle-size-modal">${MShopUtilConstantS.svg.arrow_right}</a>`,
      )}
    </div>
  `;
};

// 현대 주문 가이드
export const makeOrderGuideHtml = ({ product_detail }) => {
  const now = Date.now();
  const { shipping_date_hidden } = sel('_.stores_product', product_detail);

  const deliver_date = go(now, UtilS.getSalesPeriodOf, sel('sell_end_at'), addDays(3));
  const deliver_until_date = addDays(7)(deliver_date);

  if (shipping_date_hidden) return '';

  return html`
    <div class="pd-guide">
      <span class="pd-guide__icon">${MShopUtilConstantS.svg.box}</span>
      <span class="pd-guide__text">
        ${T(messages.expect, {
          start: format(deliver_date, 'M/d'),
          end: format(deliver_until_date, 'M/d'),
        })}
      </span>
    </div>
  `;
};

// 타이머
export const makeProductSellingTimeHtml = ({ day, hour, minute, second }) => {
  if (UtilS.isNessApp()) {
    return makeProductSellingTimeHtmlNess({ day, hour, minute, second });
  }

  const _day = Math.max(0, day);
  const _hour = String(Math.max(0, hour)).padStart(2, '0');
  const _minute = String(Math.max(0, minute)).padStart(2, '0');
  const _second = String(Math.max(0, second)).padStart(2, '0');
  return `${_day ? _day + 'D ' : ''}${_hour}:${_minute}:${_second}`;
};

// Ness 타이머
const makeProductSellingTimeHtmlNess = ({ day, hour, minute, second }) => {
  return `${day}D ${hour}H ${minute}M ${second}S`;
};

export const makePossibleQuantityHtml = (max_purchase_per_user, possible_quantity) => {
  if (!max_purchase_per_user) return '';
  let text = max_purchase_per_user
    ? ET('product_detail::max_purchase_per_user', { count: max_purchase_per_user })
    : '';

  if (possible_quantity === 0) {
    text += `<br/>${ET('product_detail::already_possible_quantity')}`;
  } else if (max_purchase_per_user !== possible_quantity) {
    text += `<br/>${ET('product_detail::possible_quantity', { count: possible_quantity })}`;
  }

  return html`
    <div class="pd-field__max_count">
      <div class="pd-field__max_count_icon">${MShopCoreTmplS.boxIcon()}</div>
      <div class="pd-field__max_count_text">${text}</div>
    </div>
  `;
};

export const makeTotalPriceInMobileFormHtml = (
  { count = 1, product_detail, is_dtg },
  { possible_quantity, max_purchase_per_user, is_hide, is_multi_option = false },
) => {
  const tot_price = count * creatorPriceOfProduct(product_detail);

  if (is_multi_option && (!max_purchase_per_user || is_dtg)) {
    return '';
  }

  return html`
    <div class="pd-field direction-column">
      ${UtilS.htmlIf2(
        !is_multi_option,
        () =>
          html`
            <div class="pd-field__count-price ">
              ${is_dtg
                ? html` <input type="hidden" name="quantity" readonly value="1" /> `
                : html`
                    <div class="pd-field__row pd-field__pd-counter-wrap ${is_hide ? 'hide' : ''}">
                      <div class="pd-field__label">${T(messages.quantity)}</div>
                      <div class="pd-counter ${is_hide ? 'disabled' : ''}">
                        <button type="button" class="pd-counter__btn down" disabled>
                          ${MShopCoreTmplS.minusIcon()}
                        </button>
                        <input
                          type="text"
                          name="quantity"
                          readonly
                          class="pd-counter__current"
                          value="${count}"
                          ${is_hide ? 'disabled' : ''}
                        />
                        <button
                          type="button"
                          class="pd-counter__btn up"
                          ${is_hide || possible_quantity <= 1 ? 'disabled' : ''}
                        >
                          ${MShopCoreTmplS.plusIcon()}
                        </button>
                      </div>
                    </div>
                  `}
              <div class="pd-total ${is_dtg || is_hide ? 'hide' : ''}  pd-field__row">
                <div class="pd-total__count">${T('order::총 상품 금액')}</div>
                <div class="pd-total__price mp_currency">${UtilS.commify(tot_price)}</div>
              </div>
            </div>
          `,
      )}
      <!-- 구매 가능 수량 안내 -->
      ${UtilS.htmlIf2(max_purchase_per_user && !is_dtg, () =>
        makePossibleQuantityHtml(max_purchase_per_user, possible_quantity),
      )}
    </div>
  `;
};

// 총 수량 및 금액
export const makeTotalPriceHtml = (
  { count = 1, product_detail, is_dtg },
  { disabled, possible_quantity, max_purchase_per_user, is_hide, is_multi_option = false } = {},
) => {
  if (UtilS.isNessApp()) {
    return makeTotalPriceHtmlNess({ count, product_detail });
  }

  const tot_price = count * creatorPriceOfProduct(product_detail);

  if (is_multi_option && (!max_purchase_per_user || is_dtg)) {
    return '';
  }

  return html`
    <div class="pd-field">
      <!-- multi option이 불가한 경우에만 기존 price html을 그린다. -->
      <!-- is_multi_option = true인 경우엔 price를 multi option과 함께 프론트에서 그려준다. -->
      ${UtilS.htmlIf2(
        !is_multi_option,
        () =>
          html`
            <div class="pd-field__count-price ${is_hide ? 'hide' : ''}">
              ${is_dtg
                ? html`<input type="hidden" name="quantity" readonly value="1" />`
                : html`
                    <div class="pd-field__pd-counter-wrap">
                      <div class="pd-field__label count_label">${T(messages.quantity)}</div>
                      <div class="pd-counter ${disabled || is_hide ? 'disabled' : ''}">
                        <button type="button" class="pd-counter__btn down" disabled>
                          ${MShopCoreTmplS.minusIcon()}
                        </button>
                        <input
                          type="text"
                          name="quantity"
                          readonly
                          class="pd-counter__current"
                          value="${count}"
                          ${disabled || is_hide ? 'disabled' : ''}
                        />
                        <button
                          type="button"
                          class="pd-counter__btn up"
                          ${disabled || is_hide || possible_quantity <= 1 ? 'disabled' : ''}
                        >
                          ${MShopCoreTmplS.plusIcon()}
                        </button>
                      </div>
                    </div>
                  `}
              <div class="pd-total ${is_dtg || is_hide ? 'hide' : ''}">
                <div class="pd-total__count">${ET('product_detail::total_price')}</div>
                <div class="pd-total__price mp_currency">${UtilS.commify(!disabled ? tot_price : 0)}</div>
              </div>
            </div>
          `,
      )}
      ${UtilS.htmlIf2(max_purchase_per_user && !is_dtg, () =>
        makePossibleQuantityHtml(max_purchase_per_user, possible_quantity),
      )}
    </div>
  `;
};

// 총 수량 및 금액
const makeTotalPriceHtmlNess = ({ count = 1, product_detail }) => {
  const tot_price = count * creatorPriceOfProduct(product_detail);
  return html`
    <div class="pd-total">
      <div class="pd-total__count">${ET('product_detail::total_label', { count: UtilS.commify(count) })}</div>
      <div class="pd-total__price mp_currency dynamic-value">${UtilS.commify(tot_price)}</div>
    </div>
  `;
};

export const makeTotalPriceHtmlNessCustom = ({ count = 1, product_detail }) => {
  const product_price = calcSizePrice(product_detail, _en);
  const tot_price = count * product_price;

  return html`
    <div class="pd-total">
      <div class="pd-total__count">${ET('product_detail::total_label', { count: UtilS.commify(count) })}</div>
      <div class="pd-total__price mp_currency dynamic-value">${UtilS.commify(tot_price)}</div>
    </div>
  `;
};

export const makeHyundaiNPackageHtml = ({ hyundai_n_products }) => {
  const { t_shirt_product, promotional_products } = hyundai_n_products;

  return html`
    <div class="pd-package">
      <div id="t_shirt_proeduct">${makeTShirtsHtml(t_shirt_product)}</div>
      <div id="promotional_products">${makePromotionalProductsHtml(promotional_products)}</div>
    </div>
  `;
};

export const makeCounterHtml = (init_count = 1, is_hide) => {
  return html`
    <div class="pd-field counter" ${is_hide ? 'style="display:none;"' : ''}>
      <div class="pd-field__label">${T(messages.quantity)}</div>
      <div class="pd-counter">
        <button type="button" class="pd-counter__btn down">${MShopUtilConstantS.svg.minus}</button>
        <input type="text" name="quantity" readonly class="pd-counter__current" value="${init_count}" />
        <button type="button" class="pd-counter__btn up">${MShopUtilConstantS.svg.plus}</button>
      </div>
    </div>
  `;
};

const makeMaxPurchaseMore = ({ max_purchase_per_user, possible_quantity }) => {
  if (max_purchase_per_user == possible_quantity) {
    return '';
  } else if (possible_quantity > 0) {
    return T(messages.possible_quantity, { possible_quantity });
  } else {
    return T(messages.already_possible_quantity);
  }
};

// 구매 수량 제한 (1인당) 인풋
const makeMaxPurchaseCounterHtml = (possible_quantity, max_purchase_per_user, is_hide) => {
  const is_ness = UtilS.isNessApp();
  return html`
    <div class="pd__quantity">
      <div class="pd-field counter" ${is_hide ? 'style="display:none;"' : ''}>
        <div class="pd-field__label">${T(messages.quantity)}</div>
        <div class="pd-counter${possible_quantity > 0 ? '' : ' pd-counter--none'}">
          <button type="button" class="pd-counter__btn down" disabled>${MShopUtilConstantS.svg.minus}</button>
          <input type="text" name="quantity" readonly class="pd-counter__current" value="${1}" />
          <button type="button" class="pd-counter__btn up">${MShopUtilConstantS.svg.plus}</button>
        </div>
      </div>

      <div class="pd__max-purchase-per-user" ${is_hide ? 'style="display:none;"' : ''}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Component 2">
            <g id="Group 1474">
              <rect id="Rectangle 440" x="4" y="6" width="12" height="11" rx="1.5" stroke="#F0415A" />
              <path
                id="Rectangle 441"
                d="M12.75 8.5V5.25C12.75 3.73122 11.5188 2.5 10 2.5V2.5C8.48122 2.5 7.25 3.73122 7.25 5.25V8.5"
                stroke="#F0415A"
                stroke-linecap="round"
              />
            </g>
          </g>
        </svg>
        ${T(messages.max_purchase_per_user, { max_purchase_per_user })}
        ${UtilS.htmlIf2(
          !is_ness,
          () => html`<div>${makeMaxPurchaseMore({ max_purchase_per_user, possible_quantity })}</div>`,
        )}
      </div>
    </div>
  `;
};

// TODO @yjj 안쓰이면 지우자
export const makeProductQuantityHtml = (possible_quantity, max_purchase_per_user, is_hide) => {
  if (UtilS.isNessApp()) {
    return makeProductQuantityHtmlNess(possible_quantity, max_purchase_per_user, is_hide);
  }
  return max_purchase_per_user
    ? makeMaxPurchaseCounterHtml(possible_quantity, max_purchase_per_user, is_hide)
    : makeCounterHtml(1, is_hide);
};

export const makeProductQuantityHtmlNess = (possible_quantity, max_purchase_per_user, is_hide) => {
  return html`
    <div class="pd__quantity" ${is_hide ? 'style="display:none;"' : ''}s>
      <div class="pd-field counter">
        <div class="pd-field__label">Quantity</div>
        <div class="pd-counter${possible_quantity > 0 ? '' : ' pd-counter--none'}">
          <button type="button" class="pd-counter__btn down">${MShopUtilConstantS.svg.minus}</button>
          <input type="text" name="quantity" readonly class="pd-counter__current" value="${1}" />
          <button type="button" class="pd-counter__btn up">${MShopUtilConstantS.svg.plus}</button>
        </div>
      </div>
      ${UtilS.htmlIf2(
        max_purchase_per_user,
        () => `
        <div class="pd__max-purchase-per-user">
          ${MShopCoreTmplS.stockIcon()}
          ${T(messages.max_purchase_per_user, { max_purchase_per_user })}
        </div>`,
      )}
    </div>
  `;
};

export const makeNotiHtml = (props) => {
  if (UtilS.isNessApp()) {
    return makeNotiHtmlNess(props);
  }

  /* 여기부터 마플샵 */
  const { product_detail, sales_info } = props;
  const {
    _: {
      stores_product: { sell_start_at, sell_end_at, quantity, order_count },
    },
  } = product_detail;
  const left_count = quantity - order_count < 0 ? 0 : quantity - order_count;

  const { has_period_limit, show_quantity_limit } = sales_info;
  const is_start_selling = +new Date() > +new Date(sell_start_at);
  const remain_time_infos = differenceTimeInUnits(
    ['day', 'hour', 'minute', 'second', 'millisecond'],
    is_start_selling ? new Date(sell_end_at) : new Date(sell_start_at),
    new Date(),
    true,
  );

  if (has_period_limit || (left_count && show_quantity_limit)) {
    return html`
      <div class="pd-noti-container">
        <div class="pd-noti-container__triangle">${MShopCoreTmplS.bubbleTriangleIcon()}</div>
        <div
          class="pd-noti"
          data-start-at="${sell_start_at}"
          data-end-at="${sell_end_at}"
          data-current="${has_period_limit ? 'period' : 'quantity'}"
        >
          ${UtilS.htmlIf2(
            has_period_limit,
            () => html`
              <div class="pd-noti__label period" data-item_name="period_limit">
                ${MShopCoreTmplS.clockIcon()}
                <div class="remain_time">
                  ${ET('product_detail::left_time', {
                    time: makeProductSellingTimeHtml(remain_time_infos),
                  })}
                </div>
              </div>
            `,
          )}
          ${UtilS.htmlIf2(
            left_count && !has_period_limit,
            () => html`
              <div class="pd-noti__label quantity" data-item_name="quantity_limit">
                ${ET('product_detail::left_count', { count: commify(left_count) })}
              </div>
            `,
          )}
        </div>
      </div>
    `;
  } else {
    return '';
  }
};

const makeNotiHtmlNess = (props) => {
  const { product_detail, sales_info } = props;
  const {
    _: {
      stores_product: { sell_start_at, sell_end_at, quantity, order_count },
    },
  } = product_detail;
  const left_count = quantity - order_count < 0 ? 0 : quantity - order_count;
  const { has_period_limit, show_quantity_limit, is_before_sale, is_after_sale } = sales_info;
  const is_start_selling = +new Date() > +new Date(sell_start_at);
  const remain_time_infos = differenceTimeInUnits(
    ['day', 'hour', 'minute', 'second', 'millisecond'],
    is_start_selling ? new Date(sell_end_at) : new Date(sell_start_at),
    new Date(),
    true,
  );

  if (has_period_limit || (left_count && show_quantity_limit)) {
    return html`
      <div class="pd-noti-wrapper">
        <div
          class="pd-noti ${is_before_sale ? 'is-before' : ''}"
          interval-count="1"
          current-mode="${has_period_limit ? 'period' : 'quantity'}"
          data-has_period="${has_period_limit}"
          data-has_quantity="${left_count && show_quantity_limit}"
        >
          ${UtilS.htmlIf2(
            has_period_limit,
            () => `
              <div class="pd-noti__label period" data-item_name="period_limit">
                ${
                  is_after_sale
                    ? MShopCoreTmplS.sandClockIcon() +
                      ET('product_detail::noti::end_of_sale', {
                        start_at: format(sell_start_at, T.lang == 'kr' ? 'yyyy/MM/dd' : 'yyyy/MM/dd'),
                        end_at: format(sell_end_at, T.lang == 'kr' ? 'yyyy/MM/dd' : 'yyyy/MM/dd'),
                      })
                    : !is_before_sale
                    ? MShopCoreTmplS.sandClockIcon() +
                      ET('product_detail::noti::until_close', {
                        start_at: sell_start_at,
                        end_at: sell_end_at,
                        remain_time: makeProductSellingTimeHtml(remain_time_infos),
                      })
                    : ET('product_detail::noti::until_open', {
                        start_at: sell_start_at,
                        end_at: sell_end_at,
                        remain_time: makeProductSellingTimeHtml(remain_time_infos),
                      })
                }
              </div>
            `,
          )}
          ${UtilS.htmlIf2(
            left_count && show_quantity_limit,
            () => `
              <div class="pd-noti__label quantity" data-item_name="quantity_limit">
                ${MShopCoreTmplS.cartIcon()}${' '}
                ${ET('product_detail::noti::left', { left: commify(left_count) })}
              </div>
            `,
          )}
        </div>
      </div>
    `;
  } else {
    return '';
  }
};

export const makeFormSubmitBtnInfo = ({
  can_selling,
  sales_info,
  is_digital_product,
  isApp,
  product_status,
  is_purchased_dtg,
}) => {
  if (UtilS.isNessApp()) {
    return makeFormSubmitBtnInfoNess({
      can_selling,
      sales_info,
      is_digital_product,
      isApp,
      product_status,
      is_purchased_dtg,
    });
  }

  const { is_before_sale, is_after_sale, is_sold_out, is_selling_product } = sales_info;
  const is_disabled =
    !can_selling || // 스토어의 판매상태
    is_sold_out || // 스토어 오너가 아니고, 솔드아웃인 경우
    !is_selling_product || // 스토어 오너가 아니고, 판매중이 아닌경우
    (isApp && is_digital_product) || // 디지털 상품이고, 환경이 앱인경우
    (is_digital_product && ![PRODUCT_STATUS.PB_ME, PRODUCT_STATUS.PUBLIC].includes(product_status)) || // 디지털상품이고, 상품의 상태가 공개중이 아닌경우
    (is_digital_product && is_purchased_dtg); // 디지털 상품이고 구매하고 7일이 지나지 않은 경우

  return go(
    void 0,
    () => {
      if (!can_selling) return ET('product_detail::cta::not_approve');
      else if (is_sold_out) return ET('product_detail::cta::sold_out');
      else if (is_before_sale) return ET('product_detail::cta::coming_soon');
      else if (is_after_sale) return ET('product_detail::cta::end_sale');
      else if (isApp && is_digital_product) return ET('product_detail::cta::only_pc');
      else if (is_digital_product && is_purchased_dtg) return ET('product_detail::cta::purchased_dtg');
      return ET('product_detail::cta::cart');
    },
    (btn_text) => {
      return {
        is_disabled,
        btn_text,
      };
    },
  );
};

const makeFormSubmitBtnInfoNess = ({
  can_selling,
  sales_info,
  is_digital_product,
  isApp,
  product_status,
  is_purchased_dtg,
}) => {
  const { is_before_sale, is_after_sale, is_sold_out, is_selling_product } = sales_info;
  const is_disabled =
    !can_selling || // 스토어의 판매상태
    is_sold_out || // 스토어 오너가 아니고, 솔드아웃인 경우
    !is_selling_product || // 스토어 오너가 아니고, 판매중이 아닌경우
    (isApp && is_digital_product) || // 디지털 상품이고, 환경이 앱인경우
    (is_digital_product && ![PRODUCT_STATUS.PB_ME, PRODUCT_STATUS.PUBLIC].includes(product_status)) || // 디지털상품이고, 상품의 상태가 공개중이 아닌경우
    (is_digital_product && is_purchased_dtg); // 디지털 상품이고 구매하고 7일이 지나지 않은 경우

  return go(
    void 0,
    () => {
      if (!can_selling) return ET('product_detail::not_approve');
      else if (is_sold_out) return ET('product_detail::sold_out_products');
      else if (is_before_sale) return ET('product_detail::coming_soon');
      else if (is_after_sale) return ET('product_detail::end_sale');
      else if (isApp && is_digital_product) return ET('product_detail::only_pc');
      else if (is_digital_product && is_purchased_dtg) return ET('product_detail::purchased_dtg');
      return ET('product_detail::cart');
    },
    (btn_text) => {
      return {
        is_disabled,
        btn_text,
      };
    },
  );
};

const makeSubmitButtonText = ({ product_detail, status, btn_text }) => {
  if (status === 'COMING_SOON') {
    const {
      _: {
        stores_product: { sell_start_at, sell_end_at },
      },
    } = product_detail;
    const is_start_selling = +new Date() > +new Date(sell_start_at);
    const remain_time_infos = differenceTimeInUnits(
      ['day', 'hour', 'minute', 'second', 'millisecond'],
      is_start_selling ? new Date(sell_end_at) : new Date(sell_start_at),
      new Date(),
      true,
    );
    return html`
      <div
        class="pd-noti text"
        data-start-at="${sell_start_at}"
        data-end-at="${sell_end_at}"
        data-current="period"
      >
        <div class="pd-noti__label period" data-item_name="period_limit">
          ${MShopCoreTmplS.rocketIcon()}
          <div class="remain_time">
            ${ET('product_detail::coming_soon', {
              time: makeProductSellingTimeHtml(remain_time_infos),
            })}
          </div>
        </div>
      </div>
    `;
  } else {
    return btn_text;
  }
};

const pcSubmit = ({ props, btn_text, status, disabled, is_only_buy_now }) => {
  if (UtilS.isNessApp()) {
    return pcSubmitNess({ props, btn_text });
  }

  /* 여기부터 마플샵 */
  const { product_detail } = props;

  const submit_btn_text = makeSubmitButtonText({ product_detail, btn_text, status });

  return html`
    <div class="pd-submit">
      <div class="pd-submit__btns">
        ${
          /* 강제 무료배송 상품일 경우 */
          is_only_buy_now
            ? ''
            : html`<button
                class="pd-submit__btn pd-submit__btn--cart"
                type="button"
                ${disabled ? 'disabled' : ''}
                data-action="cart"
              >
                ${submit_btn_text}
              </button>`
        }
        ${UtilS.htmlIf2(
          is_only_buy_now || !disabled,
          () => html`
            <div class="pd-submit__submit_btn_wrapper">
              <!-- 말풍선 -->
              ${!disabled ? makeNotiHtml(props) : ''}
              <button
                style="background: ${MShopCoreConstantS.colors.blue}; color: ${MShopCoreConstantS.colors
                  .white};"
                class="pd-submit__btn"
                type="button"
                ${disabled ? 'disabled' : ''}
                data-action="checkout"
              >
                ${disabled ? submit_btn_text : ET('product_detail::cta::buy_now')}
              </button>
            </div>
          `,
        )}
      </div>
    </div>
  `;
};

const pcSubmitNess = ({ props, btn_text }) => {
  const { product_detail, sales_info } = props;

  return html`
    <div class="pd-submit">
      ${makeNotiHtml({ product_detail, sales_info })}
      <div class="pd-submit__btns">
        <button class="pd-submit__btn pd-submit__btn--cart" type="button" data-action="cart">
          ${btn_text}
        </button>
        <button class="pd-submit__btn" type="button" data-action="checkout">
          ${ET('product_detail::buy')}
        </button>
      </div>
    </div>
  `;
};

export const submitDisabledBtn = (btn_text) => {
  return html`<button class="pd-submit__btn" disabled type="button">${btn_text}</button>`;
};

const mobileSubmit = ({ props, props: { product_detail }, btn_text, status, disabled, is_only_buy_now }) => {
  if (UtilS.isNessApp()) {
    return mobileSubmitNess({ props, btn_text });
  }
  const submit_btn_text = makeSubmitButtonText({ product_detail, btn_text, status });

  return html` <div class="pd-submit">
    <div class="pd-submit__action">${makeProdLikeHtml({ product_detail, is_mobile_bottom: true })}</div>
    <div class="pd-submit__btns">
      <div class="pd-submit__submit_btn_wrapper">
        ${!disabled ? makeNotiHtml(props) : ''}
        <button
          style="background: ${MShopCoreConstantS.colors.blue}; color: ${MShopCoreConstantS.colors.white};"
          ${disabled ? 'disabled' : ''}
          class="pd-submit__btn pd-submit__btn--form"
          type="button"
        >
          ${disabled
            ? submit_btn_text
            : is_only_buy_now
            ? ET('product_detail::cta::buy_now')
            : ET('product_detail::cta::buy')}
        </button>
      </div>

      ${UtilS.htmlIf2(
        !disabled,
        () =>
          html`${
              /* 강제 무료배송 상품일 경우 */
              is_only_buy_now
                ? ''
                : html`<button
                    style="display: none;"
                    class="pd-submit__btn pd-submit__btn--cart"
                    type="button"
                    data-action="cart"
                  >
                    ${btn_text}
                  </button>`
            }
            <button
              style="display: none; background: ${MShopCoreConstantS.colors.blue}; color: ${MShopCoreConstantS
                .colors.white};"
              class="pd-submit__btn"
              type="button"
              data-action="checkout"
            >
              ${ET('product_detail::cta::buy_now')}
            </button>`,
      )}
    </div>
  </div>`;
};

const mobileSubmitNess = ({ props: { product_detail }, btn_text }) => {
  return html`
    <div class="pd-submit">
      <div class="pd-submit__action">${makeProdLikeHtml({ product_detail })}</div>
      <div class="pd-submit__btns">
        <button class="pd-submit__btn pd-submit__btn--form" type="button">
          ${ET('product_detail::buy')}
        </button>
        <button
          style="display: none;"
          class="pd-submit__btn pd-submit__btn--cart"
          type="button"
          data-action="cart"
        >
          ${btn_text}
        </button>
        <button style="display: none;" class="pd-submit__btn" type="button" data-action="checkout">
          ${ET('product_detail::buy')}
        </button>
      </div>
    </div>
  `;
};

// 장바구니 버튼
export const makeFormSubmitBtn = (props) => {
  if (UtilS.isNessApp()) {
    return makeFormSubmitBtnNess(props);
  }

  const { is_mobile, product_detail, sales_info, isApp, store /* login_user */ } = props;
  const {
    _: { stores_products_token_gates },
  } = product_detail._.stores_product;

  const disabled = checkIsDisabled({
    isApp,
    product_detail,
    store,
    sales_info,
  });

  const status = checkProductSaleStatus({
    isApp,
    product_detail,
    store,
    sales_info,
  });

  const btn_text = {
    ABLE: ET('product_detail::cta::cart'),
    NOT_APPROVE: ET('product_detail::cta::not_approve'),
    SOLD_OUT: ET('product_detail::cta::sold_out'),
    COMING_SOON: ET('product_detail::cta::coming_soon'),
    END_SALE: ET('product_detail::cta::end_sale'),
    ONLY_PC: ET('product_detail::cta::only_pc'),
    PURCHASED_DTG: ET('product_detail::cta::purchased_dtg'),
  }[status];

  /*
   * 바로 구매하기 버튼만 등장하는 경우
   * 1. stores_products 의 only_buy_now 가 true
   * 2. 마플샵배송이 무료 배송인경우 (2177)
   * 3. 셀러상품인데 해외 배송인경우
   * */
  const is_only_buy_now =
    product_detail._.stores_product.only_buy_now ||
    product_detail.stores_delivery_company.id == PBStoresDeliveryCompanyConstantS.FREE_ID ||
    (T.lang != 'kr' &&
      product_detail.stores_delivery_company.lang != 'kr' &&
      DeliveryTypeS.isSeller(product_detail.stores_delivery_company.delivery_type_id));

  if (stores_products_token_gates.length) {
    if (is_mobile) {
      return `${makeNotiHtml(props)}${MShopAppTokenGateStoresProductTmplS.mobileSubmitTokenGate({
        product_detail,
        btn_text,
      })}`;
    } else {
      return `${makeNotiHtml(props)}${MShopAppTokenGateStoresProductTmplS.pcSubmitTokenGate({
        stores_products_token_gates,
        btn_text,
      })}`;
    }
  } else {
    if (is_mobile) {
      return mobileSubmit({ props, btn_text, status, disabled, is_only_buy_now });
    } else {
      return pcSubmit({ props, btn_text, status, disabled, is_only_buy_now });
    }
  }
};

const mobileSubmitDisabledNess = ({ props: { product_detail, sales_info }, btn_text }) => {
  const { is_before_sale } = sales_info;
  return html`
    <div class="pd-submit">
      <div class="pd-submit__action">${makeProdLikeHtml({ product_detail })}</div>
      <div class="pd-submit__btns">
        ${UtilS.htmlIf2(
          is_before_sale,
          () => makeNotiHtml({ product_detail, sales_info }),
          () => `<button class="pd-submit__btn" disabled type="button">${btn_text}</button>`,
        )}
      </div>
    </div>
  `;
};

const pcSubmitDisabledNess = ({ props, btn_text, is_disabled, is_digital_product }) => {
  const { product_detail, sales_info } = props;
  const { is_before_sale } = sales_info;

  return html`
    <div class="pd-submit">
      ${!is_before_sale ? makeNotiHtml({ product_detail, sales_info }) : ''}
      ${html`<div class="pd-submit__btns">
        ${is_before_sale ? makeNotiHtml({ product_detail, sales_info }) : submitDisabledBtn(btn_text)}
      </div> `}
      ${is_disabled && is_digital_product
        ? html`
            <div class="pd-submit__disabled">
              <img
                src="//s3.marpple.co/files/u_2308668/2023/7/original/0a51f2fdd47d99617b2888003f1bcdf7e87385cc1.webp"
                alt="caution icon image"
              />
              <div class="pd-submit__disabled-text">
                이미 구매한 상품으로 다운로드 가능 기간이 지나면 재구매가 가능해요.
              </div>
            </div>
          `
        : ''}
    </div>
  `;
};

// 장바구니 버튼
const makeFormSubmitBtnNess = (props) => {
  const { is_mobile, product_detail, sales_info, isApp, store /* login_user */ } = props;
  const {
    _: { base_product, stores_product },
    is_public,
  } = product_detail;

  const {
    is_prohibited,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    is_purchased_dtg = false,
    _: { digital_product, stores_products_token_gates },
  } = stores_product;

  const is_digital_product = !!digital_product?.id;

  const { can_selling /* user_id: owner_id */ } = store;
  const { is_use_creator, is_use_creator_en, is_use_creator_jp } = base_product;
  // const { id: user_id } = login_user || {};
  // const is_owner = owner_id === user_id;
  // const is_owner = false;

  const product_status = getProductStatus({
    is_prohibited,
    is_public,
    is_confirmed,
    is_confirm_req,
    is_digital_product,
    is_check_design,
    is_use_creator,
    is_use_creator_en,
    is_use_creator_jp,
  });

  const { is_disabled, btn_text } = makeFormSubmitBtnInfo({
    can_selling,
    sales_info,
    is_digital_product,
    isApp,
    product_status,
    is_purchased_dtg,
  });

  if (is_disabled) {
    if (is_mobile) {
      return mobileSubmitDisabledNess({ props, btn_text });
    } else {
      return pcSubmitDisabledNess({ props, btn_text, is_disabled, is_digital_product });
    }
  } else if (stores_products_token_gates.length) {
    if (is_mobile) {
      return `${MShopAppTokenGateStoresProductTmplS.mobileSubmitTokenGate({
        product_detail,
        btn_text,
      })}`;
    } else {
      return `${MShopAppTokenGateStoresProductTmplS.pcSubmitTokenGate({
        stores_products_token_gates,
        btn_text,
      })}`;
    }
  } else {
    if (is_mobile) {
      return mobileSubmit({ props, btn_text });
    } else {
      return pcSubmit({ props, btn_text });
    }
  }
};
