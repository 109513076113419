import { html } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const makeAddUrlHtml = () => {
  const is_ness = UtilS.isNessApp();

  const placeholder = is_ness
    ? `https://게임명.essential.nexon.com/kr/스토어명/products/상품번호
https://게임명.essential.nexon.com/kr/스토어명/products/상품번호
https://게임명.essential.nexon.com/kr/스토어명/products/상품번호
https://게임명.essential.nexon.com/kr/스토어명/products/상품번호`
    : `https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호
https://marpple.shop/kr/셀러명/products/상품번호`;

  return html`
    <div class="layout">
      <div class="content">
        <p class="title">상품 추가</p>
        <p class="description">
          ${is_ness ? `넥슨 에센셜` : '마플샵'} 상품 상세 페이지 URL을 입력해 상품을 추가해 보세요. <br />
          각 상품 URL을 엔터로 구분하면 여러 상품을 쉽게 추가할 수 있습니다.<br />
        </p>
        <textarea class="textarea" name="" placeholder="${placeholder}"></textarea>
      </div>

      <div class="footer">
        <button class="btn btn-cancel-frame">취소하기</button>
        <button class="btn btn-submit">저장하기</button>
      </div>
    </div>
  `;
};
