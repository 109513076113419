import $dataStr from 'fxdom/es/dataStr.js';
import { chunk, flat, go, groupBy, html, identity, map, mapObject, strMap, take, values } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilConstantS } from '../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppProductSubConstantS } from '../../../Product/Sub/S/Constant/module/MShopAppProductSubConstantS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';
import { zipWithIndex } from 'fxjs/es/Lazy';

const { title } = MShopAppProductSubConstantS.SUB_TYPE.popular;
const msg_data = {
  screen: {
    title,
    type: 'util',
    uri: `/${T.lang}/@/product/list/popular`,
  },
};

const popularItemHtml = ({
  thumbnails,
  id,
  _: {
    stores_product: { _: { store: { domain_name = '' } = {} } = {} },
    base_product: { thumbnail_ratio, base_product_color_face_url },
  },
}) => {
  const product_post_msg = {
    screen: {
      type: 'util',
      title: T('mshop::상품상세'),
      uri: `/${T.lang}/${UtilS.escape(domain_name)}/products/${id}`,
    },
  };
  return html`
    <div class="app-home-popular-product__item">
      <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
        ${MpShopProductTmplS.thumbnail(
          { thumbnail_ratio, thumbnails, base_product_color_face_url },
          { width: 600 },
        )}
      </a>
    </div>
  `;
};

export const popularProduct = ({ title, sub_title, popular_products, is_mobile, plus_crew }) => {
  const popularItems = map(popularItemHtml, take(10, popular_products));

  return html`
    <div class="app-home-popular-product-wrapper">
      <div class="app-home-popular-product">
        ${MShopAppHomeTmplS.homeTitle({
          title,
          sub_title,
          link: plus_crew?.id === MShopUtilConstantS.NBOX_CREW_ID ? null : msg_data,
        })}
      </div>
      <div class="app-home-popular-product__list">
        <div class="app-home-popular-product__list-wrapper">
          ${is_mobile && popularItems.length > 5
            ? go(
                popularItems,
                chunk(Math.ceil(popularItems.length / 2)),
                map(zipWithIndex),
                flat,
                groupBy(([i]) => i),
                mapObject(map(([_, v]) => v)),
                values,
                map(
                  (items) => html`
                    <div class="app-home-popular-product__items">${strMap(identity, items)}</div>
                  `,
                ),
                strMap(identity),
              )
            : strMap(identity, popularItems)}
        </div>
      </div>
    </div>
  `;
};
