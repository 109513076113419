import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { NewMakerGuideConfirmMuiF } from '../Mui/module/NewMakerGuideConfirmMuiF.js';
import { go, mapObject } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { NewMakerGuideConfirmS } from '../../S/Function/module/NewMakerGuideConfirmS.js';
import { $qs } from 'fxdom/es';

export const makeConfirmPopupPrams = (base_product) => {
  if (!base_product) return;
  const { id: base_product_id } = base_product;
  const db_confirm_popup =
    base_product._?.confirm_popup?.id &&
    base_product._.confirm_popup['m' + G._en] &&
    base_product._.confirm_popup['pc' + G._en] &&
    base_product._?.confirm_popup;
  const confirm_popup = go(db_confirm_popup || NewMakerGuideConfirmS.findBp(base_product_id), (confirm) => {
    if (!confirm) return;
    const is_mobile = UtilF.isLegacyMobile();
    const src1 = is_mobile ? confirm['m' + G._en] : confirm['pc' + G._en];
    const src2 = is_mobile ? confirm['m2' + G._en] : confirm['pc2' + G._en];
    if (!src1) return;
    return { src1, src2 };
  });
  return confirm_popup;
};

export const open = (confirm_popup) => {
  if (UtilS.isNessApp()) {
    return;
  }
  const is_mobile = UtilF.isLegacyMobile();
  setTimeout(() => {
    const frame = $qs('.don_frame[frame_name="/modules/NewMaker/Guide/Confirm/S/Mui/frame.js"]');
    if (!frame) {
      open(confirm_popup);
    }
  }, 100);
  return new Promise((resolve) => {
    return MuiF.openFrame(NewMakerGuideConfirmMuiF.frame, (frame, page, [tab]) => {
      frame.prev_frame_show = is_mobile;
      frame.is_modal = !is_mobile;
      frame.closed = resolve;
      tab.makeData = () => {
        return mapObject(
          (url) =>
            url
              ? UtilImageS.getResizedUrl({
                  url,
                  width: is_mobile ? 800 : 1760,
                  format: 'webp',
                  quality: 85,
                })
              : null,
          confirm_popup,
        );
      };
    });
  });
};
