import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, sel, strMap } from 'fxjs/es';
import { MpShopProductTmplS } from '../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppAdultTmplS } from '../../../Adult/S/Tmpl/module/MShopAppAdultTmplS.js';
import { MShopAppSellerS } from '../Function/module/MShopAppSellerS.js';

const storeName = (store_name) => store_name?.trim() || 'No Name';

const storePostMsg = (seller) =>
  $dataStr({
    screen: {
      type: 'util',
      title: storeName(seller.name),
      titleImage: seller.logo_img,
      uri: `/${T.lang}/${seller.domain_name}`,
    },
  });

export const mallItem = (seller) => html`
  <div class="mall-seller-list-all__item">
    <div class="mall-seller-list-all__name">
      <a
        href="/${T.lang}/${seller.domain_name}"
        class="mall-seller-list-all__name_link"
        data-post-message="${storePostMsg(seller)}"
        >${storeName(seller.name)}</a
      >
    </div>
  </div>
`;

export const setBgImg = (url) =>
  UtilImageS.getResizedUrl({
    url:
      url || '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png',
    quality: 70,
    width: 300,
    format: 'jpg',
  });

export const mallImgItem = (seller) => {
  const store_data = MShopAppSellerS.setSnsData(seller.sns_url || '');
  const img_alt = go(void 0, () => {
    if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
      return `${seller.name} 굿즈, ${seller.name} 공식 굿즈, ${seller.name}`;
    } else {
      return seller.name;
    }
  });

  return html`
    <div class="mall-seller-list-seller__item${seller.hide ? ' mall-seller-list-seller__item--hide' : ''}">
      <div class="mall-seller-list-seller__thumb">
        <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}">
          <div class="mall-seller-list-seller__item_img">
            <img src="${setBgImg(seller.thumbnail_img)}" alt="${img_alt}" />
          </div>
          ${!UtilS.isNessApp() && seller.sns_url
            ? html`
                <div class="mall-seller-list-seller__sns_icon">
                  <img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt="${store_data.type}" />
                </div>
              `
            : ''}
        </a>
      </div>
      <div class="mall-seller-list-seller__info">
        <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}">
          <div class="mall-seller-list-seller__name">${storeName(seller.name)}</div>
          ${seller.description
            ? html`<div class="mall-seller-list-seller__desc">${UtilS.escape(seller.description)}</div>`
            : ''}
        </a>
        <div class="app-store-like">
          <button
            class="app-store-like__btn"
            data-store_id="${seller.id}"
            data-already_like="${!!seller.stores_like_id}"
          >
            <div
              class="app-store-like__heart${seller.stores_like_id ? ' app-store-like__heart--on' : ''}"
            ></div>
          </button>
        </div>
      </div>
    </div>
  `;
};

export const sellerItem2 = (seller, is_mobile) => {
  const store_data = MShopAppSellerS.setSnsData(seller.sns_url || '');

  return html`
    <div class="seller-item2">
      <div class="seller-item2__seller">
        <div class="seller-item2__thumb">
          <div
            class="seller-item2__item_img"
            style="background-image: url(${setBgImg(seller.thumbnail_img)});"
          >
            <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"></a>
          </div>
          ${seller.sns_url
            ? html`
                <div class="seller-item2__sns_icon">
                  <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
                    ><img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt=""
                  /></a>
                </div>
              `
            : ''}
        </div>
        <div class="seller-item2__name">
          <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
            >${storeName(seller.name)}</a
          >
        </div>
        ${seller.description?.length
          ? html`
              <div class="seller-item2__desc">
                <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
                  >${seller.description}</a
                >
              </div>
            `
          : ''}
        <div class="seller-item2__like">
          <div class="app-store-like">
            <button
              class="app-store-like__btn"
              data-store_id="${seller.id}"
              data-already_like="${!!seller.stores_like_id}"
            >
              <div
                class="app-store-like__heart${seller.stores_like_id ? ' app-store-like__heart--on' : ''}"
              ></div>
            </button>
          </div>
        </div>
      </div>
      ${sellerItem2ProductsArea({ seller, is_mobile })}
    </div>
  `;
};

export const sellerItem2ProductsArea = ({ seller, is_mobile }) => {
  if (!seller) return '';
  if (!seller._?.stores_products.length) return sellerItemProductsEmpty();

  return html`
    <div class="seller-item2__products">
      ${is_mobile
        ? sellerItem2ProductMobile(seller)
        : strMap((st) => {
            return productItem(st._?.product, seller);
          }, seller._?.stores_products)}
    </div>
  `;
};

export const sellerItemProductsEmpty = () => html`
  <div class="seller-product-item-empty">
    <div class="img">
      <img
        src="//s3.marpple.co/files/u_218933/2021/12/original/e7d35e1bc52e0d367ea84ff6513a830ff65afd3e1.png"
        alt=""
      />
    </div>
    <p>${T('mshop::등록된 상품이 없습니다.')}</p>
  </div>
`;

export const sellerItem2ProductMobile = (seller) => html`
  <div class="swiper-container">
    <div class="swiper-wrapper">
      ${strMap((sp) => productItem(sp._?.product, seller, true), seller._?.stores_products)}
    </div>
  </div>
`;

export const mallMainSellerItem = (seller) => {
  const store_data = MShopAppSellerS.setSnsData(seller.sns_url || '');

  return html`
    <div class="mall-main-seller-list__item">
      <div class="mall-main-seller-list__seller">
        <div class="mall-main-seller-list__thumb">
          <div
            class="mall-main-seller-list__item_img"
            style="background-image: url(${setBgImg(seller.thumbnail_img)});"
          >
            <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"></a>
          </div>
          ${seller.sns_url
            ? html`
                <div class="mall-main-seller-list__sns_icon">
                  <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
                    ><img src="${store_data.ico_url}" srcset="${store_data.ico_url2}" alt=""
                  /></a>
                </div>
              `
            : ''}
        </div>
        <div class="mall-main-seller-list__name">
          <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
            >${storeName(seller.name)}</a
          >
        </div>
        ${seller.description?.length
          ? html`
              <div class="mall-main-seller-list__desc">
                <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
                  >${seller.description}</a
                >
              </div>
            `
          : ''}
        <div class="mall-main-seller-list__like">
          <div class="app-store-like">
            <button
              class="app-store-like__btn"
              data-store_id="${seller.id}"
              data-already_like="${!!seller.stores_like_id}"
            >
              <div
                class="app-store-like__heart${seller.stores_like_id ? ' app-store-like__heart--on' : ''}"
              ></div>
            </button>
          </div>
        </div>
      </div>
      <div class="mall-main-seller-list__products">
        ${strMap((sp) => productItem(sp?._?.product, seller), seller._?.stores_products)}
      </div>
    </div>
  `;
};

export const mallBrandItem = ({ crew }) => html`
  <div class="mall-seller-list-brand__item${crew.hide ? ' mall-seller-list-brand__item--hide' : ''}">
    <div class="mall-seller-list-brand__img">
      <a
        href="https://${crew.domain_name}.marpple.shop/${T.lang}"
        data-post-message="${$dataStr({
          navigate: {
            sel: 'PlusStackNavigation.Plus',
            params: {
              title: crew.name || '',
              titleImage: crew.mo_main_logo || '',
              uri: `https://${crew.domain_name}.marpple.shop/${T.lang}`,
            },
          },
        })}"
      >
        ${crew.is_adult && !crew.users_adult
          ? html`<div class="adult-item-wrap">${MShopAppAdultTmplS.adultItem()}</div>`
          : html`<img src="${setBgImg(crew.mall_logo_url)}" alt="" />`}
      </a>
    </div>
    <div class="mall-seller-list-brand__name">${crew.name || 'No Name'}</div>
  </div>
`;

export const emptyProductItem = () => html` <div class="seller-product-item-empty"></div> `;

export const productItem = (product, store, is_swiper) => {
  if (!product?.id) return emptyProductItem();

  const {
    id,
    // need_adult_check,
    thumbnails,
    _: {
      base_product: { thumbnail_ratio, base_product_color_face_url },
    },
  } = product;

  const product_post_msg = {
    screen: {
      type: 'util',
      title: T('mshop::상품상세'),
      uri: `/${T.lang}/${store.domain_name}/products/${id}`,
    },
  };

  const img_alt = go(void 0, () => {
    if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
      return `${store?.name} 굿즈, ${product?.name || sel('_.base_product.name', product) || ''}`;
    } else {
      return product?.name || sel('_.base_product.name', product) || '';
    }
  });

  return html`
    <div class="seller-product-item${is_swiper ? ' swiper-slide' : ''}" data-id="${id}">
      <a href="${product_post_msg.screen.uri}" data-post-message="${$dataStr(product_post_msg)}">
        ${MpShopProductTmplS.thumbnail(
          { thumbnail_ratio, thumbnails, base_product_color_face_url },
          {
            width: 200,
            img_alt,
          },
        )}
      </a>
    </div>
  `;
};

export const nessItem = (seller, is_mobile) => {
  const banner_img_url = UtilImageS.getResizedUrl({
    url:
      seller.banner_img ||
      '//s3.marpple.co/files/u_2308668/2023/10/original/3ee346f5abdba02d9f484dc96deb6a2f4103b8241.jpg',
    width: 1000,
    format: 'jpg',
  });

  const store_icon_url = UtilImageS.getResizedUrl({
    url: '//s3.marpple.co/files/u_2308668/2023/10/original/d1f89934f2c15ece5cc53df2310503b5f54b82691.png',
    width: 32,
    format: 'webp',
    quality: 60,
  });

  return html`
    <div class="seller-item2">
      <div class="seller-item2__thumb">
        <div class="seller-item2__item_img" style="background-image: url(${banner_img_url});">
          <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"></a>
        </div>
      </div>
      <div class="seller-item2__user-info">
        <img class="seller-item2__store-icon" src="${store_icon_url}" alt="store shape icon" />
        <div class="seller-item2__name">
          <a href="/${T.lang}/${seller.domain_name}" data-post-message="${storePostMsg(seller)}"
            >${storeName(seller.name)}</a
          >
        </div>
        <div class="seller-item2__like">
          <div class="app-store-like">
            <button
              class="app-store-like__btn"
              data-store_id="${seller.id}"
              data-already_like="${!!seller.stores_like_id}"
            >
              <div
                class="app-store-like__heart${seller.stores_like_id ? ' app-store-like__heart--on' : ''}"
              ></div>
            </button>
          </div>
        </div>
      </div>
      ${sellerItem2ProductsArea({ seller })}
    </div>
  `;
};
