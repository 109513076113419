import { html } from 'fxjs/es';
import { messages } from '../../../S/message_id.js';
import { MShopUtilS } from '../../../../../Util/S/Function/module/MShopUtilS.js';

export const makeQualityGuideHtml = (crew_id) => html`
  <div class="pd-modal-guide">
    <div class="pd-modal-guide__content">
      <p>${T(messages.quality_modal.p1)}</p>
      <p>${T(messages.quality_modal.p2)}</p>
      <p>${T(messages.quality_modal.p3)}</p>
      <p>${T(messages.quality_modal.p4)}</p>
    </div>

    <div class="pd-modal-guide__contact">
      <div class="pd-modal-guide__contact-item">
        <span>${T(messages.purchaseInfo.cs_title)}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).tel}
      </div>
      <div class="pd-modal-guide__contact-item">
        <span>${T(messages.purchaseInfo.email_title)}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).email}
      </div>
    </div>
  </div>
`;

export const makeNessQualityGuideHtml = (crew_id) => html`
  <div class="pd-modal-guide">
    <div class="pd-modal-guide__content">
      <p>${ET('product_detail::quality_modal::p1')}</p>
      <p>${ET('product_detail::quality_modal::p2')}</p>
    </div>

    <div class="pd-modal-guide__contact">
      <div class="pd-modal-guide__contact-item">
        <span>${ET('product_detail::quality_modal::cs_title')}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).tel}
      </div>
      <div class="pd-modal-guide__contact-item">
        <span>${ET('product_detail::quality_modal::email_title')}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).email}
      </div>
    </div>
  </div>
`;
