import { curry3, equals2, find, go, html, ifElse, sel, strMap } from 'fxjs/es';
import { View } from 'rune-ts';
import { CateListConstantS } from '../../../CateList/S/Constant/module/CateListConstantS.js';
import { CateListS } from '../../../CateList/S/Function/module/CateListS.js';
import { MpShopProductTmplS } from '../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { MShopAppStoreSuspendedTmplS } from '../../../MShop/App/Store/Suspended/S/Tmpl/module/MShopAppStoreSuspendedTmplS.js';
import { MShopStudioDigitalProductListTmplS } from '../../../MShop/Studio/DigitalProductList/S/Tmpl/module/MShopStudioDigitalProductListTmplS.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { makePaginationHtml } from '../../../Pagination/S/tmpl.js';
import { PBAdjustmentTmplS } from '../../../PB/Adjustment/S/Tmpl/module/PBAdjustmentTmplS.js';
import { getResizedUrl } from '../../../Util/Image/S/Function/getResized.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { ACTION, ACTION_TARGET } from '../../Authority/S/constant.js';
import { checkActions } from '../../Authority/S/util.js';
import { makeCrewSolutionHtml, makeCrewSolutionItemHtml } from '../../Crew/Solution/S/tmpl.js';

import { makeHelpSettingsBodyHtml } from '../../Help/S/tmpl.js';
import {
  PRODUCT_CUSTOM_LEVEL_TEXT,
  PRODUCT_STATUS,
  STORE_PRODUCT_CUSTOM_LEVEL,
} from '../../Product/S/constant.js';
import { makeAdjustmentsSettingsBodyHtml } from '../../SettingAdjustments/S/studio.js';
import { makeSettingAnalyticsBodyHtml } from '../../SettingAnalytics/S/tmpl.js';
import { makeArrangementSettingsBodyHtml } from '../../SettingArrangement/S/tmpl.js';
import { makeOrdersSettingsBodyHtml } from '../../SettingOrders/S/tmpl.js';
import { makeWithdrawsSettingsBodyHtml } from '../../Withdraw/S/studio.js';
import { messages } from './messages_id.js';
import { makeAccountSettingsBodyHtml, makeInviteInfoSettingsBodyHtml } from './setting_account.tmpl.js';
import { makeBankSettingsBodyHtml } from './setting_bank_account.tmpl.js';
import { languageSelector, makeSettingsHeaderHtml } from './setting_header.tmpl.js';
import { makeStoreSettingsMenuHtml } from './setting_menu.tmpl.js';
import { makeSettingsStoreTermsHtml } from './tmpl2.js';
import { selectStudioPrivacyHtml, selectStudioTermsHtml } from './tmpl3.js';
import { StudioHeader } from '../../../../services/MarppleShop/marppleShopStudioApp/components/cells/Layout/StudioTitle.ts';

export const checkIf = (value) => (value ? 'checked' : '');

const makeBaseProductHtml = (base_product) => {
  const {
    model_thumb_url,
    is_composite_publish,
    product_color_id,
    id,
    name,
    price_creator,
    print_price_creator,
    cate_list_id,
    cate_item_id,
    cbpp_profit = 0,
    is_new = false,
    price,
    all_out_of_stock,
  } = base_product;
  /* TODO 카테고리 하드코딩 */
  const show_price = cate_list_id !== CateListConstantS.DIGITAL_LIST_ID;
  return html`
    <li data-id="${id}" data-product_color_id="${product_color_id}" data-cate_item_id="${cate_item_id}">
      <div class="studio-selection-badges">
        ${is_new ? html`<div class="studio-selection-new-badge">NEW</div>` : ''}
        ${all_out_of_stock ? html`<span class="studio-selection-out-of-badge">일시 품절</span>` : ''}
      </div>
      <img alt="" src="${getResizedUrl({ url: model_thumb_url, quality: 90, width: 692 })}" />
      <span class="name">
        ${name}
        ${UtilS.htmlIf2(
          CateListS.isPhoneCaseByCateItemId(cate_item_id) && !is_composite_publish,
          () => html`<small>(${T(messages.possible_device_change)})</small> `,
        )}
      </span>
      ${UtilS.htmlIf2(
        show_price,
        () =>
          html`<span class="price mp_currency2">
            ${UtilS.commify(
              price || parseFloat(price_creator) + parseFloat(print_price_creator) + parseFloat(cbpp_profit),
            )}
          </span>`,
      )}
    </li>
  `;
};

const makeCategoryItemHtml = (item) => {
  const { name, cate_list_id, cate_item_id, url, total_count, is_new = false } = item;
  return html`
    <li data-cate_list_id="${cate_list_id}" data-cate_item_id="${cate_item_id}">
      <div class="studio-selection-badges">
        ${is_new ? html`<div class="studio-selection-new-badge">NEW</div>` : ''}
      </div>
      <img alt="" src="${url || ''}" />
      <span data-name>${UtilS.escape(name || '', true)}</span>
      <br />
      <span data-count>${total_count} items</span>
    </li>
  `;
};

export const makeBaseProductSelectionHtml = (base_products) => html`
  <div class="base-product-selection">
    <h2 class="title">${T(messages.make.p1)}</h2>
    <p class="description">${T(messages.make.p2)}</p>
    <ul class="base-products">
      ${strMap(makeBaseProductHtml, base_products)}
    </ul>
  </div>
`;

export const makeProductCategoriesHtml = (categories) => {
  return html`
    <div class="category-selection">
      <h2 class="title">${T(messages.make.p3)}</h2>
      <p class="description">${T(messages.make.p4)}</p>
      <ul class="category-items">
        ${strMap(makeCategoryItemHtml, categories)}
      </ul>
    </div>
  `;
};

const makeStyleSettingsBodyHtml = ({ store, user }) => html`
  <div class="header flex">
    <div class="left">
      ${new StudioHeader({
        title: UtilS.isMarppleShopApp() ? ET('mps2::studio::page::store_style') : T(messages.menus.styles),
        description: UtilS.isMarppleShopApp()
          ? ET('mps2::studio::page::store_style_guide')
          : T(messages.styles.p1),
        with_margin: false,
      }).toHtml()}
    </div>

    <div class="right editor">
      <button
        id="btn_edit_store"
        class="btn btn-blue"
        data-id="${store.id}"
        data-show="${checkActions(
          { actions: ACTION.UPDATE_STORE_NAME, target: ACTION_TARGET.STORE },
          { user },
        )}"
      >
        ${T(messages.styles.btn1)}
      </button>
    </div>
  </div>

  <div class="body">
    <iframe src="/${T.lang}/${UtilS.escape(store.domain_name)}?is_studio=true" frameborder="0"></iframe>
  </div>
`;

const makePleaseMobileHtml = (domain_name) => html`
  <div id="please-mobile">
    <div class="please-mobile-wrap">
      <span>${T(messages.type_mobile)}</span>
      <div>
        <i class="d-block lnr lnr-user"></i>
        <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/account">${T(messages.menus.account)}</a>
      </div>
    </div>
  </div>
`;

export const makaCreatorSettingsDisapprovalHtml = ({ user }) => {
  return user?._?.store?.is_disapproval
    ? html`<div class="seller_studio_banner">
        ${T('studio::아쉽게도 이번에는 마플샵 셀러로 모시지 못하게 되었습니다.')}
      </div>`
    : html`<div class="seller_studio_banner">${T(messages.need_allow)}</div>`;
};

export const makeCreatorSettingsHeaderHtml = ({ page_name, domain_name, user }, data) => {
  return html`
    <header id="creator_settings_header" class="setting-${page_name}">
      ${makeSettingsHeaderHtml({ domain_name, user, data })}
    </header>
  `;
};

export const makeCreatorSettingsFooterHtml = ({ domain_name, user }, data) => {
  const shopfreecaTerms =
    user?._?.crew?.id === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID
      ? html` <span class="terms">
          <a href="/${T.lang}/@/terms/${MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_DOMAIN}">
            ${T('mshop::signup::signup_use_extra_term_title')}
          </a>
        </span>`
      : '';

  return html`
    <div class="settings-footer">
      <div class="left">
        ${UtilS.htmlIfElse(
          equals2(T.lang, 'kr'),
          html`
            <span class="privacy">
              <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/privacy/latest">
                ${T(messages.footer.privacy)}
              </a>
            </span>
            <span class="terms">
              <a href="/${T.lang}/${UtilS.escape(domain_name)}/settings/terms/latest">
                ${T(messages.footer.terms)}
              </a>
            </span>
            ${shopfreecaTerms}
          `,
          `${shopfreecaTerms}`,
        )}
        <span> ${T(messages.footer.p)} </span>
      </div>
      <div class="right">
        ${UtilS.htmlIf2(data.is_mobile, () => `<div class='language-selector'>${languageSelector()}</div>`)}
        <span class="powered-by">
          &copy; ${new Date().getFullYear()} MARPPLE CORP. ALL RIGHTS RESERVED.
        </span>
      </div>
    </div>
  `;
};

export const makeCreatorSettingsPleaseMobileHtml = ({ user, domain_name }) => {
  return go(sel('_.store', user), (store) => {
    if (equals2(T.lang, 'kr')) {
      return !sel('owner_mobile', store) && user.is_seller ? makePleaseMobileHtml(domain_name) : '';
    } else {
      return (!sel('owner_mobile1', store) || !sel('owner_dialing_code', store)) && user.is_seller
        ? makePleaseMobileHtml(domain_name)
        : '';
    }
  });
};

export const makeBaseSettingsHtml = curry3(
  (page_name, makeBodyHtml, domain_name, user, solution_count, data) => {
    return html`
      ${makaCreatorSettingsDisapprovalHtml(user)}
      ${makeCreatorSettingsHeaderHtml({ page_name, domain_name, user }, data)}
      <main id="creator_settings">
        ${makeStoreSettingsMenuHtml({ domain_name, page_name, user, solution_count }, data)}
        <div id="creator_settings_body" class="setting-${page_name}">
          <div class="settings-content">
            ${makeBodyHtml instanceof View ? makeBodyHtml : makeBodyHtml(data)}
          </div>
          ${makeCreatorSettingsFooterHtml({ domain_name, user }, data)}
        </div>
      </main>
      ${makeCreatorSettingsPleaseMobileHtml({ user, domain_name })}
    `;
  },
);

export const makeEditableProductItemHtml = (product) => html`
  <li data-id="${product.id}" class="product-editable">${MpShopProductTmplS.item('setting')(product)}</li>
`;

const makeCheckBoxItemHtml = ({ id, name }) => html`
  <li class="dropdown-list-item" data-value="${id}">${name}</li>
`;

export const makeProductRevealingFilterHtml = ({ is_public = '' } = {}) => {
  const public_label = {
    '': T(messages.common.reveal_type),
    [PRODUCT_STATUS.PUBLIC]: T('mshop::ProductStatus::public'),
    [PRODUCT_STATUS.PRIVATE]: T('mshop::ProductStatus::private'),
    [PRODUCT_STATUS.PB_ME]: T('mshop::ProductStatus::pb_me'),
  };

  return html`
    <div class="public dropdown" is="dropdown" type="select" dispatch="change">
      <div class="dropdown-anchor">
        <span class="dropdown-label">${public_label[is_public]}</span>
        <img
          src="//s3.marpple.co/files/u_1504988/2021/4/original/c6dc0805e2d56252dfe92476ef8527252bd737b81.png"
        />
      </div>
      <ul class="dropdown-list product-filter" data-name="is_public" data-value="${is_public}">
        <li class="dropdown-list-item" data-label="${T(public_label[''])}" data-value="">
          ${public_label['']}
        </li>
        <li class="dropdown-list-item" data-value="pb_me">${public_label.pb_me}</li>
        <li class="dropdown-list-item" data-value="public">${public_label.public}</li>
        <li class="dropdown-list-item" data-value="private">${public_label.private}</li>
      </ul>
    </div>
  `;
};

export const makeProductCustomLevelFilterHtml = (filter_state) => {
  const custom_level = filter_state?.custom_level;
  const menu_label_str =
    PRODUCT_CUSTOM_LEVEL_TEXT(custom_level) ?? T('mshop::CustomLevel::custom-level-label');

  return html`
    <div class="public dropdown" is="dropdown" type="select" dispatch="change">
      <div class="dropdown-anchor">
        <span class="dropdown-label">${menu_label_str}</span>
        <img
          src="//s3.marpple.co/files/u_1504988/2021/4/original/c6dc0805e2d56252dfe92476ef8527252bd737b81.png"
        />
      </div>
      <ul class="dropdown-list product-filter" data-name="custom_level" data-value="${custom_level}">
        <li
          class="dropdown-list-item"
          data-label="${T('mshop::CustomLevel::custom-level-label-all')}"
          data-value=""
        >
          ${T('mshop::CustomLevel::custom-level-label-all')}
        </li>
        ${strMap((level) => {
          const label = PRODUCT_CUSTOM_LEVEL_TEXT(level);
          return html`
            <li class="dropdown-list-item" data-label="${label}" data-value="${level}">${label}</li>
          `;
        }, Object.values(STORE_PRODUCT_CUSTOM_LEVEL))}
      </ul>
    </div>
  `;
};

export const makeProductRevealingFilterHtml2 = ({ is_confirmed = '' } = {}) => {
  const confirm_label = {
    '': T('검수 여부'),
    ready: T('mshop::ProductStatus::ready_confirm'),
    false: T('mshop::ProductStatus::not_confirmed'),
    true: T('plus_admin::pa258_1'),
  };

  return html`
    <div class="public dropdown" is="dropdown" type="select" dispatch="change">
      <div class="dropdown-anchor">
        <span class="dropdown-label">${confirm_label[is_confirmed]}</span>
        <img
          src="//s3.marpple.co/files/u_1504988/2021/4/original/c6dc0805e2d56252dfe92476ef8527252bd737b81.png"
        />
      </div>
      <ul class="dropdown-list product-filter" data-name="is_confirmed" data-value="${is_confirmed}">
        <li class="dropdown-list-item" data-label="${T(confirm_label[''])}" data-value="">
          ${confirm_label['']}
        </li>
        <li class="dropdown-list-item" data-value="true">${confirm_label.true}</li>
        <li class="dropdown-list-item" data-value="false">${confirm_label.false}</li>
        <li class="dropdown-list-item" data-value="ready">${confirm_label.ready}</li>
      </ul>
    </div>
  `;
};

export const makeCategoryFilterHtml = ({ filter_state = {}, available_categories = [] }) => {
  const { category = '' } = filter_state;
  available_categories.unshift({ id: '', name: T(messages.common.all_cate) });
  const current_cate = find((item) => item.id == category, available_categories) || {};

  return html`
    <div class="lang dropdown" is="dropdown" type="select" dispatch="change">
      <div class="dropdown-anchor">
        <span class="dropdown-label">${current_cate.name || T(messages.select_category)}</span>
        <img
          src="//s3.marpple.co/files/u_1504988/2021/4/original/c6dc0805e2d56252dfe92476ef8527252bd737b81.png"
        />
      </div>
      <ul class="dropdown-list product-filter" data-name="category" data-value="${category}">
        ${strMap(
          ({ id, name_url, name }) => makeCheckBoxItemHtml({ id, name, name_url }),
          available_categories,
        )}
      </ul>
    </div>
  `;
};

export const makeCateItemFilterHtml = ({ filter_state = {}, available_categories = [] }) => {
  const { cate_item_id = '' } = filter_state;
  available_categories.unshift({ id: '', name: T(messages.common.all_cate) });
  const current_cate = find((item) => item.id == cate_item_id, available_categories) || {};

  return html`
    <div class="lang dropdown" is="dropdown" type="select" dispatch="change">
      <div class="dropdown-anchor">
        <span class="dropdown-label">${current_cate.name || T(messages.select_category)}</span>
        <img
          src="//s3.marpple.co/files/u_1504988/2021/4/original/c6dc0805e2d56252dfe92476ef8527252bd737b81.png"
        />
      </div>
      <ul class="dropdown-list product-filter" data-name="cate_item_id" data-value="${cate_item_id}">
        ${strMap(
          ({ id, name_url, name }) => makeCheckBoxItemHtml({ id, name, name_url }),
          available_categories,
        )}
      </ul>
    </div>
  `;
};

const makeProductSettingsBodyHtml = ({
  is_store_deleted,
  store_products,
  filter_state,
  pagination_state,
  available_categories,
  is_bank_account_filled,
  total_product_count,
  need_confirm_action,
  is_afreeca,
  store,
} = {}) => {
  const is_mps = UtilS.isMarppleShopApp();
  const { is_user_custom_enable = false } = store;

  return html`
    <div class="header flex">
      <div class="left">
        ${new StudioHeader({
          title: is_mps ? ET('mps2::studio::page::products') : T(messages.menus.products),
          description: is_mps ? ET('mps2::studio::page::products_guide') : is_user_custom_enable ? T(messages.products.custom_level.static) : T(messages.products.p1),
          with_margin: false,
          suffix: { type: 'guide' },
        }).toHtmlSSR()}
      </div>
      <div class="right product-filters">
        ${makeCategoryFilterHtml({ available_categories, filter_state })}
        ${is_afreeca ? '' : makeProductRevealingFilterHtml(filter_state)}
        ${need_confirm_action ? makeProductRevealingFilterHtml2(filter_state) : ''}
      </div>
    </div>
    <div class="body">
      <ul
        class="products"
        data-total_product_count="${total_product_count}"
        data-is_store_deleted="${is_store_deleted}"
        data-is_bank_account_filled="${is_bank_account_filled}"
        data-custom_level="${STORE_PRODUCT_CUSTOM_LEVEL.STATIC}"
      >
        <li class="btn-create-product">
          <div class="wrap-content">
            <div class="plus-icon">
              <img
                src="//s3.marpple.co/files/u_1165777/2020/9/original/993035129b660d8b42e3a025bf5cae74a4d2e5421.png"
                alt="${T(messages.products.p2)}"
              />
            </div>
            <div class="description">${T(messages.products.p2)}</div>
          </div>
        </li>
        ${strMap(makeEditableProductItemHtml, store_products)}
      </ul>
      ${makePaginationHtml(pagination_state)}
    </div>
  `;
};

const makeCustomAssetProductSettingsBodyHtml = ({
  is_store_deleted,
  store_products,
  filter_state,
  pagination_state,
  available_categories,
  is_bank_account_filled,
  total_product_count,
  need_confirm_action,
  is_afreeca,
  store,
} = {}) => {
  const is_mps = UtilS.isMarppleShopApp();
  const { is_user_custom_enable = false } = store;

  return html`
    <div class="header flex">
      <div class="left">
        ${new StudioHeader({
          title: is_mps ? ET('mps2::studio::page::products') : T(messages.menus.custom_asset_products),
          description: is_mps ? ET('mps2::studio::page::products_guide') : is_user_custom_enable ? T(messages.products.custom_level.asset) : T(messages.products.p1),
          with_margin: false,
          suffix: { type: 'guide' },
        }).toHtmlSSR()}
      </div>
      <div class="right product-filters">
        ${makeCategoryFilterHtml({ available_categories, filter_state })}
        ${is_afreeca ? '' : makeProductRevealingFilterHtml(filter_state)}
        ${need_confirm_action ? makeProductRevealingFilterHtml2(filter_state) : ''}
      </div>
    </div>
    <div class="body">
      <ul
        class="products"
        data-total_product_count="${total_product_count}"
        data-is_store_deleted="${is_store_deleted}"
        data-is_bank_account_filled="${is_bank_account_filled}"
        data-custom_level="${STORE_PRODUCT_CUSTOM_LEVEL.ASSET}"
      >
        <li class="btn-create-product">
          <div class="wrap-content">
            <div class="plus-icon">
              <img
                src="//s3.marpple.co/files/u_1165777/2020/9/original/993035129b660d8b42e3a025bf5cae74a4d2e5421.png"
                alt="${T(messages.products.p2)}"
              />
            </div>
            <div class="description">${T(messages.products.p2)}</div>
          </div>
        </li>
        ${strMap(makeEditableProductItemHtml, store_products)}
      </ul>
      ${makePaginationHtml(pagination_state)}
    </div>
  `;
};

const makeCustomUserProductSettingsBodyHtml = ({
  is_store_deleted,
  store_products,
  filter_state,
  pagination_state,
  available_categories,
  is_bank_account_filled,
  total_product_count,
  need_confirm_action,
  is_afreeca,
  store,
} = {}) => {
  const is_mps = UtilS.isMarppleShopApp();
  const { is_user_custom_enable = false } = store;

  return html`
    <div class="header flex">
      <div class="left">
        ${new StudioHeader({
          title: is_mps ? ET('mps2::studio::page::products') : T(messages.menus.custom_user_products),
          description: is_mps ? ET('mps2::studio::page::products_guide') : is_user_custom_enable ? T(messages.products.custom_level.user) : T(messages.products.p1),
          with_margin: false,
          suffix: { type: 'guide' },
        }).toHtmlSSR()}
      </div>
      <div class="right product-filters">
        ${makeCategoryFilterHtml({ available_categories, filter_state })}
        ${is_afreeca ? '' : makeProductRevealingFilterHtml(filter_state)}
        ${need_confirm_action ? makeProductRevealingFilterHtml2(filter_state) : ''}
      </div>
    </div>
    <div class="body">
      <ul
        class="products"
        data-total_product_count="${total_product_count}"
        data-is_store_deleted="${is_store_deleted}"
        data-is_bank_account_filled="${is_bank_account_filled}"
        data-custom_level="${STORE_PRODUCT_CUSTOM_LEVEL.USER}"
      >
        <li class="btn-create-product">
          <div class="wrap-content">
            <div class="plus-icon">
              <img
                src="//s3.marpple.co/files/u_1165777/2020/9/original/993035129b660d8b42e3a025bf5cae74a4d2e5421.png"
                alt="${T(messages.products.p2)}"
              />
            </div>
            <div class="description">${T(messages.products.p2)}</div>
          </div>
        </li>
        ${strMap(makeEditableProductItemHtml, store_products)}
      </ul>
      ${makePaginationHtml(pagination_state)}
    </div>
  `;
};

const makeRealPBProductSettingsBodyHtml = ({
  is_store_deleted,
  store_products,
  filter_state,
  pagination_state,
  available_categories,
  is_bank_account_filled,
  total_product_count,
  need_confirm_action,
} = {}) => {
  return html`
    <div class="header flex">
      <div class="left real-pb-setting-title">
        ${new StudioHeader({
          title: UtilS.isMarppleShopApp()
            ? ET('mps2::studio::page::products_creator')
            : T(messages.menus.real_pb_products),
          description: UtilS.isMarppleShopApp()
            ? ET('mps2::studio::page::products_creator_guide')
            : T(messages.real_pb_products.p1),
          suffix: { type: 'guide' },
          with_margin: false,
        }).toHtmlSSR()}
      </div>
      <div class="right product-filters flex-column">
        <button class="product-filters__button" type="button">
          ${T('pbMeModal::마플샵에 상품 공개하기')}
        </button>
        <div class="product-filters__filter">
          ${makeCategoryFilterHtml({ available_categories, filter_state })}
          ${makeProductRevealingFilterHtml(filter_state)}
          ${need_confirm_action ? makeProductRevealingFilterHtml2(filter_state) : ''}
        </div>
      </div>
    </div>
    <div class="body">
      <ul
        class="products"
        data-total_product_count="${total_product_count}"
        data-is_store_deleted="${is_store_deleted}"
        data-is_bank_account_filled="${is_bank_account_filled}"
      >
        <li class="btn-create-product">
          <div class="wrap-content">
            <div class="plus-icon">
              <img
                src="//s3.marpple.co/files/u_1165777/2020/9/original/993035129b660d8b42e3a025bf5cae74a4d2e5421.png"
                alt="${T(messages.real_pb_products.p2)}"
              />
            </div>
            <div class="description">${T(messages.real_pb_products.p2)}</div>
          </div>
        </li>
        ${strMap(makeEditableProductItemHtml, store_products)}
      </ul>
      ${makePaginationHtml(pagination_state)}
    </div>
  `;
};
const makeInviteSettingsBodyHtml = (data) => html`
  ${data?.user?._?.store.is_disapproval
    ? html`
        <div class="header"><h2>${T(messages.menus.invite)}</h2></div>
        <div class="body">
          <p>친구 초대 기능을 이용할 수 없는 상태입니다.</p>
        </div>
      `
    : html`
        <div class="header"><h2>${T(messages.menus.invite)}</h2></div>
        <div class="body">
          <div class="invite_area" data-invite_count="${sel('user._.store.invite_count', data) || '0'}">
            <div class="invite_description">
              <h3>${T(messages.invite.p1)}</h3>
              <ul>
                <li>${T(messages.invite.p2)}</li>
                <li>
                  ${T(messages.invite.p3, {
                    name: UtilS.escape(sel('user._.store.name', data)) || T(messages.common.seller),
                  })}
                </li>
                <li>
                  ${T(messages.invite.p4, {
                    name: UtilS.escape(sel('user._.store.name', data)) || T(messages.common.seller),
                  })}
                </li>
                <li class="invite_count">
                  ${T(messages.invite.p5, {
                    count: UtilS.escape(sel('user._.store.invite_count', data)) || '0',
                  })}
                </li>
              </ul>
            </div>

            <div class="invite_email">
              <h3>${T(messages.invite.t1)}</h3>
              <div class="input">
                <input type="text" name="invite_email" value="" placeholder="invite@marppleshop.com" />
                <button type="button" class="btn_invite_email btn btn-ok">${T(messages.invite.l1)}</button>
              </div>
            </div>

            <div class="invite_link">
              <h3>${T(messages.invite.t2)}</h3>
              <div class="input">
                <input type="text" name="invite_name" value="" placeholder="${T(messages.invite.p6)}" />
                <button type="button" class="btn_invite_link btn btn-ok">${T(messages.invite.l2)}</button>
              </div>
              <ul class="invite_link_list">
                ${strMap((cert) => {
                  return makeInviteStoreLinkCopyItem({
                    name: cert.ref.name || '',
                    store_domain_name: cert.ref.store_domain_name,
                    code: cert.code,
                    is_hidden: cert.is_hidden,
                  });
                }, data.invited_list)}
              </ul>
            </div>
          </div>
        </div>
      `}
`;

const makeMarketingSettingsBodyHtml = (data) => html`
  <div class="header"><h2>${T(messages.menus.marketing)}</h2></div>
  <div class="body">
    <div class="info_form form">
      <div class="controls form-group">
        <div class="control form-item form-radio">
          <div class="title">${T(messages.marketing.p1)}</div>

          <div class="form-item">
            <input
              id="is_show_crew_mall_on"
              type="radio"
              name="is_show_crew_mall"
              value="true"
              ${sel('user._.store.is_show_crew_mall', data) ? 'checked="checked"' : ''}
            />
            <label for="is_show_crew_mall_on">${T(messages.marketing.agree)}</label>

            <input
              id="is_show_crew_mall_off"
              type="radio"
              name="is_show_crew_mall"
              value="false"
              ${!sel('user._.store.is_show_crew_mall', data) ? 'checked="checked"' : ''}
            />
            <label for="is_show_crew_mall_off">${T(messages.marketing.not_agree)}</label>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="btn_modify">${T(messages.marketing.modify)}</button>
      </div>
    </div>
  </div>
`;

const makeSuspendedStoreSettingsHtml = (title) => html`
  <div class="header"><h2>${title}</h2></div>
  <div class="body">${MShopAppStoreSuspendedTmplS.fn()}</div>
`;

export const makeInviteStoreLinkCopyItem = ({ name, store_domain_name, code, is_hidden }) => html`
  <li>
    <span class="name">${T('translation::님', { name: UtilS.escape(name) })}</span>
    <input
      type="text"
      name="invite_link"
      value="https://marpple.shop/@/${store_domain_name}/invite/${code}"
    />
    ${is_hidden
      ? html`<button type="button" class="btn btn-yellow">${T(messages.invite.b1)}</button>`
      : html`<button type="button" class="btn_link_copy btn btn-info">${T(messages.invite.l3)}</button>`}
  </li>
`;

export const makeStyleSettingsHtml = makeBaseSettingsHtml(
  'styles',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.styles)),
    makeStyleSettingsBodyHtml,
  ),
);

export const makeProductSettingsHtml = makeBaseSettingsHtml(
  'products',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.products)),
    makeProductSettingsBodyHtml,
  ),
);

export const makeCustomAssetProductSettingsHtml = makeBaseSettingsHtml(
  'custom-asset-products',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.custom_asset_products)),
    makeCustomAssetProductSettingsBodyHtml,
  ),
);

export const makeCustomUserProductSettingsHtml = makeBaseSettingsHtml(
  'custom-user-products',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.custom_user_products)),
    makeCustomUserProductSettingsBodyHtml,
  ),
);

export const makeRealPBProductSettingsHtml = makeBaseSettingsHtml(
  'pb-products',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.real_pb_products)),
    makeRealPBProductSettingsBodyHtml,
  ),
);

export const makeDigitalProductSettingsHtml = makeBaseSettingsHtml(
  'digital-products',
  ifElse(
    sel('store.suspend_store'),
    () => makeSuspendedStoreSettingsHtml(T(messages.menus.make_digital_products)),
    MShopStudioDigitalProductListTmplS.root,
  ),
);

export const makeInviteInfoSettingsHtml = makeBaseSettingsHtml('invite_info', makeInviteInfoSettingsBodyHtml);
export const makeAccountSettingsHtml = makeBaseSettingsHtml('account', makeAccountSettingsBodyHtml);
export const makeInviteSettingsHtml = makeBaseSettingsHtml('invite', makeInviteSettingsBodyHtml);
export const makeMarketingSettingsHtml = makeBaseSettingsHtml('marketing', makeMarketingSettingsBodyHtml);
export const makeHelpSettingsHtml = makeBaseSettingsHtml('help', makeHelpSettingsBodyHtml);
export const makeOrdersSettingsHtml = makeBaseSettingsHtml('orders', makeOrdersSettingsBodyHtml);
export const makeSettingAnalyticsHtml = makeBaseSettingsHtml('analytics', makeSettingAnalyticsBodyHtml);
export const makeAdjustmentsSettingsHtml = makeBaseSettingsHtml(
  'adjustments',
  makeAdjustmentsSettingsBodyHtml,
);
export const makePbAdjustmentsSettingsHtml = makeBaseSettingsHtml('adjustments', PBAdjustmentTmplS.fn);
export const makeWithdrawsSettingsHtml = makeBaseSettingsHtml('withdraws', makeWithdrawsSettingsBodyHtml);
export const makeBankAccountSettingsHtml = makeBaseSettingsHtml('bank_account', makeBankSettingsBodyHtml);
export const makeTermsSettingsHtml = makeBaseSettingsHtml('terms', makeSettingsStoreTermsHtml);

export const makeTermsSettingsPageHtml = makeBaseSettingsHtml('', selectStudioTermsHtml);
export const makePrivacySettingsPageHtml = makeBaseSettingsHtml('', selectStudioPrivacyHtml);
export const makeArrangementSettingsHtml = makeBaseSettingsHtml(
  'arrangement',
  makeArrangementSettingsBodyHtml,
);

export const makeSolutionListSettingsHtml = makeBaseSettingsHtml('solution', makeCrewSolutionHtml);
export const makeSolutionItemSettingsHtml = makeBaseSettingsHtml('solution', makeCrewSolutionItemHtml);
