import axios from 'axios';
import { html, equals, some, sel, go } from 'fxjs/es';

import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppCommunityTmplS } from '../../../../Community/S/Tmpl/module/MShopAppCommunityTmplS.js';
import { MShopAppStoreCommunityEditorMuiF } from '../../Editor/F/Mui/module/MShopAppStoreCommunityEditorMuiF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';

export const openEditor = ({ article, store_id, type, title }) => {
  if (article && some(equals(article.type), ['review_text', 'review_photo']))
    return $.alert('리뷰는 수정할 수 없습니다.');

  try {
    MuiF.openFrame(MShopAppStoreCommunityEditorMuiF.frame, async (f, p, [t]) => {
      const is_mobile = MShopUtilF.isMobile();
      t.makeData = async () => {
        return {
          article,
          store_id,
          type,
          title,
        };
      };

      // f.title = article ? T('community::커뮤니티 글 수정하기') : T('community::커뮤니티 글 등록하기');
      f.title = title;
      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
      f.is_modal = !is_mobile;

      f.closing = async (frame_el, close_data) => {
        if (close_data?.reload) {
          location.reload();
        }
      };
    });
  } catch (err) {
    $.alert(T('community::불러오기 실패 다시 클릭해 주세요.'));
  }
};

export const makeInfiniteOptions = ({
  limit,
  container_el,
  domain_name,
  is_mobile,
  column_count,
  hooks,
  item_margin,
}) => {
  return {
    getItems: (page) => {
      // return go(getPhotoReviews({ page: page + 1, limit }), ({ data }) => {
      //   return data;
      // });
      const api_url = UtilS.makeApiUrl('/@api/renewal/stores/:store_name/community_articles', {
        store_name: domain_name,
      });

      return go(
        axios.get(api_url, {
          params: {
            offset: page * limit,
            limit,
          },
        }),
        ({ data }) => {
          return data.articles;
        },
      );
    },
    makeItemHtml: (article) => {
      const data = {
        article,
        store: sel('_.store', article),
        is_store: true,
        is_swiper: false,
        is_my: article.user_id == box.sel('is_user->id'),
        css_type: 'small',
        is_mobile,
      };
      return html` <div>${MShopAppCommunityTmplS.cheerUpItem(data)}</div> `;
    },
    onAdd: (els$) => {
      // els$.forEach((el) => {
      //   const RuneComponent = $find('[data-rune="PhotoReviewCard"]', el);
      //   PhotoReviewCard.createAndHydrate(RuneComponent);
      // });
    },
    item_margin: item_margin || (is_mobile ? 7 : 10),
    uniqueSel: sel('id'),
    getColumnCount: () => column_count,
    container_el,
    hooks,
    makeDividerHtml: () => {},
  };
};
