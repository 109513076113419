import { html, on } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { ArrowUpicon } from '../../../atoms/Icon';
import { makeFloatingButtonTemplate, toggleButtonAtScrollRelativeToViewport } from '../util';
import { FloatingButtonBase } from './FloatingButtonBase';

export class FloatingButton_up extends FloatingButtonBase<object> {
  constructor() {
    super({ show: false });
  }

  override onMount() {
    super.onMount();

    toggleButtonAtScrollRelativeToViewport(this, 1);
  }

  @on('click')
  override handleClick() {
    window.scrollTo({ top: 0 });
  }

  override template() {
    return makeFloatingButtonTemplate(
      html`<div class="${klass.floating_button}">
        <span class="${klass.button_icon}"> ${ArrowUpicon()} </span>
      </div> `,
      this.state.show_itself,
    );
  }
}
