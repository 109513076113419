/* Creator Api Module */
import {
  CreatorListReqData,
  CreatorDomainNameFetchOption,
  RankingApiReqData,
  UserLikedCreatorListFetchOption,
  PlusCreatorsFetchOption,
  CreatorNameReqData,
  CreatorEmailReqData,
  CreatorDataReqData,
} from '../type';
import axios from 'axios';
import { CreatorTypeS } from '../outbound/share';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS';

const getUserLikdedCreatorApiUrl = () => {
  if (UtilS.isNessApp()) {
    return `/${T.lang}/@api/my_like/stores`;
  } else {
    return `/${ET.lang}/@api/user_like_creators`;
  }
};
export const getUserLikedCreatorListApi = async (params: UserLikedCreatorListFetchOption) => {
  const { data } = await axios.get<CreatorTypeS.CreatorCardType[]>(getUserLikdedCreatorApiUrl(), {
    params,
  });

  return data;
};

export const getCreatorApi = async (params: CreatorListReqData) => {
  const { data } = await axios.get<CreatorTypeS.CreatorCardType[]>(`/${ET.lang}/@api/creators`, {
    params,
  });

  return data;
};

export const getWeeklyCreatorRankingApi = async (params: RankingApiReqData) => {
  const { data } = await axios.get<
    CreatorTypeS.MonthlyPopularCreatorData | CreatorTypeS.WeeklyPopularCreatorData
  >(`/${ET.lang}/@api/creators/ranking`, {
    params,
  });
  return data;
};

export const getCreatorsByDomainNameApi = async (params: CreatorDomainNameFetchOption) => {
  const { data } = await axios.get<CreatorTypeS.CreatorDomainNameResData[]>(
    `/${ET.lang}/@api/creators/by-domain-name`,
    {
      params,
    },
  );

  return data;
};

export const getPlusCreatorsApi = async (params: PlusCreatorsFetchOption) => {
  const { data } = await axios.get<CreatorTypeS.CreatorCardType[]>(`/${ET.lang}/@api/plus/creators`, {
    params,
  });

  return data;
};

export const getCheckNameApi = async (params: CreatorNameReqData) => {
  const { data } = await axios.post<boolean>(`/${ET.lang}/@api/get_check_name`, params);
  return data;
};

export const getCheckEmailApi = async (params: CreatorEmailReqData) => {
  const { data } = await axios.post<{ result: boolean }>(
    `/${ET.lang}'/@api/advance_reservation/get_check_store_email`,
    params,
  );
  return data.result;
};

export const postApplySellerApi = async (params: CreatorDataReqData) => {
  const { data } = await axios.post('/@api/ad_res/apply_seller', params);
  return data;
};
