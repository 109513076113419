export const ORDER_PROCESS = {
  PURCHASE: 'PURCHASE',
  BEFORE_PURCHASE: 'BEFORE_PURCHASE',
  CANCEL: 'CANCEL',
  REFUND: 'REFUND',
};

export const PURCHASE_STEP = {
  ORDERED: 'ORDERED',
  PRODUCT_PREPARING: 'PRODUCT_PREPARING',
  PB_PRODUCT_PREPARING: 'PB_PRODUCT_PREPARING',
  PRODUCT_MANUFACTURING: 'PRODUCT_MANUFACTURING',
  PRODUCT_COMPLETE: 'PRODUCT_COMPLETE',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  CONFIRMED: 'CONFIRMED',
};

export const CANCEL_STEP = {
  REQUEST_CANCEL: 'REQUEST_CANCEL',
  CANCELED: 'CANCELED',
};

export const REFUND_STEP = {
  REQUEST_REFUND: 'REQUEST_REFUND',
  REFUNDED: 'REFUNDED',
};

export const BEFORE_PURCHASE = {
  CREATED: 'CREATED',
  DEPOSIT_PENDING: 'DEPOSIT_PENDING',
  ERROR_IN_ORDER: 'ERROR_IN_ORDER',
};
