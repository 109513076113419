import {
  checkBoolean,
  checkEmail,
  checkMailOrderBusinessNumber,
  checkNotSpecialStr,
  checkOnlyNumber,
  checkTel,
} from './check.js';

export const validateBusinessInfo = (business_info) => {
  const errors = [];

  const res_company_info_public = checkBoolean(business_info.company_info_public, 'company_info_public');
  if (!res_company_info_public.result) errors.push(res_company_info_public);

  const res_company_name = checkNotSpecialStr(business_info.company_name, 'company_name');
  if (!res_company_name.result) errors.push(res_company_name);

  const res_company_president = checkNotSpecialStr(business_info.company_president, 'company_president');
  if (!res_company_president.result) errors.push(res_company_president);

  const res_business_license_number = checkOnlyNumber(
    business_info.business_license_number,
    'business_license_number',
  );
  if (!res_business_license_number.result) errors.push(res_business_license_number);

  const res_company_address = checkNotSpecialStr(business_info.company_address, 'company_address');
  if (!res_company_address.result) errors.push(res_company_address);

  const res_company_email = checkEmail(business_info.company_email);
  if (!res_company_email.result) errors.push(res_company_email);

  const res_company_tel = checkTel(business_info.company_tel, 'company_tel');
  if (!res_company_tel.result) errors.push(res_company_tel);

  const res_mail_order_business_number = checkMailOrderBusinessNumber(
    business_info.mail_order_business_number,
    'mail_order_business_number',
  );
  if (business_info.mail_order_business && !res_mail_order_business_number.result) {
    errors.push(res_mail_order_business_number);
  }

  return errors;
};
