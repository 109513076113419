import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { makeCompositePreview } from './makeCompositePreview.js';
import { NewMakerCompositePreviewMuiF } from '../Mui/module/NewMakerCompositePreviewMuiF.js';
import axios from 'axios';
import { NewMakerGuideConfirmF } from '../../../Guide/Confirm/F/Function/module/NewMakerGuideConfirmF.js';
import { getBaseProductInMaker, getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { $qs } from 'fxdom/es';
import { go, sel } from 'fxjs/es';

// title: 'Preview',
//   description1:
// '* The uploaded design will be printed as it is, so please check the guide on the detailed page.',
//   description2:
// '* Due to the difference in color mode, the screen color and the actual product may be slightly different.',

// title: 'プレビュー',
//   description1: '* アップロードしたデザインそのまま印刷するので詳細ページのガイドを必ず確認してください。',
//   description2: '* カラーモードの違いにより、画面と実際の商品の色がお互いに細かく異なることがあります。',
const air_pod_case = {
  title: '미리보기',
  descriptions: [
    '* 전면 인쇄가 가능한 제품으로 앞면, 뒷면 디자인을 누락하지 않도록 주문 전 확인 부탁드립니다.',
    '* 업로드 한 디자인 그대로 인쇄되니 상세페이지 내 가이드를 확인해 주세요.',
    '* 컬러 모드 차이로 인해 화면과 실제 상품의 색상이 미세하게 다를 수 있습니다.',
  ],
  title_en: 'Preview',
  descriptions_en: [
    '* Please note that full-sided printing is available for this product, so don’t forget to design the front and back sides prior to ordering.',
    '* The uploaded design will be printed as it is, so please check the guide on the detailed page.',
    '* Due to the difference in color mode, the screen color and the actual product may be slightly different.',
  ],
  title_jp: 'プレビュー',
  descriptions_jp: [
    '* 全体印刷が可能な製品なので表と裏のデザインが抜けないようにご注文前にご確認ください。',
    '* アップロードしたデザインそのまま印刷するので詳細ページのガイドを必ず確認してください。',
    '* カラーモードの違いにより、画面と実際の商品の色がお互いに細かく異なることがあります。',
  ],
};

function getInfo(base_product_id) {
  const info = {
    basic: {
      title: '미리보기',
      descriptions: [
        '* 업로드 한 디자인 그대로 인쇄되니 상세페이지 내 가이드를 확인해 주세요.',
        '* 컬러 모드 차이로 인해 화면과 실제 상품의 색상이 미세하게 다를 수 있습니다.',
      ],
      title_en: 'Preview',
      descriptions_en: [
        '* The uploaded design will be printed as it is, so please check the guide on the detailed page.',
        '* Due to the difference in color mode, the screen color and the actual product may be slightly different.',
      ],
      title_jp: 'プレビュー',
      descriptions_jp: [
        '* アップロードしたデザインそのまま印刷するので詳細ページのガイドを必ず確認してください。',
        '* カラーモードの違いにより、画面と実際の商品の色がお互いに細かく異なることがあります。',
      ],
    },
    6368: air_pod_case,
    6369: air_pod_case,
    6370: air_pod_case,
    6371: air_pod_case,
  };
  return info[base_product_id] ?? info.basic;
}

function resizeCanvas(canvas, size) {
  const resize_canvas = document.createElement('canvas');
  resize_canvas.width = size;
  resize_canvas.height = size;
  const ctx = resize_canvas.getContext('2d');
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, size * 0.05, size * 0.05, size * 0.9, size * 0.9);
  return resize_canvas;
}

const setCompositePreviewProductFace = async (product, canvas, size) => {
  if (canvas instanceof HTMLCanvasElement) {
    product.product_faces2.value[0].preview_thumbnail_url = await go(
      $.uploadFileToUrl(resizeCanvas(canvas, size).toDataURL(), 'preview_image', 'png'),
      sel('url'),
    );
  }
};

async function makeCompositePreviewCanvas(product) {
  try {
    const response = await axios
      .get(
        UtilS.isMarppleShopApp()
          ? UtilS.makeApiUrl('/:lang/@api/stores/:store_id/composite/preview_assoc_composite_template', {
              lang: T.lang,
              store_id: box.sel('store_id'),
            })
          : $1('#dream_factory')
          ? '/@api/composite/preview_assoc_composite_template'
          : UtilS.makeApiUrl('/:lang/@api/composite/preview_assoc_composite_template', {
              lang: T.lang,
            }),
        {
          params: { base_product_id: product.base_product_id },
        },
      )
      .catch((error) => {
        $.alert(error?.response?.data?.message || '문제가 발생 했습니다.');
      });
    const assoc_composite_template = response?.data?.assoc_composite_template;
    if (!assoc_composite_template) return;
    const canvas = await makeCompositePreview({ assoc_composite_template, product });
    return canvas;
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(
      getProductColorInMaker(),
      { message: `${product.base_product_id} 합성템플릿 미리보기 제대로 동작 안함.` },
      MakerDebugConstantS.Category.PostMaker,
      'makeCompositePreview',
    );
  }
}

export const openCompositePreview = async (product, is_save_for_thumb) => {
  const has_don_loader = !!$qs('.don_loader_wrap');

  const canvas = await makeCompositePreviewCanvas(product);

  if (!canvas) return;

  const data_url = canvas.toDataURL();
  const info = getInfo(product.base_product_id);
  return new Promise((resolve, reject) => {
    const confirm_popup = NewMakerGuideConfirmF.makeConfirmPopupPrams(getBaseProductInMaker());
    MuiF.openFrame(NewMakerCompositePreviewMuiF.frame, (frame, page, [tab]) => {
      frame.is_modal = !UtilF.isLegacyMobile();
      tab.makeData = () => {
        return {
          data_url,
          info,
          has_confirm_popup: !!confirm_popup,
        };
      };
      frame.closed = async (frame_el, is_good) => {
        if (!is_good) {
          resolve({ frame_done: false });
          setTimeout(() => {
            if (confirm_popup && !UtilS.isNessApp()) return NewMakerGuideConfirmF.open(confirm_popup);
          }, 100);
          return;
        }
        if (is_good) {
          if (is_save_for_thumb) {
            await setCompositePreviewProductFace(product, canvas, 400);
          }
          if (has_don_loader) {
            $.don_loader_start();
          }
          resolve({ frame_done: true });
        }
      };
    });
  });
};

export const openTempMaskingTapePreview = (product_color, data_url, resolve) => {
  const info = getInfo(product_color.base_product_id);
  MuiF.openFrame(NewMakerCompositePreviewMuiF.frame, (frame, page, [tab]) => {
    frame.is_modal = !UtilF.isLegacyMobile();
    tab.makeData = () => {
      return {
        data_url,
        info,
        has_confirm_popup: true,
      };
    };
    frame.closed = async (frame_el, is_good) => {
      if (!is_good) {
        resolve(false);
        return;
      }
      if (is_good) {
        $.don_loader_start();
        product_color.product_faces2.value[0].preview_thumbnail_url = await go(
          $.uploadFileToUrl(data_url, 'preview_image', 'png'),
          sel('url'),
        );
        resolve(true);
      }
    };
  });
};
