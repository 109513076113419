import { $, CustomEventWithDetail, html, on, rune, View } from 'rune-ts';
import klass from './CheerUpCard.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { ButtonHeart } from '../../atoms/ButtonHeart/ButtonHeart';
import { CheerUpTypeS } from '../../../../features/CheerUp/outbound/share';
import { formatTimeElapsed } from '../../../../shared/util/date';
import { pipe, map, toArray } from '@fxts/core';
import { CreatorConstantS } from '../../../../features/Creator/outbound/share';
import { getResizedUrl } from '../../../../shared/util/image';
import axios from 'axios';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { Confirm } from '../../../../shared/components/cells/Confirm/Confirm';
import { preventEscape } from '../../../../shared/util/preventEscape';
import { pushLoginStack } from '../../../../../../modules/Creator/Login/F/fs';
import { makeApiUrl } from '../../../../shared/util';
import { $qs } from 'fxdom/es';

interface CheerUpCardOption {
  klass?: string;
}

export class CheerUpCardEvent extends CustomEventWithDetail<{
  title: string;
  article_id: number;
}> {}

export class CheerUpCard extends View<CheerUpTypeS.CheerUpCardType> {
  state: CheerUpCardOption & {
    // FIXME
  };

  buttonHeartView: ButtonHeart;

  constructor(data: CheerUpTypeS.CheerUpCardType, option: CheerUpCardOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
    };
    this.buttonHeartView = new ButtonHeart({
      count: data.like_count,
      is_active: !!data.like_id,
      size: 'medium',
      type: 'transparent',
    });
  }

  override template() {
    const user = this.data.$one.users;
    const profile_image = user.profile_image || '';
    const { body = '', comment_count, created_at } = this.data;

    // TODO bjk row number 관련 처리 필요함
    const comment_profiles = pipe(
      this.data.$many.comments.slice(0, 3),
      map((comment) => {
        return comment.$one.users.profile_image || CreatorConstantS.thumbnail_img;
      }),
      toArray,
    );

    return html`
      <div
        class="${this.state.klass} ${klass.cheer_up_card} ${typo('14_medium')}"
        data-article_id="${this.data.id}"
      >
        <div class="${klass.wrap}">
          <div class="${klass.profile}">
            <img
              class="${klass.profile_img}"
              src="${getResizedUrl({
                url: profile_image,
                width: 48 * 2,
                format: 'webp',
                quality: 70,
              })}"
              alt=""
            />
          </div>
          <div class="${klass.name} ${typo('14_bold')}">${user.email || user.nick_name || 'NONAME'}</div>
          <div class="${klass.created_at} ${typo('12_medium')}">${formatTimeElapsed(created_at)}</div>
          <div class="${klass.body}">${body}</div>
          <div class="${klass.controls}">
            <div class="${klass.comment}">
              ${comment_profiles?.length
                ? html`
                    <div class="${klass.comment_profiles}">
                      ${comment_profiles.map((profile_img) => {
                        return html`
                          <span class="${klass.comment_profile}">
                            <img
                              class="${klass.comment_profile_img}"
                              src="${getResizedUrl({
                                url: profile_img,
                                width: 24 * 2,
                                format: 'webp',
                                quality: 70,
                              })}"
                              alt=""
                            />
                          </span>
                        `;
                      })}
                    </div>
                  `
                : ''}
              <div class="${klass.comment_count} ${typo('12_medium')}">${comment_count} Replies</div>
            </div>
            <div class="${klass.like}">${this.buttonHeartView}</div>
          </div>
        </div>
      </div>
    `;
  }

  protected override onRender() {
    this.delegate('click', `.${klass.like}`, this.createLikeHandler());
  }

  createLikeHandler() {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let initialState: boolean | null = null;

    const sendApiRequest = async ({ store_id, article_id }) => {
      try {
        const post_url = makeApiUrl(`/${ET.lang}/@api/stores/:store_id/like`, { store_id });
        const currentState = this.buttonHeartView.data.is_active;

        // 초기 상태와 현재 상태가 다를 때만 API 호출
        if (currentState !== initialState) {
          const response = await axios.post<{ result: boolean }>(post_url, {
            is_like: currentState,
            attached_id: article_id,
            attached_type: 'articles',
          });

          if (!response.data.result) throw new Error('API call failed');
        }
      } catch (error) {
        // 에러 시 초기 상태로 복구
        this.buttonHeartView.data.is_active = initialState!;
        // 카운트도 원래대로 복구
        this.buttonHeartView.data.count += initialState! ? 1 : -1;
        this.buttonHeartView.redraw();
        MShopUtilF.popToastMsg(T('community::실패'), 'error');
      } finally {
        timer = null;
        initialState = null;
      }
    };

    return async () => {
      const store_id = this.data.store_id;
      const article_id = this.data.id;

      // 타이머 없을 때만 초기 상태 저장
      if (!timer) {
        initialState = !!this.buttonHeartView.data.is_active;
      } else {
        clearTimeout(timer);
      }

      if (!box.sel('is_user->id')) {
        if (
          await Confirm.open({
            title: ET('mps2::login::need_login::title'),
            message: preventEscape(ET('mps2::login::need_login::message')),
            confirmText: ET('mps2::login::login'),
            cancelText: ET('mps2::signup::close'),
          })
        ) {
          if (MShopUtilF.isApp()) {
            pushLoginStack();
          } else if (box.sel('store_url')) {
            location.href = `${box.sel('store_url')}/login?url=${box.sel('store_url')}?a_id=${article_id}`;
          } else {
            location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
          }
        }
        return;
      }

      // UI 업데이트
      const newState = !this.buttonHeartView.data.is_active;
      this.buttonHeartView.data.is_active = newState;
      this.buttonHeartView.data.count += newState ? 1 : -1;
      this.buttonHeartView.redraw();

      timer = setTimeout(() => {
        sendApiRequest({
          store_id,
          article_id,
        });
      }, 300);
    };
  }

  @on('click', `.${klass.wrap}`)
  private _onClick(e: Event) {
    // 좋아요 버튼을 눌렀으면 아무일도 안한다.

    if ($(e.target).closest(`.${klass.like}`)?.element()) {
      return;
    }

    const article_id = this.data.id;
    // const is_ness = UtilS.isNessApp();
    const title1 = ET('mps2::cheerup::title1');

    this.dispatchEvent(CheerUpCardEvent, {
      bubbles: true,
      detail: {
        title: title1,
        article_id,
      },
    });
  }

  public redrawControls(is_active: boolean, like_count: number, comment_count: number) {
    this.buttonHeartView.data.is_active = is_active;
    this.buttonHeartView.data.count = like_count;
    $(this.element()).find(`.${klass.comment_count}`)?.setTextContent(`${comment_count} Replies`);
    this.buttonHeartView.redraw();
  }

  static redrawCheerUpControls(
    article_id: number,
    is_active: boolean,
    like_count: number,
    comment_count: number,
  ) {
    const cheerup_item_el = $qs(`.${CheerUpCard}[data-article_id="${article_id}"]`);
    const cheerUpCardView = cheerup_item_el && rune.getView(cheerup_item_el, CheerUpCard);
    if (cheerUpCardView) {
      cheerUpCardView.redrawControls(is_active, Number(like_count), comment_count);
    }
  }
}
