import axios from 'axios';
import {
  $addClass,
  $attr,
  $closest,
  $find,
  $findAll,
  $parent,
  $qs,
  $removeClass,
  $setAttr,
  $setText,
  $trigger,
  $val,
} from 'fxdom/es';
import { each, find, go, head, sel, tap } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { TermsConditionsMuiF } from '../../../../../../Terms/Conditions/F/Mui/module/TermsConditionsMuiF.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopShareFramePopUpF } from '../../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppStoreQuestionConstantS } from '../../S/Constant/module/MShopAppStoreQuestionConstantS.js';
import { MShopAppStoreQuestionS } from '../../S/Function/module/MShopAppStoreQuestionS.js';
import { MShopAppStoreQuestionTmplS } from '../../S/Tmpl/module/MShopAppStoreQuestionTmplS.js';
import { MShopAppStoreQuestionMuiF } from '../Mui/module/MShopAppStoreQuestionMuiF.js';
import { Confirm } from '../../../../../../../services/MarppleShop/renderApp/components/cells/Confirm/Confirm.ts';
import { preventEscape } from '../../../../../../../services/MarppleShop/shared/util/preventEscape.ts';

const initFile = (el) => {
  setTimeout(function () {
    el.type = '';
    el.type = 'file';
    el.disabled = false;
  }, 0);
};

export const open = async ({ user, item_order_id, product_id, store_id }) => {
  try {
    const is_mobile = MShopUtilF.isMobile();
    const is_ness = UtilS.isNessApp();

    if (!sel('id', user)) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        location.href = `${box.sel('store_url')}/login?url=${location.pathname}`;
      }
      return;
    }

    MuiF.openFrame(MShopAppStoreQuestionMuiF.frame, async (f, p, [t]) => {
      const question = null;
      const { data: _user } = await axios.get(`/${T.lang}/@api/users/login_user`);

      t.makeData = () => {
        return { user: _user, question, item_order_id, product_id, store_id, is_mobile };
      };
      f.is_modal = !is_mobile;
      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
      f.header_height = is_ness ? 72 : 60;
    });
  } catch (err) {
    // $.alert(T('community::불러오기 실패 다시 클릭해 주세요.'));
  }
};

export const openNess = async ({ user, item_order_id, product_id, store_id }) => {
  try {
    const is_mobile = MShopUtilF.isMobile();

    if (!sel('id', user)) {
      if (await $.confirm(T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?'))) {
        location.href = `${box.sel('store_url')}/login?url=${location.pathname}`;
      }
      return;
    }

    MuiF.openFrame(MShopAppStoreQuestionMuiF.frame, async (f, p, [t]) => {
      const question = null;
      const { data: _user } = await axios.get(`/${T.lang}/@api/users/login_user`);

      t.makeData = () => {
        return { user: _user, question, item_order_id, product_id, store_id };
      };
      f.is_modal = !is_mobile;
      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
      f.header_height = 72;
    });
  } catch (err) {
    // $.alert(T('community::불러오기 실패 다시 클릭해 주세요.'));
  }
};

const errorPassEl = (el) => {
  go(
    el,
    $parent('.store-qna-editor__control'),
    tap($findAll('.store-qna-editor__body--error'), each($removeClass('store-qna-editor__body--error'))),
    $findAll('.store-qna-editor__error-msg--show'),
    each($removeClass('store-qna-editor__error-msg--show')),
  );
};

const errorStatusEl = (res, el) => {
  if (!res.result) {
    return go(
      el,
      $parent('.store-qna-editor__control'),
      tap($findAll('.store-qna-editor__body'), each($addClass('store-qna-editor__body--error'))),
      $find('.store-qna-editor__error-msg'),
      tap($setText(res.error.text)),
      $addClass('store-qna-editor__error-msg--show'),
    );
  }

  errorPassEl(el);
};

const setQuestionBodyLengthTxt = (input_el, length) => {
  const txt_el = $find(
    '.store-qna-editor__body-textarea-length-txt',
    $closest('.store-qna-editor__body', input_el),
  );
  $setText(length, txt_el);
};

export const focusoutNameInput = (e) => {
  const el = e.currentTarget || e;
  const res = MShopAppStoreQuestionS.checkName(el.value.trim());
  errorStatusEl(res, el);
};

export const focusoutMobileInput = (e) => {
  const el = e.currentTarget || e;
  const res = MShopAppStoreQuestionS.checkMobile(el.value.trim());
  errorStatusEl(res, el);
};

export const focusoutEmailInput = (e) => {
  const el = e.currentTarget || e;
  const res = MShopAppStoreQuestionS.checkEmail(el.value.trim());
  errorStatusEl(res, el);
};

export const focusoutTitleInput = (e) => {
  const el = e.currentTarget || e;
  const res = MShopAppStoreQuestionS.checkTitle(el.value.trim());
  errorStatusEl(res, el);
};

export const focusoutBodyTextarea = (e) => {
  const el = e.currentTarget || e;
  const res = MShopAppStoreQuestionS.checkBody(el.value.trim());
  errorStatusEl(res, el);
};

export const keyUpBodyTextarea = (e) => {
  const el = e.currentTarget || e;
  const length = UtilS.checkTextLength(el.value.trim());
  setQuestionBodyLengthTxt(el, length);
};

export const clickDelFile = (e) => {
  const file_container_el = $find('.store-qna-editor__body--file', e.delegateTarget);
  const file_text_el = $find('.store-qna-editor__body-file-text', e.delegateTarget);
  const file_input_el = $find('.store-qna-editor__input-file', e.delegateTarget);

  $removeClass('active', file_text_el);
  $setAttr(
    {
      can_cancel: false,
    },
    file_container_el,
  );
  $setText(ET('mps2::question:: 문의와 관련된 파일을 첨부해 주세요.'), file_text_el);
  box.set('question_files', []);
  initFile(file_input_el);
};

export const changeFile = async (e) => {
  const file_input_el = e.currentTarget;
  if ($.valid_files(file_input_el)) {
    return MShopShareFramePopUpF.alert({ body: MShopAppStoreQuestionTmplS.questionAcceptedFileTypesHtml() });
  }

  const files = file_input_el.files;
  const file_container_el = $find('.store-qna-editor__body--file', e.delegateTarget);
  const file_text_el = $find('.store-qna-editor__body-file-text', e.delegateTarget);

  if (find((file) => file.size > 10485760, files)) {
    return go(
      MShopShareFramePopUpF.alert({
        title: ET('mps2::file::volume_error_title'),
        body: ET('mps2::file::volume_error_message', { volume: '10MB' }),
      }),
      () => initFile(file_input_el),
    );
  }

  file_input_el.disabled = true;

  const file_name = head(files).name;
  $addClass('active', file_text_el);
  $setText(file_name, file_text_el);
  $setAttr(
    {
      can_cancel: true,
    },
    file_container_el,
  );
  box.set('question_files', files);
  initFile(file_input_el);
};

export const openPrivacy = (e) => {
  return MuiF.openFrame(TermsConditionsMuiF.frame, (frame, p, [t]) => {
    frame.el_class = 'terms_modal_style terms_style frame-studio-modal-style question-privacy-frame';
    frame.title = ET('개인 정보 수집 및 이용 동의');
    frame.hide_frame_button_position = 'RIGHT';
    frame.hide_frame_button_type = 'X';
    frame.always_remove = true;
    frame.is_modal = !MShopUtilF.isMobile();
    frame.closed = (f, is_agree) => {
      if (is_agree) {
        $find('input[name="is_agree"]', e.delegateTarget).checked = true;
      }
    };
    t.template = () =>
      MShopAppStoreQuestionTmplS.questionPrivacyHtml({ lang: T.lang, store_name: box.sel('domain_name') });
  });
};

const checkDataErrors = (store_question) => {
  const errors = [];

  const res_name = MShopAppStoreQuestionS.checkName(store_question.name);
  if (!res_name.result) errors.push(res_name);

  const res_mobile = MShopAppStoreQuestionS.checkMobile(store_question.mobile);
  if (!res_mobile.result) errors.push(res_mobile);

  const res_email = MShopAppStoreQuestionS.checkEmail(store_question.email);
  if (!res_email.result) errors.push(res_email);

  const res_title = MShopAppStoreQuestionS.checkTitle(store_question.title);
  if (!res_title.result) errors.push(res_title);

  const res_body = MShopAppStoreQuestionS.checkBody(store_question._.store_question_item.body);
  if (!res_body.result) errors.push(res_body);

  return errors;
};

const errorsShow = (errors, container_el) => {
  const alertError = [];
  go(
    errors,
    each((err) => {
      if (err.type == 'name') {
        focusoutNameInput($find('input[name="name"]', container_el));
      } else if (err.type == 'mobile') {
        focusoutMobileInput($find('input[name="mobile"]', container_el));
      } else if (err.type == 'email') {
        focusoutEmailInput($find('input[name="email"]', container_el));
      } else if (err.type == 'title') {
        focusoutTitleInput($find('input[name="title"]', container_el));
      } else if (err.type == 'body') {
        focusoutBodyTextarea($find('.store-qna-editor__body-textarea', container_el));
      }
    }),
  );

  if (alertError.length) {
    MShopShareFramePopUpF.alert({ title: alertError[0].error.text });
  }

  if (!alertError.length && errors.length) {
    MShopShareFramePopUpF.alert({ title: '잘못된 정보가 있습니다.<br>정보를 확인해주세요.' });
  }
};

export const clickCancel = () => {
  go($qs('.question-frame > .don_wrapper > .header'), $find('.don_hide_frame'), (hide_frame_el) => {
    $trigger('click', hide_frame_el);
  });
};

export const clickSubmit = async (e) => {
  const name = $val($find('.store-qna-editor__body-input[name="name"]', e.delegateTarget));
  const mobile = $val($find('.store-qna-editor__body-input[name="mobile"]', e.delegateTarget));
  const email = $val($find('.store-qna-editor__body-input[name="email"]', e.delegateTarget));
  const title = $val($find('.store-qna-editor__body-input[name="title"]', e.delegateTarget));
  const body = $val($find('.store-qna-editor__body-textarea', e.delegateTarget));

  const store_id = $attr('data-store_id', $find('.store-qna-editor', e.delegateTarget));
  const product_id = $attr('data-product_id', $find('.store-qna-editor', e.delegateTarget));
  const item_order_id = $attr('data-item_order_id', $find('.store-qna-editor', e.delegateTarget));
  const is_agree = $find('input[name="is_agree"]', e.delegateTarget).checked;

  const store_question = {
    name,
    mobile,
    email,
    title,
    store_id,
    product_id,
    _: {
      store_question_item: {
        body,
      },
    },
  };

  if (item_order_id) store_question.item_order_id = item_order_id;

  const errors = checkDataErrors(store_question, is_agree);
  if (errors.length) {
    errorsShow(errors, e.delegateTarget);
    return;
  }

  if (!is_agree) {
    MShopShareFramePopUpF.alert({ title: MShopAppStoreQuestionConstantS.QUESTION_ERRORS.is_agree.text });
    return;
  }

  $.don_loader_start();
  try {
    await go(
      $.upload_exif(
        {
          files: box.sel('question_files'),
        },
        {
          method: 'POST',
          url: `/@api/stores/${store_id}/question`,
          data: {
            store_question: JSON.stringify(store_question),
          },
        },
      ),
      async ({ id: question_id, msg }) => {
        $.don_loader_end();
        if (question_id) {
          await MShopShareFramePopUpF.alert({
            title: T('question::문의 접수 완료'),
            body: `
              <p>${T('question::답변은 평일 영업일 기준 1일 ~ 3일 소요됩니다.')}</p>
              <p><a href="/${T.lang}/@/my_question/${question_id}">${T(
              'question::문의 내역 바로가기',
            )}</a></p>
            `,
          });
          MuiF.closeFrame();
        } else {
          await MShopShareFramePopUpF.alert({ title: msg });
        }
      },
    );
  } catch (_err) {
    $.don_loader_end();
    MShopShareFramePopUpF.alert({ title: _err.message || _err });
    console.error(_err);
  }
};

export const controlButtonState = (e) => {
  const name = $val($find('.store-qna-editor__body-input[name="name"]', e.delegateTarget));
  const mobile = $val($find('.store-qna-editor__body-input[name="mobile"]', e.delegateTarget));
  const email = $val($find('.store-qna-editor__body-input[name="email"]', e.delegateTarget));
  const title = $val($find('.store-qna-editor__body-input[name="title"]', e.delegateTarget));
  const body = $val($find('.store-qna-editor__body-textarea', e.delegateTarget));

  const store_id = $attr('data-store_id', $find('.store-qna-editor', e.delegateTarget));
  const product_id = $attr('data-product_id', $find('.store-qna-editor', e.delegateTarget));
  const item_order_id = $attr('data-item_order_id', $find('.store-qna-editor', e.delegateTarget));
  const is_agree = $find('input[name="is_agree"]', e.delegateTarget).checked;

  const store_question = {
    name,
    mobile,
    email,
    title,
    store_id,
    product_id,
    _: {
      store_question_item: {
        body,
      },
    },
  };

  if (item_order_id) store_question.item_order_id = item_order_id;

  const errors = checkDataErrors(store_question);

  const submit_button_el = $find('.store-qna-editor__btn-submit', e.delegateTarget);
  if (errors.length || !is_agree) {
    $removeClass('active', submit_button_el);
  } else {
    $addClass('active', submit_button_el);
  }
};
