import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, ippL, sel, strMap, take } from 'fxjs/es';
import { formatTimeElapsed } from '../../../../../../../services/MarppleShop/shared/util/date.ts';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopUtilConstantS } from '../../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppUpItemS } from '../../../../UpItem/S/Function/module/MShopAppUpItemS.js';
import { MShopAppCommunityS } from '../../../S/Function/module/MShopAppCommunityS.js';
import { MShopAppCommunityTmplS } from '../../../S/Tmpl/module/MShopAppCommunityTmplS.js';
import { photoLeft, reviewListComment } from './detail.js';

const reviewListSnsHref = function (url) {
  if (!url || !linkify.test(url)) return '';
  return 'http://' + url.replace(/^http:\/\/|^https:\/\/|^\/\//, '');
};

const reviewListSnsUrl = function (sns_url) {
  if (!sns_url) return 'sns';
  else if (sns_url.match(/instagram\.com/i)) return 'instagram';
  else if (sns_url.match(/facebook\.com/i)) return 'facebook';
  else if (sns_url.match(/twitter\.com/i)) return 'twitter';
  else if (sns_url.match(/naver\.com/i)) return 'naver';
  else if (sns_url.match(/youtube.com|youtu.be/i)) return 'youtube';
  else if (sns_url.match(/nexon.com|youtu.be/i)) return 'nexon';
  else return 'sns';
};

export const nessRightReview = ({
  article,
  is_store,
  is_mobile,
  is_android,
  photos,
  is_product_review,
  can_edit,
  store_id,
  review_id,
}) => {
  if (!article) return '';
  const review = sel('_.review', article);
  const base_product_size = sel('_.products.0._.base_product_size', review);
  const user_product = sel('_.user_product', review);
  const photo_limit = 5;
  const right_photos = go(photos, ippL, take(photo_limit));

  return html`
    <div class="article-detail-review" data-is_product_review="${is_product_review}">
      <div class="article-detail-review__scroll article-detail-right__scroll">
        <div class="article-detail-review__wrap">
          ${!is_mobile ? nessReviewUser({ article, is_android, can_edit, store_id, review_id }) : ''}
          <div class="article-detail-review__option-name" style="display:none;">
            ${MShopAppUpItemS.makeUpOptionName({ base_product_size, user_product })}
          </div>
          <div class="article-detail-review__body-scroll">
            <div class="article-detail-review__body">
              <div class="article-detail-review__comment">${reviewListComment(review)}</div>
            </div>
          </div>
          <div class="sns_url_wrap" is_sns_url="${!!review.sns_url}">
            <a href="${reviewListSnsHref(review.sns_url)}" class="sns_url" target="_blank">
              <span class="sns_info" sns_info="${reviewListSnsUrl(review.sns_url)}"></span>
              <span>SNS</span>
            </a>
          </div>
          <div class="article-detail-review__right-photos">
            ${strMap(([idx, photo]) => {
              return html`
                <div
                  class="article-detail-review__right-photo ${idx === 0 ? 'active' : ''}"
                  data-idx="${idx}"
                >
                  <img src="${UtilImageS.getResizedUrl({ url: photo.url, width: 100 })}" alt="review photo" />
                </div>
              `;
            }, right_photos)}
            ${is_product_review
              ? `<div class="article-detail-info__date">${formatTimeElapsed(article.created_at)}</div>`
              : ''}
          </div>
          <div class="article-detail-review__product">
            ${MShopAppCommunityTmplS.articleProduct({ review, is_store, created_at: article.created_at })}
          </div>
        </div>
      </div>
    </div>
  `;
};

export const nessReviewUser = ({ article, is_android, can_edit, store_id, review_id }) => {
  const LIMIT_NAME_NUMBER = 5;
  const masked_email =
    sel('_.user.email', article) && MShopAppCommunityS.userSubstr(sel('_.user.email', article));
  const user_nick_name = UtilS.escape(sel('_.user.nick_name', article));
  const user_name =
    UtilS.escape(user_nick_name) || UtilS.escape(masked_email).slice(0, LIMIT_NAME_NUMBER) || 'NONAME';
  const user_profile = MShopCoreTmplS.userProfile(UtilS.escape(sel('_.user.profile_image', article)));

  return html`
    <div class="article-detail-review__user ${is_android ? 'article-detail-review__user--flex' : ''}">
      <div class="article-detail-review__user-profile-wrap">${user_profile}</div>
      <div class="article-detail-review__user_name ${MShopUtilConstantS.UNICA_KLASS}">${user_name}</div>
      ${can_edit
        ? `
      <button
        type="button"
        _type="${article.type}"
        item_id="${article.id}"
        store_id="${store_id}"
        data-review_id="${review_id}"
        class="article-detail__btn_del--ness"
      >
        ${T('community::삭제')}
      </button>
        `
        : ''}
      ${is_android
        ? html`
            <div class="article-detail__more article-detail-review__more">
              <span class="article-detail__more-icon article-detail-review__more-icon"></span>
              <div class="article-detail__more-list article-detail-review__more-list">
                <div
                  class="article-detail__more-item article-detail__more-item--report article-detail-review__more-item article-detail-review__more-item--report"
                  data-id="${article.id}"
                >
                  ${T('community::신고')}
                </div>
              </div>
            </div>
          `
        : ''}
    </div>
  `;
};

export const reviewDetailTabIcon = (is_list) => {
  return html`
    <div class="article-detail__tab-button" data-is_list="true">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="14.5"
          y="2.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="14.5"
          y="8.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="14.5"
          y="14.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="8.5"
          y="2.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="8.5"
          y="8.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="8.5"
          y="14.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="2.5"
          y="2.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="2.5"
          y="8.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
        <rect
          x="2.5"
          y="14.5"
          width="3"
          height="3"
          fill="${is_list ? 'black' : '#9F9F9F'}"
          stroke="${is_list ? 'black' : '#9F9F9F'}"
        />
      </svg>
    </div>
    <div class="article-detail__tab-button" data-is_list="false">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="14.5"
          y="2.5"
          width="3"
          height="15"
          fill="${is_list ? '#9F9F9F' : 'black'}"
          stroke="${is_list ? '#9F9F9F' : 'black'}"
        />
        <rect
          x="2.5"
          y="2.5"
          width="9"
          height="15"
          fill="${is_list ? '#9F9F9F' : 'black'}"
          stroke="${is_list ? '#9F9F9F' : 'black'}"
        />
      </svg>
    </div>
  `;
};

export const photoItem = ({ id, photo_url, line_idx }) => {
  const resize_url = UtilImageS.getResizedUrl({ url: photo_url, width: 150, format: 'jpg' });
  return html`
    <div class="article-detail-photo-list__photo" data-article_id="${id}" data-line_idx="${line_idx}">
      <img src="${resize_url}" alt="review photo list item" />
    </div>
  `;
};

/**
 *
 * @param id
 * @param photo_url
 * @returns {string}
 */
export const horizontalPhotoItem = ({ id, photo_url }) => {
  const resize_url = UtilImageS.getResizedUrl({ url: photo_url, width: 150, format: 'jpg' });
  return html`
    <div class="article-detail__horizontal-photos__photo" data-article_id="${id}" data-cursor_id="${id}">
      <img src="${resize_url}" alt="review photo list item" />
    </div>
  `;
};

export const nessPhotoReview = ({
  article,
  store_id,
  store_name,
  login_user,
  store_user_id,
  is_mobile,
  is_store,
  is_android,
  is_product_review = false,
  is_list = false,
  can_edit = false,
}) => {
  const review_id = sel('_.review.id', article);
  return html`
    <div
      class="article-detail"
      is_store="${is_store || 'false'}"
      data-store_id="${store_id}"
      data-article_type="${article.type}"
      data-article_id="${article.id}"
      data-fx-json="${$dataStr(article)}"
      data-is_product_review="${is_product_review}"
      data-is_list="${is_list}"
    >
      <div class="article-detail__tab">${reviewDetailTabIcon(is_list)}</div>
      <div class="article-detail__horizontal-photos-button prev disabled">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/10/original/e933031bf8bb5a4b588cecb1751482466fedca9c1.png"
          alt="photos prev button"
        />
      </div>
      <div class="article-detail__horizontal-photos-button next disabled">
        <img
          src="//s3.marpple.co/files/u_2308668/2023/10/original/a9f4fab9a02b1d949f706f4fa447d266bf7a37261.png"
          alt="photos next button"
        />
      </div>
      <div class="article-detail__horizontal-photos-wrap">
        <div class="article-detail__horizontal-photos"></div>
      </div>
      <div class="article-detail__left">
        ${photoLeft({ photos: sel('_.review.files', article) })}
        ${is_mobile
          ? html`
              <div class="article-detail__left-user">
                ${nessReviewUser({
                  article,
                  is_android,
                  can_edit,
                  store_id,
                  review_id,
                })}
              </div>
            `
          : ''}
      </div>
      <div class="article-detail__right">
        ${nessRightReview({
          article,
          login_user,
          store_name,
          store_user_id,
          is_mobile,
          is_store,
          is_android,
          photos: sel('_.review.files', article),
          is_product_review,
          can_edit,
          store_id,
          review_id,
        })}
      </div>
    </div>
    <div
      class="article-detail-photo-list"
      data-is_product_review="${is_product_review}"
      data-is_list="${is_list}"
    ></div>
  `;
};
