import { openMpBpdTipFrame } from '../../../../www/module/front/app/mp/mp.maker.tip.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const openMakerTipModal = (func = () => true) => {
  if (!UtilS.isNessApp()) {
    return;
  }

  if (!window.localStorage.getItem('ness-first-how-to-custom')) {
    const timeout = setInterval(async () => {
      try {
        const tip_frame = func() && (await openMpBpdTipFrame(true));
        if (tip_frame) {
          clearTimeout(timeout);
          tip_frame.frame_opt.closed = () => {
            window.localStorage.setItem('ness-first-how-to-custom', true);
          };
        }
      } catch (e) {
        clearTimeout(timeout);
      }
    }, 100);
  }
};
