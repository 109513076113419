import $dataStr from 'fxdom/es/dataStr.js';
import { head, html, strMap } from 'fxjs/es';
import { CateListConstantS } from '../../../../../CateList/S/Constant/module/CateListConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductSubConstantS } from '../../../Product/Sub/S/Constant/module/MShopAppProductSubConstantS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

const { title } = MShopAppProductSubConstantS.SUB_TYPE.all;
export const styles = (styles, is_mobile) => {
  const { id: activeId } = head(styles) || {};
  /* TODO 카테고리 하드코딩 */
  const uri = `/${T.lang}/@/product/list/all?cate_list_id=${CateListConstantS.CLOTH_LIST_ID}&ss_ids=${activeId}`;
  const post_msg = { screen: { type: 'util', title, uri } };
  return html`
    <div class="app-home-styles">
      ${MShopAppHomeTmplS.homeTitle({
        title: T('mshop::Styles::t2'),
        sub_title: T('mshop::Styles::t1'),
        link: post_msg,
      })}
      <div class="app-home-styles-tab">
        <div class="app-home-styles-tab__header">
          <div class="app-home-styles-tab__header-scroll">
            <div class="app-home-styles-tab__header-contents">
              ${strMap(
                ({ id, name, thumbnail_image_url }) => html`
                  <a href="#" class="app-home-styles-tab__header-button" data-id="${id}">
                    <div
                      class="app-home-styles-tab__header-image-box ${id === activeId
                        ? 'app-home-styles-tab__header-image-box--is-active'
                        : ''}"
                    >
                      <img
                        src="${UtilS.escape(thumbnail_image_url) || ''}"
                        alt="${UtilS.escape(name)}"
                        class="app-home-styles-tab__header-image"
                      />
                    </div>
                    <span class="app-home-styles-tab__header-text">${UtilS.escape(name)}</span>
                  </a>
                `,
                styles,
              )}
            </div>
          </div>
        </div>
        <div class="app-home-styles-tab__body">
          <div class="app-home-styles-tab__body-inner">
            ${strMap(
              ({ id, _: { style_filter_images } }) => html` <div
                class="app-home-styles-tab__contents ${id === activeId
                  ? 'app-home-styles-tab__contents--is-active'
                  : ''}"
                data-id="${id}"
              >
                ${strMap(
                  ({ product_url, product_image_url }) =>
                    html`<a
                      href="/${T.lang}${UtilS.escape(product_url)}"
                      class="app-home-styles-tab__anchor"
                      data-post-message="${$dataStr({
                        screen: { type: 'util', uri: UtilS.escape(product_url) },
                      })}"
                      ><img
                        class="app-home-styles-tab__image lazy-src"
                        data-src="${UtilS.escape(product_image_url)}"
                        alt=""
                    /></a>`,
                  style_filter_images,
                )}
              </div>`,
              styles,
            )}
          </div>
        </div>
      </div>
      <div class="app-home-styles__button-box">
        <a
          href="/${T.lang}/@/hello"
          class="app-home-styles__button"
          data-post-message="${$dataStr({
            navigate: {
              name: 'SignUpSeller',
              params: {
                title: T('mshop::QuickMenu::t1'),
                uri: `/${T.lang}/@/hello`,
              },
            },
          })}"
        >
          ${T('mshop::Styles::t3')}
        </a>
      </div>
    </div>
  `;
};
