import { $appendTo, $el, $els, $find, $off, $on } from 'fxdom/es';
import {
  add,
  each,
  apply,
  reject,
  filter,
  go,
  head,
  html,
  identity,
  isNil,
  last,
  map,
  maxBy,
  rangeL,
  reduce,
  sel,
} from 'fxjs/es';
import './infinite_scroll.styl';

import { addGridItems, adjustColumnDiff, calcColumnHeightDiff, initGridStack } from './private/grid.js';
import { initLoadObserver } from './private/observer.js';
import { log } from './private/config.js';
import { replaceUrl, toFragment } from './private/util.js';
import { UtilF } from '../../../F/Function/module/UtilF.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';

const infinite_scroll_cl = 'infinite-scroll';
const top_observer_cl = infinite_scroll_cl + '__top-observer';
const content_cl = infinite_scroll_cl + '__content_wrapper';
const bottom_observer_cl = infinite_scroll_cl + '__bottom-observer';
const plc_observer_cl = infinite_scroll_cl + '__placeholder__observer';
const plc_content_cl = infinite_scroll_cl + '__placeholder__content';
const plc_cl = infinite_scroll_cl + '__placeholder';

export const init = async (options) => {
  await import('./private/viewport.js');

  const {
    getItems,
    makeItemHtml,
    uniqueSel,
    item_margin,
    getColumnCount = () => 3,
    container_el,
    initial_load = 0,
    makeDividerHtml = () => '',
    hooks: { onEmpty = () => '', onFinish = () => '', onAdd = () => '' },
    pause: { initial: paused = false, play_btn_el, no_blank_space = false } = {},
  } = options;

  let width;

  // resize 대응
  const is_mobile = MShopUtilF.isMobile();

  if (!is_mobile) {
    const resizeStartListener = (e) => {
      width = e.detail.innerWidth;
    };

    const resizeEndListener = (e) => {
      if (width == e.detail.innerWidth) return;
      log('end resize');
      restart(options);
    };

    $on('resizeStart', resizeStartListener)(window);
    $on('resizeEnd', resizeEndListener)(window);

    container_el.reset_infinite = () => {
      $off('resizeStart', resizeStartListener)(window);
      $off('resizeEnd', resizeEndListener)(window);
    };
  }

  const start_page = +new URLSearchParams(location.search).get('page');

  // state
  const state = {
    max_page: start_page + 1 || 0,
    min_page: start_page > 0 ? start_page - 1 : 0,
    played: false,
    current_stack: null,
    item_ids: [],
    first_item: null,
    last_item: null,
    finished: false,
  };

  const columns = getColumnCount();

  const from_top = !start_page || !(start_page > 0);
  const need_play_btn = from_top && paused;
  const need_placeholder = columns > 1;

  log(`
    [Infinite Scroll]
    min: ${state.min_page}
    max: ${state.max_page}
    from_top: ${from_top}
    played: ${state.played}
    columns: ${columns}
    page_param: ${start_page}
  `);

  // elements declaration
  const top_el = $el(html`<div class="${top_observer_cl}"></div>`);
  const placeholder_el = $el(
    html`<div class="${plc_cl}" style="min-height:${need_placeholder ? state.min_page * 1000 : 0}px">
      <div class="${plc_observer_cl}"></div>
      <div class="${plc_content_cl}"></div>
    </div>`,
  );
  const bottom_el = $el(html`<div class="${bottom_observer_cl}"></div>`);
  const content_wrapper_el = $el(html`<div class="${content_cl}"></div>`);

  const placeholder_content_el = $find(`.${plc_content_cl}`, placeholder_el);
  const placeholder_observer_el = $find(`.${plc_observer_cl}`, placeholder_el);

  // 내부 dom 구조 초기화
  go(
    [top_el, placeholder_el, content_wrapper_el, need_play_btn ? play_btn_el : '', bottom_el],
    filter(identity),
    toFragment,
    $appendTo(container_el),
  );

  // grid 초기화
  initGridStack(content_wrapper_el, { columns, item_margin });
  if (need_placeholder)
    initGridStack(placeholder_content_el, {
      columns,
      is_reverse: true,
      item_margin,
    });

  const updateItemCache = (items, direction) => {
    const key = direction == 'top' ? 'first_item' : 'last_item';
    state[key] = (direction == 'top' ? head : last)(items);
  };

  // 데이터 쌓기
  const stack = async (page, direction) => {
    if (state.finished) return { success: false };

    log(`Intersect:: ${page} ${direction}`);
    const is_top = direction == 'top';
    const wrapper = is_top && need_placeholder ? placeholder_content_el : content_wrapper_el;

    const items = await getItems(page);

    // update first or last item data for divider
    updateItemCache(items, direction);

    if (!items?.length) {
      if (page == 0) onEmpty();
      onFinish();
      return { success: false };
    }

    const { item_els, wrapper_els } = await go(
      items,
      reject((item) => {
        const id = uniqueSel(item);
        const has = state.item_ids.indexOf(id) !== -1;

        if (!has) state.item_ids.push(id);
        return has;
      }),
      (items) => {
        const htmls = [];
        let prev = is_top ? null : state.last_item;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const is_very_first = page == 0 && i == 0;
          const divider_html = makeDividerHtml(item, prev, is_very_first);
          const item_html = makeItemHtml(item);

          htmls.push(divider_html, item_html);
          prev = item;
        }

        if (is_top) htmls.push(makeDividerHtml(state.first_item, last(items)));

        return filter(identity, htmls);
      },
      reduce(add),
      async (item_html_list) => {
        if (!item_html_list) return { wrapper_els: null, item_els: null };
        const item_els = $els(item_html_list);
        const wrapper_els = await addGridItems(wrapper, item_els, {
          page,
          direction,
          makeDividerHtml,
        });

        return { wrapper_els, item_els };
      },
    );

    log(`Intersect End:: ${page} ${direction}`);

    onAdd(item_els);

    return {
      success: true,
      wrapper_els,
    };
  };

  // paging with scroll restoration
  if (!isNil(start_page) && start_page > 0) {
    // page > 1 이면 page - 1 에서 bottom 으로 스크롤
    state.current_stack = await stack(state.min_page, 'bottom')
      .then(from_top ? identity : () => stack(state.min_page + 1, 'bottom'))
      .then(({ success, wrapper_els }) => {
        if (!success && start_page > 0) {
          // 현재 페이지에 아이템이 없으면 초기화
          replaceUrl(UtilF.updateUrlParams({ page: null }));
          return restart(options);
        }

        if (start_page == 0) return;

        const scroll = go(
          wrapper_els,
          map((el) => el.getBoundingClientRect().top),
          apply(Math.min),
        );

        // TODO: 왜 넣었는지 모르겠음, 오히려 올라갈 때 이슈 있어서 일단 지워보고 문제 생기면 다시 대응하기로
        // document.body.style.minHeight =
        //   (state.min_page > 0 ? window.innerHeight : 0) + scroll + window.scrollY + 'px';

        log(`%c wrappers`, 'color: gray', wrapper_els);
        log(`%c scroll to ${scroll}`, 'color: purple');
        window.scrollBy(0, scroll);
      });
  } else {
    // initial load (only load min_page == 0)
    await go(
      rangeL(initial_load),
      map((page) => {
        state.current_stack = stack(page, 'bottom');
        return state.current_stack;
      }),
      each(({ success }) => {
        if (!success) state.finished = true;
      }),
    );

    if (need_play_btn) {
      // 더보기 필요할 경우, 뒤에 데이터 더 있는지 확인
      const items = await getItems(initial_load);
      if (items.length == 0) state.finished = true;
    }

    state.max_page = Math.max(initial_load - 1, 0);
  }

  // bottom observer
  const play = () => {
    log('bottom observing start: play');
    if (state.finished) return;
    if (state.played) return;

    initLoadObserver(
      async (entry, observer) => {
        log('[bottom observer]', state.max_page);
        const { success } = await (state.current_stack = stack(state.max_page++, 'bottom'));
        if (!success) {
          observer.disconnect();
        }
        return success;
      },
      {
        target_el: bottom_el,
        direction: 'bottom',
        loop: true,
      },
    );
    state.played = true;
  };

  const removePlayBtn = () => {
    play_btn_el.style.display = 'none';
    content_wrapper_el.style.height = 'auto';
  };

  if (need_play_btn) {
    if (state.finished) return removePlayBtn();

    play_btn_el.onclick = () => {
      removePlayBtn();
      play();
    };

    if (no_blank_space) {
      const { col_el, diff } = go(calcColumnHeightDiff(content_wrapper_el, -1), maxBy(sel('diff')));

      const fitted_height = col_el.getBoundingClientRect().height - diff;
      content_wrapper_el.style.height = `${fitted_height}px`;
      content_wrapper_el.style.overflow = 'hidden';
    }
  } else {
    play();
  }

  // top observer
  if (state.min_page !== 0) {
    initLoadObserver(
      (entry, observer) => {
        // 맨 위에 도착 시 observer 끊기
        if (state.min_page == 0) {
          observer.disconnect();
          return false;
        }

        return (state.current_stack = stack(--state.min_page, 'top'));
      },
      {
        target_el: placeholder_observer_el,
        direction: 'top',
        loop: true,
      },
    );
  }

  log('before initial top observer');

  // jump_top_observer
  const jump_top_observer = new IntersectionObserver(
    ([entry]) => {
      log('observe top observer - jump to', entry.isIntersecting);
      // top_end 위쪽으로 스크롤 올라온 경우 -> 3페이지 미만이면 순차 로드, 아니면 처음부터 로드
      if (entry.isIntersecting && state.min_page > 2) {
        log(`%c restart infinite by top end observer! ${entry.boundingClientRect.top}`, 'color: red');
        restart(options);
      }
    },
    {
      rootMargin: '0px 0px 0px 0px',
    },
  );

  const column_diff_adjust_observer = new IntersectionObserver(
    function adjustListener([entry]) {
      // page == 0 & direction top 이면 위치 보정
      if (entry.boundingClientRect.top < 0 && state.min_page == 0 && start_page > 1 && need_placeholder) {
        log(`ajdust column diffs:: page - ${state.min_page}, ${entry.isIntersecting ? '[in]' : '[out]'}`);

        // 맨 위 다 그리고 나서 계산해야 함

        state.current_stack.then(() => {
          adjustColumnDiff(placeholder_content_el, entry.isIntersecting);
        });
      }
    },
    {
      rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`,
    },
  );

  jump_top_observer.observe(top_el);
  column_diff_adjust_observer.observe(top_el);

  return {
    play,
  };
};

export const restart = (options) => {
  options.container_el.innerHTML = '';
  options.container_el?.reset_infinite?.();
  document.body.style.minHeight = 'auto';
  return init(options);
};
