import { html } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { MShopCoreTmplS } from '../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';

export const homeTitle = ({ title, sub_title, href, link, theme = 'black', align = 'left' }) => {
  return html`
    <div class="app-home__title" data-theme="${theme}" data-align="${align}">
      <div class="app-home__sub-title">${sub_title}</div>
      ${!link
        ? html` <div class="app-home__mid-title">${title}</div>`
        : html`
            <a
              class="app-home__more-anchor"
              href="${href || link?.screen?.uri}"
              data-post-message="${$dataStr(link)}"
            >
              <div class="app-home__mid-title">${title}</div>
              <span class="app-home__more-anchor-arrow">
                ${MShopCoreTmplS.arrowIcon({ color: theme === 'black' ? '#111111' : 'white' })}
              </span>
            </a>
          `}
    </div>
  `;
};
