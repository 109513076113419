import { $after, $is, $next, $prependTo } from 'fxdom/es';
import { go, pick } from 'fxjs/es';
import { getRootEl } from '../../../../Feed/F/util.js';
import { removeCssUnit } from '../../../../Home/F/utils/style.js';
import { SELECTOR } from '../../../S/constant.js';
import { isNotContenteditable } from '../../util.js';
import { createEmptyP } from '../medium-editor.js';
import { createActionStack } from './bug_tracker.js';

const checkClosestSiblingFromPoint = (direction, start_point) => {
  const moved_y = start_point.y + (direction == 'top' ? -5 : 5);
  const el$ = document.elementFromPoint(start_point.x, moved_y);
  return $is('.editable', el$)
    ? checkClosestSiblingFromPoint(direction, { x: start_point.x, y: moved_y })
    : el$;
};

export const newlineAroundNotEditable = (click_event) => {
  // click target 이 img container 이거나 editable 일 경우
  const is_editable = $is('.editable', click_event.target);

  if (!is_editable) return;

  const $editable = click_event.target;
  const first_child = $editable.children[0];
  const distance_top = click_event.offsetY;

  // not editable 사이 클릭 or 이미지가 제일 마지막인 경우
  if (distance_top > 100) {
    const upper$ = go(checkClosestSiblingFromPoint('top', pick(['x', 'y'], click_event)), getRootEl);
    if (!upper$) return;

    const lower$ = $next(upper$);
    const is_last_el = !lower$;
    const is_upper_not_editable = isNotContenteditable(upper$);

    if ((is_last_el && is_upper_not_editable) || (is_upper_not_editable && isNotContenteditable(lower$))) {
      const $p = createEmptyP();
      $after($p, upper$);
      window.getSelection().collapse($p, 0);
      createActionStack('new line around not editable', { event: click_event, distance_top, el: $p });
    }
  } else {
    // 최상단과 not editable 사이 클릭
    if (!$is(SELECTOR.NOT_EDITABLE, first_child)) return;

    const paddingTop = +removeCssUnit(getComputedStyle($editable).paddingTop);

    const should_insert_newline = distance_top > 0 && distance_top < paddingTop;
    if (!should_insert_newline) return;

    const $p = createEmptyP();
    $prependTo($editable, $p);
    window.getSelection().collapse($p, 0);

    createActionStack('new line around not editable', { event: click_event, distance_top, el: $p });
  }
};
