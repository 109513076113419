import { $findAll, $qs, $setHTML, $setOuterHTML } from 'fxdom/es';
import { delay, go, goS, omit, stop, tap, each } from 'fxjs/es';
import { AdScriptFbF } from '../../../AdScript/Fb/F/Function/module/AdScriptFbF.js';
import { AdScriptGaF } from '../../../AdScript/Ga/F/Function/module/AdScriptGaF.js';
import { rune } from 'rune-ts';
import { ProductMultiOptionSelector } from '../../../../services/MarppleShop/renderApp/components/cells/ProductOptionQuantitySelector/ProductMultiOptionSelector.ts';
import { AdScriptKakaoF } from '../../../AdScript/Kakao/F/Function/module/AdScriptKakaoF.js';
import { AdScriptNaverF } from '../../../AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { GoodsTypeS } from '../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { resetUserCounts, setUpCSs } from '../../../MPay/Cart/F/fs.js';
import { MShopAppCartF } from '../../../MShop/App/Cart/F/Function/module/MShopAppCartF.js';
import { MShopAppProductPbOptionF } from '../../../MShop/App/Product/PbOption/F/Function/module/MShopAppProductPbOptionF.js';
import { MShopAppProductPbOptionS } from '../../../MShop/App/Product/PbOption/S/Function/module/MShopAppProductPbOptionS.js';
import { setMultiOptionVisibility } from '../../../MShop/App/ProductDetail/F/Mui/handles.js';
import {
  makePossibleQuantityHtml,
  makeProductQuantityHtml,
} from '../../../MShop/App/ProductDetail/S/Tmpl/partials/productDetailForm.js';
import { MShopShareFramePopUpF } from '../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { PBErrorConstantS } from '../../../PB/Error/S/Constant/module/PBErrorConstantS.js';
import { creatorPriceOfProduct } from '../../../ProductColorPrice/S/fs.js';
import { UserJwtF } from '../../../User/Jwt/F/Function/module/UserJwtF.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

function createUpC({ product_color, base_product_size_id, product_option_id, quantity, type = 'cart' }) {
  const up_c = setUpCSs(product_color, quantity, {
    type,
    base_product_color_id: product_color.base_product_color_id,
    base_product_id: product_color.base_product_id,
    base_product_size_id,
    product_option_id,
    quantity: Math.max(1, quantity || 1),
    store_id: box.sel('store_id'),
  });
  up_c._ = { up_c_ss: [{ ...up_c }] };
  return up_c;
}

const createBody = ({
  product_color,
  base_product_size_id,
  original_product_id,
  product_option_id,
  quantity,
  type = 'cart',
  goods_type_id,
  hyundai_n_products,
}) => ({
  product_color: GoodsTypeS.isMps(goods_type_id)
    ? omit(['id', 'thumbnails', 'product_faces', 'is_store_original'], product_color)
    : product_color,
  up_c: createUpC({ product_color, base_product_size_id, product_option_id, quantity, type }),
  original_product_id,
  goods_type_id,
  hyundai_n_products,
});

const checkPrice = (product_color, original_product_id, product_option_id) => {
  // 일단은 문제 없음 - 정익
  return (
    true ||
    $.get('/@api/compare_price_original_product', {
      original_product_id,
      product_option_id,
      price: creatorPriceOfProduct(product_color, 1, undefined, product_option_id),
    })
  );
};

const afterCartMaxPurchase = (quantity) => {
  if (UtilS.isNessApp()) {
    return afterCartMaxPurchaseNess(quantity);
  }
  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');
  const max_purchase_per_user = box.sel('product_detail->stores_product->max_purchase_per_user');
  if (max_purchase_per_user) {
    const _possible_quantity = possible_quantity - quantity;
    box.set('product_detail->stores_product->possible_quantity', _possible_quantity);
    if (!MShopUtilF.isMobile()) {
      const quantity_el = $qs('.pd-field__max_count');
      if (quantity_el) {
        $setOuterHTML(makePossibleQuantityHtml(max_purchase_per_user, _possible_quantity), quantity_el);
      }
    }
  }
};

const afterCartMaxPurchaseNess = (quantity) => {
  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');
  const max_purchase_per_user = box.sel('product_detail->stores_product->max_purchase_per_user');
  if (max_purchase_per_user) {
    const _possible_quantity = possible_quantity - quantity;
    box.set('product_detail->stores_product->possible_quantity', _possible_quantity);
    if (!MShopUtilF.isMobile()) {
      $setHTML(
        makeProductQuantityHtml(
          _possible_quantity,
          max_purchase_per_user,
          box.sel('product_detail->stores_delivery_company->is_separate_shipping'),
        ),
        $qs('.pd__quantity'),
      );
    }
  }
};

const resetMultiOptionQuantity = () => {
  // TODO @yjj Ness 멀티옵션 지원하면 풀기
  if (UtilS.isNessApp()) return;

  if (MShopUtilF.isMobile()) return;

  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');
  const multi_options = rune
    .getView($qs(`.${ProductMultiOptionSelector}`), ProductMultiOptionSelector)
    .setTotalMaxQuantity(possible_quantity)
    .getProductOptions();

  /* 이미 담긴 옵션들이 possible_quantity보다 많으면 옵션이 초기화 되어 멀티 옵션을 가려줘야 한다.  */
  if (!multi_options.length) {
    setMultiOptionVisibility(false);
  }
};

export const addCart = async ({
  product_color,
  base_product_size_id,
  product_option_id,
  original_product_id,
  quantity,
  goods_type_id,
  hyundai_n_products,
  is_cart,
}) => {
  $.don_loader_start();
  if (!(await checkPrice(product_color, original_product_id, product_option_id))) {
    $.don_loader_end();
    return location.reload();
  }

  const scroll_top_promise = !MShopUtilF.isMobile() ? MShopUtilF.moveScrollToTop(200) : undefined;
  try {
    const up_c = await $.post(
      '/@api/ups/add',
      createBody({
        product_color,
        base_product_size_id,
        original_product_id,
        product_option_id,
        quantity,
        type: is_cart ? 'cart' : 'direct',
        goods_type_id,
        hyundai_n_products,
      }),
    );
    await goS(
      up_c,
      async (up_c) => {
        if (up_c.exist) {
          await $.alert(T('This item is already in your cart'));
          return stop();
        }
        if (up_c._.user) await go(UserJwtF.loginMessage(up_c._.user), delay(600));
        return up_c;
      },
      tap(() => {
        if (is_cart) {
          const data = { ...box.sel('user_counts') };
          data.cart += Number(quantity);
          resetUserCounts({ data });
        }
      }),
      () => {
        return afterCartMaxPurchase(quantity);
      },
      async () => {
        if (MShopUtilF.isMobile()) {
          await MuiF.removeFrame();
        }
        if (is_cart) {
          await scroll_top_promise;
          MShopUtilF.popToastMsg(T('Added to cart'), 'cart');
          AdScriptNaverF.mpsAddToCart();
          AdScriptGaF.addToCartMPS({ quantity });
          if (UtilS.isNessApp()) {
            AdScriptKakaoF.nessAddToCart();
          }

          try {
            const track_add_to_cart = {
              content_name: product_color['name' + _en],
              content_ids: product_color.product_id,
              value: up_c['discounted_price' + _en],
              content_type: 'product',
              currency: ET.lang == 'kr' ? 'KRW' : T.lang == 'jp' ? 'JPY' : 'USD',
            };

            AdScriptFbF.addToCart(track_add_to_cart);
          } catch (e) {}
        }
      },
    );
    return up_c;
  } catch (e) {
    if (e.json) {
      e.json().then(({ message }) => {
        try {
          const err = JSON.parse(message);
          return MShopShareFramePopUpF.alert({
            title: err.title,
            body: err.message,
          });
        } catch {
          return MShopShareFramePopUpF.alert({
            body: message,
          });
        }
      });
    } else {
      try {
        const err = JSON.parse(e.message);
        return MShopShareFramePopUpF.alert({
          title: err.title,
          body: err.message,
        });
      } catch {
        return MShopShareFramePopUpF.alert({
          body: e.message,
        });
      }
    }
  }

  $.don_loader_end();
};

export const addPbCart = async ({ form, product_color, spo, is_cart }) => {
  $.don_loader_start();
  const is_mobile = MShopUtilF.isMobile();

  try {
    const is_spo_options = MShopAppProductPbOptionS.isSpoOptions(spo);

    /*
     * TODO 1인당 한정판매 로직이 중복으로 들어가있음 해결을 봐야함...
     *  어거지로 해결함..
     * */
    const quantity_els = go(form, $findAll('input[name="quantity"]'));
    const pb_up_materials = await MShopAppProductPbOptionF.makePbUpMaterialService({
      quantity_els,
      product_color,
      spo,
      form,
      is_cart,
      is_spo_options,
    });

    if (!pb_up_materials.length) return;

    try {
      const ups = await MShopAppCartF.addPbUps(pb_up_materials, is_cart);

      if (is_cart) {
        each((up) => {
          afterCartMaxPurchase(up.quantity);
          AdScriptGaF.addToCartMPS({ quantity: up.quantity });
          if (UtilS.isNessApp()) {
            AdScriptKakaoF.nessAddToCart();
          }
          try {
            const track_add_to_cart = {
              content_name: product_color['name' + _en],
              content_ids: product_color.product_id,
              value: up['discounted_price' + _en],
              content_type: 'product',
              currency: ET.lang == 'kr' ? 'KRW' : T.lang == 'jp' ? 'JPY' : 'USD',
            };

            AdScriptFbF.addToCart(track_add_to_cart);
          } catch (e) {}
        }, ups);

        if (is_spo_options) {
          resetMultiOptionQuantity();
        }
      }

      return ups;
    } catch (e) {
      const axios_err_msg = e.response?.data?.message;
      const custom_error = PBErrorConstantS.findError(axios_err_msg);
      if (custom_error) {
        await $.alert(custom_error.text);
      } else {
        try {
          const err = JSON.parse(axios_err_msg || e.message);
          await MShopShareFramePopUpF.alert({
            title: err.title,
            body: err.message,
            ok: ET('mps2::modal::확인'),
          });
        } catch {
          await MShopShareFramePopUpF.alert({
            body: axios_err_msg || e.message,
          });
        }
      }
      window.location.reload();
    }
  } catch (e) {
    try {
      const err = JSON.parse(e.message);
      return is_mobile
        ? MShopUtilF.popToastMsg(err.message, 'confirm', { position: 'top' })
        : MShopShareFramePopUpF.alert({
            title: err.title,
            body: err.message,
            ok: ET('mps2::modal::확인'),
          });
    } catch {
      return MShopShareFramePopUpF.alert({
        body: e.message,
      });
    }
  } finally {
    $.don_loader_end();
  }
};
