import { $data, $delegate, $find, $qs, $removeClass, $setText } from 'fxdom/es';
import { go, html } from 'fxjs/es';
import {
  defnProductStoryEvent,
  setProductStoryTextArea,
} from '../../../../../Creator/ProductStoryEditor/F/event.js';
import {
  snsInitFacebookEditor,
  snsInitInstagramEditor,
  snsInitTwitterEditor,
  snsInitYoutubeEditor,
} from '../../../../../Creator/ProductStoryEditor/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { initTooltips2 } from '../../../../../Util/F/tooltip.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPbProductCreatorF } from '../../../PbProductCreator/F/Function/module/MShopStudioPbProductCreatorF.js';
import { MShopStudioPreDiscountProductF } from '../../../PreDiscountProduct/F/Function/module/MShopStudioPreDiscountProductF.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { isEbookByCateItemId } from '../../S/Function/util.js';
import { MShopStudioDigitalProductCreatorTmplS } from '../../S/Tmpl/module/MShopStudioDigitalProductCreatorTmplS.js';
import { MShopStudioDigitalProductCreatorMuiF } from '../Mui/module/MShopStudioDigitalProductCreatorMuiF.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';

const _getSelectedCategoryMockData = (cate_item_id) => {
  /* TODO 카테고리 하드코딩 */
  switch (cate_item_id) {
    case MShopStudioDigitalProductCreatorConstantS.WALL_PAPER_STATE.sp_cate_item_id:
      return MShopStudioDigitalProductCreatorConstantS.WALL_PAPER_STATE;
    case MShopStudioDigitalProductCreatorConstantS.VOICE_PACK_STATE.sp_cate_item_id:
      return MShopStudioDigitalProductCreatorConstantS.VOICE_PACK_STATE;
    case MShopStudioDigitalProductCreatorConstantS.GOOD_NOTE_STATE.sp_cate_item_id:
      return MShopStudioDigitalProductCreatorConstantS.GOOD_NOTE_STATE;
    case MShopStudioDigitalProductCreatorConstantS.DIGITAL_ASSET_STATE.sp_cate_item_id:
      return MShopStudioDigitalProductCreatorConstantS.DIGITAL_ASSET_STATE;
    case MShopStudioDigitalProductCreatorConstantS.E_BOOK_STATE.sp_cate_item_id:
      return MShopStudioDigitalProductCreatorConstantS.E_BOOK_STATE;
  }
};

export const pushCreatorPage =
  ({ store_ecommerce_info, ebook_categories }) =>
  async (e) => {
    go($qs('.digital-product-creator-frame__btn-back-page'), $removeClass('hide'));
    const cate_item_id = $data(e.currentTarget);
    const digital_product = _getSelectedCategoryMockData(cate_item_id);
    const front_data =
      MShopStudioDigitalProductCreatorConstantS.ONLY_FRONT_END_DATA[digital_product?.sp_cate_item_id];
    /* T함수의 결과가 promise라 일단 이렇게.. */
    const cate_name = await front_data.cate_view_name;

    return MuiF.pushPage(MShopStudioDigitalProductCreatorMuiF.page, (page, [tab]) => {
      page.custom_header = () => html`<div class="noop"></div>`;
      tab.template = () => {
        const action = MShopStudioPbProductCreatorF.setEditorAction();
        return MShopUtilF.isMobile()
          ? MShopStudioDigitalProductCreatorTmplS.baseCreatorMo({
              digital_product,
              store_ecommerce_info,
              crew_domain_name: box.sel('crew_domain_name'),
              ...(isEbookByCateItemId(cate_item_id) ? { ebook_categories } : {}),
            })
          : MShopStudioDigitalProductCreatorTmplS.baseCreatorPc({
              digital_product,
              action,
              store_ecommerce_info,
              store_url: box.sel('store_url'),
              crew_domain_name: box.sel('crew_domain_name'),
              ...(isEbookByCateItemId(cate_item_id) ? { ebook_categories } : {}),
            });
      };
      tab.appended = (tab_el) => {
        box.set('digital_product_thumbnail_files', box.sel('digital_product_thumbnail_files') || []);
        box.set('digital_product_thumbnail_photos', box.sel('digital_product_thumbnail_photos') || []);

        initTooltips2({
          container_el: tab_el,
          custom: {
            placement: 'bottom',
            offset: undefined,
            animation: 'shift-toward',
            maxWidth: MShopUtilF.isMobile() ? 500 : 340,
            interactive: true,
            trigger: 'mouseenter click',
          },
        });

        go(
          tab_el,
          defnProductStoryEvent,
          MShopStudioDigitalProductCreatorF.event,
          MShopStudioDigitalProductCreatorF.thumbnailEvent,
          MShopStudioDigitalProductCreatorF.tokenGateEvent,
        );

        if (MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
          go(
            tab_el,
            MShopStudioPreDiscountProductF.initPreDiscountPriceFEvents,
            $delegate('change', '.digital-product-creator-form__text-input[name="price"]', (changeEvent) => {
              MShopStudioPreDiscountProductF.calculatePreDiscountPriceAndRateForPB(tab_el, changeEvent);
            }),
          );
        }

        if (MShopUtilF.isMobile()) {
          $setText(
            T('studio::digital_products::header', { cate_name }),
            $qs('.digital-product-creator-frame__title'),
          );
        }
      };
      tab.showed = (tab_el) => {
        setProductStoryTextArea(tab_el);
      };
      tab.rendered = () => {
        snsInitYoutubeEditor();
        snsInitInstagramEditor();
        snsInitTwitterEditor();
        snsInitFacebookEditor();
      };
      tab.hiding = async (el, val) => {
        box.set('digital_product_thumbnail_files', []);
        box.set('digital_product_thumbnail_photos', []);
        if (!MShopUtilF.isMobile()) MShopUtilF.bodyFixed$(false);

        if (val) {
          window.location.reload();
          await new Promise(() => {});
        }
      };
    });
  };

export const pushPageEvent = (tab_el) => {
  const { store_ecommerce_info, ebook_categories } = $data(
    $find('.digital-product-category-selection', tab_el),
  );
  go(tab_el, $delegate('click', '.category', pushCreatorPage({ store_ecommerce_info, ebook_categories })));
};
