import { $addClass, $attr, $qs, $removeClass, $setAttr } from 'fxdom/es';
import { go, ifElse, tap } from 'fxjs/es';

export const checkFoldedColorChip = (fdc = $qs('#for_detail_change')) => {
  if (!fdc) return fdc;

  const dynamic_html_el = $qs('.pd-field.colors .dynamic-html', fdc);
  if (!dynamic_html_el) return fdc;

  const el = $qs('.pd-contents__colors', fdc);
  const color_list_el = $qs('.pd-radio-group.color', fdc);

  const product_color = box().maker.product_color;
  const base_product_colors = product_color._.base_product._.base_product_colors;

  const width = el.getBoundingClientRect().width;
  const style = window.getComputedStyle(color_list_el);
  const gap = parseFloat(style.getPropertyValue('column-gap'));
  const item_width = $qs('.pd-radio.color', el).getBoundingClientRect().width + gap;
  let max_count = Math.floor((width + gap) / item_width);

  const hasFold = $attr('data-folded', fdc);
  const needExpand = base_product_colors.length > max_count && !hasFold;

  // maker 진입에서 빠져나오면 el의 크기가 없는 상태라 측정이 불가능함. 이때 이전 max_count 를 가져오 사용함.
  if (max_count <= 1) {
    max_count = $attr('data-cur-max', fdc);
  } else {
    $setAttr({ 'data-cur-max': max_count }, fdc);
  }

  go(
    dynamic_html_el,
    ifElse(() => needExpand, $addClass('fold'), $removeClass('fold')),
    tap((el) => {
      el.classList.forEach((className) => {
        if (className.startsWith('max_count_')) {
          $removeClass(className)(el);
        }
      });
    }),
    $addClass(`max_count_${max_count}`),
    $setAttr({ max_count }),
  );

  return fdc;
};
