import { CustomEventWithDetail, type Html, html, on, View } from 'rune-ts';
import c from './Dropdown.module.scss';
import { htmlIf } from '../../../../shared/util';
import { DropdownDownIcon } from '../../atoms/Icon';
import { isSafari } from '../../../../shared/util/ua';
import { typo } from '../../../../shared/typography/typo';
import { filter, first, isNil, isString, map, pipe, prepend, sortBy } from '@fxts/core';

// type - style mapping
const horizontal_class_map = {
  right: c.right,
  left: c.left,
};

const text_align_class_map = {
  left: c.left,
  center: c.center,
  right: c.right,
};

const vertical_class_map = {
  bottom: c.bottom,
  top: c.top,
  auto: '',
};

export interface DropdownOption<T = unknown> {
  name: string | Html;
  value: T;
  key: string; // unique identifier
}

export class DropdownChangeEvent<T = unknown> extends CustomEventWithDetail<{
  option: DropdownOption<T> | null;
}> {}

export interface DropdownProps {
  horizontal: 'left' | 'right';
  vertical?: 'auto' | 'top' | 'bottom';
  is_opened?: boolean;
  // 드랍다운 버튼에 기본으로 표기해줄 내용 (ex, 선택해주세요, 언어 등)
  default_name?: string;
  button_klass?: string;
  klass?: string;
  has_arrow?: boolean;
  // 드랍다운 이외 영역 클릭했을 때, 열려있는 창을 닫을 것인지
  close_on_focus_out?: boolean;
  // 버튼의 padding, border 없는 버전
  without_container?: boolean;
  // 옵션 선택했을 때, 드랍다운 버튼의 표기가 바뀌지 않음 (기본은 선택한 것으로 바뀜)
  button_fixed?: boolean;

  /**
   * `data.options`에서 name 가장 긴 옵션의 폭에 맞추는 옵션
   * - `data.options`의 name 이 string 일때만 사용 가능합니다.
   */
  fit_width_to_longest_name?: boolean;

  /**
   * 드롭다운 버튼의 text_align
   * (옵션에는 미적용, 필요시 확장해서 사용할 것)
   */
  text_align?: 'left' | 'center' | 'right';
}

export interface DropdownData<T = unknown> {
  selected_option_key?: string;
  options: DropdownOption<T>[];
}

export class Dropdown<T = unknown> extends View<DropdownData<T>> {
  state: DropdownProps & {
    is_opened: boolean;
    vertical: 'auto' | 'top' | 'bottom';
    has_arrow: boolean;
    close_on_focus_out: boolean;
    without_container: boolean;
    button_fixed: boolean;

    /**
     * `data.options`에서 name 가장 긴 옵션의 폭에 맞추는 옵션
     */
    fit_width_to_longest_name?: boolean;
  };

  constructor(data: DropdownData<T>, options: DropdownProps) {
    super(data, options);

    if (options.button_fixed && isNil(options.default_name))
      throw new Error('고정 표기의 경우에는 기본 이름이 필수입니다.');

    this.state = {
      ...options,
      button_klass: options.button_klass ?? '',
      klass: options.klass ?? '',
      is_opened: options.is_opened ?? false,
      vertical: options.vertical ?? 'bottom',
      has_arrow: options.has_arrow ?? true,
      close_on_focus_out: options.close_on_focus_out ?? true,
      without_container: options.without_container ?? false,
      button_fixed: options.button_fixed ?? false,
      fit_width_to_longest_name: options.fit_width_to_longest_name ?? false,
    };
  }

  override onMount() {
    const is_safari = isSafari();
    this.addEventListener('focusout', function closeDropdown(e) {
      if (!this.state.close_on_focus_out) return;
      if (is_safari) return; // safari 에서는 relatedTarget 이 제대로 안잡힘
      if (e.relatedTarget instanceof HTMLElement) {
        // click 한 곳이 현재 Dropdown 내부일 경우에는 무시
        const close_component_el = e.relatedTarget ? e.relatedTarget.closest(`.Dropdown`) : null;
        if (close_component_el == this.element()) return;
      }

      this.toggle(false);
    });
  }

  @on('click', `.${c.button}`)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onButtonClick() {
    this.toggle(!this.state.is_opened);
  }

  @on('click', `.${c.option}`)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private _onOptionClick(e) {
    const option_el = e.currentTarget;
    const key = (option_el.getAttribute('data-key') || '') as string;

    this.toggle(false);
    const selected_option = this.select(key);

    this.dispatchEvent(DropdownChangeEvent<T>, {
      bubbles: true,
      detail: {
        option: selected_option,
      },
    });
  }

  /*
   * Data & State Update
   */

  setStateIsOpened(should_open: boolean) {
    this.state.is_opened = should_open;
  }

  /*
   * Normal Method
   */

  findSelectedOption(selected_option_key?: string) {
    if (!selected_option_key) return null;
    return this.data.options.find((option) => option.key == selected_option_key) ?? null;
  }

  private getLongestOptionName(): string | undefined {
    const selected_option = this.findSelectedOption(this.data.selected_option_key);
    const { button_fixed, default_name } = this.state;
    const default_option_name = button_fixed ? default_name : selected_option?.name ?? default_name;

    try {
      return pipe(
        this.data.options,
        map((option) => option.name),
        prepend(default_option_name),
        filter(isString),
        sortBy((name) => -1 * name.length),
        first,
      );
    } catch {
      return undefined;
    }
  }

  toggle(should_open: boolean) {
    this.setStateIsOpened(should_open);
    if (should_open) {
      this.element().classList.add(c.is_opened);
    } else {
      this.element().classList.remove(c.is_opened);
    }
  }

  select(selected_option_key: string): DropdownOption<T> | null {
    this.data.selected_option_key = selected_option_key;
    this.toggle(false);
    this.redraw();

    return this.findSelectedOption(selected_option_key);
  }

  override template() {
    const {
      data,
      state: {
        klass,
        horizontal,
        default_name,
        button_klass,
        has_arrow,
        is_opened,
        vertical,
        without_container,
        button_fixed,
        fit_width_to_longest_name = false,
        text_align = 'left',
      },
    } = this;

    const selected_option = this.findSelectedOption(this.data.selected_option_key);
    const horizontal_class = horizontal_class_map[horizontal];
    const vertical_class = vertical_class_map[vertical];
    const text_align_class = text_align_class_map[text_align];

    const dropdown_icon = html`<span class="${c.icon}">${DropdownDownIcon}</span>`;
    const selected_name = button_fixed ? default_name : selected_option?.name ?? default_name;

    return html`
      <div
        class="${htmlIf(c.is_opened, is_opened)} ${c.dropdown} ${typo('14_medium')} ${klass} ${htmlIf(
          c.without_container,
          without_container,
        )}"
      >
        <button class="${c.button} ${button_klass}">
          <span class="${c.name}">
            ${fit_width_to_longest_name
              ? html`<span class="${c.virtual_name}">${this.getLongestOptionName()}</span>`
              : html`<span>${selected_name}</span>`}
            ${htmlIf(
              html`<span class="${c.floating_name} ${text_align_class}">${selected_name}</span>`,
              fit_width_to_longest_name,
            )}
          </span>
          ${htmlIf(dropdown_icon, has_arrow)}
        </button>

        <div
          class="${c.option_container} ${horizontal_class} ${vertical_class} ${htmlIf(
            `${c.fit_width} ${c.left} ${c.right}`,
            fit_width_to_longest_name,
          )} ${htmlIf(c.with_margin, fit_width_to_longest_name && (!has_arrow || without_container))}"
        >
          ${data.options.map((option) => {
            const selected_class = selected_option == option ? c.selected : '';

            return html`<button class="${c.option} ${selected_class}" data-key="${option.key}">
              <span class="${c.option_name}">
                ${fit_width_to_longest_name
                  ? html`<span class="${c.virtual_name}">${this.getLongestOptionName()}</span>`
                  : html`<span>${option.name}</span>`}
                ${htmlIf(
                  html`<span class="${c.floating_name}">${option.name}</span>`,
                  fit_width_to_longest_name,
                )}
              </span>
            </button>`;
          })}
        </div>
      </div>
    `;
  }
}
