import { go, keys, map } from 'fxjs/es';

export const NessProductSortKey = Object.freeze({
  popular: 'popular',
  recent: 'recent',
  like: 'like',
  low_price: 'low_price',
  high_price: 'high_price',
});

export const getProductListSortOptions = (product_list_type) => {
  if (product_list_type === NessProductListType.limit) {
    return go(
      keys(NessLimitedEditionProductListSortKey),
      map((sort) => {
        return {
          name: getDisplayNameOfSortOptionBySortKeyInLimitedEdition(sort),
          value: sort,
        };
      }),
    );
  }

  if (product_list_type === NessProductListType.recent) {
    const { recent, ...rest } = NessProductSortKey;
    return go(
      { recent, ...rest },
      keys,
      map((sort) => {
        return {
          name: getDisplayNameOfSortOptionBySortKey(sort),
          value: sort,
        };
      }),
    );
  }

  return go(
    keys(NessProductSortKey),
    map((sort) => {
      return {
        name: getDisplayNameOfSortOptionBySortKey(sort),
        value: sort,
      };
    }),
  );
};

export const NessLimitedEditionProductListSortKey = Object.freeze({
  default: 'default',
  ...NessProductSortKey,
});

export const getDisplayNameOfSortOptionBySortKey = (sort) => {
  // 변수로 추상화하면 검색 힘들어서 switch/case로 처리
  switch (sort) {
    case NessProductSortKey.popular:
      return ET(`ness::product_sort::popular`);
    case NessProductSortKey.recent:
      return ET(`ness::product_sort::recent`);
    case NessProductSortKey.like:
      return ET(`ness::product_sort::most_liked`);
    case NessProductSortKey.low_price:
      return ET(`ness::product_sort::price_low_to_high`);
    case NessProductSortKey.high_price:
      return ET(`ness::product_sort::price_high_to_low`);
    default:
      throw new Error(`Invalid sort in getNessProductsSortOptions: ${sort}`);
  }
};

export const getDisplayNameOfSortOptionBySortKeyInLimitedEdition = (sort) => {
  if (sort === NessLimitedEditionProductListSortKey.default) {
    return ET(`ness::product_sort::default`);
  }
  return getDisplayNameOfSortOptionBySortKey(sort);
};

export const NessProductListType = Object.freeze({
  recent: 'recent',
  limit: 'limit',
  popular: 'popular',
  custom: 'custom',
  all: 'all',
});

export const nessPlusProductListFetchLimit = {
  newArrivals: null,
  LimitedEdition: null,
  BestSeller: 40,
  All: null,
  custom: null,
};
