import linkifyStr from 'linkify-string';
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  isAfter,
  isBefore,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfWeek,
} from 'date-fns';

import date_fns_tz from 'date-fns-tz';

import addDaysFp from 'date-fns/fp/addDays/index.js';

import {
  append,
  curry,
  curry2,
  defaultTo,
  each,
  entries,
  every,
  filterL,
  go,
  go1,
  object,
  head,
  ippL,
  isNil,
  isString,
  isUndefined,
  join,
  juxt,
  map,
  mapL,
  merge,
  omitBy,
  pick,
  pipe,
  reduce,
  rejectL,
  some,
  sortBy,
  split,
  takeAll,
  zipWithIndexL,
  delay,
} from 'fxjs/es';
import { UtilConstantS } from '../Constant/module/UtilConstantS.js';
import querystring from 'query-string';
import { colorLog } from '../../Logger/S/Function/logger.js';
import { isMarppleShopApp, isNboxApp, isNessApp } from './service_app_name.js';

const { utcToZonedTime } = date_fns_tz;

const reg = /(^[+-]?\d+)(\d{3})/;
export const commify = function (n, n2 = undefined, l = undefined) {
  if (n2) n = (parseFloat(n) + parseFloat(n2)).toFixed(l || 2);
  if (!n) return '0';
  n += '';
  const s_n = n.split('.');
  n = s_n[0];
  var n2 = s_n[1];
  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
  const result = !n2 ? n : [n, n2.substr(0, 2)].join('.');
  return escape(result).trim();
};

export const br = (str) => (str ? str.replace(/\n/g, '<br>') : '');

export const is_number = (val) => {
  const reg = /^\d+$/g;
  return reg.test(val);
};

export const is_number_minus = (val) => {
  const reg = /^-?[0-9]\d*(\d+)?$/;
  return reg.test(val);
};

export const is_empty = (val) => {
  return val.replace(/^(\s*)|(\s*$)/gi, '') == '';
};

export const is_special_str = (val) => {
  const reg = /[~!@#$%^&*()'"_+|<>?:{}]/;
  return reg.test(val);
};

export const isSpecialStr2 = (val) => {
  const reg = /^[a-zA-Z0-9ㄱ-ㅎ가-힣*()\-·[\]&+,~.\s]*$/;
  return reg.test(val);
};

export const is_number_or_str = (val) => {
  const reg = /^[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9+]*$/;
  return reg.test(val);
};

export const isEn = (val) => {
  const reg = /^[a-zA-Z]*$/;
  return reg.test(val);
};

export const isEnOrNumberOrUnder = (val) => {
  const reg = /^[a-zA-Z0-9_+]*$/;
  return reg.test(val);
};

export const isKrPhoneNumber = (val) => {
  const reg = /^\(?([0-9]{3})\)?[-]?([0-9]{3,4})[-]?([0-9]{4})$/;
  return reg.test(val);
};

export const isKrTelNumber = (val) => {
  //유전 전화 + 휴대폰 번호
  const phone_reg = /^(0[2-8][0-5]?|01[01346-9])-?([0-9]{3,4})-?([0-9]{4})$/;
  //대표전화번호 1588 등
  // const tel_reg = /^(1544|1566|1577|1588|1644|1688)-?([0-9]{4})$/;
  const tel_reg =
    /^(1588|1577|1899|1544|1644|1661|1566|1600|1670|1688|1666|1599|1877|1855|1800)-?([0-9]{4})$/;

  // 안심번호
  const tel2_reg = /^(050[1-9])-?([0-9]{3,4})-?([0-9]{4})$/;

  return phone_reg.test(val) || tel_reg.test(val) || tel2_reg.test(val);
};

export const isGlobalPhoneNumber = (val) => {
  const reg = /^\+?([0-9]{2})\)?[-]?([0-9]{4})[-]?([0-9]{4})$/;
  return reg.test(val);
};

export const isUrl = (val) => {
  const reg =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return reg.test(val);
};

export const checkTextLength = (val) => {
  const str = (val.length * 9 - encodeURIComponent(val).length) / 8; // 영문숫자의 개수
  const msg_byte = parseInt((val.length - str) * 2 + str, 10); // euc-kr 바이트 구하기

  return msg_byte;
};

export const checkStringLength = (str) => {
  const length = String(str).trim().length;

  return length;
};

export const checkSellerDomainLength = (str) => {
  return str.length > 1 && str.length <= 30;
};
const _isValidEmail = (val) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(val).toLowerCase());
  // var reg = /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{2,5}$/;
  // return reg.test(val);
};
export const is_valid_email = _isValidEmail;

export const isValidEmail = _isValidEmail;

export const makeKrPhoneHyphen = (phone_num) => {
  if (T.lang == 'kr') {
    return phone_num
      .replace(/[^0-9]/g, '')
      .replace(/(^02|^050[1-9]|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
      .replace('--', '-');
  }

  // $(this).val(
  //   $(this)
  //     .val()
  //     .replace(/[^0-9]/g, '')
  //     .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
  //     .replace('--', '-')
  // );
};

export const makeGlobalPhoneNumberKr = (number) => {
  number = number.replace(/[^0-9]/g, '');
  return '82' + number.substring(1);
};

export const maker_text_escape = (str) =>
  str
    .replace(/(script)/gi, '#maker_text_escape#$1#un_maker_text_escape#')
    .replace(/</g, '&mklt;')
    .replace(/>/g, '&mkgt;')
    .replace(/=/g, '&mkeq;');

export const un_maker_text_escape = (str) =>
  str
    .replace(/#maker_text_escape#(script)#un_maker_text_escape#/gi, '$1')
    .replace(/&mklt;/g, '<')
    .replace(/&mkgt;/g, '>')
    .replace(/&mkeq;/g, '=');

export const addAttr = (k, v) => (isUndefined(v) ? k : `${k}="${v}"`);
export const addAttrIf = (cond, k, v) => (cond ? addAttr(k, v) : '');
export const htmlIfElse = curry((condition, str1, str2) => legacyHtml` ${condition ? str1 : str2} `);

export const htmlIf = curry((condition, str) => (condition ? legacyHtml` ${str} ` : ''));

export const htmlIf2 = curry((condition, makeHtmlIf, makeHtmlElse = () => '') =>
  condition ? makeHtmlIf() : makeHtmlElse(),
);

const formatDateTo = curry((format = 'YYYY-MM-DD', a) => moment(a).format(format));
export const formatDateWithDash = formatDateTo('YYYY-MM-DD');

export const formatPeriod = (from, to) =>
  from && to ? `${formatDateWithDash(from)} ~ ${formatDateWithDash(to)}` : '';

const BEFORE_SALE = 'BEFORE_SALE';
const ON_SALE = 'ON_SALE';
const AFTER_SALE = 'AFTER_SALE';

const format_unit_map = {
  '': {
    year: '년',
    month: '개월',
    day: '일',
    hour: '시간',
    minute: '분',
    second: '초',
  },
  _jp: {
    year: '年',
    month: 'ヶ月',
    day: '日',
    hour: '時間',
    minute: '分',
    second: '秒',
  },
};
// 3日後に販売開始
const format_unit = (lang, unit, value) => {
  if (lang === '_en') {
    return `${unit}${value > 1 ? 's' : ''}`;
  } else {
    return format_unit_map[lang][unit];
  }
};

const salesPeriodDistanceFormat = ([diff, unit, type]) => {
  const diff_text = `${diff} ${format_unit(G._en, unit, diff)}`;
  if (type === BEFORE_SALE) return T('mp_shop_product::몇일 남음', { diff_text });
  if (type === ON_SALE) return T('mp_shop_product::몇일 남음', { diff_text });
  return T('mp_shop_product::몇일 전 판매종료', { diff_text });
};

export const setTime = curry(({ hours, minutes, seconds, milliseconds }, target) =>
  go(
    target,
    (d) => setHours(d, hours),
    (d) => setMinutes(d, minutes),
    (d) => setSeconds(d, seconds),
    (d) => setMilliseconds(d, milliseconds),
  ),
);
export const clearTime = setTime({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
export const toSalesEndTime = setTime({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });

export const toTimeZoneSeoul = (date) => utcToZonedTime(date, UtilConstantS.TZ_SEOUL);

export const getSalesPeriodOf = (date = new Date()) =>
  go(
    date,
    toTimeZoneSeoul,
    (zoned_date) => startOfWeek(zoned_date, { weekStartsOn: 1, locale: { code: 'ko-KR' } }),
    juxt(clearTime, pipe(addDaysFp(6), toSalesEndTime)),
    ([sell_start_at, sell_end_at]) => ({ sell_start_at, sell_end_at }),
  );

export const distanceToSalesPeriod = (period = [], target = new Date()) => {
  if (some(isNil, period)) return '';
  const [start, end] = map((a) => new Date(a), period);
  const date_fns = [
    [differenceInYears, 'year'],
    [differenceInMonths, 'month'],
    [differenceInDays, 'day'],
    [differenceInHours, 'hour'],
    [differenceInMinutes, 'minute'],
    [differenceInSeconds, 'second'],
  ];

  let type = null;
  let diff_args = null;

  if (isBefore(target, start)) {
    type = BEFORE_SALE;
    diff_args = [start, target];
  } else if (isAfter(target, end)) {
    type = AFTER_SALE;
    diff_args = [target, end];
  } else {
    type = ON_SALE;
    diff_args = [end, target];
  }

  return go(
    date_fns,
    mapL(([f, unit]) => [f(...diff_args), unit]),
    filterL(([diff, unit]) => (unit == 'day' ? diff > 1 : diff > 0)),
    head,
    append(type),
    salesPeriodDistanceFormat,
  );
};

export const differenceTimeInUnits = curry2((units, date_left, date_right, ceil_second) => {
  const unit_order = ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'];
  const unit_map = {
    year: [differenceInYears, addYears],
    month: [differenceInMonths, addMonths],
    day: [differenceInDays, addDays],
    hour: [differenceInHours, addHours],
    minute: [differenceInMinutes, addMinutes],
    second: [differenceInSeconds, addSeconds],
    millisecond: [differenceInMilliseconds, addMilliseconds],
  };

  const new_date_left = ceil_second ? addSeconds(date_left, 1) : date_left;

  return go(
    units,
    sortBy((u) => unit_order.indexOf(u)),
    (sorted) =>
      reduce(
        ([result, acc_date], unit) => {
          const diff = unit_map[unit][0](new_date_left, acc_date);

          return [{ ...result, [unit]: diff }, unit_map[unit][1](acc_date, diff)];
        },
        [{}, date_right],
        sorted,
      ),
    head,
  );
});

export const catchHi =
  (f, ...fs) =>
  (...args) => {
    try {
      return go(f(...args), ...fs);
    } catch (e) {
      console.error(e);
    }
  };

export const catchHiAsync =
  (f, ...fs) =>
  async (...args) => {
    try {
      return await go(f(...args), ...fs);
    } catch (e) {
      console.error(e);
    }
  };

export const makeProductTag = ({ sell_start_at, sell_end_at, quantity, order_count }) => {
  if (!quantity && !sell_start_at) return {};
  if (quantity && quantity <= order_count) return { id: 'sold_out', text: 'Sold out' };
  if (quantity && !sell_end_at) return { id: 'limited_edition', text: 'Limited edition' };

  const now = Date.now();
  const sell_start = new Date(sell_start_at).getTime();
  const sell_end = new Date(sell_end_at).getTime();
  if (now < sell_start) return { id: 'coming_soon', text: 'Coming soon' };
  if (now < sell_end) return { id: 'limited_edition', text: 'Limited edition' };
  return { id: 'sold_out', text: 'Sold out' };
};

export const matchExactly = curry((regex, value) => head(value.match(regex)) === value);
export const matchPositiveInteger = matchExactly(/^[1-9]+[0-9]*/);
export const toBoolean = (a) => {
  if (typeof a === 'boolean') return a;
  else return a === 'true';
};

export const makeApiUrl = curry((str, obj) =>
  go(
    str,
    split('/'),
    mapL((a) => [a.startsWith(':'), a]),
    mapL(([is_key, a]) => [a, is_key ? obj[a.slice(1)] : null]),
    mapL(([a, b]) => defaultTo(a, b)),
    join('/'),
  ),
);

export const makeUrl = (url) => ((url || '').indexOf('//') < 0 ? '//' + url : url);

export const trimErrorLog = (err_json_string) =>
  err_json_string
    .replace(/\\n/g, '\n')
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .replace(/\\&/g, '&')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f');

export const formatErrorLog = (err) =>
  go(
    err,
    pick(['stack', 'message', 'url', 'originalUrl', 'shopify_product_id']),
    (err2) => JSON.stringify(err2, null, 4),
    trimErrorLog,
  );

export const interpolate = curry(
  pipe(function* (target, xs) {
    const iter = xs[Symbol.iterator]();
    const is_arr = target instanceof Array;
    const { value } = iter.next();
    yield value;
    for (const a of iter) {
      if (is_arr) yield* target;
      else yield target;
      yield a;
    }
  }, takeAll),
);

export const wrapArr = (xs) => (xs instanceof Array ? xs : [xs]);

export const trim = (str) => (typeof str == 'string' ? str.trim() : str);

export const handleNumber = curry((digit, n, handleFn) => {
  const mul = (a, b) => a * b;
  const div = (a, b) => a / b;

  const positive = digit > 0;
  if (digit == 0) return Math.round(n);
  const unit = 10 ** Math.abs(digit);
  const [f1, f2] = positive ? [div, mul] : [mul, div];
  return f2(handleFn(f1(n, unit)), unit);
});

export const roundTo = curry((digit, n) => handleNumber(digit, n, Math.round));
export const floorTo = curry((digit, n) => handleNumber(digit, n, Math.floor));

export const recursiveL = curry(function* (f, start) {
  while (true) yield (start = go1(start, f));
});

export const equalProps = (o1, o2, key) => o1[key] == o2[key];

export const makeQueryString = (query) =>
  go(
    query,
    entries,
    rejectL(([, v]) => isNil(v)),
    mapL(([k, v]) => [k, encodeURIComponent(v)]),
    mapL(([k, v]) => `${k}=${v}`),
    join('&'),
  );

/**
 * @param {string} search - url parameter
 * @param {object} new_params - value 를 null | undefined 로 하면 삭제
 * @param {boolean=} preserve_prev - 기존 parameter 유지 여부
 * @return {string}
 */
export const getUpdateUrlParams = (search, new_params, preserve_prev = true) =>
  go(
    querystring.parse(search[0] === '?' ? search.slice(1) : search),
    (prev) => (preserve_prev ? merge(prev, new_params) : new_params),
    omitBy(([, v]) => isNil(v)),
    querystring.stringify,
  );

/**
 * @param {string} url full url to update
 * @param {Record<string, any>} new_params
 * @param {boolean=} preserve_prev - 기존 parameter 유지 여부
 * @return {string}
 */
export const updateUrlParams = (url, new_params, preserve_prev = true) => {
  const query_string = getUpdateUrlParams(url.split('?')[1] || '', new_params, preserve_prev);

  return `${url.split('?')[0]}${query_string ? '?' + query_string : ''}`;
};

/**
 * @param {string} url
 * @return {string}
 */
export const removeUrlParams = (url) => url.split('?')[0];

export const legacyHtml = (strs, ...datas) => {
  datas = mapL(
    (d) => (typeof d == 'function' ? d() : Array.isArray(d) ? d.join('') : d === undefined ? '' : d),
    datas,
  );
  return go1(
    reduce((res, str) => go1(datas.next().value, (data) => `${res}${data}${str}`), strs),
    (a) => a.replace(/\s*(>|<)\s*/g, '$1').trim(),
  );
};

export const parallelSum = (xss) =>
  go(
    xss[0],
    zipWithIndexL,
    map(([i, x]) => x + reduce((acc, c) => acc + c[i], 0, xss.slice(1))),
  );

export function is_empty_object(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

const escapeMap = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#x27;', '`': '&#x60;' };
const unescapeMap = Object.fromEntries(map(([key, val]) => [val, key], Object.entries(escapeMap)));
const createEscaper = function (map) {
  const escaper = function (match) {
    return map[match];
  };
  const source = '(?:' + Object.keys(map).join('|') + ')';
  const testRegexp = RegExp(source);
  const replaceRegexp = RegExp(source, 'g');
  return function (string, br_replace = false) {
    string = string == null ? '' : '' + string;
    if (testRegexp.test(string)) {
      return br_replace
        ? string
            .replace(/<br\s*\/?>/g, '/n')
            .replace(replaceRegexp, escaper)
            .replace(/\n/g, '<br>')
        : string.replace(replaceRegexp, escaper);
    }

    return br_replace ? string.replace(/\n/g, '<br>') : string;
  };
};
export const unEscaper = createEscaper(unescapeMap);
export const escaper = createEscaper(escapeMap);
export const escape = (string, br_replace) => go(string, unEscaper, (string) => escaper(string, br_replace));

export const lowerCase = (str) => (isString(str) ? str.toLowerCase() : str);
export const upperCase = (str) => (isString(str) ? str.toUpperCase() : str);

export const commaThousands = (n) => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function toCamel(str) {
  return str.replace(/_([a-z])/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

export const linkify = (txt, len) => {
  if (typeof txt != 'string') return '';

  txt = txt
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#x3A;/g, ':')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .trim();

  return (
    !txt.match(/<(.|\n)*?>/g) && txt.length > len
      ? linkifyStr(txt.substring(0, len), {
          target: { url: '_blank' },
        }) + '...'
      : linkifyStr(txt, { target: { url: '_blank' } })
  ).replace(/\n/g, '<br>');
};

export const validPasswordKr = (pwd) => {
  if (pwd.length < 10) {
    return {
      message: '10자 이상 입력해주세요. / 영문, 숫자, 특수문자 사용 가능',
      result: false,
    };
  }

  if (/(\w)\1\1\1/.test(pwd)) {
    return {
      message: '동일한 문자를 과도하게 연속해서 사용할 수 없습니다.',
      result: false,
    };
  }

  let ascSeqCharCnt = 0; // 오름차순 연속 문자 카운트
  let descSeqCharCnt = 0; // 내림차순 연속 문자 카운트

  for (let i = 0; i < pwd.length; i++) {
    // charAt(): 문자값 반환
    const char_0 = pwd.charAt(i);
    const char_1 = pwd.charAt(i + 1);
    const char_2 = pwd.charAt(i + 2);

    // charCodeAt(): 유니코드값 반환
    const diff_0_1 = char_0.charCodeAt(0) - char_1.charCodeAt(0);
    const diff_1_2 = char_1.charCodeAt(0) - char_2.charCodeAt(0);

    if (diff_0_1 === 1 && diff_1_2 === 1) {
      ascSeqCharCnt += 1;
    }

    if (diff_0_1 === -1 && diff_1_2 === -1) {
      descSeqCharCnt += 1;
    }

    if (ascSeqCharCnt > 1 || descSeqCharCnt > 1) {
      return {
        message: '보안을 위해 연속된 문자를 사용할 수 없습니다.',
        result: false,
      };
    }
  }

  if (!/((?=.*\d)(?=.*[a-zA-Z])(?=.*[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]).{10,})/.test(pwd)) {
    return {
      message: '영문, 숫자, 특수문자를 조합하여 설정해주세요.',
      result: false,
    };
  }

  return { result: true };
};

export const createPerfTimer = (title, base_color) => {
  const start = Date.now();
  return (subtitle, color) => {
    console.log(`%c${title} - ${subtitle}: ${Date.now() - start}ms`, `color: ${color || base_color}`);
  };
};

export const makeKrBusinessNumber = (number) => {
  if (!number) return '';

  const new_number = number.replace(/\s/g, '').replace(/\D/g, '');
  if (new_number.length === 10) {
    return new_number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  } else if (number.length === 12) {
    return new_number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  }
  return number;
};

export const getCurrency = () => {
  if (T.lang === 'kr') return '원';
  if (T.lang === 'en') return '$';
  if (T.lang === 'jp') return '¥';
};

export function isRightCurrency(currency, lang) {
  if (lang === 'kr' && currency === 'KRW') return true;
  if (lang === 'en' && currency === 'USD') return true;
  if (lang === 'jp' && currency === 'JPY') return true;
  return false;
}

/**
 * user_products를 받아 원본이미지 url을 변경해주는 함수
 * @param user_products
 * @returns {*}
 */
export const makeBlindOriginalImageUrlFromUps = (user_products) => {
  const changeBlindProductFaceValue = (product_face_value) => {
    go(
      product_face_value,
      wrapArr,
      each((product_faces2_value) => {
        product_faces2_value.designs = go(
          JSON.stringify(product_faces2_value.designs).replaceAll(
            's3.marpple.co',
            'kjuihhoucma2hdul2njj.sss.marppleshop.com',
          ),
          JSON.parse,
        );
      }),
    );
  };

  try {
    go(
      user_products,
      wrapArr,
      each((up) => {
        changeBlindProductFaceValue(up?._?.product_color?.product_faces2?.value);
        changeBlindProductFaceValue(up?._?.product_color?.product_faces?.value);
      }),
    );
    return user_products;
  } catch (_) {
    return user_products;
  }
};

export function emptyNullGuard(list) {
  if (!Array.isArray(list)) throw new Error(`list must be an array`);

  list.forEach((item) => {
    if (isEmpty(item)) throw new Error(`item cannot be null`);
  });
}

/**
 * 매개변수가 비어 있는지 여부를 체크하는 함수
 * @param {*} param - 체크할 매개변수
 * @returns {boolean} 매개변수가 비어 있으면 true, 그렇지 않으면 false
 */
export function isEmpty(param) {
  if (param === null || param === undefined) {
    return true;
  }
  if (Array.isArray(param) && param.length === 0) {
    return true;
  }

  if (param instanceof Date) return false;

  if (typeof param === 'function') {
    return false;
  }

  if (typeof param === 'object' && Object.keys(param).length === 0) {
    return true;
  }

  if (typeof param === 'string' && param === '') {
    return true;
  }

  return false;
}

export function isNotEmpty(param) {
  return !isEmpty(param);
}

export function returnIfEmptyNull(value) {
  return isEmpty(value) ? null : value;
}

export const escapeRegExp = (str) => {
  if (!str) return str;
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getBpDiscountSetTypes = () => {
  if (isNessApp()) {
    return ['NESS'];
  }
  if (isNboxApp()) {
    return ['NBOX'];
  }
  if (isMarppleShopApp()) {
    return ['MSHOP'];
  }
  return ['MP', 'MP_WOW'];
};

export const throttleWithLastExecute = (f, time) => {
  let block = false;
  const throttleFunction = function (...args) {
    if (block) return;
    block = true;
    const res = f(...args);
    go1(delay(time, res), () => (block = false)).finally(() => (block = false));
    return res;
  };

  let setTime;
  return (...args) => {
    if (setTime) clearTimeout(setTime);
    if (block) setTime = setTimeout(() => f(...args), time);
    throttleFunction(...args);
  };
};

export const padWithZero = (input, targetLength) => {
  let str = String(input);
  while (str.length < targetLength) {
    str = '0' + str;
  }
  return str;
};

export const parseIntlColumns = (target) => {
  const lang = typeof T != 'undefined' ? T.lang : ET.lang;

  return go(
    target,
    entries,
    map(([k, v]) => (k.slice(-5) === '_intl' ? [k.replace(/_intl$/, ''), v?.[lang]] : [k, v])),
    object,
  );
};

export const isNumberSequential = (num_arr) => {
  if (num_arr.length < 2) return;
  return go(
    num_arr,
    ippL,
    every(([i, num]) => {
      if (i === 0) return true;
      return Math.abs(num - num_arr[i - 1]) === 1;
    }),
  );
};

export const translateUnit = (unit) => {
  if (unit === '개') {
    return TT('translateUnit::개');
  }
};

// eslint-disable-next-line no-new-func
export const isNode = new Function(`try {return this===global;}catch(e){return false;}`)();

export const makeCombinations = (arrays_of_array) => {
  const end = arrays_of_array.length - 1;
  const result = [];

  function addTo(curr, start) {
    const first = arrays_of_array[start];
    const last = start === end;
    for (let i = 0; i < first.length; ++i) {
      const copy = curr.slice();
      copy.push(first[i]);
      if (last) {
        result.push(copy);
      } else {
        addTo(copy, start + 1);
      }
    }
  }

  if (arrays_of_array.length) {
    addTo([], 0);
  } else {
    result.push([]);
  }
  return result;
};

export function throwError(text) {
  throw new Error(colorLog([' ERROR ', ' ' + text], ['', 'yellow'], ['bg_red', '']));
}

export function colorConsole(text) {
  colorLog([' LOG ', ' ' + text], ['', 'white'], ['bg_yellow', '']);
}

export function colorErrorConsole(text) {
  colorLog([' ERROR ', ' ' + text], ['', 'white'], ['bg_red', '']);
}

export function safeStrToNumber(value) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value !== 'string') {
    return NaN;
  }

  value = value.replace(/,/g, '');

  const num = parseFloat(value);

  if (isNaN(num)) {
    return NaN;
  }

  return num;
}

/**
 * URL에 프로토콜이 없는 경우 https를 추가합니다.
 * @param {string} url - 처리할 URL
 * @returns {string} 프로토콜이 추가된 URL
 */
export function ensureProtocol(url) {
  if (url.startsWith('//')) {
    return `https:${url}`;
  }
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}

export function formatBytes(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  let value = bytes;

  // 1024 단위로 값을 나누면서 적절한 단위를 찾음
  while (value >= 1024 && unitIndex < units.length - 1) {
    value /= 1024;
    unitIndex++;
  }

  // 소수점 둘째 자리까지 표시
  return `${value.toFixed(2)} ${units[unitIndex]}`;
}

export function cleanNullParams(params) {
  return Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null && value !== ''));
}
