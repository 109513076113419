// 16885 아이돌 로맨스 세트 상품의 spo_id, stores_product_id 15642609 상품아이디
// 15875 bjktest 테스트용 spo_id, stores_product_id 14563697 상품아이디

export const set_1 = {
  spo_ids: [16885, 15875, 16920, 1],
  options: {
    TEE: [
      'TEE - Bleach Black (S)',
      'TEE - Bleach Black (M)',
      'TEE - Bleach Black (L)',
      'TEE - Bleach Black (XL)',
      'TEE - Bleach Black (2XL)',
      'TEE - Off White (S)',
      'TEE - Off White (M)',
      'TEE - Off White (L)',
      'TEE - Off White (XL)',
      'TEE - Off White (2XL)',
      'TEE - Soft Pink (S)',
      'TEE - Soft Pink (M)',
      'TEE - Soft Pink (L)',
      'TEE - Soft Pink (XL)',
      'TEE - Soft Pink (2XL)',
    ],
    Sweater: [
      'Sweater - Bleach Black (M)',
      'Sweater - Bleach Black (L)',
      'Sweater - Bleach Black (XL)',
      'Sweater - Creamy (M)',
      'Sweater - Creamy (L)',
      'Sweater - Creamy (XL)',
      'Sweater - Xmas Red (M)',
      'Sweater - Xmas Red (L)',
      'Sweater - Xmas Red (XL)',
    ],
    'Riding Hood': [
      'Riding Hood - Black (M)',
      'Riding Hood - Black (L)',
      'Riding Hood - Black (XL)',
      'Riding Hood - Creamy (M)',
      'Riding Hood - Creamy (L)',
      'Riding Hood - Creamy (XL)',
      'Riding Hood - Xmas Red (M)',
      'Riding Hood - Xmas Red (L)',
      'Riding Hood - Xmas Red (XL)',
    ],
    Cardigan: [
      'Cardigan - Black (M)',
      'Cardigan - Black (L)',
      'Cardigan - Black (XL)',
      'Cardigan - Creamy (M)',
      'Cardigan - Creamy (L)',
      'Cardigan - Creamy (XL)',
      'Cardigan - Xmas Red (M)',
      'Cardigan - Xmas Red (L)',
      'Cardigan - Xmas Red (XL)',
    ],
    Hat: ['Hat - Black', 'Hat - White'],
    FRIEND: ['FRIEND - Black', 'FRIEND - White'],
  },
};
