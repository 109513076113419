import axios from 'axios';
import { makeApiUrl } from '../../../../shared/util';
import { CreatorStudioOnboardingActionLogBodyType } from '../type';

export const logCreatorStudioOnboardingAction = (
  params: { store_id: number },
  body: CreatorStudioOnboardingActionLogBodyType,
) => {
  return axios.post(makeApiUrl(`/${ET.lang}/@api/stores/:store_id/onboarding_action`, params), body);
};
