import { html } from 'rune-ts';
import {
  AfreecaTvIcon,
  BlogIcon,
  ChzzkIcon2,
  DefaultWebSiteIcon,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../renderApp/components/atoms/Icon';
import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS';

export const getSnsIcon = (url: string) => {
  const default_data = {
    link: UtilS.makeUrl(url) || '',
  };

  if (!url || !url.length)
    return {
      ...default_data,
      tmpl: html``,
      name: '',
    };

  if (url.indexOf('youtube') != -1 || url.indexOf('youtu.be') != -1) {
    return {
      ...default_data,
      tmpl: YoutubeIcon,
      name: 'Youtube',
    };
  } else if (url.indexOf('instagram') != -1) {
    return {
      ...default_data,
      tmpl: InstagramIcon,
      name: 'Instagram',
    };
  } else if (url.indexOf('grafolio') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_14345/2020/3/original/186925762dc538121cd0c87ed585bc8b7272fce2d4296db.svg"
        alt=""
      />`,
      name: 'Grafolio',
    };
  } else if (url.indexOf('facebook') != -1) {
    return {
      ...default_data,
      tmpl: FacebookIcon,
      name: 'Instagram',
    };
  } else if (url.indexOf('twitch') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_14345/2020/3/original/18692618df9f0e7cab511c0d0c2fad8024c0c4dcd6e34a2.svg"
        alt="Twitch"
      />`,
      name: 'Twitch',
    };
  } else if (url.indexOf('twitter') != -1) {
    return {
      ...default_data,
      tmpl: TwitterIcon,
      name: 'Twitter',
    };
  } else if (url.indexOf('x.com') != -1) {
    return {
      ...default_data,
      tmpl: TwitterIcon,
      name: 'Twitter',
    };
  } else if (url.indexOf('comic.naver') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_14345/2020/3/original/186925830b1d4b72512b15bc22adbaef5db59027d214384.svg"
        alt="Comic Naver"
      />`,
      name: 'Comic Naver',
    };
  } else if (url.indexOf('page.kakao') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2020/4/original/18657207f0b6722560c71472ccb36544dc9de28fcdf5da9.svg"
        alt="kakao page"
      />`,
      name: 'Kakao Page',
    };
  } else if (url.indexOf('webtoon.daum') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2020/4/original/186571982c427c152f6a1b41fa67a0e51ed72c26a670e93.svg"
        alt="Daum Webtoon"
      />`,
      name: 'Daum Webtoon',
    };
  } else if (url.indexOf('blog.naver') != -1) {
    return {
      ...default_data,
      tmpl: BlogIcon,
      name: 'Blog',
    };
  } else if (url.indexOf('tiktok') != -1) {
    return {
      ...default_data,
      tmpl: TiktokIcon,
      name: 'Tiktok',
    };
  } else if (url.indexOf('behance') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg"
        alt=""
      />`,
      name: 'behance',
    };
  } else if (url.indexOf('brunch.co') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg"
        alt="Brunch"
      />`,
      name: 'Brunch',
    };
  } else if (url.indexOf('spooncast') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2020/4/original/1866068b7eee88c9b383ca70466400027ca379ae1464b83.svg"
        alt="Spooncast"
      />`,
      name: 'Spooncast',
    };
  } else if (url.indexOf('cafe.daum') != -1) {
    return {
      ...default_data,
      tmpl: html`<img
        src="//s3.marpple.co/files/u_218933/2024/9/original/8ed49b3e902d8b5db4dbc53d48ead42f0a1420a01.svg"
        alt="Daum Cafe"
      />`,
      name: 'Daum Cafe',
    };
  } else if (url.indexOf('chzzk.naver') != -1) {
    return {
      ...default_data,
      tmpl: ChzzkIcon2,
      name: 'Chzzk',
    };
  } else if (url.indexOf('afreecatv') != -1) {
    return {
      ...default_data,
      tmpl: AfreecaTvIcon,
      name: 'afreecatv',
    };
  } else {
    return {
      ...default_data,
      tmpl: DefaultWebSiteIcon,
      name: '',
    };
  }
};
