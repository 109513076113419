import { each, find, go, omit } from 'fxjs/es';
import { MShopAppProductPbOptionTmplS } from '../../S/Tmpl/module/MShopAppProductPbOptionTmplS.js';

export const renderSelectOptionNone = ({ spo_options, spo_option_els, disabled }) => {
  go(
    spo_options,
    each((remaining_spo_option) => {
      const remaining_spo_option_el = go(
        spo_option_els,
        find((spo_option_el) => spo_option_el.dataset.spo_option_id == remaining_spo_option.id),
      );
      remaining_spo_option_el.outerHTML = MShopAppProductPbOptionTmplS.selectOption(
        omit('_')(remaining_spo_option),
        disabled,
      );
    }),
  );
};

export const renderSelectOptionNoneNess = ({ spo_options, spo_option_els }) => {
  go(
    spo_options,
    each((remaining_spo_option) => {
      const remaining_spo_option_el = go(
        spo_option_els,
        find((spo_option_el) => spo_option_el.dataset.spo_option_id == remaining_spo_option.id),
      );
      remaining_spo_option_el.outerHTML = MShopAppProductPbOptionTmplS.selectOptionNess(
        omit('_')(remaining_spo_option),
      );
    }),
  );
};
