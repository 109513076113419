import { filter, go, map } from 'fxjs/es';
import { $findAll } from 'fxdom/es';

export const getCurrentSpoOptions = ({ spo_option_els }) => {
  return go(
    spo_option_els,
    map((spo_option_el) => ({
      id: spo_option_el.dataset.spo_option_id,
      _: {
        spo_option_values: go(
          spo_option_el,
          $findAll('option'),
          filter((option_el) => option_el.value),
          map((option_el) => ({ id: option_el.value, name: option_el.dataset.name })),
        ),
      },
    })),
  );
};
