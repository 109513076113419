import { $findAll, $qs } from 'fxdom/es';
import { each, go } from 'fxjs/es';
import { initMpsTooltips, initTooltips2 } from '../../../../../Util/F/tooltip.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppUpItemF } from '../../../UpItem/F/Function/module/MShopAppUpItemF.js';
import { addRecommendProducts } from './addRecommendProducts.js';

export const afterRender = (el) => {
  const is_ness = UtilS.isNessApp();

  if (is_ness) {
    initTooltips2({
      container_el: el,
      custom: {
        placement: 'bottom',
        animation: 'shift-toward',
        maxWidth: MShopUtilF.isMobile() ? 343 : 310,
        offset: undefined,
      },
    });
  } else {
    initMpsTooltips({
      container_el: el,
      custom: {
        appendTo: $qs('.mshop-cart'),
        placement: 'bottom-start',
        animation: 'shift-toward',
        maxWidth: MShopUtilF.isMobile() ? 343 : 352,
        offset: [0, 2],
        popperOptions: {
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                padding: 16,
                boundariesElement: 'viewport',
              },
            },
          ],
        },
      },
    });
  }

  go(el, $findAll('.canvas-basic-item'), each(MShopAppUpItemF.drawThumbnail));

  addRecommendProducts(el);
};
