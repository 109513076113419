import { go, sel } from 'fxjs/es';
import { $qs } from 'fxdom/es';
import { getCurrentFcanvas } from '../../Fcanvas/cv_object.js';
import { makeCanvasTextured } from '../../cylinder.js';
import {
  loadImageFromUrl,
  makeCanvasByContrastEffect,
  makeCanvasByImg,
  makeCanvasByUrl,
} from '../../../../Canvas/S/util.js';
import { NewMakerCheckerF } from '../../../../NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { deepFindCvObjectsAsync } from '../../util.js';

export const isShadeBp = function (base_product) {
  return go(
    base_product,
    sel('_.base_product_colors.0._.base_product_color_faces.0._.base_product_face'),
    isShadeStyle,
  );
};

export const isShadeStyle = function (fcanvas_or_size_face) {
  return !!sel('preview.shade', fcanvas_or_size_face);
};

export const isShadeStyle2 = function (fcanvas_or_size_face) {
  return !!sel('preview.shade2', fcanvas_or_size_face);
};

export const isBlackAndWhiteContrast = function (fcanvas_or_size_face) {
  return !!sel('preview.shade.is_black_and_white_contrast', fcanvas_or_size_face);
};

export const isShadeBpNotBlackAndWhiteContrast = function (base_product) {
  return go(
    base_product,
    sel('_.base_product_colors.0._.base_product_color_faces.0._.base_product_face'),
    (obj) => isShadeStyle(obj) && !isBlackAndWhiteContrast(obj),
  );
};

export const isFcanvasShadeMode = (fcanvas) => {
  return isShadeStyle(fcanvas) || isShadeStyle2(fcanvas) || isBlackAndWhiteContrast(fcanvas);
};

export const getShadeFcanvas = (fcanvas) => {
  if (!($qs('html.maker_page') || G.mp.maker.is_activated)) return;
  fcanvas = fcanvas || getCurrentFcanvas();
  return isShadeStyle(fcanvas) && fcanvas;
};
export async function renderMakerPageShade(fcanvas, objects) {
  if (!fcanvas) return;
  if (fcanvas.is_preview_pass) return;
  if (sel('preview.shade', fcanvas)) {
    const is_black_and_white_contrast = sel('preview.shade.is_black_and_white_contrast', fcanvas);
    const hex = sel('preview.shade.hex', fcanvas);
    await G.mp.maker.cv_objects_deep_each(objects, async (cv_o) => {
      if (cv_o._data.is_not_design) return;
      if (
        !is_black_and_white_contrast &&
        cv_o?._data?.cv_type === 'cv_image' &&
        cv_o._data.is_black_and_white_contrast
      ) {
        delete cv_o._data.is_black_and_white_contrast;
      }
      if (cv_o._data.is_black_and_white_contrast) return;
      if (is_black_and_white_contrast && cv_o?._data?.cv_type === 'cv_image') {
        cv_o._data.is_black_and_white_contrast = true;
        cv_o._element = await go(
          makeCanvasByContrastEffect(makeCanvasByImg(cv_o._element)),
          (c) => c.toDataURL(),
          loadImageFromUrl,
        );
      } else {
        cv_o._element = await go(
          makeCanvasTextured(cv_o._element, hex, null),
          (c) => c.toDataURL(),
          loadImageFromUrl,
        );
      }
    });
  }
}

export async function hasFullImageCvImage(objects) {
  return deepFindCvObjectsAsync(objects, async (cv_obj) => {
    if (cv_obj._data.cv_type === 'cv_image') {
      const img_canvas = await makeCanvasByUrl(G.to_150(cv_obj._data.image_url));
      return NewMakerCheckerF.isFullImage(img_canvas);
    }
  });
}
