import { html } from 'fxjs/es';

export const fn = ({ id, type }) =>
  html`
    <div class="app-report">
      <form class="app-report__form">
        <div class="app-report__inner-container">
          <div class="app-report__contents">
            <input name="type" type="hidden" value="${type}" />
            <input name="id" type="hidden" value="${id}" />
            <div class="app-report__radio-group">
              <div class="app-report__radio">
                <input
                  type="radio"
                  class="app-report__radio-input"
                  id="desc1"
                  name="description"
                  value="1"
                  checked
                />
                <label for="desc1" class="app-report__radio-label">${T('mshop::report::reportItem1')}</label>
              </div>
              <div class="app-report__radio">
                <input type="radio" class="app-report__radio-input" id="desc2" name="description" value="2" />
                <label for="desc2" class="app-report__radio-label">${T('mshop::report::reportItem2')}</label>
              </div>
              <div class="app-report__radio">
                <input type="radio" class="app-report__radio-input" id="desc3" name="description" value="3" />
                <label for="desc3" class="app-report__radio-label">${T('mshop::report::reportItem3')}</label>
              </div>
              <div class="app-report__radio">
                <input type="radio" class="app-report__radio-input" id="desc4" name="description" value="4" />
                <label for="desc4" class="app-report__radio-label">${T('mshop::report::reportItem4')}</label>
              </div>
              <div class="app-report__radio">
                <input type="radio" class="app-report__radio-input" id="desc5" name="description" value="5" />
                <label for="desc5" class="app-report__radio-label">${T('mshop::report::reportItem5')}</label>
              </div>
              <div class="app-report__radio">
                <input type="radio" class="app-report__radio-input" id="desc6" name="description" value="6" />
                <label for="desc6" class="app-report__radio-label">${T('mshop::report::reportItem6')}</label>
              </div>
            </div>
            <div class="app-report__box app-report__box--background">
              <div class="app-report__text app-report__text--bold">⚠️ ${T('mshop::report::noticeTitle')}</div>
              <ul class="app-report__list">
                <li class="app-report__list-item">
                  <p class="app-report__text">${T('mshop::report::noticeDescription1')}</p>
                </li>
                <li class="app-report__list-item">
                  <p class="app-report__text">${T('mshop::report::noticeDescription2')}</p>
                </li>
              </ul>
            </div>
            <div class="app-report__checkbox">
              <input
                type="checkbox"
                name="is_blocking"
                class="app-report__checkbox-input"
                id="report_check"
                checked
              />
              <label for="report_check" class="app-report__checkbox-label">
                ${T('mshop::report::reportItem7')}
                <span class="app-report__checkbox-description"> ${T('mshop::report::reportItem8')} </span>
              </label>
            </div>
          </div>
          <div class="app-report__box app-report__box--button">
            <button type="submit" class="app-report__button app-report__button--report">
              ${T('mshop::report::title')}
            </button>
          </div>
        </div>
      </form>
    </div>
  `;
