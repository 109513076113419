import { $append, $qs, $remove } from 'fxdom/es';
import { html } from 'rune-ts';
import { UtilS } from '../../../../modules/Util/S/Function/module/UtilS.js';
import { Modal } from '../../renderApp/components/atoms/Modal/Modal.ts';
import { MShopCoreConstantS } from '../../../../modules/MShop/Core/S/Constant/module/MShopCoreConstantS.js';
import { ptr } from '../util/index.ts';
import { ButtonAction } from '../components/atoms/ButtonAction/ButtonAction.ts';
import { MShopUtilF } from '../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';

/**
 * 기존 동의 관련 모달창을 rune modal component 로 띄우기 위한 함수입니다.
 * 반환된 클로저 함수를 실행하면 모달이 열립니다.
 * @param title - modal header title
 * @param body - modal body html
 * @param body_klass - modal body root html
 * @param parent_selector - 부모 엘리먼트 셀렉터
 * @param container_klass - 모달 컨테이너 class
 * @param skip_body_fix - 모달 bodyFix를 안하고 싶으면 true (모달 위에서 모달 띄우는 상황 등)
 * @returns {(function(): void)|*}
 */
export function createAgreeModal({
  title,
  body,
  body_klass,
  style_klass,
  parent_selector = '',
  container_klass = '',
  skip_body_fix = false,
  is_bottom_sheet,
}) {
  const is_mobile = MShopUtilF.isMobile();
  const modalConfirmButton = new ButtonAction({
    label: ET('mps2::modal::확인'),
    type: 'blue',
    size: 'large',
  });
  const getTextMargin = (margin) => `${ptr(margin)} 0`;
  const getSafePaddingBottomPropsStr = (offset) => `
padding-bottom: calc(env(safe-area-inset-bottom) + ${offset}px);
padding-bottom: calc(constant(safe-area-inset-bottom) + ${offset}px);`;

  const modalStyleMap = UtilS.isNessApp()
    ? {}
    : {
        'privacy-popup': `
      <style>
        .Modal {
          z-index: 6 !important;
        }
        .privacy-popup *:not(p) {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        .privacy-popup table {
          border-spacing: 0;
        }
        .privacy-popup th,
        .privacy-popup td {
          border: 0.5px solid ${MShopCoreConstantS.colors.gray_20} !important;
          padding: ${ptr('8')} !important;
        }
        .privacy-popup .privacy-popup__p {
          margin: ${getTextMargin(8)};
          padding: 0;
        }
      </style>
    `,
        terms_body: `
      <style>
        .terms_body {
          padding: 0 !important;
        }
        .terms_body > .body {
          margin: 0 !important;
          height: ${ptr(232)} !important;
        }
        .terms_body .text_body {
          ${is_mobile ? getSafePaddingBottomPropsStr(132) : ''}
        }
        .terms_body .agree_header > p {
          margin: ${getTextMargin(13)};
        }
        .terms_body h3 {
          margin: ${getTextMargin(16)};
        }
        .terms_body p {
          margin: ${getTextMargin(13)};
        }
      </style>
    `,
      };

  // padding-bottom calc(env(safe-area-inset-bottom) + 82px)
  // padding-bottom calc(constant(safe-area-inset-bottom) + 82px)
  const agreeModal = new Modal({
    title,
    size: 'large',
    isOpen: false,
    backdrop: true,
    body: html.preventEscape(`<div class="body">${body} ${modalStyleMap[body_klass] || ''}</div>`),
    footer: modalConfirmButton,
    is_bottom_sheet: is_bottom_sheet ?? MShopUtilF.isMobile(),
    container_klass,
    skip_body_fix,
  });

  agreeModal.className = `don_frame ${style_klass || ''}`;

  if (parent_selector) {
    $append(agreeModal.render(), $qs(parent_selector));
  } else {
    document.body.append(agreeModal.render());
  }
  const modal_body_el = agreeModal.element().querySelector('.body');

  // 기존 템플릿에 존재하는 레거시 버튼 제거
  const button_el = modal_body_el?.querySelector('.option');
  button_el && $remove(modal_body_el.querySelector('.option'));

  modalConfirmButton.addEventListener('click', () => {
    agreeModal.close();
  });

  return () => {
    return agreeModal.open();
  };
}
