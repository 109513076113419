import { filter, map, reduce, add, flat, go, html, sel, strMap } from 'fxjs/es';

export const make = (
  rows,
  {
    basic = false,
    klass = '',
    component = '',
    sensitivity = 1,
    multiple = false,
    min = 0,
    max = Infinity,
    theme, // { color, targets },
  } = {},
) => {
  if (min > 0 || max < Infinity) {
    const count = go(rows, flat, filter(sel('selected')), sel('length'));

    const err = new Error();
    err.statue = 404;
    if (min > 0 && count < min) throw err;
    if (max < Infinity && count > max) throw err;
  }

  const inline_style = !theme
    ? ''
    : go(
        theme.targets,
        map((target) => `${target}: ${theme.color};`),
        reduce(add),
        (styles) => `style="${styles}"`,
      );

  const component_class = component || (basic ? 'button' : '');

  const makeItem = (item) =>
    html` <li
      class="slide-selector__item${component_class ? ` ${component_class}-slider__item` : ''}"
      data-value="${item.value}"
      data-selected="${item.selected}"
      ${item.selected && inline_style}
    >
      ${item.icon
        ? html` <span
            class="slide-selector__item__icon${component_class
              ? ` ${component_class}-slider__item__icon`
              : ''}"
            >${item.icon}</span
          >`
        : ''}
      <span
        class="slide-selector__item__content${component_class
          ? ` ${component_class}-slider__item__content`
          : ''}"
        >${item.content}</span
      >
    </li>`;

  const makeRow = (list) =>
    html` <ul class="slide-selector__row${component_class ? ` ${component_class}-slider__row` : ''}">
      ${strMap(makeItem, list)}
    </ul>`;

  return html`
    <div
      class="slide-selector ${klass}${component_class ? ` ${component_class}-slider` : ''} ${component}"
      ${multiple ? 'data-multiple' : ''}
      ${min ? `data-min="${min}"` : ''}
      ${max ? `data-min="${max}"` : ''}
      data-sensitivity="${sensitivity}"
      ${theme ? `data-theme-color="${theme.color}"` : ''}
      ${theme ? `data-theme-targets="${theme.targets.join(',')}"` : ''}
    >
      <div class="slide-selector__wrapper${component_class ? ` ${component_class}-slider__wrapper` : ''}">
        ${strMap(makeRow, rows)}
      </div>
    </div>
  `;
};

/*
- Sample Data

const slides = [
    [
      { icon: '✌', content: 'Stocks', value: 1234 },
      { icon: '😁', content: 'Entrepreneur', value: 2345 },
      { icon: '⚡', content: 'Phones', value: 3456 },
      { icon: '🔊', content: 'League of Legends' },
      { icon: '🍾', content: 'Cases' },
      { icon: '⌛', content: 'Glitters' },
      { icon: '✌', content: 'Stocks' },
      { icon: '😁', content: 'Entrepreneur' },
      { icon: '⚡', content: 'Phones' },
      { icon: '🍾', content: 'Cases' },
      { icon: '⌛', content: 'Glitters' },
    ],
    [
      { icon: '⌚', content: 'Hello' },
      { icon: '❤', content: 'World' },
      { icon: '🎤', content: 'World of Warcraft' },
      { icon: '😉', content: 'Instagram' },
      { icon: '💔', content: 'Facebook' },
      { icon: '🔊', content: 'League of Legends' },
      { icon: '⌚', content: 'Hello' },
      { icon: '❤', content: 'World' },
      { icon: '🎤', content: 'World of Warcraft' },
      { icon: '😉', content: 'Instagram' },
      { icon: '💔', content: 'Facebook' },
    ],
    [
      { icon: '🎄', content: 'Clubhouse' },
      { icon: '🎧', content: 'Newbee' },
      { icon: '✍', content: 'Small' },
      { icon: '💣', content: 'Starcraft' },
      { icon: '🎄', content: 'Clubhouse' },
      { icon: '🎧', content: 'Newbee' },
      { icon: '✍', content: 'Small' },
      { icon: '💣', content: 'Starcraft' },
    ],
  ];

    <div
        class="category-slider"
        is="slide-selector"
        data-sensitivity="1"
        data-multiple
        style="margin-bottom:30px; background-color: beige;"
    >
        ${ComponentsS.makeSlideSelectorInner(slides)}
    </div>
 */
