import { find, go, html, sel, strMap } from 'fxjs/es';
import { CateListConstantS } from '../../../../../../CateList/S/Constant/module/CateListConstantS.js';
import { SizeTipTmpl } from '../../../../../../CateList/S/Function/SizeTipTmpl.js';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { isAccRingBp } from '../../../S/fs.js';
import { messages } from '../../message_id.js';

const getByLang = (key, obj) => sel(key + _en, obj);
const makeGetByEnv = (is_mobile) => (key, obj) => getByLang(`${key}${is_mobile ? '_m' : '_pc'}`, obj);

export const makeSizeInfoHtml = (base_product) => {
  const size_table = getByLang('size_table', base_product);
  const is_ness = UtilS.isNessApp();
  const bp = base_product;

  return html`
    <div class="pd-bp__section size">
      <div class="pd-bp__section-header">
        <span class="tit">${T('product_detail::Size chart')}</span>
      </div>
      <div class="pd-bp__size-info">
        ${size_table ? `<div class='table-wrapper'>${size_table}</div>` : ''}
        ${!is_ness && ET.lang == 'kr' ? SizeTipTmpl(bp) : ''}
        ${!is_ness &&
        ET.lang == 'jp' &&
        bp.cate_list_id != CateListConstantS.PHONE_ACC_LIST_ID &&
        !isAccRingBp(bp)
          ? `<p class="size_txt1">表記サイズには若干の(約1~3cm)誤差がある場合がございますが、ご了承ください。</p>`
          : ''}
      </div>
    </div>
  `;
};

export const makeBaseProductInfoHtml = (bp, is_mobile) => {
  const getByEnv = makeGetByEnv(is_mobile);

  const base_product_detail = sel('_.base_product_detail', bp);
  const size_compare_is_public = sel('size_compare.is_public', base_product_detail);
  const thumbnails = sel('thumbnails' + _en, base_product_detail);
  const size_compare_items = sel('size_compare.items', base_product_detail);
  const is_vertical = go(thumbnails, (thumbs) => {
    if (!thumbs || !thumbs.length) return false;
    const thumb = find((thumb) => !!thumb.width, thumbs);
    return thumb && parseInt(thumb.width) > parseInt(thumb.height);
  });

  const product_info = getByLang('product_info', bp);
  const brand_name = getByLang('brand_name', bp);
  const brand_description = getByLang('brand_description', bp);
  const has_brand_info = bp.brand_logo && brand_name && brand_description;
  const size_table = getByLang('size_table', bp);
  const print_guide = getByEnv('print_guide', bp);
  const guide = getByEnv('guide', bp);
  const additional_info = getByLang('_.additional_info', bp);
  const is_ness = UtilS.isNessApp();
  // ${UtilS.htmlIf2(is_mobile, () => `<div class='swiper-pagination'></div>`)}

  return html`
    <div class="pd-bp product_info_detail" bp_id="${bp.id}">
      ${UtilS.htmlIf2(
        !is_ness && thumbnails?.length,
        () => `
          <div class="pd-bp__section thumbs">
            <div class="pd-bp__thumbs" is_vertical="${is_vertical}">
              <div class="swiper_thumbnails swiper-container pd-bp__swiper">
                <div class="swiper-wrapper">
                  ${strMap(
                    (thumb) => `
                      <div class="pd-preview__item swiper-slide pd-bp__slide">
                        <img src='${UtilImageS.getResizedUrl({
                          url: thumb.url,
                          format: 'jpg',
                          quality: 90,
                          width: is_mobile ? 800 : 1340,
                        })}' width='100%'>
                      </div>
                    `,
                    thumbnails,
                  )}
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>`,
      )}
      ${UtilS.htmlIf2(
        is_ness && additional_info?.base_info,
        () => `
          <div class="pd-bp__section">
            <div class="pd-bp__section-header">
              <h4 class="tit">${T(messages.product_base_info)}</h4>
            </div>
            <div class="pd-bp__info">
              ${additional_info.base_info}
            </div>
          </div>`,
      )}
      ${UtilS.htmlIf2(
        !is_ness && product_info?.length,
        () => `
          <div class="pd-bp__section">
            <div class="pd-bp__section-header">
              <h4 class="tit">${T(messages.product_base_info)}</h4>
            </div>
            <div class="pd-bp__info">
              ${product_info}
            </div>
            <div class="pd-bp__info">
              <p><strong>${T('product_detail::precautions')}</strong></p>
              <ul>
                <li>
                  ${T('product_detail::precautions_info')}
                </li>
              </ul>
            </div>
            <div class="pd-bp__brand" style="display: ${has_brand_info ? 'block' : 'none'};">
              <div class="pd-bp__brand-logo"></div>
              <img src="${G.to_150(bp.brand_logo) || ''}" alt="" />
              <div class="pd-bp__brand-name">${getByLang('brand_name', bp)}</div>
              <div class="pd-bp__brand-description">${getByLang('brand_description', bp)}</div>
            </div>
          </div>`,
      )}
      ${UtilS.htmlIf2(
        // print_guide?.length,
        false,
        () => `
          <div class="pd-bp__section">
            <div class="pd-bp__section-header">
              <h4 class="tit">${T('product_detail::Print Guide')}</4>
            </div>
            <div class="pd-bp__print-guide">
              ${print_guide}
            </div>
          </div>`,
      )}
      ${UtilS.htmlIf2(
        guide?.length,
        () => `
          <div class="pd-bp__section">
            <div class="pd-bp__section-header">
              <h4 class="tit">${T('product_detail::Guide')}</h4>
            </div>
            <div class="pd-bp__guide">
              ${guide}
            </div>
          </div>`,
      )}
      ${UtilS.htmlIf2(size_table, () => makeSizeInfoHtml(bp))}
      ${UtilS.htmlIf2(
        !is_ness && size_compare_is_public && size_compare_items?.length,
        () => `
          <div class="pd-bp__section size_compare">
            <div class="pd-bp__section-header">
              <h4 class="tit">${T('product_detail::Size Compare')}</h4>
            </div>
            <div class="pd-bp__section-body">
              <div class="size_compare_items swiper-container pd-bp__swiper">
                <div class="swiper-wrapper">
                  ${strMap(
                    (item) => `
                      <div class="item swiper-slide">
                        <img
                          src="${is_mobile ? item.m_url : item.url}"
                          alt=""
                          width='100%'
                        />
                        <div class="txt">${item.txt1}</div>
                        <div class="txt">${item.txt2}</div>
                      </div>`,
                    size_compare_items,
                  )}
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>`,
      )}
    </div>
  `;
};
