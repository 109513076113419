import { extend } from 'fxjs/es';
import { UtilNumberS } from '../../../../Util/Number/S/Function/module/UtilNumberS.js';
import { BaseProductAdditionalPriceRateConstantS } from '../Constant/module/BaseProductAdditionalPriceRateConstantS.js';

function roundToNearestRule(amount) {
  // 뒤 세 자리만 추출
  const lastThree = amount % 1000;
  // 규칙에 따른 처리
  let adjustment;
  if (lastThree === 0) {
    adjustment = 0;
  } else if (lastThree <= 500) {
    /* 1원~499원 -> 500원 */
    adjustment = 500 - lastThree;
  } else if (lastThree <= 900) {
    /* 501원~899원 -> 900원 */
    adjustment = 900 - lastThree;
  } else {
    /* 901원~999원 -> 1,000원 */
    adjustment = 1000 - lastThree;
  }

  // 원래 금액에 조정값 더하기
  return amount + adjustment;
}

const customRounding = (price, lang, partner) => {
  if (partner === BaseProductAdditionalPriceRateConstantS.PARTNER.NESS) {
    if (lang == 'kr') {
      return roundToNearestRule(Math.round(price));
    } else if (lang == 'en') {
      return Math.ceil(price * 100) / 100;
    } else {
      return Math.ceil(price);
    }
  }
};

export const makeProfit = (price_info) => {
  return {
    profit: UtilNumberS.subDecimal(
      customRounding(
        UtilNumberS.mulDecimal(
          price_info.price_without_option,
          UtilNumberS.addDecimal(price_info.bpapr_profit_rate, 1),
        ),
        'kr',
        price_info.bpapr_partner,
      ),
      price_info.price_without_option,
    ),
    profit_en: UtilNumberS.subDecimal(
      customRounding(
        UtilNumberS.mulDecimal(
          price_info.price_without_option_en,
          UtilNumberS.addDecimal(price_info.bpapr_profit_rate, 1),
        ),
        'en',
        price_info.bpapr_partner,
      ),
      price_info.price_without_option_en,
    ),
    profit_jp: UtilNumberS.subDecimal(
      customRounding(
        UtilNumberS.mulDecimal(
          price_info.price_without_option_jp,
          UtilNumberS.addDecimal(price_info.bpapr_profit_rate, 1),
        ),
        'jp',
        price_info.bpapr_partner,
      ),
      price_info.price_without_option_jp,
    ),
  };
};
export const setProfit = (product) => {
  extend(product, makeProfit(product.price_info));
};
