import { type Html, html, on, UnsafeHtml, View } from 'rune-ts';
import klass from './Modal.module.scss';
import { CloseIcon } from '../Icon';
import { classes, htmlIf } from '../../../util';
import { typo } from '../../../typography/typo';

export interface ModalProps {
  isOpen: boolean;
  backdrop?: boolean;
  backdrop_not_close?: boolean;
  size: 'medium' | 'large' | 'free';
  title?: string;
  body: Html | string | View | UnsafeHtml;
  footer?: Html | View;
  disable_escape_keydown?: boolean;
}

// modal 닫힐 때 호출
export class ModalClosingEvent<T = undefined> extends CustomEvent<T> {}

// modal close animation 끝나고 호출됨
export class ModalClosedEvent<T = undefined> extends CustomEvent<T> {}

export class Modal extends View<ModalProps> {
  protected defaultHeader(title: string) {
    return html`
      <div class="${klass.header} ${typo('16_bold')}">
        ${title}
        <button type="button" class="${klass.close_btn}">${CloseIcon}</button>
      </div>
    `;
  }

  protected defaultFooter(footer) {
    return html`<div class="${klass.footer}">${footer}</div>`;
  }

  protected override template(data: ModalProps) {
    const { isOpen, backdrop = true, size, title, body, footer } = data;
    return html`
      <div
        class="${classes(klass.modal_container, {
          [klass.open]: isOpen,
        })}"
      >
        ${htmlIf(
          html`
            <div
              class="${classes(klass.backdrop, {
                [klass.open]: isOpen,
              })}"
            ></div>
          `,
          backdrop,
        )}
        <div
          class="${classes(klass.modal, {
            [klass.medium]: size === 'medium',
            [klass.large]: size === 'large',
          })}"
        >
          ${title ? this.defaultHeader(title) : ''}
          <div class="${klass.body}">${body}</div>
          ${footer ? this.defaultFooter(footer) : ''}
        </div>
      </div>
    `;
  }

  override onRender() {
    if (!this.data.disable_escape_keydown) {
      window.addEventListener('keydown', (e) => {
        if (e.code !== 'Escape') {
          return;
        }
        this.close();
      });
    }
  }

  close<T = undefined>(param?: T) {
    const element = this.element();
    if (!element) return;
    const modal = element.querySelector(`.${klass.modal}`);
    const backdrop = element.querySelector(`.${klass.backdrop}`);
    const onEndAnimation = () => {
      modal?.classList.remove(klass.animate, klass.slide_out);
      element.classList.remove(klass.open);
      modal?.removeEventListener('animationend', onEndAnimation);

      this.dispatchEvent(ModalClosedEvent<T>, {
        bubbles: true,
        detail: param,
      });
    };

    modal?.addEventListener('animationend', onEndAnimation);
    modal?.classList.add(klass.animate, klass.slide_out);
    backdrop?.classList.remove(klass.open);

    this.dispatchEvent(ModalClosingEvent<T>, {
      bubbles: true,
      detail: param,
    });
  }

  open() {
    const element = this.element();
    if (!element) return;

    const modal = element.querySelector(`.${klass.modal}`);
    const backdrop = element.querySelector(`.${klass.backdrop}`);
    // modal?.addEventListener('animationend', () => {
    //   modal?.classList.remove(klass.animate, klass.slide_in);
    // });
    element.classList.add(klass.open);
    backdrop?.classList.add(klass.open);
    modal?.classList.add(klass.animate, klass.slide_in);
  }

  @on('click', `.${klass.backdrop}`)
  _clickBackDrop() {
    if (this.data.backdrop_not_close) return;
    this.close();
  }

  @on('click', `.${klass.close_btn}`)
  _closeModal() {
    this.close();
  }
}
