import { find, go, isEmpty, values } from 'fxjs/es';
import { PBDeliveryTrackingConstantS } from '../../../../../PB/DeliveryTracking/S/Constant/module/PBDeliveryTrackingConstantS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { ECOMMERCE_ERRORS } from '../Constant/ecommerceinfo.js';

export const checkNotSpecialStr = (str, type) => {
  if (!str?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  if (str.length > 255) return { result: false, error: ECOMMERCE_ERRORS.text_length_255, type };

  return { result: true };
};

// export const checkStringOrNumber = (str, type) => {
//   if (!str?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
//   if (str.length > 255) return { result: false, error: ECOMMERCE_ERRORS.text_length_255, type };
//   if (UtilS.is_number_or_str(str)) return { result: false, error: ECOMMERCE_ERRORS.restrict_character, type };
//
//   return { result: true };
// };

export const checkOnlyNumber = (number, type) => {
  if (!UtilS.is_number(number)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };

  return { result: true };
};

export const checkCertName = (str, type) => {
  if (!str?.length) return { result: false, error: ECOMMERCE_ERRORS.cert, type };
  if (str.length > 255) return { result: false, error: ECOMMERCE_ERRORS.text_length_255, type };
  if (UtilS.is_special_str(str)) return { result: false, error: ECOMMERCE_ERRORS.restrict_character, type };

  return { result: true };
};

export const checkCertMobile = (mobile, type) => {
  if (!mobile?.length) return { result: false, error: ECOMMERCE_ERRORS.cert, type };
  if (!UtilS.is_number(mobile)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (!UtilS.isKrPhoneNumber(mobile)) return { result: false, error: ECOMMERCE_ERRORS.mobile_check, type };

  return { result: true };
};

export const checkBirthday = (str, type) => {
  if (!str) return { result: false, error: ECOMMERCE_ERRORS.cert, type };
  if (str.length != 6) return { result: false, error: ECOMMERCE_ERRORS.birthday_length, type };
  if (!UtilS.is_number(str)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };

  return { result: true };
};

export const checkMobile = (mobile, type) => {
  if (!mobile?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  if (!UtilS.is_number(mobile)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (!UtilS.isKrPhoneNumber(mobile)) return { result: false, error: ECOMMERCE_ERRORS.mobile_check, type };

  return { result: true };
};

export const checkTel = (mobile, type) => {
  if (!mobile?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  if (!UtilS.is_number(mobile)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (!UtilS.isKrTelNumber(mobile)) return { result: false, error: ECOMMERCE_ERRORS.mobile_check, type };

  return { result: true };
};

export const checkContactStoresDeliveryCompany = (mobile, email) => {
  if (!email) {
    return checkTel(mobile, 'take_back_mobile');
  } else if (!mobile) {
    return checkEmail(email, 'take_back_email');
  }
  return { result: true };
};

export const checkEmail = (email, type = 'company_email') => {
  if (!email?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  if (!UtilS.isValidEmail(email)) return { result: false, error: ECOMMERCE_ERRORS.email_check, type };

  return { result: true };
};

export const checkPrice = (price, type) => {
  if (!UtilS.is_number(price)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };

  return { result: true };
};

export const checkShippingPrice = (price, type) => {
  if (!UtilS.is_number(price)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (parseInt(price) < 0) return { result: false, error: ECOMMERCE_ERRORS.shipping_price_min_price, type };
  // if (parseInt(price) > 20000)
  //   return { result: false, error: ECOMMERCE_ERRORS.shipping_price_max_price, type };

  return { result: true };
};

export const checkAdditionalShippingPrice = (price, type) => {
  if (!UtilS.is_number(price)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (parseInt(price) < 0)
    return { result: false, error: ECOMMERCE_ERRORS.additional_shipping_price_min_price, type };
  // if (parseInt(price) > 20000)
  //   return { result: false, error: ECOMMERCE_ERRORS.additional_shipping_price_max_price, type };

  return { result: true };
};

export const checkRefundPrice = (price, type) => {
  if (!UtilS.is_number(price)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (parseInt(price) < 0) return { result: false, error: ECOMMERCE_ERRORS.refund_min_price, type };
  // if (parseInt(price) > 80000) return { result: false, error: ECOMMERCE_ERRORS.refund_max_price, type };

  return { result: true };
};

export const checkFreeShippingMinPrice = (price, type) => {
  if (!UtilS.is_number(price)) return { result: false, error: ECOMMERCE_ERRORS.only_number, type };
  if (parseInt(price) < 100)
    return { result: false, error: ECOMMERCE_ERRORS.free_shipping_min_price_min_price, type };
  // if (parseInt(price) > 1000000)
  //   return { result: false, error: ECOMMERCE_ERRORS.free_shipping_min_price_max_price, type };

  return { result: true };
};

export const checkHasSelectedCountries = (selected_countries, type) => {
  if (!selected_countries.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  return { result: true };
};

export const checkBoolean = (bool, type) => {
  if (isEmpty(bool)) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  return { result: true };
};

export const checkUrl = (url, type) => {
  if (!url?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type };
  if (!UtilS.isUrl(url)) return { result: false, error: ECOMMERCE_ERRORS.url_check, type };

  return { result: true };
};

export const checkDeliveryCompanyId = (str) => {
  if (!str?.length) return { result: false, error: ECOMMERCE_ERRORS.required, type: 'delivery_company_id' };

  const delivery_company_info = go(
    PBDeliveryTrackingConstantS.info,
    values,
    find((data) => {
      return data.id == str;
    }),
  );

  if (!delivery_company_info) {
    return { result: false, error: ECOMMERCE_ERRORS.has_delivery_company_id, type: 'delivery_company_id' };
  }

  return { result: true };
};

export const checkMailOrderBusinessNumber = (str) => {
  if (!str?.length) {
    return { result: false, error: ECOMMERCE_ERRORS.required, type: 'mail_order_business_number' };
  }
  if (str.length > 50) {
    return { result: false, error: ECOMMERCE_ERRORS.text_length_50, type: 'mail_order_business_number' };
  }
  return { result: true };
};

export const checkShippingName = (name) => {
  if (name?.length < 2)
    return { result: false, error: ECOMMERCE_ERRORS.text_length_under_2, type: 'shipping_name' };
  if (name?.length > 15)
    return { result: false, error: ECOMMERCE_ERRORS.text_length_15, type: 'shipping_name' };
  if (UtilS.is_special_str(name))
    return { result: false, error: ECOMMERCE_ERRORS.restrict_character, type: 'shipping_name' };

  return { result: true };
};
