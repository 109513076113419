import { go, strMap, zipWithIndexL, html } from 'fxjs/es';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';

export const openMpBpdTipFrame = (animation) => {
  return $.frame.open({
    frame_name: 'mp.bpd_tip_frame',
    animation,
    is_modal: !UtilF.isLegacyMobile(),
    el_class: !UtilF.isLegacyMobile() ? 'is_pc' + ' mp_bpd_tip shrink-modal' : 'is_mobile' + ' mp_bpd_tip',
    header_height: !UtilF.isLegacyMobile() ? 60 : 73,
  });
};

$.frame.defn_frame({
  frame_name: 'mp.bpd_tip_frame',
  page_name: 'mp.bpd_tip_page',
  always_remove: true,
  hide_frame_button_type: ' ',
  title: T('product_detail::Tip'),
  showing: function () {
    $.add_class($1('body'), 'body_scroll');
  },
  hiding: function () {
    $.remove_class($1('body'), 'body_scroll');
  },
  appended: $.on('click', function (e) {
    if (e.target == e.delegateTarget) $.frame.close();
  }),
});

$.frame.defn_page({
  page_name: 'mp.bpd_tip_page',
  tabs: [
    {
      tab_name: 'mp.bpd_tip_tab',
      data_func: function () {
        return box.sel('maker->product_color->_->base_product->_->base_product_detail');
      },
      template: (bpd) => legacyHtml`
        <div
          class="tip_area"
          length="${bpd[!UtilF.isLegacyMobile() ? 'tip_images_pc' + _en : 'tip_images_m' + _en]?.length || 0}"
        >
          <div class="tip_images">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                ${go(
                  !UtilF.isLegacyMobile() ? bpd[`tip_images_pc${_en}`] : bpd[`tip_images_m${_en}`],
                  strMap(
                    (image) => legacyHtml`
                      <div class="swiper-slide item swiper-lazy" data-background="${image.url}">
                        <div class="swiper-lazy-preloader"></div>
                      </div>
                    `,
                  ),
                )}
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-prev swiper-button-black"></div>
              <div class="swiper-button-next swiper-button-black"></div>
            </div>
            <div class="btn_prev"></div>
            <div class="btn_next"></div>
            <div class="tip_pagination">
              ${go(
                !UtilF.isLegacyMobile() ? bpd[`tip_images_pc${_en}`] : bpd[`tip_images_m${_en}`],
                (images) => {
                  if (images?.length) {
                    const _images =
                      !UtilF.isLegacyMobile() && images.length > 2
                        ? images.slice(0, images.length - 2)
                        : images;
                    return html`
                      ${strMap(([i]) => {
                        return legacyHtml` <div class="page_icon" idx="${i}"></div> `;
                      }, zipWithIndexL(_images))}
                    `;
                  }
                },
              )}
            </div>
          </div>
          <div class="tip_description">
            ${go(bpd['tip_description' + _en], (tip_desc) => {
              if (typeof tip_desc != 'string' || !tip_desc.length) return '';
              return G.collabo_type == 'creator' ? tip_desc.replace(', AI', '') : tip_desc;
            })}
          </div>
        </div>
      `,
      appended: __(
        $.on('click', '.tip_pagination .page_icon', function (e) {
          const idx = _p.index_of(
            $.find($.closest(e.$currentTarget, '.tip_pagination'), '.page_icon'),
            e.$currentTarget,
          );

          $.find(e.$delegateTarget, '.swiper-pagination-bullet')[idx].click();
        }),
        $.on('click', '.btn_prev', function (e) {
          $.find1(e.$delegateTarget, '.swiper-button-prev').click();
        }),
        $.on('click', '.btn_next', function (e) {
          $.find1(e.$delegateTarget, '.swiper-button-next').click();
        }),
      ),
      showed: function (tab) {
        new Swiper('.tip_area .swiper-container', {
          spaceBetween: !UtilF.isLegacyMobile() ? 8 : 8,
          slidesPerView: 'auto',
          centeredSlides: UtilF.isLegacyMobile() ? true : !($.find(tab, '.swiper-slide').length > 2),
          grabCursor: true,
          loop: false,
          watchSlidesVisibility: true,
          preloadImages: false,
          lazy: true,
          initialSlide: !UtilF.isLegacyMobile() ? 0 : 0,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          on: {
            slideChange: function () {
              change_page();
            },
          },
        });

        change_page();

        function change_page() {
          const idx = _p.index_of(
            $('.tip_area .swiper-pagination .swiper-pagination-bullet'),
            $1('.tip_area .swiper-pagination-bullet-active'),
          );
          const next_bullet = $1('.tip_area .tip_pagination .page_icon[idx="' + idx + '"]');

          $.remove_class($.siblings(next_bullet), 'active');
          $.add_class(next_bullet, 'active');
        }
      },
    },
  ],
});
