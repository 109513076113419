import { $addClass, $qs, $removeClass } from 'fxdom/es';
import { GoodsTypeConstantS } from '../../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopAppProductS } from '../../../../App/Product/S/Function/module/MShopAppProductS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioEcommerceInfoS } from '../../../EcommerceInfo/S/Function/module/MShopStudioEcommerceInfoS.js';
import { MShopStudioPbProductCreatorMuiF } from '../Mui/module/MShopStudioPbProductCreatorMuiF.js';
import { checkBaseInfo, setEditorAction } from './fn.js';

export const openCreator = async ({ store_id }) => {
  const base_info = await checkBaseInfo(store_id);
  if (!base_info) {
    return;
  }
  const is_mobile = MShopUtilF.isMobile();
  if (is_mobile) {
    $addClass('open-editor', $qs('html'));
  }
  const { product_types, store_ecommerce_info, stores_delivery_companies } = base_info;
  return MuiF.openFrame(MShopStudioPbProductCreatorMuiF.frame, async (f, p, [t]) => {
    box.set('product_types', product_types);
    f.is_modal = !MShopUtilF.isMobile();
    t.makeData = () => ({
      cate_lists: MShopAppProductS.rejectDigitalGoods(true)(box.sel('cate_lists')),
      product_types,
      store_ecommerce_info,
      stores_delivery_companies,
      crew_domain_name: box.sel('crew_domain_name'),
      store_url: box.sel('store_url'),
      action: setEditorAction(),
      goods_type_id: GoodsTypeConstantS.PBG.id,
      is_studio_worker_admin: !!box.sel('is_studio_worker_admin'),
      is_possible_oversea_crew: MShopStudioEcommerceInfoS.isPossibleOverseaByCrewId(box.sel('crew_id')),
    });
    f.closed = () => {
      if (is_mobile) $removeClass('open-editor', $qs('html'));
    };
    f.hiding = async (el, val) => {
      box.set('im_pb_stores_product', null);
      box.set('pb_stores_product', null);
      box.set('pb_product_spo_options', null);
      box.set('pb_thumbnail_files', []);
      box.set('pb_thumbnail_photos', []);
      if (!MShopUtilF.isMobile()) MShopUtilF.bodyFixed$(false);
      if (val) {
        window.location.reload();
      }
    };
  });
};
