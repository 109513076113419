import { getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { onbeforeunload_event_off } from '../../../../Maker/F/util.js';
import {
  initAfterPlacingProductFaces,
  renderNewFacesByCurrentBpcfsInMakerA,
  getTempLocalStorageProduct,
  removeAllTempLocalStorage,
} from '../../../../Maker/F/mp_maker.js';
import { go, each } from 'fxjs/es';
import { NewMakerUtilF } from '../../../Util/F/Function/module/NewMakerUtilF.js';
import { createCvPattern } from '../../../../Maker/F/CvPattern/fs.js';
import { createCvImageP } from '../../../../Maker/F/CvImage/fs.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { isFcanvasShadeMode } from '../../../../Maker/F/CvPreview/Shade/render.js';

function isOriginalImage(url) {
  if (url.indexOf('base64') != -1) return true;
  if (url.indexOf('/original/') != -1) return true;
  if (url.indexOf('/150/') != -1) return false;
  if (url.indexOf('/350/') != -1) return false;
  if (url.indexOf('/600/') != -1) return false;
  if (url.indexOf('/900/') != -1) return false;
  if (url.indexOf('_150x0') != -1) return false;
  if (url.indexOf('_350x0') != -1) return false;
  if (url.indexOf('_600x0') != -1) return false;
  return true;
}

export const service = {
  /* 상품이 처음 세팅 될때 하는 세팅, canvas_editor 자체에 id를 부여 */
  init: (base_product) => {
    box.set('maker->canvas_editor', {
      id: `${base_product.id}_${new Date().getTime()}`,
    });
  },
  get: () => {
    return box.sel('maker->canvas_editor');
  },
  resetAndReload: async () => {
    if (G.collabo_type !== '') return $.alert('해당 서비스에서는 동작하지 않는 기능입니다.');
    getTempLocalStorageProduct();
    removeAllTempLocalStorage();
    onbeforeunload_event_off();
    $.don_loader_start();
    window.location.href = window.location.href = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?bp_id=${getProductColorInMaker().base_product_id}`;
    setTimeout(() => {
      $.don_loader_end();
    }, 5000);
  },
  reset: () => {
    return go(
      renderNewFacesByCurrentBpcfsInMakerA(true),
      () => initAfterPlacingProductFaces(),
      () => {
        NewMakerUtilF.setDataUploadTypeIsDesigned();
        removeAllTempLocalStorage();
      },
    );
  },
  setResizedImageToFit: async (source_cv_obj) => {
    try {
      const fcanvas = source_cv_obj.canvas;
      if (!fcanvas) return;
      if (G.mp.maker.has_dosu_related || isFcanvasShadeMode(source_cv_obj.canvas)) return;
      function setElment(source_cv_obj, cloned_cv_obj) {
        if (cloned_cv_obj._element && cloned_cv_obj._originalElement) {
          source_cv_obj._element = cloned_cv_obj._element;
          source_cv_obj._originalElement = cloned_cv_obj._originalElement;
        }
      }
      if (source_cv_obj._data.cv_type === 'cv_pattern') {
        const new_cv_pattern = await createCvPattern(
          G.mp.maker.to_attrs(source_cv_obj),
          undefined,
          undefined,
          1,
          true,
        );
        setElment(source_cv_obj, new_cv_pattern);
        fcanvas.renderAll();
      }
      if (source_cv_obj._data.cv_type === 'cv_image') {
        const real_width = source_cv_obj.width * source_cv_obj.getObjectScaling().scaleX;
        const src = source_cv_obj.getElement().src;
        if (real_width >= 600 && !isOriginalImage(src)) {
          const cv_image = await createCvImageP(G.mp.maker.to_attrs(source_cv_obj), 1, true);
          setElment(source_cv_obj, cv_image);
          fcanvas.renderAll();
        }
      }
      if (source_cv_obj.type == 'group') {
        go(source_cv_obj._objects, each(service.setResizedImageToFit));
      }
    } catch (e) {
      MakerDebugF.api.MakerDebugPost(
        getProductColorInMaker(),
        e,
        MakerDebugConstantS.Category.Image,
        'setResizedImageToFit',
      );
    }
  },
  // testGet: () => {
  //   console.log(CanvasEditor.get()?.id);
  //   setInterval(() => {
  //     console.log(CanvasEditor.get()?.id);
  //   }, 3000);
  // },
};
