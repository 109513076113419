import $dataStr from 'fxdom/es/dataStr.js';
import { sel, html } from 'fxjs/es';
import { makeUtilScreenNavigate } from '../../../../../../services/MarppleShop/shared/app/navigate.ts';
import ago from '../../../../../Util/S/ago.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppCommunityConstantS } from '../Constant/module/MShopAppCommunityConstantS.js';

export const userSubstr = (str) => {
  if (!str || !str.length) return str || 'NONAME';
  else if (str.length > 5) return str.substr(0, 4).replace('@', '') + '***';
  else if (str.length == 2) return str.substr(0, 1) + '***';
  return str.substr(0, 2) + '***';
};

export const dateFromNow = (date) => {
  return ago(new Date(date));
};
export const getUserName = (user_parent_obj) => {
  const masked_email = sel('_.user.email', user_parent_obj) || 'NONAME';
  const user_nick_name = sel('_.user.nick_name', user_parent_obj);
  const store_name = sel('_.user._.store.name', user_parent_obj);

  return store_name || masked_email || user_nick_name || 'NONAME';
};
export const getUserProfileUrl = (user_parent_obj) => {
  return sel('_.user._.store.thumbnail_img', user_parent_obj) || sel('_.user.profile_image', user_parent_obj);
};

export const getCreatorShopUrl = (user_parent_obj) => {
  const domain_name = sel('_.user._.store.domain_name', user_parent_obj);
  return domain_name && `/${ET.lang}/${domain_name}`;
};

export const makeUserNameChildrenHtml = (user_parent_obj, typo_klass) => {
  const uri = getCreatorShopUrl(user_parent_obj);
  const user_name = UtilS.escape(getUserName(user_parent_obj));

  return sel('_.user._.store.id', user_parent_obj)
    ? html`
        <a class="${typo_klass}" href="${uri}" data-post-message="${$dataStr(makeUtilScreenNavigate(uri))}">
          <span>${user_name}</span>
          <img
            src="//s3.marpple.co/files/u_1504988/2024/2/original/1ed71b2ae0f93a8ba3d8d7db76e331946f2cb37d1.svg"
            alt=""
          />
        </a>
      `
    : html`<span class="${typo_klass}">${user_name}</span>`;
};

export const getCreatorShopLinkKlass = (creator_shop_url) => {
  return creator_shop_url ? MShopAppCommunityConstantS.CREATOR_SHOP_LINK_KLASS : '';
};
