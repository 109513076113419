import { html } from 'fxjs/es';
import klass from './related_item.module.scss';
import { typo } from '../../../../../../services/MarppleShop/shared/typography/typo';

type Plus_content_data = {
  id: number;
  thumbnail_image: string;
  title: string;
  sub_title: string;
};

export const related_section_map = {
  creator_view: {
    title: 'RELATED CREATOR VIEW',
    makeItemHtml: () => {
      return html`<div class="${klass.plus_content_item}"></div>`;
    },
  },
  plus_content: {
    title: 'RELATED CONTENTS',
    makeItemHtml: (content: Plus_content_data, is_mobile: boolean) => {
      const href_attr = `href="/@/todays/${content.id}"`;

      return html`<div class="${klass.plus_content_item} swiper-slide">
        <a ${href_attr}>
          <img class="${klass.thumbnail_image}" src="${content.thumbnail_image}" alt="" />
        </a>

        <div class="${klass.text_container}">
          <a ${href_attr} class="${klass.title} ${typo(is_mobile ? '16_bold' : '20_bold')}">
            ${content.title}
          </a>
          <a ${href_attr} class="${klass.sub_title} ${typo('14_medium')}">${content.sub_title}</a>
        </div>
      </div>`;
    },
  },
  curation: {
    title: 'RELATED CURATION',
    makeItemHtml: () => {
      return html`<div class="${klass.plus_content_item}"></div>`;
    },
  },
};
