import { html } from 'fxjs/es';
import { makeTitleWithTooltipHtml } from '../../../../../Creator/Setting/S/product.tmpl.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { returnIf } from '../../../../Util/S/Function/util.js';
import { PreDiscountPriceExampleImage } from '../Constant/preDiscountConst.js';
import {
  calcDiscountedRateByPercent,
  getPreDiscountPriceDisplayValue,
  getPreDiscountRateDisplayValue,
  isPreProductDiscountSupported,
} from '../Function/preDiscountProduct.js';

/**
 * 마케팅 가격을 설정하는 템플릿
 * 마케팅 가격 관련된 변수명은 "PreDiscountPrice" 이다.
 * @param {object} product
 * @param {number} product.pre_discount_price
 * @param {number} product.price
 * @param {boolean} product.is_pod
 */
export const makePreDiscountPriceTmpl = ({ pre_discount_price, price, profit, is_pod }) => {
  if (!isPreProductDiscountSupported()) {
    return '';
  }

  const is_settings_hidden = !pre_discount_price;
  const tooltip = {
    title: T('마케팅 가격'),
    content: T(
      '마케팅 가격은 실제 가격에 영향을 주지 않는 마케팅 목적의 할인 이전 가격을 의미합니다.<br>(소비자 가격 대비 100원 이상의 금액만 입력 가능)',
    ),
  };

  const component = 'pre-discount-component';

  return html`
    <div class="pre-discount-container">
      <!-- 마케팅 가격 설정 -->
      <div class="pre-discount-toggle">
        <label>
          <input
            type="checkbox"
            class="pre-discount-toggle__checkbox"
            id="pre-discount-checkbox"
            ${UtilTmplS.checkIf(pre_discount_price)}
          />
          ${T('마케팅 가격 설정')}
        </label>
        <div class="pre-discount-example">
          <span class="pre-discount-example__title">예시</span>
          <div class="pre-discount-example__image-container">
            <img
              class="pre-discount-example__image"
              src="${is_pod ? PreDiscountPriceExampleImage.ForPOD : PreDiscountPriceExampleImage.ForNonPOD}"
            />
          </div>
        </div>
      </div>

      <div class="pre-discount-settings ${returnIf(is_settings_hidden, 'hidden')}">
        <!-- 마케팅 가격 -->
        <div class="pre-discount-price">
          <div class="pre-discount-price__header">
            ${is_pod
              ? makeTitleWithTooltipHtml({
                  title: tooltip.title,
                  tooltip_content: tooltip.content,
                })
              : html`
                  <div class="pre-discount-price__header-tooltip pb-editor-form__label-txt">
                    ${tooltip.title}
                    <span class="tooltip-icon2 tooltip-icon2--question">${UtilTmplS.tooltipIcon2El()}</span>
                    <div class="tooltip-container">
                      <p>${tooltip.content}</p>
                    </div>
                  </div>
                `}
          </div>
          <div class="pre-discount-price__body ${component}__input-container">
            <input
              class="${component}__input"
              type="text"
              id="pre-discount-price"
              name="pre_discount_price"
              placeholder="${T('100원 단위로 입력해주세요.')}"
              value="${getPreDiscountPriceDisplayValue(PriceS.price(pre_discount_price))}"
              data-price="${price}"
              min="${PriceS.add(price, 100)}"
              data-is_pod="${is_pod || false}"
            />
            <span class="${component}__input-append">원</span>
            <span class="${component}__input-suffix" data-input_id="pre-discount-price"></span>
          </div>
        </div>

        <!-- 마케팅 할인률 -->
        <div class="pre-discount-rate">
          <span class="pre-discount-rate__header ${component}__title">${T('마케팅 할인률')}</span>
          <div class="pre-discount-rate__body ${component}__input-container">
            <input
              disabled
              class="${component}__input"
              type="text"
              id="discounted-rate"
              placeholder="${'입력한 가격으로 자동 계산됩니다.'}"
              value="${getPreDiscountRateDisplayValue(
                calcDiscountedRateByPercent(pre_discount_price, price),
              )}"
            />
            <span class="${component}__input-append">%</span>
            <span class="${component}__input-suffix" data-input_id="discounted-rate"></span>
          </div>
        </div>
      </div>
    </div>
  `;
};
