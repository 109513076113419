export const types = {
  person: 'PERSON',
  company: 'COMPANY',
};

export const ECOMMERCE_ERRORS = {
  required: {
    value: 'required',
    text: '필수 입력 사항입니다.',
  },

  birthday_length: {
    value: 'birthday_length',
    text: '생년월일은 6자로 입력해주세요.',
  },

  cert: {
    value: 'cert',
    text: '본인 인증이 진행되지 않았습니다.',
  },

  string_or_number: {
    value: 'string_or_number',
    text: '문자와 숫자만 입력해 주세요.',
  },

  address_check: {
    value: 'address_check',
    text: '올바른 주소를 입력해 주세요.',
  },

  mobile_check: {
    value: 'mobile_check',
    text: '올바른 연락처를 입력해 주세요.',
  },

  email_check: {
    value: 'email_check',
    text: '올바른 이메일을 입력해 주세요.',
  },

  url_check: {
    value: 'url_check',
    text: '올바른 URL을 입력해 주세요.',
  },

  text_length_under_2: {
    value: 'text_length_under_2',
    text: '배송정책명은 2자 이상으로 입력해주세요.',
  },

  text_length_15: {
    value: 'text_length_15',
    text: '15자 이하로 입력해 주세요.',
  },

  text_length_50: {
    value: 'text_length_50',
    text: '50자 이하로 입력해 주세요.',
  },

  text_length_100: {
    value: 'text_length_100',
    text: '100자 이하로 입력해 주세요.',
  },

  text_length_255: {
    value: 'text_length_255',
    text: '255자 이하로 입력해 주세요.',
  },

  text_length_300: {
    value: 'text_length_300',
    text: '300자 이하로 입력해 주세요.',
  },

  restrict_character: {
    value: 'restrict_character',
    text: '특수문자를 제외하고 입력해 주세요.',
  },

  only_number: {
    value: 'only_number',
    text: '숫자만 입력해 주세요.',
  },

  min_price100: {
    value: 'min_price100',
    text: '100원 이상 금액을 설정해 주세요.',
  },

  free_shipping_min_price_min_price: {
    value: 'free_shipping_min_price_min_price',
    text: '100원 이상 금액을 설정해 주세요.',
  },

  free_shipping_min_price_max_price: {
    value: 'free_shipping_min_price_max_price',
    text: '1,000,000원 이하로 금액을 설정해 주세요.',
  },

  shipping_price_min_price: {
    value: 'shipping_price_min_price',
    text: '최소 0원 ~ 최대 20,000원 이하로 입력해 주세요.',
  },

  shipping_price_max_price: {
    value: 'shipping_price_max_price',
    text: '최소 0원 ~ 최대 20,000원 이하로 입력해 주세요.',
  },

  additional_shipping_price_min_price: {
    value: 'additional_shipping_price_min_price',
    text: '최소 0원 ~ 최대 20,000원 이하로 입력해 주세요.',
  },

  additional_shipping_price_max_price: {
    value: 'additional_shipping_price_max_price',
    text: '최소 0원 ~ 최대 20,000원 이하로 입력해 주세요.',
  },

  refund_min_price: {
    value: 'refund_min_price',
    text: '최소 0원 ~ 최대 20,000원 이하로 입력해 주세요.',
  },

  refund_max_price: {
    value: 'refund_max_price',
    text: '최소 0원 ~ 최대 80,000원 이하로 입력해 주세요.',
  },

  is_agree: {
    value: 'is_agree',
    text: '개인 정보 수집 및 약관 동의 후 저장해 주세요.',
  },

  seller_confirm: {
    value: 'seller_confirm',
    text: '크리에이터 주의사항을 확인 후 저장해 주세요.',
  },

  has_delivery_company_id: {
    value: 'has_delivery_company_id',
    text: '택배사 정보가 잘 못 되었습니다. 다시 선택해주세요.',
  },

  selected_countries: {
    value: 'selected_countries',
    text: '배송국가를 하나이상 선택해야합니다.',
  },
};
