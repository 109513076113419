/*
 * @dev navigator.share 가 지원되는 환경에서, 클립보드에 복사를 누르거나 공유를 취소했을 때에도 exception 이 울림
 */
export const runNavigatorShare = async ({ title, text, url }: ShareData) => {
  try {
    /* TODO 너무 밤이라 일단 선조치 후보고 하게씁니다. by 필정 */
    await navigator.share({
      // title,
      // text,
      url,
    });
  } catch (err) {
    const error = err as DOMException;
    if (error.name !== 'AbortError') throw err;
  }
};
