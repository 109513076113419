import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict/index.js';
import kr from 'date-fns/locale/ko/index.js';
import en from 'date-fns/locale/en-US/index.js';
import jp from 'date-fns/locale/ja/index.js';

const locale = T.lang == 'kr' ? kr : T.lang == 'jp' ? jp : en;
const suffix = T.lang == 'kr' ? ' 전' : T.lang == 'jp' ? '前' : ' ago';

export default function ago(date) {
  return `${formatDistanceToNowStrict(date, { locale })}${suffix}`;
}
