import { go, html, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { NessCustomMakerFrameShareTmplS } from '../../S/Tmpl/module/NessCustomMakerFrameShareTmplS.js';
import { NessCustomMakerFrameShareF } from '../Function/module/NessCustomMakerFrameShareF.js';
import { NessCustomMakerCoreConstantS } from '../../../../Core/S/Constant/module/NessCustomMakerCoreConstantS.js';
import { NessCustomMakerCoreS } from '../../../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';

const mui_ref_path = '/modules/NessCustomMaker/Frame/Share/F/Mui/bp_option_selector_tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const bp_option_selector_tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  always_remove: true, // closeFrame 때 무조건 삭제

  title: '', // tab button의 title
  selected: true, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {
    // ! makeEvent.js openFrame 에서,
    // return {
    //   product: box().maker.product_color,
    //   category: '??'
    // };
  }, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template({ product, option_id, ui_type = NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.SELECT }) {
    if (!product || !option_id) throw new Error('Need some data to make bp_option_selector_tab');

    const bp_option_group = NessCustomMakerCoreS.getBpOptionByOptionGroupId(product, true, option_id);
    const bp_options = bp_option_group.option_group._.bp_options;
    const selected_id = bp_option_group.selected_id;

    return NessCustomMakerFrameShareTmplS.iscroll_wrapper_tmpl(
      go(
        bp_options,
        strMap((bp_option) =>
          bp_selector_item(
            selected_id,
            bp_option,
            bp_option_group.option_group.id,
            ui_type === NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.IMAGE_SELECT,
          ),
        ),
      ),
      {
        class: ui_type === NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.IMAGE_SELECT ? 'wrap' : '',
      },
    );
  },
  showing(tab_el, data) {},
  showed(tab) {
    NessCustomMakerFrameShareF.iscroll_showed(tab);
  },
  hiding(tab) {
    NessCustomMakerFrameShareF.iscroll_hiding(tab);
  },
  appended(tab) {
    return _pipe(
      $.on3(
        'click',
        '.selector_item.option_item:not(.sold_out)',
        NessCustomMakerFrameShareF.bpOptionSelectEvent,
      ),
    )(tab);
  },
};

const bp_selector_item = (selected_bp_option_id, bp_option, bp_option_group_id, show_img = false) => {
  const _price = `${bp_option.price}`;
  const price = _price.startsWith('-') ? _price : '+' + _price;
  const sold_out = ''; // false ? 'sold_out' : '';

  return html`
    <li
      name="${bp_option_group_id}"
      data-id="${bp_option.id}"
      data-fx-json="${$dataStr(bp_option)}"
      data-dev_name="${bp_option.dev_name}"
      data-is_public="${bp_option.is_public}"
      data-name="${bp_option.name}"
      data-name_en="${bp_option.name_en}"
      data-name_jp="${bp_option.name_jp}"
      data-no="${bp_option.no}"
      value="${bp_option.id}"
      ${selected_bp_option_id === bp_option.id ? 'selected' : ''}
      class="selector_item option_item i_item ${UtilTmplS.selectIf(
        selected_bp_option_id === bp_option.id,
      )} ${sold_out}"
      ${UtilTmplS.disableIf(sold_out)}
    >
      ${UtilS.htmlIf(
        show_img,
        `<img
          data-column_length="2"
          class="selector_item-img"
          src="${bp_option.preview_meta?.selectors?.img}"
          alt=""
        />`,
      )}
      <span>${bp_option['name' + G._en]}</span>
      ${UtilS.htmlIf(_price !== '0', `<span class="item_price" >${UtilS.commify(price)}</span>`)}
      ${UtilS.htmlIf(sold_out, `<span class="item_sold_out" >${ET('common::sold_out')}</span>`)}
    </li>
  `;
};
