import { MShopUtilF } from '../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { openFrameAsset } from '../../../Maker/F/DecorationIcon/event.js';
import { NewMakerCvObjectCvImageF } from '../../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';

export const handleOpenFrameAsset = async () => {
  const image = await openFrameAsset();
  if (image) {
    await NewMakerCvObjectCvImageF.addCvImageToMpMaker(image);
  }
};

export const handleProductLinkShare = async () => {
  const isAndroid = /Android/.test(navigator.userAgent);

  const toast = (msg) => {
    if (isAndroid) return;
    MShopUtilF.popToastMsg(msg, 'info', { has_bottom_margin: true });
  };

  try {
    navigator.clipboard
      .writeText(location.origin + location.pathname)
      .then(() => {
        toast(T('s_about::url_copied2'));
      })
      .catch(() => {
        toast(T('s_about::url_failed'));
      });
  } catch (e) {
    MShopUtilF.clipboard(location.origin + location.pathname);
    toast(T('s_about::url_copied2'));
  }
};
