import axios, { AxiosResponse } from 'axios';
import {
  PatchPopupSortRequestBody,
  PopupResponseStatus,
  PostPopupRequestBody,
  PutPopupRequestBody,
  SetPopupCookieRequestBody,
} from '../type';
import { makeApiUrl } from '../../../shared/util';

export const postSetPopupCookie = async (form: SetPopupCookieRequestBody) => {
  const { data } = await axios.post<PostPopupRequestBody, AxiosResponse<PopupResponseStatus>>(
    '/@api/popups/set-cookie',
    form,
  );
  return data;
};

export const postCrewPopup = async (form: PostPopupRequestBody) => {
  const { data } = await axios.post<PostPopupRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/crews/:crew_id/popups', { crew_id: `${form.crew_id}` }),
    form,
  );
  return data;
};

export const putCrewPopup = async (form: PutPopupRequestBody) => {
  const { data } = await axios.put<PutPopupRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/crews/:crew_id/popups/:id', { crew_id: `${form.crew_id}`, id: `${form.id}` }),
    form,
  );
  return data;
};

export const deleteCrewPopup = async (popup_id: number, crew_id: number) => {
  const { data } = await axios.delete<AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/crews/:crew_id/popups/:id', { crew_id: `${crew_id}`, id: `${popup_id}` }),
  );
  return data;
};

export const patchCrewPopupSort = async (form: PatchPopupSortRequestBody, crew_id: number) => {
  const { data } = await axios.patch<PatchPopupSortRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/crews/:crew_id/popups/sort', { crew_id: `${crew_id}` }),
    form,
  );
  return data;
};

export const postStorePopup = async (form: PostPopupRequestBody) => {
  const { data } = await axios.post<PostPopupRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/stores/:store_id/popups', { store_id: `${form.store_id}` }),
    form,
  );
  return data;
};

export const putStorePopup = async (form: PutPopupRequestBody) => {
  const { data } = await axios.put<PutPopupRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/stores/:store_id/popups/:id', { store_id: `${form.store_id}`, id: `${form.id}` }),
    form,
  );
  return data;
};

export const deleteStorePopup = async (popup_id: number, store_id: number) => {
  const { data } = await axios.delete<AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/stores/:store_id/popups/:id', { store_id, id: popup_id }),
  );
  return data;
};

export const patchStorePopupSort = async (form: PatchPopupSortRequestBody, store_id) => {
  const { data } = await axios.patch<PatchPopupSortRequestBody, AxiosResponse<PopupResponseStatus>>(
    makeApiUrl('/@api/stores/:store_id/popups/sort', { store_id: `${store_id}` }),
    form,
  );
  return data;
};
