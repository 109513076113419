import { $qs } from 'fxdom/es';
import { compact, each, entries, every, filter, go, map, reject } from 'fxjs/es';
import { getBaseProductInMaker, getRealFcanvass } from '../../../../Maker/F/getSth.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NewMakerCheckConditionConstantS } from '../../../CheckCondition/S/Constant/module/NewMakerCheckConditionConstantS.js';
import { NewMakerConditionPopupF } from '../../../ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';
import { NewMakerConditionAgreeTextF } from '../../../ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { renderVisibleWhenCleared } from '../../../../Maker/F/select_faces.js';
import { NewMakerCallConditionPopupF } from './module/NewMakerCallConditionPopupF.js';
import { setIScroll } from '../../../../Maker/F/Marpplizer/fs.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { toggleButtonActive } from '../../../../NessCustomMaker/Marpplizer/F/Function/generalOptionButtons.js';

export let conditionPass = {};

let async_queue = null;

class AsyncQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
  }

  enqueue(cv_object_to_push) {
    this.queue = this.queue.filter((cv_object) => cv_object !== cv_object_to_push);
    this.queue.push(cv_object_to_push);

    if (this.processing) return;
    this.processQueue();
  }

  async processQueue() {
    this.processing = true;
    while (this.queue.length > 0) {
      const cv_object = this.queue.shift();
      try {
        await call([cv_object]);
      } catch (error) {
        if (!window.ENV?.is_prod) {
          console.error(error);
        }
      }
    }
    uiRender();
    this.processing = false;
  }
}

export const setAndRender = (cv_target) => {
  if (!cv_target) return;
  if (!async_queue) async_queue = new AsyncQueue();
  if (!is_mapping_confirm_popup()) {
    return;
  }
  // async_queue.enqueue(cv_target);

  if (cv_target._objects?.length && !cv_target._data?.cv_type) {
    /* 임시 그룹 일때 */
    cv_target._objects.forEach((cv_target) => {
      async_queue.enqueue(cv_target);
    });
  } else {
    async_queue.enqueue(cv_target);
  }
};

const checkConditionAgreement = () => {
  const makerProps = box().maker?.maker_props;

  if (makerProps == null) return;

  const is_disabled =
    NewMakerCallConditionPopupF.is_mapping_confirm_popup() &&
    NewMakerConditionAgreeTextF.getAgreeTextData()?.length;
  makerProps.is_condition_agree = !is_disabled;
};

export const uiRender = () => {
  if (!UtilF.isLegacyMobile()) {
    NewMakerConditionAgreeTextF.renderActiveObj(G.mp.maker.active());
    const is_ness_custom_maker = $qs('html.ness-custom-maker');
    if (UtilS.isNessApp() && is_ness_custom_maker) {
      const is_disabled =
        NewMakerCallConditionPopupF.is_mapping_confirm_popup() &&
        NewMakerConditionAgreeTextF.getAgreeTextData()?.length;
      toggleButtonActive(!is_disabled);
    } else {
      NewMakerConditionAgreeTextF.checkAddCartDisabled();
    }
  }
  toggleTooltip();
  checkConditionAgreement();
  setIScroll($qs('.activity'));
};

export const deleteAndRender = (cid) => {
  if (!cid) return;
  if (!is_mapping_confirm_popup()) return;
  deleteConditionPass(cid);
  uiRender();
};

export const toggleTooltip = () => {
  const tooltip_confirm_popups = NewMakerConditionPopupF.getTooltipConfirmPopups(G._en);
  if (!tooltip_confirm_popups?.length) return;
  go(
    tooltip_confirm_popups,
    map((confirm_popup) => {
      const passes = go(
        conditionPass,
        entries,
        map(([key, cid_condition_pass]) => key.match(/^c.*[0-9]$/) && cid_condition_pass[confirm_popup.type]),
        compact,
      );

      return [confirm_popup, passes?.length ? every(({ is_pass }) => is_pass, passes) : true];
    }),
    each((condition_pass) => outBoundsFullFillForToolTip(...condition_pass)),
  );
};

const call = async (designs) => {
  const confirm_popups = go(
    getBaseProductInMaker()._?.confirm_popups,
    filter(
      (confirm_popup) => confirm_popup.type !== NewMakerCheckConditionConstantS.CONFIRM_POPUP_WRONG_TEXT_TYPE,
    ),
  );
  if (!confirm_popups?.length) return;
  await go(
    designs,
    compact,
    each(async (design) => {
      if (!design.canvas) return;
      await go(
        confirm_popups,
        each(async (confirm_popup) => {
          const confirm_popup_condition = confirm_popup.type;
          const is_pass =
            !design.visible ||
            (await NewMakerConditionPopupF.checkCondition(confirm_popup_condition, design));
          updateConditionPass({ cid: design.cid, confirm_popup_condition, value: { is_pass } });
        }),
      );
      design.is_warning = !Object.values(conditionPass[design.cid] || {})
        .map((pass) => pass.is_pass)
        .every((v) => v);
      design.canvas?.renderAll();
    }),
  );
};

function outBoundsFullFillForToolTip(confirm_popup, is_pass) {
  const condition_tooltip = $qs('.condition_tooltip');
  if (condition_tooltip) {
    const tooltip = confirm_popup[`tooltip${G._en}`];
    if (tooltip) {
      const condition_tooltip_img = $qs('.condition_tooltip_img img');
      if (condition_tooltip_img) {
        condition_tooltip_img.src = tooltip;
      }
      condition_tooltip.style.display = is_pass ? 'none' : UtilF.isLegacyMobile() ? 'flex' : 'block';
    }
  }
}

export function is_mapping_confirm_popup() {
  const confirm_popups = getBaseProductInMaker()?._?.confirm_popups;
  return !!confirm_popups?.length;
}

export function updateConditionPass({ cid, confirm_popup_condition, value }) {
  // if (!conditionPass[confirm_popup_condition]) conditionPass[confirm_popup_condition] = {};
  if (!conditionPass[cid]) conditionPass[cid] = {};
  if (!conditionPass[cid][confirm_popup_condition]) conditionPass[cid][confirm_popup_condition] = {};

  // conditionPass[confirm_popup_condition] = {
  //   ...conditionPass[confirm_popup_condition],
  //   ...value,
  // };

  conditionPass[cid][confirm_popup_condition] = {
    ...conditionPass[cid][confirm_popup_condition],
    ...value,
  };
}

export function deleteConditionPass(cid) {
  delete conditionPass[cid];
}

export function resetConditionPass() {
  conditionPass = {};
}

export function reset() {
  resetConditionPass();
  const condition_tooltip = $qs('.condition_tooltip');
  if (condition_tooltip) {
    condition_tooltip.style.display = 'none';
    const condition_tooltip_img = $qs('.condition_tooltip_img img');
    if (condition_tooltip_img) {
      condition_tooltip_img.src = '';
    }
  }
  if (is_mapping_confirm_popup()) {
    go(
      getRealFcanvass(),
      each((fcanvas) => {
        const designs = G.mp.maker.designs(fcanvas);
        go(
          designs,
          each((design) => {
            design.is_warning = false;
          }),
        );
        if (!designs?.length) {
          uiRender();
        } else {
          go(
            designs,
            reject((cv_obj) => cv_obj._data.clone_parent_cid),
            each((design) => {
              setAndRender(design);
            }),
          );
        }
      }),
    );
  }
}

export function resetForNotShowingWarning() {
  resetConditionPass();
  const condition_tooltip = $qs('.condition_tooltip');
  if (condition_tooltip) {
    condition_tooltip.style.display = 'none';
    const condition_tooltip_img = $qs('.condition_tooltip_img img');
    if (condition_tooltip_img) {
      condition_tooltip_img.src = '';
    }
  }
  if (is_mapping_confirm_popup()) {
    go(
      getRealFcanvass(),
      each((fcanvas) => {
        const designs = G.mp.maker.designs(fcanvas);
        go(
          designs,
          each((design) => {
            design.is_warning = false;
          }),
        );
        fcanvas.renderAll();
        renderVisibleWhenCleared(fcanvas);
      }),
    );
  }
}
