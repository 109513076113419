import { html, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';

export const modetail = ({ photos }) => html`
  <div class="article_detail_mo_photos">
    ${strMap(
      (p) => `
        <div class="photo"><img src="${UtilImageS.getResizedUrl({
          url: p.url,
          width: 1024,
          format: 'webp',
        })}" alt=""></div>
      `,
      photos,
    )}
  </div>
`;
