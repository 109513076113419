import dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

const menu = () => {
  return [
    // 인기 셀러
    {
      name: T('mshop::QuickMenu::t2'),
      mobileName: T('mshop::QuickMenu::t2_mo'),
      href: `/${T.lang}/@/sellers/sub?type=popular`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/2f8d93a5de45affe39c951decbc43c02899cbd2e1.jpg',
      message: dataStr({
        navigate: {
          name: 'UtilScreen',
          params: {
            title: T('mshop::QuickMenu::t2'),
            uri: `/${T.lang}/@/sellers/sub?type=popular`,
          },
        },
      }),
    },

    // 실시간 랭킹
    {
      name: T('mshop::QuickMenu::t4'),
      href: `/${T.lang}/@/live`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/1b04a54789d5b35222a8f4b2f264a6d4897d657f2.jpg',
      message: dataStr({
        tabNavigate: 'Live',
      }),
    },

    // 크리에이터 신청
    {
      name: T('mshop::QuickMenu::t12'),
      name_android: T('mshop::QuickMenu::t9'),
      href: `/${T.lang}/@/hello`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/1f292e0a401db012498ea4912c4be55763bcce3d5.jpg',
      message: dataStr({
        navigate: {
          name: 'SignUpSeller',
          params: {
            title: T('mshop::QuickMenu::t1'),
            uri: `/${T.lang}/@/hello`,
          },
        },
      }),
    },

    // 공식 굿즈 -> 팝업스토어
    {
      name: T('mshop::QuickMenu::t13'),
      href: `/${T.lang}/@/sellers/banner`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/e2659a28413e145dfbdd0bbbbac49b9776decf861.jpg',
      message: dataStr({
        navigate: {
          name: 'UtilScreen',
          params: {
            title: T('mshop::마플샵 팝업스토어'),
            uri: `/${T.lang}/@/sellers/banner`,
          },
        },
      }),
    },

    // 기업셀러 신청 -> 마플샵 플러스 신청
    {
      name: T('mshop::QuickMenu::t15'),
      href: `/${T.lang}/@/plus`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/62a892f6098ce65864e8425d5b6331afc39f245b6.jpg',
      message: dataStr({
        navigate: {
          name: 'UtilScreen',
          params: {
            title: T('mshop::QuickMenu::t8'),
            uri: `/${T.lang}/@/plus`,
          },
        },
      }),
      is_hidden: T.lang !== 'kr',
    },

    // 오프라인 팝업 신청
    {
      name: T('mshop::QuickMenu::t14'),
      href: `/${T.lang}/@/popupstore/offline`,
      src: '//s3.marpple.co/files/u_218933/2024/4/original/031d88d2281452fdd5427d3484bc1a704cf86ffb1.png',
      message: dataStr({
        navigate: {
          name: 'EmptyScreen',
          params: {
            title: T('mshop::QuickMenu::t14'),
            uri: `/${T.lang}/@/popupstore/offline`,
          },
        },
      }),
    },

    // 유튜브 쇼핑
    {
      name: T('mshop::QuickMenu::t11'),
      href: `/${T.lang}/@/youtube_shopping?utm_source=on_site&utm_medium=organic_own&utm_campaign=240320_youtube_shopping_bridge_traffic_mshop_riri`,
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/84c75ed46d062964880d98dd36667576ec4108c08.jpg',
      target: '_blank',
      message: dataStr({
        navigate: {
          name: 'EmptyScreen',
          params: {
            title: T('mshop::QuickMenu::t11'),
            uri: `/${T.lang}/@/youtube_shopping?utm_source=on_site&utm_medium=organic_own&utm_campaign=240320_youtube_shopping_bridge_traffic_mshop_riri`,
          },
        },
      }),
    },

    // 크리에이터스 클럽
    {
      name: T('mshop::QuickMenu::t10'),
      mobileName: 'CCC',
      href: 'https://creatorscommunity.club/',
      src: '//s3.marpple.co/files/u_1504988/2024/2/original/3c02b15126a28368678d8c53a41f2e32b1b676dd4.jpg',
      target: '_blank',
    },
  ];
};

// const menu_kr = menu.map((m, i) => {
//   if (i == 1) {
//     return menu[2];
//   } else if (i == 2) {
//     return {
//       name: T('mshop::QuickMenu::t10'),
//       href: 'https://creatorscommunity.club/',
//       src: '//s3.marpple.co/files/u_1396787/2022/7/original/1162db8542b565866cad6a7815be79e0b60dd5931.png',
//       target: '_blank',
//     };
//   }
//   return m;
// });

const makeName = ({ name, name_mo, name_android, mobileName }, is_android, is_mobile) => {
  if (is_android && T.lang === 'kr' && name_android) {
    return name_android;
  } else if (is_mobile && mobileName) {
    return mobileName;
  } else {
    return name;
  }
};

export const quickMenu = (is_android, is_mobile) => html`
  <div class="app-home-quick-menu">
    <div class="app-home-quick-menu__scroll-container">
      <div class="app-home-quick-menu__container">
        ${strMap(
          ({ name, name_android, mobileName, href, src, message, target, is_hidden }) =>
            is_hidden
              ? ''
              : html`
                  <a
                    href="${href}"
                    class="app-home-quick-menu__anchor"
                    ${message ? `data-post-message="${UtilS.escape(message)}"` : ''}
                    ${target ? `target=${target}` : ''}
                  >
                    <div class="app-home-quick-menu__image-box">
                      <img class="app-home-quick-menu__image" src="${UtilS.escape(src)}" alt="${name}" />
                    </div>
                    <span class="app-home-quick-menu__text"
                      >${makeName({ name, name_android, mobileName }, is_android, is_mobile)}</span
                    >
                  </a>
                `,
          // T.lang == 'kr' ? menu_kr : menu,
          menu(is_mobile),
        )}
      </div>
    </div>
  </div>
`;
