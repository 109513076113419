export const PRODUCT_BADGE_TYPE = {
  real: 'real',
  sold_out: 'sold_out',
  coming: 'coming',
  sell_close: 'sell_close',
  sell_at: 'sell_at',
  limit: 'limit',
  nft: 'nft',
};

export const CUSTOM_IMAGE_FOLDER_NAME = 'custom_image_folder_name';
export const CUSTOM_ALL_FOLDER_NAME = 'all';
