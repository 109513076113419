import { nessHome } from './NessHome.js';
import { marpplizerImage } from './cvImage.js';

export const marpplizerInit = () => {
  G.mp.maker.custom_marpplizer = {
    home: nessHome,
    text: nessHome,
    image: marpplizerImage,
  };
};
