import { go, sel, html } from 'fxjs/es';
import { $find } from 'fxdom/es';
import { SVGEditorTempSvgProductsF } from '../../../../TempSvgProducts/F/Function/module/SVGEditorTempSvgProductsF.js';
import { createCanvasElement, makeCanvasByUrl } from '../../../../../Canvas/S/util.js';
import { BpOptionKeyRingConstantS } from '../../../../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { NewMakerBaseProductsKeyRingF } from '../../../../../NewMaker/BaseProducts/KeyRing/F/Function/module/NewMakerBaseProductsKeyRingF.js';
import { NewMakerCvObjectUtilF } from '../../../../../NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { BpOptionKeyRingS } from '../../../../../BpOption/KeyRing/S/Function/module/BpOptionKeyRingS.js';
import { NewMakerCvObjActionF } from '../../../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { setProductAfterVectorEditor } from './setProductAfterVectorEditor.js';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { OMPCoreUtilF } from '../../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

/*
 * temp_save (bp_option_ids, original_svg_file_url, user_id, base_product_id)
 * product_setting
 * thumbnail_setting (fitted_size)
 * show_price_size
 * */

async function setBpOptionAndShowPriceSize({ product, fitted_size, bp_option_groups_el }) {
  const selected_option_group = product._.selected_option_group;
  const is_free = selected_option_group.bp_option_ids.includes(BpOptionKeyRingConstantS.FREE_BP_OPTION_ID);
  if (is_free) {
    BpOptionKeyRingS.setBpOptionByFittedSize({
      fitted_size,
      base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
      selected_option_group,
    });
  }
  await BpOptionF.makeSelectedOptionPriceC2323({
    base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
    bp_option_groups_el,
    selected_option_group,
  });
  const price = product['price' + _en];
  if (typeof G.mp.maker.priceAlertBySize === 'function') {
    return G.mp.maker.priceAlertBySize({ price, fitted_size });
  }
  if (G.collabo_type !== 'creator' && is_free) priceAlertBySize({ price, fitted_size });
}
function priceAlertBySize({ fitted_size, price }) {
  if (G.collabo_type === '') {
    return OMPCoreUtilF.titleAlert({
      title: T('maker_error_alert::크기에 맞춰 가격이 책정되었어요.'),
      content: `${T('maker_basic_word::사이즈')}: ${fitted_size.width} x ${fitted_size.height} mm<br />${T(
        'maker_basic_word::가격',
      )}: ${UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang)}`,
      style: { content_color: 'OG' },
    });
  }
  const color = '#0157ff';
  return $.alert(html`
    <div>${T('maker_error_alert::크기에 맞춰 가격이 책정되었어요.')}</div>
    <div style="color:${color};">
      ${T('maker_basic_word::사이즈')}: ${fitted_size.width} x ${fitted_size.height} mm<br />${T(
        'maker_basic_word::가격',
      )}:
      <span>${UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang)}</span>
    </div>
  `);
}
async function setThumbnailAndPrice({
  fitted_size,
  cutting_line_svg_file,
  printable_png_file,
  thumbnail_png_file,
  product,
  hook_position_ratio,
  bp_option_groups_el,
}) {
  const cutting_line_svg_str = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      reject(new Error('cutting_line_svg_file 을 읽을 수 없습니다.'));
    });
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsText(cutting_line_svg_file);
  });

  const printable_data_url = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      reject(new Error('printable_png_file 을 읽을 수 없습니다.'));
    });
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(printable_png_file);
  });
  const thumbnail_data_url = thumbnail_png_file
    ? await new Promise((resolve, reject) => {
        const file_reader = new FileReader();
        file_reader.addEventListener('error', (event) => {
          console.error(event);
          reject(new Error('printable_png_file 을 읽을 수 없습니다.'));
        });
        file_reader.addEventListener('load', () => resolve(file_reader.result));
        file_reader.readAsDataURL(thumbnail_png_file);
      })
    : await (() => {
        return go(printable_data_url, async (printable_data_url) => {
          const canvas = await makeCanvasByUrl(printable_data_url);
          const size = 2000;
          const c = createCanvasElement(
            canvas.width > canvas.height
              ? { width: size, height: (canvas.height * size) / canvas.width }
              : { width: (canvas.width * size) / canvas.height, height: size },
          );
          c.getContext('2d').drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, c.width, c.height);
          return c.toDataURL();
        });
      })();
  const selected_option_group = product._.selected_option_group;
  const material = selected_option_group.bp_option_ids.includes(
    BpOptionKeyRingConstantS.KEYRING_MATERIAL_GL_BP_OPTION_ID,
  )
    ? 'gl'
    : selected_option_group.bp_option_ids.includes(BpOptionKeyRingConstantS.KEYRING_MATERIAL_AU_BP_OPTION_ID)
    ? 'au'
    : '';

  const is_free = selected_option_group.bp_option_ids.includes(BpOptionKeyRingConstantS.FREE_BP_OPTION_ID);
  const ratio =
    (await go(makeCanvasByUrl(thumbnail_data_url), sel('width'))) /
    (await go(makeCanvasByUrl(printable_data_url), sel('width')));
  const { canvas, coord, real_size } = await NewMakerBaseProductsKeyRingF.svgAndPngToCvImages({
    svg_data_url: cutting_line_svg_str,
    png_data_url: thumbnail_data_url,
    hook_position_ratio,
    material,
    ratio,
  });
  const image = await go(
    canvas,
    (canvas) => {
      return NewMakerCvObjectUtilF.makeImageObjForCvObj(canvas.toDataURL());
    },
    (obj) => {
      obj.width_cm = ((obj.width / 300) * 2.54) / ratio;
      obj.height_cm = ((obj.height / 300) * 2.54) / ratio;
      return obj;
    },
  );
  const size_bp_option_id = go(
    bp_option_groups_el,
    $find(`select[data-name="사이즈"]`),
    (select_el) => select_el.value,
  );
  if (is_free) {
    fitted_size.width = Math.min(real_size.width, 100);
    fitted_size.height = Math.min(real_size.height, 100);
  }
  BpOptionKeyRingS.applyC2323AfterEditor({ image, fitted_size, coord, product, size_bp_option_id });

  await NewMakerCvObjActionF.render({ bp_option_ids: selected_option_group.bp_option_ids });

  await setBpOptionAndShowPriceSize({
    product,
    fitted_size,
    bp_option_groups_el,
  });
}
export const keyringPostProcess =
  ({ product, lockable_bp_option_group_els, bp_option_groups_el }) =>
  async ({
    original_svg_file,
    cutting_line_svg_file,
    makePrintablePNGFile,
    hook_position_ratio,
    fitted_size,
    makeThumbnailPNGFile,
  }) => {
    const formData = new FormData();
    formData.append('original_svg_file', await original_svg_file);
    const { original_svg_file_url } = await $.upload(formData, {
      url: '/@fileUpload/svg_images_images/upload_svg_product_materials',
      data: {},
    });
    await SVGEditorTempSvgProductsF.saveTempSvgProduct({
      bp_option_ids: product._.selected_option_group.bp_option_ids,
      original_svg_file_url,
      user_id: box.sel('is_user->id'),
      base_product_id: product.base_product_id,
    });
    const { file: printable_png_file, width: real_size } = await makeBiggerHoleFiles(
      await makePrintablePNGFile(),
      hook_position_ratio,
    );
    const { file: thumbnail_png_file } = await makeBiggerHoleFiles(
      await makeThumbnailPNGFile(),
      hook_position_ratio,
      real_size,
    );
    // const printable_png_file = await makePrintablePNGFile();
    setProductAfterVectorEditor({
      lockable_bp_option_group_els,
      bp_option_groups_el,
      product,
      original_svg_file_url,
      printable_file: printable_png_file,
      cutting_line_svg_file,
    });
    await setThumbnailAndPrice({
      fitted_size,
      cutting_line_svg_file,
      printable_png_file,
      thumbnail_png_file,
      product,
      hook_position_ratio,
      bp_option_groups_el,
    });
  };

async function makeBiggerHoleFiles(keyring_design_file, hook_position_ratio, real_size) {
  const printable_data_url = await new Promise((resolve, reject) => {
    const file_reader = new FileReader();
    file_reader.addEventListener('error', (event) => {
      console.error(event);
      reject(new Error('printable_png_file 을 읽을 수 없습니다.'));
    });
    file_reader.addEventListener('load', () => resolve(file_reader.result));
    file_reader.readAsDataURL(keyring_design_file);
  });
  const canvas = await makeCanvasByUrl(printable_data_url);
  const hole_cm = 0.4;
  const cm = real_size ? hole_cm * (canvas.width / real_size) : hole_cm;
  const ctx = canvas.getContext('2d');
  const x = hook_position_ratio.x * canvas.width;
  const y = hook_position_ratio.y * canvas.height;
  ctx.globalCompositeOperation = 'destination-out';
  ctx.beginPath();
  ctx.arc(x, y, (cm / 2 / 2.54) * 300, 0, 2 * Math.PI);
  ctx.fill();
  return new Promise((res) => {
    canvas.toBlob(function (blob) {
      // Create a file object
      res({ file: new File([blob], keyring_design_file.name, { type: 'image/png' }), width: canvas.width });
    });
  });
}

export const keyringProcessByFile = async (
  { product, lockable_bp_option_group_els, bp_option_groups_el },
  {
    cutting_line_svg_file,
    makePrintablePNGFile,
    hook_position_ratio,
    makeThumbnailPNGFile,
    original_svg_file_url,
    original_svg_file_name,
  },
) => {
  const { file: printable_png_file, width: real_size } = await makeBiggerHoleFiles(
    await makePrintablePNGFile(),
    hook_position_ratio,
  );
  const { file: thumbnail_png_file } = await makeBiggerHoleFiles(
    await makeThumbnailPNGFile(),
    hook_position_ratio,
    real_size,
  );
  setProductAfterVectorEditor({
    lockable_bp_option_group_els,
    bp_option_groups_el,
    product,
    original_svg_file_url,
    printable_file: printable_png_file,
    cutting_line_svg_file,
    original_svg_file_name,
  });
  await setThumbnailAndPrice({
    fitted_size: {},
    cutting_line_svg_file,
    printable_png_file,
    thumbnail_png_file,
    product,
    hook_position_ratio,
    bp_option_groups_el,
  });
};
