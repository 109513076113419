import { curry, find, html, strMap } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../Util/S/Function/util.js';
import {
  AFREECA_PRODUCT_STATUS_TOOLTIP,
  PRODUCT_CUSTOM_LEVEL_TEXT,
  PRODUCT_STATUS_TEXT,
  PRODUCT_STATUS_TOOLTIP,
} from './constant.js';

const makeProductSizeOptionHtml = curry(
  (default_value, size) => legacyHtml`
    <option
      value="${size.id}"
      data-price="${size.price}"
      ${default_value === size.id ? 'selected' : ''}
      ${size._is_not_stock ? 'disabled' : ''}
    >
      ${(size._is_not_stock ? `[${T('일시 품절')}] ` : '') + UtilS.escape(size['name' + _en])}
    </option>
  `,
);

export const makeProductSizeSelectorHtml = (base_product_size_id, base_product_sizes) => {
  const selected_item = find(({ id }) => id === base_product_size_id, base_product_sizes);
  return legacyHtml`
    <div class="wrap-selector" data-type="select">
      <select name="size">
        ${strMap(makeProductSizeOptionHtml(base_product_size_id), base_product_sizes)}
      </select>
      <div class="selector-view">
        <span class="selector-view-name"
          >${
            (selected_item._is_not_stock ? `[${T('일시 품절')}] ` : '') +
            UtilS.escape(selected_item['name' + _en])
          }</span
        >
        <span class="selector-view-btn"
          ><img
            src="https://s3.marpple.co/files/u_1187077/2020/1/original/1416625d00f2cd0a85493e5bd05c373d582f6988b2b8c84.svg"
        /></span>
      </div>
    </div>
  `;
};

export const makeProductStatusHtml = (product_status, is_afreeca) =>
  html`<div class="mp-shop-product-status__item" data-status="${product_status}">
    ${PRODUCT_STATUS_TEXT(product_status)}
    <div class="mp-shop-product-status-tooltip">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 10 6">
        <g fill="none" fill-rule="evenodd">
          <g fill="rgba(17, 17, 17, 0.9)" stroke="rgba(17, 17, 17, 0.9)">
            <path
              d="M1 6L5 0 9 6"
              transform="translate(-1604 -233) translate(1531 213) translate(73 20) matrix(-1 0 0 1 10 0)"
            />
          </g>
        </g>
      </svg>
      <div>
        ${is_afreeca
          ? AFREECA_PRODUCT_STATUS_TOOLTIP(product_status)
          : PRODUCT_STATUS_TOOLTIP(product_status)}
      </div>
    </div>
  </div>`;

export const makeProductCustomLevelHtml = (custom_level) => {
  return html`<div class="mp-shop-product-custom_level__item" data-custom_level="${custom_level}">
    ${PRODUCT_CUSTOM_LEVEL_TEXT(custom_level)}
  </div>`;
};
