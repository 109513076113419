import { $, html, View } from 'rune-ts';
import klass from './ButtonClose.module.scss';
import { CloseIcon } from '../Icon';
import anime from 'animejs';
import { htmlIf } from '../../../util';

export interface ButtonCloseProps {
  type?: 'submit' | 'button' | 'reset';
  hide?: boolean;
  tag?: 'button' | 'span';
  klass?: string;
  transparent?: boolean;
}

class Button extends View<ButtonCloseProps> {
  override template() {
    const { type, tag } = this.data;
    if (tag === 'span') {
      return html`<span class="${klass.button}"></span> `;
    }

    return html` <button class="${klass.button}" type="${type ?? 'button'}"></button> `;
  }
}

export class ButtonClose extends View<ButtonCloseProps> {
  button: Button;
  hideAnime?: anime.AnimeInstance;

  constructor(data: ButtonCloseProps = {}) {
    super(data);
    this.button = new Button(this.data);
  }

  override template() {
    const { hide, transparent } = this.data;

    return html`
      <label
        data-hide="${!!hide}"
        class="${klass.button_close} ${this.data.klass} ${htmlIf(klass.transparent, !!transparent)}"
      >
        ${this.button}
        <span class="${klass.close}">${CloseIcon}</span>
      </label>
    `;
  }

  show() {
    if (this.hideAnime && !this.hideAnime.paused) {
      this.hideAnime.pause();
    }

    const el = this.element();
    el.style.opacity = '';
    $(el).setAttribute('data-hide', false);
    this.data.hide = false;
  }

  immediateHide() {
    const el = this.element();

    $(el).setAttribute('data-hide', true);
    el.style.opacity = '';
    this.data.hide = true;
  }

  hide() {
    if (this.hideAnime) {
      this.hideAnime.restart();
      return;
    }

    const el = this.element();

    this.hideAnime = anime({
      targets: el,
      opacity: 0,
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => {
        $(el).setAttribute('data-hide', true);
        el.style.opacity = '';
        this.data.hide = true;
      },
    });
  }
}
