export const layout_components = {
  header: 'ness-default-header',
  bottom_header_store_title: 'ness-default-header-bottom-store-title',
  bottom_header_menu_list: 'ness-default-header-bottom-menu-list',
  bottom_header_category: 'ness-default-header-bottom-category',
  global_menu_popup: 'header-global-menu-popup',
  header_left_arrow: 'ness-default-header-left-with-arrow',
  header_left_custom_maker_arrow: 'ness-custom-maker-header-left-with-arrow',
  footer: 'ness-default-footer',
};

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  blue: '#00BAFF',
  error: '#EC1131',

  /* gray scale */
  gray_90: '#606060',
  gray_80: '#9F9F9F',
  gray_60: '#CCCCCC',
  gray_20: '#D9D9D9',
  gray_5: '#F6F6F6',

  /* dimmed effect */
  dim_90: 'rgba(0, 0, 0, 0.90)',
  dim_70: 'rgba(0, 0, 0, 0.70)',
  dim_50: 'rgba(0, 0, 0, 0.50)',
  dim_30: 'rgba(0, 0, 0, 0.30)',

  /* button */
  $button_glass_white_20: 'rgba(255, 255, 255, 0.20)',
  $button_glass_gray_20: 'rgba(217, 217, 217, 0.20)',
};

// domain specific constants
// all crew 처럼 ness 에 대응되는 crew, 나중에는 marketplace 로 맵핑해서 할 수 있도록 각 부분 수정하기
export const ness_crew_id = 539;
