import { formatDistanceToNow } from 'date-fns';
import en from 'date-fns/locale/en-US/index.js';
import jp from 'date-fns/locale/ja/index.js';
import kr from 'date-fns/locale/ko/index.js';
import { find, go, last, sumBy } from 'fxjs/es';
import { NessOutboundS } from '../../../../../../Ness/Outbound/S/Function/module/NessOutboundS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { PRODUCT_BADGE_TYPE } from '../Constant/item.js';

const makeD = ({ day, hour, minute }) =>
  day > 0
    ? `D-${day}`
    : hour > 0
    ? // 키 값을 count로 해야만 i18n의 plural이 작동합니다. 1 hour, 10 hours -> https://www.i18next.com/translation-function/plurals 참고
      T('mshop::ProductBadge::??시간전', { count: hour })
    : T('mshop::ProductBadge::??분전', { count: Math.max(minute, 1) });

export const setBadge = (
  {
    sell_start_at,
    sell_end_at,
    public_at,
    quantity,
    is_quantity_public = true,
    order_count,
    ordered_at,
    is_sptg,
    spo_item_stocks,
    rank,
    pre_discount_rate,
    need_all,
    is_custom = false,
  },
  is_studio,
) => {
  if (UtilS.isNessApp() && !is_studio) {
    return NessOutboundS.getNessProductBadge({
      spo_item_stocks,
      public_at,
      rank,
      pre_discount_rate,
      quantity,
      order_count,
      is_quantity_public,
      sell_start_at,
      sell_end_at,
      is_custom,
    });
  }

  if (!quantity && !sell_start_at && !sell_end_at && !ordered_at && !is_sptg && !spo_item_stocks?.length)
    return null;

  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) <= 0;
  const start_at = new Date(sell_start_at);
  const end_at = new Date(sell_end_at);
  const now = Date.now();
  /* 매진 */
  const badges = [];

  if (!is_studio && ordered_at) {
    const lang = { kr, en, jp };
    return {
      type: PRODUCT_BADGE_TYPE.real,
      name: formatDistanceToNow(new Date(ordered_at), {
        locale: lang[T.lang.toLowerCase()],
      }),
    };
  }

  if ((quantity > 0 && quantity <= order_count) || (sell_start_at && end_at <= now) || is_no_spo_item_stock) {
    badges.push({
      type: PRODUCT_BADGE_TYPE.sold_out,
      name: ET('product_detail::badges::sold_out'),
    });
  } else {
    // if (sell_start_at && sell_end_at) {
    //   const is_coming = now < start_at;
    //   const { day, hour, minute, second } = UtilS.differenceTimeInUnits(
    //     ['day', 'hour', 'minute', 'second'],
    //     is_coming ? start_at : end_at,
    //     now,
    //     true,
    //   );
    //   const d = makeD({ day, hour, minute });
    //   if (is_coming) {
    //     return {
    //       type: PRODUCT_BADGE_TYPE.coming,
    //       name: T(`mshop::ProductBadge::출시${day > 7 ? '예정' : '??'}`, { d }),
    //     };
    //   } else if (end_at > now) {
    //     const is_close_imminent = day <= 2;
    //     badges.push({
    //       type: PRODUCT_BADGE_TYPE.sell_at,
    //       name: T(`mshop::ProductBadge::${is_close_imminent ? '마감??' : '한정'}`, { d }), // d:  `${hour}:${minute}:${second}`,
    //       reference: { is_close_imminent, end_at, time_diff: { day, hour, minute, second } },
    //     });
    //   }
    // }
    //
    // /* 한정 수량 */
    // if (quantity > 0 && quantity > order_count) {
    //   const rest_quantity = quantity - order_count;
    //   const show_rest_quantity =
    //     is_quantity_public && (rest_quantity / quantity <= 0.2 || rest_quantity <= 10);
    //   badges.push({
    //     type: PRODUCT_BADGE_TYPE.limit,
    //     name: T(`mshop::ProductBadge::${show_rest_quantity ? '??개남음' : '한정'}`, {
    //       c: rest_quantity,
    //     }),
    //     reference: { show_rest_quantity, rest_quantity },
    //   });
    // }
    //
    // if (is_sptg) {
    //   badges.push({
    //     type: PRODUCT_BADGE_TYPE.nft,
    //     name: 'NFT' + T(`mshop::ProductBadge::한정`),
    //   });
    // }
  }

  if (quantity > 0 || (sell_start_at && sell_end_at)) {
    badges.push({
      type: PRODUCT_BADGE_TYPE.limit,
      name: ET('product_detail::badges::limited'),
    });
  }

  return is_studio || need_all
    ? badges
    : find(({ name }) => name != T(`mshop::ProductBadge::한정`), badges) || last(badges);
};

export const setSimpleBadge = ({
  sell_start_at,
  sell_end_at,
  quantity,
  order_count,
  ordered_at,
  is_sptg,
  spo_item_stocks,
  is_quantity_public,
}) => {
  if (!quantity && !sell_start_at && !sell_end_at && !ordered_at && !is_sptg && !spo_item_stocks?.length)
    return null;

  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) === 0;
  const start_at = new Date(sell_start_at);
  const end_at = new Date(sell_end_at);
  const now = Date.now();
  /* 매진 */
  const badges = [];

  if ((quantity > 0 && quantity <= order_count) || (sell_start_at && end_at <= now) || is_no_spo_item_stock) {
    badges.push({ type: 'sold_out' });
  } else {
    if (sell_start_at && sell_end_at) {
      const is_coming = now < start_at;
      const { day, hour, minute } = UtilS.differenceTimeInUnits(
        ['day', 'hour', 'minute', 'second'],
        is_coming ? start_at : end_at,
        now,
        true,
      );
      if (is_coming) {
        return { type: 'coming', day, hour, minute };
      } else if (end_at > now) {
        badges.push({ type: 'remaining', day, hour, minute });
      }
    }

    /* 한정 수량 */
    if (quantity > 0 && quantity > order_count) {
      const qty = quantity - order_count;
      badges.push(is_quantity_public ? { type: 'qty', qty } : { type: 'qty_hide' });
    }

    if (is_sptg) {
      badges.push({
        type: 'nft',
        name: 'NFT',
      });
    }
  }
  /* 설정기간 이전 */

  /* 가격할인 */

  /* 실시간 판매 */
  return badges;
};
