export const PB_PRODUCT_TYPES = {
  marpple: 'MARPPLE',
  always: 'ALWAYS',
  hand_made: 'HAND_MADE',
  print_on_demand: 'PRINT_ON_DEMAND',
  custom: 'CUSTOM',
  limited: 'LIMITED',
};

export const QNA_TYPES = {
  BOARD: 'BOARD',
  SNS: 'SNS',
};

export const REFUND_INFO_TYPES = {
  normal: 'NORMAL',
  print_on_demand: 'PRINT_ON_DEMAND',
};

export const GLOBAL_TOGGLE_CLASSNAME = 'pb-global';
export const GLOBAL_WRAP_CLASSNAME = 'pb-global-wrap';

export const CANNOT_GLOBAL_CLASSNAME = 'cannot-global';
