export const thumb_ratios = {
  vert: 394 / 470,
  normal: 394 / 346,
  hori: 394 / 240,
};

export const summary_cols = [
  'id',
  'public_at',
  'feed_category_id',
  'thumb_ratio',
  'thumb_text_position',
  'thumbnail',
  'title',
  'content',
  'thumb_preview_products',
];

export const MPS_TYPE = {
  curation: 'CURATION',
  creator_view: 'CREATOR_VIEW',
  plus: 'PLUS',
};
