import { html, strMap, head } from 'fxjs/es';
import { set_1 } from '../Constant/customselectoption.js';

export const customSelectOptionHTML = ({ spo_text_options }) => {
  if (!spo_text_options.length) return '';

  const data = set_1;
  const spo_text_option = head(spo_text_options);

  return html`
    <div
      class="spo_custom_options"
      data-spo_id="${data.spo_id}"
      data-spo_text_option_id="${spo_text_option.id}"
      data-spo_text_option_name="${spo_text_option.name}"
    >
      <select
        class="spo_custom_option"
        name="custom_spo_option_tee"
        data-name="TEE"
        data-is_default_selected="true"
      >
        <option value="">TEE</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options.TEE)}
      </select>
      <select
        class="spo_custom_option"
        name="custom_spo_option_sweater"
        data-name="Sweater"
        data-is_default_selected="true"
      >
        <option value="">Sweater</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options.Sweater)}
      </select>
      <select
        class="spo_custom_option"
        name="custom_spo_option_riding_hood"
        data-name="Riding Hood"
        data-is_default_selected="true"
      >
        <option value="">Riding Hood</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options['Riding Hood'])}
      </select>
      <select
        class="spo_custom_option"
        name="custom_spo_option_cardigan"
        data-name="Cardigan"
        data-is_default_selected="true"
      >
        <option value="">Cardigan</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options.Cardigan)}
      </select>
      <select
        class="spo_custom_option"
        name="custom_spo_option_hat"
        data-name="Hat"
        data-is_default_selected="true"
      >
        <option value="">Hat</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options.Hat)}
      </select>
      <select
        class="spo_custom_option"
        name="custom_spo_option_friend"
        data-name="FRIEND"
        data-is_default_selected="true"
      >
        <option value="">FRIEND</option>
        ${strMap((name) => {
          return html` <option value="${name}">${name}</option> `;
        }, data.options.FRIEND)}
      </select>
    </div>
  `;
};
