export const NESS_POPUP_PROPERTY = {
  id: 166,
  description: 'ness 점선채우기',
  pc: '//s3.marpple.co/files/u_2798351/2024/10/original/57c3d709f8f41747b17b2124ac5f7f26aa676edb1.png',
  pc_en: '//s3.marpple.co/files/u_3486526/2024/7/original/618de4a6170995a237bdb18ed4228a067d4f5e181.png',
  pc_jp: '//s3.marpple.co/files/u_3486526/2024/7/original/3b6373c369e53c93417391e23709eb1e491c81181.png',
  m: '//s3.marpple.co/files/u_4464247/2024/11/original/3ffa88840cc07a5ba85ccf0292deac0fa744b1921.png',
  m_en: '//s3.marpple.co/files/u_4464247/2024/11/original/3ffa88840cc07a5ba85ccf0292deac0fa744b1921.png',
  m_jp: '//s3.marpple.co/files/u_4464247/2024/11/original/3ffa88840cc07a5ba85ccf0292deac0fa744b1921.png',
  pc2: null,
  pc2_en: null,
  pc2_jp: null,
  m2: null,
  m2_en: null,
  m2_jp: null,
  title: null,
  title_en: null,
  title_jp: null,
  ok_text: '확인했습니다',
  ok_text_en: 'Confirmed',
  ok_text_jp: '確認しました',
  cancel_text: '',
  cancel_text_en: '',
  cancel_text_jp: '',
  type: 'out_bounds_full_filled',
  deleted_at: null,
  is_agreement: false,
  cart_modal_agreement_text: '점선까지 디자인을 다 채우셨나요?',
  cart_modal_agreement_text_en: 'Filled design to the dotted line?',
  cart_modal_agreement_text_jp: '点線までデザインを埋めましたか？',
  tooltip: '//s3.marpple.co/files/u_2798351/2024/10/original/2bd6bd2faade706167d56dcc11947975a6e1bb3e1.png',
  tooltip_en: '//s3.marpple.co/files/u_3486526/2024/7/original/048ca60bdc7b1784319e5d8587925f0e8c7b3db71.png',
  tooltip_jp: '//s3.marpple.co/files/u_3486526/2024/7/original/00f5bdcc14da41d5059b0a02f8298a272e17b3a31.png',
};
