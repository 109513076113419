import { AdScriptFbF } from '../../../../AdScript/Fb/F/Function/module/AdScriptFbF.js';
import { AdScriptKakaoF } from '../../../../AdScript/Kakao/F/Function/module/AdScriptKakaoF.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import {
  getBaseProductInMaker,
  getProductColorInMaker,
  getProductFaces2InMaker,
  getRealFcanvass,
} from '../../../../Maker/F/getSth.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { changeSimpleBpByBpId } from '../../../../Maker/F/change_bp.js';
import { makeBaseProductInfoHtml } from '../../../../MShop/App/ProductDetail/S/Tmpl/partials/baseProductInfo.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NessCustomMakerConstantS } from '../../../S/Constant/module/NessCustomMakerConstantS.js';
import { delay, each, go, html, last, map, not, omit, pick, sel, some, tap } from 'fxjs/es';
import { $closest, $el, $find, $findAll, $insertAfter, $on, $qs, $remove, $replaceWith } from 'fxdom/es';
import { NessCustomMakerTmplS } from '../../../S/Tmpl/module/NessCustomMakerTmplS.js';
import { ProductF } from '../../../../Product/F/Function/module/ProductF.js';
import { BpOptionUploadTypeS } from '../../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { OMPCoreUtilF } from '../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { enterSvgApplication } from '../../../../SVGEditor/ProductDetail/F/Function/enterToVector.js';
import { processAfterSelect } from '../../../../BpOption/F/Function/bpoption.js';
import { NewMakerUtilF } from '../../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { ConfigSentryF } from '../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { setTempLocalStorage } from '../../../../Maker/F/mp_maker.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { MShopShareFramePopUpF } from '../../../../MShop/ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import {
  check_all_in_all_sizes3,
  is_hidden_image_checker_passed,
  isMakerDone,
} from '../../../../Maker/F/overflow.js';
import { createSVGMaterialForPC } from '../../../../SVGEditor/ProductDetail/F/Function/fs.js';
import { GoodsTypeConstantS } from '../../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { MShopUtilF } from '../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { resetUserCounts, setUpCSs } from '../../../../MPay/Cart/F/fs.js';
import { AdScriptNaverF } from '../../../../AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { AdScriptGaF } from '../../../../AdScript/Ga/F/Function/module/AdScriptGaF.js';
import tippy from 'tippy.js';
import { NessCustomMakerCoreF } from './module/NessCustomMakerCoreF.js';
import { PriceS } from '../../../../Price/S/Function/module/PriceS.js';
import { openFrameAsset } from '../../../../Maker/F/DecorationIcon/event.js';
import { NewMakerProductF } from '../../../../NewMaker/Product/F/Function/module/NewMakerProductF.js';
import { NessCoreConstantS } from '../../../../Ness/Core/S/Constant/module/NessCoreConstantS.js';
import { NessCustomMakerCoreS } from '../../S/Function/module/NessCustomMakerCoreS.js';
import { convertSvgTextToSvgEl, getSvgString } from '../../../../SVGEditor/Util/F/Function/util.js';
import { isNeedPreview } from '../../../../Maker/S/categorize.js';
import { NewMakerCallConditionPopupF } from '../../../../NewMaker/CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { renderVisibleWhenSelected } from '../../../../Maker/F/select_faces.js';
import { getCurrentFcanvas, getCvDesigns } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { changeProductBpcA } from '../../../../Maker/F/Product/bpc_fs.js';
import { changeProductBps } from '../../../../Maker/F/Product/bps_fs.js';
import { NewMakerProductS } from '../../../../NewMaker/Product/S/Function/module/NewMakerProductS.js';
import { marpplizerProxy } from '../../../../Maker/F/Marpplizer/marpplizer.js';
import { NewMakerCheckConditionF } from '../../../../NewMaker/CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { NewMakerCompositePreviewF } from '../../../../NewMaker/CompositePreview/F/Function/module/NewMakerCompositePreviewF.js';
import { NewMakerConditionAgreeTextF } from '../../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { SVGEditorUploadImageF } from '../../../../SVGEditor/UploadImage/F/Function/module/SVGEditorUploadImageF.js';

export async function changePhoneCaseBpByBpId(bp_id) {
  const canvass_el = $1('.mp_maker .canvass');
  try {
    $.css(canvass_el, { opacity: 0 });
    const bp = await $.get('/@api/ness_maker/base_product', { id: bp_id });

    if (bp._.confirm_popups?.length) bp._.confirm_popups = [NessCustomMakerConstantS.NESS_POPUP_PROPERTY];
    const base_product_colors = getBaseProductInMaker()._.base_product_colors;
    if (!isNeedPreview(bp)) {
      bp._.base_product_colors = NessCustomMakerCoreS.makeFilteredBaseProductColorsByBpcs(
        bp._.base_product_colors,
        base_product_colors,
      );
    }

    await changeSimpleBpByBpId(bp);

    box().maker.maker_props.is_condition_agree = undefined;
    // toggleButtonActive(false);

    if (NewMakerCheckConditionF.ConditionChecker.canInstantiate()) {
      G.mp.maker.conditionCheckerInstance = new NewMakerCheckConditionF.ConditionChecker();
    } else {
      G.mp.maker.conditionCheckerInstance = null;
    }

    $.css(canvass_el, { opacity: 1 });
    adjustBpTab(bp);
    NewMakerConditionAgreeTextF.checkAddCartDisabled();
    G.mp.maker.SameDesignChecker.reset();
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(
      getProductColorInMaker(),
      e,
      MakerDebugConstantS.Category.ChangeBp,
      'NESS',
    );
    $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
  } finally {
    $.don_loader_end();
    $.css(canvass_el, { opacity: 1 });
  }
}

export const adjustBpTab = (bp) => {
  const bp_tab = $1('[tab_name="bp"] .product_info_detail');
  // const story_tab = $1('[tab_name="story"] .pd-story');
  $replaceWith($el(makeBaseProductInfoHtml(bp, UtilF.isLegacyRealMobile())), bp_tab);
  // const product_detail = box().product_detail;
  // const product_story = sel('product_story', product_detail);
  // $replaceWith($el(makeProductStoryHtml(product_story, UtilF.isLegacyRealMobile())), story_tab);
};

export const vectorEditorTabAppendingMobile = (tab_el) => {
  const add_el = go(tab_el, $find('.icon_menu[data-icon_type="add"]'));
  go(
    html`
      <div
        class="icon_menu footer none"
        data-is_show="true"
        data-is_active="true"
        data-icon_type="ness-custom-maker__asset-library"
      >
        <button type="button" class="icon"></button>
        <span class="label_text">커스텀 에셋</span>
      </div>
    `,
    $el,
    tap(
      $on('click', async () => {
        const image = await openFrameAsset();
        if (image) {
          await SVGEditorUploadImageF.embedImageInSvgEditor(tab_el, image);
        }
      }),
    ),
    $insertAfter(add_el),
  );
  $remove(add_el);
};
export const vectorEditorTabAppendingPc = (tab_el) => {
  const left_menu_els = go(tab_el, $findAll('.left_container .left_menu'));
  go(
    html`
      <div class="left_menu" data-icon_type="ness-custom-maker__asset-library" data-is_show="true">
        <button type="button" class="icon"></button>
        <span class="label_text"> 커스텀 에셋 </span>
      </div>
    `,
    $el,
    tap(
      $on('click', async () => {
        const image = await openFrameAsset();
        if (image) {
          await SVGEditorUploadImageF.embedImageInSvgEditor(tab_el, image);
        }
      }),
    ),
    $insertAfter(last(left_menu_els)),
  );
  go(left_menu_els, each($remove));
};

export async function svgUrlToSvgEl(svg_url) {
  const svg_string = await getSvgString({ svg_url });
  if (UtilS.isEmpty(svg_string)) {
    throw new Error(`Cannot retrieve SVG`);
  }

  const svg_el = convertSvgTextToSvgEl({ svg_string });
  return svg_el;
}

export const noticeContentHtml = async () => {
  const used_folders = box().maker.product_color._.svg_product_materials?.length
    ? await ProductF.getUsedImagesFolder({
        svg_el: await svgUrlToSvgEl(
          box().maker.product_color._.svg_product_materials[0].original_svg_file_url,
        ),
      })
    : await ProductF.getUsedImagesFolder({
        product: box.sel('maker->product_color'),
      });

  const { shipping_price } = box().maker.maker_props;

  const makePriceHtml = (price, show_event_badge) => html` <div class="price">
    ${price
      ? `+ ${UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang)}`
      : html`무료${show_event_badge ? html` <div class="event_badge">Event</div>` : ``}`}
  </div>`;

  // const shipping_info = {
  //   title: '배송비',
  //   content: [
  //     {
  //       title: '별도 배송 상품',
  //       price_tmpl: makePriceHtml(shipping_price, true),
  //     },
  //   ],
  // };

  const asset_info = used_folders.length
    ? {
        title: '에셋',
        content: go(
          used_folders,
          map(({ name, price }) => {
            return {
              title: name,
              price_tmpl: makePriceHtml(price, false),
            };
          }),
        ),
      }
    : null;

  return NessCustomMakerTmplS.makeNoticeHtml({
    notice_infos: [asset_info],
  });
};

export const toVectorEditor = async (tab_el) => {
  // if (G.collabo_type === '' && !isLoginCheck()) {
  //   // const bp_option_ids = getSelectedBpOptionIds(getProductColorInMaker());
  //   // redirect_url.searchParams.set('bp_option_ids', bp_option_ids);
  //   const product_color = box.sel('maker->product_color');
  //   setTempLocalStorageProduct(product_color);
  //
  //   const redirect_url = new URL(location.href);
  //   redirect_url.searchParams.set('login_redirect', 'true');
  //   const login_url = new URL(`${location.origin}/${T.lang}/login`);
  //   login_url.searchParams.set('url', redirect_url.pathname + redirect_url.search + redirect_url.hash);
  //   location.href = login_url.toString();
  //   $.don_loader_start();
  //   setTimeout(() => {
  //     $.don_loader_end();
  //   }, 5000);
  //   return;
  // }

  const mp_maker = $qs('#maker_frame');
  if (
    UtilF.isLegacyMobile() &&
    BpOptionUploadTypeS.UPLOAD_TYPE.isDirectType(getProductColorInMaker()) &&
    mp_maker.dataset.upload_type_direct_is_designed === 'true'
  ) {
    return BpOptionUploadTypeS.UPLOAD_TYPE.notAvailableSvgEnterIfUploadedAlert(
      G.collabo_type === 'creator' ? alert : OMPCoreUtilF.alert,
    );
  }
  const bp_option_groups_el = $qs('.bp_option_groups');
  if (bp_option_groups_el) {
    const isNotNormal = BpOptionF.isVirtualBpOptionNotNormal(bp_option_groups_el);
    if (isNotNormal) {
      await go(
        OMPCoreUtilF.alert({
          content: T(
            'maker_error_alert::We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.',
          ),
        }),
        () => (window.location.search = `?bp_id=${box()?.maker?.product_color?.base_product_id}`),
      );
    }
  }
  const time_per_1000_px = 100;
  const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
  await anime({
    targets: 'html, body',
    scrollTop: 0,
    duration,
    easing: 'easeInOutCubic',
  }).finished;
  await enterSvgApplication({
    tab_el,
    svg_url:
      sel('_.svg_product_materials.0.original_svg_file_url')(box().maker.product_color) ||
      sel('_.svg_product._.svg_product_pages.0._.svg_image.url')(box().maker.product_color),
  });
};

export const bpSelectOptionHandling = async (select_el, selected_bp_option_id) => {
  if (G.mp.maker.active()) G.mp.maker.unselect_all();
  const prev_selected_bp_option_id = select_el.value;
  try {
    const bp_option_group_el = $closest('.bp_option_group')(select_el);
    BpOptionF.selectBpOption({ bp_option_group_el, selected_bp_option_id });
    await processAfterSelect(select_el);
    NewMakerUtilF.setDataUploadTypeIsDesigned();
  } catch (e) {
    await bpSelectOptionHandling(select_el, prev_selected_bp_option_id);
    console.error(e);
    $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
    ConfigSentryF.error(e);
  }
};

function redirectLogin(url, path = 'login') {
  setTempLocalStorage();
  const redirect_url = new URL(url);
  redirect_url.searchParams.set('is_redirect_from_login', 'true');
  const login_url = new URL(`${location.origin}/${T.lang}/${path}`);
  login_url.searchParams.set('url', redirect_url.pathname + redirect_url.search + redirect_url.hash);
  location.href = login_url.toString();
  $.don_loader_start();
  setTimeout(() => {
    $.don_loader_end();
  }, 5000);
}

const nessMakerPass = async () =>
  go(is_hidden_image_checker_passed(), async () =>
    go(
      getRealFcanvass(),
      some((f_canvas) => getCvDesigns(f_canvas._objects)?.length),
      tap(function (has_design) {
        if (!has_design) {
          return $.alert(T('maker_error_alert::ness_no_design'));
        }
      }),
    ),
  );

export const isMakerPass = async () => {
  const product_color = getProductColorInMaker();
  try {
    const base_product = product_color._.base_product;
    if (NewMakerProductS.isUnavailableSize(product_color) && !isNeedPreview(base_product)) {
      $.alert('현재 재고가 없는 상품 입니다.');
      return;
    }
    $.don_loader_start();
    const bp_option_groups_el = $qs('.bp_option_groups');
    if (bp_option_groups_el) {
      product_color._.selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
    }

    await nessMakerPass();
    const is_pass = await isMakerDone();
    if (!is_pass) {
      return;
    }
    box.set('maker->product_color->og_image_url', null);
    check_all_in_all_sizes3();
    const mp_maker_el = $qs('.mp_maker');
    if (mp_maker_el?.dataset?.is_vector === 'true') {
      const bp_option_groups_el = $qs('.bp_option_groups');
      if (bp_option_groups_el?.dataset?.is_designed !== 'true') {
        $.alert(T('maker_error_alert::ness_no_design'));
        return;
      }
    } else if (
      go(
        getProductFaces2InMaker(),
        some((pf) => pf.designs?.length),
        not,
      )
    ) {
      $.alert(T('maker_error_alert::ness_no_design'));
      return;
    }
    if (product_color.product_faces2?.value[0]?.designs?.length) {
      const result = await NewMakerCompositePreviewF.openCompositePreview(product_color, true);
      if (result?.frame_done === false) {
        return;
      }
    }
    return true;
  } catch (e) {
    ConfigSentryF.error(e);
    MakerDebugF.api.MakerDebugPost(
      product_color,
      e,
      MakerDebugConstantS.Category.PostMaker,
      'NESS-MAKER-DONE',
    );
    $.alert(`문제가 발생했습니다.<br>계속 문제가 발생한다면, 고객센터에 문의 주세요.`);
  } finally {
    $.don_loader_end();
  }
};
export const apiCreateUpProduct = async ({ quantity, is_cart, need_close_frame }) => {
  const product_color = getProductColorInMaker();
  try {
    $.don_loader_start();
    const { svg_product_materials, selected_option_group } = await createSVGMaterialForPC(
      pick(['svg_product_materials', 'selected_option_group'])(box.sel('maker->product_color->_')),
    );
    await addToCartOrBuy({
      product_color,
      base_product_size_id: product_color.base_product_size_id,
      original_product_id: box().maker.original_product_id,
      quantity,
      goods_type_id: GoodsTypeConstantS.MPS.id,
      svg_product_materials,
      selected_option_group,
      is_cart,
      need_close_frame,
    });
  } catch (e) {
    ConfigSentryF.error(e);
    MakerDebugF.api.MakerDebugPost(product_color, e, MakerDebugConstantS.Category.PostMaker, 'CART-API');
    $.alert(`문제가 발생했습니다.<br>계속 문제가 발생한다면, 고객센터에 문의 주세요.`);
  } finally {
    $.don_loader_end();
  }
};

function makeDirectUrl(result_up_c) {
  return `${location.origin}/${T.lang}/@/checkout?direct=true&up_ids=${JSON.stringify([result_up_c.id])}`;
}

async function addToCartOrBuy({
  product_color,
  base_product_size_id,
  product_option_id,
  original_product_id,
  quantity,
  goods_type_id,
  svg_product_materials,
  selected_option_group,
  is_cart,
  need_close_frame,
}) {
  const scroll_top_promise = !MShopUtilF.isMobile() ? MShopUtilF.moveScrollToTop(200) : undefined;
  const up_c = setUpCSs(product_color, quantity, {
    type: is_cart ? 'cart' : 'direct',
    base_product_color_id: product_color.base_product_color_id,
    base_product_id: product_color.base_product_id,
    base_product_size_id,
    product_option_id,
    quantity: Math.max(1, quantity || 1),
    store_id: box.sel('store_id'),
  });
  let need_login = false;
  if (UtilS.isNessApp() && (!box.sel('is_user->type') || box.sel('is_user->type') == 'TEMP')) {
    if (
      await MShopShareFramePopUpF.confirm({
        title: ET('ness::login::require::title'),
        body: ET('ness::login::require::body'),
        cancel: T('translation::Cancel'),
        ok: T('translation::Sign in'),
      })
    ) {
      need_login = true;
    } else {
      return;
    }
  }
  up_c._ = { up_c_ss: [{ ...up_c }] };

  await NewMakerProductF.postProcess.tattoo(product_color);
  product_color.is_same_design_with_original = await G.mp.maker.SameDesignChecker.isSameDesign();
  const result_up_c = await $.post('/@api/ups/add_for_asset', {
    product_color: omit(['id', 'thumbnails', 'is_store_original', '_', 'quantity'], product_color),
    up_c,
    original_product_id,
    goods_type_id,
    svg_product_materials,
    selected_option_group,
  });

  if (need_login) {
    if (is_cart) {
      const url = G.update_query_str(location.href, 'pc_id', result_up_c.product_color_id);
      redirectLogin(url);
      await delay(3000)();
      return;
    } else {
      redirectLogin(makeDirectUrl(result_up_c));
      await delay(3000)();
    }
  }
  if (need_close_frame) {
    await MuiF.closeFrame();
  }
  $.frame.extend_state(
    void 0,
    null,
    G.update_query_str(location.href, 'pc_id', result_up_c.product_color_id),
    'replace',
  );
  if (is_cart) {
    await scroll_top_promise;
    MShopUtilF.popToastMsg(T('Added to cart'), 'cart');
    AdScriptNaverF.mpsAddToCart();
    AdScriptGaF.addToCartMPS({ quantity });

    try {
      const track_add_to_cart = {
        content_name: product_color['name' + _en],
        content_ids: original_product_id,
        value: up_c['discounted_price' + _en],
        content_type: 'product',
        currency: ET.lang == 'kr' ? 'KRW' : T.lang == 'jp' ? 'JPY' : 'USD',
      };

      AdScriptFbF.addToCart(track_add_to_cart);
    } catch (e) {}

    if (UtilS.isNessApp()) {
      AdScriptKakaoF.nessAddToCart();
    }

    const data = { ...box.sel('user_counts') };
    data.cart += Number(quantity);
    resetUserCounts({ data });
  } else {
    location.href = makeDirectUrl(result_up_c);
    await delay(3000)();
  }
  return result_up_c;
}

export const registerNoticeTippy = async (el, custom = {}) => {
  const q_mark = $.find1(el, '.ness-custom-maker__question-mark');
  if (!q_mark) return;

  G.mp.maker.notice_tippy_1 = tippy(q_mark, {
    content: await NessCustomMakerCoreF.noticeContentHtml(),
    arrow: false,
    allowHTML: true,
    theme: 'ness-custom-maker',
    maxWidth: 'none',
    ...custom,
  });
  return el;
};

export const priceAlertBySize = ({ fitted_size, bottom_stand_size }) => {
  const color = NessCoreConstantS.colors.gray_90;
  const sizeInfo = bottom_stand_size
    ? `${fitted_size.width} x ${fitted_size.height + bottom_stand_size} mm`
    : `${fitted_size.width} x ${fitted_size.height} mm`;
  const price = NessCustomMakerCoreS.getProductPrice(getProductColorInMaker());

  return $.alert(html`
    <div class="price-alert-body">
      <div class="price-alert-body__title">크기에 따라 가격이 변경되었습니다.</div>
      <div class="price-alert-body__content" style="color:${color};">
        <div>${T('maker_basic_word::사이즈')}<b style="margin-left : 8px">${sizeInfo}</b></div>
        <div class="mp_currency">
          ${T('maker_basic_word::가격')}<b style="margin-left : 8px">${UtilS.commify(price)}</b>
        </div>
      </div>
    </div>
  `);
};

export const changeProductBaseProductColor = async (bpc) => {
  // const product = getProductColorInMaker();
  // const bps_id = product.base_product_size_id;
  // if (
  //   go(
  //     bpc._.sold_out_sizes,
  //     some(({ id }) => id == bps_id),
  //   )
  // ) {
  //   /* 해당 사이즈는 품절*/
  //   const base_product_size_id = go(
  //     bpc._.base_product_sizes2,
  //     filter((bps) => !bps._is_not_stock && !bps._is_discontinued),
  //     head,
  //   );
  //   if (!base_product_size_id) {
  //     /* 사이즈가 모두 단종 또는 일시품절 */
  //     $.alert('해당 사이즈는 모두 단종 또는 일시 품절');
  //     return;
  //   } else {
  //     /* 다른 사이즈로 수정 */
  //     $.alert('다른사이즈로 수정');
  //     product.base_product_size_id = base_product_size_id;
  //   }
  // }
  await changeProductBpcA(bpc);
  await marpplizerProxy();
  if (NewMakerCallConditionPopupF.is_mapping_confirm_popup()) {
    renderVisibleWhenSelected(getCurrentFcanvas());
  }
};

export const changeProductBaseProductSize = async (base_product_size_id) => {
  await changeProductBps(Number(base_product_size_id));
};
