import axios from 'axios';
import {
  $addClass,
  $append,
  $attr,
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $next,
  $on,
  $outerHeight,
  $parent,
  $qs,
  $remove,
  $removeClass,
  $setCss,
  $setHTML,
  $setScrollTop,
  $setText,
  $toggleClass,
  $trigger,
} from 'fxdom/es';
import { each, go, head, reject, sel, tap } from 'fxjs/es';
import { Confirm } from '../../../../../../../services/MarppleShop/renderApp/components/cells/Confirm/Confirm.ts';
import { preventEscape } from '../../../../../../../services/MarppleShop/shared/util/preventEscape.ts';
import { pushLoginStack } from '../../../../../../Creator/Login/F/fs.js';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { UtilInfiniteF } from '../../../../../../Util/Infinite/F/Function/module/UtilInfiniteF.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../../../Util/S/Function/util.js';
import { MShopShareFramePopUpF } from '../../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppReportF } from '../../../../Report/F/Function/module/MShopAppReportF.js';
import { MShopAppStoreCommunityF } from '../../../../Store/Community/F/Function/module/MShopAppStoreCommunityF.js';
import { ARTICLE_PHOTO_TYPES, ARTICLE_TYPES } from '../../../../Store/Community/S/Constant/community.js';
import { MShopAppCommunityF } from '../../../F/Function/module/MShopAppCommunityF.js';
import { MShopAppCommunityDetailMuiS } from '../../S/Mui/module/MShopAppCommunityDetailMuiS.js';
import { MShopAppCommunityDetailTmplS } from '../../S/Tmpl/module/MShopAppCommunityDetailTmplS.js';
import { horizontalPhotoItem } from '../../S/Tmpl/nessDetail.js';
import {
  articleDetailSwiperInit,
  articleDetailSwiperInitNess,
  changeTab,
  initCursorInfiniteScroll,
  resetDetailEl,
  resetDetailLeftRight,
} from '../Function/detail.js';
import { MShopAppCommunityDetailF } from '../Function/module/MShopAppCommunityDetailF.js';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...MShopAppCommunityDetailMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {}, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    MShopAppCommunityDetailF.androidIncreaseCommentCount(tab_el);
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {
    const article = $data($find('.article-detail', tab_el));
    if (!article) return;

    const is_mobile = MShopUtilF.isMobile();

    if (ARTICLE_PHOTO_TYPES.includes(article.type)) {
      if (UtilS.isNessApp()) {
        articleDetailSwiperInitNess(tab_el);
      } else {
        articleDetailSwiperInit(tab_el);
      }
    }

    const product_id = head(article?._?.review?._?.products)?.id;
    if (UtilS.isNessApp()) {
      if (product_id) {
        const photos_api_url = UtilS.makeApiUrl('/:lang/@api/articles/photos/:product_id', {
          lang: ET.lang,
          product_id,
        });

        const makeInfiniteOpt = ({ limit, api_url, container_el, column_count, item_margin, hooks }) => {
          return {
            getItems: (page) => {
              return go(
                axios.get(api_url, {
                  params: {
                    limit,
                    offset: page * limit,
                  },
                }),
                ({ data: { results } }) => {
                  return results;
                },
              );
            },
            makeItemHtml: (photo_infos) => {
              return MShopAppCommunityDetailTmplS.photoItem(photo_infos);
            },
            item_margin,
            uniqueSel: sel('id'),
            getColumnCount: () => column_count,
            container_el,
            makeDividerHtml: () => {},
            hooks,
          };
        };

        /* 리뷰 상세 좌측 탭의 리뷰포토 무한스크롤 */
        UtilInfiniteF.init(
          makeInfiniteOpt({
            limit: is_mobile ? 64 : 42,
            api_url: photos_api_url,
            container_el: $find('.article-detail-photo-list', tab_el),
            column_count: is_mobile ? 4 : 7,
            item_margin: 8,
            hooks: {
              onEmpty: () => {
                $setHTML(`<div>empty photo</div>`, $find('.article-detail-photo-list', tab_el));
              },
            },
          }),
        );

        if (!is_mobile) {
          /* 리뷰 상세 우측 탭의 가로로 되어있는 포토 리스트의 무한스크롤 */
          initCursorInfiniteScroll({
            container_el: $find('.article-detail__horizontal-photos', tab_el),
            infinite_cursor_api: photos_api_url,
            limit: 6,
            html_item: horizontalPhotoItem,
            infiniteCursorFunc: async function infiniteCursorFunc({
              api_url,
              limit,
              cursor,
              direction,
              is_first,
            }) {
              const {
                data: { results, has_next },
              } = await axios.get(api_url, {
                params: { cursor, direction, limit, is_first },
              });

              return { results, has_next };
            },
            first_cursor: article.id,
          });
        }
      }
    }

    const review_right_height = $qs('.article-detail__right')?.clientHeight;
    const header_height = $qs('.article-detail__title')?.clientHeight;
    const right_photos_height = $qs('.article-detail-review__right-photos')?.clientHeight;

    return go(
      tab_el,
      $.on2('click', '.article-detail-info__btn_like', MShopAppCommunityF.articleLike),
      $delegate('click', '.article-comment-write__btn_submit', MShopAppCommunityDetailF.postComment),
      $delegate('keydown', '.article-comment-write__textarea', MShopAppCommunityDetailF.keyDownTextarea),
      $delegate('keyup', '.article-comment-write__textarea', MShopAppCommunityDetailF.keyUpTextarea),
      $delegate(
        'input',
        '.article-comment-write__textarea',
        MShopAppCommunityDetailF.inputTextarea({
          review_right_height,
          header_height,
          right_photos_height,
          is_mobile,
        }),
      ),
      $delegate('click', '.article-detail-comments__btn_del', MShopAppCommunityDetailF.deleteComment),
      $.on2('click', '.article-detail-comments__btn_like', MShopAppCommunityDetailF.commentLike),
      $delegate('click', '.article-detail-comments__btn_report', MShopAppReportF.openFrame('comment')),

      $delegate('click', '.article-detail-comments__more', MShopAppCommunityDetailF.commentMore),
      $delegate('click', '.article-detail__close', () => {
        MuiF.closeFrame();
      }),
      $delegate('click', '.article-detail-cont__control', async (e) => {
        const article_el = $find('.article-detail', e.delegateTarget);
        const store_id = $attr('data-store_id', article_el);
        const article = $data(article_el);
        const { top, height, left, width } = e.currentTarget.getBoundingClientRect();

        const control_layer_el = $el(
          MShopAppCommunityDetailTmplS.articleControlLayer({
            item: article,
            store_id,
            type: 'article',
            can_edit: article.user_id == box.sel('is_user->id'),
          }),
        );
        $setCss(
          { top: `${top + height}px`, left: `${left - width - 70}px` },
          $find('.detail-control-layer__controls', control_layer_el),
        );

        go(
          control_layer_el,
          $delegate('click', '.detail-control-layer__back, .detail-control-layer__btn_cancel', (e) => {
            $remove(e.delegateTarget);
          }),
          $delegate('click', '.detail-control-layer__btn_del', MShopAppCommunityDetailF.deleteArticle),
          $delegate('click', '.detail-control-layer__btn_modify', (e) => {
            const store_id = $attr('store_id', e.currentTarget);
            const article = $data(e.currentTarget);

            MShopAppStoreCommunityF.openEditor({
              store_id,
              article,
              type: article.type,
              title: T('community::커뮤니티 글 수정하기'),
            });

            $remove(e.delegateTarget);
          }),
        );

        $append(control_layer_el, $qs('#body'));
      }),
      $delegate('click', '.article-detail__photo', (e) => {
        if (!is_mobile || UtilS.isNessApp()) return;

        const article = $data($find('.article-detail', e.delegateTarget));
        if (!article) return;

        let photos = [];

        const photo_types = go(
          ARTICLE_PHOTO_TYPES,
          reject((type) => type == ARTICLE_TYPES.review_photo),
        );

        if (photo_types.includes(article.type)) photos = article.photos;
        if (article.type == ARTICLE_TYPES.review_photo) photos = sel('_.review.files', article);
        if (!photos || !photos.length) return;

        MShopAppCommunityDetailF.openMobilePhoto({ photos });
      }),
      $delegate('click', '.article-comment-write__btn_open_editor', async (e) => {
        if (!is_mobile) return;

        const article_detail_el = $find('.article-detail', e.delegateTarget);
        const article_id = $attr('data-article_id', article_detail_el);
        const store_id = $attr('data-store_id', article_detail_el);
        const is_store = $attr('data-store_id', article_detail_el);
        const article = $data(article_detail_el);

        if (!box.sel('is_user->id')) {
          if (
            await Confirm.open({
              title: ET('mps2::login::need_login::title'),
              message: preventEscape(ET('mps2::login::need_login::message')),
              confirmText: ET('mps2::login::login'),
              cancelText: ET('mps2::signup::close'),
            })
          ) {
            if (MShopUtilF.isApp()) {
              pushLoginStack();
            } else if (box.sel('store_url')) {
              location.href = `${box.sel('store_url')}/login?url=${box.sel(
                'store_url',
              )}/community?a_id=${article_id}`;
            } else {
              location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
            }
          }
          return;
        }

        const data = await MShopAppCommunityDetailF.openCommentEditor({
          store_id,
          article,
          article_detail_el,
        });

        if (!data) return;
        if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');
        if ($find('.article-detail-comments__more', article_detail_el)) {
          await MShopAppCommunityDetailF.appendGetComments(e);
        } else {
          $append(
            $el(
              MShopAppCommunityDetailTmplS.commentItem({
                comment: data.comment,
                is_owner: true,
                is_store,
                has_store:
                  sel('_.store.user_id', article) == data.comment.user_id ? sel('_.store', article) : null,
                is_store_owner: sel('_.store.user_id', article) == box.sel('is_user->id'),
              }),
            ),
            $find('.article-detail-comments__list', e.delegateTarget),
          );

          MShopUtilF.popToastMsg(T('mshop::댓글이 등록되었습니다.'), 'confirm');
          $setText(data.count, $find('.article-detail-comments__count', e.delegateTarget));
          MShopAppCommunityDetailF.androidIncreaseCommentCount(e.delegateTarget);
        }

        setTimeout(() => {
          $setScrollTop($outerHeight(e.delegateTarget) + 10000, $qs('html'));
        }, 100);
      }),

      // 응원하기 수정 삭제 컨트롤러 이벤트
      $delegate('click', '.article-detail-cheer-up__more-icon', (e) => {
        e.originalEvent.stopPropagation();

        $toggleClass('article-detail-cheer-up__more-list--active', $next(e.target));
      }),
      $delegate('click', '.article-detail-cheer-up__more-item--report', (e) => {
        $removeClass('article-detail-cheer-up__more-list--active', $parent(e.target));
        MShopAppReportF.openFrame('article')(e);
      }),
      $delegate('click', '.article-detail-cheer-up__more-item--delete', async (e) => {
        if (
          !(await MShopShareFramePopUpF.confirm({
            title: ET('mps2::article::remove'),
            body: ET('mps2::article::remove_desc'),
            ok: ET('mps2::article::remove_ok'),
            cancel: ET('mps2::article::remove_cancel'),
          }))
        ) {
          return;
        }
        try {
          $.don_loader_start();

          const article_id = e.currentTarget.dataset.article_id;
          const store_id = e.currentTarget.dataset.store_id;

          const { data } = await axios.delete(`/@api/stores/${store_id}/article/delete`, {
            data: { article_id },
          });
          if (!data.result) return MShopUtilF.popToastMsg(T('community::실패'), 'error');
          $.frame.extend_state(void 0, null, window.location.pathname, 'replace');
          MuiF.closeFrame({ reload: true });
        } catch (e) {
          $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
        }
        $.don_loader_end();
      }),
      tap(() => {
        $on('click', function (e) {
          const more_list$ = $qs('.article-detail-review__more-list');

          if (
            more_list$ &&
            $hasClass('article-detail-review__more-list--active', more_list$) &&
            !$hasClass('article-detail-review__more-item', e.target)
          ) {
            if (
              !$hasClass('article-detail-review__more', e.target) &&
              !$qs('.article-detail-review__more').contains(e.target)
            ) {
              $removeClass('article-detail-review__more-list--active', more_list$);
            }
          }
        })(document);
      }),
      // end 응원하기 수정 삭제 컨트롤러 이벤트

      // 리뷰 수정 삭제 컨트롤러 이벤트
      $delegate('click', '.article-detail-review__more-icon', (e) => {
        e.originalEvent.stopPropagation();

        $toggleClass('article-detail-review__more-list--active', $next(e.target));
      }),
      $delegate('click', '.article-detail-review__more-item--report', (e) => {
        $removeClass('article-detail-review__more-list--active', $parent(e.target));
        MShopAppReportF.openFrame('article')(e);
      }),
      $delegate('click', '.article-detail-review__more-item--delete', async (e) => {
        if (
          !(await MShopShareFramePopUpF.confirm({
            title: ET('review::remove'),
            body: ET('review::remove_desc'),
            ok: ET('ness::confirm::ok'),
            cancel: ET('ness::confirm::cancel'),
          }))
        ) {
          return;
        }
        try {
          $.don_loader_start();
          await axios.delete(`/${T.lang}/@api/review`, { data: { id: e.currentTarget.dataset.review_id } });
          location.reload();
        } catch (e) {
          $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
        }
        $.don_loader_end();
      }),
      tap(() => {
        $on('click', function (e) {
          const more_list$ = $qs('.article-detail-review__more-list');

          if (
            more_list$ &&
            $hasClass('article-detail-review__more-list--active', more_list$) &&
            !$hasClass('article-detail-review__more-item', e.target)
          ) {
            if (
              !$hasClass('article-detail-review__more', e.target) &&
              !$qs('.article-detail-review__more').contains(e.target)
            ) {
              $removeClass('article-detail-review__more-list--active', more_list$);
            }
          }
        })(document);
      }),
      // end 리뷰 수정 삭제 컨트롤러 이벤트
      $delegate('click', '.article-detail-info__btn-comment', (e) => {
        if (is_mobile) {
          const btn_open_editor_el = go(
            e.currentTarget,
            $closest('.article-detail'),
            $find('.article-comment-write__btn_open_editor'),
          );
          $trigger('click', btn_open_editor_el);
        } else {
          const comment_write_el = go(
            e.currentTarget,
            $closest('.article-detail'),
            $find('.article-comment-write__textarea'),
          );
          comment_write_el.focus();
        }
      }),
      $delegate('click', '.article-detail-review__right-photo', (e) => {
        const swiper = go(e.currentTarget, $closest('.article-detail'), $find('.swiper-container')).swiper;
        const idx = e.currentTarget.dataset.idx;
        swiper.slideTo(idx, 0);
        go(
          e.currentTarget,
          $closest('.article-detail-review__right-photos'),
          $findAll('.active'),
          each((active_el) => {
            $removeClass('active', active_el);
          }),
        );
        $addClass('active', e.currentTarget);
      }),
      $delegate('click', '.article-detail__tab-button', (e) => {
        const article_detail_el = $find('.article-detail', tab_el);
        const article_detail_tab_el = $find('.article-detail__tab', tab_el);
        const article_detail_photo_list_el = $find('.article-detail-photo-list', tab_el);
        const is_list = e.currentTarget.dataset.is_list === 'true';
        if (is_list === (article_detail_el.dataset.is_list === 'true')) return;

        changeTab({ is_list, article_detail_tab_el, article_detail_el, article_detail_photo_list_el });
      }),

      /* Ness 전용 */
      $delegate('click', '.article-detail-photo-list__photo', async (e) => {
        const article_detail_photo_list_el = $closest('.article-detail-photo-list', e.currentTarget);
        const article_detail_el = $find('.article-detail', tab_el);
        const article_id = e.currentTarget.dataset.article_id;
        const article_detail_url = makeApiUrl('/:lang/@api/articles/:article_id', {
          lang: T.lang,
          article_id,
        });
        const is_ness = UtilS.isNessApp();

        /* 리뷰 상세 내용 변경 */
        const { new_article_detail_el } = await resetDetailEl({
          article_detail_el,
          article_detail_url,
          is_mobile,
          is_ness,
        });

        /* init cursor infinite scroll */
        const photos_api_url = UtilS.makeApiUrl('/:lang/@api/articles/photos/:product_id', {
          lang: ET.lang,
          product_id,
        });
        await initCursorInfiniteScroll({
          container_el: $find('.article-detail__horizontal-photos', tab_el),
          infinite_cursor_api: photos_api_url,
          limit: 6,
          html_item: horizontalPhotoItem,
          infiniteCursorFunc: async function infiniteCursorFunc({
            api_url,
            limit,
            cursor,
            direction,
            is_first,
          }) {
            const {
              data: { results, has_next },
            } = await axios.get(api_url, {
              params: { cursor, direction, limit, is_first },
            });

            return { results, has_next };
          },
          first_cursor: article_id,
        });

        /* tab menu 변경 */
        changeTab({
          is_list: false,
          article_detail_el: new_article_detail_el,
          article_detail_photo_list_el,
        });

        /* 리뷰 상세 swiper init */
        articleDetailSwiperInitNess(tab_el);
      }),

      /* Ness 전용 */
      $delegate('click', '.article-detail__horizontal-photos__photo', async (e) => {
        go($qs('body'), $find('.article-detail__horizontal-photos__photo.active'), $removeClass('active'));
        $addClass('active', e.currentTarget);

        const article_id = e.currentTarget.dataset.article_id;
        const article_detail_url = makeApiUrl('/:lang/@api/articles/:article_id', {
          lang: T.lang,
          article_id,
        });
        const article_right_el = $find('.article-detail__right', tab_el);
        const article_left_el = $find('.article-detail__left', tab_el);

        /* 리뷰 상세 left, right 내용 변경 */
        await resetDetailLeftRight({ article_right_el, article_left_el, article_detail_url, is_mobile });

        /* 리뷰 상세 swiper init */
        articleDetailSwiperInitNess(tab_el);
      }),
      $delegate('click', '.article-detail__btn_del--ness', async (e) => {
        if (
          !(await MShopShareFramePopUpF.confirm({
            title: ET('review::remove'),
            body: ET('review::remove_desc'),
            ok: ET('ness::confirm::ok'),
            cancel: ET('ness::confirm::cancel'),
          }))
        ) {
          return;
        }
        try {
          $.don_loader_start();
          await axios.delete(`/${T.lang}/@api/review`, { data: { id: e.currentTarget.dataset.review_id } });
          location.reload();
        } catch (e) {
          $.alert(sel('response.data.message', e) || '삭제가 불가능합니다.');
        }
        $.don_loader_end();
      }),
    );
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
