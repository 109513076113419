import axios from 'axios';
import {
  $addClass,
  $append,
  $attr,
  $closest,
  $delegate,
  $find,
  $findAll,
  $hasClass,
  $next,
  $prev,
  $qs,
  $qsa,
  $removeAttr,
  $removeClass,
  $setAttr,
  $setCss,
  $setHTML,
  $setOuterHTML,
  $setScrollTop,
  $setText,
  $setVal,
  $text,
  $trigger,
  $val,
} from 'fxdom/es';

import {
  cond,
  constant,
  each,
  equals,
  filter,
  find,
  flatten,
  forEach,
  go,
  html,
  identity,
  ifElse,
  indexBy,
  isEmpty,
  map,
  noop,
  not,
  pick,
  pipe,
  sel,
  selEq,
  sortBy,
  tap,
  when,
  every,
  defaultTo,
  head,
  zip,
  compact,
  isNil,
} from 'fxjs/es';
import { customAlphabet } from 'nanoid';
import { rune } from 'rune-ts';
import UAParser from 'ua-parser-js';
import { ProductBadgeList } from '../../../../../../services/MarppleShop/renderApp/components/cells/ProductBadgeList/ProductBadgeList.ts';
import {
  OptionSetView,
  ProductMultiOptionSelector,
} from '../../../../../../services/MarppleShop/renderApp/components/cells/ProductOptionQuantitySelector/ProductMultiOptionSelector.ts';
import { ButtonClose } from '../../../../../../services/MarppleShop/shared/components/atoms/ButtonClose/ButtonClose.ts';

import { makeProductColorWithThumbnailTodataurlByBpcOrBpId } from '../../../../../Composite/Thumbnail/F/fs.js';
import { addCart, addPbCart } from '../../../../../Creator/Cart/F/fs.js';
import { pushLoginStack } from '../../../../../Creator/Login/F/fs.js';
import { CREATOR_BASE_PRODUCT_COLOR_API_URL } from '../../../../../Creator/Product/S/constant.js';
import { getAvailableColorsByCode } from '../../../../../Creator/Setting/S/fs.js';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { setPfColllaboTypeBpsId } from '../../../../../Maker/F/util.js';
import { isNeedPreview } from '../../../../../Maker/S/categorize.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { creatorPriceOfProduct } from '../../../../../ProductColorPrice/S/fs.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { UtilNumberS } from '../../../../../Util/Number/S/Function/module/UtilNumberS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../../Util/S/Function/util.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppProductItemF } from '../../../Product/Item/F/Function/module/MShopAppProductItemF.js';
import { MShopAppProductPbOptionF } from '../../../Product/PbOption/F/Function/module/MShopAppProductPbOptionF.js';
import { MShopAppProductDetailMobileOptionFormMuiF } from '../../MobileOptionForm/F/Mui/module/MShopAppProductDetailMobileOptionFormMuiF.js';
import { MShopAppProductDetailModalMuiF } from '../../Modal/F/Mui/module/MShopAppProductDetailModalMuiF.js';
import { MShopAppProductDetailModalTmplS } from '../../Modal/S/Tmpl/module/MShopAppProductDetailModalTmplS.js';
import { MULTI_OPTION_WRAP_KLASS } from '../../S/constant.js';
import { isMultiOption } from '../../S/fs.js';
import { messages } from '../../S/message_id.js';
import { makeBaseProductInfoHtml, makeSizeInfoHtml } from '../../S/Tmpl/partials/baseProductInfo.js';
import { makeTShirtSizesHtml } from '../../S/Tmpl/partials/hyundai_n.js';
import { makeProdImageSlideHtml, makeThumbSwiperHtml } from '../../S/Tmpl/partials/productDetailContent.js';
import { makeRadioOptions } from '../../S/Tmpl/partials/productDetailForm.js';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { makeEmptyScreenNavigate } from '../../../../../../services/MarppleShop/shared/app/navigate.ts';
import { Confirm } from '../../../../../../services/MarppleShop/renderApp/components/cells/Confirm/Confirm.ts';
import { preventEscape } from '../../../../../../services/MarppleShop/shared/util/preventEscape.ts';

const getByLang = (key, obj) => sel(key + _en, obj);

// 인풋 이름 비교함수
export const inputNameEquals = (name) => pipe(sel('currentTarget'), $attr('name'), equals(name));

// 스와이퍼 만드는 함수
function swiper_init(selector, options = {}) {
  const $container = $qs(selector);
  const length = sel('dataset.len', $container);
  const $wrapper = $find('.swiper-wrapper', $container);

  if (length == 1) {
    return $setCss({ opacity: 1 }, $wrapper);
  }

  new Swiper(selector, {
    on: {
      init: () => {
        $setCss({ opacity: 1 }, $container);
        $setCss({ opacity: 1 }, $wrapper);
      },
    },
    loopAdditionalSlides: 1,
    // spaceBetween: G.is_pc_size() ? 6 : 4,
    // slidesPerView: 1.4,
    // centeredSlides: true,
    // edgeSwipeDetection: true,
    // edgeSwipeThreshold: 40,
    grabCursor: true,
    loop: true,
    initialSlide: 0,
    preloadImages: false,
    lazy: true,
    navigation: {
      nextEl: `${selector} .swiper-button-next`,
      prevEl: `${selector} .swiper-button-prev`,
    },
    pagination: {
      el: `${selector} .swiper-pagination`,
      dynamicBullets: false,
      clickable: true,
    },
    ...options,
  });
}

// 이미지에 클래스 추가
export function addClassImage($container) {
  go($qsa('img', $container), forEach($addClass('disable-image-save')));
}

// 프리뷰 스와이퍼 실행
export const swiperProductDetailPreview = function (current_index = 0) {
  const thumbs = $qs('.pd-preview__thumbs');
  const initial_slide = Number(current_index);
  const swiper = new Swiper('.pd-preview__swiper', {
    initialSlide: isNaN(initial_slide) || initial_slide < 0 ? 0 : initial_slide,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  });

  go(
    thumbs,
    $delegate('click', '.pd-preview__thumbs-btn', (e) => {
      const target = $closest('.pd-preview__thumbs-btn', e.target);
      const is_active = $hasClass('active', target);

      if (is_active) return;

      const _index = target.dataset.index;
      swiper.slideTo(_index);
    }),
  );

  swiper.on('slideChange', () => {
    const index = swiper.activeIndex;
    const target = $qsa('.pd-preview__thumbs-btn', thumbs)[index];
    const prev_active = $qs('.pd-preview__thumbs-btn.active', thumbs);

    $removeClass('active', prev_active);
    $addClass('active', target);
  });
};

// Ness 프리뷰 스와이퍼 실행
export const swiperProductDetailPreviewNess = function (current_index = 0) {
  const thumbs = $qs('.pd-preview__thumbs');
  if (!thumbs) return;
  const initial_slide = Number(current_index);
  const swiper = new Swiper('.pd-preview__swiper', {
    initialSlide: isNaN(initial_slide) || initial_slide < 0 ? 0 : initial_slide,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  });

  go(
    thumbs,
    $delegate('click', '.pd-preview__thumbs-btn', (e) => {
      const target = $closest('.pd-preview__thumbs-btn', e.target);
      const is_active = $hasClass('active', target);

      if (is_active) return;

      const _index = target.dataset.index;
      const prev_active = $qs('.pd-preview__thumbs-btn.active', thumbs);
      swiper.slideTo(_index);
      $removeClass('active', prev_active);
      $addClass('active', target);
    }),
  );

  swiper.on('slideChange', () => {
    const index = swiper.activeIndex;
    const target = $qsa('.pd-preview__thumbs-btn', thumbs)[index];
    const prev_active = $qs('.pd-preview__thumbs-btn.active', thumbs);

    $removeClass('active', prev_active);
    $addClass('active', target);
  });
};

export const swiperProductDetailPreviewMo = function (current_index = 0) {
  const selector = '.pd-preview.swiper-container';
  const initial_slide = Number(current_index);

  swiper_init(selector, {
    initialSlide: isNaN(initial_slide) || initial_slide < 0 ? 0 : initial_slide,
    loopAdditionalSlides: 0,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 1,
    pagination: {
      el: `${selector} .swiper-pagination`,
    },
  });
};

export const swiperProductDetailPreviewMoNess = function (current_index = 0) {
  const selector = '.pd-preview.swiper-container';
  if (!$qs(selector)) return;
  const initial_slide = Number(current_index);

  swiper_init(selector, {
    initialSlide: isNaN(initial_slide) || initial_slide < 0 ? 0 : initial_slide,
    loopAdditionalSlides: 0,
    loop: false,
    slidesPerView: 1,
    spaceBetween: 1,
    pagination: {
      el: `${selector} .swiper-pagination`,
      type: 'progressbar',
    },
  });
};

// 베이스프로덕트 이미지 스와이퍼 실행
export const initBaseProductSwiper = () => {
  const $contents = $qs('.pd-info-group');
  if (!$contents) return;
  if ($find('.swiper_thumbnails.swiper-container', $contents)) {
    swiper_init('.swiper_thumbnails', {
      loop: false,
      freeMode: true,
      initialSlide: 0,
      slidesPerView: 'auto',
      cssMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
      },
    });
  }

  if ($find('.size_compare_items.swiper-container', $contents)) {
    swiper_init('.size_compare_items', {
      loop: false,
      slidesPerView: 'auto',
      cssMode: true,
      freeMode: true,
      centeredSlides: false,
      initialSlide: 0,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
      },
    });
  }
};

export const initBaseProductSwiperNess = () => {
  const is_mobile = box.sel('is_mobile');
  const $contents = $qs('.pd-contents');
  const $tab_content = $find('.pd-contents__tabs-contents[current-tab="bp"]', $contents);

  if (!$tab_content) return;

  const thumbs_selector = '.swiper_thumbnails';
  const size_selector = '.size_compare_items';

  if ($find('.swiper_thumbnails.swiper-container', $tab_content)) {
    swiper_init(thumbs_selector, {
      centeredSlides: !is_mobile,
      loop: !is_mobile,
      initialSlide: 0,
      slidesPerView: 'auto',
      cssMode: true,
      pagination: is_mobile
        ? {
            el: `${thumbs_selector} .swiper-pagination`,
            type: 'fraction',
          }
        : {
            el: `${thumbs_selector} .swiper-pagination`,
            dynamicBullets: false,
            clickable: true,
          },
    });
  }

  if ($find('.size_compare_items.swiper-container', $tab_content)) {
    swiper_init(size_selector, {
      loop: false,
      slidesPerView: 'auto',
      cssMode: true,
      centeredSlides: false,
      initialSlide: 0,
    });
  }
};

// 탭 변경 이벤트 핸들러
export const handleTabChange = (e) => {
  const $contents = $closest('.pd-contents', e.target);
  const $tabs = $closest('.pd-contents__tabs', e.target);
  const $current_tab = $find('.active', $tabs);
  const key = sel('key', e.target.dataset);
  const next_tab_content = $find(`[tab_name=${key}]`, $contents);
  const is_mobile = MShopUtilF.isMobile();

  if (!next_tab_content) return; // sentry에서 Null 이슈가 보고되어서 예외처리

  window.scrollTo({ top: next_tab_content?.offsetTop - (is_mobile ? 124 : 62), behavior: 'smooth' });
  $removeClass('active', $current_tab);
  $addClass('active', e.target);
};

// Ness탭 변경 이벤트 핸들러
export const handleTabChangeNess = (e) => {
  const $contents = $closest('.pd-contents', e.target);
  const $tab_content = $find('.pd-contents__tabs-contents', $contents);
  const $tabs = $closest('.pd-contents__tabs', e.target);
  const $current_tab = $find('.active', $tabs);
  const key = sel('key', e.target.dataset);

  $removeClass('active', $current_tab);
  $addClass('active', e.target);
  $setAttr({ 'current-tab': key }, $tab_content);

  if (key === 'bp') initBaseProductSwiper();
};

// 청약철회 모달 이벤트 핸들러
export const handlOpenRetractionModal = (crew_id) => {
  const content = MShopAppProductDetailModalTmplS.makeAncRetractionHtml(crew_id);

  MuiF.openFrame(MShopAppProductDetailModalMuiF.frame, (f, p, [t]) => {
    f.title = T(messages.purchaseInfo.btn_guide1);
    f.el_class = f.el_class + ' narrow';
    f.frame_tag = 'anc_retraction_modal';
    t.makeData = () => ({ content });
  });
};

// 청약철회 모달 이벤트 핸들러
export const handlOpenRetractionModalNess = (crew_id) => {
  const content = MShopAppProductDetailModalTmplS.makeNessAncRetractionHtml(crew_id);

  MuiF.openFrame(MShopAppProductDetailModalMuiF.frame, (f, p, [t]) => {
    f.title = T(messages.purchaseInfo.btn_guide1);
    f.el_class = f.el_class + ' narrow';
    f.frame_tag = 'anc_retraction_modal';
    t.makeData = () => ({ content });
  });
};

// 품질보증 모달 이벤트 핸들러
export const handlOpenQualityGuideModal = (crew_id) => {
  const content = MShopAppProductDetailModalTmplS.makeQualityGuideHtml(crew_id);

  MuiF.openFrame(MShopAppProductDetailModalMuiF.frame, (f, p, [t]) => {
    f.title = T(messages.purchaseInfo.btn_guide2);
    f.el_class = f.el_class + ' narrow';
    f.frame_tag = 'quality_guide_modal';
    t.makeData = () => ({ content });
  });
};

// Ness 품질보증 모달 이벤트 핸들러
export const handlOpenQualityGuideModalNess = (crew_id) => {
  const content = MShopAppProductDetailModalTmplS.makeNessQualityGuideHtml(crew_id);

  MuiF.openFrame(MShopAppProductDetailModalMuiF.frame, (f, p, [t]) => {
    f.title = T(messages.purchaseInfo.btn_guide2);
    f.el_class = f.el_class + ' narrow';
    f.frame_tag = 'quality_guide_modal';
    t.makeData = () => ({ content });
  });
};

// TODO @share_ness 사이즈 가이드 모달 이벤트 핸들러
export const handleOpenSizeGuideModal = () => {
  const base_product = box.sel('product_detail->base_product');
  const content = makeSizeInfoHtml(base_product);

  MuiF.openFrame(MShopAppProductDetailModalMuiF.frame, (f, p, [t]) => {
    f.title = T(messages.size_guide);
    f.el_class = f.el_class + ' size pd-bp';
    f.frame_tag = 'size_guide_modal';
    t.makeData = () => ({ content });
    t.appending = () => {
      if (box.sel('is_mobile')) MShopUtilF.bodyFixed$(false);
    };
    t.hiding = () => {
      if (box.sel('is_mobile')) {
        $setScrollTop(0, window);
        MShopUtilF.bodyFixed$(true);
      }
    };
  });
};

// Ness FAQ 토글 이벤트 핸들러
export const handleToggleFaqNess = (e) => {
  const $faq = $closest('.pd-faq__qna', e.target);
  const $ans = $find('.pd-faq__qna-a', $faq);
  const $ans_inner = $find('.pd-faq__qna-a--inner', $faq);
  const rect = $ans_inner.getBoundingClientRect();

  if ($hasClass('active', $faq)) {
    $removeClass('active', $faq);
    $setCss({ height: 0 }, $ans);
  } else {
    $addClass('active', $faq);
    $setCss({ height: `${rect.height}px` }, $ans);
  }
};

// 구매안내 토글 이벤트 핸들러
export const handleTogglePurchaseInfo = (e) => {
  const $item = $closest('.pd-purchase-info__section', e.target);
  const $body = $find('.pd-purchase-info__body', $item);
  const $ans_inner = $find('.pd-purchase-info__body--inner', $item);
  const rect = $ans_inner.getBoundingClientRect();

  if ($hasClass('active', $item)) {
    $removeClass('active', $item);
    $setCss({ height: 0 }, $body);
  } else {
    $addClass('active', $item);
    $setCss({ height: `${rect.height}px` }, $body);
  }
};

// TODO @share_ness 상품 링크 공유
export const handleProductLinkShare = async () => {
  const is_app = MShopUtilF.isApp();
  const { base_product, product_color } = box.sel('product_detail');
  const { ['name' + _en]: prod_name } = product_color;
  const { ['name' + _en]: base_name } = base_product;
  const ua = new UAParser();
  const app_version = (/MarppleShopApp@(\w+.\w+.\w+)/gm.exec(ua.getUA()) || [])[1];

  if (is_app && app_version >= '1.1.0') {
    return MShopUtilF.postMessage({
      share: {
        title: `${T('마플샵')} - ${prod_name || base_name}`,
        url: location.href,
      },
    });
  }

  try {
    navigator.clipboard
      .writeText(location.href)
      .then(() => {
        MShopUtilF.popToastMsg(T('s_about::url_copied2'), 'info', { has_bottom_margin: true });
      })
      .catch(() => {
        MShopUtilF.popToastMsg(T('s_about::url_failed'), 'info', { has_bottom_margin: true });
      });
  } catch (e) {
    MShopUtilF.clipboard(location.href);
    MShopUtilF.popToastMsg(T('s_about::url_copied2'), 'info', { has_bottom_margin: true });
  }
};

// 좋아요 토글 이벤트 핸들러
export const handleToggleLike = async (e) => {
  if (!window.box.sel('is_user->id') || window.box.sel('is_user->type') == 'TEMP')
    return (
      (await Confirm.open({
        title: ET('mps2::login::need_login::title'),
        message: preventEscape(ET('mps2::login::need_login::message')),
        confirmText: ET('mps2::login::login'),
        cancelText: ET('mps2::signup::close'),
      })) &&
      (MShopUtilF.isApp()
        ? MShopUtilF.postMessage(
            makeEmptyScreenNavigate(`/${T.lang}/@/login?url=${location.pathname + location.search}`),
          )
        : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`))
    );

  const $like_btn = $closest('.pd__action-btn.like', e.target);
  if (!$like_btn) {
    return;
  }
  const liked = $hasClass('active', $like_btn);
  const { stores_product_id } = $like_btn.dataset;
  if (liked) $removeClass('active', $like_btn);
  else $addClass('active', $like_btn);

  try {
    await MShopAppProductItemF.updateLike(liked, stores_product_id, true);
  } catch (error) {
    // $toggleClass('active', $like_btn);
    if (!liked) $removeClass('active', $like_btn);
    else $addClass('active', $like_btn);
  }
};

// Ness 좋아요 토글 이벤트 핸들러
export const handleToggleLikeNess = async (e) => {
  if (!window.box.sel('is_user->id') || window.box.sel('is_user->type') == 'TEMP')
    return (
      (await MShopShareFramePopUpF.confirm({
        title: T('community::로그인 후 이용해 주세요. 로그인 하시겠습니까?'),
      })) &&
      (MShopUtilF.isApp()
        ? pushLoginStack()
        : (location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`))
    );

  const $like_btn = $closest('.pd__action-btn.like', e.target);
  if (!$like_btn) {
    return;
  }
  const liked = $hasClass('active', $like_btn);
  const { stores_product_id } = $like_btn.dataset;

  try {
    await MShopAppProductItemF.updateLike(liked, stores_product_id);

    if (liked) $removeClass('active', $like_btn);
    else $addClass('active', $like_btn);
  } catch (error) {
    // $toggleClass('active', $like_btn);
  }
};

// 총 합계 계산
export const computePrice = () => {
  if (UtilS.isNessApp()) {
    return computePriceNess();
  }

  const $pd_form = $qs('.pd-form');
  const $tot_price = $find('.pd-total__price', $pd_form);
  // const $tot_count = $find('.pd-total__count .value', $pd_form);
  const $quantity = $find('[name="quantity"]', $pd_form);
  const [price, quantity, optional_price] = map(Number, [
    $val($qs('[name="price"]')),
    $quantity ? $val($quantity) : 1,
    sel('dataset.price', $find('[name="size"]:checked', $pd_form)),
  ]);
  // const pb_optional_price =
  //   go(
  //     $pd_form,
  //     $findAll('.spo_option'),
  //     map((spo_option_select_el) => {
  //       const option_el = go(
  //         [...spo_option_select_el.options],
  //         find((option) => option.value === spo_option_select_el.value),
  //       );
  //       return PriceS.price(option_el?.dataset.price);
  //     }),
  //     compact,
  //     sum,
  //   ) || 0;
  const pb_optional_price = (() => {
    const spo_option_els = go($pd_form, $findAll('.spo_option'));
    if (!spo_option_els.length) return 0;
    const spo_items = box.sel('product_detail->spo->_->spo_items');
    if (!spo_items.length) return 0;
    const selected_spo_option_value_ids = go(
      spo_option_els,
      map((spo_option_el) => parseInt(spo_option_el.value)),
    );
    const spo_item = spo_items.find((spo_item) =>
      spo_item.spo_option_value_ids.every((spo_option_value_id) =>
        selected_spo_option_value_ids.includes(spo_option_value_id),
      ),
    );
    return PriceS.price(spo_item?.price);
  })();

  const tot_price = (price + (optional_price || 0) + pb_optional_price) * quantity;
  // $setText(`${UtilS.commify(quantity)}`, $tot_count);
  $tot_price && $setText(`${UtilS.commify(tot_price)}`, $tot_price);
  return tot_price;
};

const computePriceNess = () => {
  const $pd_form = $qs('.pd-form');
  const $tot_price = $find('.pd-total__price.dynamic-value', $pd_form);
  const $tot_count = $find('.pd-total__count .dynamic-value', $pd_form);
  const $quantity = $find('[name="quantity"]', $pd_form);
  const [price, quantity, optional_price] = map(Number, [
    $val($qs('[name="price"]')),
    $quantity ? $val($quantity) : 1,
    sel('dataset.price', $find('[name="size"]:checked', $pd_form)),
  ]);
  // const pb_optional_price =
  //   go(
  //     $pd_form,
  //     $findAll('.spo_option'),
  //     map((spo_option_select_el) => {
  //       const option_el = go(
  //         [...spo_option_select_el.options],
  //         find((option) => option.value === spo_option_select_el.value),
  //       );
  //       return PriceS.price(option_el?.dataset.price);
  //     }),
  //     compact,
  //     sum,
  //   ) || 0;
  const pb_optional_price = (() => {
    const spo_option_els = go($pd_form, $findAll('.spo_option'));
    if (!spo_option_els.length) return 0;
    const spo_items = box.sel('product_detail->spo->_->spo_items');
    if (!spo_items.length) return 0;
    const selected_spo_option_value_ids = go(
      spo_option_els,
      map((spo_option_el) => parseInt(spo_option_el.value)),
    );
    const spo_item = spo_items.find((spo_item) =>
      spo_item.spo_option_value_ids.every((spo_option_value_id) =>
        selected_spo_option_value_ids.includes(spo_option_value_id),
      ),
    );
    return PriceS.price(spo_item?.price);
  })();
  const tot_price = (price + (optional_price || 0) + pb_optional_price) * quantity;
  $setText(`${UtilS.commify(quantity)}`, $tot_count);
  $setText(`${UtilS.commify(tot_price)}`, $tot_price);
};

// 사이즈 옵션 렌더함수
export const renderSizeOptions = (size, sizes) => {
  const $target = $closest('.dynamic-html', $qs('[name="size"]'));
  const options = map((item) => {
    const price = getByLang('price', item);
    const label = item._is_not_stock
      ? T(messages.sold_out)
      : PriceS.price(price)
      ? `+${PriceS.price(getByLang('price', item))}`
      : '';
    return {
      label,
      value: item.id,
      name: getByLang('name', item),
      price: getByLang('price', item),
      disabled: item._is_not_stock,
      is_public: item.is_public,
      is_active: size == item.id,
    };
  }, sizes);

  go(makeRadioOptions({ name: 'size', options }), (html_str) => $setHTML(html_str, $target));
};

// 컬러 옵션 렌더함수
export const renderProductColor = (product_color, is_mobile) => {
  box.sel('product_detail').product_color = product_color;

  const selector = '.pd-preview';
  const $preview = $qs(selector);
  const current_index = $preview?.swiper?.activeIndex;
  const product_detail = {
    ...product_color,
    thumbnail_ratio: box.sel('product_detail')?.base_product?.thumbnail_ratio,
    _: { stores_product: box.sel('product_detail')?.stores_product },
  };
  const html = is_mobile
    ? makeProdImageSlideHtml({
        product_detail,
        is_mobile,
      })
    : makeThumbSwiperHtml({ product_detail });

  $setOuterHTML(html, $preview);
  is_mobile ? swiperProductDetailPreviewMo(current_index) : swiperProductDetailPreview(current_index);
};

// 컬러 변경 이벤트 핸들러
export const handleColorChange = async (e) => {
  if (UtilS.isNessApp()) {
    return await handleColorChangeNess(e);
  }

  try {
    const $field = $closest('.pd-field', e.target);
    const $item = $closest('.pd-radio', e.target);
    const is_mobile = box.sel('is_mobile');
    const { product_color, base_product } = box.sel('product_detail');
    const { base_product_colors } = sel('_', base_product);
    const bpc_id = e.target.value;

    const options_el = !is_mobile
      ? $closest('.pd-form__options', e.currentTarget)
      : $closest('.pd-contents__options', e.currentTarget);
    const is_disabled = options_el.classList.contains('disabled');

    if ($hasClass('active', $item)) return;

    $.don_loader_start();

    if (!box.sel('product_detail->cloned_product_color')) {
      box.set('product_detail->cloned_product_color', { ...product_color });
    }

    const new_product_color = await makeProductColorWithThumbnailTodataurlByBpcOrBpId({
      product_color,
      bpc_id,
    });

    // 프리뷰 스와이퍼 리랜더
    renderProductColor(new_product_color, is_mobile);

    const $bpcs = $qs('[name="size"]:checked');
    if ($qs('[name="size"]') && !is_disabled) {
      const sizes = go(base_product_colors, find(selEq('id', Number(bpc_id))), sel('_.base_product_sizes2'));
      const bpcs_id = $bpcs ? go($bpcs, $val, Number) : sizes[0].id;
      renderSizeOptions(bpcs_id, sizes);
    }

    // 기존 선택된 아이템에서 선택 제거
    $field.querySelector('.active')?.classList.remove('active');

    // 신규 컬러에 선책 추가
    $item.classList.add('active');
    $item.querySelector('[name="color"]').checked = true;
    MShopUtilF.hydrationRune(ProductBadgeList, ButtonClose);
    $.don_loader_end();
  } catch (err) {
    $.don_loader_end();
  }
};

const handleColorChangeNess = async (e) => {
  const $field = $closest('.pd-field', e.target);
  const $item = $closest('.pd-radio', e.target);
  const is_mobile = box.sel('is_mobile');
  const { product_color, base_product } = box.sel('product_detail');
  const { base_product_colors } = sel('_', base_product);
  const bpc_id = e.target.value;

  if ($hasClass('active', $item)) return;

  if (!box.sel('product_detail->cloned_product_color')) {
    box.set('product_detail->cloned_product_color', { ...product_color });
  }

  const new_product_color = await makeProductColorWithThumbnailTodataurlByBpcOrBpId({
    product_color,
    bpc_id,
  });

  renderProductColor(new_product_color, is_mobile);

  const $bpcs = $qs('[name="size"]:checked');
  if ($qs('[name="size"]')) {
    const sizes = go(base_product_colors, find(selEq('id', Number(bpc_id))), sel('_.base_product_sizes2'));
    const bpcs_id = $bpcs ? go($bpcs, $val, Number) : sizes[0].id;
    renderSizeOptions(bpcs_id, sizes);
  }

  const $ex_active_btn = $find('.active', $field);
  if ($ex_active_btn) $removeClass('active', $ex_active_btn);
  $addClass('active', $item);
};

// 디바이스 변경 이벤트 핸들러
export const handleDeviceChange = async (e) => {
  if (UtilS.isNessApp()) {
    return handleDeviceChangeNess(e);
  }

  const bp_id = $val(e.target);
  const is_mobile = box.sel('is_mobile');
  const options_el = !is_mobile
    ? $closest('.pd-form__options', e.currentTarget)
    : $closest('.pd-contents__options', e.currentTarget);
  const is_disabled = options_el.classList.contains('disabled');
  const {
    product_color,
    base_product,
    stores_product: {
      _: { store_product_colors },
    },
  } = box.sel('product_detail');

  if (!product_color.product_faces2.value) {
    const {
      data: { product_faces2 },
    } = await axios.get(`/${T.lang}/@api/product_color/p2`, { params: { id: product_color.id } });
    product_color.product_faces2 = product_faces2;
  }

  if (!box.sel('product_detail->cloned_product_color')) {
    box.set('product_detail->cloned_product_color', { ...product_color });
  }

  const product_faces2 = box.sel('product_detail->cloned_product_color->product_faces2');
  const new_product_color = await go(
    makeProductColorWithThumbnailTodataurlByBpcOrBpId({
      product_color: Object.assign(product_color, { product_faces2 }),
      bp_id,
    }),
    pick([
      'base_product_color_id',
      'base_product_id',
      'base_product_size_id',
      'product_faces2',
      'price_info',
      'thumbnails',
    ]),
    (obj) => Object.assign({}, product_color, obj),
  );

  renderProductColor(new_product_color, is_mobile);

  const $color_options = $qs('[name="color"]');
  const $bp_info_tab = $qs('.pd__tab-contents .product_info_detail');
  const $quantity = $qs('[name="quantity"]');

  if ($color_options) {
    const $target = $closest('.dynamic-html', $color_options);
    go(
      $.get(`/@api/base_product_colors?bp_id=${bp_id}`),
      when(
        () => not(isNeedPreview(base_product)),
        (base_product_colors) => getAvailableColorsByCode(base_product_colors, store_product_colors),
      ),
      (colors) => {
        const options = go(
          colors,
          map((item) => ({
            value: item.id,
            label: item['name' + _en],
            color: item.color_code,
            is_public: item.is_public,
            is_active: item.id === new_product_color.base_product_color_id,
          })),
          sortBy((item) => !item.is_active),
        );

        box.set('product_detail->base_product->_->base_product_colors', colors);
        return { name: 'color', type: 'color', options };
      },
      makeRadioOptions,
      (html_str) => {
        $removeClass('folded', $target);
        $setHTML(html_str, $target);
      },
    );
  }
  if ($bp_info_tab) {
    const bp = box.sel('maker->product_color->_->base_product');
    $setHTML(makeBaseProductInfoHtml(bp, is_mobile), $bp_info_tab);
    initBaseProductSwiper();
    G.don_lazy();
  }

  if ($quantity && !is_disabled) {
    $setVal(1, $quantity);
    $trigger('change', $qs('[name="quantity"]'));
  }

  MShopUtilF.hydrationRune(ProductBadgeList, ButtonClose);
};

export const handleDeviceChangeNess = async (e) => {
  const bp_id = $val(e.target);
  const is_mobile = box.sel('is_mobile');
  const {
    product_color,
    base_product,
    stores_product: {
      _: { store_product_colors },
    },
  } = box.sel('product_detail');

  if (!product_color.product_faces2.value) {
    const {
      data: { product_faces2 },
    } = await axios.get(`/${T.lang}/@api/product_color/p2`, { params: { id: product_color.id } });
    product_color.product_faces2 = product_faces2;
  }

  if (!box.sel('product_detail->cloned_product_color')) {
    box.set('product_detail->cloned_product_color', { ...product_color });
  }

  const product_faces2 = box.sel('product_detail->cloned_product_color->product_faces2');
  const new_product_color = await go(
    makeProductColorWithThumbnailTodataurlByBpcOrBpId({
      product_color: Object.assign(product_color, { product_faces2 }),
      bp_id,
    }),
    pick([
      'base_product_color_id',
      'base_product_id',
      'base_product_size_id',
      'product_faces2',
      'price_info',
      'thumbnails',
    ]),
    (obj) => Object.assign({}, product_color, obj),
  );

  renderProductColor(new_product_color, is_mobile);

  const $color_options = $qs('[name="color"]');
  const $bp_info_tab = $qs('.pd__tab-contents .product_info_detail');
  const $quantity = $qs('[name="quantity"]');

  if ($color_options) {
    const $target = $closest('.dynamic-html', $color_options);
    go(
      $.get(`${CREATOR_BASE_PRODUCT_COLOR_API_URL}?bp_id=${bp_id}`),
      when(
        () => not(isNeedPreview(base_product)),
        (base_product_colors) => getAvailableColorsByCode(base_product_colors, store_product_colors),
      ),
      (colors) => {
        const options = go(
          colors,
          map((item) => ({
            value: item.id,
            label: item['name' + _en],
            color: item.color_code,
            is_public: item.is_public,
            is_active: item.id === new_product_color.base_product_color_id,
          })),
          sortBy((item) => !item.is_active),
        );

        box.set('product_detail->base_product->_->base_product_colors', colors);
        return { name: 'color', type: 'color', options };
      },
      makeRadioOptions,
      (html_str) => {
        $removeClass('folded', $target);
        $setHTML(html_str, $target);
      },
    );
  }
  if ($bp_info_tab) {
    const bp = box.sel('maker->product_color->_->base_product');
    $setHTML(makeBaseProductInfoHtml(bp, is_mobile), $bp_info_tab);
    initBaseProductSwiper();
    G.don_lazy();
  }
  if ($quantity) {
    $setVal(1, $quantity);
    $trigger('change', $qs('[name="quantity"]'));
  }
};

// 사이즈 변경 이벤트 핸들러
export const handleSizeChange = (e) => {
  computePrice(e);
};

export const handleChangeQuantity = (e) => {
  if (UtilS.isNessApp()) {
    return handleChangeQuantityNess(e);
  }

  const $container = $closest('.pd-counter', e.target);
  const $btn = $closest('.pd-counter__btn', e.target);
  const $btn_plus = $find('.up', $container);
  const $btn_minus = $find('.down', $container);
  const $input = $find('.pd-counter__current', $container);
  const is_up = $hasClass('up', $btn);
  const { value = '1' } = $input;
  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');
  if (is_up) {
    $removeAttr('disabled', $btn_minus);
    const nextValue = parseInt(value, 10) + 1;
    if (possible_quantity == null || nextValue <= possible_quantity) {
      $input.value = nextValue;
      if (nextValue === possible_quantity) {
        $setAttr({ disabled: '' }, $btn_plus);
      }
    }
  } else {
    $removeAttr('disabled', $btn_plus);
    const nextValue = parseInt(value, 10) - 1;
    if (nextValue <= 1) {
      $setAttr({ disabled: '' }, $btn_minus);
      $input.value = 1;
    } else {
      $input.value = nextValue;
    }
  }

  $trigger('change', $qs('[name="quantity"]'));
};

const _handleChangeQuantityNessEvent = (e) => {
  const $container = $closest('.pd-counter', e.target);
  const $btn = $closest('.pd-counter__btn', e.target);
  const $input = $find('.pd-counter__current', $container);
  const is_up = $hasClass('up', $btn);
  const { value = '1' } = $input;
  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');
  if (is_up) {
    const nextValue = parseInt(value, 10) + 1;
    if (possible_quantity == null || nextValue <= possible_quantity) {
      $input.value = nextValue;
    }
  } else {
    const nextValue = parseInt(value, 10) - 1;
    $input.value = nextValue < 1 ? 1 : nextValue;
  }
};

export const handleChangeQuantityNess = (e) => {
  _handleChangeQuantityNessEvent(e);

  $trigger('change', $qs('[name="quantity"]'));
};

export const handleChangeQuantityNessCustom = (e) => {
  _handleChangeQuantityNessEvent(e);

  $trigger('change', $find('[name="quantity"]', e.delegateTarget));
};

export const handlePodMultiOption = (e) => {
  const {
    base_product: {
      _: { base_product_colors },
    },
    base_product,
  } = box.sel('product_detail');

  /* 색상선택 시 내 스마트폰에 적용하기인 경우 옵션 추가하지 않는다. */
  if ($closest('.color', e.currentTarget) && isNeedPreview(base_product)) {
    return;
  }

  const $color = $qs('[name="color"]:checked');
  const selected_color_id = $color ? go($color, $val, Number) : null;
  const selected_bpc = go(
    base_product_colors,
    find(selEq('id', selected_color_id)),
    defaultTo(head(base_product_colors)),
  );

  const disabled = go(
    e.currentTarget,
    $closest('.pd-form__options'),
    ifElse(identity, $hasClass('disabled'), () => void 0),
  );

  if (
    !isMultiOption(box.sel('product_detail'), {
      devices: box.sel('devices'),
      hyundai_n_products: box.sel('hyundai_n_products'),
      selected_bpc,
      disabled,
    })
  ) {
    return;
  }

  const add_cart_args = getAddCartArgs();
  const price = computePrice();
  const parsed_option = compact([
    add_cart_args.device_name,
    add_cart_args.color_name,
    add_cart_args.size_name,
  ]).map((option_name) => {
    return {
      value_name: option_name,
    };
  });

  const product_option = {
    id: Number(
      add_cart_args.product_color.id + add_cart_args.base_product_size_id ||
        0 + add_cart_args.product_option_id ||
        0 + customAlphabet('0123456789', 3)(),
    ),
    price,
    quantity: add_cart_args.quantity,
    option_values: parsed_option,
  };

  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');

  go(
    $qs(`.${ProductMultiOptionSelector}`),
    ifElse(
      identity,
      async (el) => {
        const result = rune
          .getView(el, ProductMultiOptionSelector)
          .addProductOptionSet({ ...product_option, extra_info: add_cart_args });

        if (!result.is_success && result.type === ProductMultiOptionSelector.getConstant().DUPLICATION) {
          return await MShopShareFramePopUpF.alert({
            title: ET('mps2::product_detail::duplicated_option'),
            body: ET('mps2::product_detail::duplicated_option_desc'),
          });
        }

        if (!result.is_success && result.type === ProductMultiOptionSelector.getConstant().QUANTITY) {
          return await MShopShareFramePopUpF.alert(
            possible_quantity
              ? {
                  title: ET('mps2::product_detail::over_quantity'),
                  body: ET('mps2::product_detail::over_quantity_desc'),
                }
              : {
                  title: ET('product_detail::already_possible_quantity_title'),
                  body: ET('product_detail::already_possible_quantity'),
                },
          );
        }

        setMultiOptionVisibility(true);
      },
      async () => {
        if (possible_quantity === 0) {
          return await MShopShareFramePopUpF.alert({
            title: ET('product_detail::already_possible_quantity_title'),
            body: ET('product_detail::already_possible_quantity'),
          });
        }

        go(
          $qs(`.${MULTI_OPTION_WRAP_KLASS}`),
          $append(
            new ProductMultiOptionSelector(
              { product_options: UtilS.wrapArr({ ...product_option, extra_info: add_cart_args }) },
              {
                is_mobile: MShopUtilF.isMobile(),
                max_total_quantity: possible_quantity,
              },
            ).render(),
          ),
        );

        setMultiOptionVisibility(true);
      },
    ),
  );
};

// 폼 요소 체인지 이벤트 핸들러
export const handleInputChange = cond(
  [inputNameEquals('color'), pipe(tap(handleColorChange), tap(handlePodMultiOption))],
  [inputNameEquals('size'), pipe(tap(handleSizeChange), tap(handlePodMultiOption))],
  [inputNameEquals('quantity'), computePrice],
  [
    inputNameEquals('device'),
    pipe(
      tap(() => $.don_loader_start()),
      tap(handleDeviceChange),
      tap(handlePodMultiOption),
      tap(() => $.don_loader_end()),
    ),
  ],
  // [inputNameEquals('product_option_id'), handlePBOptionChange],
  [constant(true), noop],
);

// 폼 요소 체인지 이벤트 핸들러
export const handleInputChangeNess = cond(
  [
    inputNameEquals('color'),
    pipe(
      tap(() => $.don_loader_start()),
      handleColorChange,
      tap(() => $.don_loader_end()),
    ),
  ],
  [inputNameEquals('size'), handleSizeChange],
  [inputNameEquals('quantity'), computePrice],
  [
    inputNameEquals('device'),
    pipe(
      tap(() => $.don_loader_start()),
      handleDeviceChange,
      tap(() => $.don_loader_end()),
    ),
  ],
  // [inputNameEquals('product_option_id'), handlePBOptionChange],
  [constant(true), noop],
);

// TODO @share_ness 현대 패키지 상품 중 티셔츠 변경 이벤트 핸들러
export const handleInputChangeTShirts = (e) => {
  const t_shirts_sizes$ = $qs('#t_shirts_sizes');
  $setHTML(
    makeTShirtSizesHtml(
      window.box.sel(
        `product_detail->hyundai_n_products->t_shirt_product->[1]->(#${parseInt(e.currentTarget.value)})`,
      ),
      parseInt($find('select[name="t_size"]', t_shirts_sizes$).value),
    ),
    t_shirts_sizes$,
  );
};

// TODO @share_ness ness에서 안쓰이면 ness 부분에서 지워주자. 현대 패키지 장바구니 이벤트 핸들러
export const handlePackageFormSubmit = async (e) => {
  if (e.currentTarget.hasAttribute('disabled')) return;

  const t_shirt_product$ = $qs('#t_shirt_product');
  const promotional_products$ = $qs('#promotional_products');
  const hyundai_n_products = window.box.sel('product_detail->hyundai_n_products');
  const {
    t_shirt_product: [t_title, t_shirts],
    promotional_products: pps,
  } = hyundai_n_products;

  const t_shirt = [
    t_title,
    parseInt(sel('value', $find('select[name="t_shirts"]', t_shirt_product$))),
    parseInt(sel('value', $find('select[name="t_size"]', t_shirt_product$))),
  ];

  const promotional_products = map(
    (select$) => [$attr('name', select$), parseInt(sel('value', select$))],
    $findAll('select', promotional_products$),
  );

  const makeName = (arr) => (option$) =>
    sel('name' + G._en, indexBy(sel('id'), arr)[parseInt($val(option$))]);

  const option_to_string = `${go(
    t_shirt_product$,
    $find('select[name="t_shirts"]'),
    makeName(t_shirts),
  )} ${go(t_shirt_product$, $find('select[name="t_size"] option:checked'), $text)} / ${go(
    promotional_products$,
    $findAll('select'),
    map(makeName(flatten(map(sel('1'), pps)))),
  ).join(' / ')}`;

  if (
    await $.confirm(html`
      <div>${option_to_string}</div>
      <div>${T(messages.check_cart_in)}</div>
    `)
  ) {
    handleFormSubmit(e, { t_shirt, promotional_products, option_to_string });
  }
};

const getAddCartArgs = (is_cart) => {
  const {
    original_product_id,
    product_color,
    stores_product: { goods_type_id },
    hyundai_n_products,
  } = box.sel('product_detail');

  const form = document.cartForm;

  const base_product_size_id =
    go(form?.size, find(sel('checked')), sel('value')) || product_color.base_product_size_id;
  const product_option_id = form?.product_option_id?.value;
  const quantity = form?.quantity?.value || 1;
  const color_name =
    (form?.color &&
      go(
        form?.color,
        (color) => (color.length ? find(sel('checked'), color) : color),
        $next,
        $attr('title'),
      )) ||
    '';
  const size_name =
    (form?.size &&
      go(
        form?.size,
        (size) => (size.length ? find(sel('checked'), size) : size),
        $next,
        $find('.pd-radio__face--value'),
        $text,
      )) ||
    '';
  const device_name = form.device?.selectedOptions[0].textContent;

  return {
    product_color,
    base_product_size_id,
    product_option_id,
    original_product_id,
    quantity,
    goods_type_id,
    hyundai_n_products,
    is_cart,
    device_name,
    color_name,
    size_name,
  };
};

export const getDataFromProductMultiOptionSelector = (is_cart) => {
  const productMultiOptionSelectorView = rune.getView(
    $qs(`.${ProductMultiOptionSelector}`),
    ProductMultiOptionSelector,
  );

  if (!productMultiOptionSelectorView) {
    throw new Error('productMultiOptionSelectorView가 존재하지 않습니다.');
  }

  return go(
    zip(
      productMultiOptionSelectorView.getProductOptions(),
      productMultiOptionSelectorView.getAllProductOptionExtraInfos(),
    ),
    map(([view_option, extra_info]) => {
      return { ...extra_info, quantity: view_option.quantity, type: is_cart ? 'cart' : 'direct' };
    }),
  );
};

// 모바일 장바구니 폼 모달 오픈 함수
const mobileCartFormOpen = () => {
  const { is_mobile, product_detail } = box();
  const goods_type_id = product_detail.stores_product?.goods_type_id;

  MuiF.openFrame(MShopAppProductDetailMobileOptionFormMuiF.frame, async (f, p, [t]) => {
    f.title = ET('product_detail::option_modal_title');
    f.frame_tag = 'pd-form-modal';
    f.always_remove = !!GoodsTypeS.isPod(goods_type_id);
    t.makeData = () => ({ is_mobile, product_detail });
    if (is_mobile) {
      $addClass('mobile-cart-form-open', document.body);
      await MuiF.makeFrameHeight(f, t);
    }
  });
};

// 장바구니 폼 섭밋 이벤트 핸들러
export const handleFormSubmit = async (e, hyundai_n_products) => {
  if (e.currentTarget.hasAttribute('disabled')) return;

  const { action } = e.currentTarget.dataset;
  const {
    original_product_id,
    product_color,
    base_product,
    base_product: {
      _: { base_product_colors },
    },
    stores_product: { goods_type_id },
    spo,
  } = box.sel('product_detail');

  const submit_area = $closest('.pd-submit', e.currentTarget);

  const form = document.cartForm;
  const is_mobile = MShopUtilF.isMobile();
  if (is_mobile && !form && !hyundai_n_products) {
    submit_area.classList.add('open');
    return mobileCartFormOpen();
  }

  const $color = $qs('[name="color"]:checked');
  const selected_color_id = $color ? go($color, $val, Number) : null;
  const selected_bpc = go(
    base_product_colors,
    find(selEq('id', selected_color_id)),
    defaultTo(head(base_product_colors)),
  );

  if (
    isMultiOption(box.sel('product_detail'), {
      devices: box.sel('devices'),
      hyundai_n_products: box.sel('hyundai_n_products'),
      selected_bpc,
    }) &&
    !$qs(`.${OptionSetView}`)
  ) {
    await MShopShareFramePopUpF.alert({
      title: ET('mps2::product_detail::no_select_option'),
      body: ET('mps2::product_detail::no_select_option_desc'),
    });
    return;
  }

  if (!goods_type_id) {
    await MShopShareFramePopUpF.alert({
      title: T('product_detail::The product information is invalid.<br>Will refresh.'),
    });
    return location.reload();
  }

  /* 장바구니 / 바로구매 */
  const is_cart = action === 'cart';

  /* ness */
  if (UtilS.isNessApp() && !is_cart && (!box.sel('is_user->type') || box.sel('is_user->type') == 'TEMP')) {
    if (
      await MShopShareFramePopUpF.confirm({
        title: ET('ness::login::require::title'),
        body: ET('ness::login::require::body'),
        cancel: T('translation::Cancel'),
        ok: T('translation::Sign in'),
      })
    ) {
      window.location.href = `/${T.lang}/login?url=${window.location.href}`;
      return;
    }
    return;
  }

  go(
    goods_type_id,
    async (goods_type_id) => {
      if (GoodsTypeS.isMps(goods_type_id)) {
        if (!product_color.product_faces2.value) {
          const {
            data: { product_faces2, base_product_color_id, base_product_id },
          } = await axios.get(`/${T.lang}/@api/product_color/p2`, { params: { id: product_color.id } });
          product_color.product_faces2 = product_faces2;
          product_color.product_faces2.set_info = {
            base_product_color_id,
            base_product_id,
          };
        }

        if (base_product_colors.length === 1 && base_product.is_composite_publish) {
          const temp_thumb_url = go(
            product_color.thumbnails.value,
            find((thumb) => thumb.is_thumb),
            sel('url'),
          );
          go(
            product_color.product_faces2.value,
            each((pf) => {
              pf.temp_thumb_url = temp_thumb_url;
            }),
          );
        }

        const base_product_size_id =
          go(form?.size, find(sel('checked')), sel('value')) || product_color.base_product_size_id;
        const product_option_id = form?.product_option_id?.value;
        const quantity = form?.quantity?.value || 1;

        const disabled_sizes = go(
          base_product_colors,
          find(selEq('id', product_color.base_product_color_id)),
          sel('_.base_product_sizes2'),
          filter(sel('_is_not_stock')),
          map(sel('id')),
        );

        if (disabled_sizes.includes(Number(base_product_size_id))) {
          return $.alert(T(messages.sold_out_size));
        }

        setPfColllaboTypeBpsId(product_color, product_color.collabo_type, product_color.base_product_size_id);

        if (!product_color.product_faces2.value) {
          ConfigSentryF.error(new Error('handleFormSubmit - No product_faces2'), {
            location: window.location.href,
          });
        }

        if (product_color.product_faces2.set_info) {
          if (
            product_color.base_product_color_id !=
              product_color.product_faces2.set_info?.base_product_color_id ||
            product_color.base_product_id != product_color.product_faces2.set_info?.base_product_id
          ) {
            ConfigSentryF.error(new Error('handleFormSubmit - diff base_product_id, base_product_color_id'), {
              location: window.location.href,
            });
          }
        }

        const args = {
          product_color,
          base_product_size_id,
          product_option_id,
          original_product_id,
          quantity,
          goods_type_id,
          hyundai_n_products,
          is_cart,
        };

        return addCart(args);
      } else {
        return addPbCart({
          form,
          product_color,
          spo,
          is_cart,
        });
      }
    },
    (ups) => {
      if (!is_cart && every((up) => up?.up_id, UtilS.wrapArr(ups))) {
        const uri = `/${T.lang}/@/checkout?direct=true&up_ids=${JSON.stringify(
          UtilS.wrapArr(ups).map(({ id }) => id),
        )}`;
        if (MShopUtilF.isCreatorApp()) {
          MShopUtilF.postMessage({
            navigate: {
              sel: 'PaymentStackNavigation.OrderScreen',
              params: {
                title: T('translation::Proceed To Checkout'),
                uri,
              },
            },
          });
        } else {
          location.href = uri;
        }
      }
      $.don_loader_end();
    },
  );
};

// 아직 사용하지 않음
// export const handlePBOptionChange = (e) => {
//   const $view = $find('.selector-view-name', e.target.parentNode);
//   go(e.target, $find(`option[value="${e.target.value}"]`), $text, (text) => $setText(text, $view));
//   computePrice();
// };

const replaceHeart = async () => {
  try {
    const $like_btn = $qs('.pd__action-btn.like');
    if (!$like_btn) return;
    const [activated_likes] = await MShopAppProductItemF.getMyHeartList(true, {
      stores_product_id: $like_btn.dataset.stores_product_id,
    });
    if (!activated_likes) return;

    if (!activated_likes[0]) {
      $removeClass('active', $like_btn);
    } else {
      $addClass('active', $like_btn);
    }
  } catch (e) {
    e.name = 'MShopAppProductDetailMuiF.replaceHeart';
    ConfigSentryF.error(e);
    throw e;
  }
};

const appListener = async function (e) {
  try {
    const { is_focused } = JSON.parse(e.data);
    if (is_focused) {
      resetMaxPurchasePerUser();
      await replaceHeart();
    }
  } catch (err) {}
};

function checkPlaying($el) {
  return !go($el, $closest('#product_detail'), $find('.preview__play-button'), $hasClass('pause'));
}

const resetMaxPurchasePerUser = () => {
  if (!isNil(box.sel('product_detail->stores_product->max_purchase_per_user'))) {
    window.location.reload();
  }
};

// TODO @share_ness
export const resetLikeBackForward = () => {
  if (!MShopUtilF.isApp()) return UtilF.initBackForward(replaceHeart);
  window.addEventListener('message', appListener);
  document.addEventListener('message', appListener);
};

export const $togglePlayerButton = (e) => {
  const $el = e.currentTarget || e;
  const $audio = go($el, $closest('#product_detail'), $find('audio'));
  if (!checkPlaying($el)) {
    $audio.play();
    go($el, $closest('#product_detail'), $find('.preview__play-button'), $removeClass('pause'));
  } else {
    $audio.pause();
    go($el, $closest('#product_detail'), $find('.preview__play-button'), $addClass('pause'));
  }
};

const $playPlayerButton = (e) => {
  const $el = e.currentTarget || e;
  const $audio = go($el, $closest('#product_detail'), $find('audio'));
  go($el, $closest('#product_detail'), $find('.preview__play-button'), $removeClass('pause'));
  $audio.play();
};

const $pausePlayerButton = (e) => {
  const $el = e.currentTarget || e;
  const $audio = go($el, $closest('#product_detail'), $find('audio'));
  go($el, $closest('#product_detail'), $find('.preview__play-button'), $addClass('pause'));
  $audio.pause();
};

export const handlePlayerButton = (e) => {
  $togglePlayerButton(e.currentTarget);
};
export const convertAudioTime = (seconds) => {
  const min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);
  if (sec < 10) {
    sec = '0' + String(sec);
  }
  return min + ':' + sec;
};

export const drawCurrentTime = ($audio) => {
  go(
    $audio,
    $closest('#product_detail'),
    $find('.current-time'),
    $setText(convertAudioTime($audio.currentTime)),
  );
};

export const drawProgressBar = ($audio) => {
  go(
    $audio,
    $closest('#product_detail'),
    $find('.preview__progress'),
    $setCss({ width: `${($audio.currentTime / $audio.duration) * 100}%` }),
  );
};
export const handlePlayerTime = (e) => {
  const $audio = e.currentTarget;
  drawCurrentTime($audio);
  drawProgressBar($audio);
  if ($audio.currentTime === $audio.duration) {
    $togglePlayerButton(e.currentTarget);
  }
};

const progressHandler = (e) => {
  const $audio = go(e.currentTarget, $closest('#product_detail'), $find('audio'));
  const full_width = e.currentTarget.clientWidth;
  const rect = e.currentTarget.getBoundingClientRect();
  const clientX = e.clientX || e.touches[0].clientX;
  const current_location = clientX - rect.left;
  const ratio = current_location / full_width;
  $audio.currentTime = ratio * $audio.duration;
};
export const handleMouseDownProgress = (e) => {
  progressHandler(e);
  const $audio = go(e.currentTarget, $closest('#product_detail'), $find('audio'));
  const $progress_container = e.currentTarget;
  // $pausePlayerButton($progress_container);
  $audio.muted = true;
  $progress_container.addEventListener('mousemove', progressHandler);
  $progress_container.onmouseup = () => {
    // $playPlayerButton($progress_container);
    $audio.muted = false;
    $progress_container.removeEventListener('mousemove', progressHandler);
    $progress_container.onmouseup = null;
  };
  $progress_container.ondragstart = () => false;
};

export const handleTouchDownProgress = (e) => {
  e.originalEvent.preventDefault();
  progressHandler(e);
  const $audio = go(e.currentTarget, $closest('#product_detail'), $find('audio'));
  const $progress_container = e.currentTarget;
  // $pausePlayerButton($progress_container);
  $audio.muted = true;
  $progress_container.addEventListener('touchmove', progressHandler);
  $progress_container.ontouchend = () => {
    // $playPlayerButton($progress_container);
    $audio.muted = false;
    $progress_container.removeEventListener('touchmove', progressHandler);
    $progress_container.ontouchend = null;
  };
  $progress_container.ondragstart = () => false;
};

export const handleMouseMoveProgress = (e) => {
  progressHandler(e);
};

export const handleToggleInfoSection = (e) => {
  const section = $closest('.pd-info-group', e.target);
  const is_open = section.classList.contains('open');
  section.classList.toggle('open');
  e.target.innerHTML = is_open ? ET('product_detail::unfold_btn') : ET('product_detail::fold_btn');
};

export const handleClickReviewsMore = (e) => {
  const $page = $qs('#product_detail');
  const $tabs = $find('.pd-contents__tabs', $page);
  const $current_tab = $find('.active', $tabs);
  const key = 'review';
  const next_tab = $find(`[data-key=${key}]`, $page);
  const next_tab_content = $find(`[tab_name=${key}]`, $page);
  const rect = next_tab_content.getBoundingClientRect();
  window.scrollTo({ top: rect.top, behavior: 'smooth' });
  $removeClass('active', $current_tab);
  $addClass('active', next_tab);
};

export const handleClickLikeReview = async (e) => {
  try {
    const is_like = $attr('is_like', e.currentTarget) == 'true';
    const article_detail_el = $closest('.pd-review-item', e.currentTarget);
    const article_id = $attr('article_id', article_detail_el);
    const store_id = $attr('store_id', article_detail_el);

    if (!box.sel('is_user->id')) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else if (box.sel('store_url')) {
          location.href = `${box.sel('store_url')}/login?url=${box.sel(
            'store_url',
          )}/community?a_id=${article_id}`;
        } else {
          location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
        }
      }
      return;
    }

    const post_url = makeApiUrl(`/@api/stores/:store_id/like`, { store_id });

    return go(
      $.post(post_url, {
        is_like: !is_like,
        attached_id: article_id,
        attached_type: 'articles',
      }),
      (data) => {
        if (!data.result)
          return MShopUtilF.popToastMsg(T('community::실패'), 'error', { has_bottom_margin: true });
        if (data.like.is_hidden) {
          $setAttr({ is_like: false }, e.currentTarget);
        } else {
          $setAttr({ is_like: true }, e.currentTarget);
        }
        $setAttr({ is_like: !data.like.is_hidden }, e.currentTarget);
        $setText(data.count, e.currentTarget);
      },
    );
  } catch (err) {}
};

export const handleInputTextArea = (e, init) => {
  const target = e.currentTarget;
  const max = Math.max(target.scrollHeight, target.offsetHeight);
  if (init) {
    target.style.minHeight = `${max}px`;
  }
  target.style.height = target.value ? `${max}px` : 'auto';
};

export const initScrollSpy = (is_mobile) => {
  // window.addEventListener(
  //   'mousewheel',
  //   debounce((e) => {
  //     console.log(window.scrollY);
  //   }, 150),
  // );
};

export const initTextarea = (tab_el) => {
  const form = $qs('.pd-form', tab_el);
  if (!form) return;
  const textareas = $qsa('textarea', form) || [];
  textareas.forEach((ta) => handleInputTextArea({ currentTarget: ta }, true));
};

const buttonCloseHelper = (() => {
  let clicked = false;

  return {
    isClicked() {
      return clicked;
    },
    setClicked(is_clicked) {
      clicked = is_clicked;
    },
  };
})();

const resetSpoOptions = (e) => {
  /* select tag 초기화 */
  go(
    e.delegateTarget,
    $findAll('select.spo_option'),
    each((el) => {
      el.selectedIndex = 0;
      el.dataset.is_default_selected = 'true';
    }),
  );
};

export const setMultiOptionVisibility = (is_visible) => {
  $setCss({ display: is_visible ? 'block' : 'none' }, $qs(`.${MULTI_OPTION_WRAP_KLASS}`));
};

const addSpoMultiOption = (product_option) => {
  const is_separate_shipping = box.sel('product_detail->stores_delivery_company->is_separate_shipping');
  const possible_quantity = box.sel('product_detail->stores_product->possible_quantity');

  go(
    $qs(`.${ProductMultiOptionSelector}`),
    ifElse(
      identity,
      async (el) => {
        /* DOM에 ProductMultiOptionSelectorView가 존재하면 옵션세트 추가 메소드만 실행 */

        /* 개별 배송(is_separate_shipping = true)인 경우 수량 조절이 불가능 해야한다. */
        const result = rune
          .getView(el, ProductMultiOptionSelector)
          .addProductOptionSet({ ...product_option, is_disabled: is_separate_shipping });

        if (!result.is_success && result.type === ProductMultiOptionSelector.getConstant().DUPLICATION) {
          return await MShopShareFramePopUpF.alert({
            title: ET('mps2::product_detail::duplicated_option'),
            body: is_separate_shipping
              ? ET('mps2::product_detail::duplicated_option_desc2')
              : ET('mps2::product_detail::duplicated_option_desc'),
          });
        }

        if (!result.is_success && result.type === ProductMultiOptionSelector.getConstant().QUANTITY) {
          return await MShopShareFramePopUpF.alert(
            possible_quantity
              ? {
                  title: ET('mps2::product_detail::over_quantity'),
                  body: ET('mps2::product_detail::over_quantity_desc'),
                }
              : {
                  title: ET('product_detail::already_possible_quantity_title'),
                  body: ET('product_detail::already_possible_quantity'),
                },
          );
        }

        setMultiOptionVisibility(true);
      },
      async () => {
        /* DOM에 ProductMultiOptionSelectorView가 존재하지 않으면 View생성하고 append */
        /* 개별 배송(is_separate_shipping = true)인 경우 수량 조절이 불가능 해야한다. */
        if (possible_quantity === 0) {
          return await MShopShareFramePopUpF.alert({
            title: ET('product_detail::already_possible_quantity_title'),
            body: ET('product_detail::already_possible_quantity'),
          });
        }

        go(
          $qs(`.${MULTI_OPTION_WRAP_KLASS}`),
          $append(
            new ProductMultiOptionSelector(
              { product_options: UtilS.wrapArr({ ...product_option, is_disabled: is_separate_shipping }) },
              {
                is_mobile: MShopUtilF.isMobile(),
                max_total_quantity: possible_quantity,
              },
            ).render(),
          ),
        );

        setMultiOptionVisibility(true);
      },
    ),
  );
};

const controlButtonClose = (button_close_view_el, is_view = false) => {
  const buttonCloseView = rune.getView(button_close_view_el, ButtonClose);
  if (!button_close_view_el || !buttonCloseView) return;

  if (is_view) {
    buttonCloseView.show();
  } else {
    buttonCloseView.hide();
  }
};

export const handleSpoMultiOption = async (e) => {
  if (buttonCloseHelper.isClicked()) {
    return;
  }

  const spo_option_els = go(e.currentTarget, $closest('.pb-options'), $findAll('select.spo_option'));
  const spo_text_option_els = go(e.currentTarget, $closest('.pb-options'), $findAll('input.spo_text_option'));

  const is_all_spo_option_selected = go(
    spo_option_els,
    filter((el) => el.selectedIndex === 0), // selected default만 추출
    isEmpty,
  );

  const is_all_text_spo_option_filled = go(
    spo_text_option_els,
    filter((el) => $val(el) === ''), // 입력되지 않은 input만 추출
    isEmpty,
  );

  const can_add_option = is_all_spo_option_selected && is_all_text_spo_option_filled;

  if (can_add_option) {
    const form = document.cartForm;
    const product_detail = box.sel('product_detail');
    const { product_color, spo } = product_detail;

    const pb_up_materials = await MShopAppProductPbOptionF.makePbUpMaterial({
      product_id: product_color.id,
      profit: product_color[`profit${_en}`],
      spo_items: spo._.spo_items,
      spo_option_els: go(form, $findAll('.spo_option')),
      spo_text_option_els: go(form, $findAll('.spo_text_option')),
      spo_custom_option_els: go(form, $findAll('.spo_custom_option')),
    });

    const price = UtilNumberS.addDecimal(
      creatorPriceOfProduct(product_color),
      Number(pb_up_materials.option_price),
    );

    /* option을 ProductMultiOptionSelector에 들어갈 수 있도록 파싱  */
    const parsed_option = go(
      form,
      $findAll('.spo_option'),
      map((el) => {
        return {
          value_name: $text(el.options[el.selectedIndex]).trim(),
        };
      }),
    );

    const parsed_text_option = go(
      pb_up_materials.spo_text_option_values,
      map(({ spo_text_option_id, value }) => {
        return {
          value_type: 'text',
          value_id: spo_text_option_id,
          value_name: value,
        };
      }),
    );

    const product_option = {
      // text_option만 다를 경우 spo_id는 고유하지 않아서 nanoId도 추가
      id: Number(pb_up_materials.spo_item.id + customAlphabet('0123456789', 3)()),
      price,
      quantity: pb_up_materials.quantity,
      option_values: [...parsed_text_option, ...parsed_option],
    };

    addSpoMultiOption({ ...product_option, extra_info: pb_up_materials });
    resetSpoOptions(e);
  }
};

export const handleMultiOptionCloseButton = (e) => {
  const productMultiOptionSelectorView = rune.getView(e.currentTarget, ProductMultiOptionSelector);

  /* 모든 옵션을 삭제한 상황인 경우 MULTI_OPTION_WRAP_KLASS 엘리먼트 가리기 */
  if (!productMultiOptionSelectorView?.getProductOptions().length) {
    setMultiOptionVisibility(false);
  }
};

export const handleSpoTextOption = (e) => {
  go(e.currentTarget, $closest('.spo_text_option_wrap'), $addClass('active'));

  if ($val(e.currentTarget).length) {
    controlButtonClose($next(e.currentTarget), true);
  } else {
    controlButtonClose($next(e.currentTarget), false);
  }
};

export const handleFocusOutButtonClose = (e) => {
  go(e.currentTarget, $closest('.spo_text_option_wrap'), $removeClass('active'));
  controlButtonClose($next(e.currentTarget), false);
};

export const handleClickButtonClose = (e) => {
  if (!$closest('.spo_text_option_wrap', e.currentTarget)) return;

  buttonCloseHelper.setClicked(true);
  $setVal('', $prev(e.currentTarget));
  setTimeout(() => {
    buttonCloseHelper.setClicked(false);
  }, 1);
};
