import { $delegate, $el, $findAll, $remove } from 'fxdom/es';
import { each, go, html } from 'fxjs/es';
import { BpOptionF } from '../../../BpOption/F/Function/module/BpOptionF.js';
import { BpOptionS } from '../../../BpOption/S/Function/module/BpOptionS.js';
import { BpOptionTmplS } from '../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import { discontinuedSizeIds, excludingDiscontinued, soldOutSizeIds } from '../../../ProductColor/S/fs.js';
import { getIsBizProduct, setPriceKeyringAndAcryl } from '../../S/product.js';
import { removeCvBlueLineInMaker } from '../cv_blue_line.js';
import {
  getBaseProductColorsInMaker,
  getBaseProductSizesInMaker,
  getCurrentBpcId,
  getCurrentBpsId,
  getProductColorInMaker,
} from '../getSth.js';
import { eventProductBpcA, getBpcTitle, getCurrentBpcName } from '../Product/bpc_fs.js';
import { makeProductBpcPcHtml } from '../Product/bpc_tmpl.js';
import { eventProductBpsA } from '../Product/bps_fs.js';
import { makeBpsSizeItemHtml } from '../Product/bps_tmpl.js';
import { open_chooser } from '../Product/product_fs.js';
import { product_editor_product_tmpl } from '../Product/product_tmpl.js';
import { fcanvasChanging } from '../util.js';
import { bizOptionMenus } from './biz.js';
import { clickHandlerForReviewSummaryCount } from './fs.js';
import { NewMakerPropertyBaseProductConstantS } from '../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { BpOptionUploadTypeS } from '../../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { isStoreProductCustom } from '../../../Creator/Product/S/fs.js';
import { getCustomLevelFromBox } from '../../../Creator/Setting/F/custom.js';

function makeArgsForBpsSizeItemHtml() {
  return [
    excludingDiscontinued(
      getBaseProductSizesInMaker(),
      discontinuedSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker()),
    ),
    getCurrentBpsId(),
    soldOutSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker()),
  ];
}

const event = (el) =>
  go(
    el,
    $delegate(
      'click',
      '.product_editor_ev .color_item:not(.selected)',
      fcanvasChanging(async function (e) {
        $.don_loader_start();
        removeCvBlueLineInMaker();
        await eventProductBpcA(e.currentTarget);
        if ($1('#marpplizer .product_editor .size.section')) {
          $1('#marpplizer .product_editor .size.section').outerHTML = makeBpsSizeItemHtml(
            ...makeArgsForBpsSizeItemHtml(),
          );
        }
        $.don_loader_end();
      }),
    ),
    $delegate('click', '.open_chooser', open_chooser),
    $delegate('click', '.review_summary .counts', clickHandlerForReviewSummaryCount),
    $delegate(
      'click',
      '.product_editor_ev .size.section li:not(.selected)',
      fcanvasChanging(eventProductBpsA),
    ),
  );

export const marpplizer_home = function () {
  const product_color = box().maker.product_color;
  const is_custom_editor = isStoreProductCustom(getCustomLevelFromBox());

  const { is_pure_biz_product } = getIsBizProduct(product_color);
  const biz_option_values = bizOptionMenus.get.bizOptionValuesFromBox();
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(
    product_color._.base_product,
  )?.length;
  const is_bpc_show = !product_color._.base_product.is_bpc_hidden;
  const is_bps_show = !has_bp_option_groups && !product_color._.base_product.is_bps_hidden;
  const bp_discount_set = product_color._.base_product._.bp_discount_set;
  product_color._.base_product._.base_products_bp_option_groups =
    product_color._.base_product._.base_products_bp_option_groups.sort(function (a, b) {
      const a_bog = a._.bp_option_group;
      const b_bog = b._.bp_option_group;
      if (b_bog.is_visibility === a_bog.is_visibility) {
        return a_bog.no - b_bog.no;
      }
      return b_bog.is_visibility - a_bog.is_visibility;
    });
  const base_products_bp_option_groups = product_color._.base_product._.base_products_bp_option_groups;
  const shared_product = box.sel('shared_product');
  const id = 'marpplizer_home';
  setPriceKeyringAndAcryl(product_color);

  const html_body = html`
    <div class="main_head">Product</div>
    ${shared_product
      ? html`<div class="shared_product_wrap">
          <p>상품 링크 만료일</p>
          <div class="shared_product_wrap__expires_at">
            ${moment(shared_product.expires_at).format('YYYY/MM/DD HH:mm 까지')}
          </div>
        </div>`
      : ''}
    ${product_editor_product_tmpl(getProductColorInMaker())}
    ${is_bpc_show
      ? makeProductBpcPcHtml(
          getBaseProductColorsInMaker(),
          getCurrentBpcName(),
          getBpcTitle(),
          getCurrentBpcId(),
        )
      : ``}
    ${is_bps_show ? makeBpsSizeItemHtml(...makeArgsForBpsSizeItemHtml()) : ``}
    ${has_bp_option_groups
      ? html`
          <div class="section maker-product-info__bp-option-groups">
            ${BpOptionTmplS.bpAllOptions({
              product: product_color,
              is_custom_editor,
            })}
          </div>
        `
      : ``}
    <!-- 비즈 전용 옵션 메뉴 -->
    ${BpOptionTmplS.biz.get.html.adapter({
      is_render: is_pure_biz_product,
      biz_option_values,
      bp_discount_set,
    })}
    ${BpOptionUploadTypeS.UPLOAD_TYPE.isUploadType(base_products_bp_option_groups)
      ? html`
          <div class="section maker-product-info__upload-type">
            ${BpOptionUploadTypeS.UPLOAD_TYPE.html.uploadHtml(product_color)}
          </div>
        `
      : ``}
    <!-- 도수 색상 카드 -->
    ${BpOptionF.bizDosuCard.get.html()}
    ${T.lang == 'kr' && G.collabo_type == ''
      ? html`
          <div class="delivery-price section">
            <div class="head">${T('배송비')}</div>
            <div class="body">
              ${product_color._.base_product?.maker_features?.[
                NewMakerPropertyBaseProductConstantS.DIFFERENT_DELIVERY_FEE
              ]?.message || '3,000원'}
            </div>
          </div>
        `
      : ''}
  `;
  /* (도서 산간 지역의 경우, 추가 비용 3,000원이 발생합니다.)*/
  // 만들기 툴은 곧 제공 예정이에요!
  const x_image = html`<img src="//s3.marpple.co/files/u_193535/2024/3/original/846518fd0bf13a97986b396befa3002aac127f771.png" style="width:8px;height:8px;margin-left:8px;" alt=""></img>`;
  return {
    name: 'home',
    app: 'base1',
    tooltips: shared_product
      ? []
      : [
          {
            content: go(
              html`
                <div style="text-align: center;">
                  <span>${TT('biz::opt::title::tippy_content')}</span>${x_image}
                </div>
              `,
              $el,
            ),
            target_unique_class: '.group_discount_guide',
          },
          {
            content: go(
              html`
                <div style="text-align: center;">
                  <span>${TT('maker_upload_type::만들기 툴은 곧 제공 예정이에요!')}</span>${x_image}
                </div>
              `,
              $el,
            ),
            target_unique_class: '.maker-upload-type-selector__is-not-ready-editor',
          },
        ],
    html: html`
      <div class="activity" id="${id}">
        <div class="body product_editor product_editor_ev marpplizer_contents_wrapper">${html_body}</div>
      </div>
    `,
    remove: (el) => {
      go(el, $findAll(`#${id}`), each($remove));
    },
    event,
  };
};
