import { cvImageObjForTmpl } from '../../../../Maker/F/CvImage/fs.js';
import { go, html } from 'fxjs/es';
import { makeCvPatternHtml } from '../../../../Maker/F/CvPattern/tmpl.js';
import { NewMakerConditionAgreeTextF } from '../../../../NewMaker/ConditionAgreeText/F/Function/module/NewMakerConditionAgreeTextF.js';
import { makePatternEvent } from '../../../../Maker/F/CvPattern/fs.js';
import { NewMakerFitPrintWidthF } from '../../../../NewMaker/FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';

const event = (el) =>
  go(
    el,
    makePatternEvent,
    $.on('click', '.image_editor_ev .fit_to_print_width >.head', function (e) {
      $.toggle_class(e.currentTarget, 'checked');
      const is_fitted_to_print_width = $.has_class(e.currentTarget, 'checked');
      if (is_fitted_to_print_width) {
        const cv_obj = G.mp.maker.active();
        NewMakerFitPrintWidthF.activateFitToPrintWidth(cv_obj, cv_obj.canvas);
        cv_obj.canvas.renderAll();
      } else NewMakerFitPrintWidthF.cancelFitToPrintWidth(G.mp.maker.active());
    }),

    (el) => NewMakerConditionAgreeTextF.clickEvent(el, '.condition_agree_text'),
  );
export async function marpplizerImage() {
  const _cv_object = G.mp.maker.active();
  const { pattern_data, request_bg_removal, is_fitted_to_print_width } = await cvImageObjForTmpl();
  return {
    name: 'image',
    app: 'base2',
    html: html`
      <div class="activity">
        <div class="cv_image_editor body image_editor_ev marpplizer_contents_wrapper">
          <div class="main_head">
            <div class="body">${T('Edit Image')}</div>
            <div class="option"><button class="marpplizer_back_btn marpplizer_back_btn_styl"></button></div>
          </div>
          <div class="section fit_to_print_width">
            <div class="head ${is_fitted_to_print_width ? 'checked' : ''}">
              <div class="option check_box"></div>
              <div class="title">${T('maker::양옆 채우기')}</div>
            </div>
          </div>
          ${makeCvPatternHtml(pattern_data)}
          <div class="request_bg_removal section">
            <div class="head ${request_bg_removal ? 'checked' : ''}">
              <div class="option check_box"></div>
              <div class="title">${T('Background Remove Request')}</div>
            </div>
          </div>
          <div class="condition_agree_text section">${NewMakerConditionAgreeTextF.fn(_cv_object)}</div>
        </div>
      </div>
    `,
    event,
  };
}
