import { go, html, identity, some, strMap } from 'fxjs/es';
import { NewMakerPropertyBaseProductConstantS } from '../../../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBaseProductS } from '../../../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { OMPCoreAtomTmplS } from '../../../../OMP/Core/Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

const svgFileUpload = {
  getOriginalFileName: (product_color) => {
    return (
      (product_color._.svg_product_materials &&
        product_color._.svg_product_materials[0]?.original_svg_file_name) ||
      product_color._.svg_product?._?.svg_product_pages[0]?._?.svg_image?.original_name
    );
  },
  getFileType: () => {
    return {
      file: 'SVG',
      mimetype: 'image/svg+xml',
      file_descriptions: [
        T('maker_upload_type::일러스트레이터 버전'),
        T('maker_upload_type::첨부 파일 형식: SVG (10MB 이하)'),
        T('maker_upload_type::위 템플릿 파일의 가이드를 준수하여 디자인 작업해 주세요.'),
      ],
    };
  },
};
export const UPLOAD_TYPE = {
  bp_option_group_id: 252,
  isUploadType: (base_products_bp_option_groups) =>
    go(
      base_products_bp_option_groups,
      some(
        (base_products_bp_option_group) =>
          base_products_bp_option_group._.bp_option_group.id === UPLOAD_TYPE.bp_option_group_id,
      ),
    ),
  getUploadType: (base_products_bp_option_groups, selected_bp_option_ids) => {
    if (some((id) => id == UPLOAD_TYPE.option.direct.bp_option_id, selected_bp_option_ids))
      return UPLOAD_TYPE.option.direct.dev_name;
    if (some((id) => id == UPLOAD_TYPE.option.editor.bp_option_id, selected_bp_option_ids))
      return UPLOAD_TYPE.option.editor.dev_name;
    return '';
  },
  isDirectType: (product) => {
    return some(
      (id) => id == UPLOAD_TYPE.option.direct.bp_option_id,
      product._.selected_option_group?.bp_option_ids,
    );
  },
  isEditorType: (product) => {
    return some(
      (id) => id == UPLOAD_TYPE.option.editor.bp_option_id,
      product._.selected_option_group?.bp_option_ids,
    );
  },
  option: {
    direct: {
      dev_name: 'DIRECT',
      bp_option_id: 685, // stage 595 / dev 685
      isDirectType: (product) => {
        return some(
          (id) => id == UPLOAD_TYPE.option.direct.bp_option_id,
          product._.selected_option_group?.bp_option_ids,
        );
      },
      getFileType: (base_product) => {
        return base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.PDF_FILE_POSSIBLE]
          ? NewMakerPropertyBaseProductS.pdfFilePossible.getFileType()
          : svgFileUpload.getFileType();
      },
      getPrintingFile: (product) => {
        if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product._.base_product)) {
          return NewMakerPropertyBaseProductS.pdfFilePossible.getPrintingFile(product);
        }
      },
      makePdfResultTmpl: ({ pdf_printing_file, printing_template }) => {
        return NewMakerPropertyBaseProductS.pdfFilePossible.makeResultTmpl({
          pdf_printing_file,
          printing_template,
        });
      },
      getOriginalFileName: (product_color) => {
        return NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)
          ? NewMakerPropertyBaseProductS.pdfFilePossible.getOriginalFileName(product_color)
          : svgFileUpload.getOriginalFileName(product_color);
      },
    },
    editor: {
      dev_name: 'EDITOR',
      bp_option_id: 686, // stage 562 / dev 686
      isReady: (base_products_bp_option_groups) => {
        if (!base_products_bp_option_groups?.length) return;
        return go(
          base_products_bp_option_groups,
          some((bpbog) => bpbog.default_bp_option_id === UPLOAD_TYPE.option.editor.bp_option_id),
        );
      },
      isReadyBp: (product_color) => {
        return !!UPLOAD_TYPE.option.editor.isReady(
          product_color._.base_product._.base_products_bp_option_groups,
        );
      },
    },
  },
  html: {
    customSelectBoxHtml: (selected_bp_option_id, is_editor_not_ready, is_custom_editor) => {
      const editor_html = html`
        <div
          data-is_public="true"
          class="option ${selected_bp_option_id == UPLOAD_TYPE.option.editor.bp_option_id
            ? 'selected'
            : ''} ${is_editor_not_ready ? 'maker-upload-type-selector__is-not-ready-editor' : ''}"
          data-id="${UPLOAD_TYPE.option.editor.bp_option_id}"
          style="${is_editor_not_ready ? 'opacity:0.3; cursor:not-allowed;' : ''}"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M9.66512 19C10.2148 19 10.6645 19.45 10.6645 20C10.6645 20.55 10.2148 21 9.66512 21C9.11547 21 8.66576 20.55 8.66576 20C8.66576 19.45 9.11547 19 9.66512 19Z"
              fill="currentColor"
            />
            <path
              d="M20.9879 9.67C20.9879 10.22 20.5382 10.67 19.9885 10.67C19.4389 10.67 18.9892 10.22 18.9892 9.67C18.9892 9.12 19.4389 8.67 19.9885 8.67C20.5382 8.67 20.9879 9.12 20.9879 9.67Z"
              fill="currentColor"
            />
            <path
              d="M9.66512 3C10.2148 3 10.6645 3.45 10.6645 4C10.6645 4.55 10.2148 5 9.66512 5C9.11547 5 8.66576 4.55 8.66576 4C8.66576 3.45 9.11547 3 9.66512 3Z"
              fill="currentColor"
            />
            <path
              d="M14.3222 3C14.8718 3 15.3215 3.45 15.3215 4C15.3215 4.55 14.8718 5 14.3222 5C13.7725 5 13.3228 4.55 13.3228 4C13.3228 3.45 13.7725 3 14.3222 3Z"
              fill="currentColor"
            />
            <path
              d="M4.99809 2H2.99936C2.44743 2 2 2.44772 2 3V5C2 5.55228 2.44743 6 2.99936 6H4.99809C5.55002 6 5.99746 5.55228 5.99746 5V3C5.99746 2.44772 5.55002 2 4.99809 2Z"
              fill="currentColor"
            />
            <path
              d="M20.9879 2H18.9892C18.4373 2 17.9898 2.44772 17.9898 3V5C17.9898 5.55228 18.4373 6 18.9892 6H20.9879C21.5398 6 21.9873 5.55228 21.9873 5V3C21.9873 2.44772 21.5398 2 20.9879 2Z"
              fill="currentColor"
            />
            <path
              d="M4.99809 9.67C4.99809 10.22 4.54838 10.67 3.99873 10.67C3.44908 10.67 2.99936 10.22 2.99936 9.67C2.99936 9.12 3.44908 8.67 3.99873 8.67C4.54838 8.67 4.99809 9.12 4.99809 9.67Z"
              fill="currentColor"
            />
            <path
              d="M4.99809 14.33C4.99809 14.88 4.54838 15.33 3.99873 15.33C3.44908 15.33 2.99936 14.88 2.99936 14.33C2.99936 13.78 3.44908 13.33 3.99873 13.33C4.54838 13.33 4.99809 13.78 4.99809 14.33Z"
              fill="currentColor"
            />
            <path
              d="M4.99809 18H2.99936C2.44743 18 2 18.4477 2 19V21C2 21.5523 2.44743 22 2.99936 22H4.99809C5.55002 22 5.99746 21.5523 5.99746 21V19C5.99746 18.4477 5.55002 18 4.99809 18Z"
              fill="currentColor"
            />
            <path
              d="M12.0236 8.53L13.9724 19.94C14.0723 20.38 14.632 20.51 14.9118 20.16L16.8505 17.8L18.9892 21.51C19.259 21.98 19.8486 22.13 20.3183 21.87C20.788 21.6 20.9379 21.01 20.6781 20.54L18.5395 16.83L21.5476 16.33C21.9873 16.26 22.1572 15.7 21.8274 15.4L12.9131 8.01C12.5233 7.65 11.9037 8.01 12.0236 8.53Z"
              fill="currentColor"
            />
          </svg>
          <div class="select_box maker-upload-type-selector__text">${T('maker_upload_type::디자인하기')}</div>
        </div>
      `;
      const upload_html = html`<div
        data-is_public="true"
        class="option ${selected_bp_option_id == UPLOAD_TYPE.option.direct.bp_option_id ? 'selected' : ''}"
        data-id="${UPLOAD_TYPE.option.direct.bp_option_id}"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M14.3993 17.495H15.9688C15.9688 17.495 21.3892 17.856 21.4275 12.62C21.3892 7.30031 15.9688 7.25744 15.9688 7.25744C14.7188 4.86976 12.3564 3.35742 9.51768 3.35742C5.73305 3.35742 2.57031 6.46447 2.57031 10.1824C2.57031 14.1299 5.54776 17.495 9.51768 17.495M15.2492 13.8936L12.1063 11.2146M12.1063 11.2146L8.96345 13.8936M12.1063 11.2146V20.6431"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="select_box maker-upload-type-selector__text">
          ${G.collabo_type === 'creator'
            ? T('maker_upload_type::내 디자인 업로드하기')
            : T('maker_upload_type::디자인 파일 업로드')}
        </div>
      </div>`;
      return html`
        <div class="select_box maker-upload-type-selector">
          ${go(
            is_custom_editor
              ? [editor_html] // 스튜디오 커스텀 상품 에디터에서는 "직접업로드" 방식 없음
              : is_editor_not_ready
              ? [upload_html, editor_html]
              : [editor_html, upload_html],
            strMap(identity),
          )}
        </div>
      `;
    },
    mobileHtmlForSvgFileUploadMobile: (base_product) => {
      const is_pdf_file_possible = NewMakerPropertyBaseProductS.pdfFilePossible.isIt(base_product);
      return G.collabo_type === 'creator'
        ? html`
            <div class="maker-upload-type-mobile  ${is_pdf_file_possible ? '' : 'only_svg'}">
              <div class="maker-upload-type-mobile__body">
                <img
                  src="//s3.marpple.co/files/u_193535/2023/12/original/99378eca9e10e3ed591a18c61a7a92a349e5071d1.png"
                  style="width:20px; height:20px;"
                  alt=""
                />
                <div class="maker-upload-type-mobile__text">
                  ${T('maker_upload_type::내 디자인 파일로 만드는 방법')}
                </div>
                <img
                  src="//s3.marpple.co/files/u_193535/2023/12/original/14d644d387fa36a7780e8021d354af035b89f5ed1.png"
                  style="width:20px; height:20px;"
                  alt=""
                />
              </div>
            </div>
          `
        : html`
            <div class="maker-upload-type-mobile">
              <button
                type="button"
                class="option_btn ${is_pdf_file_possible ? '' : 'only_svg'}"
                id="mp-maker-pdf-file-upload-pop-up"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.3993 17.495H15.9688C15.9688 17.495 21.3892 17.856 21.4275 12.62C21.3892 7.30031 15.9688 7.25744 15.9688 7.25744C14.7188 4.86976 12.3564 3.35742 9.51768 3.35742C5.73305 3.35742 2.57031 6.46447 2.57031 10.1824C2.57031 14.1299 5.54776 17.495 9.51768 17.495M15.2492 13.8936L12.1063 11.2146M12.1063 11.2146L8.96345 13.8936M12.1063 11.2146V20.6431"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="select_box maker-upload-type-selector__text">
                  ${T('maker_upload_type::디자인 파일 업로드')}
                </div>
              </button>
              ${is_pdf_file_possible
                ? html`
                    <div class="desc">
                      ${T('maker_upload_type::마플 템플릿(ai 파일)을 다운받아 제작해 보세요.')}
                    </div>
                  `
                : html``}
            </div>
          `;
    },
    uploadHtml: (product_color) => {
      const original_name = UPLOAD_TYPE.option.direct.getOriginalFileName(product_color);
      const { file_descriptions, mimetype } = UPLOAD_TYPE.option.direct.getFileType(
        product_color._.base_product,
      );
      return html`
        <div class="maker-upload-type-template">
          <div>
            <div class="maker-upload-type-template__title">
              ${T('maker_upload_type::템플릿 다운로드 / 도안업로드')}
            </div>
            <div class="maker-upload-type-template__download-template">
              <button class="maker-upload-type-template__download-btn">
                ${OMPCoreAtomTmplS.downloadIcon()}
                <span>${T('maker_upload_type::템플릿 다운로드')}</span>
              </button>
            </div>
            <div class="maker-upload-type-template__upload-file" style="margin-top:12px">
              <button class="maker-upload-type-template__upload-btn">
                <img
                  src="//s3.marpple.co/files/u_193535/2023/11/original/55d1c81630bf3dad53ad3d527f5bdc62bf0951931.svg"
                  alt=""
                /><span class="maker-upload-type-template__file-name"
                  >${original_name ||
                  (G.collabo_type === 'creator'
                    ? T('maker_upload_type::디자인 업로드')
                    : T('maker_upload_type::도안 업로드'))}</span
                >
              </button>
              <input type="file" style="display: none;" value="" accept="${mimetype}" />
            </div>
            <ul class="maker-upload-type-template__upload-description">
              ${go(
                file_descriptions,
                strMap(
                  (desc) => html`
                    <li>
                      <img
                        src="//s3.marpple.co/files/u_193535/2023/11/original/371204c31bb055e4059260200d4e2d646a6604801.png"
                        alt=""
                      /><span>${desc}</span>
                    </li>
                  `,
                ),
              )}
            </ul>
          </div>
        </div>
      `;
    },
    boardHtml: (product_color) => {
      if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)) {
        const preview = NewMakerPropertyBaseProductS.bpOptionPreviews.makePreviewHtml(product_color);
        return html` <div class="maker-upload-type-board" style="display: none;">${preview}</div> `;
      }
      return product_color._.base_product.maker_type === 'KEYRING'
        ? html`
            <div class="maker-upload-type-board" style="display: none;">
              ${G.collabo_type === 'creator'
                ? T('maker_upload_type::템플릿을 다운로드하시고 직접 나만의 아크릴 키링을 만들어 보세요.')
                : T(
                    'maker_upload_type::템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 키링을 만들어 보세요.',
                  )}
            </div>
          `
        : html`
            <div class="maker-upload-type-board" style="display: none;">
              ${G.collabo_type === 'creator'
                ? T('maker_upload_type::템플릿을 다운로드하시고 직접 나만의 아크릴 스탠드를 만들어 보세요.')
                : T(
                    'maker_upload_type::템플릿을 다운로드하시고 도안을 제작해 나만의 아크릴 스탠드를 만들어 보세요.',
                  )}
            </div>
          `;
    },
  },

  notAvailableSvgEnterIfUploadedAlert: async (alertFunc) => {
    if (G.collabo_type === 'creator') {
      return await alertFunc({
        title: T('maker_upload_type::maker_upload_type_01'),
        body: T('maker_upload_type::maker_upload_type_02'),
      });
    } else {
      return await alertFunc({
        content: T('maker_upload_type::전문가용 에디터를 활용해 제작한 도안은 PC에서 확인하실 수 있어요.'),
      });
    }
  },
};
