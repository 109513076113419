import $dataStr from 'fxdom/es/dataStr.js';
import { html, strMap } from 'fxjs/es';
import { messages } from '../../../../../Creator/Setting/S/messages_id.js';
import { MShopStudioDigitalProductCreatorTmplS } from './module/MShopStudioDigitalProductCreatorTmplS.js';

export const categorySelectionMo = ({ cate_items, ebook_categories, store_ecommerce_info }) => {
  return html`
    <div
      class="digital-product-category-selection"
      data-fx-json="${$dataStr({ store_ecommerce_info, ebook_categories })}"
    >
      <div class="digital-product-category-selection__title">
        <div class="digital-product-category-selection__title__main">${T(messages.digital_products.p3)}</div>
        <div class="digital-product-category-selection__title__sub">${T(messages.digital_products.p4)}</div>
      </div>
      <div class="digital-product-category-selection__categories">
        ${strMap(MShopStudioDigitalProductCreatorTmplS.makeCateMenuHtml, cate_items)}
      </div>
    </div>
  `;
};
