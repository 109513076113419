import { AdScriptNaverF } from '../../../../../AdScript/Naver/F/Function/module/AdScriptNaverF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { delay, each, go, tap, map, head, reduce, add, mapL, tail, takeAllC } from 'fxjs/es';
import { UserJwtF } from '../../../../../User/Jwt/F/Function/module/UserJwtF.js';
import { resetUserCounts } from '../../../../../MPay/Cart/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { PBPbUpS } from '../../../../../PB/PbUp/S/Function/module/PBPbUpS.js';
import axios from 'axios';

export const addPbUps = async (pb_up_materials, is_cart) => {
  each((pb_up_material) => {
    PBPbUpS.pbUpMaterial(pb_up_material);
  }, pb_up_materials);

  const scroll_top_promise = !MShopUtilF.isMobile() ? MShopUtilF.moveScrollToTop(200) : undefined;

  // 세션이 없는 유저인 경우 병렬로 돌리면 up의 user_id가 다르게 생성되어 첫번째로 먼저 찌른다음 나머지를 병렬로 추가
  const first_up = await axios
    .post(`/${T.lang}/@api/pb_ups/add`, { pb_up_material: head(pb_up_materials) })
    .then(({ data }) => data);

  const rest_pb_up_materials = tail(pb_up_materials);
  const rest_ups = await go(
    rest_pb_up_materials,
    mapL(async (pb_up_material) => {
      return await axios.post(`/${T.lang}/@api/pb_ups/add`, { pb_up_material }).then(({ data }) => data);
    }),
    takeAllC(10),
  );

  const ups = [first_up, ...rest_ups];

  await go(
    ups,
    async (ups) => {
      const up = head(ups);
      if (up._?.user) await go(UserJwtF.loginMessage(up._.user), delay(600));
      return up;
    },
    tap(() => {
      const total_quantity = go(
        pb_up_materials,
        map(({ quantity }) => quantity),
        reduce(add),
      );

      if (is_cart) {
        const data = { ...box.sel('user_counts') };
        data.cart += Number(total_quantity);
        resetUserCounts({ data });
      }
    }),
    async () => {
      if (MShopUtilF.isMobile()) {
        await MuiF.removeFrame();
      }
      if (is_cart) {
        await scroll_top_promise;
        MShopUtilF.popToastMsg(T('Added to cart'), 'cart');
        AdScriptNaverF.mpsAddToCart();
      }
    },
  );
  return ups;
};
