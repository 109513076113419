import { $append, $findAll, $el, $addClass, $qs, $find } from 'fxdom/es';
import { take1, each, mapL, html, go } from 'fxjs/es';
import {
  ProductListApiS,
  ProductListTypeS,
} from '../../../../../../services/MarppleShop/features/ProductList/outbound/share.ts';
import { ProductCardList } from '../../../../../../services/MarppleShop/renderApp/components/cells/ProductCardList/ProductCardList.ts';
import { typoPcMo } from '../../../../../../services/MarppleShop/shared/typography/typo.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';

export const addRecommendProducts = (el) => {
  if (!UtilS.isMarppleShopApp()) return;

  const wrapper_klass = 'mshop-cart__recommend-products';
  const products_wrap_klass = 'mshop-cart__recommend-products-wrap';
  if ($qs(`.${wrapper_klass}`)) return;

  const is_mobile = MShopUtilF.isMobile();
  go(
    el,
    $findAll('.empty_page'),
    mapL(async (cart_el) => {
      /* 카트가 비어있으면 추천상품 그려주기 */
      const texts = {
        title: ET('mps2::search::recommend'),
      };

      const recommend_products_el = $el(html`
        <div class="${wrapper_klass}">
          <div class="mshop-cart__recommend-products-header">
            <span
              class="mshop-cart__recommend-products-title ${typoPcMo({
                is_mobile,
                pc: '16_bold',
                mo: '12_medium',
              })}"
              >${texts.title}</span
            >
          </div>
          <div class="${products_wrap_klass}"></div>
        </div>
      `);

      $append(recommend_products_el, cart_el);

      return [
        recommend_products_el,
        await ProductListApiS.getBestProductListApi({
          limit: 6,
          best_product_type: ProductListTypeS.bestProductTypeMap.weekly,
          without_sold_out: true,
          is_random: true,
        }),
      ];
    }),
    take1,
    each(([recommend_products_el, products]) => {
      $append(
        new ProductCardList(
          { products },
          {
            is_mobile,
            resize_width: 600,
            container_klass: products_wrap_klass,
            type: is_mobile ? 'compact' : 'normal',
            prevent_default_style: true,
            hide_badges: true,
            hide_tag: true,
          },
        ).render(),
        $find('.mshop-cart__recommend-products-wrap', recommend_products_el),
      );
      $addClass('show', recommend_products_el);
    }),
  );
};
