import axios from 'axios';

export const apiCall = {
  /**
   * @define 프로덕트에서 사용된 image_ids 를 찾아서 folder 조회
   * @param {number[]} image_ids
   * @return {Promise<{  }>}
   * */
  getUsedImageFolders: async ({ image_ids }) => {
    return (
      await axios.get(`/${T.lang}/@api/product/image-folders`, {
        params: {
          image_ids,
        },
      })
    ).data;
  },
};
