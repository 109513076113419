import { go, map, omit, rest, take1, html, strMap } from 'fxjs/es';
import { ButtonClose } from '../../../../../../../services/MarppleShop/shared/components/atoms/ButtonClose/ButtonClose.ts';
import { PriceS } from '../../../../../../Price/S/Function/module/PriceS.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductDetailCustomSelectOptionConstantS } from '../../../../ProductDetail/CustomSelectOption/S/Constant/module/MShopAppProductDetailCustomSelectOptionConstantS.js';
import { MShopAppProductDetailCustomSelectOptionTmplS } from '../../../../ProductDetail/CustomSelectOption/S/Tmpl/module/MShopAppProductDetailCustomSelectOptionTmplS.js';
import { isSpoOptions } from '../Function/pboption.js';
/*
 * spo_option
 *   < spo_option_values {id, is_selected, name, n_price, n_no_stock}
 * */
export const selectOption = (spo_option, disabled) => {
  const is_default_selected = !spo_option._?.spo_option_values?.find(
    (spo_option_value) => spo_option_value.is_selected,
  );

  return html`
    <select
      class="spo_option"
      name="spo_option_${spo_option.id}"
      ${disabled ? 'disabled' : ''}
      data-dimm="${disabled ? 'true' : 'false'}"
      data-spo_option_id="${spo_option.id}"
      data-is_default_selected="${is_default_selected}"
    >
      <option value="" ${is_default_selected ? 'selected' : ''}>${spo_option.name}</option>
      ${strMap((spo_option_value) => {
        return html`
          <option
            value="${spo_option_value.id}"
            ${spo_option_value.is_selected ? 'selected' : ''}
            data-name="${spo_option_value.name}"
            data-n_price="${spo_option_value.n_price}"
            ${spo_option_value.n_no_stock ? 'disabled' : ''}
          >
            ${spo_option_value.name}
            ${parseFloat(spo_option_value.n_price) > 0
              ? `(+${PriceS.pricify(spo_option_value.n_price)})`
              : ``}
            ${spo_option_value.n_no_stock ? `- ${T('mshop::ProductDetail::sold_out')}` : ''}
          </option>
        `;
      }, spo_option._?.spo_option_values)}
    </select>
  `;
};

export const selectOptionNess = (spo_option) => {
  const is_default_selected = !spo_option._?.spo_option_values?.find(
    (spo_option_value) => spo_option_value.is_selected,
  );
  return html`
    <select
      class="spo_option"
      name="spo_option_${spo_option.id}"
      data-spo_option_id="${spo_option.id}"
      data-is_default_selected="${is_default_selected}"
    >
      <option value="" ${is_default_selected ? 'selected' : ''}>${spo_option.name}</option>
      ${strMap(
        (spo_option_value) =>
          html`
            <option
              value="${spo_option_value.id}"
              ${spo_option_value.is_selected ? 'selected' : ''}
              data-name="${spo_option_value.name}"
              data-n_price="${spo_option_value.n_price}"
              ${spo_option_value.n_no_stock ? 'disabled' : ''}
            >
              ${spo_option_value.name}
              ${parseFloat(spo_option_value.n_price) > 0
                ? `(+ ${PriceS.pricify(spo_option_value.n_price)})`
                : ``}
              ${spo_option_value.n_no_stock ? `- ${T('mshop::ProductDetail::sold_out')}` : ''}
            </option>
          `,
        spo_option._?.spo_option_values,
      )}
    </select>
  `;
};

export const textOption = (spo_text_option, disabled) => {
  if (UtilS.isNessApp()) {
    return textOptionNess(spo_text_option, disabled);
  }

  return html`
    <div class="spo_text_option_wrap ${disabled ? 'disabled' : ''}">
      <input
        class="spo_text_option"
        name="spo_text_option_${spo_text_option.id}"
        data-spo_text_option_id="${spo_text_option.id}"
        placeholder="${spo_text_option.name}"
        ${disabled ? 'disabled' : ''}
      />
      ${new ButtonClose({ hide: true, tag: 'span' }).toHtmlSSR()}
    </div>
  `;
};

const textOptionNess = (spo_text_option) => html`
  <textarea
    class="spo_text_option"
    name="spo_text_option_${spo_text_option.id}"
    data-spo_text_option_id="${spo_text_option.id}"
    placeholder="${spo_text_option.name}"
    rows="1"
  ></textarea>
`;

export const optionTmpl = (spo = {}, disabled) => {
  const spo_options =
    spo._?.spo_options.length > 0
      ? go(spo._.spo_options, take1).concat(go(spo._.spo_options, rest, map(omit('_'))))
      : [];
  const spo_text_options = spo._?.spo_text_options;

  return html`
    ${UtilS.htmlIf(
      isSpoOptions(spo),
      `
      <div class="pb-options">
        <div class="pb-options__label">${ET('product_detail::options::custom')}</div>
        <div class="pb-options__options">
          <!--  텍스트형 옵션  -->
          ${strMap((opt) => textOption(opt, disabled))(spo_text_options)}
          
          <!--  셀렉트형 옵션  -->
          ${strMap((opt) => selectOption(opt, disabled))(spo_options)}
          
        </div>
      </div>`,
    )}
  `;
};

export const customOptionTmpl = (spo = {}) => {
  if (!MShopAppProductDetailCustomSelectOptionConstantS.set_1.spo_ids.includes(spo.id)) return;
  const spo_text_options = spo._?.spo_text_options;
  return MShopAppProductDetailCustomSelectOptionTmplS.customSelectOptionHTML({ spo_text_options });
};
