import { go, join, map } from 'fxjs/es';

export const pMakeOptionInfo = (group_names_option_names) =>
  go(
    group_names_option_names,
    map(
      (group_names_option_name) =>
        group_names_option_name['option_name' + G._en] || group_names_option_name.option_name,
    ),
    join(', '),
  );
