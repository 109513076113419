import { $find, $qs } from 'fxdom/es';
import { Header } from '../../../../../../services/MarppleShop/renderApp/components/cells/Header/Header.ts';
import { LayoutView } from '../../../../../../services/MarppleShop/renderApp/components/cells/Layout/LayoutView.ts';
import { ProductBadgeList } from '../../../../../../services/MarppleShop/renderApp/components/cells/ProductBadgeList/ProductBadgeList.ts';
import { ButtonClose } from '../../../../../../services/MarppleShop/shared/components/atoms/ButtonClose/ButtonClose.ts';
import { NessCoreF } from '../../../../../Ness/Core/F/Function/module/NessCoreF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppProductDetailMuiS } from '../../S/Mui/module/MShopAppProductDetailMuiS.js';
import { appendedFn, checkFoldedNess } from '../fn.js';
import { initScrollSpy, initTextarea } from './handles.js';
import { Popup } from '../../../../../../services/MarppleShop/shared/components/cells/Popup/Popup.ts';
import { NessCustomMakerF } from '../../../../../NessCustomMaker/F/Function/module/NessCustomMakerF.js';
import axios from 'axios';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...MShopAppProductDetailMuiS.tab,

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,
  appending(tab_el) {
    if (UtilS.isNessApp()) {
      checkFoldedNess();
    }

    MShopUtilF.hydrationRune(Header, LayoutView, ProductBadgeList, ButtonClose, Popup);
  }, // tab 엘리먼트가 만들어지면 울리는 함수
  showing(tab_el$) {}, // tab 엘리먼트 show하기 전 울리는 함수_
  showed(tab_el$) {
    if (UtilS.isNessApp()) {
      const is_ness_custom_maker = $qs('html.ness-custom-maker');
      if (is_ness_custom_maker) {
        if (MShopUtilF.isMobile()) {
          NessCustomMakerF.refreshCustomProductDetailHead(tab_el$);
        }
      } else {
        NessCoreF.hideLoading();
      }
    }
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el$) {
    if (UtilS.isNessApp()) {
      if (!MShopUtilF.isMobile()) {
        const header_height = $qs('.mshop-default-header__inner')?.clientHeight || 80;
        if ($qs('.pd-form__sticky')) {
          new StickySidebar('.pd-form__sticky', {
            containerSelector: '.pd-form__sticky-slide-container',
            resizeSensor: true,
            topSpacing: header_height,
            bottomSpacing: 50,
          });
        }
      }
    }

    if (UtilS.isMarppleShopApp()) {
      if ($qs('.private-product-detail')) return;

      const is_mobile = MShopUtilF.isMobile();
      const product_info_el = tab_el$.querySelector('.pd-info-group');
      const contents_els = product_info_el.querySelectorAll('.info_el') || [];
      initScrollSpy(is_mobile);
      initTextarea(tab_el$);

      let content_height = 0;
      contents_els.forEach((el) => {
        const rect = el.getBoundingClientRect();
        content_height += rect.height;
      });
      const hide_el = $qs('.pd-info-group__hide', tab_el$);
      const has_sns = $find('.pd-story__section.sns', product_info_el);
      const max_height = is_mobile ? 1600 : 2400;

      if (!has_sns && content_height < max_height) {
        hide_el.classList.add('hide');
        const timeout = setTimeout(() => {
          hide_el.classList.add('none');
          clearTimeout(timeout);
        }, 2000);
      }
    }
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수
  hiding(tab_el$, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el$, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el$, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el$, v) {}, // tab이 삭제된 후 울리는 함수

  appended: async (tab_el) => {
    appendedFn(tab_el);
    if (UtilS.isNessApp()) {
      const ness_custom_maker_el = $qs('.pd-contents__ness-custom-maker');
      if (ness_custom_maker_el) {
        // $.don_loader_start();
        const product = box.sel('maker->product_color');
        const {
          data: { product_faces2 },
        } = await axios.get(`/${T.lang}/@api/product_color/p2`, {
          params: { id: product.id },
        });
        product.product_faces2 = product_faces2;
        product.id = null;
        if (MShopUtilF.isMobile()) {
          await NessCustomMakerF.startNessCustomForMobile(ness_custom_maker_el);
        } else {
          await NessCustomMakerF.startNessCustomForPc(ness_custom_maker_el);
        }
        NessCoreF.hideLoading({ duration: 0 });
      }
    }
  },
};
