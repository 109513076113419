import { CustomEventWithDetail, html, on, View } from 'rune-ts';
import { preventEscape } from '../../../../shared/util/preventEscape';
import { Confirm } from '../Confirm/Confirm';
import klass from './CreatorCheerUpProfile.module.scss';
import { staticTypo, typo } from '../../../../shared/typography/typo';
import { CreatorConstantS, CreatorTypeS } from '../../../../features/Creator/outbound/share';
import { makeEmptyScreenNavigate } from '../../../../shared/app/navigate';
import { dataStr } from '../../../../shared/util/dataStr';
import { HeartFillIcon, WikiEditIcon } from '../../atoms/Icon';
import { htmlIf } from '../../../../shared/util';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { getSnsIcon } from '../../../../shared/util/snsIcon';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { pushLoginStack } from '../../../../../../modules/Creator/Login/F/fs';
import axios from 'axios';
import { ConfigSentryF } from '../../../../../../modules/Config/Sentry/F/Function/module/ConfigSentryF';
import { $data, $qs } from 'fxdom/es';
import { MuiF } from '../../../../../../modules/Mui/F/Function/module/MuiF';
import { MShopAppStoreAboutEditModalMuiF } from '../../../../../../modules/MShop/App/Store/About/EditModal/F/Mui/module/MShopAppStoreAboutEditModalMuiF';
import { getResizedUrl } from '../../../../shared/util/image';

type CreatorCheerUpProfileData = CreatorTypeS.CreatorCardType & {
  is_mobile: boolean;
  is_store_owner?: boolean;
};

interface CreatorCheerUpProfileOption {
  klass?: string;
}

export class CreatorCheerUpLikeToggleEvent extends CustomEventWithDetail<{
  toggleLikeButton: () => void;
}> {}

export class CreatorCheerUpProfile extends View<CreatorCheerUpProfileData> {
  state: CreatorCheerUpProfileOption & {
    // FIXME
  };

  constructor(data: CreatorCheerUpProfileData, option: CreatorCheerUpProfileOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
    };
  }

  snsIcon(sns_url: string | null) {
    if (!sns_url) return '';
    const { tmpl, link } = getSnsIcon(sns_url);
    return html` <div class="${klass.sns_icon}"><a href="${link}" target="_blank">${tmpl}</a></div> `;
  }

  override template() {
    const {
      domain_name,
      thumbnail_img,
      is_store_owner,
      sns_url,
      name,
      seller_type,
      description,
      is_liked,
      like_count,
    } = this.data;

    const creator_thumbnail_img = thumbnail_img || CreatorConstantS.thumbnail_img;

    const txt1 = ET('mps2::creator::txt1');
    const _description = description || ET('mps2::creator::description_empty');
    if (this.data.is_mobile) {
      return html`
        <div class="${this.state.klass} ${klass.creator_cheer_up_profile} ${typo('14_medium')}">
          <div class="${klass.wrap}">
            <div class="${klass.thumb}">
              <a
                href="/${ET.lang}/${domain_name}"
                data-post-message="${dataStr(makeEmptyScreenNavigate(`/${ET.lang}/${domain_name}`))}"
              >
                <img
                  src="${getResizedUrl({
                    url: creator_thumbnail_img,
                    width: 180 * 2,
                    format: 'webp',
                    quality: 70,
                  })}"
                  alt="${name}"
                  class="${klass.thumb_img}"
                />
              </a>
            </div>
            <div class="${klass.info}">
              <div class="${klass.header}">
                <div class="${klass.name} ${typo('20_bold')}">${name}</div>
                ${sns_url ? html`<div class="${klass.about_sns}">${this.snsIcon(sns_url)}</div>` : ''}
                ${is_store_owner
                  ? html`
                      <div class="${klass.about_edit}">
                        <button type="button" class="${klass.about_btn_edit}">${WikiEditIcon}</button>
                      </div>
                    `
                  : ''}
              </div>
              <div class="${klass.seller_type} ${typo('14_medium')}">${seller_type || ' '}</div>
            </div>

            <div class="${klass.like_area}">
              <button type="button" class="${klass.like_btn} ${htmlIf(klass.active, is_liked)}">
                <span class="${klass.like}">${HeartFillIcon}</span>
                <span
                  class="${klass.like_count} ${staticTypo('unica_14_regular')} ${htmlIf(
                    klass.active,
                    is_liked,
                  )}"
                  >${UtilS.commify(like_count)}</span
                >
              </button>
            </div>
          </div>

          <div class="${klass.about_title} ${typo('16_bold')}">${txt1}</div>
          <div class="${klass.description}">${_description}</div>
        </div>
      `;
    } else {
      return html`
        <div class="${this.state.klass} ${klass.creator_cheer_up_profile} ${typo('14_medium')}">
          <div class="${klass.thumb}">
            <a
              href="/${ET.lang}/${domain_name}"
              data-post-message="${dataStr(makeEmptyScreenNavigate(`/${ET.lang}/${domain_name}`))}"
            >
              <img
                src="${getResizedUrl({
                  url: creator_thumbnail_img,
                  width: 180 * 2,
                  format: 'webp',
                  quality: 70,
                })}"
                alt="${name}"
                class="${klass.thumb_img}"
              />
            </a>
          </div>
          <div class="${klass.info}">
            <div class="${klass.header}">
              <div class="${klass.name} ${typo('28_bold')}">${name}</div>
              ${sns_url ? html`<div class="${klass.about_sns}">${this.snsIcon(sns_url)}</div>` : ''}
              ${is_store_owner
                ? html`
                    <div class="${klass.about_edit}">
                      <button type="button" class="${klass.about_btn_edit}">${WikiEditIcon}</button>
                    </div>
                  `
                : ''}
            </div>
            <div class="${klass.seller_type} ${typo('14_medium')}">${seller_type || ' '}</div>
            <div class="${klass.about_title} ${typo('16_bold')}">${txt1}</div>
            <div class="${klass.description}">${_description}</div>
          </div>

          <div class="${klass.like_area}">
            <button type="button" class="${klass.like_btn} ${htmlIf(klass.active, is_liked)}">
              <span class="${klass.like}">${HeartFillIcon}</span>
              <span
                class="${klass.like_count} ${staticTypo('unica_14_regular')} ${htmlIf(
                  klass.active,
                  is_liked,
                )}"
                >${UtilS.commify(like_count)}</span
              >
            </button>
          </div>
        </div>
      `;
    }
  }

  @on('click', `.${klass.like_btn}`)
  private _onLikeClick() {
    this.dispatchEvent(CreatorCheerUpLikeToggleEvent, {
      bubbles: true,
      detail: {
        toggleLikeButton: this.toggleLikeButton.bind(this),
      },
    });
  }

  @on('click', `.${klass.about_btn_edit}`)
  private _onAboutEditClick() {
    const store = $data($qs('.store-community2'));
    MuiF.openFrame(MShopAppStoreAboutEditModalMuiF.frame, (f, p, [t]) => {
      t.makeData = () => ({ store });
    });
  }

  toggleLikeButtonStyle() {
    this.data.like_count = this.data.is_liked ? this.data.like_count - 1 : this.data.like_count + 1;
    this.data.is_liked = !this.data.is_liked;
    this.redraw();
  }

  async toggleLikeButton() {
    if (!box.sel('is_user->id') || box.sel('is_user->type') == 'TEMP') {
      const confirmed = (await Confirm.open({
        title: ET('mps2::login::need_login::title'),
        message: preventEscape(ET('mps2::login::need_login::message')),
        confirmText: ET('mps2::login::login'),
        cancelText: ET('mps2::signup::close'),
      })) as boolean;

      if (confirmed) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else {
          location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`;
        }
      }
      return;
    }

    const already_like = !!this.data.is_liked;

    this.toggleLikeButtonStyle();

    try {
      await axios.post(UtilS.makeApiUrl(`/:lang/@api/stores_like`, { lang: T.lang }), {
        already_like,
        store_id: this.data.id,
      });
      MShopUtilF.popToastMsg(
        already_like
          ? T('mshop::좋아요 목록에서 삭제되었습니다.')
          : T('mshop::좋아요 목록에 추가되었습니다.'),
        already_like ? 'error' : 'store_like',
      );
    } catch (err) {
      this.toggleLikeButtonStyle();
      ConfigSentryF.error(err);
    }
  }
}
