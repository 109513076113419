import { $qs, $setAttr } from 'fxdom/es';
import { NessCoreConstantS } from '../../../../../Core/S/Constant/module/NessCoreConstantS.js';

export const controlViewMoreBtnVisibilityInCateListHeader = () => {
  const category_menus_wrap_el = $qs(`.${NessCoreConstantS.layout_components.bottom_header_category}__menus`);

  if (!category_menus_wrap_el.firstElementChild || !category_menus_wrap_el.lastElementChild) return;

  /* 첫번째 아이템과 마지막 아이템의 y좌표가 다른 경우 flex wrap이 적용된 것으로 판단한다. */
  const first_el_y_coordinate = category_menus_wrap_el.lastElementChild.getBoundingClientRect().top;
  const last_el_y_coordinate = category_menus_wrap_el.firstElementChild.getBoundingClientRect().top;
  const is_menu_wrap = first_el_y_coordinate !== last_el_y_coordinate;

  const more_button_el = $qs('.ness-default-header-bottom-category__menu-more');

  if (is_menu_wrap) {
    $setAttr({ 'data-is_hidden': false }, more_button_el);
  } else {
    $setAttr({ 'data-is_hidden': true }, more_button_el);
  }

  $setAttr({ 'data-is_hidden': false }, category_menus_wrap_el);
};

export const scrollActiveCateListToBeCentered = () => {
  const active_menu_el = $qs(
    `.${NessCoreConstantS.layout_components.bottom_header_category}__menu[data-active="true"]`,
  );
  if (active_menu_el) {
    active_menu_el.scrollIntoView({ inline: 'center' });
  }
};
