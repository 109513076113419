import { filter, find, flatMap, go, map, omit } from 'fxjs/es';

/*
 * 옵션 선택할때마다, 그에 맞는 조합의 상황에 맞는 spo_options 를 내보내줌
 * spo_options
 *   < spo_option_values
 * spo_items
 *   x spo_item_stocks
 * */
export const pGetRemainingSpoOptions = ({ selected_spo_option_value_ids, spo_items, spo_options }) => {
  const possible_spo_items = go(
    spo_items,
    filter((spo_item) =>
      selected_spo_option_value_ids.every((selected_spo_option_value_id) =>
        spo_item.spo_option_value_ids.some(
          (spo_option_value_id) => selected_spo_option_value_id == spo_option_value_id,
        ),
      ),
    ),
  );
  const possible_spo_option_value_ids = go(
    possible_spo_items,
    flatMap((spo_item) => spo_item.spo_option_value_ids),
  );
  const remaining_spo_options = spo_options.slice(selected_spo_option_value_ids.length, spo_options.length);
  const is_last = remaining_spo_options.length === 1;
  return go(
    remaining_spo_options,
    map((spo_option) => {
      return {
        ...omit(['_'], spo_option),
        _: {
          spo_option_values: go(
            spo_option._.spo_option_values,
            filter((spo_option_value) => possible_spo_option_value_ids.includes(spo_option_value.id)),
            map((spo_option_value) => {
              if (is_last) {
                const spo_item = go(
                  possible_spo_items,
                  find((possible_spo_item) =>
                    possible_spo_item.spo_option_value_ids.some(
                      (spo_option_value_id) => spo_option_value_id === spo_option_value.id,
                    ),
                  ),
                );
                if (!spo_item)
                  throw new Error(
                    `no spo_item 문제 있음...::${JSON.stringify(spo_option_value)}, ${JSON.stringify(
                      possible_spo_items,
                    )}`,
                  );
                const n_price = spo_item.price;
                const n_no_stock = spo_item.spo_item_stock_id
                  ? spo_item._.spo_item_stock?.quantity === 0
                  : false;
                return {
                  ...spo_option_value,
                  n_price,
                  n_no_stock,
                };
              } else {
                return spo_option_value;
              }
            }),
          ),
        },
      };
    }),
  );
};
