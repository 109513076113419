import { html } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import $dataStr from 'fxdom/es/dataStr.js';

export const makeTodayItem = (
  today,
  {
    not_lazy = false,
    klass = '',
    without_category = false,
    category_query = '',
    target = '_self',
    replace_stack = false,
    size = '',
  } = {},
) => {
  const component = 'today-feed';

  const link = `/${T.lang}/@/todays/${today.id}${category_query ? `?category=${category_query}` : ''}`;

  const image_url = today.thumbnail.includes('data:image')
    ? today.thumbnail
    : UtilImageS.getResizedUrl({
        url: today.thumbnail,
        width: 780,
        format: 'webp',
      });

  const post_message = {
    [replace_stack ? 'replaceNavigate' : 'screen']: {
      type: 'util',
      title: T('mshop::Content Detail'),
      uri: link,
    },
  };

  return html`
    <div class="${component} ${klass} ${size ? `${component}_${size}` : ''}">
      <a class="" href="${link}" target="${target}" data-post-message="${$dataStr(post_message)}">
        <div class="${component}__thumb_container">
          <div class="${component}__thumb_wrapper">
            <img
              class="${!not_lazy ? 'lazy-src' : ''} ${component}__thumbnail"
              ${not_lazy ? 'src' : 'data-src'}="${image_url}"
              alt="${today.title}, ${today.description}"
            />
          </div>
        </div>
        <div class="${component}__content">
          <div class="${component}__category" data-show="${!without_category}">
            ${today.category || T('feature')}
          </div>
          <div class="${component}__content_title">
            <p class="${component}__content_title_txt">${today.title || ''}</p>
          </div>
          <p class="${component}__content_description">${today.description || ''}</p>
        </div>
      </a>
    </div>
  `;
};
