import { $, html, on, View } from 'rune-ts';
import klass from './ButtonCount.module.scss';
import { compact, each, pipe } from '@fxts/core';
import { classes } from '../../../util';
import { staticTypo } from '../../../typography/typo';

export class ChangeCountEvent extends CustomEvent<never> {}

export interface ButtonCountData {
  count: number;
  min?: number;
  max?: number;
}

export interface ButtonCountOption {
  klass?: string;
  input_name?: string;
  is_disabled?: boolean;
  is_disabled_plus?: boolean;
  is_disabled_minus?: boolean;
}

interface ButtonCountState extends ButtonCountData, ButtonCountOption {
  min: number;
  max: number;
  input_name: string;
  is_disabled: boolean;
  is_disabled_minus: boolean;
  is_disabled_plus: boolean;
}

export class ButtonCount extends View<ButtonCountData> {
  state: ButtonCountState;

  constructor(data: ButtonCountData, option: ButtonCountOption = {}) {
    super(data, option);

    this.state = {
      count: data.count,
      min: (data.min ?? -9999) as number,
      max: (data.max ?? 9999) as number,
      klass: option.klass ?? '',
      input_name: option.input_name ?? '',
      is_disabled_minus: option.is_disabled_minus ?? false,
      is_disabled_plus: option.is_disabled_plus ?? false,
      is_disabled: option.is_disabled ?? false,
    };
  }

  override template() {
    return html`<div class="${this.state.klass} ${klass.button_count}">
      ${this.makeMinusButtonHtml()}
      <input
        ${this.state.is_disabled ? 'disabled' : ''}
        class="${klass.count} ${staticTypo('unica_16_medium')}"
        name="${this.state.input_name}"
        value="${this.state.count}"
        ${this.state.min === this.state.max ? 'disabled' : ''}
        readonly
      />
      ${this.makePlusButtonHtml()}
    </div> `;
  }

  override redraw(): this {
    if (this.state.count <= this.state.min) {
      this.setButtonDisabled(true, 'minus');
      this.setStateCount(Math.max(this.state.count, this.state.min)); // 설정한 min보다 작은 값이 들어가는 것을 방지
    } else {
      this.setButtonDisabled(false, 'minus');
    }

    if (this.state.count >= this.state.max) {
      this.setButtonDisabled(true, 'plus');
      this.setStateCount(Math.min(this.state.count, this.state.max)); // 설정한 max보다 작은 값이 들어가는 것을 방지
    } else {
      this.setButtonDisabled(false, 'plus');
    }

    $(this.element())
      .find(`.${klass.count}`)
      ?.setValue(String(this.state.count))
      .chain((el) => {
        if (this.state.min === this.state.max) {
          el.setAttribute('disabled', '');
        }
      });

    return this;
  }

  @on('click', `.${klass.plus_button}`)
  clickPlusButton() {
    this.setStateCount(++this.state.count);
    this.redraw();
    this.dispatchEvent(ChangeCountEvent, {
      bubbles: true,
    });
  }

  @on('click', `.${klass.minus_button}`)
  clickMinusButton() {
    this.setStateCount(--this.state.count);
    this.redraw();
    this.dispatchEvent(ChangeCountEvent, {
      bubbles: true,
    });
  }

  public getStateCount() {
    return this.state.count;
  }

  public setButtonDisabled(is_disabled: boolean, button_type: 'plus' | 'minus' = 'minus') {
    if (!(button_type === 'plus' || button_type === 'minus')) {
      throw new Error('버튼 타입이 올바르지 않습니다. 두번째 인자로 plus 혹은 minus를 넣어주세요');
    }

    pipe(
      [
        this.element().querySelector(
          button_type === 'minus' ? `.${klass.minus_button}` : `.${klass.plus_button}`,
        ),
      ],
      compact,
      each((el: HTMLElement) => {
        if (is_disabled) {
          el.classList.add(klass.button_disabled);
        } else {
          el.classList.remove(klass.button_disabled);
        }
      }),
    );
  }

  public setDisabled() {
    this.state.min = 1;
    this.state.max = 1;
    this.setStateCount(1);

    this.redraw();
  }

  private setStateCount(count: number) {
    this.state.count = count;
  }

  private makeMinusButtonHtml() {
    return html`
      <div
        class="${classes(klass.minus_button, {
          [klass.minus_button]: this.state.count > this.state.min,
          [klass.button_disabled]:
            this.state.is_disabled || this.state.is_disabled_minus || this.state.count === this.state.min,
        })}"
      >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_10333_35468)">
            <path
              d="M17.9998 13.0867H5.99975C5.67975 13.0867 5.41309 12.8267 5.41309 12.5C5.41309 12.1733 5.67309 11.9133 5.99975 11.9133H17.9998C18.3198 11.9133 18.5864 12.1733 18.5864 12.5C18.5864 12.8267 18.3264 13.0867 17.9998 13.0867Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_10333_35468">
              <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    `;
  }

  private makePlusButtonHtml() {
    return html`
      <div
        class="${classes(klass.plus_button, {
          [klass.plus_button]: this.state.count < this.state.max,
          [klass.button_disabled]:
            this.state.is_disabled || this.state.is_disabled_plus || this.state.count === this.state.max,
        })}"
      >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_10333_35465)">
            <path
              d="M18.0797 11.9134H12.5797V6.42004C12.5797 6.10004 12.3197 5.83337 11.993 5.83337C11.6663 5.83337 11.4063 6.09337 11.4063 6.42004V11.92H5.91967C5.59967 11.92 5.33301 12.18 5.33301 12.5067C5.33301 12.8334 5.59301 13.0934 5.91967 13.0934H11.4197V18.5934C11.4197 18.9134 11.6797 19.18 12.0063 19.18C12.333 19.18 12.593 18.92 12.593 18.5934V13.0934H18.093C18.413 13.0934 18.6797 12.8334 18.6797 12.5067C18.6797 12.18 18.4197 11.92 18.093 11.92L18.0797 11.9134Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_10333_35465">
              <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    `;
  }
}
