import {
  $addClass,
  $appendTo,
  $attr,
  $el,
  $findAll,
  $hasClass,
  $prependTo,
  $qsa,
  $removeClass,
} from 'fxdom/es';
import {
  curry,
  ifElse,
  reverse,
  each,
  entries,
  equals,
  findIndex,
  go,
  groupBy,
  head,
  lt,
  lte,
  html,
  identity,
  map,
  mapC,
  range,
  reduce,
  zipWithIndexL,
} from 'fxjs/es';
import { replaceUrl } from './util.js';
import { log } from './config.js';
import anime from 'animejs';
import './grid.styl';
import { UtilF } from '../../../../F/Function/module/UtilF.js';

const error_msgs = {
  should_init: (f_name) => `Grid Error:: Should initialize grid before ${f_name}`,
  already_init: () => `Grid Error:: Grid is already initialized`,
};

const safari = UtilF.is_safari();

const grid_cl = 'grid-stack-container';
const grid_col_cl = grid_cl + '__column';
const grid_col_reverse_cl = grid_cl + '__column--reverse';
const grid_item_wrapper_cl = grid_cl + '__item_wrapper';
const grid_item_cl = grid_cl + '__item';
const grid_hide_wrapper = grid_cl + '__hide_wrapper';
const grid_hide_wrapper_hided = grid_cl + '__hide_wrapper--on';

let grid_toggle_observer;
let grid_page_observer;

export const createColumn = (is_reverse, margin, is_last) =>
  $el(
    html`<div
      class="${grid_col_cl} ${is_reverse ? grid_col_reverse_cl : ''}"
      style="margin-right: ${is_last ? 0 : margin}px"
    ></div>`,
  );

export const createHideWrapper = (page) =>
  $el(html`<div class="${grid_hide_wrapper}" data-page="${page}"></div>`);

export const initGridStack = (grid_container_el, { item_margin, columns = 3, is_reverse = false } = {}) => {
  if (checkGridInitialized(grid_container_el)) throw new Error(error_msgs.already_init());

  grid_container_el.item_margin = item_margin;

  grid_toggle_observer = new IntersectionObserver(
    (entries) => {
      go(
        entries,
        map((entry) => {
          const should_show = entry.isIntersecting;

          const getHeight = () => {
            if (should_show) return 'auto';

            // grid_reflow_stack.push(entry.target)
            //
            // if (!update_height_raf) updateGroupHeight()
            //
            // return 'auto';

            const rect = entry.boundingClientRect;
            return rect.height == 0 ? 'auto' : `${rect.height}px`;
          };

          entry.target.style.height = getHeight();

          (should_show ? $removeClass : $addClass)(grid_hide_wrapper_hided, entry.target);
        }),
      );
    },
    {
      rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`,
    },
  );

  const grid_page_state = [];
  grid_page_observer = new IntersectionObserver(
    (entry_list) => {
      // 1. 현재 보이는 그룹 저장

      each((entry) => {
        const page = $attr('data-page', entry.target);
        if (entry.isIntersecting) {
          grid_page_state.push(page);
        } else {
          const idx = findIndex(equals(page), grid_page_state);
          if (idx === -1) return;
          grid_page_state.splice(idx, 1);
        }
      }, entry_list);

      // 2. 현재 보이는 그룹이 제일 많은 그룹으로 page 설정
      const most_page = go(
        grid_page_state,
        groupBy(identity),
        entries,
        map(([page, pages]) => [page, pages.length]),
        reduce((prev, cur) => (prev[1] > cur[1] ? prev : cur)),
        head,
      );

      log('%c most page ', 'color: yellow', most_page, grid_page_state);

      replaceUrl(UtilF.updateUrlParams({ page: most_page == 0 ? null : most_page }));

      history.scrollRestoration = !safari && most_page > 0 ? 'manual' : 'auto';
    },
    {
      rootMargin: `0px 0px 0px 0px`,
    },
  );

  $addClass(grid_cl, grid_container_el);

  go(
    range(columns),
    zipWithIndexL,
    map(([idx]) =>
      createColumn(is_reverse, Array.isArray(item_margin) ? item_margin[0] : item_margin, idx == columns - 1),
    ),
    map($appendTo(grid_container_el)),
    each((column_el) => {
      column_el.height = 0;
      column_el.count = 0;
    }),
  );
};

export const onImageLoaded = curry(async (f, el) => {
  const img_els = $findAll('img', el);
  await go(
    img_els,
    mapC((img_el) => {
      if (img_el.complete) return;
      return new Promise((res, rej) => {
        img_el.onload = res;
        img_el.onerror = res; // image error 일때도 무한 스크롤 진행
      });
    }),
  );

  if (!img_els.length) return f();

  f();

  return el;
});

export const findShortColIdx = (cols, from_right, compare = from_right ? lt : lte) =>
  go(
    cols,
    zipWithIndexL,
    (zipped_col_els) =>
      reduce(
        ([idx1, prev], [idx2, cur]) => (compare(prev.height, cur.height) ? [idx1, prev] : [idx2, cur]),
        zipped_col_els,
      ),
    head,
  );

export const addGridItems = async (grid_container_el, item_els, { page, direction, group_prefix_html }) => {
  if (!checkGridInitialized(grid_container_el)) throw new Error(error_msgs.should_init('add item'));
  const is_top_direction = direction == 'top';

  const item_margin_bottom = Array.isArray(grid_container_el.item_margin)
    ? grid_container_el.item_margin[1]
    : grid_container_el.item_margin;

  const column_els = $findAll(`.${grid_col_cl}`, grid_container_el);
  const column_count = column_els.length;
  const hide_wrapper_els = go(
    column_els,
    map(() => createHideWrapper(page)),
    zipWithIndexL,
    map(([idx, el]) => {
      // el.height = 0;
      if (is_top_direction) $prependTo(column_els[idx], el);
      else $appendTo(column_els[idx], el);

      return el;
    }),
  );

  let top_col_idx = 0; // 위쪽에 붙일때 마지막으로 붙였던 칼럼 인덱스, 위에 붙이는건 역순으로
  let bottom_col_idx = column_count - 1;

  await go(
    item_els,
    ifElse(() => direction == 'top', reverse, identity),
    map(async (item_el) => {
      // const col_idx = findShortColIdx(column_els, direction == 'top');
      const col_idx = (() => {
        if (is_top_direction) {
          return (top_col_idx = (top_col_idx - 1 + column_count) % column_count);
        } else {
          return (bottom_col_idx = (bottom_col_idx + 1) % column_count);
        }
      })();
      // const col_el = column_els[col_idx];
      const hide_wrapper_el = hide_wrapper_els[col_idx];

      const item_wrapper_el = $el(
        html`<div class="${grid_item_wrapper_cl}" style="margin-bottom: ${item_margin_bottom}px"></div>`,
      );

      // group
      if (hide_wrapper_el.children.length == 0 && group_prefix_html)
        $appendTo(hide_wrapper_el, $el(group_prefix_html));

      $addClass(grid_item_cl, item_el);
      $appendTo(item_wrapper_el, item_el);
      (direction == 'top' ? $prependTo : $appendTo)(hide_wrapper_el, item_wrapper_el);
      // item_el.style.visibility = 'hidden';

      return onImageLoaded(() => {
        // col_el.height += item_el.offsetHeight;
        item_el.style.visibility = 'visible';
      }, item_el);
    }),
  );

  each((el) => grid_toggle_observer.observe(el), hide_wrapper_els);
  each((el) => grid_page_observer.observe(el), hide_wrapper_els);

  return hide_wrapper_els;
};

export const checkGridInitialized = (grid_container_el) => {
  return $hasClass(grid_cl, grid_container_el);
};

export const getAllGridWrappers = () => $qsa(`.${grid_hide_wrapper_hided}`);

export const calcColumnHeightDiff = (container_el, direction) => {
  const getDiff = (col_el, item_el) => {
    const selector = direction > 0 ? 'top' : 'bottom';
    return col_el.getBoundingClientRect()[selector] - item_el.getBoundingClientRect()[selector];
  };

  return go(
    container_el,
    $findAll(`.${grid_col_cl}`),
    map((col_el) => [
      col_el,
      direction > 0 ? col_el.children[0] : col_el.children[col_el.children.length - 1],
    ]),
    map(([col_el, item_el]) => ({
      col_el,
      diff: getDiff(col_el, item_el),
    })),
  );
};

export const adjustColumnDiff = (container_el, is_top) => {
  const diffs = calcColumnHeightDiff(container_el, is_top ? 1 : -1);
  go(
    diffs,
    map(({ col_el, diff }) => {
      anime({
        targets: col_el,
        translateY: diff,
        duration: 400,
        easing: 'linear',
      });
    }),
  );
};
