import { makeFakeMpMakerSize } from '../../../../../Maker/F/fake_mp_maker.js';
import {
  close_select_faceA,
  mpMakerSelectFaceDeactiveTippy,
  renderVisibleWhenCleared,
} from '../../../../../Maker/F/select_faces.js';
import { renderProductColorInProductDetailMobile } from '../../../../../Maker/F/maker_tmpl.js';
import { NewMakerLegacyF } from '../../../../../NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { ProductDetailTabsF } from '../../../../../ProductDetailTabs/F/Function/module/ProductDetailTabsF.js';
import { compact, html, each, go } from 'fxjs/es';
import { $show } from 'fxdom/es';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { NessCustomMakerF } from '../../../../F/Function/module/NessCustomMakerF.js';
import { getRealFcanvass } from '../../../../../Maker/F/getSth.js';

export function progress_move(on_animation = true) {
  const idx = G.mp.maker.editing_canvas_idx() || 0;
  const total = G.mp.maker.get_bpcfs_length();

  const progressBar = $('.fake_mp_maker .progress-bar');
  if (progressBar && total > 1) {
    const percentage = ((idx + 1) / total) * 100;
    if (on_animation) {
      $.css(progressBar, { width: `${percentage}%`, transition: 'width 0.2s linear' });
    } else {
      $.css(progressBar, { width: `${percentage}%`, transition: 'none' });
    }
  }
}

export function defineFakeMpMaker(etc_els) {
  $.frame.defn_frame({
    frame_name: 'mp.maker.fake.mobile',
    page_name: 'mp.maker.fake.mobile_page',
    // height: 0,
    animation: false,
    prev_frame_show: true,
    closing: __(
      function () {
        makeFakeMpMakerSize();
        G.mp.maker.unselect_all();
        G.product_detail_scroll_pass = true;
        mpMakerSelectFaceDeactiveTippy();
        if ($1('html.all_faces_mode')) {
          return close_select_faceA();
        }
      },
      function () {
        if (G.mp.maker.conditionCheckerInstance && G.mp.maker.conditionCheckerInstance.checkPass()) {
          go(
            getRealFcanvass(),
            each((fcanvas) => {
              renderVisibleWhenCleared(fcanvas);
            }),
          );
        }
        BpOptionF.makeSelectDisabled();
        renderProductColorInProductDetailMobile();
        G.mp.maker.remove_class_el_maker();
        _p.go(G.mp.maker.zoom.canvas_container_display_mode(), NewMakerLegacyF.swiperInit, function () {
          $.remove_class($1('html'), 'maker_editor_mode_for_canvas');
        });
      },
    ),
    appended: () => {
      ProductDetailTabsF.scrollDestroy();
    },
    closed: function () {
      if (MShopUtilF.isMobile()) {
        NessCustomMakerF.refreshCustomProductDetailHead();
      }
      if (typeof gtag == 'function') {
        const init_time = localStorage.getItem('init_time');
        if (init_time) {
          gtag('event', 'maker_dwell_time', {
            dwell_time: new Date().getTime() - init_time,
          });
        }
      }
      localStorage.removeItem('init_time');

      $.remove_class($1('html'), 'maker_editor_mode');
      $.don_loader_end();
      _p.go($('.canvas_man'), $.remove);
      _p.go($('.tip_box'), $.remove);

      return _p.go(
        ___,
        G.mp.maker.maker_mobile_close,
        function () {
          $.css($('#maker_frame'), {
            height: G.mp.maker.canvas_container_height,
          });
          go(etc_els, each($show));
          return anime({
            targets: compact(etc_els),
            duration: 500,
            opacity: 1,
          }).finished;
        },
        async function () {
          G.product_detail_scroll_pass = false;
        },
      );
    },
  });
  $.frame.defn_page({
    page_name: 'mp.maker.fake.mobile_page',
    el_class: 'page_in_mp_maker',
    tabs: [
      {
        tab_name: 'mp.maker.fake.mobile_tab',
        template: () => html` <div></div> `,
      },
    ],
  });
}
