import { sel } from 'fxjs/es';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { isNeedPreview } from '../../../../../Maker/S/categorize.js';
import { MShopAppUpItemS } from './module/MShopAppUpItemS.js';
/*
 * {
 * product_color:{product_faces2.can_not_change_color}
 * base_product:{name}
 * base_product_color:{name}
 * is_color_show: base_product_colors.length > 1
 * }
 *
 * */
export const makeMpUpSecondName = ({
  product_color,
  base_product,
  base_product_color,
  is_color_show,
  selected_option_group,
}) => {
  const can_not_change_color = Boolean(sel('product_faces2.can_not_change_color', product_color));
  const color_name = base_product_color['name' + G._en];
  const option_title = selected_option_group?.title;
  const bp_name = base_product['name_creator' + G._en];
  return `${bp_name}${
    !is_color_show || can_not_change_color || isNeedPreview(base_product) ? '' : ` [${color_name}]`
  }${option_title ? ` - ${option_title}` : ''}`;
};

/**
 * ness 리뷰 상세에 유저가 구매한 상품 옵션이름으로 들어간다. 일단 카트 옵션명을 따라가지만 추후 고도화 해야한다.
 * @param base_product_size
 * @param user_product
 * @returns {string}
 */
export const makeUpOptionName = (user_product) => {
  const goods_type_id = user_product?.goods_type_id;
  if (!goods_type_id) return '';

  if (GoodsTypeS.isMps(goods_type_id)) {
    return user_product._.base_product_size['name' + G._en] || '';
  }

  const { group_names_option_names } = user_product._.spo_item;
  return MShopAppUpItemS.pMakeOptionInfo(group_names_option_names) || '';
};
