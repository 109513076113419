import dataStr from 'fxdom/es/dataStr.js';
import { html } from 'fxjs/es';
import { UtilS } from '../../../../../../../Util/S/Function/module/UtilS.js';

export const fn = ({ store }) => {
  const default_thumb =
    '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png';

  const txt1 = ET('mps2::cheerup::profile_txt1');
  const txt2 = ET('mps2::cheerup::profile_txt2');
  const txt3 = ET('mps2::cheerup::profile_txt3');
  const btn_txt2 = ET('mps2::cheerup::btn_txt2');

  return html`
    <form class="about-form" name="about-form" data-fx-json="${dataStr(store)}">
      <div class="about-form__thumb">
        <div class="about-form__thumb-img">
          <img src="${store.thumbnail_img || default_thumb}" />
          <label class="about-form__edit-thumb">
            <span class="about-form__edit-thumb-btn">${txt1}</span>
            <input type="file" name="thumb" class="hidden" accept="image/jpg, image/jpeg, image/png" />
            <input type="hidden" name="thumbnail_img" value="${store.thumbnail_img || ''}" />
          </label>
        </div>
        <p class="about-form__thumb-p">${txt2}</p>
      </div>
      <div class="about-form__sns">
        <label class="about-form__label">${T('s_about::sns_label')}</label>
        <input
          type="text"
          class="about-form__control"
          name="sns_url"
          value="${store.sns_url ?? ''}"
          placeholder="${T('s_about::sns_holder')}"
        />
      </div>
      <div class="about-form__desc">
        <label class="about-form__label">${T('s_about::desc_label')}</label>
        <textarea
          type="textarea"
          class="about-form__control"
          name="description"
          maxlength="150"
          placeholder="${txt3}"
        >
${store.description ?? ''}</textarea
        >
        <span class="about-form__desc-length">
          <span class="about-form__desc-length-count">${UtilS.checkStringLength(store.description)}</span>
          / 150${ET.lang == 'kr' ? ' 자' : ''}
        </span>
      </div>
      <div class="about-form__submit">
        <button type="submit">${btn_txt2}</button>
      </div>
    </form>
  `;
};
