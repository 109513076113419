import { getCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';

function targetAreaInCvObject(cv_target, target_area) {
  const cv_target_area = getCvObj(cv_target.canvas._objects, target_area);
  if (typeof cv_target_area?.isContainedWithinObject !== 'function') return;
  return cv_target_area.isContainedWithinObject(cv_target);
}
function cvObjectInTargetArea(cv_target, target_area) {
  const cv_target_area = getCvObj(cv_target.canvas._objects, target_area);
  if (typeof cv_target_area?.isContainedWithinObject !== 'function') return;
  return cv_target.isContainedWithinObject(cv_target_area);
}

export const checkContainPrintArea = (cv_target) => targetAreaInCvObject(cv_target, 'cv_print_area');

export const checkContainObjectInSafetyArea = (cv_target) =>
  cvObjectInTargetArea(cv_target, 'cv_safety_area');

export const checkContainObjectInPrintArea = (cv_target) => cvObjectInTargetArea(cv_target, 'cv_print_area');
