import {
  $addClass,
  $attr,
  $closest,
  $delegate,
  $find,
  $findAll,
  $qs,
  $remove,
  $removeClass,
  $setAttr,
  $setText,
  $el,
  $on,
  $trigger,
  $qsa,
} from 'fxdom/es';
import { go, ifElse, sel, tap, html, values, every, equals2, head, identity, each } from 'fxjs/es';
import { rune } from 'rune-ts';
import { ProductBadgeList } from '../../../../../services/MarppleShop/renderApp/components/cells/ProductBadgeList/ProductBadgeList.ts';
import {
  DeleteOptionSetEvent,
  ProductMultiOptionSelector,
} from '../../../../../services/MarppleShop/renderApp/components/cells/ProductOptionQuantitySelector/ProductMultiOptionSelector.ts';
import { ButtonClose } from '../../../../../services/MarppleShop/shared/components/atoms/ButtonClose/ButtonClose.ts';
import { parseCustomEvent } from '../../../../../services/MarppleShop/shared/util/helper.ts';
import { CreatorCriteoF } from '../../../../Creator/Criteo/F/Function/module/CreatorCriteoF.js';
import { getAvailableColors } from '../../../../Creator/Setting/S/fs.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MpShopProductS } from '../../../../MpShop/Product/S/Function/module/MpShopProductS.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { NessInboundF } from '../../../../Ness/Inbound/F/Function/module/NessInboundF.js';
import { NessOutboundF } from '../../../../Ness/Outbound/F/Function/module/NessOutboundF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { initTooltips } from '../../../../Util/F/tooltip.js';
import { UtilInfiniteF } from '../../../../Util/Infinite/F/Function/module/UtilInfiniteF.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../Util/S/Function/util.js';
import { MShopStudioDigitalProductCreatorF } from '../../../Studio/DigitalProductCreator/F/Function/module/MShopStudioDigitalProductCreatorF.js';
import { MShopUtilF } from '../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppCommunityF } from '../../Community/F/Function/module/MShopAppCommunityF.js';
import { MShopAppCommunityConstantS } from '../../Community/S/Constant/module/MShopAppCommunityConstantS.js';
import { MShopAppCommunityTmplS } from '../../Community/S/Tmpl/module/MShopAppCommunityTmplS.js';
import { MShopAppProductPbOptionF } from '../../Product/PbOption/F/Function/module/MShopAppProductPbOptionF.js';
import { MShopAppReportF } from '../../Report/F/Function/module/MShopAppReportF.js';
import { MShopAppStoreQuestionF } from '../../Store/Question/F/Function/module/MShopAppStoreQuestionF.js';
import { MShopAppTokenGateStoresProductF } from '../../TokenGate/StoresProduct/F/Function/module/MShopAppTokenGateStoresProductF.js';
import { makeProductSellingTimeHtml } from '../S/Tmpl/partials/productDetailForm.js';
import { MShopAppProductDetailZoomMuiF } from '../Zoom/F/Mui/module/MShopAppProductDetailZoomMuiF.js';
import {
  addClassImage,
  computePrice,
  convertAudioTime,
  handleChangeQuantity,
  handleClickButtonClose,
  handleClickLikeReview,
  handleClickReviewsMore,
  handleFocusOutButtonClose,
  handleFormSubmit,
  handleInputChange,
  handleInputChangeNess,
  handleInputChangeTShirts,
  handleInputTextArea,
  handleMouseDownProgress,
  handleMultiOptionCloseButton,
  handleOpenSizeGuideModal,
  handlePackageFormSubmit,
  handlePlayerButton,
  handlePlayerTime,
  handleProductLinkShare,
  handleSpoMultiOption,
  handleSpoTextOption,
  handleTabChange,
  handleTabChangeNess,
  handleToggleFaqNess,
  handleToggleInfoSection,
  handleToggleLike,
  handleToggleLikeNess,
  handleTogglePurchaseInfo,
  handleTouchDownProgress,
  handlOpenQualityGuideModal,
  handlOpenQualityGuideModalNess,
  handlOpenRetractionModal,
  handlOpenRetractionModalNess,
  initBaseProductSwiper,
  initBaseProductSwiperNess,
  resetLikeBackForward,
  swiperProductDetailPreview,
  swiperProductDetailPreviewMo,
  swiperProductDetailPreviewMoNess,
  swiperProductDetailPreviewNess,
} from './Mui/handles.js';

export function checkFoldedNess(on_resize = false) {
  const is_mobile = box.sel('is_mobile');
  const dynamic_html_el = $qs('.pd-field.colors .dynamic-html');

  if (!dynamic_html_el) return;

  const el = $qs(is_mobile ? '.pd-contents__colors' : '.pd-form__colors');
  const color_list_el = $qs('.pd-radio-group.color');
  const product_detail = box.sel('product_detail');
  const base_product_colors = sel('base_product._.base_product_colors', product_detail);
  const store_product_colors = sel('stores_product._.store_product_colors', product_detail);
  const colors = getAvailableColors(base_product_colors, store_product_colors);

  const width = el.getBoundingClientRect().width;
  const style = window.getComputedStyle(color_list_el);
  const gap = parseFloat(style.getPropertyValue('column-gap'));
  const item_width = $qs('.pd-radio.color', el).getBoundingClientRect().width + gap;
  const max_count = Math.floor((width + gap) / item_width) * (is_mobile ? 1 : 2);
  const is_folded = colors?.length > max_count;

  go(
    dynamic_html_el,
    tap((el) => {
      if (on_resize) return;
      ifElse(() => is_folded, $addClass('folded'), $removeClass('folded'))(el);
    }),
    $setAttr({ max_count }),
  );
}

export const appendedFnNess = (tab_el) => {
  if (!MShopUtilF.isCreatorAndroidApp() && $find('.pd__action-btn.report', tab_el))
    $remove($find('.pd__action-btn.report', tab_el));

  const is_mobile = MShopUtilF.isMobile();

  window.onresize = () => checkFoldedNess(true);

  if (!is_mobile) {
    UtilF.initBackForward(() => {
      computePrice();
    });
  }

  try {
    NessInboundF.delegateUpdateProductLike(tab_el);
    NessOutboundF.initTopArrowButton();

    return go(
      tab_el,

      // 우클릭 및 이미지 다운로드 금지
      tap(() => {
        if (box.sel('is_user->_->crew->id')) return;
        document.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
        addClassImage($qs('#product_detail'));
      }),

      // 상품 프리뷰 스와이프
      tap(is_mobile ? swiperProductDetailPreviewMoNess : swiperProductDetailPreviewNess),

      // 기본상품 프리뷰 스와이프
      tap(initBaseProductSwiperNess),

      // 리뷰 무한스크롤
      tap(async (tab_el) => {
        const review_cont_el = $find('#pd-review', tab_el);
        if (!review_cont_el) return;
        const review_list_el = $find('.pd-review__list', review_cont_el);

        const api_url = makeApiUrl(`/${T.lang}/@api/products/:product_id/reviews`, {
          product_id: box.sel('product_detail->original_product_id'),
        });

        // eslint-disable-next-line no-undef
        UtilInfiniteF.init(
          MShopAppCommunityF.makeInfiniteOptions({
            limit: is_mobile ? 4 : 6,
            api_url,
            container_el: review_list_el,
            is_mobile,
            column_count: 1,
            is_ie: window.is_ie,
            initial_load: 1,
            makeItemFunc: (article) => {
              return MShopAppCommunityTmplS.pdReviewItemNess({
                article,
                store: sel('_.store', article),
                is_ie: window.is_ie,
                is_mobile,
              });
            },
            hooks: {
              onEmpty: () => {
                const emptyEl = html`
                  <div class="pd-review__empty">
                    <h4>${ET('product_detail::review::empty_title')}</h4>
                    <p>${ET('product_detail::review::empty_text')}</p>
                  </div>
                `;
                review_cont_el.innerHTML = emptyEl;
              },
            },
            pause: {
              initial: true,
              play_btn_el: $el(html`<div class="pd-review__more">${await T('mshop::더보기')}</div>`),
              no_blank_space: false,
            },
          }),
        );
      }),

      tap(async () => {
        const preview_signed_url = box.sel('product_detail->dtg_resources_info')?.preview_signed_url || null;
        if (preview_signed_url) {
          const $preview = $find('.preview', tab_el);
          const audio_duration = await MShopStudioDigitalProductCreatorF.$addSrcToPreviewPlayer(
            $preview,
            preview_signed_url,
          );
          go($preview, $find('.duration'), $setText(convertAudioTime(audio_duration)));
        }
      }),

      // 남은시간 카운트다운
      tap(() => {
        const $noti = $find('.pd-noti', tab_el);
        if (!$noti) return;

        const { has_period, has_quantity } = $noti.dataset;
        const $remain_time = $find('.remain_time', tab_el);
        if (!has_period || !$remain_time) return;

        const { start_at, end_at } = $remain_time.dataset;
        const is_start_selling = +new Date() > +new Date(start_at);

        let prev;

        const timer = setInterval(() => {
          const count = parseInt($attr('interval-count', $noti), 10);
          const is_period_mode = $attr('current-mode', $noti) === 'period';
          const is_two_times = count > 0 && count % 4 === 0;

          const remain_time_infos = UtilS.differenceTimeInUnits(
            ['day', 'hour', 'minute', 'second'],
            is_start_selling ? new Date(end_at) : new Date(start_at),
            new Date(),
            true,
          );

          const html = makeProductSellingTimeHtml(remain_time_infos);

          if (prev == html) {
            $setAttr({ 'interval-count': count + 1 }, $noti);
            return;
          }

          prev = $remain_time.innerHTML = html;
          const done = go(remain_time_infos, values, every(equals2(0)));
          if (done) return clearInterval(timer), setTimeout(() => location.reload(), 50);

          if (is_two_times && has_quantity === 'true') {
            $setAttr(
              {
                'current-mode': is_period_mode ? 'quantity' : 'period',
                'interval-count': 1,
              },
              $noti,
            );
          } else {
            $setAttr({ 'interval-count': count + 1 }, $noti);
          }
        }, 1000);
      }),

      // 광고 분석 스크립트 실행
      tap(() => {
        if (window.criteo_q && T.lang == 'kr')
          CreatorCriteoF.viewItem({
            id:
              box.sel('product_detail->stores_product->original_product_id') ||
              box.sel('product_detail->stores_product->product_id'),
          });
        // updateCompositeThumbnails(box.sel('product_detail->product_color'));
      }),

      // color 확장
      $delegate('click', '.expand-btn', (e) => $removeClass('folded', $qs('.pd-field.colors .dynamic-html'))),

      // Tab 변경 :: done
      $delegate('click', '.pd-tabs__item', handleTabChangeNess),

      // 청약철회 가이드 모달 토글 :: done
      $delegate('click', '.anc_retraction_guide', () => {
        return handlOpenRetractionModalNess(box()?.crew_id);
      }),

      // 품질보증 가이드 모달 토글 :: done
      $delegate('click', '.quality_guide', () => {
        return handlOpenQualityGuideModalNess(box()?.crew_id);
      }),

      // FAQ 토글 :: done
      $delegate('click', '.pd-faq__qna-q', handleToggleFaqNess),

      // Like 토글 :: todo
      $delegate('click', '.pd__action-btn.like', handleToggleLikeNess),

      // 링크 공유 :: todo
      $delegate('click', '.pd__action-btn.share', handleProductLinkShare),

      // 현대샵이 아닌 경우에 인풋 체인지 이벤트
      $delegate('change', '[is-package="false"] .pd-field [name]', handleInputChangeNess),

      // 사이즈 가이드 모달 토글 :: done
      $delegate('click', '.toggle-size-modal', handleOpenSizeGuideModal),

      // 수량 버튼 클릭 :: done
      $delegate('click', '.pd-counter__btn', handleChangeQuantity),

      // nft 인증
      $delegate('click', '[is-package="false"] .token-gate__btn', (e) => {
        return MShopAppTokenGateStoresProductF.certifyNft(box.sel('product_detail->stores_product'), e);
      }),

      // 현대샵이 아닌때 장바구니 버튼 클릭 :: done
      $delegate('click', '[is-package="false"] .pd-submit__btn', handleFormSubmit),

      // 현대샵인 경우에 티셔츠 변경 이벤트
      $delegate('change', '[is-package="true"] [name="t_shirts"]', handleInputChangeTShirts),

      // 현대샵인 경우 장바구니 버튼 :: done
      $delegate('click', '[is-package="true"] .pd-submit__btn', handlePackageFormSubmit),

      // 상품 리뷰 상세 보기
      $delegate('click', '.pd-review-item', (e) => {
        const article_el = e.currentTarget;
        const article_id = $attr('article_id', article_el);
        const review_list_el = $closest('.pd-review-list', article_el);

        MShopAppCommunityF.openDetailNess({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: review_list_el ? [article_el] : [],
          no_replace_url: true,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: false, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      // 상품 리뷰 이미지 클릭 이벤트
      $delegate('click', '.pd-review-image-item', (e) => {
        const article_id = e.currentTarget.dataset.articleId;

        MShopAppCommunityF.openDetailNess({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: [],
          no_replace_url: true,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: false, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      $delegate('click', '.pd-review__images-more', (e) => {
        const first_pd_review__image_el = head($findAll('.pd-review-image-item', e.delegateTarget));
        const article_id = first_pd_review__image_el.dataset.articleId;

        MShopAppCommunityF.openDetailNess({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: [],
          no_replace_url: true,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: true, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      // 신고
      $delegate('click', '.pd__action-btn.report', MShopAppReportF.openFrame('product')),
      $delegate('click', '.pd-links__item-qna', () => {
        const product_id = box.sel('product_detail->stores_product->product_id');
        const store_id = box.sel('store_id');
        MShopAppStoreQuestionF.openNess({ user: box.sel('is_user'), product_id, store_id });
      }),

      // pb 옵션 체인지 :: check
      $delegate('change', '.pb-options__options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pSelectEventNess(spo, changed_el);
        $trigger('change', $qs('[name="quantity"]'));
      }),
      $delegate('change', '.pd__pb_custom_options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pCustomSelectEvent(spo, changed_el);
      }),
      $delegate('click', '.preview__play-buttons', handlePlayerButton),

      ifElse(
        () => MShopUtilF.isMobile(),
        $delegate('touchstart', '.preview__progress-container', handleTouchDownProgress),
        $delegate('mousedown', '.preview__progress-container', handleMouseDownProgress),
      ),

      $on('timeupdate', '.preview__player', handlePlayerTime),

      is_mobile
        ? $delegate('click', '.pd-preview__list .mp-product-img', async (e) =>
            MuiF.openFrame(MShopAppProductDetailZoomMuiF.frame, (f, p, [t]) => {
              t.makeData = () => ({
                img: go($el(e.currentTarget.outerHTML), (img) => {
                  img.src = MpShopProductS.setOnDemandJpegUrl({
                    url: e.currentTarget.src,
                    quality: 100,
                    bg: 'f6f6f6',
                  });
                  $addClass('pd-zoom__img', img);
                  return img.outerHTML;
                }),
              });
            }),
          )
        : identity,
      () => {
        initTooltips(tab_el, {
          placement: 'bottom',
          offset: undefined,
          animation: 'shift-toward',
          maxWidth: 400,
        });
      },
      () => {
        const checked_option_el = $qs('.pd-select option:checked');
        const selected_option_el = $qs('.pd-select option[selected]');
        if (checked_option_el != selected_option_el) $trigger('change', checked_option_el);
        const spo = box.sel('product_detail->spo');
        if (spo?._?.spo_options?.length) {
          const pb_options_el = $qs('.pd__pb_options');
          if (pb_options_el) {
            MShopAppProductPbOptionF.pSelectPageInitNess({ spo, pb_options_el });
          }
        }
        return resetLikeBackForward();
      },

      // 페이지 진입 시 제품 색상변경 이벤트 실행 ??
      tap(() => {
        // 셀러가 선택한 색상이 base_product.base_product_colors에 더 이상 존재하지 않을 수 있음.
        const {
          base_product,
          stores_product: {
            goods_type_id,
            _: { store_product_colors },
          },
          product_color: { base_product_color_id, product_faces2 },
        } = box.sel('product_detail');

        const base_product_colors = sel('_.base_product_colors', base_product);
        const available_colors = getAvailableColors(base_product_colors, store_product_colors);

        if (GoodsTypeS.isMps(goods_type_id) && !available_colors.length) {
          alert(T('error::This product is out of stock.'));
          return location.replace(`/${T.lang}/${box.sel('domain_name')}`);
        }

        const checked_color_el = $qs('[name="color"]:checked');

        if (
          GoodsTypeS.isNotPod(goods_type_id) ||
          product_faces2.can_not_change_color ||
          Number(checked_color_el?.value) === base_product_color_id
        ) {
          // box color랑 선택된 input에 select된 것이 같으면 그냥 리턴
          return;
        }

        if (checked_color_el) {
          // 선택된 컬러가 존재하면 트리거로 선택해주기
          $trigger('change', checked_color_el);
        }
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

export const appendedFn = (tab_el) => {
  if (UtilS.isNessApp()) {
    return appendedFnNess(tab_el);
  }

  // TODO @yjj Ness 머지작업이 모두 끝난게 확정나면 Ness관련 코드 정리하기
  if ($qs('.private-product-detail')) return;

  if (!MShopUtilF.isCreatorAndroidApp() && $find('.pd__action-btn.report', tab_el))
    $remove($find('.pd__action-btn.report', tab_el));

  const is_mobile = MShopUtilF.isMobile();
  const is_ness = UtilS.isNessApp();

  // window.onresize = () => checkFolded(true);

  if (!is_mobile) {
    UtilF.initBackForward(() => {
      computePrice();
    });
  }

  try {
    const product_price = (() => {
      return box.sel('product->price' + _en) + box.sel('product->profit' + _en);
    })();

    if (typeof gtag == 'function') {
      const ga_view_item = {
        dynx_itemid: box.sel('product->id'), // 상품 코드
        is_login_user: Boolean(box.sel('is_user')),
        dynx_itemid2: box.sel('product->id'), // 상품 코드
        dynx_pagetype: 'offerdetail',
        dynx_totalvalue: product_price, // 상품 금액
        ecomm_prodid: box.sel('product->id'),
        ecomm_pagetype: 'offerdetail',
        event_label: 'offerdetail',
        items: [
          {
            id: box.sel('product->id'),
            name: box.sel('product->name' + _en) || box.sel('product->_->base_product->name' + _en),
            list_name: '상품 상세',
            item_brand: box.sel('store->name'),
            list_position: 1,
            quantity: 1,
            price: product_price,
          },
        ],
      };

      gtag('event', 'view_item', ga_view_item);
    }
  } catch (_err) {}

  try {
    if (is_ness) {
      NessInboundF.delegateUpdateProductLike(tab_el);
      NessOutboundF.initTopArrowButton();
    }

    return go(
      tab_el,

      // 우클릭 및 이미지 다운로드 금지
      tap(() => {
        if (box.sel('is_user->_->crew->id')) return;
        document.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
        addClassImage($qs('#product_detail'));
      }),

      // 상품 프리뷰 스와이프
      tap(is_mobile ? swiperProductDetailPreviewMo : swiperProductDetailPreview),

      // 기본상품 프리뷰 스와이프
      tap(initBaseProductSwiper),

      // 리뷰 무한스크롤
      tap(async (tab_el) => {
        const review_cont_el = $find('#pd-review', tab_el);
        if (!review_cont_el) return;
        const review_list_el = $find('.pd-review__list', review_cont_el);
        const api_url = makeApiUrl(`/${T.lang}/@api/products/:product_id/reviews`, {
          product_id: box.sel('product_detail->original_product_id'),
        });
        const is_ness = UtilS.isNessApp();

        UtilInfiniteF.init(
          MShopAppCommunityF.makeInfiniteOptions({
            limit: is_mobile ? 4 : 6,
            api_url,
            container_el: review_list_el,
            is_mobile,
            column_count: 1,
            is_ie: window.is_ie,
            initial_load: 1,
            makeItemFunc: (article) => {
              return MShopAppCommunityTmplS.pdReviewItem({
                article,
                store: sel('_.store', article),
                is_ie: window.is_ie,
                is_mobile,
              });
            },
            hooks: {
              onEmpty: () => {
                const emptyEl = html`
                  <div class="pd-review__empty">
                    <h4>
                      ${is_ness
                        ? ET('product_detail::review::empty_title')
                        : ET('product_detail::review::empty_title_mps')}
                    </h4>
                    <p>
                      ${is_ness
                        ? ET('product_detail::review::empty_text')
                        : ET('product_detail::review::empty_text_mps')}
                    </p>
                  </div>
                `;
                review_list_el.innerHTML = emptyEl;
              },
            },
            pause: {
              initial: false,
              play_btn_el: $el(html`<div class="pd-review__more">${await T('mshop::더보기')}</div>`),
              no_blank_space: false,
            },
          }),
        );
      }),

      tap(async () => {
        const preview_signed_url = box.sel('product_detail->dtg_resources_info')?.preview_signed_url || null;
        if (preview_signed_url) {
          const $preview = $find('.preview', tab_el);
          const audio_duration = await MShopStudioDigitalProductCreatorF.$addSrcToPreviewPlayer(
            $preview,
            preview_signed_url,
          );
          go($preview, $find('.duration'), $setText(convertAudioTime(audio_duration)));
        }
      }),

      // 탭 컨텐츠 관련 이벤트
      // defnProductDetailFooterEvent,

      // 남은시간 카운트다운
      tap(() => {
        const $noti = $find('.pd-noti', tab_el);
        if (!$noti) return;

        const { current, startAt: start_at, endAt: end_at } = $noti.dataset;
        const $remain_time = $find('.remain_time', $noti);
        const is_start_selling = +new Date() > +new Date(start_at);

        let prev;

        if (current === 'period') {
          const setIntervalFn = () => {
            const remain_time_infos = UtilS.differenceTimeInUnits(
              ['day', 'hour', 'minute', 'second'],
              is_start_selling ? new Date(end_at) : new Date(start_at),
              new Date(),
              true,
            );

            const is_done = go(remain_time_infos, values, every(equals2(0)));

            const html = is_start_selling
              ? ET('product_detail::left_time', {
                  time: makeProductSellingTimeHtml(remain_time_infos),
                })
              : ET('product_detail::coming_soon', {
                  time: makeProductSellingTimeHtml(remain_time_infos),
                });
            prev = $remain_time.innerHTML = html;

            if (is_done) {
              setTimeout(function () {
                window.location.reload();
              }, 10);
            }
          };
          const productBadgeListView = rune.getView($qs(`.${ProductBadgeList}`), ProductBadgeList);
          const interval_timer_ids =
            productBadgeListView && productBadgeListView.startBadgesTimer(setIntervalFn);

          if (!interval_timer_ids?.length) {
            setInterval(setIntervalFn, 1000);
          }
        }
      }),

      // 광고 분석 스크립트 실행
      tap(() => {
        if (window.criteo_q && T.lang == 'kr')
          CreatorCriteoF.viewItem({
            id:
              box.sel('product_detail->stores_product->original_product_id') ||
              box.sel('product_detail->stores_product->product_id'),
          });
        // updateCompositeThumbnails(box.sel('product_detail->product_color'));
      }),

      // color 확장
      $delegate('click', '.expand-btn', (e) => $removeClass('fold', $qs('.pd-field.colors'))),

      // Tab 변경 :: done
      $delegate('click', '.pd-tabs__item', handleTabChange),

      // 청약철회 가이드 모달 토글 :: done
      $delegate('click', '.anc_retraction_guide', () => {
        return handlOpenRetractionModal(box()?.crew_id);
      }),

      // 품질보증 가이드 모달 토글 :: done
      $delegate('click', '.quality_guide', () => {
        return handlOpenQualityGuideModal(box()?.crew_id);
      }),

      // // FAQ 토글 :: done
      // $delegate('click', '.pd-faq__qna-q', handleToggleFAQ),

      // FAQ 토글 :: done
      $delegate('click', '.pd-purchase-info__header', handleTogglePurchaseInfo),

      // Like 토글
      $delegate('click', '.pd__action-btn.like', handleToggleLike),

      // 링크 공유
      $delegate('click', '.pd__action-btn.share', handleProductLinkShare),

      // 현대샵이 아닌 경우에 인풋 체인지 이벤트
      $delegate('change', '[is-package="false"] .pd-field [name]', handleInputChange),

      // 사이즈 가이드 모달 토글 :: done
      $delegate('click', '.toggle-size-modal', handleOpenSizeGuideModal),

      // 수량 버튼 클릭
      $delegate('click', '.pd-counter__btn', handleChangeQuantity),

      // nft 인증
      $delegate('click', '[is-package="false"] .token-gate__btn', (e) => {
        return MShopAppTokenGateStoresProductF.certifyNft(box.sel('product_detail->stores_product'), e);
      }),

      // 현대샵이 아닌때 장바구니 버튼 클릭
      $delegate('click', '[is-package="false"] .pd-submit__btn', handleFormSubmit),

      // 현대샵인 경우에 티셔츠 변경 이벤트
      $delegate('change', '[is-package="true"] [name="t_shirts"]', handleInputChangeTShirts),

      // 현대샵인 경우 장바구니 버튼
      $delegate('click', '[is-package="true"] .pd-submit__btn', handlePackageFormSubmit),

      $delegate('click', '.pd-info-group__btn', handleToggleInfoSection),

      // 상품 리뷰 상세 보기
      $delegate('click', '.pd-review-item__info, .pd-review-item__content', (e) => {
        if ($closest(`.${MShopAppCommunityConstantS.CREATOR_SHOP_LINK_KLASS}`, e.target)) {
          return;
        }

        const article_el = $closest('.pd-review-item', e.currentTarget);
        const article_id = $attr('article_id', article_el);
        const review_list_el = $closest('.pd-review-list', article_el);
        const is_ness = UtilS.isNessApp();

        // TODO NESS 분기를 이런식으로 유지할지 판단해야한다.
        MShopAppCommunityF.openDetail({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: review_list_el ? [article_el] : [],
          no_replace_url: true,
          is_ness,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: false, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      $delegate('click', '.article-item-control__btn_like', handleClickLikeReview),

      // 상품 리뷰 이미지 클릭 이벤트
      $delegate('click', '.pd-review-image-item', (e) => {
        const article_id = e.currentTarget.dataset.articleId;
        const is_ness = UtilS.isNessApp();

        MShopAppCommunityF.openDetail({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: [],
          no_replace_url: true,
          is_ness,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: false, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      $delegate('click', '.pd-review__images-more', (e) => {
        const first_pd_review__image_el = head($findAll('.pd-review-image-item', e.delegateTarget));
        const article_id = first_pd_review__image_el.dataset.articleId;
        const is_ness = UtilS.isNessApp();

        MShopAppCommunityF.openDetail({
          article_id,
          parent_cont_el: e.delegateTarget,
          parent_items: [],
          no_replace_url: true,
          is_ness,
          is_product_review: true, // 상품상세에서 띄운 것인지 판단
          is_list: true, // 상품상세 More 버튼을 눌러서 띄운 것인지 판단
        });
      }),

      // 신고
      $delegate('click', '.pd__action-btn.report', MShopAppReportF.openFrame('product')),

      $delegate('click', '.pd-links__item-qna', () => {
        const product_id = box.sel('product_detail->stores_product->product_id');
        const store_id = box.sel('store_id');
        MShopAppStoreQuestionF.open({ user: box.sel('is_user'), product_id, store_id });
      }),

      // pb 옵션 체인지 :: check
      $delegate('change', '.pd-form__pb_options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pSelectEvent(spo, changed_el);
        const quantity_els = $qsa('[name="quantity"]');
        if (quantity_els.length) {
          each($trigger('change', $qs('[name="quantity"]')), quantity_els);
        }
      }),

      $delegate('change', '.pd-form__pb_custom_options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pCustomSelectEvent(spo, changed_el);
      }),

      $delegate('click', '.preview__play-buttons', handlePlayerButton),
      ifElse(
        () => MShopUtilF.isMobile(),
        $delegate('touchstart', '.preview__progress-container', handleTouchDownProgress),
        $delegate('mousedown', '.preview__progress-container', handleMouseDownProgress),
      ),

      $delegate('click', '.pd-product__review__count', handleClickReviewsMore),

      $delegate('input', '.spo_text_option', handleInputTextArea),
      $delegate('click', '.kc-cert__info-link a', (e) => {
        e.originalEvent.preventDefault();
        window.open('', '_blank').document.write(html`
          <form
            id="hiddenForm"
            action="https://www.safetykorea.kr/release/certificationsearch"
            method="POST"
          ></form>
          <script>
            document.getElementById('hiddenForm').submit();
          </script>
        `);
      }),
      $on('timeupdate', '.preview__player', handlePlayerTime),

      /* multi option 관련 이벤트 */
      $delegate('input', 'select.spo_option', handleSpoMultiOption),
      $delegate('focusout', 'input.spo_text_option', handleSpoMultiOption),
      $delegate(
        parseCustomEvent(DeleteOptionSetEvent),
        `.${ProductMultiOptionSelector}`,
        handleMultiOptionCloseButton,
      ),

      $delegate('focusin', 'input.spo_text_option', handleSpoTextOption),
      $delegate('input', 'input.spo_text_option', handleSpoTextOption),
      $delegate('keydown', 'input.spo_text_option', (e) => {
        if (e.key === 'Enter') {
          e.originalEvent.preventDefault();
        }
      }),
      $delegate('focusout', 'input.spo_text_option', handleFocusOutButtonClose),
      $delegate('mousedown', `.${ButtonClose}`, handleClickButtonClose),

      is_mobile
        ? $delegate('click', '.pd-preview__list .mp-product-img', async (e) =>
            MuiF.openFrame(MShopAppProductDetailZoomMuiF.frame, (f, p, [t]) => {
              t.makeData = () => ({
                img: go($el(e.currentTarget.outerHTML), (img) => {
                  img.src = MpShopProductS.setOnDemandJpegUrl({
                    url: e.currentTarget.src,
                    quality: 100,
                    bg: 'f6f6f6',
                  });
                  $addClass('pd-zoom__img', img);
                  return img.outerHTML;
                }),
              });
            }),
          )
        : identity,
      tap(() => {
        initTooltips(tab_el, {
          placement: 'bottom',
          offset: undefined,
          animation: 'fade',
          maxWidth: 400,
          arrow: false,
          hideOnClick: true,
          trigger: 'click',
          onMount(instance) {
            const wrapper = $qs('.tooltip-container');
            const close = () => {
              instance.hide();
              wrapper.removeEventListener('click', close);
            };
            wrapper.addEventListener('click', close);
          },
        });
      }),
      tap(() => {
        const checked_option_el = $qs('.pd-select option:checked');
        const selected_option_el = $qs('.pd-select option[selected]');
        const options_el = !is_mobile ? $qs('.pd-form__options') : $qs('.pd-contents__options');
        const is_disabled = options_el.classList.contains('disabled');
        if (checked_option_el != selected_option_el) $trigger('change', checked_option_el);
        const spo = box.sel('product_detail->spo');

        if (spo?._?.spo_options?.length) {
          const pb_options_el = $qs('.pb-options__options');
          if (pb_options_el) {
            MShopAppProductPbOptionF.pSelectPageInit({ spo, pb_options_el, disabled: is_disabled });
          }
        }
        return resetLikeBackForward();
      }),
      // 페이지 진입 시 제품 색상변경 이벤트 실행 ??
      tap(() => {
        // 셀러가 선택한 색상이 base_product.base_product_colors에 더 이상 존재하지 않을 수 있음.
        const {
          base_product,
          stores_product: {
            goods_type_id,
            _: { store_product_colors },
          },
          product_color: { base_product_color_id, product_faces2 },
        } = box.sel('product_detail');

        const base_product_colors = sel('_.base_product_colors', base_product);
        const available_colors = getAvailableColors(base_product_colors, store_product_colors);

        if (GoodsTypeS.isMps(goods_type_id) && !available_colors.length) {
          alert(T('error::This product is out of stock.'));
          return location.replace(`/${T.lang}/${box.sel('domain_name')}`);
        }

        const checked_color_el = $qs('[name="color"]:checked');

        if (
          GoodsTypeS.isNotPod(goods_type_id) ||
          product_faces2.can_not_change_color ||
          Number(checked_color_el?.value) === base_product_color_id
        ) {
          // box color랑 선택된 input에 select된 것이 같으면 그냥 리턴
          return;
        }

        if (checked_color_el) {
          // 선택된 컬러가 존재하면 트리거로 선택해주기
          $trigger('change', checked_color_el);
        }
      }),
    );
  } catch (e) {
    console.error(e);
  }
};
