// 상품상세 - 스토리
import { go, html, sel, strMap } from 'fxjs/es';
import { makeProductStoryImageHtml } from '../../../../../../Creator/ProductStory/S/tmpl.js';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
// import { makeYoutubeEmbedUrl } from '../../../../../../Creator/Crew/BannerEditor/S/Function/util.js';

const get = (key, obj) => sel(key + _en, obj);

const makeYoutubeEmbedUrl = (url = '') => {
  const embed_url = 'https://www.youtube.com/embed/';
  const embed_regexp = /embed\/+/;
  const match_regexp = /watch\?v=([^&]+)/;
  const short_regexp = /shorts\/([^&|?]+)/;

  if (embed_regexp.test(url)) {
    return url;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp);
    return embed_url + youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp);
    return embed_url + youtube_id;
  }
  return (
    embed_url +
    url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '')
  );
};

export const makeProductStoryHtml = (product_story = {}) => {
  const title = get('title', product_story);
  const desc = get('description', product_story);
  const images = sel('_.product_story_images', product_story);
  const { youtube_embed, fb_embed, instagram_html, twitter_html } = product_story;

  return html`
    <div class="pd-story">
      ${UtilS.htmlIf2(
        title || desc,
        () => `
        <div id="title-section" class="pd-story__section">
          <h2 class='pd-story__title'>${UtilS.linkify(title)}</h2>
          <p class='pd-story__desc'>${UtilS.linkify(desc)}</p>
        </div>`,
      )}
      ${UtilS.htmlIf2(
        youtube_embed,
        () => `
        <div id="youtube-section" class="pd-story__section sns">
          <div class="youtube-wrap">
            <iframe
              src="${go(
                makeYoutubeEmbedUrl(youtube_embed),
                (y) => `${y}${y.indexOf('?') > -1 ? '&' : '?'}playsinline=1`,
              )}"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>`,
      )}
      ${UtilS.htmlIf2(
        instagram_html,
        () => html` <div id="instagram-section" class="pd-story__section sns">${instagram_html}</div>`,
      )}
      ${UtilS.htmlIf2(
        twitter_html,
        () => html` <div id="twitter-section" class="pd-story__section sns">${twitter_html}</div>`,
      )}
      ${UtilS.htmlIf2(
        fb_embed,
        () => html` <div id="facebook-section" class="pd-story__section sns">
          <div class="fb-post" data-href="${fb_embed}" data-width="500"></div>
        </div>`,
      )}
      ${UtilS.htmlIf2(
        !!images.length,
        () => html`
          <div id="image-section" class="pd-story__section images">
            <div class="product-story-images">${strMap(makeProductStoryImageHtml(), images)}</div>
          </div>
        `,
      )}
    </div>
  `;
};
