import * as NessAppPlusHomeCostantS from '../../../App/Plus/Home/S/Constant/home.js';
import { NessProductListType } from '../../../App/Plus/Product/S/Constant/product.js';
import { NessConstantS } from '../../../S/Constant/module/NessConstantS.js';
import { NESS_CUSTOM_ASSET_PRODUCT_LIST_URL } from '../../../S/Constant/custom.js';

/**
 * 상품 카테고리 헤더의 "신상품", "한정판", "인기 상품" 메뉴
 * appendMenus 에 추가하면 외부에서 수동으로 추가할 수 있습니다.
 */
export const getSpecialMenusForCateListHeader = (visibility_by_special_menu = {}, appendMenus = []) => {
  const new_arrivals_menu = {
    type: NessProductListType.recent,
    value: NessProductListType.recent,
    name: ET('ness::product::product_03'),
  };

  const limited_edition_menu = {
    type: NessProductListType.limit,
    value: NessProductListType.limit,
    name: ET('ness::product::product_05'),
  };

  const popular_menu = {
    type: NessProductListType.popular,
    value: NessProductListType.popular,
    name: ET('ness::product::product_07'),
  };

  const special_menus = [];
  if (visibility_by_special_menu[NessProductListType.recent]) {
    special_menus.push(new_arrivals_menu);
  }

  if (visibility_by_special_menu[NessProductListType.custom]) {
    special_menus.push({
      type: NessProductListType.custom,
      value: NessProductListType.custom,
      name: NessConstantS.NESS_CUSTOM_HARDCODING.maplestory.menu_name_en, // 커스텀이 메이플만 있어 이름 하드코딩
      href: NESS_CUSTOM_ASSET_PRODUCT_LIST_URL,
    });
  }

  if (visibility_by_special_menu[NessProductListType.limit]) {
    special_menus.push(limited_edition_menu);
  }
  if (NessAppPlusHomeCostantS.isBestSellerShow && visibility_by_special_menu[NessProductListType.popular]) {
    special_menus.push(popular_menu);
  }
  return special_menus;
};
