import { html } from 'fxjs/es';
//
// * spo_option.name 옵션 추가시 필수 사항입니다.
// * spo_option.name 옵션명은 중복 불가합니다.
// * spo_option_value.name 옵션 추가시 필수 사항입니다.
// * spo_option.about_spo_option_values 옵션 상세 정보는 1개이상 필요합니다.
// * spo_option.about_spo_option_values 옵션 상세 정보는 중복 불가합니다.
// * spo_option.about_spo_option_values 옵션 상세 정보는 20개 이하 등록 가능합니다.

export const pb_product_editor_error = {
  pd_empty: {
    value: 'pd_empty',
    text: '스토어 정보가 없습니다.',
  },
  required: {
    value: 'required',
    text: '필수 입력 사항입니다.',
  },
  min_text: {
    value: 'min_text',
    text: '3자 이상 입력해 주세요.',
  },
  text_length_100: {
    value: 'text_length_100',
    text: '100자 이하로 입력해 주세요.',
  },
  text_length_30: {
    value: 'text_length_30',
    text: '30자 이하로 입력해 주세요.',
  },
  text_length_300: {
    value: 'text_length_300',
    text: '300자 이하로 입력해 주세요.',
  },
  option_length_100: {
    value: 'option_length_100',
    text: '옵션의 총 개수가 100개 이하가 되도록 입력해 주세요.',
  },
  option_length_350: {
    value: 'option_length_350',
    text: '옵션의 총 개수가 350개 이하가 되도록 입력해 주세요.',
  },
  option_length_400: {
    value: 'option_length_400',
    text: '옵션의 총 개수가 400개 이하가 되도록 입력해 주세요.',
  },
  restrict_character: {
    value: 'restrict_character',
    text: '특수문자를 제외하고 입력해 주세요.',
  },
  restrict_character2: {
    value: 'restrict_character2',
    text: '특수문자는 * - · ( ) [ ] / & + , ~ . : @ ! 만 입력 가능합니다.',
  },
  black_list_name: {
    type: 'text',
    text: '사용 불가능한 단어가 포함되어 있습니다.',
  },
  only_number: {
    value: 'only_number',
    text: '숫자를 입력해 주세요.',
  },

  min_price100: {
    value: 'min_price100',
    text: '최소 100원 ~ 최대 10,000,000원 이하로 입력해 주세요.',
  },

  max_price: {
    value: 'max_price',
    text: '최소 100원 ~ 최대 10,000,000원 이하로 입력해 주세요.',
  },

  option_min_price: {
    value: 'option_min_price',
    text: '최소 0원 ~ 최대 10,000,000원 이하로 입력해 주세요.',
  },

  option_max_price: {
    value: 'option_max_price',
    text: '최소 0원 ~ 최대 10,000,000원 이하로 입력해 주세요.',
  },

  min0_max100: {
    value: 'min0_max100',
    text: '퍼센트를 입력해 주세요.',
  },
  limited_only_number: {
    value: 'limited_only_number',
    text: '한정판의 경우 빈 값이나 제작 소요 기간을 숫자로 입력해 주세요.',
  },
  spo_empty: {
    value: 'spo_empty',
    text: '숫자를 입력해 주세요.',
  },
  select_category: {
    value: 'select_category',
    text: '상품 카테고리를 설정해 주세요.',
  },
  pb_type: {
    value: 'pb_type',
    text: '필수 입력사항 입니다.',
  },
  thumbnail_picture: {
    value: 'thumbnail_picture',
    text: '사진은 1개 이상 필수 입니다.',
  },
  thumbnail_picture_max: {
    value: 'thumbnail_picture_max',
    text: '사진은 6개 이상 넣을 수 없습니다.',
  },
  add_spo_option_value_required: {
    value: 'add_spo_option_value_required',
    text: '옵션 추가시 필수 사항입니다.',
  },
  same_spo_option_name: {
    value: 'same_spo_option_name',
    text: '옵션명은 중복 불가합니다.',
  },
  spo_option_name_required: {
    value: 'spo_option_name_required',
    text: '옵션명은 필수 사항입니다.',
  },
  spo_option_detail_name_required: {
    value: 'spo_option_detail_name_required',
    text: '옵션 상세 정보를 다시 확인해 주세요.',
  },
  spo_option_stock_items_length: {
    value: 'spo_option_stock_items_length',
    text: '옵션별 수량 관리 시 옵션이 필요합니다.',
  },
  spo_option_error: {
    value: 'spo_option_error',
    text: '옵션정보를 확인해주세요.',
  },
  spo_option_value_length_min_required: {
    value: 'spo_option_value_length_required',
    text: '옵션 상세 정보는 1개 이상 필요합니다.',
  },
  spo_option_value_length_max_required: {
    value: 'spo_option_value_length_max_required',
    text: '옵션 상세 정보는 20개 이하까지 가능합니다.',
  },
  same_spo_option_value_name: {
    value: 'same_spo_option_value_name',
    text: '옵션 상세 정보는 중복 불가합니다.',
  },
  select_qna: {
    value: 'select_qna',
    text: '1개를 선택해 주세요.',
  },
  qna_sns_link: {
    value: 'qna_sns_link',
    text: 'URL형식에 맞게 입력해 주세요.',
  },
  quantity_and_sell_period_and_max_purchase_per_user: {
    value: 'quantity_and_sell_period_and_max_purchase_per_user',
    title: '한정 옵션 미설정',
    text: '한정 판매 기간 또는 한정 판매 수량 또는 1인당 구매 가능 수량 중 한개 이상 입력해 주세요.',
  },

  producing_period_required: {
    value: 'producing_period_required',
    text: '제작 소요 기간을 입력해 주세요.',
  },

  producing_period_avg_big: {
    value: 'producing_period_avg_big',
    text: '최대 소요일을 평균 소요일 보다 높게 입력해 주세요.',
  },

  limited_start_at_end_at_err: {
    value: 'limited_start_at_end_at_err',
    text: '한정 판매 기간 설정이 잘 못 되었습니다.',
  },

  check_limited_quantity: {
    value: 'check_limited_quantity',
    text: '한정 판매 수량을 입력해 주세요.',
  },

  check_limited_quantity_min: {
    value: 'check_limited_quantity_min',
    text: '한정 판매 수량을 1개 이상 입력해 주세요.',
  },

  check_max_purchase_per_user: {
    value: 'check_max_purchase_per_user',
    text: '1인당 구매 수량을 입력해 주세요.',
  },

  check_limited_period: {
    value: 'check_limited_period',
    text: '한정 판매 기간을 확인해 주세요.',
  },

  is_agree_error: {
    value: 'is_agree_error',
    text: '약관 동의후 등록해 주세요.',
  },

  check_sku_size: {
    value: 'check_sku_size_error',
    text: html`해외 배송의 경우, 정확한 배송비 계산을 위해<br />모든 옵션 목록의 상품 체적정보를 입력해
      주세요.`,
  },

  check_material: {
    value: 'check_material_error',
    text: '의류 카테고리일 경우, 소재 입력이 필수 항목입니다.',
  },

  check_global_delivery_company: {
    value: 'check_global_delivery_company_error',
    text: '최소 하나 이상의 해외 배송 정책을 선택해야 합니다.',
  },
};
