import { $delegate, $find, $on } from 'fxdom/es';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { MShopStudioDigitalProductCreatorF } from './module/MShopStudioDigitalProductCreatorF.js';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import flatpickr from 'flatpickr/dist/flatpickr.js';
export const event = (tab_el) => {
  const digital_product_prop = MShopStudioDigitalProductCreatorConstantS.PROPERTY;
  MShopStudioDigitalProductCreatorF.defnMoreButtonsEvent(tab_el);

  /* flatpickr init */
  const ebook_publication_date_el = $find(
    `input[name=${digital_product_prop.ebook_publication_date}]`,
    tab_el,
  );

  if (ebook_publication_date_el) {
    flatpickr(ebook_publication_date_el, {
      locale: T.lang === 'kr' ? Korean : void 0,
      dateFormat: 'Y.m.d',
      disableMobile: true,
    });
  }

  return go(
    tab_el,
    $delegate(
      'change',
      `.digital-product-creator-form__control input[name=${digital_product_prop.is_public}]`,
      MShopStudioDigitalProductCreatorF.handleRadioInput(digital_product_prop.is_public),
    ),
    $delegate(
      'change',
      `.digital-product-creator-form__control input[name=${digital_product_prop.license_type}]`,
      MShopStudioDigitalProductCreatorF.handleLicenseRadioInput(digital_product_prop.license_type),
    ),
    $delegate(
      'change',
      `.digital-product-creator-form__control input[name=${digital_product_prop.is_hyper_link}]`,
      MShopStudioDigitalProductCreatorF.handleRadioInput(digital_product_prop.is_hyper_link),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.product_name_kr}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.product_name_kr),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.ebook_writer}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.ebook_writer),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.ebook_illustrator}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.ebook_illustrator),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.ebook_translator}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.ebook_translator),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.ebook_publisher}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.ebook_publisher),
    ),
    $delegate(
      'input',
      `.digital-product-creator-form__control input[name=${digital_product_prop.ebook_publication_date}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.ebook_publication_date),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.price}]`,
      MShopStudioDigitalProductCreatorF.handlePriceInput(digital_product_prop.price),
    ),
    $delegate(
      'input',
      `.digital-product-creator-form__control input[name=${digital_product_prop.price}]`,
      MShopStudioDigitalProductCreatorF.convertPrice,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-box',
      MShopStudioDigitalProductCreatorF.clickDigitalProductUploadButton,
    ),
    $on(
      'click',
      '.digital-product-creator-form__preview-upload-box',
      MShopStudioDigitalProductCreatorF.clickDigitalProductUploadButton,
    ),
    $delegate(
      'change',
      '.digital-product-creator-form__input-file',
      MShopStudioDigitalProductCreatorF.uploadDigitalProduct,
    ),
    $delegate(
      'change',
      '.digital-product-creator-form__preview_input-file',
      MShopStudioDigitalProductCreatorF.upLoadPreviewFile,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-result__download',
      MShopStudioDigitalProductCreatorF.downloadDigitalProduct,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-result__delete',
      MShopStudioDigitalProductCreatorF.deleteDigitalProduct,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-result__fail-delete',
      MShopStudioDigitalProductCreatorF.deleteFailDigitalProduct,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-result__delete-preview',
      MShopStudioDigitalProductCreatorF.deletePreviewDigitalProduct,
    ),
    $delegate(
      'input',
      `.digital-product-creator-form__control select[name=${digital_product_prop.ebook_category_id}]`,
      MShopStudioDigitalProductCreatorF.handleSelectEbookCate,
    ),
    $delegate(
      'input',
      '.digital-product-creator-form__product_names input[type=text]',
      MShopStudioDigitalProductCreatorF.handleCheckLetterLen,
    ),
    $delegate(
      'click',
      '.digital-product-creator-form__upload-results-dashboard',
      MShopStudioDigitalProductCreatorF.handleToggleUploadResults,
    ),
    $delegate(
      'change',
      `.digital-product-creator-form__control input[name=${digital_product_prop.qna_type}]`,
      MShopStudioDigitalProductCreatorF.changeQnaType(digital_product_prop.qna_type),
    ),
    $delegate(
      'focusout',
      `.digital-product-creator-form__control input[name=${digital_product_prop.qna_link}]`,
      MShopStudioDigitalProductCreatorF.handleTextInput(digital_product_prop.qna_link),
    ),
    $delegate(
      'click',
      `.digital-product-creator-form__check-box-label input[name=${digital_product_prop.agree_copyright}]`,
      MShopStudioDigitalProductCreatorF.handleAgreeCopyright(digital_product_prop.agree_copyright),
    ),
    $delegate('click', '.digital-product-creator__btn-cancel', () => {
      MuiF.closeFrame();
    }),
    $delegate(
      'click',
      '.digital-product-creator__btn-prohibit',
      MShopStudioDigitalProductCreatorF.patchStoreProductById(digital_product_prop.is_prohibited),
    ),
    $delegate(
      'click',
      '.digital-product-creator__btn-is_solution',
      MShopStudioDigitalProductCreatorF.patchStoreProductById(digital_product_prop.is_solution),
    ),
    $delegate(
      'click',
      '.digital-product-creator__btn-delete',
      MShopStudioDigitalProductCreatorF.deleteStoreProduct,
    ),
    $delegate('click', '.digital-product-creator__btn-submit', MShopStudioDigitalProductCreatorF.submit),
  );
};
