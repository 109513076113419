import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { Stores } from '../../../../entities/pool/public/stores';

export const checkStoreCanCreateCreatorStudioOnboardingEvent = (store: Stores) => {
  return !store?.is_disapproval && store?.can_selling;
};

export const checkServiceCanCreateCreatorStudioOnboardingEvent = () => {
  return UtilS.isMarppleShopApp() && ET.lang === 'kr';
};
