import { go, map } from 'fxjs/es';

export const nullifyGlobalProps = (stores_product) => {
  const nullify_spo_items = go(
    stores_product._.product._.spo._.spo_items,
    map((spo_item) => {
      const { group_names_option_names } = spo_item;
      const new_group_names_option_names = go(
        group_names_option_names,
        map((name_obj) => {
          return {
            ...name_obj,
            group_name_en: null,
            group_name_jp: null,
            option_name_en: null,
            option_name_jp: null,
          };
        }),
      );
      return { ...spo_item, group_names_option_names: new_group_names_option_names };
    }),
  );

  const nullify_spo_options = go(
    stores_product._.product._.spo._.spo_options,
    map((option) => {
      const { spo_option_values } = option._;
      const new_spo_option_values = go(
        spo_option_values,
        map((ov) => {
          return {
            ...ov,
            name_en: null,
            name_jp: null,
          };
        }),
      );
      return {
        ...option,
        _: {
          spo_option_values: new_spo_option_values,
        },
        name_en: null,
        name_jp: null,
      };
    }),
  );

  const nullify_spo_text_options = stores_product._.product?._.spo?._?.spo_text_options?.map(
    (spo_text_option) => {
      return {
        ...spo_text_option,
        name_en: null,
        name_jp: null,
      };
    },
  );

  stores_product._.product.name_en = null;
  stores_product._.product.name_jp = null;
  stores_product.certification_info_en = null;
  stores_product.certification_info_jp = null;
  stores_product.product_basic_info_en = null;
  stores_product.product_basic_info_jp = null;
  stores_product.refund_info_en = null;
  stores_product.refund_info_jp = null;

  stores_product._.product._.spo._.spo_options = nullify_spo_options;
  stores_product._.product._.spo._.spo_items = nullify_spo_items;
  stores_product._.product._.spo._.spo_text_options = nullify_spo_text_options;

  stores_product._.product._.stores_delivery_companies_product.stores_delivery_company_id_en = null;
  stores_product._.product._.stores_delivery_companies_product.stores_delivery_company_id_jp = null;

  return stores_product;
};
