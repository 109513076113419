import { isObject } from 'fxjs/es';

export class NessLoginErrorF extends Error {
  constructor(message) {
    if (isObject(message)) {
      super(JSON.stringify(message));
      this.json = message;
    } else {
      super(message);
      this.message = message;
    }

    this.name = '넥슨 웹 인증 서비스 오류';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NessLoginErrorF);
    }
  }
}

export const isSignedIn = () => {
  if (!inface) throw new NessLoginErrorF('inface가 없습니다.');

  return inface.auth.isSignedIn();
};

export const goSignIn = () => {
  if (!inface) throw new NessLoginErrorF('inface가 없습니다.');

  return inface.auth.gotoSignIn();
};

export const goSignOut = () => {
  if (!inface) throw new NessLoginErrorF('inface가 없습니다.');

  return inface.auth.gotoSignOut();
};

export const goSignUp = () => {
  if (!inface) throw new NessLoginErrorF('inface가 없습니다.');

  return inface.auth.gotoSignUp();
};

export const goAccountEdit = () => {
  if (!inface) throw new NessLoginErrorF('inface가 없습니다.');

  return inface.auth.gotoAccount({ target: '_blank' });
};
