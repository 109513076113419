import { $appendTo, $attr, $closest, $delegate, $el, $find, $on, $remove, $setAttr } from 'fxdom/es';
import { html } from 'fxjs/es';
import { createActionStack } from '../features/bug_tracker.js';

// 2024.05 기준 marppleshop 2.0 에서 alt 에 caption 얹어서 감 (caption 으로 쓸 거면 caption + alt 로 쓰고 아닌 경우는 alt 만 쓰기)

const makeCaptionHtml = (text) => {
  return html`<p class="caption">${text}</p>`;
};

export const showAltPrompt = (img_container_element, image_element) => {
  const alt = $attr('alt', image_element);
  const caption_element = $find('.caption', img_container_element);

  $.body_fixed(true);
  const $message_box = $el(html`
    <div class="don_dialog_msg medium-editor-plugin__alt-prompt" contenteditable="false">
      <div class="content">
        <div class="body">
          <div class="msg">
            <input
              class="alt input_common"
              type="text"
              value="${alt || caption_element?.textContent || ''}"
              placeholder="Description of image"
            />
            <div class="caption_checkbox_container">
              <label
                ><input name="caption" type="checkbox" ${caption_element ? 'checked' : ''} /> 캡션 여부
              </label>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="buttons">
            <button class="btn btn-cancel">취소</button>
            <button class="btn btn-ok">확인</button>
          </div>
        </div>
      </div>
    </div>
  `);

  const save = () => {
    $remove($message_box);
    const alt = $find('input.alt', $message_box).value;
    const is_caption = $find('input[name="caption"]', $message_box).checked;
    $.body_fixed(false);
    $setAttr({ alt }, image_element);

    if (is_caption) {
      if (caption_element) {
        caption_element.innerText = alt;
      } else {
        const new_caption_element = $el(makeCaptionHtml(alt));
        img_container_element.appendChild(new_caption_element);
      }
    } else {
      if (caption_element) $remove(caption_element);
    }

    createActionStack('set image alt');
  };

  $delegate('click', '.btn-cancel', (e) => {
    $remove($message_box);
    $.body_fixed(false);
  })($message_box);

  $delegate('click', '.btn-ok', (e) => save())($message_box);

  $on('keydown', (e) => (e.code === 'Enter' ? save() : ''))($find('input.alt', $message_box));

  $appendTo($closest('.editor', image_element), $message_box);
  $find('.body input', $message_box).focus();
};
