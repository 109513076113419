import { go, strMap, ippL } from 'fxjs/es';
import { NessCustomMakerFrameShareTmplS } from '../../S/Tmpl/module/NessCustomMakerFrameShareTmplS.js';
import { NessCustomMakerFrameShareF } from '../Function/module/NessCustomMakerFrameShareF.js';
import { $find } from 'fxdom/es';

import { getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
import { legacyHtml } from '../../../../../Util/S/Function/util.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { NessCustomMakerCoreS } from '../../../../Core/S/Function/module/NessCustomMakerCoreS.js';

const mui_ref_path = '/modules/NessCustomMaker/Frame/Share/F/Mui/bp_size_selector_tab.js';

/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const bp_size_selector_tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  // always_remove: true, // closeFrame 때 무조건 삭제

  title: '사이즈', // tab button의 title
  selected: true, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)
  makeData(tab) {
    return {};
  }, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template({ selected_bpc_id, base_product_colors }) {
    return '';
  },
  showing(tab_el) {
    const don_wrapper_el = go(tab_el, $find('.don_wrapper'));
    const [bpss, base_product_size_id, sold_out_size_ids] = NessCustomMakerCoreS.getBaseProductSizes(
      getProductColorInMaker(),
    );

    don_wrapper_el.innerHTML = NessCustomMakerFrameShareTmplS.iscroll_wrapper_tmpl(
      go(
        bpss,
        ippL,
        strMap(function ([i, bps]) {
          const id = bps.id;
          const short_name = bps['short_name' + _en];
          const price = bps['price' + _en];
          const selected = bpss.length === 1 || bps.id === base_product_size_id ? 'selected' : '';
          const sold_out = sold_out_size_ids.includes(bps.id) ? 'sold_out' : '';
          return legacyHtml`
        <li
          class="selector_item size_item item ${selected} ${sold_out}"
          data-fx-json="${$dataStr(bps)}"
          ${UtilTmplS.disableIf(sold_out)}
        >
          <span>${short_name}</span>
          ${UtilS.htmlIf(price, `<span class="item_price" >+${UtilS.commify(price)}</span>`)}
          ${UtilS.htmlIf(sold_out, `<span class="item_sold_out" >${ET('common::sold_out')}</span>`)}
        </li>
      `;
        }),
      ),
    );
  },
  showed(tab) {
    NessCustomMakerFrameShareF.iscroll_showed(tab);
  },
  hiding(tab) {
    NessCustomMakerFrameShareF.iscroll_hiding(tab);
  },
  appended(tab) {
    return _pipe(
      $.on3('click', '.selector_item.size_item:not(.sold_out)', NessCustomMakerFrameShareF.bpSizeSelectEvent),
    )(tab);
  },
};
