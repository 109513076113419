export const BANNER_TYPE = {
  public: 'public',
  private: 'private',
  reservation: 'reservation',
};

/*
export const PC_BANNER_IMAGE = {
  width: ,
  height: ,
}

export const MOBILE_BANNER_IMAGE = {
  width: ,
  height: ,
}
*/
