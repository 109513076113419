import { html } from 'fxjs/es';
import { UtilS } from '../Function/module/UtilS.js';

export const tooltipIcon2El = () =>
  UtilS.isNessApp()
    ? html` <span class="tooltip-icon2--question-default"
        ><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 6.08172 14.9183 2.5 10.5 2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5ZM12.6417 10.9504C13.5181 10.4967 14.5 9.98835 14.5 8.9099C14.5 7.79849 13.5676 6.5 10.527 6.5C7.95946 6.5 6.58108 7.45736 6.5 9.18501H8.44595C8.47297 8.04058 9.56757 7.66644 10.4595 7.66644C11.3378 7.66644 12.473 7.90853 12.473 8.81087C12.473 9.51206 11.7854 9.86986 11.0642 10.2452C10.2688 10.6591 9.43243 11.0943 9.43243 12.0351V12.2111H11.2973C11.2973 11.6464 11.9352 11.3161 12.6417 10.9504ZM9.31081 12.9374V14.5H11.4189V12.9374H9.31081Z"
            fill="#9F9F9F"
          /></svg
      ></span>`
    : html`
        <span class="tooltip-icon2--question-default">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_3748_455778)">
              <path
                d="M10.0003 1.66406C5.40033 1.66406 1.66699 5.3974 1.66699 9.9974C1.66699 14.5974 5.40033 18.3307 10.0003 18.3307C14.6003 18.3307 18.3337 14.5974 18.3337 9.9974C18.3337 5.3974 14.6003 1.66406 10.0003 1.66406ZM11.042 14.0641C11.042 14.6391 10.5753 15.1057 10.0003 15.1057C9.42533 15.1057 8.95866 14.6391 8.95866 14.0641V9.68906C8.95866 9.11406 9.42533 8.6474 10.0003 8.6474C10.5753 8.6474 11.042 9.11406 11.042 9.68906V14.0641ZM10.0003 6.98073C9.42533 6.98073 8.95866 6.51406 8.95866 5.93906C8.95866 5.36406 9.42533 4.8974 10.0003 4.8974C10.5753 4.8974 11.042 5.36406 11.042 5.93906C11.042 6.51406 10.5753 6.98073 10.0003 6.98073Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_3748_455778">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      `;
