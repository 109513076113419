import { getCurrentFcanvas } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getProductColorInMaker, getProductFaces2InMaker } from '../../../../Maker/F/getSth.js';
import { find, go } from 'fxjs/es';
import { findSthInAmbiguousAreaWithPrintSafetyArea } from '../../../Checker/F/Function/isAmbiguousAreaCheckPass.js';
import { NewMakerCheckerF } from '../../../Checker/F/Function/module/NewMakerCheckerF.js';

export const getMaterialForCheckCvObjInAmbiguousArea = () => {
  const base_product_face_id = getCurrentFcanvas().bpf_id;
  const base_product_size_id = getProductColorInMaker().base_product_size_id;
  const pf = go(
    getProductFaces2InMaker(),
    find((pf) => pf.bpf_id === base_product_face_id),
  );
  const size_face = go(
    pf.size_faces,
    find((sf) => sf.base_product_size_id == base_product_size_id),
  );
  if (!NewMakerCheckerF.hasMaterialForAmbiguousAreaFunc(size_face)) return;
  return { size_face, designs: pf.designs };
};
export const checkCvObjInAmbiguousArea = ({ size_face, designs }, cv_target) => {
  if (!cv_target) return;
  return findSthInAmbiguousAreaWithPrintSafetyArea(
    {
      size_face,
      designs: [
        go(
          designs,
          find((design) => design.cid === cv_target.cid),
        ),
      ],
      with_white: false,
    },
    1,
  );
};
