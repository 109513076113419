import { go, compact, each } from 'fxjs/es';
import { $closest, $delegate, $hide, $qs } from 'fxdom/es';
import { ConfigSentryF } from '../../../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { NessCustomMakerMobileFakeMpMakerF } from '../../../FakeMpMaker/F/Function/module/NessCustomMakerMobileFakeMpMakerF.js';
import { MShopAppProductDetailMuiF } from '../../../../../MShop/App/ProductDetail/F/Mui/module/MShopAppProductDetailMuiF.js';
import { NessCustomMakerCoreS } from '../../../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
import { NessCustomMakerF } from '../../../../F/Function/module/NessCustomMakerF.js';
import { NessCustomMakerCoreF } from '../../../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { mpMakerSelectFaceActiveTippy } from '../../../../../Maker/F/select_faces.js';
import { is_overflow_on_line_checker_passed } from '../../../../../Maker/F/overflow.js';

export const event = (el, etc_els) => {
  NessCustomMakerMobileFakeMpMakerF.defineFakeMpMaker(etc_els);
  return go(
    el,
    $delegate(
      'click',
      '.custom-product-detail-mo__footer-buttons #ness-cart-button:not(.disabled)',
      async (e) => {
        await MShopUtilF.moveScrollToTop(200);
        if (G.mp.maker.conditionCheckerInstance) {
          if (!(await G.mp.maker.conditionCheckerInstance.openConfirmModalMobile())) return;
        }
        if (!(await NessCustomMakerCoreF.isMakerPass())) return;

        NessCustomMakerCoreS.syncProductDetail(getProductColorInMaker(), box().product_detail);
        NessCustomMakerF.refreshCustomProductDetailHead();

        await MShopAppProductDetailMuiF.handleFormSubmit(e);
      },
    ),

    $delegate('click', '#to_vector_editor', async (e) => {
      const tab_el = $closest('.don_tab', e.delegateTarget);
      /* NESS 커스텀하는 방법 모달  */
      NessCustomMakerF.openMakerTipModal(() => {
        return !!$qs('.icon_menu[data-icon_type="ness-custom-maker__asset-library"]');
      });
      await NessCustomMakerCoreF.toVectorEditor(tab_el);
      NessCustomMakerF.refreshCustomProductDetailHead(tab_el);
    }),
    $delegate('click', '.custom-product-detail-mo__footer-buttons #to_maker', async function (e) {
      const time_per_1000_px = 100;
      const duration = (Math.abs($.scroll_top(window) - 0) / 1000) * time_per_1000_px;
      // $.attr($1('html'), 'f_index', '1');
      try {
        await go(
          anime({
            targets: 'html, body',
            scrollTop: 0,
            duration,
            easing: 'easeInOutCubic',
          }).finished,
          function () {
            return anime({
              targets: compact(etc_els),
              duration: 300,
              opacity: 0,
              easing: 'linear',
            }).finished;
          },
          function () {
            go(etc_els, each($hide));
            $.add_class($1('html'), 'maker_editor_mode');
            $.add_class($1('html'), 'maker_editor_mode_for_canvas');
          },
          async function () {
            $.css($('#maker_frame'), { height: 'auto' });
            $.frame.open({
              frame_name: 'mp.maker.fake.mobile',
            });
            await G.mp.maker.select_face_initA();
            if (G.mp.maker.conditionCheckerInstance) {
              G.mp.maker.conditionCheckerInstance.reset();
            }
            mpMakerSelectFaceActiveTippy();
            /* NESS 커스텀하는 방법 모달  */
            NessCustomMakerF.openMakerTipModal(() => {
              return !!$qs('.don_frame[frame_name="mp.maker.fake.mobile"]');
            });
          },
        );
      } catch (e) {
        $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
        ConfigSentryF.makerCapture(e);
      }
    }),

    $delegate('click', '#to_cart', async (e) => {
      if (!(await is_overflow_on_line_checker_passed())) return;
      const $input = $qs('.pd-counter__current');
      if ($input) {
        const { value = 1 } = $input;
        await NessCustomMakerCoreF.apiCreateUpProduct({
          quantity: value,
          is_cart: true,
          need_close_frame: true,
        });
      }
    }),
    $delegate('click', '#to_buy', async (e) => {
      if (!(await is_overflow_on_line_checker_passed())) return;
      const $input = $qs('.pd-counter__current');
      if ($input) {
        const { value = 1 } = $input;
        await NessCustomMakerCoreF.apiCreateUpProduct({
          quantity: value,
          is_cart: false,
        });
      }
    }),
  );
};
