import { html, View } from 'rune-ts';
import { ArrowRightIcon } from '../../../../renderApp/components/atoms/Icon';
import { Progress } from '../../../../shared/components/cells/Progress/Progress';
import { typo } from '../../../../shared/typography/typo';
import { getResizedUrl } from '../../../../shared/util/image';
import klass from './CreatorOnboardingBanner.module.scss';

export interface CreatorOnboardingBannerData {
  progress: number;
  link: string;
}

export class CreatorOnboardingBanner extends View<CreatorOnboardingBannerData> {
  override template() {
    if (this.data.progress === 100) {
      return html` <a href="${this.data.link}">
        <div class="${klass.banner}">
          <div class="${klass.img_wrapper}">
            <img
              src="${getResizedUrl({
                url: '//s3.marpple.co/files/u_2283830/2024/11/original/2e26fd3289fee385ae8bbd5fc6d15b9ce2fff3532.png',
                width: 120,
                height: 120,
                quality: 80,
              })}"
              alt=""
            />
          </div>
          <div class="${klass.guide}">
            <span class="${typo('14_bold')}">
              ${ET('mps2::seller_studio::onBoarding::completed::title')}
            </span>
            <span class="${klass.desc} ${typo('10_medium')}">
              ${ET('mps2::seller_studio::onBoarding::completed::desc')}
            </span>
          </div>
        </div>
      </a>`;
    }

    if (this.data.progress === 0) {
      return html` <a href="${this.data.link}" class="${klass.banner}">
        <div class="${klass.banner}">
          <div class="${klass.img_wrapper}">
            <img
              src="${getResizedUrl({
                url: '//s3.marpple.co/files/u_2283830/2024/11/original/f0ae8cc45bc82d1aa2591bc386499e82da42435f1.png',
                width: 120,
                height: 120,
                quality: 80,
              })}"
              alt=""
            />
          </div>
          <div class="${klass.guide}">
            <span class="${typo('14_bold')}"> ${ET('mps2::seller_studio::onBoarding::start::title')} </span>
            <span class="${klass.desc} ${typo('10_medium')}">
              ${ET('mps2::seller_studio::onBoarding::start::desc')}
            </span>
          </div>
        </div>
      </a>`;
    }

    return html` <a href="${this.data.link}" class="${klass.banner}">
      <div class="${klass.banner}">
        ${new Progress(
          { percent: this.data.progress },
          {
            bar_color: 'white',
            size: 'small',
          },
        )}
        <div class="${klass.guide}">
          <span class="${typo('14_bold')}">
            ${`${this.data.progress}%`} ${ET('mps2::seller_studio::onBoarding::complete')}
          </span>
          <span class="${klass.desc} ${typo('10_medium')}">
            ${ET('mps2::seller_studio::onBoarding::continue')}
            <span class="${klass.icon}">${ArrowRightIcon}</span></span
          >
        </div>
      </div>
    </a>`;
  }
}
