import { html, strMap } from 'fxjs/es';
import { htmlIf } from '../../../../../Util/S/Function/util.js';

const cross_svg = html`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_1067_5747)">
      <path
        d="M6.62913 6.00003L10.3689 2.25503C10.5437 2.08003 10.5437 1.80003 10.3689 1.63003C10.1942 1.45503 9.91456 1.45503 9.73981 1.63003L6 5.37003L2.26019 1.63003C2.08544 1.45503 1.80583 1.45503 1.63107 1.63003C1.45631 1.80503 1.45631 2.08503 1.63107 2.25503L5.37087 6.00003L1.63107 9.74503C1.45631 9.92003 1.45631 10.2 1.63107 10.37C1.71595 10.455 1.83079 10.5 1.94563 10.5C2.06047 10.5 2.17032 10.455 2.26019 10.37L6 6.63003L9.73981 10.37C9.82469 10.455 9.93953 10.5 10.0544 10.5C10.1692 10.5 10.2791 10.455 10.3689 10.37C10.5437 10.195 10.5437 9.91503 10.3689 9.74503L6.62913 6.00003Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1067_5747">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

const add_svg = html`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
    <path
      d="M16.0009 10.0009L3.9991 10.0009M9.99999 16.0018L9.99999 4.00001"
      stroke="#A2A2A2"
      stroke-linecap="round"
    />
  </svg>
`;

const info_svg = html`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_391_33299)">
      <path
        d="M7.99992 1.33325C4.31992 1.33325 1.33325 4.31992 1.33325 7.99992C1.33325 11.6799 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.6799 1.33325 7.99992 1.33325ZM8.83325 11.2533C8.83325 11.7133 8.45992 12.0866 7.99992 12.0866C7.53992 12.0866 7.16659 11.7133 7.16659 11.2533V7.75325C7.16659 7.29325 7.53992 6.91992 7.99992 6.91992C8.45992 6.91992 8.83325 7.29325 8.83325 7.75325V11.2533ZM7.99992 5.58659C7.53992 5.58659 7.16659 5.21325 7.16659 4.75325C7.16659 4.29325 7.53992 3.91992 7.99992 3.91992C8.45992 3.91992 8.83325 4.29325 8.83325 4.75325C8.83325 5.21325 8.45992 5.58659 7.99992 5.58659Z"
        fill="#A2A2A2"
      />
    </g>
    <defs>
      <clipPath id="clip0_391_33299">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

const flags = {
  ko: html`<img
    src="//s3.marpple.co/files/u_1396787/2020/11/original/ecd38341eb6b9e0e820a28f1f2203956a23a0ad61.png"
  />`,
  en: html`<img
    src="//s3.marpple.co/files/u_1396787/2020/11/original/ce04b2abdf2d685ed86edc7f2d6390c5e6fe1f8f1.png"
  />`,
  jp: html`<img
    src="//s3.marpple.co/files/u_1396787/2020/11/original/4a057f84e5d2e2a9dde8f481a75b70581835940c1.png"
  />`,
};

// 파일 인풋
export const fileInputControl = ({
  aspect_ratio,
  name,
  src,
  placeholder,
  image_size = [0, 0],
  extensions = ['jpg', 'png'],
  file_size = 3,
}) => {
  const accept = extensions.map((ext) => `.${ext}`).join(',');

  return html`
    <div class="popup-store-control-file">
      <label
        class="popup-store-control-file__label ${src ? 'image_attached' : ''}"
        style="aspect-ratio: ${aspect_ratio};"
      >
        <input
          type="file"
          name="${name}"
          value=""
          data-src="${src}"
          class="hidden"
          accept="${accept}"
          data-image_size="${image_size.join(',')}"
          data-size="${1024 * 1024 * file_size}"
          data-ratio="${aspect_ratio}"
        />
        <div class="popup-store-control-file__preview">
          <img src="${src}" alt="image preview" class="preview_img" />
        </div>
        <div class="popup-store-control-file__icon">
          <img
            src="//s3.marpple.co/files/u_1165777/2020/9/original/81c91fe418fc9ceefcc1c3d951a63e57ca0378f71.png"
          />
        </div>
        <p>${placeholder}</p>
      </label>
      <div class="popup-store-control-file__info">
        <p>${ET('사이즈 {{ratio}}px', { ratio: aspect_ratio.replace('/', '*') })}</p>
        <p class="divide">|</p>
        <p>${ET('확장자 {{exts}} 가능', { exts: extensions.map((ext) => ext.toUpperCase()).join(',') })}</p>
        <p class="divide">|</p>
        <p>${ET('최대 {{size}}MB', { size: file_size })}</p>
      </div>
    </div>
  `;
};

// 텍스트 인풋
export const textInputControl = ({ name, value = '', placeholder, max_length, suffix, pattern }) => {
  const length_tmpl = () => html`
    <span class="popup-store-control-text__max-length">
      <span class="value">${value.length ?? 0}</span> / ${max_length} 자
    </span>
  `;

  return html`
    <div class="popup-store-control-text">
      <input
        type="text"
        name="${name}"
        value="${value}"
        placeholder="${placeholder}"
        maxlength="${max_length}"
        ${pattern ? html`data-pattern="${pattern}"` : ''}
      />
      ${max_length ? length_tmpl(max_length, value) : ''}
      ${suffix ? html`<span class="popup-store-control-text__suffix">${suffix}</span>` : ''}
    </div>
  `;
};

// 텍스트에이리어
export const textareaControl = ({
  name,
  value = '',
  placeholder = '',
  rows = 2,
  max_length,
  prefix = null,
  flag = '',
  line_limit = 0,
  pattern = null,
}) => {
  const length_tmpl = () => html`
    <span class="popup-store-control-textarea__max-length">
      <span class="value">${value.length ?? 0}</span> / ${max_length} 자
    </span>
  `;

  if (flag) {
    prefix = html`<span class="popup-store-control-textarea__flag">${flags[flag]}</span>`;
  }

  return html`
    <div class="popup-store-control-textarea">
      ${prefix ? html`<span class="popup-store-control-textarea__prefix">${prefix}</span>` : ''}
      <textarea
        name="${name}"
        rows="${rows}"
        placeholder="${placeholder}"
        maxlength="${max_length}"
        ${line_limit ? html`data-line_limit="${line_limit}"` : ''}
        ${pattern ? html`data-pattern="${pattern}"` : ''}
      >
${value}</textarea
      >
      ${max_length ? length_tmpl(max_length, value) : ''}
    </div>
  `;
};

export const makeKeywordValueHtml = (value) => {
  return html`
    <button type="button" class="popup-store-control-keyword__value" data-value="${value}">
      #${value} ${cross_svg}
    </button>
  `;
};

// 키워드 컨트롤
export const keywordControl = ({ values, limit, name, placeholder }) => {
  return html`
    <div class="popup-store-control-keyword" data-limit="${limit}">
      <input type="hidden" name="${name}" value="${values.join(',')}" />
      <div class="popup-store-control-keyword__controls">
        <div class="popup-store-control-keyword__input">
          ${textInputControl({
            name: 'keyword-input',
            placeholder,
          })}
        </div>
        <button type="button" class="popup-store-control-keyword__add-btn">${add_svg}</button>
      </div>
      <div class="popup-store-control-keyword__values">${strMap(makeKeywordValueHtml, values)}</div>
    </div>
  `;
};

export const publicControl = ({ name, value = '', show_start_at, show_end_at }) => {
  return html`
    <div class="popup-store-control-public">
      <div class="popup-store-control-public__radios">
        <label class="popup-store-control-public__label">
          <input type="radio" name="${name}" value="public" ${value === 'public' ? 'checked' : ''} />
          ${ET('공개')}
        </label>
        <label class="popup-store-control-public__label">
          <input type="radio" name="${name}" value="private" ${value === 'private' ? 'checked' : ''} />
          ${ET('비공개')}
        </label>
        <label class="popup-store-control-public__label">
          <input type="radio" name="${name}" value="reserve" ${value === 'reserve' ? 'checked' : ''} />
          ${ET('예약 공개')}
        </label>
      </div>

      <div class="popup-store-control-public__reserve" data-show="${value === 'reserve'}">
        <div class="popup-store-control-public__reserve-time">
          <input type="datetime-local" name="show_start_at" value="${show_start_at}" />
          <span>~</span>
          <input
            type="datetime-local"
            name="show_end_at"
            value="${show_end_at}"
            ${htmlIf(!show_end_at, 'disabled')}
          />
        </div>
        <div class="">
          <label>
            <input ${htmlIf(!show_end_at, 'checked')} name="no_end" type="checkbox" />
            종료일 없음
          </label>
        </div>
        <p class="popup-store-control-public__info">
          ${info_svg} 한국 표준 시간(KST) 기준이며, 설정한 게시일 전까지 비공개 상태로 유지됩니다.
        </p>
      </div>
    </div>
  `;
};

export const periodControl = ({ start_name, start_value, end_name, end_value }) => {
  return html`
    <div class="popup-store-control-period">
      <input
        type="datetime-local"
        class="popup-store-control-period__input"
        name="${start_name}"
        value="${start_value}"
      />
      <span>~</span>
      <input
        type="datetime-local"
        class="popup-store-control-period__input"
        name="${end_name}"
        value="${end_value}"
      />
    </div>
  `;
};

export const goalControl = ({ goal_type, goal_value, is_soldout, soldout_label }) => {
  return html`
    <div class="popup-store-control-goal">
      <div class="popup-store-control-goal__row">
        <div class="popup-store-control-goal__select">
          <select name="goal_type">
            <option value="count" ${htmlIf(goal_type === 'count', 'selected')}>판매 목표 수량</option>
            <option value="price" ${htmlIf(goal_type === 'price', 'selected')}>판매 목표 금액</option>
          </select>
        </div>
        <div class="popup-store-control-goal__input">
          ${textInputControl({
            name: 'goal_value',
            value: goal_value === 0 ? '' : goal_value,
            placeholder: '0',
            suffix: goal_type === 'count' ? '개' : '원',
            pattern: '^[0-9]+$',
          })}
        </div>
      </div>

      <div class="popup-store-control-goal__is_soldout">
        <label>
          <input type="checkbox" name="is_soldout" ${is_soldout ? 'checked' : ''} />
          완판 표기
        </label>
      </div>
      <div class="popup-store-control-goal__soldout_label ${!is_soldout ? 'hidden' : ''}">
        ${textInputControl({
          name: 'soldout_label',
          value: soldout_label,
          placeholder: 'Sold out',
        })}
      </div>
    </div>
  `;
};

export const goalVisibleControl = ({ value = '' }) => {
  /*
  <label className="popup-store-control-goal-visible__label">
    <input type="radio" name="goal_visible" value="goal" ${value === 'goal' ? 'checked' : ''} />
    ${ET('판매 목표')}
  </label>
  <label class="popup-store-control-goal-visible__label">
    <input type="radio" name="goal_visible" value="on_date" ${value === 'on_date' ? 'checked' : ''} />
    ${ET('전시 기간 노출')}
  </label>
  */

  return html`
    <div class="popup-store-control-goal-visible">
      <div class="popup-store-control-goal-visible__radios">
        <label class="popup-store-control-goal-visible__label">
          <input type="radio" name="goal_visible" value="timer" ${value === 'timer' ? 'checked' : ''} />
          ${ET('종료일 타이머(D-Day)')}
        </label>
        <label class="popup-store-control-goal-visible__label">
          <input type="radio" name="goal_visible" value="hidden" ${value === 'hidden' ? 'checked' : ''} />
          ${ET('전체 미노출')}
        </label>
      </div>
    </div>
  `;
};
