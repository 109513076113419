import { html, View } from 'rune-ts';

import klass from './ButtonHeart.module.scss';
import { commify } from '../../../../shared/util/commify';
import { HeartFillIcon } from '../Icon';
import { staticTypo } from '../../../../shared/typography/typo';

const typeOptions = Object.freeze({
  bright: klass.bright,
  dark: klass.dark,
  transparent: klass.transparent,
});

const sizeOptions = Object.freeze({
  regular: klass.regular,
  medium: klass.medium,
});

export interface ButtonHeartProps {
  count: number;
  klass?: string;
  is_active?: boolean;
  type?: keyof typeof typeOptions;
  size?: keyof typeof sizeOptions;
}

export class ButtonHeart extends View<ButtonHeartProps> {
  static typeOptions = typeOptions;
  static sizeOptions = sizeOptions;

  getTypeOption() {
    return (this.data.type && ButtonHeart.typeOptions[this.data.type]) || ButtonHeart.typeOptions.bright;
  }

  getSizeOption() {
    return (this.data.size && ButtonHeart.sizeOptions[this.data.size]) || ButtonHeart.sizeOptions.regular;
  }

  override template(data: ButtonHeartProps) {
    return html`
      <button
        class="${klass.heart_button} ${data.klass ?? ''} ${data.is_active
          ? klass.active
          : ''} ${this.getSizeOption()} ${this.getTypeOption()} ${staticTypo('unica_14_regular')}"
        type="button"
      >
        <span class="${klass.icon}">${HeartFillIcon}</span>
        ${commify(this.data.count)}
      </button>
    `;
  }
}
