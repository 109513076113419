import axios from 'axios';
import { addDays, min } from 'date-fns';
import flatpickr from 'flatpickr/dist/flatpickr.js';
import { Korean } from 'flatpickr/dist/l10n/ko.js';

import {
  $addClass,
  $after,
  $append,
  $appendTo,
  $attr,
  $clone,
  $closest,
  $css,
  $data,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $hide,
  $insertAfter,
  $next,
  $outerHeight,
  $parent,
  $prev,
  $qs,
  $qsa,
  $remove,
  $removeClass,
  $setAttr,
  $setCss,
  $setHTML,
  $setScrollTop,
  $setText,
  $setVal,
  $show,
  $trigger,
  $val,
} from 'fxdom/es';
import {
  isNil,
  compact,
  defaults,
  each,
  extend,
  filterL,
  find,
  findWhere,
  flatMap,
  go,
  html,
  ippL,
  last,
  map,
  mapL,
  mapObject,
  max,
  pick,
  pipe,
  pluck,
  reduce,
  reject,
  sel,
  selEq,
  some,
  sum,
  takeAllC,
  tap,
  uniqBy,
  zip,
  filter,
  join,
  every,
  match,
} from 'fxjs/es';
import moment from 'moment';
import { ACTION, ACTION_TARGET, SERVICE } from '../../../../../Creator/Authority/S/constant.js';
import { checkActions } from '../../../../../Creator/Authority/S/util.js';
import { setProductStoryImage } from '../../../../../Creator/ProductStoryEditor/F/event.js';
import { PRODUCT_STORY_MAX_WIDTH } from '../../../../../Creator/ProductStoryEditor/S/width.js';
import { getFormValues } from '../../../../../Creator/Store/F/fs.js';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { SpoSpoConstantS } from '../../../../../Spo/Spo/S/Constant/module/SpoSpoConstantS.js';
import { checkHasUp } from '../../../../../Ups/F/fs.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { initTooltips2 } from '../../../../../Util/F/tooltip.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { UtilStringS } from '../../../../../Util/String/S/Function/module/UtilStringS.js';
import { MShopShareFramePopUpF } from '../../../../ShareFrame/PopUp/F/Function/module/MShopShareFramePopUpF.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPbProductConstantS } from '../../../PbProduct/S/Constant/module/MShopStudioPbProductConstantS.js';
import { MShopStudioPbProductS } from '../../../PbProduct/S/Function/module/MShopStudioPbProductS.js';
import { pb_product_editor_error } from '../../../PbProduct/S/Function/pbProductEditorError.js';
import { MShopStudioPbProductTmplS } from '../../../PbProduct/S/Tmpl/module/MShopStudioPbProductTmplS.js';
import { globalNoOptionTable } from '../../../PbProduct/S/Tmpl/shippingGlobalHtml.js';
import { MShopStudioPbProductSizeF } from '../../../PbProductSize/F/Function/module/MShopStudioPbProductSizeF.js';
import { MShopStudioPbProductTipF } from '../../../PbProductTip/F/Function/module/MShopStudioPbProductTipF.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { checkQuantity } from './quantity_check.js';

moment.locale('ko');

let file_id = 1;
const makeUniqId = function () {
  return 'pb_file_' + file_id++;
};
const initFile = (e) => () =>
  setTimeout(function () {
    e.currentTarget.type = '';
    e.currentTarget.type = 'file';
    e.currentTarget.disabled = false;
  }, 0);

const makePbImageToArrayInBox = (parent_el) => {
  box.set(
    'pb_thumbnail_files',
    map(
      ($el) => findWhere({ file_id: $attr('data-file-id', $el) }, box.sel('pb_thumbnail_files')),
      $findAll('.pb-thumbnail-editor__item--on[data-is_file="true"]', parent_el),
    ),
  );
};

export const convertURLtoFile = async (url) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };
  return new File([data], filename, metadata);
};

const getEditorContainerEl = (el) => {
  return $closest('.pb-editor', el);
};

function makeDataSpoItems(elements) {
  if (!Array.isArray(elements)) {
    throw new TypeError();
  }
  const end = elements.length - 1;
  const result = [];

  function addTo(curr, start) {
    const first = elements[start];
    const last = start === end;
    for (let i = 0; i < first.length; ++i) {
      const copy = curr.slice();
      copy.push(first[i]);
      if (last) {
        result.push(copy);
      } else {
        addTo(copy, start + 1);
      }
    }
  }

  if (elements.length) {
    addTo([], 0);
  } else {
    result.push([]);
  }
  return result;
}

const togglePbEditorThumbnail = (is_show_upload, parent_el) => {
  $setAttr({ 'is-show-upload': is_show_upload }, $find('.pb-thumbnail-editor__view-area', parent_el));
};

function hideMoreButtons(e) {
  if (!$closest('.pb-editor-form__btn-more', e.target)) {
    const $open_els = $qsa('.buttons[is_open="true"] .more-buttons');
    each((el) => go(el, $hide, $parent, $setAttr({ is_open: false })), $open_els);
  }
}

export const defnMoreButtonsEvent = (wrap_el) => {
  document.addEventListener('click', hideMoreButtons);
  return $delegate('click', '.pb-editor-form__btn-more', (e) => {
    go(e.currentTarget, $closest('.buttons'), $setAttr({ is_open: 'true' }), $find('.more-buttons'), $show);
  })(wrap_el);
};

export const setThumbFile = async (e, container_el, files) => {
  const is_input = !!e;
  const pb_photos_el = $find('.pb-thumbnail-editor__photos', container_el);
  const total_len = $findAll('.pb-thumbnail-editor__photo', pb_photos_el).length + files.length;
  const fail_images = [];
  if (is_input && total_len > 6)
    return go($.alert(T('review::Maximum of MAX photos', { max: 6 })), initFile(e));

  if (is_input && find((file) => file.size > 5242880, files)) {
    return go($.alert(T('File size is too large', { mb: 5 })), initFile(e));
  }

  if (is_input) {
    e.currentTarget.disabled = true;
  }

  return go(
    files,
    filterL((file) => file.type.match('image')),
    mapL((file) => {
      return go(
        new Promise((rs, rj) => {
          const reader = new FileReader();
          reader.onload = () => {
            const min_size = 740;
            const max_size = 5000;
            const image = new Image();
            image.onload = () => {
              if (image.width < min_size) {
                fail_images.push({ file, type: 'min_width' });
                return rj(file);
              }
              if (image.width > max_size) {
                fail_images.push({ file, type: 'max_width' });
                return rj(file);
              }
              if (image.height < min_size) {
                fail_images.push({ file, type: 'min_height' });
                return rj(file);
              }
              if (image.height > max_size) {
                fail_images.push({ file, type: 'max_height' });
                return rj(file);
              }
              rs(image);
            };
            image.src = reader.result || '';
          };
          reader.readAsDataURL(file);
        }),
        async () => {
          const url = await $.get_data_url(500)(file);
          const file_id = makeUniqId();
          file.file_id = file_id;
          const idx = box.sel('pb_thumbnail_photos').length > 0 ? box.sel('pb_thumbnail_photos').length : 0;

          return box.push('pb_thumbnail_photos', { file_id, url, idx });
        },
        () => box.push('pb_thumbnail_files', file),
      ).catch((file_original_name) => {
        console.error(file_original_name);
      });
    }),
    takeAllC,
    () => {
      if (is_input) initFile(e)();

      $setHTML(
        MShopStudioPbProductTmplS.makePbThumbnailEditorHtml(box.sel('pb_thumbnail_photos')),
        $find('.pb-editor__thumbnails-area', container_el),
      );

      if (fail_images.length) {
        $.alert(T('pb_product::이미지 가로 세로 사이즈를 최소 740px 최대 5000px로 올려주세요.'));
      }
    },
    () => {
      makePbImageToArrayInBox(container_el);
      checkAllEvents(container_el);
    },
  );
};

export const changeThumbFile = async (e) => {
  if ($.valid_files(e.currentTarget))
    return $.alert(
      html`
        <div class="ecommerce-info__alert">
          <h3>파일 첨부 불가능</h3>
          <p style="text-align: center;">
            첨부가 불가능한 파일입니다.<br />
            (첨부 가능 파일 : jpg, png)
          </p>
        </div>
      `,
    );

  const files = e.currentTarget.files;
  const container_el = getEditorContainerEl(e.currentTarget);

  return await setThumbFile(e, container_el, files);
};

export const patchStoreProductById = (key) => async () => {
  try {
    const store_product = box.sel('pb_stores_product');
    await $.patch(
      UtilS.makeApiUrl('/@api/stores/:store_id/store_products/:id', {
        store_id: box.sel('store_id'),
        id: store_product.id,
      }),
      {
        [key]: !store_product[key],
      },
    );
    MuiF.closeFrame({ refresh: true });
    // location.reload();
  } catch (_err) {
    console.error(_err);
  }
};

export const deleteStoreProduct = async () => {
  if (await checkHasUp(box.sel('pb_stores_product->product_id'))) {
    return MShopShareFramePopUpF.alert({
      title: T(
        '구매되었거나 장바구니에 담긴 상품은<br>삭제 할 수 없습니다.<br>대신 공개 설정을 이용해보세요.',
      ),
    });
  }

  const yes = await MShopShareFramePopUpF.confirm({ title: T('삭제 하시겠습니까?') });
  if (!yes) return;

  try {
    await $.delete(
      UtilS.makeApiUrl(`/${T.lang}/@api/stores/:store_id/products/:id`, {
        store_id: box.sel('store_id'),
        id: box.sel('pb_stores_product->product_id'),
      }),
    );
    MuiF.closeFrame({ refresh: true });
  } catch (_err) {
    console.error(_err);
    MShopShareFramePopUpF.alert({ title: '삭제되지 않았습니다.' });
  }
};

export const delegateTplFeeCheckValue = (delegateTarget) => {
  return go(
    delegateTarget,
    $delegate('focusout', 'input[name="tpl_fee"]', (e) => {
      const tpl_fee = parseInt(e.target.value);
      if (!tpl_fee) {
        e.target.value = 0;
      } else if (tpl_fee < 0) {
        e.target.value = 0;
      } else if (tpl_fee >= 100) {
        e.target.value = 100;
      }
    }),
    $delegate('keyup', 'input[name="tpl_fee"]', (e) => {
      const tpl_fee = parseFloat(e.target.value);
      if (tpl_fee < 0) {
        e.target.value = 0;
      } else if (tpl_fee >= 100) {
        e.target.value = 100;
      }
    }),
  );
};
export const toTplStoreProduct = async () => {
  MShopShareFramePopUpF.confirm({
    title: T('tpl::입고 상태 변경'),
    body: html`
      <div class="to-tpl">
        <div class="to-tpl__description">
          ${T('tpl::입고 수수료 입력 후 입고 완료 버튼을 클릭하면<br />입고 상태가 완료로 변경됩니다.')}
        </div>
        <div class="pb-editor-form__body to-tpl__input">
          <input
            id="to_tpl_fee"
            type="number"
            name="tpl_fee"
            value="0"
            class="pb-editor-form__body-input to-tpl__input-value"
            min="0"
            max="100"
          />
          <div class="pb-editor-form__body-right">%</div>
        </div>
      </div>
    `,
    ok: T('tpl::입고 완료'),
    cancel: T('취소'),
  });

  const ok$ = $qs('#don_confirm .ok');
  const clone_ok$ = $clone(ok$);
  go(
    $qs('#don_confirm'),
    tap(() => {
      $remove(ok$);
      $addClass('to-tpl__not-ok', clone_ok$);
      $after(clone_ok$, $qs('#don_confirm .cancel'));
    }),
    $delegate('input', 'input[name="tpl_fee"]', (e) => {
      const tpl_fee = parseFloat(e.target.value);
      if (tpl_fee <= 100 && tpl_fee >= 0) {
        $removeClass('to-tpl__not-ok', clone_ok$);
      } else {
        $addClass('to-tpl__not-ok', clone_ok$);
      }
    }),
    delegateTplFeeCheckValue,
    $delegate('click', '.ok', async () => {
      if (!$hasClass('to-tpl__not-ok', clone_ok$)) {
        try {
          await axios.post(
            UtilS.makeApiUrl(`/${T.lang}/@api/stores/:store_id/pb_products/:id/tpl`, {
              store_id: box.sel('store_id'),
              id: box.sel('pb_stores_product->product_id'),
            }),
            {
              tpl_fee: parseFloat($qs('#to_tpl_fee').value),
            },
          );
          window.location.reload();
        } catch (e) {
          if (e.response.status == 403) {
            $.alert(T('입고 상태로 변경할 수 없는 스토어 입니다. 개발팀에 문의해주세요.'));
          } else {
            $.alert(e.response.data.message);
          }
        }
      }
    }),
  );
};

export const clickProductTipOpen = () => {
  MShopStudioPbProductTipF.openFrame();
};

export const clickImageUpload = (e) => {
  $trigger('click', $find('.pb-thumbnail-editor__input-file', e.delegateTarget));
};

export const clickThumbListEmptyItem = (e) => {
  togglePbEditorThumbnail(true, e.delegateTarget);
  const selected_photo_el = $find('.pb-thumbnail-editor__item--selected', e.delegateTarget);
  if (selected_photo_el)
    $removeClass(
      'pb-thumbnail-editor__item--selected',
      $find('.pb-thumbnail-editor__item--selected', e.delegateTarget),
    );
};

export const clickThumbListItem = (e) => {
  const item_el = $closest('.pb-thumbnail-editor__item', e.currentTarget);
  const idx = $attr('data-idx', item_el);
  const prev_photo_el = $find(`.pb-thumbnail-editor__photo--show`, e.delegateTarget);
  const photo_el = $find(`.pb-thumbnail-editor__photo[data-idx="${idx}"]`, e.delegateTarget);
  const selected_photo_el = $find('.pb-thumbnail-editor__item--selected', e.delegateTarget);

  togglePbEditorThumbnail(false, e.delegateTarget);

  if (selected_photo_el) $removeClass('pb-thumbnail-editor__item--selected', selected_photo_el);
  $addClass('pb-thumbnail-editor__item--selected', item_el);

  if (prev_photo_el) $removeClass('pb-thumbnail-editor__photo--show', prev_photo_el);
  $addClass('pb-thumbnail-editor__photo--show', photo_el);
};

export const clickDeleteThumbListItem = (e) => {
  const item_el = $closest('.pb-thumbnail-editor__item', e.currentTarget);
  const file_id = $attr('data-file-id', item_el);
  const container_el = getEditorContainerEl(e.currentTarget);

  if (file_id) {
    1;
    box.remove2(`pb_thumbnail_files->(file=>file.file_id=='${file_id}')`);
    box.remove2(`pb_thumbnail_photos->(photo=>photo.file_id=='${file_id}')`);
  } else {
    const { url } = $data(item_el);
    box.remove2(`pb_thumbnail_photos->(photo=>photo.url=='${url}')`);
  }

  $setHTML(
    MShopStudioPbProductTmplS.makePbThumbnailEditorHtml(box.sel('pb_thumbnail_photos')),
    $find('.pb-editor__thumbnails-area', e.delegateTarget),
  );

  checkAllEvents(container_el);
};

export const clickDeleteThumbnail = (e) => {
  const delete_photo_el = $find(
    '.pb-thumbnail-editor__item--selected .pb-thumbnail-editor__item-thumb-del',
    e.delegateTarget,
  );
  $trigger('click', delete_photo_el);
};

export const clickSetFirstThumb = (e) => {
  const idx = $attr('data-idx', $closest('.pb-thumbnail-editor__photo', e.currentTarget));
  const firstPhoto = box.sel('pb_thumbnail_photos')[idx];
  go(
    box.sel('pb_thumbnail_photos'),
    reject((photo) => photo.url == firstPhoto.url),
    (photos) => {
      box.set('pb_thumbnail_photos', [firstPhoto, ...photos]);
      return box.sel('pb_thumbnail_photos');
    },
    (photos) => {
      $setHTML(
        MShopStudioPbProductTmplS.makePbThumbnailEditorHtml(photos),
        $find('.pb-editor__thumbnails-area', e.delegateTarget),
      );
    },
    () => makePbImageToArrayInBox(e.delegateTarget),
  );
};

export const enterHelpIcon = (e) => {
  const { top, height } = e.currentTarget.getBoundingClientRect();
  const { left: f_left } = $find('.pb-editor-form', e.delegateTarget).getBoundingClientRect();
  const { x, y } = $find('.pb-editor__wrapper', e.delegateTarget).getBoundingClientRect();
  const scroll_top = $find('.pb-editor__wrapper', e.delegateTarget).scrollTop;
  const type = $attr('data-type', e.currentTarget);
  const is_mobile = MShopUtilF.isMobile();
  const icon_left = $attr(is_mobile ? 'data-mobile-left' : 'data-left', e.currentTarget);
  const tooltip_el = $find('.editor-tooltip', e.delegateTarget);
  const body_el = $find('.editor-tooltip__body', tooltip_el);

  $setCss(
    extend(
      {
        top: `${top - y + height + scroll_top + 10}px`,
        left: `${f_left - x}px`,
      },
      is_mobile && {
        right: `${f_left - x}px`,
      },
    ),
    $find('.editor-tooltip__wrap', tooltip_el),
  );

  $setCss({ left: icon_left }, $find('.editor-tooltip__arrow', tooltip_el));

  $setHTML(MShopStudioPbProductTmplS.pdTypeTooltipHTML(type), body_el);
  go(e.delegateTarget, $find('.editor-tooltip'), $removeClass('editor-tooltip--hide'));

  setTimeout(() => {
    $setCss(
      {
        display: 'block',
        height: $outerHeight(
          $find(is_mobile ? '.pb-editor__wrapper' : '.pb-editor__right', e.delegateTarget),
        ),
      },
      $find('.editor-tooltip__bg', tooltip_el),
    );
  }, 200);
};

export const leaveHelpIcon = (e) => {
  const tooltip_el = $find('.editor-tooltip', e.delegateTarget);
  $setCss({ display: 'none' }, $find('.editor-tooltip__bg', tooltip_el));
  $addClass('editor-tooltip--hide', tooltip_el);
};

export const clickHelpIcon = () => {};

export const clickPrevThumbnail = (e) => {
  const selected_item_el = $find('.pb-thumbnail-editor__item--selected', e.delegateTarget);
  const prev_el = $prev(selected_item_el);

  if (prev_el && $hasClass('pb-thumbnail-editor__item--on', prev_el)) {
    $trigger('click', $find('.pb-thumbnail-editor__item-img', prev_el));
  } else {
    const last_el = go($findAll('.pb-thumbnail-editor__item-img', e.delegateTarget), last);
    $trigger('click', last_el);
  }
};

export const clickNextThumbnail = (e) => {
  const selected_item_el = $find('.pb-thumbnail-editor__item--selected', e.delegateTarget);
  const next_el = $next(selected_item_el);

  if (next_el && $hasClass('pb-thumbnail-editor__item--on', next_el)) {
    $trigger('click', $find('.pb-thumbnail-editor__item-img', next_el));
  } else {
    $trigger(
      'click',
      $find('.pb-thumbnail-editor__item-img', $find('.pb-thumbnail-editor__item--on', e.delegateTarget)),
    );
  }
};

export const keyUpName = (e) => {
  setPbBodyLengthTxt(e.currentTarget);
};

const errorPassEl = (el) => {
  go(
    el,
    $parent('.pb-editor-form__control'),
    tap($findAll('.pb-editor-form__body--error'), each($removeClass('pb-editor-form__body--error'))),
    $findAll('.pb-editor-form__error-msg--show'),
    each($removeClass('pb-editor-form__error-msg--show')),
  );
};

const errorStatusEl = (res, el) => {
  if (!res.result) {
    return go(
      el,
      $parent('.pb-editor-form__control'),
      tap($findAll('.pb-editor-form__body'), each($addClass('pb-editor-form__body--error'))),
      (el) => {
        return last($findAll('.pb-editor-form__error-msg', el));
      },
      tap($setText(res.error.text)),
      $addClass('pb-editor-form__error-msg--show'),
    );
  }

  errorPassEl(el);
};

const isInputValid = (e, func) => {
  const $el = e.currentTarget || e;
  const value = $val($el);
  const error_result = func(value);
  const container_el = getEditorContainerEl($el);
  errorStatusEl(error_result, $el);
  checkAllEvents(container_el);
  const { result } = error_result;
  return result;
};
export const focusoutName = (e) => {
  const el = e.currentTarget || e;
  go(
    el,
    $closest('.pb-editor-form__control'),
    $findAll('input'),
    reject(($el) => $css('display', $parent($el)) === 'none'),
    some(($el) => {
      return !isInputValid($el, MShopStudioPbProductS.checkPdName);
    }),
  );
};

export const focusoutCertificationInfo = (e) => {
  const el = e.currentTarget || e;
  const certification_info = $val(el);
  const res = MShopStudioPbProductS.checkCertificationInfoTxt(certification_info);
  const container_el = getEditorContainerEl(el);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const changeCertificationInfo = ({ currentTarget }) => {
  const control$ = $closest('.pb-editor-form__control', currentTarget);
  const kc_bodys$ = $qsa('.pb-editor-form__kc-body');
  const kc_inputs$ = $findAll('.pb-editor-form__body-input', control$);

  if (currentTarget.value == 'show') {
    each($addClass('pb-editor-form__kc-body--show'), kc_bodys$);

    each((kc_input$) => {
      kc_input$.value = '';
    }, kc_inputs$);
    control$.dataset.is_certification_info = 'true';
  } else {
    each($removeClass('pb-editor-form__kc-body--show'), kc_bodys$);
    each((kc_input$) => {
      kc_input$.value = '';
    }, kc_inputs$);
    control$.dataset.is_certification_info = 'false';
  }
};

export const focusoutProfit = (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const view_price = $val(el);
  const price = PriceS.price(view_price);
  $setVal(view_price, el);
  const res = MShopStudioPbProductS.checkPdProfit(price);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const convertPriceForm = (e) => {
  const el_value = $val(e.currentTarget);
  const text_value = UtilS.commify(el_value.replace(/[^0-9]/g, '').replace(/(^0+)/, ''));
  $setVal(text_value, e.currentTarget);
};

export const focusoutPdSellerProfitShare = (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const seller_profit_share = $val(el);
  const res = MShopStudioPbProductS.checkClientPdSellerProfitShare(seller_profit_share);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const changeProfitShare = ({ target, delegateTarget: $tab_el }) => {
  const container_el = getEditorContainerEl(target);
  const seller_profit_share = Number(Number($val(target)).toFixed(2));
  const max = Number($attr('max', target));
  const min = Number($attr('min', target));
  if (seller_profit_share > max) {
    target.value = max;
  } else if (seller_profit_share < min) {
    target.value = min;
  } else if (seller_profit_share % 1 === 0) {
    target.value = Number(seller_profit_share.toFixed(0));
  } else {
    target.value = seller_profit_share;
  }

  const $company_profit_share = $find('[name="company_profit_share"]', $tab_el);
  $setVal(`${(100 - target.value).toFixed(2)} %`, $company_profit_share);

  checkAllEvents(container_el);
};

export const focusoutAvgDays = (e) => {
  const el = e.currentTarget || e;
  const parent_el = $parent('.pb-editor', el);
  const avg_days = $val(el);
  const pd_type = $val($find('select[name="product_type"]', parent_el));
  const max_days = $val($find('input[name="max_days"]', parent_el));
  const res = MShopStudioPbProductS.checkProducingPeriod(pd_type, avg_days, max_days);
  const container_el = getEditorContainerEl(el);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const focusoutMaxDays = (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const parent_el = $parent('.pb-editor', el);
  const pd_type = $val($find('select[name="product_type"]', parent_el));
  const avg_days = $val($find('input[name="avg_days"]', parent_el));
  const max_days = $val(el);
  const res = MShopStudioPbProductS.checkProducingPeriod(pd_type, avg_days, max_days);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const focusoutTotalStock = (e) => {
  const el = e.currentTarget || e;
  const count = $val(el);
  const res = MShopStudioPbProductS.checkPdTotalStock(count);
  const container_el = getEditorContainerEl(el);
  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const focusoutRealTotalStock = (e) => {
  const el = e.currentTarget || e;
  const count = $val(el);
  const res = MShopStudioPbProductS.checkPdTotalStock(count);
  const container_el = getEditorContainerEl(el);
  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const focusoutOptionPrice = async (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const view_price = $val(el);
  const price = PriceS.price(view_price);
  const res = MShopStudioPbProductS.checkOptionPrice(price);

  if (!res.result && res.error == MShopStudioPbProductS.pb_product_editor_error.only_number) {
    return $setVal(0, el);
  }

  if (!res.result) {
    await $.alert(res.error.text);
    return el.focus();
  }
  checkAllEvents(container_el);
};

export const focusoutOptionStock = async (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const stock = $val(el);
  const res = MShopStudioPbProductS.checkOptionStock(stock);

  if (!res.result && res.error == MShopStudioPbProductS.pb_product_editor_error.only_number) {
    return $setVal(0, el);
  }

  if (!res.result) {
    await $.alert(res.error.text);
    return el.focus();
  }
  checkAllEvents(container_el);
};

export const focusoutRealOptionStock = async (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const stock = $val(el);
  const res = MShopStudioPbProductS.checkOptionRealStock(stock);

  if (!res.result && res.error == MShopStudioPbProductS.pb_product_editor_error.only_number) {
    return $setVal(0, el);
  }

  if (!res.result) {
    await $.alert(res.error.text);
    return el.focus();
  }
  checkAllEvents(container_el);
};

export const focusoutQnaLink = (e) => {
  const el = e.currentTarget || e;
  const container_el = getEditorContainerEl(el);
  const link = $val(el);
  const res = MShopStudioPbProductS.checkQnaLink(link);

  errorStatusEl(res, el);
  checkAllEvents(container_el);
};

export const focusoutOptionDirect = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const direct_els = $findAll('.pb-editor-form__body-input-op-direct', e.delegateTarget);
  const txt = $val(e.currentTarget);
  if (direct_els.length <= 1 && !txt.length) {
    return errorPassOptionDirectEl(e.currentTarget);
  }

  const res = MShopStudioPbProductS.checkSpoDirectOptionTxt(txt);

  errorStatusOptionDirectEl(res, e.currentTarget);
  checkAllEvents(container_el);
};

export const errorPassOptionDirectEl = (el) => {
  go(
    el,
    $parent('.pb-editor-form__direct-option-control'),
    tap($findAll('.pb-editor-form__body--error'), each($removeClass('pb-editor-form__body--error'))),
    $findAll('.pb-editor-form__error-msg--show'),
    each($removeClass('pb-editor-form__error-msg--show')),
  );
};

const errorStatusOptionDirectEl = (res, el) => {
  if (!res.result) {
    return go(
      el,
      $parent('.pb-editor-form__direct-option-control'),
      tap($findAll('.pb-editor-form__body'), each($addClass('pb-editor-form__body--error'))),
      $find('.pb-editor-form__error-msg:last-child'),
      tap($setText(res.error.text)),
      $addClass('pb-editor-form__error-msg--show'),
    );
  }

  errorPassOptionDirectEl(el);
};

export const keyUpOptionDirectInput = (e) => {
  setPbBodyLengthTxt(e.currentTarget);
};

const setPbBodyLengthTxt = (input_el) => {
  const length = input_el.value.trim().length;
  const txt_el = $find(
    '.pb-editor-form__body-length-txt, .pb-editor-form__body-length-txt-en, .pb-editor-form__body-length-txt-jp',
    $closest('.pb-editor-form__body', input_el),
  );
  $setText(length, txt_el);
};

export const changeCateLists = async (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const cate_list_id = e.currentTarget.value;
  const cate_item_select_area_el = $find(
    '.pb-editor-form__cate-items .pb-editor-form__select-area',
    e.delegateTarget,
  );

  $setHTML(
    MShopStudioPbProductTmplS.selectCateItems(box.sel(`cate_lists->(#${cate_list_id})->_->cate_items`)),
    cate_item_select_area_el,
  );

  checkAllEvents(container_el);
};

export const changeCateItems = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  errorPassEl(e.currentTarget);
  checkAllEvents(container_el);

  const options = e.currentTarget.options;
  const selectedIndex = e.currentTarget.selectedIndex;
  const is_cloth = options[selectedIndex]?.dataset?.isCloth === 'true';
  const material_el = go(container_el, $find('.pb-editor-form__material'));

  if (material_el) {
    material_el.classList.toggle('pb-tpl-invisible', !is_cloth);
  }
};

export const changeProductType = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const product_type_id = e.currentTarget.value;
  const { show_spo_producing_period, is_limited_possible } = box.sel(`product_types->(#${product_type_id})`);

  const producing_period_el = $find('.pb-editor-form__producing-period', e.delegateTarget);

  if (show_spo_producing_period && producing_period_el) {
    const avg_days = $val($find('input[name="avg_days"]', producing_period_el));
    const max_days = $val($find('input[name="max_days"]', producing_period_el));
    $remove(producing_period_el);
    const new_producing_period_el = $el(
      MShopStudioPbProductTmplS.producingPeriodInput(product_type_id, { avg_days, max_days }),
    );
    $insertAfter($find('.pb-editor-form__pd-type', e.delegateTarget), new_producing_period_el);
  }

  if (show_spo_producing_period && !producing_period_el) {
    const new_producing_period_el = $el(MShopStudioPbProductTmplS.producingPeriodInput(product_type_id));
    $insertAfter($find('.pb-editor-form__pd-type', e.delegateTarget), new_producing_period_el);
  }

  if (!show_spo_producing_period && producing_period_el) $remove(producing_period_el);

  if (is_limited_possible) {
    go(
      e.delegateTarget,
      $find('.pb-editor-form__control-limited'),
      $removeClass('pb-editor-form__control-limited--hide'),
    );
  } else {
    go(
      e.delegateTarget,
      $find('.pb-editor-form__control-limited'),
      $addClass('pb-editor-form__control-limited--hide'),
    );

    const checked_check_sales_period_el = $find('input[name="check_sales_period"]:checked', e.delegateTarget);
    if (checked_check_sales_period_el) {
      $trigger('click', checked_check_sales_period_el);
    }
    const checked_check_quantity_el = $find('input[name="check_quantity"]:checked', e.delegateTarget);
    if (checked_check_quantity_el) {
      $trigger('click', checked_check_quantity_el);
    }

    $find('input[name="check_sales_period"]', e.delegateTarget).checked = false;
    $find('input[name="check_quantity"]', e.delegateTarget).checked = false;
  }

  checkAllEvents(container_el);
};

export const changeStockType = async (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const form_el = $find('.pb-editor-form', e.delegateTarget);
  const stock_type = $val(e.currentTarget);
  if (
    box.sel('pb_stores_product->id') &&
    form_el.dataset.stock_type !== SpoSpoConstantS.pSpoStockType.NONE &&
    go(
      [...$qsa('input[type="number"][name="total_stock"]')].concat([
        ...$qsa('.pb-editor-form__option-quantity-input'),
      ]),
      compact,
      map($val),
      map(parseInt),
      sum,
      (quantity) => quantity > 0,
    ) &&
    !(await $.confirm('옵션과 재고 설정이 초기화됩니다.<br>계속 하시겠습니까?'))
  ) {
    const prev_stock_type_input = go(
      $closest('.pb-editor-form__control-stock', e.currentTarget),
      $find(`input[value=${form_el.dataset.stock_type}]`),
    );
    prev_stock_type_input.checked = true;
    return;
  } else {
    go(
      [...$qsa('input[type="number"][name="total_stock"]')].concat([
        ...$qsa('.pb-editor-form__option-quantity-input'),
      ]),
      compact,
      each($setVal(0)),
    );
  }

  $setAttr({ 'data-stock_type': stock_type }, form_el);

  const triggerSetOpListBtn = () => {
    const btn_set_op_list_btn = $find('.pb-editor-form__btn-set-op-list', e.delegateTarget);
    const btn_set_op_list_tpl_btn = $find('.pb-editor-form__btn-set-op-list-tpl', e.delegateTarget);
    if (btn_set_op_list_btn) {
      $trigger('click', btn_set_op_list_btn);
    }
    if (btn_set_op_list_tpl_btn) {
      $trigger('click', btn_set_op_list_tpl_btn);
    }
  };

  triggerSetOpListBtn();

  checkAllEvents(container_el);
};

export const clickAddOptionDirect = (e) => {
  const op_direct_area_el = $find('.pb-editor-form__op-direct-input-area', e.delegateTarget);
  const length = $findAll('.pb-editor-form__direct-option', op_direct_area_el).length;
  const current_idx =
    go(op_direct_area_el, $findAll('.pb-editor-form__direct-option'), map($attr('data-idx')), max) || 0;
  const next_idx = parseInt(current_idx) + 1;

  if (length >= 3) return MShopShareFramePopUpF.alert({ title: '최대 3개까지 추가 할 수 있습니다.' });

  go(MShopStudioPbProductTmplS.globalOptionDirectInput([next_idx, null]), $el, $appendTo(op_direct_area_el));
};

export const clickDelOptionDirect = (e) => {
  const op_direct_area_el = $find('.pb-editor-form__op-direct-input-area', e.delegateTarget);
  const length = $findAll('.pb-editor-form__direct-option', op_direct_area_el).length;

  if (length <= 1) {
    go(
      e.currentTarget,
      $closest('.pb-editor-form__direct-option'),
      $findAll('input[type="text"]'),
      each((el) => {
        $setVal('', el);
        setPbBodyLengthTxt(el);
      }),
    );
    return;
  }

  $remove($closest('.pb-editor-form__direct-option', e.currentTarget));
};

export const clickAddOption = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const option_area_el = $find('.pb-editor-form__options', e.delegateTarget);
  const length = $findAll('.pb-editor-form__option-control', option_area_el).length;
  const current_idx =
    go(option_area_el, $findAll('.pb-editor-form__option-control'), map($attr('data-idx')), max) || 0;
  const next_idx = parseInt(current_idx) + 1;

  if (length >= 3) return $.alert('최대 3개까지 추가 할 수 있습니다.');
  $append($el(MShopStudioPbProductTmplS.optionControl([next_idx, null])), option_area_el);

  checkAllEvents(container_el);
};

export const clickAddOptionTpl = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const option_area_el = $find('.pb-editor-form__options', e.delegateTarget);
  const length = $findAll('.pb-editor-form__option-control', option_area_el).length;
  const current_idx =
    go(option_area_el, $findAll('.pb-editor-form__option-control'), map($attr('data-idx')), max) || 0;
  const next_idx = parseInt(current_idx) + 1;

  if (length >= 3) return $.alert('최대 3개까지 추가 할 수 있습니다.');
  $append($el(MShopStudioPbProductTmplS.makeGlobalOptionControlHtml([next_idx, null])), option_area_el);

  checkAllEvents(container_el);
};

export const clickDelOption = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const option_area_el = $find('.pb-editor-form__options', e.delegateTarget);
  const length = $findAll('.pb-editor-form__option-control', option_area_el).length;

  if (length <= 1) {
    const input_els = $findAll(
      'input[type="text"]',
      $closest('.pb-editor-form__option-control', e.currentTarget),
    );

    each((input_el) => {
      $setVal('', input_el);
    }, input_els);

    box.set('pb_product_spo_options', []);
    return;
  }

  $remove($closest('.pb-editor-form__option-control', e.currentTarget));

  checkAllEvents(container_el);
};

const checkOpList = (option_control_els) => {
  const container_el = getEditorContainerEl(option_control_els[0]);
  const is_global = isGlobalMode(container_el);
  let is_pass = true;
  each((el) => {
    const name_el = $find('.pb-editor-form__body-option-group-name', el);
    const name_en_el = $find('.pb-editor-form__body-option-group-name_en', el);
    const name_jp_el = $find('.pb-editor-form__body-option-group-name_jp', el);
    const detail_info_el = $find('.pb-editor-form__body-option-detail-info', el);
    const detail_info_en_el = $find('.pb-editor-form__body-option-detail-info_en', el);
    const detail_info_jp_el = $find('.pb-editor-form__body-option-detail-info_jp', el);

    const name_res = MShopStudioPbProductS.checkSpoOptionName($val(name_el));
    const name_en_res = is_global
      ? MShopStudioPbProductS.checkSpoOptionName($val(name_en_el))
      : { result: true };
    const name_jp_res = is_global
      ? MShopStudioPbProductS.checkSpoOptionName($val(name_jp_el))
      : { result: true };
    const detail_res = MShopStudioPbProductS.checkSpoOptionDetailName($val(detail_info_el));
    const detail_en_res = is_global
      ? MShopStudioPbProductS.checkSpoOptionDetailName($val(detail_info_en_el))
      : { result: true };
    const detail_jp_res = is_global
      ? MShopStudioPbProductS.checkSpoOptionDetailName($val(detail_info_jp_el))
      : { result: true };

    errorStatusEl(name_res, name_el);
    errorStatusEl(detail_res, detail_info_el);

    if (is_global) {
      errorStatusEl(name_en_res, name_en_el);
      errorStatusEl(name_jp_res, name_jp_el);
      errorStatusEl(detail_en_res, detail_info_en_el);
      errorStatusEl(detail_jp_res, detail_info_jp_el);

      /* 입력을 안했을 경우 옵션별 수량 에러 메세지를 지우고 필수 입력 메세지만 띄우기 위함 */
      if (!detail_en_res.result) {
        errorPassSpoEl(detail_info_en_el, 'en');
      }
      if (!detail_jp_res.result) {
        errorPassSpoEl(detail_info_jp_el, 'jp');
      }
    }

    if (!name_res.result) is_pass = false;
    if (!name_en_res.result && is_global) is_pass = false;
    if (!name_jp_res.result && is_global) is_pass = false;
    if (!detail_res.result) is_pass = false;
  }, option_control_els);

  if (container_el) checkAllEvents(container_el);
  return is_pass;
};

const errorStatusSpoJpEl = ({ text, detail_info_jp$ }) => {
  return go(
    detail_info_jp$,
    $parent('.pb-editor-form__control'),
    tap($findAll('.pb-editor-form__body'), each($addClass('pb-editor-form__body--error'))),
    $find('.pb-editor-form__error-msg--spo-jp'),
    tap($setText(text)),
    $addClass('pb-editor-form__error-msg--show'),
  );
};
const errorStatusSpoEnEl = ({ text, detail_info_en$ }) => {
  return go(
    detail_info_en$,
    $parent('.pb-editor-form__control'),
    tap($findAll('.pb-editor-form__body'), each($addClass('pb-editor-form__body--error'))),
    $find('.pb-editor-form__error-msg--spo-en'),
    tap($setText(text)),
    $addClass('pb-editor-form__error-msg--show'),
  );
};

const errorPassSpoEl = (el, lang) => {
  go(
    el,
    $parent('.pb-editor-form__control'),
    $findAll(`.pb-editor-form__error-msg--spo-${lang}`),
    each($removeClass('pb-editor-form__error-msg--show')),
  );
};
const checkDetailInfoLen = ({
  detail_info_len,
  detail_info_en_len,
  detail_info_jp_len,
  detail_info_en$,
  detail_info_jp$,
}) => {
  if (detail_info_len !== detail_info_en_len && detail_info_len !== detail_info_jp_len) {
    errorStatusSpoJpEl({
      text: '언어별 옵션 개수가 맞지 않습니다. 콤마 , 로 구분하여 국문과 동일한 개수로 입력해 주세요.',
      detail_info_jp$,
    });
    errorStatusSpoEnEl({
      text: '언어별 옵션 개수가 맞지 않습니다. 콤마 , 로 구분하여 국문과 동일한 개수로 입력해 주세요.',
      detail_info_en$,
    });
    return true;
  }

  if (detail_info_len !== detail_info_en_len) {
    errorStatusSpoEnEl({
      text: '언어별 옵션 개수가 맞지 않습니다. 콤마 , 로 구분하여 국문과 동일한 개수로 입력해 주세요.',
      detail_info_en$,
    });
    return true;
  }

  if (detail_info_len !== detail_info_jp_len) {
    errorStatusSpoJpEl({
      text: '언어별 옵션 개수가 맞지 않습니다. 콤마 , 로 구분하여 국문과 동일한 개수로 입력해 주세요.',
      detail_info_jp$,
    });
    return true;
  }
  return false;
};

export const clickSetOpList = (e) => {
  try {
    const container_el = getEditorContainerEl(e.currentTarget);
    const is_global = isGlobalMode(container_el);
    const stock_type = UtilF.strValTrim($find('input[name="stock_type"]:checked', e.delegateTarget));
    const options_area_el = $find('.pb-editor-form__options-area', e.delegateTarget);
    const option_control_els = $findAll('.pb-editor-form__option-control', e.delegateTarget);

    const is_pass = checkOpList(option_control_els);
    if (!is_pass) return;

    const prev_spo_options = setGlobalSpoOptions(e.delegateTarget);
    const prev_group_names = pluck('name', prev_spo_options);
    const prev_group_names_en = pluck('name_en', prev_spo_options);
    const prev_group_names_jp = pluck('name_jp', prev_spo_options);
    const prev_spo_items_data = makeGlobalSpoItemsData({
      group_names: prev_group_names,
      group_names_en: prev_group_names_en,
      group_names_jp: prev_group_names_jp,
      spo_item_els: $findAll('.pb-editor-form__options-table tbody tr', e.delegateTarget),
      stock_type,
    });

    const spo_options = map((el) => {
      const id = $attr('data-id', el);
      const name = UtilF.strValTrim($find('.pb-editor-form__body-option-group-name', el));
      const name_en = is_global
        ? UtilF.strValTrim($find('.pb-editor-form__body-option-group-name_en', el))
        : null;
      const name_jp = is_global
        ? UtilF.strValTrim($find('.pb-editor-form__body-option-group-name_jp', el))
        : null;
      const detail_info$ = $find('.pb-editor-form__body-option-detail-info', el);
      const detail_info_en$ = is_global ? $find('.pb-editor-form__body-option-detail-info_en', el) : null;
      const detail_info_jp$ = is_global ? $find('.pb-editor-form__body-option-detail-info_jp', el) : null;
      const detail_info = UtilF.strValTrim(detail_info$);
      const detail_info_en = is_global ? UtilF.strValTrim(detail_info_en$) : null;
      const detail_info_jp = is_global ? UtilF.strValTrim(detail_info_jp$) : null;

      const detail_info_len = detail_info.split(',').length;
      const detail_info_en_len = is_global ? detail_info_en.split(',').length : null;
      const detail_info_jp_len = is_global ? detail_info_jp.split(',').length : null;
      if (
        is_global &&
        checkDetailInfoLen({
          detail_info_len,
          detail_info_en_len,
          detail_info_jp_len,
          detail_info$,
          detail_info_en$,
          detail_info_jp$,
        })
      ) {
        throw new Error('global_spo_error');
      }

      if (!name.length)
        throw new Error(MShopStudioPbProductS.pb_product_editor_error.spo_option_name_required.text);
      if (is_global && !name_en.length)
        throw new Error(MShopStudioPbProductS.pb_product_editor_error.spo_option_name_required.text);
      if (is_global && !name_jp.length)
        throw new Error(MShopStudioPbProductS.pb_product_editor_error.spo_option_name_required.text);

      return {
        id,
        name,
        name_en,
        name_jp,
        _: {
          spo_option_values: map((name) => {
            if (!name.length)
              throw new Error(
                MShopStudioPbProductS.pb_product_editor_error.spo_option_detail_name_required.text,
              );
            return { name: name.trim() };
          }, detail_info?.split(',')),
          spo_option_values_en: map((name_en) => {
            if (is_global && !name_en.length)
              throw new Error(
                MShopStudioPbProductS.pb_product_editor_error.spo_option_detail_name_required.text,
              );
            return { name_en: name_en.trim() };
          }, detail_info_en?.split(',')),
          spo_option_values_jp: map((name_jp) => {
            if (is_global && !name_jp.length)
              throw new Error(
                MShopStudioPbProductS.pb_product_editor_error.spo_option_detail_name_required.text,
              );
            return { name_jp: name_jp.trim() };
          }, detail_info_jp?.split(',')),
        },
      };
    }, option_control_els);

    const total_spo_items_lang_length = go(void 0, () => {
      return {
        kr_length: reduce(
          (num, spo_option) => {
            return num * parseInt(sel('_.spo_option_values.length', spo_option));
          },
          1,
          spo_options,
        ),
        en_length: reduce(
          (num, spo_option) => {
            return num * parseInt(sel('_.spo_option_values_en.length', spo_option));
          },
          1,
          spo_options,
        ),
        jp_length: reduce(
          (num, spo_option) => {
            return num * parseInt(sel('_.spo_option_values_jp.length', spo_option));
          },
          1,
          spo_options,
        ),
      };
    });

    if (
      total_spo_items_lang_length.kr_length > 400 ||
      total_spo_items_lang_length.en_length > 400 ||
      total_spo_items_lang_length.jp_length > 400
    ) {
      throw new Error(MShopStudioPbProductS.pb_product_editor_error.option_length_400.text);
    }

    box.set('pb_product_spo_options', spo_options);

    const group_names = pluck('name', spo_options);
    const group_names_en = pluck('name_en', spo_options);
    const group_names_jp = pluck('name_jp', spo_options);
    const spo_items_data = go(
      spo_options,
      map((option) => pluck('name', option._.spo_option_values)),
    );
    const spo_items_data_en =
      is_global &&
      go(
        spo_options,
        map((option) => pluck('name_en', option._.spo_option_values_en)),
      );
    const spo_items_data_jp =
      is_global &&
      go(
        spo_options,
        map((option) => pluck('name_jp', option._.spo_option_values_jp)),
      );

    const new_spo_items = makeDataSpoItems(spo_items_data);
    const new_spo_items_en = is_global && makeDataSpoItems(spo_items_data_en);
    const new_spo_items_jp = is_global && makeDataSpoItems(spo_items_data_jp);

    const zip_spo_items = go(
      zip(new_spo_items, new_spo_items_en, new_spo_items_jp),
      map(([items, items_en, items_jp]) => {
        return go(
          items,
          ippL,
          map(([idx]) => {
            return {
              name: items[idx],
              name_en: is_global && items_en[idx],
              name_jp: is_global && items_jp[idx],
            };
          }),
        );
      }),
    );

    const spo_items = go(
      zip_spo_items,
      map((m) => {
        const spo_item = {
          group_names_option_names: go(
            m,
            ippL,
            map(([idx, value]) => {
              const { name, name_en = null, name_jp = null } = value;
              return {
                group_name: group_names[idx],
                group_name_en: group_names_en[idx],
                group_name_jp: group_names_jp[idx],
                option_name: name,
                option_name_en: name_en,
                option_name_jp: name_jp,
                global_flag: true,
              };
            }),
          ),
          price: 0,
        };

        const find_spo_item = go(
          prev_spo_items_data,
          find((_spo_item) => {
            return (
              JSON.stringify(spo_item.group_names_option_names) ==
              JSON.stringify(_spo_item.group_names_option_names)
            );
          }),
        );

        if (find_spo_item) {
          spo_item.id = find_spo_item.id;
          spo_item.price = find_spo_item.price;
        }

        if (stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK) {
          spo_item._ = {
            spo_item_stock: { quantity: 0 },
          };

          if (find_spo_item && sel('_.spo_item_stock.quantity', find_spo_item)) {
            spo_item._.spo_item_stock.quantity = sel('_.spo_item_stock.quantity', find_spo_item);
          }
        }

        return spo_item;
      }),
    );

    $setHTML(
      `${MShopStudioPbProductTmplS.globalSpoItemsArea({
        spo_options,
        spo_items,
        stock_type,
      })}
      <p class="pb-editor-form__error-msg">
        ${MShopStudioPbProductS.pb_product_editor_error.required.text}
      </p>`,
      options_area_el,
    );

    $setText(spo_items.length, $find('.pb-editor-form__label-txt-sub2', e.delegateTarget));
    checkAllEvents(container_el);

    const toggle_sku_el = go(container_el, $find('.pb-editor-form__toggle-sku'));
    if (toggle_sku_el) {
      toggle_sku_el.checked = false;
    }
    // checkQuantity(e);
  } catch (_err) {
    if (_err.message === 'global_spo_error') {
      return;
    }
    console.error(_err);
    $.alert(_err.message);
  }
};

export const changeCheckAllSpoItem = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const checked_els = $findAll('.pb-editor-form__option-check:checked', e.delegateTarget);
  const check_els = $findAll('.pb-editor-form__option-check', e.delegateTarget);

  if (checked_els.length && checked_els.length == check_els.length) {
    each((el) => {
      el.checked = false;
    }, check_els);
    e.currentTarget.checked = false;
    return;
  }

  each((el) => {
    el.checked = true;
  }, check_els);
  e.currentTarget.checked = true;

  checkAllEvents(container_el);
};

export const clickDelSelectedSpoItems = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const checked_els = $findAll('.pb-editor-form__option-check:checked', e.delegateTarget);
  const check_els = $findAll('.pb-editor-form__option-check', e.delegateTarget);
  const goods_type_id = container_el.dataset.goods_type_id;
  const toggle_sku_el = go(container_el, $find('.pb-editor-form__toggle-sku'));

  if (checked_els.length == check_els.length) {
    $setHTML(
      GoodsTypeS.isTpl(goods_type_id) ? globalNoOptionTable({}) : '',
      $find('.pb-editor-form__options-area', e.delegateTarget),
    );
    if (toggle_sku_el) {
      toggle_sku_el.checked = false;
    }
  } else {
    each((el) => {
      $remove($closest('tr', el));
    }, checked_els);
  }

  setOptionControls(e.delegateTarget);

  checkAllEvents(container_el);
  // checkQuantity(e);
};

export const clickChangeOptionSize = ({ currentTarget: ct }) => {
  const tbody = go(ct, $closest('tbody'));
  const tr = go(ct, $closest('tr'));
  const option_names = go(
    tr,
    $findAll('.spo-item-text:first-child'),
    map((el) => el.innerText),
  );
  const [row] = go(
    tbody,
    $findAll('tr'),
    ippL,
    filter(([i, row]) => row === tr),
  );

  const [row_index] = row;
  const { sku, spo_id, spo_item_id } = $data(tr);
  MShopStudioPbProductSizeF.openFrame({ option_names, row_index, spo_id, spo_item_id, sku });
};

export const clickDelSpoItem = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const is_global = isGlobalMode();

  $remove($closest('tr', e.currentTarget));
  const spo_item_els = $findAll('.pb-editor-form__options-table tbody tr', e.delegateTarget);
  if (!spo_item_els.length) {
    $setHTML(
      is_global ? globalNoOptionTable({}) : '',
      $find('.pb-editor-form__options-area', e.delegateTarget),
    );
    const toggle_sku_el = go(container_el, $find('.pb-editor-form__toggle-sku'));
    if (toggle_sku_el) {
      toggle_sku_el.checked = false;
    }
  }

  setOptionControls(e.delegateTarget);

  checkAllEvents(container_el);

  // checkQuantity(e);
};

export const changeCheckSpoItem = (e) => {
  const checked_els = $findAll('.pb-editor-form__option-check:checked', e.delegateTarget);
  const check_els = $findAll('.pb-editor-form__option-check', e.delegateTarget);
  const check_all_el = $find('.pb-editor-form__option-check-all', e.delegateTarget);
  check_all_el.checked = checked_els.length && checked_els.length == check_els.length;
};

export const changeCheckSalesPeriod = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  if (e.currentTarget.checked) {
    $addClass(
      'pb-editor-form__control-sales-period--show',
      $find('.pb-editor-form__control-sales-period', e.delegateTarget),
    );
  } else {
    $removeClass(
      'pb-editor-form__control-sales-period--show',
      $find('.pb-editor-form__control-sales-period', e.delegateTarget),
    );
  }

  checkAllEvents(container_el);
};

export const changeCheckQuantity = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  if (e.currentTarget.checked) {
    $addClass(
      'pb-editor-form__control-quantity--show',
      $find('.pb-editor-form__control-quantity', e.delegateTarget),
    );
  } else {
    $removeClass(
      'pb-editor-form__control-quantity--show',
      $find('.pb-editor-form__control-quantity', e.delegateTarget),
    );
  }

  checkAllEvents(container_el);
};

export const changeCheckMaxPurchasePerUser = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  if (e.currentTarget.checked) {
    $addClass(
      'pb-editor-form__control-max_purchase_per_user--show',
      $find('.pb-editor-form__control-max_purchase_per_user', e.delegateTarget),
    );
  } else {
    $removeClass(
      'pb-editor-form__control-max_purchase_per_user--show',
      $find('.pb-editor-form__control-max_purchase_per_user', e.delegateTarget),
    );
  }

  checkAllEvents(container_el);
};

export const changeQnaType = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const qna_type = UtilF.strValTrim(e.currentTarget);

  if (qna_type == MShopStudioPbProductConstantS.QNA_TYPES.SNS) {
    const sns_el = $find('.pb-editor-form__qna-type-sns--hide', e.delegateTarget);
    if (!sns_el) return;
    $removeClass('pb-editor-form__qna-type-sns--hide', sns_el);

    const board_el = $find('.pb-editor-form__qna-type-mp', e.delegateTarget);
    $addClass('pb-editor-form__qna-type-mp--hide', board_el);
  } else {
    const board_el = $find('.pb-editor-form__qna-type-mp--hide', e.delegateTarget);
    if (!board_el) return;
    $removeClass('pb-editor-form__qna-type-mp--hide', board_el);

    const sns_el = $find('.pb-editor-form__qna-type-sns', e.delegateTarget);
    $addClass('pb-editor-form__qna-type-sns--hide', sns_el);
  }

  checkAllEvents(container_el);
};

export const changeStoresDeliveryCompanies = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const selected_stores_delivery_company = $data(e.currentTarget).find(({ id }) => {
    return id == e.currentTarget.value;
  });
  $setHTML(
    MShopStudioPbProductTmplS.selectedStoresDeliveryCompany({ selected_stores_delivery_company }),
    $next($closest('.pb-editor-form__body', e.currentTarget)),
  );

  if (isGlobalMode()) {
    const stores_delivery_company_id_en_el = $find(
      'select[name="stores_delivery_company_id_en"]',
      container_el,
    );
    const stores_delivery_company_id_jp_el = $find(
      'select[name="stores_delivery_company_id_jp"]',
      container_el,
    );
    const stores_delivery_company_id_en = UtilF.strValTrim(stores_delivery_company_id_en_el) || null;
    const stores_delivery_company_id_jp = UtilF.strValTrim(stores_delivery_company_id_jp_el) || null;
    const res = MShopStudioPbProductS.checkGlobalDeliveryCompanyIds(
      stores_delivery_company_id_en,
      stores_delivery_company_id_jp,
    );
    errorStatusEl(res, stores_delivery_company_id_en_el);
    errorStatusEl(res, stores_delivery_company_id_jp_el);
  }

  checkAllEvents(container_el);
};

export const changeRefundInfoType = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const refund_info_el = $find('.pb-editor-form__control--refund-info', e.delegateTarget);
  const refund_info_textarea = $find('textarea[name="refund_info"]', e.delegateTarget);
  const refund_info_type = UtilF.strValTrim(e.currentTarget);
  $setAttr({ refund_info_type }, refund_info_el);
  if (refund_info_type == MShopStudioPbProductConstantS.REFUND_INFO_TYPES.normal) {
    if ($val(refund_info_textarea) != MShopStudioPbProductTmplS.refundInfoNormalEx) {
      $setVal(MShopStudioPbProductTmplS.refundInfoNormalEx, refund_info_textarea);

      if (isGlobalMode()) {
        const refund_info_textarea_en = $find('textarea[name="refund_info_en"]', e.delegateTarget);
        const refund_info_textarea_jp = $find('textarea[name="refund_info_jp"]', e.delegateTarget);
        $setVal(MShopStudioPbProductTmplS.refundInfoNormalExEn, refund_info_textarea_en);
        $setVal(MShopStudioPbProductTmplS.refundInfoNormalExJp, refund_info_textarea_jp);
      }
    }
  } else {
    if ($val(refund_info_textarea) != MShopStudioPbProductTmplS.refundInfoPrintOnDemandEx) {
      $setVal(MShopStudioPbProductTmplS.refundInfoPrintOnDemandEx, refund_info_textarea);

      if (isGlobalMode()) {
        const refund_info_textarea_en = $find('textarea[name="refund_info_en"]', e.delegateTarget);
        const refund_info_textarea_jp = $find('textarea[name="refund_info_jp"]', e.delegateTarget);
        $setVal(MShopStudioPbProductTmplS.refundInfoPrintOnDemandExEn, refund_info_textarea_en);
        $setVal(MShopStudioPbProductTmplS.refundInfoPrintOnDemandExJp, refund_info_textarea_jp);
      }
    }
  }

  checkAllEvents(container_el);
};

export const focusoutOption = (e) => {
  const container_el = getEditorContainerEl(e.currentTarget);
  const prev_value = $attr('data-prev_value', e.currentTarget).trim();
  const current_value = $val(e.currentTarget).trim();

  if (prev_value == current_value) {
    return $setVal(current_value, e.currentTarget);
  }

  const res = MShopStudioPbProductS.checkSpoOptionName(current_value);
  if (!res.result) {
    $setVal(prev_value, e.currentTarget);
    return $.alert(res.error.text);
  }

  const has_option_name = go(
    box.sel('pb_product_spo_options'),
    (spo_options) => {
      const result = [];
      each((spo_option) => {
        result.push(...spo_option._.spo_option_values);
      }, spo_options);
      return result;
    },
    find((spo_option_value) => {
      return spo_option_value.name == current_value;
    }),
  );

  if (has_option_name) {
    $setVal(prev_value, e.currentTarget);
    return $.alert('이미 있는 옵션입니다.');
  }

  const option_name_els = $findAll(
    `.pb-editor-form__option-name-input[data-prev_value="${prev_value}"]`,
    e.delegateTarget,
  );

  each((el) => {
    $setAttr({ 'data-id': '' }, $parent('tr[data-id]', el));
    $setAttr({ 'data-spo_item_stock_id': '' }, $parent('[data-spo_item_stock_id]', el));
    $setAttr({ 'data-prev_value': current_value }, el);
    $setVal(current_value, el);
  }, option_name_els);

  setOptionControls(e.delegateTarget);
  checkAllEvents(container_el);
};

export const focusoutEnOption = (e) => {
  const prev_value = $attr('data-prev_value_en', e.currentTarget).trim();
  const current_value = $val(e.currentTarget).trim();

  if (prev_value == current_value) {
    return $setVal(current_value, e.currentTarget);
  }

  const res = MShopStudioPbProductS.checkSpoOptionName(current_value);
  if (!res.result) {
    $setVal(prev_value, e.currentTarget);
    return $.alert(res.error.text);
  }

  const option_name_els = $findAll(
    `.pb-editor-form__option-name-input[data-prev_value_en="${prev_value}"]`,
    e.delegateTarget,
  );

  each((el) => {
    $setVal(current_value, el);
  }, option_name_els);

  const container_el = getEditorContainerEl(e.currentTarget);
  checkAllEvents(container_el);
};

export const focusoutJpOption = (e) => {
  const prev_value = $attr('data-prev_value_jp', e.currentTarget).trim();
  const current_value = $val(e.currentTarget).trim();

  if (prev_value == current_value) {
    return $setVal(current_value, e.currentTarget);
  }

  const res = MShopStudioPbProductS.checkSpoOptionName(current_value);
  if (!res.result) {
    $setVal(prev_value, e.currentTarget);
    return $.alert(res.error.text);
  }
  const option_name_els = $findAll(
    `.pb-editor-form__option-name-input[data-prev_value_jp="${prev_value}"]`,
    e.delegateTarget,
  );

  each((el) => {
    $setVal(current_value, el);
  }, option_name_els);

  const container_el = getEditorContainerEl(e.currentTarget);
  checkAllEvents(container_el);
};

export const setOptionControls = (container_el) => {
  const spo_options = setGlobalSpoOptions(container_el);

  $setHTML(
    MShopStudioPbProductTmplS.globalOptionControls(spo_options),
    $find('.pb-editor-form__options', container_el),
  );
};

export const initLimitedSalesPeriod = (container_el) => {
  const now = new Date();

  // box로 처리??
  const { sell_start_at, sell_end_at } = go(
    box.sel('pb_stores_product') || {},
    pick(['sell_start_at', 'sell_end_at']),
    mapObject((a) => (a ? new Date(a) : null)),
  );

  const sell_at_el$ = $find('input[name="sell_at"]', container_el);
  const sell_start_at_el$ = $setVal(
    sell_start_at || UtilS.clearTime(now),
    $find('input[name="sell_start_at"]', container_el),
  );
  const sell_end_at_el$ = $setVal(
    sell_end_at || UtilS.clearTime(addDays(now, 7)),
    $find('input[name="sell_end_at"]', container_el),
  );
  const flat = flatpickr(sell_at_el$, {
    mode: 'range',
    locale: Korean,
    enableTime: true,
    enableSeconds: true,
    minDate: UtilS.clearTime(sell_start_at ? min([sell_start_at, now]) : now),
    disableMobile: true,
    defaultDate: [sell_start_at || UtilS.clearTime(now), sell_end_at || UtilS.clearTime(addDays(now, 7))],
    static: true,
    onClose: (dates, values) => {
      const ats = (values || '').split('~');
      if (ats.length < 2) {
        $setVal('', sell_start_at_el$);
        $setVal('', sell_end_at_el$);
        setTimeout(() => flat.clear(), 0);
      } else {
        const [start_at = '', end_at = ''] = ats;
        $setVal(start_at.trim(), sell_start_at_el$);
        $setVal(end_at.trim(), sell_end_at_el$);
      }
    },
  });
};

const makeSpoItemsData = ({ group_names, spo_item_els, stock_type }) => {
  if (stock_type == SpoSpoConstantS.pSpoStockType.NONE && !spo_item_els.length) return;

  // 옵션이 정상적으로 있는 경우
  return go(
    spo_item_els,
    map((el) => {
      const group_names_option_names = go(
        $findAll('input[name="spo_item"]', el),
        ippL,
        map(([idx, name_el]) => {
          return {
            group_name: group_names[idx],
            option_name: UtilF.strValTrim(name_el),
          };
        }),
      );
      const spo_item = {
        group_names_option_names,
        price: PriceS.price($val($find('.pb-editor-form__option-price-input', el))),
      };

      const spo_item_stock_id = $attr('data-spo_item_stock_id', el);
      if (spo_item_stock_id) spo_item.spo_item_stock_id = spo_item_stock_id;

      if (stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK) {
        const quantity_el = $find('.pb-editor-form__option-quantity-input', el);
        const quantity = quantity_el ? UtilF.strValTrimParseInt(quantity_el) : 0;

        const real_quantity_el = $find('.pb-editor-form__option-real-quantity-input', el);
        const real_quantity = real_quantity_el ? UtilF.strValTrimParseInt(real_quantity_el) : 0;

        spo_item._ = {
          spo_item_stock: {
            quantity,
            real_quantity,
          },
        };
        if (spo_item_stock_id) spo_item._.spo_item_stock.id = spo_item_stock_id;
      }

      // TODO 일단 OPTION_STOCK 일때는 id 삭제
      // 나중에 옵션 순서와 OPTION_STOCK 인 경우에도 data-id를 유지한다거나 하는 부분 확인해서 수정해야함
      const id = $attr('data-id', el);
      if (id) spo_item.id = id;

      // console.log(spo_item.spo_item_stock_id, '==== spo_item_stock_id');

      return spo_item;
    }),
  );
};

const formData = pipe((container_el) => {
  const name_el = $find('input[name="name"]', container_el);
  const profit_el = $find('input[name="profit"]', container_el);
  const seller_profit_share_el = $find('input[name="seller_profit_share"]', container_el);
  const quantity_el = $find('input[name="quantity"]', container_el);
  const is_quantity_public = go(void 0, () => {
    const input_el = $find('input[name="is_quantity_public"]:checked', container_el);
    if (!input_el) return false;
    return go(input_el, $val, UtilS.toBoolean);
  });
  const max_purchase_per_user_el = $find('input[name="max_purchase_per_user"]', container_el);
  const sell_start_at_el = $find('input[name="sell_start_at"]', container_el);
  const sell_end_at_el = $find('input[name="sell_end_at"]', container_el);

  const stock_type = UtilF.strValTrim($find('input[name="stock_type"]:checked', container_el));
  const product_type_id = UtilF.strValTrim($find('select[name="product_type"]', container_el));
  const sp_cate_list_id = UtilF.strValTrimParseInt($find('select[name="cate_lists"]', container_el));
  const sp_cate_item_id = UtilF.strValTrimParseInt($find('select[name="cate_items"]', container_el));
  const cate_items = $find('select[name="cate_items"]', container_el);
  const options = cate_items.options;
  const selectedIndex = cate_items.selectedIndex;
  const is_cloth = options[selectedIndex]?.dataset?.isCloth === 'true';
  const is_public = go(container_el, $find('input[name="public"]:checked'), $val, UtilS.toBoolean);
  const has_limited_quantity = $find('input[name="check_quantity"]', container_el).checked;
  const has_max_purchase_per_user = $find('input[name="check_max_purchase_per_user"]', container_el).checked;
  const has_limited_period = $find('input[name="check_sales_period"]', container_el).checked;
  const qna_type = UtilF.strValTrim($find('input[name="qna_type"]:checked', container_el));
  const qna_link = UtilF.strValTrim($find('input[name="qna_link"]', container_el));
  const stores_delivery_company_id =
    UtilF.strValTrim($find('select[name="stores_delivery_company_id"]', container_el)) || void 0;
  const product_basic_info =
    UtilF.strValTrim($find('textarea[name="product_basic_info"]', container_el)) || null;

  const kc_cert_radio_checked_el$ = $find(
    'input[type="radio"][name="kc-certification"]:checked',
    container_el,
  );

  const is_kc_cert_checked = kc_cert_radio_checked_el$ ? kc_cert_radio_checked_el$.value === 'show' : false;

  const certification_info_el$ = $find('input[name="certification_info"]', container_el);
  const certification_info = certification_info_el$ ? UtilF.strValTrim(certification_info_el$) || null : null;

  const shipping_date_hidden_el$ = $find('input[name="shipping_date_hidden"]:checked', container_el);

  const shipping_date_hidden = shipping_date_hidden_el$ ? UtilF.strValTrim(shipping_date_hidden_el$) : null;

  const refund_info_type = UtilF.strValTrim($find('input[name="refund_info_type"]:checked', container_el));
  const refund_info = UtilF.strValTrim($find('textarea[name="refund_info"]', container_el)) || null;

  const spo_text_options = go(container_el, $findAll('.pb-editor-form__body-input-op-direct'), (els) => {
    if (!els || !els.length) return [];
    if (els.length == 1 && $val(els[0]) == '') return [];
    return map((el) => {
      return { id: $attr('data-id', el), name: UtilF.strValTrim(el) };
    }, els);
  });

  // 재고형인데 옵션이 없는 경우는 에러처리 해야함
  const spo_options = setGlobalSpoOptions(container_el);
  const group_names = pluck('name', spo_options);
  const spo_items = go(
    void 0,
    () => {
      return makeSpoItemsData({
        group_names,
        spo_item_els: $findAll('.pb-editor-form__options-table tbody tr', container_el),
        stock_type,
      });
    },
    (spo_items) => {
      if (stock_type != SpoSpoConstantS.pSpoStockType.OPTION_STOCK) return spo_items;

      const prev_spo_item_stocks = go(
        box.sel('im_pb_stores_product->_->product->_->spo->_->spo_items'),
        flatMap((im_spo_item) => {
          return im_spo_item?._?.spo_item_stock;
        }),
        compact,
      );

      if (!prev_spo_item_stocks?.length) return spo_items;

      each((spo_item) => {
        const prev_spo_item_stock = find((prev_spo_item_stock) => {
          return sel('_.spo_item_stock.id', spo_item) == prev_spo_item_stock.id;
        }, prev_spo_item_stocks);

        if (prev_spo_item_stock) {
          spo_item._.spo_item_stock.plus_minus_quantity =
            spo_item._.spo_item_stock.quantity - prev_spo_item_stock.quantity;
        }
      }, spo_items);
      return spo_items;
    },
  );

  const spo_producing_period = go(container_el, $find('.pb-editor-form__producing-period'), (el) => {
    if (!el) return;
    const avg_days = UtilF.strValTrim($find('input[name="avg_days"]', el));
    const max_days = UtilF.strValTrim($find('input[name="max_days"]', el));

    if (!avg_days.length || !max_days.length) return;

    return {
      id: $attr('data-id', el),
      spo_id: box.sel('pb_stores_product->_->product->spo_id'),
      avg_days,
      max_days,
    };
  });

  const spo_item_stock = go(void 0, () => {
    if (stock_type != SpoSpoConstantS.pSpoStockType.TOTAL_STOCK) return;
    const total_stock_el = $find('input[name="total_stock"]', container_el);
    const real_total_stock_el = $find('input[name="real_total_stock"]', container_el);
    const total_stock = UtilF.strValTrimParseInt(total_stock_el);
    const real_total_stock = UtilF.strValTrimParseInt(real_total_stock_el);
    const total_spo_item_stock_id = $attr('data-spo_item_stock_id', total_stock_el);
    const _spo_item_stock = {
      quantity: total_stock,
      real_quantity: real_total_stock,
    };

    if (total_spo_item_stock_id) _spo_item_stock.id = total_spo_item_stock_id;

    const prev_spo_item_stock = sel('_.product._.spo._.spo_item_stock', box.sel('im_pb_stores_product'));
    if (prev_spo_item_stock && prev_spo_item_stock.id == total_spo_item_stock_id) {
      _spo_item_stock.plus_minus_quantity = total_stock - prev_spo_item_stock.quantity;
    }

    return _spo_item_stock;
  });
  const store_id = box.sel('store_id') || box.sel('pb_stores_product->store_id');

  const stores_product = {
    id: box.sel('pb_stores_product->id'),
    store_id,
    is_free_shipping_product: false,
    product_type_id,
    sp_cate_list_id,
    sp_cate_item_id,
    is_cloth,
    max_purchase_per_user: has_max_purchase_per_user ? $val(max_purchase_per_user_el) : null,
    has_limited_quantity,
    quantity: has_limited_quantity ? $val(quantity_el) : null,
    is_quantity_public,
    sell_start_at: has_limited_period ? moment($val(sell_start_at_el)).toDate() : null,
    sell_end_at: has_limited_period ? moment($val(sell_end_at_el)).toDate() : null,
    qna_type,
    qna_link: qna_type == MShopStudioPbProductConstantS.QNA_TYPES.SNS ? qna_link : null,
    product_basic_info,
    is_kc_cert_checked,
    certification_info,
    refund_info_type,
    refund_info,
    _: {
      product: {
        id: box.sel('pb_stores_product->_->product->id'),
        name: $val(name_el).trim(),
        profit: PriceS.price($val(profit_el).trim()),
        store_id,
        thumbnails: {
          value: go(
            $findAll('.pb-thumbnail-editor__item--on', container_el),
            ippL,
            map(([idx, el]) => {
              const file_id = $attr('data-file-id', el);
              const thumb_data = $data(el);

              return {
                /* @@thumbnail is_pb 체크@@ */
                ...pick(['width', 'height', 'is_pb'], thumb_data),
                url: file_id ? '' : thumb_data.url,
                is_thumb: idx == 0,
              };
            }),
          ),
        },
        spo_id: box.sel('pb_stores_product->_->product->spo_id'),
        is_public,
        _: {
          stores_delivery_companies_product: { stores_delivery_company_id },
          spo: {
            id: box.sel('pb_stores_product->_->product->spo_id'),
            stock_type,
            _: {
              spo_options,
              spo_items,
              spo_text_options,
              spo_producing_period,
              spo_item_stock,
            },
          },
        },
        ...(MShopStudioPreDiscountProductS.isPreProductDiscountSupported()
          ? {
              pre_discount_price: PriceS.price($val($find('#pre-discount-price', container_el)).trim()),
            }
          : {}),
      },
    },
  };

  if (!isNil(shipping_date_hidden)) {
    stores_product.shipping_date_hidden = UtilS.toBoolean(shipping_date_hidden);
  }

  if (seller_profit_share_el) {
    stores_product.seller_profit_share = UtilF.strValTrimParseInt(seller_profit_share_el) / 100;
  }

  return { stores_product, container_el };
}, mutateStoreProductForGlobal);

const errorsShow = (stores_product, errors, container_el) => {
  const alertError = [];
  go(
    errors,
    each((err) => {
      if (err.type == 'pd_name') {
        focusoutName($find('input[name="name"]', container_el));
        alertError.push(err);
      } else if (err.type == 'pd_name_en') {
        focusoutName($find('input[name="name_en"]', container_el));
        alertError.push(err);
      } else if (err.type == 'pd_name_jp') {
        focusoutName($find('input[name="name_jp"]', container_el));
        alertError.push(err);
      } else if (err.type == 'pd_profit') {
        focusoutProfit($find('input[name="profit"]', container_el));
        alertError.push(err);
      } else if (err.type == 'pd_seller_profit_share') {
        focusoutPdSellerProfitShare($find('input[name="seller_profit_share"]', container_el));
        const seller_profit_share_el = $find('input[name="seller_profit_share"]', container_el);
        const res = MShopStudioPbProductS.checkServerPdSellerProfitShare($val(seller_profit_share_el));
        errorStatusEl(res, seller_profit_share_el);
        alertError.push(err);
      } else if (err.type == 'total_stock') {
        focusoutTotalStock($find('input[name="total_stock"]', container_el));
        alertError.push(err);
      } else if (err.type == 'real_total_stock') {
        focusoutTotalStock($find('input[name="real_total_stock"]', container_el));
        alertError.push(err);
      } else if (err.type == 'thumbnails') {
        alertError.push(err);
      } else if (err.type == 'category') {
        errorStatusEl(err, $find('select[name="cate_lists"]', container_el));
        alertError.push(err);
      } else if (err.type == 'avg_days') {
        focusoutAvgDays($find('input[name="avg_days"]', container_el));
        alertError.push(err);
      } else if (err.type == 'max_days') {
        focusoutMaxDays($find('input[name="max_days"]', container_el));
        alertError.push(err);
      } else if (err.type == 'option_price') {
        alertError.push(err);
      } else if (err.type == 'limited') {
        alertError.push(err);
      } else if (err.type == 'limited_quantity') {
        alertError.push(err);
      } else if (err.type == 'limited_max_purchase_per_user') {
        alertError.push(err);
      } else if (err.type == 'limited_period') {
        alertError.push(err);
      } else if (err.type == 'spo_items') {
        alertError.push(err);
      } else if (err.type == 'option_stock') {
        alertError.push(err);
      } else if (err.type == 'qna_link') {
        focusoutQnaLink($find('input[name="qna_link"]', container_el));
        alertError.push(err);
      } else if (err.type == 'is_agree') {
        alertError.push(err);
      } else if (err.type == 'certification_info') {
        const certification_info_el = $find('input[name="certification_info"]', container_el);
        errorStatusEl(err, certification_info_el);
        alertError.push(err);
      } else if (err.type == 'hs_code') {
        const hs_code_el = $find('input[name="hs_code"]', container_el);
        errorStatusEl(err, hs_code_el);
        alertError.push(err);
      } else if (err.type === 'material') {
        const material_el = $find('input[name="material"]', container_el);
        errorStatusEl(err, material_el);
        alertError.push(err);
      } else if (err.type === 'sku_size') {
        const options_area_el = $find('.pb-editor-form__options-area', container_el);
        const error_msg_el = $find('.pb-editor-form__error-msg', options_area_el);
        const table_rows = go(options_area_el, $findAll('tbody > tr'));
        go(error_msg_el, tap($setHTML(err.error.text)), $addClass('pb-editor-form__error-msg--show'));
        go(
          table_rows,
          map((el) => {
            const data = $data(el);
            const { width, height, length, weight } = data?.sku || {};
            const valid = width && height && length && weight;
            const option_button_size_el = go(el, $find('.pb-editor-form__option-btn-size'));
            if (option_button_size_el) {
              option_button_size_el.classList.toggle('invalid', !valid);
            }
          }),
        );

        alertError.push(err);
      } else if (err.type === 'global_delivery_company' && isGlobalMode()) {
        /* 해외 배송 가능으로 선택했을 경우 해외 배송 정책이 최소 하나 이상 선택되어 있어야 합니다. */
        const stores_delivery_company_id_en_el = $find(
          'select[name="stores_delivery_company_id_en"]',
          container_el,
        );
        const stores_delivery_company_id_jp_el = $find(
          'select[name="stores_delivery_company_id_jp"]',
          container_el,
        );
        errorStatusEl(err, stores_delivery_company_id_en_el);
        errorStatusEl(err, stores_delivery_company_id_jp_el);
        alertError.push(err);
      }
    }),
  );

  errorMoveScroll(errors[0], container_el);

  if (alertError.length) {
    return MShopShareFramePopUpF.alert({
      title: alertError[0].error.title,
      body: alertError[0].error.text,
    });
  }

  if (!alertError.length && errors.length) {
    $.alert('잘못된 정보가 있습니다.<br>정보를 확인해주세요.');
  }
};

const errorMoveScroll = (err, container_el) => {
  const is_mobile = MShopUtilF.isMobile();
  const { y } = is_mobile ? { y: 0 } : $find('.pb-editor__wrapper', container_el).getBoundingClientRect();
  const scroll_el = is_mobile ? $qs('html, body') : $find('.pb-editor__right', container_el);
  const scroll_top = scroll_el.scrollTop;

  if (err.type == 'pd_name') {
    const { top } = $find('.pb-editor-form__name', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'pd_profit') {
    const { top } = $find('.pb-editor-form__profit', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'pd_seller_profit_share') {
    const { top } = $find('input[name="seller_profit_share"]', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'total_stock') {
    const { top } = $find('.pb-editor-form__total-stock', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'real_total_stock') {
    const { top } = $find('.pb-editor-form__real_total-stock', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'thumbnails') {
    $setScrollTop(0, scroll_el);
  } else if (err.type == 'category') {
    const { top } = $find('.pb-editor-form__category', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'avg_days') {
    const { top } = $find('.pb-editor-form__producing-period', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'max_days') {
    const { top } = $find('.pb-editor-form__producing-period', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'limited') {
    const { top } = $find('.pb-editor-form__control-limited', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'limited_quantity') {
    const { top } = $find('.pb-editor-form__control-limited', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'limited_max_purchase_per_user') {
    const { top } = $find(
      '.pb-editor-form__control-max_purchase_per_user',
      container_el,
    ).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'limited_period') {
    const { top } = $find('.pb-editor-form__control-limited', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'spo_items') {
    const { top } = $find('.pb-editor-form__control-option', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'option_price') {
    const { top } = $find('.pb-editor-form__control-option', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'option_stock') {
    const { top } = $find('.pb-editor-form__control-option', container_el).getBoundingClientRect();
    $setScrollTop(scroll_top + top - y - 10, scroll_el);
  } else if (err.type == 'certification_info') {
    $find('input[name="certification_info"]', container_el).focus();
  }
};

const checkAllEvents = (container_el) => {
  const stores_product = formData(container_el);
  const is_global = isGlobalMode(container_el);

  const errors = MShopStudioPbProductS.checkDataErrors(
    stores_product,
    box.sel('pb_thumbnail_photos'),
    is_global,
    stores_product._.create_sku,
    GoodsTypeS.isTpl(container_el.dataset.goods_type_id),
  );

  const btn_submit_els = $findAll('.pb-editor-form__btn-submit', container_el);
  if (errors.length) {
    each($addClass('pb-editor-form__btn-submit--disabled'), btn_submit_els);
  } else {
    each($removeClass('pb-editor-form__btn-submit--disabled'), btn_submit_els);
  }
};

export const setPdThumbsToStoryImages = async (e) => {
  const total_len =
    $findAll('.product-story-image', e.delegateTarget).length + box.sel('pb_thumbnail_photos').length;
  if (total_len > 10) {
    const max_length_txt = ET('mps2::studio::editor::max_length_10_txt');
    MShopShareFramePopUpF.alert({ title: max_length_txt });
  }

  const files = await go(
    box.sel('pb_thumbnail_photos'),
    map(async (file) => {
      if (file?.file_id) {
        const find_file = find((f) => f.file_id == file.file_id, box.sel('pb_thumbnail_files'));
        const copied_file = new File([find_file], find_file.name, {
          type: find_file.type,
          lastModified: find_file.lastModified,
        });
        return copied_file;
      } else {
        return await convertURLtoFile(file?.url);
      }
    }),
  );
  await setProductStoryImage(null, e.delegateTarget, files, true);
};

export const submit = async (e) => {
  try {
    const container$ = getEditorContainerEl(e.currentTarget);
    const is_global = isGlobalMode(container$);
    const stores_product = formData(getEditorContainerEl(container$));
    const is_update = !!box.sel('pb_stores_product->id');

    const create_sku = stores_product._.create_sku;

    const errors = MShopStudioPbProductS.checkDataErrors(
      stores_product,
      box.sel('pb_thumbnail_photos'),
      is_global,
      create_sku,
      GoodsTypeS.isTpl(container$.dataset.goods_type_id),
    );
    if (errors.length) {
      errorsShow(stores_product, errors, e.delegateTarget);
      return;
    }

    if (await checkQuantity(e)) {
      return;
    }

    const store_id = box.sel('store_id');
    // 입고 상품을 공개처리할 때, Sku 확인
    if (GoodsTypeS.isTpl(container$.dataset.goods_type_id) && stores_product._.product.is_public) {
      const sku_nickname_els = go(
        container$,
        $findAll('.pb-editor-form__option-sku-nickname-input'),
        filter((el) => el.checkVisibility()),
      );

      if (!sku_nickname_els.length) {
        $.alert('Sku 코드를 생성해주세요.');
        return;
      }
    }

    // sku 생성시 alert 표시
    if (create_sku) {
      const sku_nickname_els = go(
        container$,
        $findAll('.pb-editor-form__option-sku-nickname-input'),
        filter((el) => el.checkVisibility()),
      );

      const empty_every_sku_nickname = go(
        sku_nickname_els,
        every((el) => UtilS.isEmpty(el.value)),
      );

      const fill_every_sku_nickname = go(
        sku_nickname_els,
        every((el) => el.value),
      );

      if (fill_every_sku_nickname || empty_every_sku_nickname) {
        if (empty_every_sku_nickname) {
          if (
            !(await MShopShareFramePopUpF.confirm({
              title: 'Sku ID가 자동으로 생성됩니다',
              body: html`
                <div>
                  각 상품 옵션의 Sku ID는 필수 입력 사항이므로<br />
                  입력하지 않을 시, 자동으로 생성됩니다.
                </div>
              `,
              ok: '상품 등록',
              cancel: '돌아가기',
            }))
          ) {
            return;
          }
        } else if (
          !(await MShopShareFramePopUpF.confirm({
            title: '완료 후 변경 불가한 사항이 있습니다',
            body: html`
              <div>
                Sku 생성 후, 카테고리와 옵션 수정이 불가합니다.<br />
                수정이 필요하시다면 Sku 코드 생성을<br />
                Off 상태로 변경 후 등록해 주세요.
              </div>
            `,
            ok: '상품 등록',
            cancel: '돌아가기',
          }))
        ) {
          return;
        }
      } else {
        const fill_some_sku_nickname = go(
          sku_nickname_els,
          some((el) => el.value),
        );
        if (fill_some_sku_nickname) {
          if (
            !(await MShopShareFramePopUpF.confirm({
              title: 'Sku ID가 자동으로 생성됩니다',
              body: html`
                <div>
                  입력하지 않은 Sku ID가 있습니다.<br />
                  모든 옵션의 Sku ID는 필수 입력 사항이므로<br />
                  입력하지 않을 시, 자동으로 생성됩니다.
                </div>
              `,
              ok: '상품 등록',
              cancel: '돌아가기',
            }))
          ) {
            return;
          }
        }
      }

      if (is_global) {
        const spo_options = stores_product._.product._.spo._.spo_options;

        if (spo_options.length > 0) {
          const option_named_filled = go(
            spo_options,
            every((option_name) => {
              return option_name?.name && option_name?.name_en && option_name?.name_jp;
            }),
          );

          if (!option_named_filled) {
            const btn_set_op_list_btn = $find('.pb-editor-form__btn-set-op-list', container$);
            if (btn_set_op_list_btn) {
              MShopShareFramePopUpF.alert({
                title: pb_product_editor_error.spo_option_name_required.text,
              });
              $trigger('click', btn_set_op_list_btn);
            }
            return;
          }
        }
      }
    }

    $.don_loader_start();
    const form_data_files = new FormData();
    [...box.sel('pb_thumbnail_files'), ...box.sel('product_story_image_files')]?.forEach((file) => {
      form_data_files.append('files[]', file, file.name);
    });
    await go(
      $.upload(form_data_files, {
        method: is_update ? 'PUT' : 'POST',
        url: is_update
          ? UtilS.makeApiUrl(`/:lang/@api/stores/:store_id/pb_products/:stores_product_id/update`, {
              lang: T.lang,
              store_id,
              stores_product_id: box.sel('pb_stores_product->id'),
            })
          : UtilS.makeApiUrl(`/:lang/@api/stores/:store_id/pb_products/create`, {
              lang: T.lang,
              store_id,
            }),
        max_resize: PRODUCT_STORY_MAX_WIDTH,
        data: {
          stores_product_storage_id: box.sel('stores_product_storage_id'),
          stores_product: JSON.stringify(stores_product),
          product_story_image_no: JSON.stringify(box.sel('product_story_image_no')),
          product_story: JSON.stringify(
            extend(
              defaults(getFormValues($1('#product-story-editor')), {
                title: null,
                description: null,
                fb_embed: null,
                youtube_embed: null,
                tw_embed: null,
                ins_embed: null,
              }),
              is_global
                ? undefined
                : { title_en: null, title_jp: null, description_en: null, description_jp: null },
            ),
          ),
          product_story_image_files_length: box.sel('product_story_image_files').length,
          remove_product_story_image_files: JSON.stringify(box.sel('remove_product_story_image_files')),
        },
      }),
      () => {
        $.don_loader_end();
        MuiF.closeFrame(true);
      },
    );
  } catch (_err) {
    $.don_loader_end();
    try {
      const data = _err.response && JSON.parse(_err.response);

      if (data?.message === 'DUPLICATED_NICKNAME') {
        const nicknames = data?.nicknames;
        const container$ = getEditorContainerEl(e.currentTarget);
        go(
          container$,
          $findAll('.pb-editor-form__option-sku-nickname-input'),
          filter((el) => el.checkVisibility()),
          filter((el) => nicknames.includes(el.value)),
          each($addClass('invalid')),
        );

        MShopShareFramePopUpF.alert({
          title: '중복된 Sku ID를 변경해 주세요',
          body: html`Sku ID는 상품을 식별하는 고유 번호이므로 <br />중복된 Sku ID로 등록할 수 없습니다.
            <ul style="margin: 0 auto; padding: 0; color: #d92a0f;width: fit-content;">
              ${Array.isArray(nicknames)
                ? go(
                    nicknames,
                    map((nickname) => `<li>${nickname}</li>`),
                    join(''),
                  )
                : nicknames}
            </ul> `,
        });
      } else {
        $.alert(data?.message || _err.message);
      }
    } catch (e) {
      $.alert('문제가 발생했습니다.');
    }
    console.error(_err);
  }
};

export const tempSave = async (e) => {
  try {
    const store_id = box.sel('store_id');
    const {
      data: { data: _stores_product },
    } = await axios.get(`/${T.lang}/@api/stores/${store_id}/pb_products/temp_storage`);

    if (
      _stores_product &&
      !(await MShopShareFramePopUpF.confirm({
        title: T('pb_product::이전 임시저장 상품 삭제'),
        body: `이전에 임시저장된 상품이 존재합니다.<br />이전 상품은 삭제하고 현재 등록 중인 상품을<br />임시저장하시겠습니까?`,
        cancel: T('아니요'),
        ok: T('네'),
      }))
    ) {
      return;
    }

    $.don_loader_start();
    const stores_product = formData(getEditorContainerEl(e.currentTarget));
    const form_data_files = new FormData();
    [...box.sel('pb_thumbnail_files'), ...box.sel('product_story_image_files')]?.forEach((file) => {
      form_data_files.append('files[]', file, file.name);
    });
    await go(
      $.upload_exif(form_data_files, {
        method: 'POST',
        url: `/${T.lang}/@api/stores/${store_id}/pb_products/temp_save`,
        max_resize: 1000,
        data: {
          stores_product: JSON.stringify(stores_product),
          product_story_image_no: JSON.stringify(box.sel('product_story_image_no')),
          product_story: JSON.stringify(
            defaults(getFormValues($1('#product-story-editor')), {
              title: null,
              description: null,
              fb_embed: null,
              youtube_embed: null,
              tw_embed: null,
              ins_embed: null,
            }),
          ),
          product_story_image_files_length: box.sel('product_story_image_files').length,
          remove_product_story_image_files: JSON.stringify(box.sel('remove_product_story_image_files')),
        },
      }),
      () => {
        $.don_loader_end();
        $.alert(
          html`
            <div class="ecommerce-info__alert">
              <h3>임시저장 완료</h3>
              <p style="text-align: center;">등록 중인 상품이 임시 저장되었습니다.</p>
            </div>
          `,
        );
      },
    );
  } catch (_err) {
    $.don_loader_end();
    try {
      const data = _err.response && JSON.parse(_err.response);
      $.alert(data?.message || _err.message);
    } catch (e) {
      $.alert('문제가 발생했습니다.');
    }
    console.error(_err);
  }
};

export const checkBaseInfo = async (store_id) => {
  const {
    data: { product_types, store_ecommerce_info, stores_delivery_companies },
  } = await axios.get(`/${T.lang}/@api/stores/${store_id}/pb_products/base_info`);

  if (!stores_delivery_companies.length) {
    await $.alert(html`
      <div class="studio-alert">
        <h3 class="studio-alert__title">크리에이터 정보 입력 안내</h3>
        <div class="studio-alert__body">
          <p class="studio-alert__p">
            ${T('pb_product::내 상품을 등록하려면 크리에이터 정보가 필요합니다.')}<br />
            ${T('pb_product::샵 관리 &gt; 크리에이터 정보에서 입력해 주세요.')}
          </p>
        </div>
      </div>
    `);
    $.don_loader_start(null, {}, 1000);
    location.href = `${box.sel('store_url')}/settings/ecommerce_info`;

    return false;
  } else {
    return { product_types, store_ecommerce_info, stores_delivery_companies };
  }
};

export const setEditorAction = () => {
  const actions = box.sel('actions');
  const action = {};
  action.is_studio_worker_admin = !!box.sel('is_studio_worker_admin');
  action.need_confirm_action = find(selEq('name', SERVICE.CONFIRMATION), box.sel('crew_services'));
  action.fixed_bp_profit = find(selEq('name', SERVICE.FIXED_PROFIT), box.sel('crew_services'));
  action.has_permission_to_confirm = checkActions(
    {
      actions: ACTION.UPDATE_PRODUCT_CONFIRM,
      target: ACTION_TARGET.CREW,
    },
    { user_actions: actions },
  );
  action.has_permission_to_prohibit = checkActions(
    { actions: ACTION.PROHIBIT_PRODUCT, target: ACTION_TARGET.STORE },
    { user_actions: actions },
  );
  action.has_permission_to_delete = checkActions(
    { actions: ACTION.DELETE_PRODUCT, target: ACTION_TARGET.STORE },
    { user_actions: actions },
  );
  action.is_crew_admin = checkActions(
    {
      actions: ACTION.ACCESS_CREW_ADMIN,
      target: ACTION_TARGET.CREW,
    },
    { user_actions: actions },
  );

  action.crew_domain_name = box.sel('crew_domain_name');
  action.is_mobile = MShopUtilF.isMobile();
  action.without_bonus_profit = box.sel('is_user->_->crew->without_bonus_profit');

  /* 마플 직원이고 입고상품가능  */
  action.has_permission_tpl = box.sel('is_tpl_possible') && action.is_studio_worker_admin;

  return action;
};

export const isGlobalMode = (el) => {
  return (el || $qs('.pb-editor')).dataset.is_global === 'true';
};

const checkCanGlobal = async (container_el) => {
  if (GoodsTypeS.isTpl(container_el.dataset.goods_type_id)) {
    return true;
  }

  const is_delivery_company_en = container_el.dataset.is_delivery_company_en === 'true';
  const is_delivery_company_jp = container_el.dataset.is_delivery_company_jp === 'true';

  const omission_state = match({
    is_delivery_company_en,
    is_delivery_company_jp,
  })
    .case(
      ({ is_delivery_company_en, is_delivery_company_jp }) =>
        !(is_delivery_company_en || is_delivery_company_jp),
    )(() => '해외')
    // 추후 배송정책 상태를 모두 봐야 하는것으로 기획이 변경될 경우 주석해제
    // .case(({ is_delivery_company_en }) => !is_delivery_company_en)(() => '글로벌')
    // .case(({ is_delivery_company_jp }) => !is_delivery_company_jp)(() => '일본')
    .else(() => false);

  if (!omission_state) {
    return true;
  }

  const is_goto_setting = await MShopShareFramePopUpF.confirm({
    title: `${omission_state} 배송 정책 미설정`,
    body: `${omission_state} 배송 정책 등록 후 해외 판매가 가능합니다.<br />
${omission_state} 판매를 위해 ‘샵 관리 > 크리에이터 정보’에서<br />
영문(EN)샵/일본(JP)샵 배송 정책을 설정해 주세요.`,
    cancel: '취소',
    ok: '설정하러 가기',
  });

  if (is_goto_setting) {
    $.don_loader_start(null, {}, 1000);
    return (location.href = `${box.sel('store_url')}/settings/ecommerce_info`);
  }

  return false;
};

const setTempButton = (is_global) => {
  const temp_button_els = compact($qsa('.pb-editor-form__btn-temp-save'));
  if (is_global === 'true') {
    go(
      temp_button_els,
      each(
        $setCss({
          display: 'none',
        }),
      ),
    );
  } else {
    go(
      temp_button_els,
      each(
        $setCss({
          display: 'unset',
        }),
      ),
    );
  }
};

export const toggleGlobalInput = async (e) => {
  const container$ = getEditorContainerEl(e.currentTarget);
  const is_global = $val(e.currentTarget);

  if (is_global === 'true') {
    const can_global = await checkCanGlobal(container$);
    if (!can_global) {
      return $trigger('click', $qs(`.${MShopStudioPbProductConstantS.CANNOT_GLOBAL_CLASSNAME}`));
    }
  }

  container$.dataset.is_global = is_global;
  checkAllEvents(container$);

  /* TODO @yjj 해외 배송 임시저장 기능 완료하면 제거 */
  setTempButton(is_global);
};

export const setGlobalSpoOptions = (container_el) => {
  const option_table = $find('.pb-editor-form__options-table > table', container_el);
  if (!option_table) {
    box.set('pb_product_spo_options', []);
    return [];
  }

  const group_names = go(
    container_el,
    $findAll('.pb-editor-form__options-table thead .pb-editor-form__options-name'),
    map((options_name$) => {
      const name = go(options_name$, $find('.group_name')).dataset.group_name;
      const name_en = go(options_name$, $find('.group_name_en')).dataset.group_name_en;
      const name_jp = go(options_name$, $find('.group_name_jp')).dataset.group_name_jp;
      return { name, name_en, name_jp };
    }),
  );

  const spo_options = go(
    group_names,
    ippL,
    map(([idx, name_global]) => {
      return {
        ...name_global,
        _: {
          spo_option_values: go(
            $findAll('.pb-editor-form__options-table tbody tr', container_el),
            map((el) => {
              return {
                name: UtilF.strValTrim($findAll('.pb-editor-form__option-name-input-kr', el)[idx]),
                name_en: UtilF.strValTrim($findAll('.pb-editor-form__option-name-input-en', el)[idx]),
                name_jp: UtilF.strValTrim($findAll('.pb-editor-form__option-name-input-jp', el)[idx]),
              };
            }),
            uniqBy((value) => {
              return value.name;
            }),
          ),
        },
      };
    }),
  );

  // 처음 입력했던 옵션 순서 보장
  const pb_product_spo_options = go(box.sel('pb_product_spo_options'), (prev_spo_options) => {
    if (!prev_spo_options?.length) return spo_options;

    return go(
      prev_spo_options,
      map((prev_so) => {
        const spo_option = find((so) => {
          return prev_so.name == so.name;
        }, spo_options);

        const option_values = go(
          sel('_.spo_option_values', prev_so),
          map((prev_sov) => {
            return find((sov) => {
              return sov.name == prev_sov.name;
            }, sel('_.spo_option_values', spo_option));
          }),
        );

        const prev_option_value_names = go(sel('_.spo_option_values', prev_so), map(sel('name')));

        const changed_option_value = go(
          sel('_.spo_option_values', spo_option),
          find((sov) => {
            return !prev_option_value_names.includes(sov.name);
          }),
        );

        spo_option._.spo_option_values = go(
          option_values,
          map((option_value) => {
            if (option_value) return option_value;
            return changed_option_value;
          }),
          compact,
        );
        return spo_option;
      }),
      compact,
    );
  });

  box.set('pb_product_spo_options', pb_product_spo_options);
  return spo_options;
};

const makeGlobalSpoItemsData = ({
  group_names,
  group_names_en,
  group_names_jp,
  spo_item_els,
  stock_type,
}) => {
  if (stock_type == SpoSpoConstantS.pSpoStockType.NONE && !spo_item_els.length) return;

  // 옵션이 정상적으로 있는 경우
  return go(
    spo_item_els,
    map((el) => {
      const group_names_option_names = go(
        $findAll('input[name="spo_item"]', el),
        ippL,
        map(([idx, name_el]) => {
          const tpl_option_names$ = $closest('.pb-editor-form__options-name', name_el);
          const option_name = UtilF.strValTrim(name_el);
          const option_name_en = go(tpl_option_names$, $find('input[name="spo_item_en"]'), UtilF.strValTrim);
          const option_name_jp = go(tpl_option_names$, $find('input[name="spo_item_jp"]'), UtilF.strValTrim);
          return {
            group_name: group_names[idx],
            group_name_en: group_names_en[idx],
            group_name_jp: group_names_jp[idx],
            option_name,
            option_name_en,
            option_name_jp,
          };
        }),
      );
      const origin_sku = go(el, $data) || {};
      const spo_item = {
        group_names_option_names,
        price: PriceS.price($val($find('.pb-editor-form__option-price-input', el))),
        spo_id: origin_sku.spo_id,
        spo_item_id: origin_sku.spo_item_id,
        sku: {
          ...origin_sku.sku,
          nickname: $val($find('.pb-editor-form__option-sku-nickname-input', el)),
        },
      };

      const spo_item_stock_id = $attr('data-spo_item_stock_id', el);
      if (spo_item_stock_id) spo_item.spo_item_stock_id = spo_item_stock_id;

      if (stock_type == SpoSpoConstantS.pSpoStockType.OPTION_STOCK) {
        const quantity_el = $find('.pb-editor-form__option-quantity-input', el);
        const quantity = quantity_el ? UtilF.strValTrimParseInt(quantity_el) : 0;

        const real_quantity_el = $find('.pb-editor-form__option-real-quantity-input', el);
        const real_quantity = real_quantity_el ? UtilF.strValTrimParseInt(real_quantity_el) : 0;
        spo_item._ = {
          spo_item_stock: {
            quantity,
            real_quantity,
          },
        };
        if (spo_item_stock_id) spo_item._.spo_item_stock.id = spo_item_stock_id;
      }

      const id = $attr('data-id', el);
      if (id) spo_item.id = id;

      return spo_item;
    }),
  );
};

function mutateStoreProductForGlobal({ stores_product, container_el }) {
  /* TPL */
  if (GoodsTypeS.isTpl(container_el.dataset.goods_type_id)) {
    const tpl_fee$ = $find('input[name="tpl_fee"]', container_el);
    stores_product._.tpl_fee = { ratio: $val(tpl_fee$), before_ratio: tpl_fee$.dataset.before_ratio };

    const material$ = $find('input[name="material"]', container_el);
    const material = $val(material$);
    stores_product.material = material;
    const hs_code$ = $find('input[name="hs_code"]', container_el);
    stores_product._.hs_code = $val(hs_code$);
  }

  /* GLOBAL */
  const name_en$ = $find('input[name="name_en"]', container_el);
  const name_jp$ = $find('input[name="name_jp"]', container_el);
  const certification_info_en$ = $find('input[name="certification_info_en"]', container_el);
  const certification_info_jp$ = $find('input[name="certification_info_jp"]', container_el);

  const name_en = $val(name_en$).trim();
  const name_jp = $val(name_jp$).trim();
  const certification_info_en = certification_info_en$
    ? UtilF.strValTrim(certification_info_en$) || null
    : null;
  const certification_info_jp = certification_info_jp$
    ? UtilF.strValTrim(certification_info_jp$) || null
    : null;
  const product_basic_info_en =
    UtilF.strValTrim($find('textarea[name="product_basic_info_en"]', container_el)) || null;
  const product_basic_info_jp =
    UtilF.strValTrim($find('textarea[name="product_basic_info_jp"]', container_el)) || null;
  const refund_info_en = UtilF.strValTrim($find('textarea[name="refund_info_en"]', container_el)) || null;
  const refund_info_jp = UtilF.strValTrim($find('textarea[name="refund_info_jp"]', container_el)) || null;

  stores_product._.product.name_en = name_en;
  stores_product._.product.name_jp = name_jp;
  stores_product.certification_info_en = certification_info_en;
  stores_product.certification_info_jp = certification_info_jp;
  stores_product.product_basic_info_en = product_basic_info_en;
  stores_product.product_basic_info_jp = product_basic_info_jp;
  stores_product.refund_info_en = refund_info_en;
  stores_product.refund_info_jp = refund_info_jp;

  /* SPO 덮어쓰기 */
  const spo_text_options = go(
    container_el,
    $findAll('.pb-editor-form__direct-option'),
    map((global_option$) => {
      const id = global_option$.dataset.text_option_id;
      const name = go(global_option$, $find('.pb-editor-form__body-input-op-direct'), UtilF.strValTrim);
      const name_en =
        go(global_option$, $find('.pb-editor-form__body-input-op-direct_en'), UtilF.strValTrim) || name;
      const name_jp =
        go(global_option$, $find('.pb-editor-form__body-input-op-direct_jp'), UtilF.strValTrim) || name;
      return {
        id,
        name,
        name_en,
        name_jp,
      };
    }),
  );
  const stock_type = UtilF.strValTrim($find('input[name="stock_type"]:checked', container_el));
  const spo_options = setGlobalSpoOptions(container_el);
  const group_names = pluck('name', spo_options);
  const group_names_en = pluck('name_en', spo_options);
  const group_names_jp = pluck('name_jp', spo_options);

  const spo_items = go(
    void 0,
    () => {
      return makeGlobalSpoItemsData({
        group_names,
        group_names_en,
        group_names_jp,
        spo_item_els: $findAll('.pb-editor-form__options-table tbody tr', container_el),
        stock_type,
      });
    },
    (spo_items) => {
      if (stock_type != SpoSpoConstantS.pSpoStockType.OPTION_STOCK) return spo_items;

      const prev_spo_item_stocks = go(
        box.sel('im_pb_stores_product->_->product->_->spo->_->spo_items'),
        flatMap((im_spo_item) => {
          return im_spo_item?._?.spo_item_stock;
        }),
        compact,
      );

      if (!prev_spo_item_stocks?.length) return spo_items;

      each((spo_item) => {
        const prev_spo_item_stock = find((prev_spo_item_stock) => {
          return sel('_.spo_item_stock.id', spo_item) == prev_spo_item_stock.id;
        }, prev_spo_item_stocks);

        if (prev_spo_item_stock) {
          spo_item._.spo_item_stock.plus_minus_quantity =
            spo_item._.spo_item_stock.quantity - prev_spo_item_stock.quantity;
        }
      }, spo_items);
      return spo_items;
    },
  );

  stores_product._.product._.spo._.spo_options = spo_options;
  stores_product._.product._.spo._.spo_items = spo_items;
  stores_product._.product._.spo._.spo_text_options = spo_text_options;

  const toggle_sku_el = go(container_el, $find('.pb-editor-form__toggle-sku'));
  stores_product._.create_sku = false;

  if (toggle_sku_el) {
    if (toggle_sku_el.checked && !toggle_sku_el.disabled) {
      stores_product._.create_sku = true;
      const no_options_el = go(container_el, $find('.pb-editor-form__options-table-no-options'));
      if (no_options_el) {
        const sku_nickname_el = go(container_el, $find('.pb-editor-form__option-sku-nickname-input'));
        const row = go(sku_nickname_el, $closest('tr'));
        const { sku: size } = go(row, $data);

        extend(stores_product._.product._.spo._, {
          no_option_sku: { nickname: sku_nickname_el?.value, size },
        });
      }
    }
  }

  if (GoodsTypeS.isPbg(container_el.dataset.goods_type_id)) {
    const stores_delivery_company_id_en =
      UtilF.strValTrim($find('select[name="stores_delivery_company_id_en"]', container_el)) || null;
    const stores_delivery_company_id_jp =
      UtilF.strValTrim($find('select[name="stores_delivery_company_id_jp"]', container_el)) || null;

    stores_product._.product._.stores_delivery_companies_product.stores_delivery_company_id_en =
      stores_delivery_company_id_en;
    stores_product._.product._.stores_delivery_companies_product.stores_delivery_company_id_jp =
      stores_delivery_company_id_jp;
  }

  stores_product.goods_type_id = container_el.dataset.goods_type_id;

  const is_global = isGlobalMode(container_el);
  if (!is_global) {
    return MShopStudioPbProductS.nullifyGlobalProps(stores_product);
  }
  stores_product.is_global = true;
  return stores_product;
}

export const changeMaterial = ({ currentTarget: ct }) => {
  ct.value = UtilStringS.sanitizeInputEnglishOnly(ct.value)?.toUpperCase();
};

export const createSku = async ({ currentTarget: ct }) => {
  const container$ = getEditorContainerEl(ct);
  const is_global = isGlobalMode(container$);
  const stores_product = formData(getEditorContainerEl(container$));
  const product = stores_product._.product;
  const spo = stores_product._.product._.spo;
  const {
    _: { spo_options, spo_items, spo_item_stock },
  } = spo;

  if (ct.checked) {
    MShopShareFramePopUpF.alert({
      title: 'Sku 코드를 생성할 수 있습니다',
      body: html`주의 : Sku 생성 후 상품을 등록하면<br />카테고리와 옵션 수정이 불가능합니다.`,
    });
  }
  // 옵션이 있는 경우
  const spo_items_wrapper_el = go(container$, $find('.pb-editor-form__spo-items-wrapper'));
  if (spo_items_wrapper_el) {
    go(
      container$,
      $findAll('.pb-editor-form__options-sku-nickname, .pb-editor-form__options-size'),
      each((el) => el.classList.toggle('show-sku', ct.checked)),
    );
  }
  // 옵션이 없는 경우
  const no_options_wrapper_el = go(container$, $find('.pb-editor-form__options-table-no-options'));
  if (no_options_wrapper_el) {
    go(
      container$,
      $findAll('.pb-editor-form__options-sku-nickname, .pb-editor-form__options-size'),
      each((el) => el.classList.toggle('show-sku', ct.checked)),
    );
  }

  if (ct.checked) {
    initTooltips2({ container_el: container$ });
  }

  // try {
  //   $.don_loader_start();
  //   await axios.post(
  //     UtilS.makeApiUrl(`/${T.lang}/@api/stores/:store_id/spo/:spo_id/spo_items`, {
  //       store_id: box.sel('store_id'),
  //       spo_id: stores_product._.product._.spo.id,
  //     }),
  //     {
  //       spo,
  //       spo_options,
  //       spo_items,
  //       spo_item_stock,
  //       product,
  //     },
  //   );
  // } catch (_err) {
  //   try {
  //     const data = _err.response && _err.response.data;
  //     $.alert(data?.message || _err.message);
  //   } catch (e) {
  //     $.alert('문제가 발생했습니다.');
  //   }
  //   console.error(_err);
  // } finally {
  //   $.don_loader_end();
  // }
};

export const validHscodeFormat = ({ currentTarget: ct }) => {
  ct.value = ct.value.replace(/[^0-9.-]/g, '');

  const hs_code = $val(ct);
  const error_res = MShopStudioPbProductS.checkHsCode(hs_code);
  errorStatusEl(error_res, ct);
  const container_el = getEditorContainerEl(ct);
  checkAllEvents(container_el);
};

export const handleMaxPurchasePerUser = ({ currentTarget: ct }) => {
  ct.value = ct.value.replace(/[^0-9.-]/g, '');
  if (!ct.value) {
    ct.value = 1;
  }
};
