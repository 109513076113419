import { format, startOfDay } from 'date-fns';
import { html } from 'fxjs/es';
import {
  fileInputControl,
  goalControl,
  goalVisibleControl,
  keywordControl,
  periodControl,
  publicControl,
  textareaControl,
  textInputControl,
} from './_controls.js';

import {
  FeedProductAddModalContainerView,
  FeedProductListPreviewView,
} from '../../../../Event/S/Tmpl/form.ts';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

export const popupStoreField = ({ title, description, controls }) => {
  return html`
    <div class="popup-store-form-field">
      <div class="popup-store-form-field__title">
        <h4>${title}</h4>
        ${description ? html`<p>${description}</p>` : ''}
      </div>
      <div class="popup-store-form-field__controls">${controls}</div>
    </div>
  `;
};

/* 팝업스토어 메타 필드 */
export const popupStoreMetaSection = (data) => {
  return html`
    <section class="popup-store-edit-section">
      <!-- 매인 썸네일 -->
      ${popupStoreField({
        title: '메인 썸네일',
        description: '진행예정이나 진행중인 상태에서 노출됩니다.',
        controls: fileInputControl({
          aspect_ratio: '2752 / 1092',
          image_size: [2752, 1092],
          name: 'thumbnail',
          src: data?.thumbnail || '',
          placeholder: '팝업스토어 메인에 노출될 이미지를 등록해 주세요.',
          file_size: 3,
        }),
      })}

      <!-- 모바일 썸네일 -->
      ${popupStoreField({
        title: '모바일 썸네일',
        description: '진행예정이나 진행중인 상태에서 모바일에 노출됩니다.',
        controls: fileInputControl({
          aspect_ratio: '686 / 914',
          image_size: [686, 914],
          name: 'mobile_thumbnail',
          src: data?.mobile_thumbnail || '',
          placeholder: '모바일 팝업스토어 메인에 노출될 이미지를 등록해 주세요.',
          file_size: 3,
        }),
      })}

      <!-- 리스트 썸네일 -->
      ${popupStoreField({
        title: '리스트 썸네일',
        description: '팝업스토어 종료 후 리스트에서 노출됩니다.',
        controls: fileInputControl({
          aspect_ratio: '1146 / 860',
          image_size: [1146, 860],
          name: 'list_thumbnail',
          src: data?.list_thumbnail || '',
          placeholder: '리스트에 노출될 이미지를 등록해 주세요.',
          file_size: 3,
        }),
      })}

      <!-- 번호 -->
      ${popupStoreField({
        title: '번호',
        controls: textInputControl({
          name: 'numbering',
          value: data.numbering || '1',
          placeholder: '',
          pattern: '^[0-9]+$',
        }),
      })}

      <!-- PC 썸네일 타이틀 -->
      ${popupStoreField({
        title: 'PC 썸네일 타이틀',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'title',
              value: data.title || '',
              placeholder: '팝업스토어 국문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 36,
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'title_en',
              value: data.title_en || '',
              placeholder: '팝업스토어 영문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 72,
              flag: 'en',
            })}
            ${textareaControl({
              name: 'title_jp',
              value: data.title_jp || '',
              placeholder: '팝업스토어 일문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 36,
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <!-- 모바일 썸네일 타이틀 -->
      ${popupStoreField({
        title: '모바일 썸네일 타이틀',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'mobile_title',
              value: data.mobile_title || '',
              placeholder: '팝업스토어 국문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 36,
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'mobile_title_en',
              value: data.mobile_title_en || '',
              placeholder: '팝업스토어 영문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 72,
              flag: 'en',
            })}
            ${textareaControl({
              name: 'mobile_title_jp',
              value: data.mobile_title_jp || '',
              placeholder: '팝업스토어 일문 타이틀을 입력해 주세요.',
              rows: 3,
              max_length: 36,
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <!-- 팝업스토어 요약 -->
      ${popupStoreField({
        title: '팝업스토어 요약',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'description',
              value: data.description || '',
              placeholder: '팝업스토어 국문 요약을 입력해 주세요.',
              rows: 4,
              max_length: 80,
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'description_en',
              value: data.description_en || '',
              placeholder: '팝업스토어 영문 요약을 입력해 주세요.',
              rows: 4,
              max_length: 160,
              flag: 'en',
            })}
            ${textareaControl({
              name: 'description_jp',
              value: data.description_jp || '',
              placeholder: '팝업스토어 일문 요약을 입력해 주세요.',
              rows: 4,
              max_length: 80,
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <!-- 전시 기간 설정 -->
      ${popupStoreField({
        title: '전시 기간 설정',
        controls: periodControl({
          start_name: 'start_at',
          start_value: data.start_at ? format(new Date(data.start_at), 'yyyy-MM-dd HH:mm:ss') : '',
          end_name: 'end_at',
          end_value: data.end_at ? format(new Date(data.end_at), 'yyyy-MM-dd HH:mm:ss') : '',
        }),
      })}

      <!-- 크리에이터 영문명 -->
      ${popupStoreField({
        title: '크리에이터명',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'creator_name',
              value: data.creator_name || '',
              placeholder: '아카이브에 노출될 크리에이터명을 입력해 주세요.',
              rows: 1,
              max_length: 30,
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'creator_name_en',
              value: data.creator_name_en || '',
              placeholder: '나우에 노출될 크리에이터명을 입력해 주세요.',
              rows: 1,
              max_length: 30,
              flag: 'en',
            })}
          </div>
        `,
      })}

      <!-- 크리에이터샵 링크 -->
      ${popupStoreField({
        title: '크리에이터샵 링크',
        controls: textInputControl({
          name: 'creator_shop_url',
          value: data.creator_shop_url || '',
          placeholder: '해당 크리에이터샵 URL을 입력해 주세요.',
          // pattern: '^((http|https):// | /)?(www.)?([a-zA-Z0-9]+)?(\\.)?[a-z]+([a-zA-z0-9_/]+)?$',
        }),
      })}

      <!-- SEO 타이틀 -->
      ${popupStoreField({
        title: 'SEO 타이틀',
        description: '최대 2줄',
        controls: textareaControl({
          name: 'seo_title',
          value: data.seo_title || '',
          placeholder: '검색 결과에 노출될 제목을 입력해 주세요. (영문)',
          rows: 2,
          line_limit: 2,
          // pattern: '^[0-9a-zA-Z\\s,.!_-\n]{1,}$',
        }),
      })}

      <!-- SEO 설명 -->
      ${popupStoreField({
        title: 'SEO 설명',
        description: '최대 2줄',
        controls: textareaControl({
          name: 'seo_description',
          value: data.seo_description || '',
          placeholder: '검색 결과에 노출될 페이지 설명을 입력해 주세요. (영문)',
          rows: 2,
          line_limit: 2,
          // pattern: '^[0-9a-zA-Z\\s,.!_-\n]{1,}$',
        }),
      })}

      <!-- SEO 키워드 -->
      ${popupStoreField({
        title: 'SEO 키워드',
        description: '최대 5개',
        controls: keywordControl({
          name: 'seo_keywords',
          limit: 5,
          placeholder: '검색 결과에 노출될 키워드를 입력해 주세요.',
          values: data.tags || [],
        }),
      })}

      <!-- 공개 상태 -->
      ${popupStoreField({
        title: '공개 상태',
        controls: publicControl({
          name: 'status',
          value: data.status || 'public',
          show_start_at: data.show_start_at
            ? format(utcToZonedTime(data.show_start_at), 'yyyy-MM-dd HH:mm:ss')
            : format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
          show_end_at: data.show_end_at
            ? format(utcToZonedTime(data.show_end_at), 'yyyy-MM-dd HH:mm:ss')
            : null,
        }),
      })}
    </section>
  `;
};

/* 팝업스토어 아이템 메타 필드 */
export const popupStoreItemMetaSection = (data) => {
  return html`
    <section class="popup-store-edit-section">
      <div style="max-width: 480px;">
        <!-- 커버 이미지 -->
        ${popupStoreField({
          title: '커버 이미지',
          description: '팝업스토어 상세페이지 최상단에 노출되는 이미지',
          controls: fileInputControl({
            // aspect_ratio: '2880 / 1500',
            // image_size: [2880, 1500],
            aspect_ratio: '3840 / 2000',
            image_size: [3840, 2000],
            name: 'cover_thumbnail',
            src: data.cover_thumbnail || '',
            placeholder: '팝업스토어 커버 이미지를 등록해 주세요.',
            file_size: 8,
          }),
        })}
      </div>

      <!-- 타이틀 -->
      ${popupStoreField({
        title: '타이틀',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'content_title',
              value: data.content_title || '',
              max_length: 50,
              placeholder: '팝업스토어 상세페이지에 삽입될 국문 타이틀을 입력해 주세요.',
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'content_title_en',
              value: data.content_title_en || '',
              max_length: 100,
              placeholder: '팝업스토어 상세페이지에 삽입될 영문 타이틀을 입력해 주세요.',
              flag: 'en',
            })}
            ${textareaControl({
              name: 'content_title_jp',
              value: data.content_title_jp || '',
              max_length: 50,
              placeholder: '팝업스토어 상세페이지에 삽입될 일문 타이틀을 입력해 주세요.',
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <!-- 팝업스토어 설명 -->
      ${popupStoreField({
        title: '팝업스토어 설명',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'content_description',
              value: data.content_description || '',
              rows: 5,
              placeholder:
                '팝업스토어 상세페이지 상단에 노출되는 팝업스토어에 대한 국문 설명을 입력해 주세요.',
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'content_description_en',
              value: data.content_description_en || '',
              rows: 5,
              placeholder:
                '팝업스토어 상세페이지 상단에 노출되는 팝업스토어에 대한 영문 설명을 입력해 주세요.',
              flag: 'en',
            })}
            ${textareaControl({
              name: 'content_description_jp',
              value: data.content_description_jp || '',
              rows: 5,
              placeholder:
                '팝업스토어 상세페이지 상단에 노출되는 팝업스토어에 대한 일문 설명을 입력해 주세요.',
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <!-- 목표 설정 -->
      ${popupStoreField({
        title: '목표 설정',
        controls: goalControl({
          goal_type: data.goal_type || 'count',
          goal_value: data.goal_value ? parseInt(data.goal_value).toLocaleString() : 0,
          is_soldout: data.is_soldout || false,
          soldout_label: data.soldout_label || 'Sold out',
        }),
      })}

      <!-- 목표 노출 설정 -->
      ${popupStoreField({
        title: '목표 노출 설정',
        controls: goalVisibleControl({
          value: data.goal_visible || 'timer',
        }),
      })}
    </section>
  `;
};

/* 팝업스토어 아이템 에디터 필드 */
export const popupStoreItemEditorSection = (data) => {
  return html`
    <section class="popup-store-edit-section">
      <div class="popup-store-editor editor_style_container">
        ${data.content || '<div class="editor editable popup-store"><p><br/></p></div>'}
      </div>
    </section>
  `;
};

/* 팝업스토어 아이템 상품 필드 */
export const popupStoreItemProductsSection = (data, { is_mobile }) => {
  return html`
    <section class="popup-store-edit-section">
      <div class="popup-store-form-field row">
        <div class="popup-store-form-field__title">
          <h4>팝업스토어 연관 상품</h4>
        </div>
        <button type="button" class="add-products-modal-btn">상품 추가하기</button>
      </div>

      <!-- 전시 상품 타이틀 -->
      ${popupStoreField({
        title: '연관 상품 타이틀',
        controls: textareaControl({
          name: 'product_section_title',
          value: data.product_section_title || '',
          rows: 1,
          placeholder: '연관 상품 타이틀을 입력해 주세요.',
          max_length: 100,
        }),
      })}

      <!-- 전시 상품 설명 -->
      ${popupStoreField({
        title: '연관 상품 설명',
        controls: html`
          <div class="popup-store-control-group">
            ${textareaControl({
              name: 'product_section_description',
              value: data.product_section_description || '',
              placeholder: '연관 상품에 대한 국문 설명을 입력해 주세요.',
              rows: 1,
              max_length: 80,
              flag: 'ko',
            })}
            ${textareaControl({
              name: 'product_section_description_en',
              value: data.product_section_description_en || '',
              rows: 1,
              placeholder: '연관 상품에 대한 영문 설명을 입력해 주세요.',
              max_length: 160,
              flag: 'en',
            })}
            ${textareaControl({
              name: 'product_section_description_jp',
              value: data.product_section_description_jp || '',
              rows: 1,
              placeholder: '연관 상품에 대한 일문 설명을 입력해 주세요.',
              max_length: 80,
              flag: 'jp',
            })}
          </div>
        `,
      })}

      <div class="popup-store-related-products">
        <input name="product_ids" value="${data.product_ids}" type="hidden" />

        ${new FeedProductListPreviewView({
          is_mobile,
          products: data.related_products || [],
          klass: 'popup-store-related-products__list',
        }).toHtmlSSR()}
      </div>

      ${new FeedProductAddModalContainerView({
        products: data.related_products || [],
        klass: 'popup-store-related-products__modal',
        max_count: 30,
      }).toHtmlSSR()}
    </section>
  `;
};
