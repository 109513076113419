import { $append, $closest, $el, $qs } from 'fxdom/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { find, go, html, strMap } from 'fxjs/es';
import { imageSelectorArrowIcon } from '../../../../../OMP/Core/Atom/S/Tmpl/icon.js';
import { events } from './events.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';

export const imageDropBox = ({
  selected_bp_option_id,
  options,
  column_length,
  element,
  max_height,
  color,
}) => {
  const { top, left, right, width } = element.getBoundingClientRect();
  return html`
    <div class="image_selector_wrap">
      <div
        class="select_box maker-image-selector"
        data-fx-json="${$dataStr(options)} "
        data-column_length=${column_length}
        style="width:${width}px; 
        position:fixed;
        top:${top - 2}px; 
        left:${left}px; 
        right:${right}px;"
      >
        ${imageSelectorHead({ selected_bp_option_id, options })}
        ${imageSelectedOptions({ selected_bp_option_id, options, column_length, max_height, color })}
      </div>
    </div>
  `;
};

export const imageSelectorHead = ({ selected_bp_option_id, options }) => {
  const { name, id } = go(
    options,
    find(({ id }) => id == selected_bp_option_id),
  );
  return html`<div class="maker-image-selector__head" data-id="${id}" data-name="${name}">
    <span>${name}</span>
    ${imageSelectorArrowIcon()}
  </div>`;
};

export const getColumnLength = async (item_img) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => (image.width === image.height ? resolve(3) : resolve(2));
    image.onerror = (error) => {
      console.error('Error loading image:', error);
      resolve(2);
    };
    image.src = item_img;
  });
};
export const imageSelectedOptions = ({
  selected_bp_option_id,
  options,
  column_length,
  height,
  max_height,
  color,
}) => {
  const is_short = $qs('html[marpplizer_mode="short"]');
  const is_mobile_size = $qs('html[is_mobile_size="true"]');

  return html`<div
    class="maker-image-selector__options"
    ${is_mobile_size || !is_short
      ? `style="grid-template-columns: repeat(${column_length}, 1fr); height: ${height}px; max-height: ${max_height}px; "`
      : ''}
  >
    ${strMap(({ id, name, img, selected_img, price }) => {
      const is_selected = id == selected_bp_option_id;

      const style = is_selected ? `border: 1px solid ${color || '#FF6B00'}; border-radius: 10px` : '';

      const image_src = is_selected && color ? img : is_selected && selected_img ? selected_img : img;

      return html`
        <div class="paper_option ${is_selected ? 'selected_option' : ''}" data-id="${id}" data-name="${name}">
          <img
            data-column_length=${column_length}
            class="paper_option__img"
            src=${image_src}
            style="${image_src === img ? style : ''}"
            alt="${name}"
          />
          <div class="paper_option__name" style="${is_selected ? `color: ${color || '#FF6B00'}` : ''}">
            ${name}
          </div>
          ${price ? html`<div class="paper_option__price">+${UtilS.commify(price)}</div>` : ``}
        </div>
      `;
    }, options)}
  </div>`;
};

export const openImageDropBox = async (data, selectFunc) => {
  $.body_fixed(true);
  const column_length = await getColumnLength(data.options[0].img);
  const scroll = $qs('.activity');

  const { bottom: select_box_bottom } = data.element.getBoundingClientRect();
  const { bottom } = scroll.getBoundingClientRect();

  let max_height;
  if (bottom - select_box_bottom <= 400) {
    scroll.myScroll?.scrollToElement($closest('.bp_option_group', data.element), 0, false, false);
    const marpplizer_height = $qs('#marpplizer').offsetHeight;
    const scroll_y = scroll?.myScroll?.y || 0;
    const select_box_offset_top = data.element.offsetTop;
    const image_drop_box_header_height = 49 + 5;
    const target_location_y = scroll_y + select_box_offset_top + image_drop_box_header_height;
    max_height = Math.min(Math.max(marpplizer_height - target_location_y - 30, 100), 450);
  }

  $append($el(imageDropBox({ ...data, column_length, max_height })), document.body);
  events($qs('.image_selector_wrap'), data, selectFunc);
};
