import { $after, $closest, $is, $prependTo, $prev, $remove } from 'fxdom/es';
import { each, go, head, reduce, reject, reverseL, sel, takeAll, takeWhile } from 'fxjs/es';
import { makeFragment } from '../../../../Util/F/fs.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { findParentUntil, firstTextNode, getRootEl, lastTextNode } from '../../../Feed/F/util.js';
import { KEY_CODE, SELECTOR } from '../../S/constant.js';
import { isNotContenteditable } from '../util.js';
import { createActionStack } from './features/bug_tracker.js';
import { createEmptyP } from './medium-editor.js';

// 한 줄만 있는 상태에서 backspace, delete 누를 시 내용이 없어져 클릭을 못하는 버그 방지
export const insertEmpty = (e) => {
  if (e.keyCode !== 8 && e.keyCode !== 46) return;
  const is_not_empty = go(
    e.target.children,
    reject((e) => e.classList.contains('insert_buttons')),
    sel('length'),
  );

  if (!is_not_empty) {
    const $p = createEmptyP();
    $prependTo(e.target, $p);
    $p.click();

    createActionStack('insert empty p', {});
  }
};

export const preventSpanCreation = (e) => {
  if (e.target.tagName == 'SPAN') {
    e.target.outerHTML = e.target.innerHTML;
    createActionStack('prevent span creation', { e });
  }
};

export const preventOuterDivCreation = (e) => {
  if (e.target.nodeType !== 1) return;

  const root$ = getRootEl(e.target);
  if (root$?.tagName == 'DIV' && !isNotContenteditable(root$)) {
    createActionStack('outer div creation!!!', { e });
  }
};

const moveCursorPosition = ($target, selection) => {};

export const shouldHandleDeletion = (selection, $target_container, sibling, is_front) => {
  const range = selection.getRangeAt(0);

  const offset = is_front ? 'startOffset' : 'endOffset';
  const node_position_end = selection.anchorNode[sibling] == null;
  const text_position_end = is_front
    ? range[offset] == 0
    : range[offset] == range.endContainer.textContent.length;
  const is_sibling_text = !$is(SELECTOR.NOT_EDITABLE, $target_container);

  return node_position_end && text_position_end && is_sibling_text && range.collapsed;
};

export const preventSplitBackspace = (e) => {
  if (e.keyCode !== KEY_CODE.BACKSPACE && e.keyCode !== KEY_CODE.DELETE) return;
  const is_front = e.keyCode == KEY_CODE.BACKSPACE;

  const selection = window.getSelection();
  const sibling = is_front ? 'previousElementSibling' : 'nextElementSibling';
  const $cur_container = findParentUntil('.editable > *', selection.getRangeAt(0).startContainer);
  const $target_container = $cur_container[sibling];
  if (!shouldHandleDeletion(selection, $target_container, sibling, is_front)) return;

  // change cursor position
  const text_node = is_front ? lastTextNode($target_container) : firstTextNode($target_container);
  if (!text_node) return;
  selection.collapse(text_node, is_front ? text_node.textContent.length : 0);

  const first_chlidren = head($target_container.childNodes);
  const frag = reduce(
    (frag, node) => {
      frag.appendChild(node);
      return frag;
    },
    document.createDocumentFragment(),
    [...$cur_container.childNodes],
  );

  is_front ? $target_container.appendChild(frag) : $target_container.insertBefore(frag, first_chlidren);

  if (!is_front) moveCursorPosition($cur_container, selection);

  $remove($cur_container);

  e.preventDefault();
};

// bold, italic 같은 처리 시에 앞뒤에 whitespace 없어지는 문제 방지
export const replaceWhitespaceChar = (content) => content.replace(/> /g, '>&nbsp;').replace(/ </g, '&nbsp;<');

// 앞, 뒤의 contenteditable = false 인 대상 지우지 않기
// delete 는 맨 마지막 caret 인지 따지기가 어려워서 일단 보류
export const preventDeleteNoteditable = (e) => {
  if (e.keyCode !== KEY_CODE.BACKSPACE) return;

  const sel = window.getSelection();
  if (!sel.isCollapsed || sel.anchorOffset != 0) return;

  const root$ = findParentUntil('.editable > *', sel.anchorNode);

  // 잠깐 숨겼다가 텍스트 앞에 붙이고 다시 넣기
  const prev_el$ = $prev(root$);

  if (!prev_el$) return;
  if (!isNotContenteditable(prev_el$)) return;

  // 앞에 not editable 만 있을 경우 event prevent
  // 앞에 있는 not editable 전체 조회
  const prev_not_editables$ = go(
    root$,
    UtilS.recursiveL($prev),
    takeWhile((el$) => el$ && isNotContenteditable(el$)),
    reverseL,
    takeAll,
  );

  const top_not_editable$ = head(prev_not_editables$);
  const text_node$ = $prev(top_not_editable$);
  if (!text_node$) return e.preventDefault();

  createActionStack('prevent delete not editable', {});
  each($remove, prev_not_editables$);
  requestAnimationFrame(() => {
    $after(makeFragment(prev_not_editables$), text_node$);
  });
};

//
export const focusClickPlaceholder = (e) => {
  const sel = window.getSelection();
  const editor$ = e.currentTarget;

  if (!sel.anchorNode) return;

  const node = sel.anchorNode.parentElement;
  const cursor_in_editor = $closest('.editable', node) || $closest('.popup-store-editor', node);

  if (cursor_in_editor) return;
  sel.collapse(editor$.children[0], 0);
  createActionStack('focus click placeholder', { e });
  // if (!editor$)
};
