import { html, type Html, rune, UnsafeHtml, View } from 'rune-ts';
import c from './layout.module.scss';
import { type FooterData, FooterView } from '../Footer/Footer';
import { html as fxHtml, isNil } from 'fxjs/es';
import { htmlIf } from '../../../../shared/util';
import { MakeOptional } from '../../../../shared/type/types';
import { Locals } from '../../../../shared/type/locals';
import { DOCUMENT_EVENT, listenDocumentEvent } from '../../../../shared/util/event';
import { SubHeaderMobileData } from '../SubHeader/SubHeaderMobile';
import { FloatingBottomContainer } from '../FloatingBottomContainer/FloatingBottomContainer';
import { FloatingBoxData } from '../FloatingBox/FloatingBox';

export type LayoutStates = {
  is_mobile: boolean;
  require_top_arrow_button: boolean;
  full_width: boolean;
  // 포커스 안하고 싶을때는 -1
  selected_bottom_tab_idx: -1 | 0 | 1 | 2 | 3 | 4;
  has_bottom_tab_bar: boolean;
  app_info: Locals['webviewapp'];
  user?: {
    id?: number;
  };
  has_sub_header_sub_menu_container?: boolean;
  floating_box?: Omit<FloatingBoxData, 'is_mobile'>;
  floating_bottom_margin?: string;
};

type BaseLayoutContent = {
  body: View | Html | string | UnsafeHtml;
};

type LayoutContentWithFooter = BaseLayoutContent & {
  footer: Html | string;
  footer_data?: never; // Ensuring footer_data is not present
};

type LayoutContentWithFooterData = BaseLayoutContent & {
  footer?: never; // Ensuring footer is not present
  footer_data: FooterData;
};

export type LayoutContent = LayoutContentWithFooter | LayoutContentWithFooterData;

type LayoutOptions = MakeOptional<
  LayoutStates,
  'has_bottom_tab_bar' | 'full_width' | 'require_top_arrow_button' | 'selected_bottom_tab_idx'
>;

/*
 * @dev 기존 페이지 수정 시에는 이 함수 직접 사용, 그 외 Rune 내에서 사용 시에는 Layout rune 사용하기
 */
export const makeLayoutHtml = (
  layout_content: LayoutContent,
  {
    is_mobile,
    full_width,
    app_info,
    selected_bottom_tab_idx,
    has_bottom_tab_bar,
    user,
    has_sub_header_sub_menu_container,
    floating_box,
    floating_bottom_margin,
  }: LayoutStates & { is_mobile: boolean }, // is_mobile 무조건 넣어줌
  htmlFn: Html | typeof fxHtml,
) => {
  const is_rune = htmlFn === html;

  const footer =
    layout_content.footer ??
    htmlFn`<div class="${c.footer}">${
      is_rune
        ? new FooterView({ ...layout_content.footer_data, lang: ET.lang, is_mobile })
        : new FooterView({ ...layout_content.footer_data, lang: ET.lang, is_mobile }).toHtmlSSR()
    }</div>`;

  const floating_bottom_container = new FloatingBottomContainer(
    {
      floating_box: { up: true, ...floating_box, is_mobile },
      bottom_tab_bar: {
        on: has_bottom_tab_bar,
        selected_idx: selected_bottom_tab_idx,
      },
      app_info,
      is_logged_in: !!user,
      is_mobile,
    },
    {
      bottom: floating_bottom_margin,
    },
  );

  return htmlFn`
    <div class="${c.layout} ${htmlIf(c.full_width, full_width)} ${htmlIf(
    c.has_sub_header_sub_menu_container,
    has_sub_header_sub_menu_container ?? false,
  )}">
      <div class="${c.body_container} LayoutView__body_container">${layout_content.body}</div>
      ${footer}
      ${is_rune ? floating_bottom_container : floating_bottom_container.toHtmlSSR()}
      </div>
`;
};

export class LayoutView extends View<LayoutContent> {
  state: LayoutStates;

  constructor(data: LayoutContent, options: LayoutOptions) {
    super(data, options);

    this.state = {
      ...options,
      floating_box: options.floating_box || {},
      require_top_arrow_button: options.require_top_arrow_button ?? false,
      full_width: options.full_width ?? false,
      selected_bottom_tab_idx: options.selected_bottom_tab_idx ?? 0,
      has_bottom_tab_bar: options.has_bottom_tab_bar ?? false,
    };
  }

  override onMount() {
    super.onMount();

    this.listenHeaderSubMenuContainerAttached();
  }

  listenHeaderSubMenuContainerAttached() {
    listenDocumentEvent(DOCUMENT_EVENT.sub_header_menu_change, (e) => {
      const menu = e.detail.menu as SubHeaderMobileData['menus'][0];
      const is_attached = !!menu.sub_menus?.length;
      const klass = c.has_sub_header_sub_menu_container;

      if (is_attached) {
        this.element().classList.add(klass);
      } else {
        this.element().classList.remove(klass);
      }
    });
  }

  getIsMobile(): boolean {
    const is_mobile = rune.getSharedData(this)?.is_mobile ?? this.state.is_mobile;
    if (isNil(is_mobile)) throw new Error('is_mobile is not set');
    return is_mobile;
  }

  override template() {
    const is_mobile = this.getIsMobile();
    return makeLayoutHtml(this.data, { ...this.state, is_mobile }, html);
  }
}
