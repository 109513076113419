import { $delegate, $qs, $qsa, $trigger, $val } from 'fxdom/es';
import { defaultTo, each, find, go, head, html, omit, selEq } from 'fxjs/es';
import {
  DeleteOptionSetEvent,
  ProductMultiOptionSelector,
} from '../../../../../../../services/MarppleShop/renderApp/components/cells/ProductOptionQuantitySelector/ProductMultiOptionSelector.ts';
import { ButtonClose } from '../../../../../../../services/MarppleShop/shared/components/atoms/ButtonClose/ButtonClose.ts';
import { parseCustomEvent } from '../../../../../../../services/MarppleShop/shared/util/helper.ts';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppProductPbOptionF } from '../../../../Product/PbOption/F/Function/module/MShopAppProductPbOptionF.js';
import {
  handleChangeQuantity,
  handleClickButtonClose,
  handleFocusOutButtonClose,
  handleInputChange,
  handleSpoMultiOption,
  handleMultiOptionCloseButton,
  handleOpenSizeGuideModal,
  handleSpoTextOption,
  handleChangeQuantityNessCustom,
} from '../../../F/Mui/handles.js';
import { MShopAppProductDetailMobileOptionFormMuiS } from '../../S/Mui/module/MShopAppProductDetailMobileOptionFormMuiS.js';
import { MShopAppProductDetailMobileOptionFormTmplS } from '../../S/Tmpl/module/MShopAppProductDetailMobileOptionFormTmplS.js';
import { isStoreProductCustom } from '../../../../../../Creator/Product/S/fs.js';
import { NessCustomMakerF } from '../../../../../../NessCustomMaker/F/Function/module/NessCustomMakerF.js';
import { NessCustomMakerCoreF } from '../../../../../../NessCustomMaker/Core/F/Function/module/NessCustomMakerCoreF.js';
/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */

export const tab = {
  ...MShopAppProductDetailMobileOptionFormMuiS.tab,

  template: () => {
    const { is_mobile, product_detail, devices, is_seller, hyundai_n_products } = box();

    const {
      product_color,
      selected_option_group,
      spo,
      base_product: {
        ['name' + _en]: base_name,
        _: { base_product_colors },
      },
      stores_delivery_company,
    } = product_detail;
    const $device = $qs('[name="device"]');
    const $color = $qs('[name="color"]:checked');
    const selected_color_id = $color ? go($color, $val, Number) : null;
    const selected_device_id = $device ? go($device, $val, Number) : null;

    const selected_bpc = go(
      base_product_colors,
      find(selEq('id', selected_color_id)),
      defaultTo(head(base_product_colors)),
    );
    const selected_device = go(devices, find(selEq('id', selected_device_id)), defaultTo(head(devices)));

    const args = {
      is_seller,
      is_mobile,
      devices,
      spo,
      product_detail: {
        is_sticker: !UtilS.is_empty_object(selected_option_group) && base_name,
        ...product_color,
        _: { ...omit(['product_color'], product_detail) },
      },
      stores_delivery_company,
      selected_bpc,
      selected_device,
      has_color_options: !!selected_color_id,
    };

    return html`
      <form class="pd-form" name="cartForm" is-package="${!!hyundai_n_products}">
        ${MShopAppProductDetailMobileOptionFormTmplS.fn(args)}
      </form>
    `;
  },

  inner_scroll_target: '', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,
  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    MShopUtilF.hydrationRune(ButtonClose);

    const custom_level = box().product_detail?.stores_product?.custom_level;
    if (custom_level && isStoreProductCustom(custom_level)) {
      return nessCustomEvent(tab_el);
    }

    go(
      tab_el,
      $delegate('change', '.pd-form__pb_options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pSelectEvent(spo, changed_el);
        const quantity_els = $qsa('[name="quantity"]');
        if (quantity_els.length) {
          each($trigger('change', $qs('[name="quantity"]')), quantity_els);
        }
      }),
      $delegate('change', '.pd-form__pb_custom_options select', (e) => {
        const spo = box().product_detail.spo;
        const changed_el = e.currentTarget;
        MShopAppProductPbOptionF.pCustomSelectEvent(spo, changed_el);
      }),
      $delegate('click', '.pd-form .pd-counter__btn', handleChangeQuantity),
      $delegate('change', '.pd-field [name]', handleInputChange),
      $delegate('click', '.toggle-size-modal', handleOpenSizeGuideModal),

      /* spo multi option 관련 이벤트 */
      $delegate('input', 'select.spo_option', handleSpoMultiOption),
      $delegate('focusout', 'input.spo_text_option', handleSpoMultiOption),
      $delegate(
        parseCustomEvent(DeleteOptionSetEvent),
        `.${ProductMultiOptionSelector}`,
        handleMultiOptionCloseButton,
      ),

      $delegate('focusin', 'input.spo_text_option', handleSpoTextOption),
      $delegate('input', 'input.spo_text_option', handleSpoTextOption),
      $delegate('focusout', 'input.spo_text_option', handleFocusOutButtonClose),
      $delegate('mousedown', `.${ButtonClose}`, handleClickButtonClose),
    );
    const spo = box.sel('product_detail->spo');
    if (spo?._?.spo_options?.length) {
      const pb_options_el = $qs('.pd-form__pb_options');
      if (pb_options_el) {
        MShopAppProductPbOptionF.pSelectPageInit({ spo, pb_options_el });
      }
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {}, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {}, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered(tab_el) {}, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수
  hiding(tab_el, v) {}, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수
  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};

const nessCustomEvent = (tab_el) => {
  go(
    tab_el,
    $delegate('change', '.pd-form__pb_options select', (e) => {
      const spo = box().product_detail.spo;
      const changed_el = e.currentTarget;
      MShopAppProductPbOptionF.pSelectEvent(spo, changed_el);
      const quantity_els = $qsa('[name="quantity"]');
      if (quantity_els.length) {
        each($trigger('change', $qs('[name="quantity"]')), quantity_els);
      }
    }),
    $delegate('change', '.pd-form__pb_custom_options select', (e) => {
      const spo = box().product_detail.spo;
      const changed_el = e.currentTarget;
      MShopAppProductPbOptionF.pCustomSelectEvent(spo, changed_el);
    }),
    $delegate('click', '.pd-form .pd-counter__btn', handleChangeQuantityNessCustom),
    $delegate('change', '.pd-field [name]', async (e) => {
      const name = e.currentTarget.name;
      if (name === 'size') {
        $.don_loader_start();
        await NessCustomMakerCoreF.changeProductBaseProductSize(Number(e.currentTarget.value));
        $.don_loader_end();
      }
      handleInputChange(e);
      NessCustomMakerF.refreshCustomProductDetailHead();
    }),
    $delegate('click', '.toggle-size-modal', handleOpenSizeGuideModal),

    /* spo multi option 관련 이벤트 */
    $delegate(
      parseCustomEvent(DeleteOptionSetEvent),
      `.${ProductMultiOptionSelector}`,
      handleMultiOptionCloseButton,
    ),
    $delegate('mousedown', `.${ButtonClose}`, handleClickButtonClose),
  );
};
