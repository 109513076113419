import { $delegate, $find, $hide, $qs, $setCss, $show } from 'fxdom/es';
import { debounce, go } from 'fxjs/es';
import NewHeadroom from 'headroom.js';
import { NessAppPlusHeaderF } from '../../../App/Plus/Header/F/Function/module/NessAppPlusHeaderF.js';
import { NessUtilF } from '../../../Util/F/Function/module/NessUtilF.js';
import { NessCoreConstantS } from '../../S/Constant/module/NessCoreConstantS.js';
import { changeIconColor } from './core.js';

const getHeaderEl = (header_el) => header_el || $qs(`.${NessCoreConstantS.layout_components.header}`);

let newHeadroom = null;

export const getHeaderHeadroom = () => newHeadroom;

/**
 * @dev headroom 적용
 * @param {HTMLElement} [_header_el]
 */
export const applyHeaderHeadroom = (_header_el) => {
  // headroom 동작
  const header_el = getHeaderEl(_header_el);
  const headroom_target_klass = `${NessCoreConstantS.layout_components.header}__position-wrapper`;
  const headroom_target_el = $find(`.${headroom_target_klass}`, header_el);

  header_el.state.hooks = {
    ...header_el.state.hooks,
    onPin: [],
    onUnpin: [],
    onTop: [],
    onNotTop: [],
    onBottom: [],
    onNotBottom: [],
  };

  const callHooks = (name) =>
    header_el.state.hooks[name].forEach((fn) => fn(header_el, headroom_target_el()));

  const headroom_instance = new NewHeadroom(headroom_target_el, {
    classes: {
      initial: headroom_target_klass,
      pinned: `${headroom_target_klass}--pinned`,
      unpinned: `${headroom_target_klass}--unpinned`,
      top: `${headroom_target_klass}--top`,
      notTop: `${headroom_target_klass}--not-top`,
      bottom: `${headroom_target_klass}--bottom`,
      notBottom: `${headroom_target_klass}--not-bottom`,
      frozen: `${headroom_target_klass}--frozen`,
    },
    onPin() {
      callHooks('onPin');
    },
    onUnpin() {
      callHooks('onUnpin');
    },
    onTop() {
      callHooks('onTop');
    },
    onNotTop() {
      callHooks('onNotTop');
    },
    onBottom() {
      callHooks('onBottom');
    },
    onNotBottom() {
      callHooks('onNotBottom');
    },
  });

  headroom_instance.init();

  header_el.headroom = headroom_instance;

  return headroom_instance;
};

/**
 *
 * @dev header 의 headroom 이 동작할 때, hook 때 호출하고 싶은 함수들을 등록 (headroom 초기화 부분이 init 스크립트에 있어서 개별 영역에서 접근하려면 이런식으로)
 * @param {HTMLElement} header_el
 * @param {string} hook_name
 * @param {() => void} callback
 * @param {boolean} is_remove
 */
export const registerHeadroomHook = function onHeadroomHook(header_el, hook_name, callback, is_remove) {
  const { hooks } = header_el.state;
  if (!header_el.headroom) throw new Error('headroom not init');
  if (is_remove) {
    const idx = hooks[hook_name].findIndex(callback);
    hooks[hook_name].splice(idx, 1);
  } else {
    hooks[hook_name].push(callback);
  }
};

/**
 *
 * @dev 컬렉션과 에디토리얼 상세에서 header 의 headroom 이 동작할 때, 타이틀을 토글 시켜준다.
 * @param {HTMLElement} [_header_el]
 * @param {boolean} should_hide
 */
export const toggleSelectedTitle = (_header_el, should_hide) => {
  const header_el = getHeaderEl(_header_el);

  const selected_title_el = $find(
    `.${NessCoreConstantS.layout_components.header}__selected-title`,
    header_el,
  );
  if (!selected_title_el) return;

  if (should_hide) {
    // 인라인으로 width 안달려있으면 transition 위해서 초기화 진행
    if (!selected_title_el.style.width) {
      const { width } = selected_title_el.getBoundingClientRect();
      $setCss({ width }, selected_title_el);
      selected_title_el.offsetHeight; // force recalculate layout
    }
    $setCss({ width: 0 }, selected_title_el);
  } else {
    $setCss({ width: 'auto' }, selected_title_el);
    const width = selected_title_el.getBoundingClientRect()?.width;
    // auto 로 바꿔서 width 계산하는 과정에서 transition 이 끊어졌기 때문에 다시 0 으로 돌려서 transition 하고 recalculate 진행
    $setCss({ width: 0 }, selected_title_el);
    selected_title_el.offsetHeight; // force recalculate layout
    $setCss({ width }, selected_title_el);
  }
};

/**
 * @param {HTMLElement} [_header_el]
 * @param {"white" | "black"} [content_color] 색상 설정
 * @param {"white" | "black" | "transparent"} [background_color] 색상 설정
 * @param {boolean} [hide_title] 색상 설정
 */
export const changeHeaderColor = function changeHeaderColor(_header_el, { content_color, background_color }) {
  if (!content_color && !background_color) return;

  const header_el = getHeaderEl(_header_el);

  if (content_color) header_el.dataset.contentColor = content_color;
  if (background_color) header_el.dataset.backgroundColor = background_color;

  // icon color change - icon 많아지면 getIconEls 로 추상화하기
  const logo_icon_el = $find(`.${NessCoreConstantS.layout_components.header}__logo-wrapper svg`, header_el);
  if (logo_icon_el) changeIconColor([logo_icon_el], content_color);

  header_el.state?.hooks?.onChangeColor?.({ content_color, background_color });
};

const initGlobalMenu = () => {
  const global_menu_el = $qs(`.${NessCoreConstantS.layout_components.global_menu_popup}`);

  // 닫을 때 이벤트 잡아서 display none
  global_menu_el.addEventListener('transitionend', () => {
    if (global_menu_el.style.opacity == '0') $hide(global_menu_el);
  });

  global_menu_el.initialized = true;
};

/**
 *
 * @param {boolean} is_open
 */
export const handleGlobalMenu = (is_open) => {
  const global_menu_el = $qs(`.${NessCoreConstantS.layout_components.global_menu_popup}`);
  // don frame fix
  if (is_open) {
    $show(global_menu_el);
    // animation frame 에서 하지 않으면 display 바뀌는 것과 opacity 변환이 한 스택에서 이루어지면서 transition 이 일어나지 않음
    requestAnimationFrame(() => {
      global_menu_el.style.opacity = '1';
    });
  } else {
    global_menu_el.style.opacity = is_open ? '1' : '0';
    // display none 은 transitionend 이벤트 리스너에 달려있음, initGlobalMenu 함수 참고
  }
};

/**
 * @dev header 가 light 상태일때, 스크롤 내려오면 theme 을 dark 로 변경, (layering = true, theme = light 인 곳만 사용하기)
 * header 는 항상 위쪽에 있으니까, scroll 된 거리로 진행 여부 판단
 * @param {HTMLElement} header_el
 */
export const initHeaderAnimation = (header_el) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        changeHeaderColor(header_el, { content_color: 'white', background_color: 'transparent' });
      } else {
        changeHeaderColor(header_el, { content_color: 'black', background_color: 'white' });
      }
    },
    {
      rootMargin: '0px 0px 0px 0px',
    },
  );

  observer.observe(header_el);
};

/**
 * @dev header 관련된 javascript initialize, hooks 는 차차 추가해나가기
 * @param {Object} params
 * @param {HTMLElement} params.header_el
 * @param {boolean} params.is_mobile
 * @param {Object} params.hooks
 * @param {(string) => void} params.hooks.onChangeColor
 */
export const initNessHeader = (params) => {
  const header_el = getHeaderEl(params.header_el);

  // state
  header_el.state = {
    init: true,
    hooks: params.hooks,
  };

  // headroom 동작
  if (header_el.dataset.headroom == 'true') {
    newHeadroom = applyHeaderHeadroom(header_el);
  }

  if (params.is_mobile) {
    // init global menu popup
    initGlobalMenu();
    const menu_button_el = $find(`.${NessCoreConstantS.layout_components.header}__right-menu`, header_el);
    const close_button_el = $find(
      `.${NessCoreConstantS.layout_components.global_menu_popup}__head-close-icon`,
      header_el,
    );

    if (menu_button_el) {
      menu_button_el.addEventListener('click', () => handleGlobalMenu(true));
    }
    if (close_button_el) {
      close_button_el.addEventListener('click', () => handleGlobalMenu(false));
    }
  }

  // getDefaultHeaderCenter_selected 일때
  const back_button_el = $find(`.${NessCoreConstantS.layout_components.header}__back-btn`, header_el);
  if (back_button_el) {
    back_button_el.addEventListener('click', (e) => {
      // location.href = `${G.NESS_MAIN_LINK}/@/${x_button_el.dataset.path}`;
      history.back();
    });
  }

  const bottom_header_category_el = $qs(
    `.${NessCoreConstantS.layout_components.bottom_header_category}__core`,
  );

  if (bottom_header_category_el) {
    /* 더보기 버튼 표출 유무 컨트롤 */
    if (NessUtilF.isMobile()) {
      NessAppPlusHeaderF.scrollActiveCateListToBeCentered();
    }
    NessAppPlusHeaderF.controlViewMoreBtnVisibilityInCateListHeader();

    window.addEventListener(
      'resize',
      debounce(() => NessAppPlusHeaderF.controlViewMoreBtnVisibilityInCateListHeader(), 200),
    );
  }

  go(
    header_el,
    $delegate('click', `.${NessCoreConstantS.layout_components.header}__login`, (e) => {
      e.originalEvent.preventDefault();
      location.href = `${location.origin}/login?url=${location.pathname}${location.search}`;
    }),
    $delegate('click', `.${NessCoreConstantS.layout_components.global_menu_popup}__login`, (e) => {
      e.originalEvent.preventDefault();
      location.href = `${location.origin}/login?url=${location.pathname}${location.search}`;
    }),
  );

  return header_el;
};
