import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { extend } from 'fxjs/es';

export const setSnsData = (url) => {
  const sns = {
    type: '',
    ico_url: '',
    head_ico_url: '',
    link: UtilS.makeUrl(url) || '',
  };

  if (!url || !url.length) return sns;

  if (url.indexOf('youtube') != -1 || url.indexOf('youtu.be') != -1)
    return extend(sns, {
      type: 'youtube',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/82e0595399dae11ee2e3eb2b48692677eabbe6871.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/74adc0abb42f1622c9346d2d857aa5f28fe89dff2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/1869259b2d28718c7ffdfc039f46ac443a24c795e8c4af1.svg',
    });
  else if (url.indexOf('instagram') != -1)
    return extend(sns, {
      type: 'instagram',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/83826689827ee2f9813f263cf555a04f2304ba9c1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/9ccc7c272eb9cb4d2a7e5d3686a1109f075fb0172.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692608a1c885fca72a3f0fc592de3fe7edaad58f9c005.svg',
    });
  else if (url.indexOf('grafolio') != -1)
    return extend(sns, {
      type: 'grafolio',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/30f9c83c7e082ae30d678276985bf82a4367dbf91.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/b2c8a3d03bc998aa98da836af93d715e36d891ca2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/186925762dc538121cd0c87ed585bc8b7272fce2d4296db.svg',
    });
  else if (url.indexOf('facebook') != -1)
    return extend(sns, {
      type: 'facebook',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/6ece0c22651da5f7e586488dbcb49de8f5f868c01.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/8acc9e2f41f3f2fdbc7732dca831dee68458f92c2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692623f85db52d7240c93be8bdd9a2ef55a9a563a5fbb.svg',
    });
  else if (url.indexOf('twitch') != -1)
    return extend(sns, {
      type: 'twitch',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/b0cf58178a98c3c38dd06e4075184f1fe67d58ef1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/a921ebc054b12e04f6c56bea0bb737a50a46eff22.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/18692618df9f0e7cab511c0d0c2fad8024c0c4dcd6e34a2.svg',
    });
  else if (url.indexOf('twitter') != -1)
    return extend(sns, {
      type: 'twitter',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/25d4831f682c99a554354bfcf22ee85841e329cc1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/4b16f8b117ad64e8ab28cc9b85a0889b78945c402.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1865718064d6b8459a2d6dd3e66b340333e4babeb734485.svg',
    });
  else if (url.indexOf('cafe.naver') != -1)
    return extend(sns, {
      type: 'naver cafe',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/2d05f53978e4d72969f60b7ecb2a95e29392c2da1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/05a33be8cbbe2937deced3847ccd57885c45b54d2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/186925830b1d4b72512b15bc22adbaef5db59027d214384.svg',
    });
  else if (url.indexOf('comic.naver') != -1)
    return extend(sns, {
      type: 'naver webtoon',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/3e13a397e5c430b33e03d1fda57f902e4cc27f2c1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/6f9081953e1c8cc7912d5bbc5b9041620935ccd92.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_14345/2020/3/original/186925830b1d4b72512b15bc22adbaef5db59027d214384.svg',
    });
  else if (url.indexOf('page.kakao') != -1)
    return extend(sns, {
      type: 'kakao page',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/1ba89914396e28b102a5343c4909ea3ca38033cb1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/c19cc47ac7dfafe2e92b23aae4d42a2ea42aa5f62.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657207f0b6722560c71472ccb36544dc9de28fcdf5da9.svg',
    });
  else if (url.indexOf('webtoon.daum') != -1)
    return extend(sns, {
      type: 'daum webtoon',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/01cb22cfd66299fa85d84f624cefe549f4c792c51.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/cd2e1ce73a76de1ff3f39bbf41b6d49a91b513482.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186571982c427c152f6a1b41fa67a0e51ed72c26a670e93.svg',
    });
  else if (url.indexOf('blog.naver') != -1)
    return extend(sns, {
      type: 'naver blog',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/ab39b72cee5d9d86cfd896ee7a39adbe062da5441.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/8bce53ab61e47a31f96ffff0dbd0c9a8845ee1d82.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/190884750759154c69d2474baf7d2f8920cc3e23529cf03.svg',
    });
  else if (url.indexOf('band.us') != -1)
    return extend(sns, {
      type: 'naver band',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/e329b75e27e80f2d149418661b8dd4c4d71b4e6e1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/9338464f4fda8449d35eee11af84a070a6f556332.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/190884750759154c69d2474baf7d2f8920cc3e23529cf03.svg',
    });
  else if (url.indexOf('pinterest') != -1)
    return extend(sns, {
      type: 'pinterest',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/e2bbd6032f06728fd3dd81d427ef1d4c6d27eb6a1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/30d45dfe11db4f658a0494bb9697a18ae89ad0be2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/186572131a073f75cb0b92e2a53e3f52079b2d17c29702f.svg',
    });
  else if (url.indexOf('tiktok') != -1)
    return extend(sns, {
      type: 'tiktok',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/a74bc34c76cda66516bf550d8c85511ca66ee1ba1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/eb708d54362913365c4b40f14e50c85ada61702e2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/18657225bca2126635ad62c76984a6c30f70512fdb0d51a.svg',
    });
  else if (url.indexOf('behance') != -1)
    return extend(sns, {
      type: 'behance',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/55caa114d85f557b7746cfabee46171b4657a4511.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/a8bfcd5f8a57991297107131ef87c79dbf87595c2.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg',
    });
  else if (url.indexOf('brunch.co') != -1)
    return extend(sns, {
      type: 'brunch',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/541a46c17c3ce97eec886e4302f2ef7f6f8cca651.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/07ceaac8288d4ffffb037421678d5ed73571aa602.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908848114f09ab338e235a3de2226aeafc5215a2e9562a.svg',
    });
  else if (url.indexOf('spooncast') != -1)
    return extend(sns, {
      type: 'spooncast',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/74ccc291b6c2e8f42c539d73f5f7d979ba97efde1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/fc5d7d17edd7b0e02f3fa91ba39f5a030e1027012.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1866068b7eee88c9b383ca70466400027ca379ae1464b83.svg',
    });
  else if (url.indexOf('cafe.daum') != -1)
    return extend(sns, {
      type: 'daum cafe',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/3f1390cdb6fbf3a6cd1d5f004aca60d23e43cbdf1.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/ed500d225c320ac1581343b552734aa1dc0b88c72.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/5/original/186632584b19bb35d3d534bf2c46ec0d1a27035b91b6e99.svg',
    });
  else
    return extend(sns, {
      type: 'site',
      ico_url: '//s3.marpple.co/files/u_218933/2021/5/original/620dcf1ad0a49193028849282168e992242b8aa31.png',
      ico_url2:
        '//s3.marpple.co/files/u_218933/2021/5/original/6afff3ba7de55eacb5f5b2f6fc6cf483bf381b252.png 2x',
      head_ico_url:
        '//s3.marpple.co/files/u_218933/2020/4/original/1908846be76562591509b3e23f9ee7f2d66902dcf8f2988.svg',
    });
};
