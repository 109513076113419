import { each, pipe } from 'fxjs/es';
import { $find, $findAll } from 'fxdom/es';

// export const iscroll_showing = pipe(
//   $find('.iscroll_wrapper'),
//   // each($setCss({ opacity: 0, transition: 'opacity 0.2s' })),
// );

export const iscroll_showed = pipe(
  $findAll('.iscroll_wrapper'),
  each((el) => {
    if (el.myScroll) return el.myScroll.refresh();
    el.myScroll = new IScroll(el, {
      click: true,
    });
    el.myScroll.scrollToElement($find('>.iscroll_ul_wrapper >.i_item.selected', el), 0, true, true);

    // $setCss({ opacity: 1 })(el);
  }),
  // $.css({ opacity: 1 }),
);

export const iscroll_hiding = pipe(
  $findAll('.iscroll_wrapper'),
  each((el) => {
    if (!el.myScroll) return;
    // el.myScroll.scrollTo(0, 0, 0);
    el.myScroll.destroy();
    el.myScroll = null;

    // $.iscroll_off
  }),
);
