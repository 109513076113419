import { go } from 'fxjs/es';
import { renderSelectOption, renderSelectOptionNess } from './renderSelectOption.js';
import { renderSelectOptionNone, renderSelectOptionNoneNess } from './renderSelectOptionNone.js';
import { $findAll } from 'fxdom/es';

export const pSelectPageInit = ({ spo, pb_options_el, disabled }) => {
  go(spo._.spo_options, (spo_options) =>
    renderSelectOptionNone({
      spo_options,
      spo_option_els: go(pb_options_el, $findAll('.spo_option')),
      disabled,
    }),
  );
  renderSelectOption({
    spo_items: spo._.spo_items,
    spo_options: spo._.spo_options,
    selected_spo_option_i: 0,
    spo_option_els: go(pb_options_el, $findAll('.spo_option')),
    disabled,
  });
};

export const pSelectPageInitNess = ({ spo, pb_options_el }) => {
  go(spo._.spo_options, (spo_options) =>
    renderSelectOptionNoneNess({ spo_options, spo_option_els: go(pb_options_el, $findAll('.spo_option')) }),
  );
  renderSelectOptionNess({
    spo_items: spo._.spo_items,
    spo_options: spo._.spo_options,
    selected_spo_option_i: 0,
    spo_option_els: go(pb_options_el, $findAll('.spo_option')),
  });
};
