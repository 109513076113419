import axios from 'axios';
import { $data, $find, $findAll, $hasClass, $hide, $qs, $qsa, $setCss, $show } from 'fxdom/es';
import { each, go, map, omit, sel } from 'fxjs/es';

import { BpOptionF } from '../../../../BpOption/F/Function/module/BpOptionF.js';
import { getCurrentFcanvas } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { getCurrentBpfInMaker, getProductColorInMaker } from '../../../../Maker/F/getSth.js';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { makeBpcfAvgSize } from '../../../../Maker/F/canvas_size.js';
import { BpOptionKeyRingConstantS } from '../../../../BpOption/KeyRing/S/Constant/module/BpOptionKeyRingConstantS.js';
import { makeFakeMpMakerSize } from '../../../../Maker/F/fake_mp_maker.js';
import { NewMakerCvObjActionF } from '../../../../NewMaker/CvObjAction/F/Function/module/NewMakerCvObjActionF.js';
import { stickerChangePreview } from './fs.js';
import { NewMakerBpOptionF } from '../../../../NewMaker/BpOption/F/Function/module/NewMakerBpOptionF.js';
import { BpOptionS } from '../../../../BpOption/S/Function/module/BpOptionS.js';
import { openExMakerEditor } from './private/openExMakerEditor.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { onbeforeunload_event_on_vector_editor } from '../../../../Maker/F/util.js';
import { renderProductColorInProductDetailMobile } from '../../../../Maker/F/maker_tmpl.js';
import { marpplizerProxy } from '../../../../Maker/F/Marpplizer/marpplizer.js';
import { MakerDebugF } from '../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { calcMakerPrice } from '../../../../ProductColorPrice/S/fs.js';

const ompSideBarShow = () => {
  if (G.collabo_type === '') {
    go($qsa('.omp-layout__sidebar'), each($show));
  }
};

const ompSideBarHide = () => {
  if (G.collabo_type === '') {
    go($qsa('.omp-layout__sidebar'), each($hide));
  }
};

async function urlToSvgFile(url) {
  const res = await axios.get(url);
  return new File([res.data], 'original_svg_file.svg', { type: 'image/svg+xml' });
}

export const enterSvgApplication = async ({ tab_el, svg_url }) => {
  const bp_option_groups_el = $qs('.bp_option_groups');
  const lockable_bp_option_group_els = $qsa('.bp_option_groups .bp_option_group[data-is_lockable="true"]');

  const svg_file = svg_url && go(svg_url, urlToSvgFile);
  const print_area = getCurrentBpfInMaker().size_faces[0].print.px;
  const product = getProductColorInMaker();
  const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
  product._.selected_option_group = selected_option_group;
  const bp_option_id_for_db_data = go(
    BpOptionS.selectFittedSizeBpOption({
      base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
      selected_option_group,
    }),
    sel('id'),
  );
  await BpOptionF.makeSelectedOptionPriceC2323(
    {
      base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
      bp_option_groups_el,
      selected_option_group,
    },
    bp_option_id_for_db_data,
    true,
  );
  const title = product._.base_product.name;

  const price = calcMakerPrice({ pc: product, is_not_designed: true });

  const thumbnail_width_min_px = print_area.width;

  const options = go(
    bp_option_groups_el,
    $findAll('.bp_option_group'),
    map($find('select')),
    map((select_el) => {
      const bp_option_id = parseInt(select_el.value);
      const bp_option_group = JSON.parse(JSON.stringify($data(select_el)));
      const bp_option = JSON.parse(
        JSON.stringify($data($find(`option[value="${select_el.value}"]`)(select_el))),
      );
      return {
        bp_option_id,
        snapshot: {
          bp_option_group: omit('_')(bp_option_group),
          bp_option: omit('_')(bp_option),
          maker_meta: bp_option._.maker_meta,
        },
      };
    }),
  );

  const app = go($qs('html'), $hasClass('creator'), (is_creator) => (is_creator ? 'creator' : 'mp'));
  $setCss({ visibility: 'hidden' }, $qs('#maker_frame'));
  $setCss({ visibility: 'hidden' }, getCurrentFcanvas().lowerCanvasEl);
  onbeforeunload_event_on_vector_editor(true);
  try {
    ompSideBarHide();
    if (product._.base_product.maker_type === VectorEditorConstantS.KEYRING_EDITOR) {
      const { openKeyringEditor } = await import('./private/openKeyringEditor.js');
      await openKeyringEditor({
        editor_props: {
          tab_el,
          price,
          title,
          options,
          svg_file,
        },
        app,
        post_process_props: {
          product,
          lockable_bp_option_group_els,
          bp_option_groups_el,
        },
      });
      if (!bp_option_groups_el.dataset.is_designed) {
        await NewMakerCvObjActionF.render({ bp_option_ids: selected_option_group.bp_option_ids });
      }
    } else if (product._.base_product.maker_type === VectorEditorConstantS.STICKER_EDITOR) {
      const { openStickerEditor } = await import('./private/openStickerEditor.js');
      const thumbnail_factor = 2;
      const thumbnail_stroke_width_px = 1;
      await openStickerEditor({
        app,
        editor_props: {
          tab_el,
          price,
          title,
          options,
          svg_file,
          thumbnail_width_min_px: thumbnail_width_min_px * thumbnail_factor,
          thumbnail_stroke_width_px: thumbnail_stroke_width_px * thumbnail_factor,
        },
        post_process_prop: { product, lockable_bp_option_group_els, bp_option_groups_el },
      });
      if (bp_option_groups_el.dataset.is_designed !== 'true') {
        await stickerChangePreview(bp_option_groups_el);
      }
    } else if (product._.base_product.maker_type === VectorEditorConstantS.PHOTO_CARD_EDITOR) {
      // await openPhotoCardEditor({
      //   app,
      //   editor_props: {
      //     tab_el,
      //     price,
      //     title,
      //     options,
      //     svg_file,
      //   },
      //   post_process_props: {
      //     product,
      //     lockable_bp_option_group_els,
      //     bp_option_groups_el,
      //   },
      // });
    } else if (product._.base_product.maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR) {
      const { openAcrylicFigureEditor } = await import('./private/openAcrylicFigureEditor.js');
      await openAcrylicFigureEditor({
        post_process_props: {
          product,
          lockable_bp_option_group_els,
          bp_option_groups_el,
        },
        app,
        editor_props: {
          title,
          options,
          svg_file,
          price,
          tab_el,
        },
      });
      if (bp_option_groups_el.dataset.is_designed !== 'true') {
        const preview_images = BpOptionS.selectedOptionGroup.getPreviewImagesInMaker(
          box().maker.product_color._.base_product._.base_products_bp_option_groups,
          selected_option_group,
        );
        if (preview_images?.length) {
          await NewMakerBpOptionF.addPreviewImage(preview_images);
        }
      }
    } else if (product._.base_product.maker_type === VectorEditorConstantS.EX_MAKER_EDITOR) {
      await openExMakerEditor({
        app,
        editor_props: {
          title,
          options,
          svg_file,
          price,
          tab_el,
        },
        post_process_props: {
          product,
          lockable_bp_option_group_els,
          bp_option_groups_el,
        },
      });
    } else {
      console.error('관련 maker_type 이 없습니다.');
    }
    await BpOptionF.makeSelectedOptionPriceC2323({
      base_products_bp_option_groups: product._.base_product._.base_products_bp_option_groups,
      bp_option_groups_el,
      selected_option_group,
    });
    if (!UtilF.isLegacyMobile()) {
      await marpplizerProxy();
    } else {
      renderProductColorInProductDetailMobile();
      G.mp.maker.bpcf_size = makeBpcfAvgSize();
      if (
        selected_option_group.bp_option_ids.includes(BpOptionKeyRingConstantS.KEYRING_NO_GORI_BP_OPTION_ID)
      ) {
        G.mp.maker.bpcf_size = {
          top: 207.12209302325581,
          left: 132.99418604651163,
          height: 239.38953488372093,
          bottom: 220.63953488372093,
          width: 110.75581395348837,
        };
      }
      makeFakeMpMakerSize();
      G.mp.maker.zoom.canvas_container_display_mode(true);
    }
  } catch (error) {
    if (
      [
        'viewport_coordinate_system.height 는 0 보다 커야 합니다.',
        'viewport_coordinate_system.width 는 0 보다 커야 합니다.',
      ].includes(error?.message)
    ) {
      await $.alert(T(`maker_error_alert::화면을 크게해서 다시 시도 해주세요.`));
      window.location.reload();
    } else if (error?.__mp_alert_message) {
      await $.alert(error?.__mp_alert_message);
    } else {
      const message = error?.message ?? '알수 없는 오류가 발생 했습니다.';
      await $.alert(message);
      MakerDebugF.api.MakerDebugPost(
        getProductColorInMaker(),
        error,
        MakerDebugConstantS.Category.SvgEditor,
        'enterSvgApplication',
      );
    }
    marpplizerProxy();
  }
  ompSideBarShow();
  $setCss({ visibility: 'visible' }, $qs('#maker_frame'));
  $setCss({ visibility: 'visible' }, getCurrentFcanvas().lowerCanvasEl);
  onbeforeunload_event_on_vector_editor();
};
