import { html, View } from 'rune-ts';
import klass from './Popup.module.scss';
import Swiper from 'swiper';
import { EffectFade, Pagination } from 'swiper/modules';
import { filter, map, pipe, toArray } from '@fxts/core';
import { CloseIcon } from '../../atoms/Icon';
import { typo } from '../../../typography/typo';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { htmlIf } from '../../../util';
import { getResizedUrl } from '../../../util/image';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { PopupApiS } from '../../../../features/Popup/outbound/share';

export interface PopupSlide {
  image_url: string;
  id?: number;
  link_url?: string;
  close_color_code: string;
  button_bg_color_code: string;
  button_text_color_code: string;
}

export interface PopupProps {
  popups?: PopupSlide[];
  custom_klass?: string;
  is_preview?: boolean;
  crew_id?: number;
  product_id?: number;
  store_id?: number;
}

export interface ClientPopupProps {
  popups: PopupSlide[];
  custom_klass?: string;
  is_preview?: boolean;
  crew_id?: number;
  product_id?: number;
  store_id?: number;
}

export class Popup extends View<PopupProps> {
  current_popup?: PopupSlide;
  swiper?: Swiper;

  constructor(props: PopupProps) {
    super(props);

    if (props.popups && props.popups.length > 0) {
      this.current_popup = props.popups[0];
    }
  }

  override template() {
    const { custom_klass = '', is_preview = false } = this.data;

    const {
      close_color_code = '',
      button_bg_color_code = '',
      button_text_color_code = '',
    } = this.current_popup || {};

    return html`
      <div class="${klass.container} ${custom_klass}" data-preview="${is_preview}">
        <div class="${klass.backdrop}"></div>

        <div class="${klass.popup_content}">
          <div class="swiper ${klass.swiper}">
            <div class="swiper-wrapper ${klass.swiper_wrapper}">${this._renderPopupList()}</div>
            ${htmlIf(
              html`<div class="swiper-pagination ${klass.pagination}"></div>`,
              !!this.data.popups && this.data.popups?.length > 1,
            )}
            <div class="${klass.close_icon}" style="color:${close_color_code}">${CloseIcon}</div>
          </div>
          <div
            class="${klass.button_container}"
            style="background-color:${button_bg_color_code};color:${button_text_color_code}"
          >
            <span class="${klass.show_today} ${typo('14_medium')}">오늘 보지 않기</span>
            <span class="${klass.close} ${typo('14_bold')}">닫기</span>
          </div>
        </div>
      </div>
    `;
  }

  protected override onUnmount() {
    if (!this.data.is_preview && MShopUtilF.isMobile()) {
      MShopUtilF.allowScroll();
    }
  }

  protected override onMount() {
    this.swiper = this._onLoadPopupSwiper();

    if (!this.data.is_preview && MShopUtilF.isMobile()) {
      MShopUtilF.preventScroll();
    }

    this.delegate('click', `.${klass.close}, .${klass.close_icon}`, () => {
      this.closeCurrentPopup();
    });

    this.delegate('click', `.${klass.show_today}`, async () => {
      const popup_id = this.current_popup?.id;
      this.closeCurrentPopup();
      if (popup_id) {
        await PopupApiS.postSetPopupCookie({ popup_id });
      }
    });
  }

  private _onLoadPopupSwiper() {
    return new Swiper(`.${klass.swiper}`, {
      modules: [Pagination, EffectFade],
      slidesPerView: 1,
      loop: !!(this.data.popups && this.data.popups.length > 1),
      pagination: {
        el: '.swiper-pagination',
        bulletClass: klass.bullet,
        bulletActiveClass: klass.active_bullet,
        clickable: true,
      },
      on: {
        slideChange: (swiper) => {
          const { popups } = this.data;
          if (popups) {
            this.current_popup = popups[swiper.realIndex] || popups[0];
            this.setPopupData(this.current_popup, popups);
          }
        },
      },
    });
  }

  private _renderPopupList() {
    return pipe(
      this.data.popups || [],
      map(({ image_url, link_url }) => {
        return {
          image_url: getResizedUrl({
            url: image_url,
            width: 1120,
            height: 840,
            quality: 85,
            format: 'webp',
          }),
          link_url: link_url ? UtilS.unEscaper(link_url) : '',
        };
      }),
      map(
        ({ image_url, link_url }) =>
          html`
            <${link_url ? 'a' : 'div'}
              class="swiper-slide ${klass.swiper_slide}"
              ${link_url ? html`href="${link_url}"` : ''}
            >
              <img src="${image_url}" alt="Popup Image" />
            </${link_url ? 'a' : 'div'}>
          `,
      ),
      toArray,
    );
  }

  setPopupData(custom_popup_slide?: PopupSlide, popups?: PopupProps['popups']) {
    this.data.popups = popups;
    this.current_popup = custom_popup_slide;
    const $swiper_container = this.element().querySelector(`.${klass.swiper}`) as HTMLElement;
    const $button_container = this.element().querySelector(`.${klass.button_container}`) as HTMLElement;
    const $close_icon_wrapper = this.element().querySelector(`.${klass.close_icon}`) as HTMLElement;

    $swiper_container.style.backgroundColor = this.current_popup
      ? this.current_popup.button_bg_color_code
      : '';
    $button_container.style.backgroundColor = this.current_popup
      ? this.current_popup.button_bg_color_code
      : '';
    $button_container.style.color = this.current_popup ? this.current_popup.button_text_color_code : '';
    $close_icon_wrapper.style.color = this.current_popup ? this.current_popup.close_color_code : '';
  }

  closeCurrentPopup() {
    if (this.data.popups) {
      if (this.data.popups?.length > 1) {
        this.data.popups = pipe(
          this.data.popups,
          filter((popup) => popup.id !== this.current_popup?.id),
          toArray,
        );
        this.redraw();
      } else {
        this.element().remove();
      }
    }
  }

  override redraw() {
    super.redraw();
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = this._onLoadPopupSwiper();
    }
    return this;
  }
}
