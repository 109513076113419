export const POPUP_EXPOSURE_TYPE = {
  HOME: 'HOME',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  HOME_AND_PRODUCT_DETAIL: 'HOME_AND_PRODUCT_DETAIL',
} as const;

export const POPUP_GOODS_TYPE = {
  ALL: 'ALL',
  MPS: 'MPS',
  TPL: 'TPL',
  PBG: 'PBG',
  DTG: 'DTG',
  CUSTOM: 'CUSTOM',
} as const;

export const POPUP_EXPOSURE_AT_HOME = [POPUP_EXPOSURE_TYPE.HOME, POPUP_EXPOSURE_TYPE.HOME_AND_PRODUCT_DETAIL];
export const POPUP_EXPOSURE_AT_PRODUCT_DETAIL = [
  POPUP_EXPOSURE_TYPE.PRODUCT_DETAIL,
  POPUP_EXPOSURE_TYPE.HOME_AND_PRODUCT_DETAIL,
];
