import { html } from 'fxjs/es';

export const popupApplySeller = () => {
  return html`
    <div class="app-home-modal">
      <div class="app-home-modal__mask"></div>
      <div class="app-home-modal__container">
        <div class="app-home-apply-seller">
          <div class="app-home-apply-seller__image-box">
            <img
              src="//s3.marpple.co/files/u_1396787/2022/2/original/79bd1ed944da6f8586a8ba49892acda825df70e91.png"
              class="app-home-apply-seller__image"
              alt="지금 셀러 신청하면 5000포인트 바로 지급!"
            />
          </div>
          <div class="app-home-apply-seller__button-box">
            <button class="app-home-apply-seller__button app-home-apply-seller__button--close">닫기</button>
            <button class="app-home-apply-seller__button app-home-apply-seller__button--detail">
              자세히 보기
            </button>
          </div>
        </div>
      </div>
    </div>
  `;
};
