import { createCustomToolbar } from './common/custom-toolbar.js';
import { $addClass, $closest, $delegate, $findAll, $on, $prependTo, $remove, $removeClass } from 'fxdom/es';
import { go, noop, filter, takeAll } from 'fxjs/es';
import { calcDistance } from './common/util.js';
import { openButtonEditor } from '../custom-insert-plugin/button.js';
import { addAlignClass } from './medium-editor-text-align.js';

const hideToolbar = (toolbar_el) => $removeClass('medium-editor-toolbar-active', toolbar_el);

const closeToolbarHandler = (toolbar_el) => (e) => {
  const button_container_el = $closest('.editor-button-container', e.target);
  if (button_container_el) return;
  hideToolbar(toolbar_el);
};

const getContainerElFromChild = $closest('.editor-button-container');

const openToolbar = ($container, $toolbar, distance) => {
  $prependTo($container, $toolbar);
  $toolbar.style.left = `${distance.left}px` || '50%';
  $addClass('medium-editor-toolbar-active', $toolbar);
};

const button_actions = [
  { icon: 'fa fa-align-left', name: 'left-action', state: ({ button_align }) => button_align },
  { icon: 'fa fa-align-center', name: 'center-action', state: ({ button_align }) => button_align },
  { icon: 'fas fa-plus', name: 'plus-action', state: () => true },
  { icon: 'fas fa-pen', name: 'edit-action', state: () => true },
  { icon: 'fa fa-times', name: 'close-action', state: () => true },
];

const delegateButtonAlignEvents = (toolbar_el) =>
  go(
    toolbar_el,
    $delegate('click', '.center-action', (e) => {
      const container_el = getContainerElFromChild(e.target);
      addAlignClass('align-center', container_el);
      hideToolbar(toolbar_el);
    }),
    $delegate('click', '.left-action', (e) => {
      const container_el = getContainerElFromChild(e.target);
      addAlignClass('align-left', container_el);
      hideToolbar(toolbar_el);
    }),
  );

/**
 *
 * @param editor
 * @param {Object}  options
 * @param {boolean} options.button_align 버튼 왼쪽정렬, 오른쪽 정렬 추가하는 옵션
 */
export const createButtonToolbar = (editor, options = { button_align: false }) => {
  const $editor = editor.elements[0];
  let selected_button_el;

  const custom_toolbar_el = createCustomToolbar({
    klass: 'custom_button_toolbar',
    actions: go(
      button_actions,
      filter((action) => action.state(options)),
      takeAll,
    ),
  });

  // close
  $on('click', closeToolbarHandler(custom_toolbar_el))(document);

  // open
  $delegate('click', '.editor-button', (e) => {
    if (!editor.isActive) return;
    selected_button_el = $closest('.editor-button', e.target);
    const container_el = getContainerElFromChild(e.target);
    const distance = calcDistance(container_el, selected_button_el);
    openToolbar(container_el, custom_toolbar_el, distance);
    e.originalEvent.preventDefault();
  })($editor);

  // action handlers
  go(
    custom_toolbar_el,
    $delegate('click', '.close-action', (e) => {
      hideToolbar(custom_toolbar_el);
      const container_el = getContainerElFromChild(selected_button_el);
      $remove(selected_button_el);
      const button_els = $findAll('.editor-button', container_el);
      if (!button_els.length) $remove(container_el);
    }),
    $delegate('click', '.edit-action', (e) => {
      hideToolbar(custom_toolbar_el);
      openButtonEditor(editor, {
        prev_container_el: getContainerElFromChild(selected_button_el),
        prev_btn_el: selected_button_el,
      });
    }),
    $delegate('click', '.plus-action', (e) => {
      const container_el = getContainerElFromChild(selected_button_el);
      const button_els = $findAll('.editor-button', container_el);

      if (button_els.length > 1) return $.alert('버튼은 한 줄에 최대 두개 입니다.');

      hideToolbar(custom_toolbar_el);
      openButtonEditor(editor, {
        prev_container_el: container_el,
      });
    }),
    options.button_align ? delegateButtonAlignEvents : noop,
  );
};
