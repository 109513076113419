/*
 * option_type
 * - fixed bp_option_groups
 * - select bp_option_groups
 * - select_image bp_option_groups
 * */

import { BpOptionConstantS } from '../../../../BpOption/S/Constant/module/BpOptionConstantS.js';

export const BP_OPTION_GROUPS_TYPE = {
  // [BpOptionConstantS.UI_TYPE.SELECT]: BpOptionConstantS.UI_TYPE.SELECT,
  SELECT: BpOptionConstantS.UI_TYPE.SELECT,
  IMAGE_SELECT: BpOptionConstantS.UI_TYPE.IMAGE_SELECT,
  FIXED: 'FIXED',
};
