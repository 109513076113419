import { html, View } from 'rune-ts';
import klass from './Breadcrumbs.module.scss';
import { getTypoRaw } from '../../../../shared/typography';
import { ArrowRightIcon } from '../../atoms/Icon';
import { classes } from '../../../../shared/util';

export interface BreadcrumbsProps {
  steps: {
    href: string;
    label: string;
  }[];
  color?: 'black' | 'white';
}

export class Breadcrumbs extends View<BreadcrumbsProps> {
  protected override template(data: BreadcrumbsProps) {
    const { steps, color = 'black' } = data;

    return html`
      <div class="${klass.breadcrumbs}">
        <div
          class="${klass.steps} ${getTypoRaw('unica_14_medium')} ${classes({
            [klass.black]: color === 'black',
            [klass.white]: color === 'white',
          })}"
        >
          ${steps.map((step, index) => {
            return html`
              ${index > 0 ? html`<div class="${klass.arrow}">${ArrowRightIcon}</div>` : ''}
              <a href="${step.href}" class="${klass.step}">${html.preventEscape(step.label)}</a>
            `;
          })}
        </div>
      </div>
    `;
  }
}
