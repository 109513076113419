import { html, go, strMap } from 'fxjs/es';
import $dataStr from 'fxdom/es/dataStr.js';
import { NessCustomMakerFrameShareF } from '../Function/module/NessCustomMakerFrameShareF.js';
import { NessCustomMakerFrameShareTmplS } from '../../S/Tmpl/module/NessCustomMakerFrameShareTmplS.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';

const mui_ref_path = '/modules/NessCustomMaker/Frame/Share/F/Mui/bp_device_selector_tab.js';
/*
 * 서버와 프론트에서 사용될 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#3bf02d1d08ed4eb9ac2b8656b513d154
 * */
export const bp_device_selector_tab = {
  tab_name: mui_ref_path,
  el_id: '', // tab element id 추가
  el_class: '', // tab element class 추가
  tag: '', // 커스텀 태그
  title: '기종', // tab button의 title
  selected: true, //탭이 여러개일때 먼저 선택되어있을 tab, tab이 하나면 true가 됨, tab이 여러개인데 모두 false면 맨 첫번째가 true
  attrs: {}, // tab 엘리먼트에 들어갈 어트리뷰트 지정 (id, class 등 자유롭게 추가가능)

  makeData(tab) {
    return box().devices;
    // return box().maker.phone_cases;
  }, // template 함수 인자에 들어갈 값, 반드시 MuiB.ssr 함수안에서 동적으로 정의되어야함.
  template(bp_devices) {
    return NessCustomMakerFrameShareTmplS.iscroll_wrapper_tmpl(
      go(
        bp_devices,
        strMap((bp) => bp_selector_item(bp)),
      ),
    );
  },
  showing(tab_el) {},
  // showing: NessCustomMakerFrameS.iscroll_showing,
  showed(tab) {
    NessCustomMakerFrameShareF.iscroll_showed(tab);
  },
  hiding(tab) {
    NessCustomMakerFrameShareF.iscroll_hiding(tab);
  },
  appended(tab) {
    return _pipe(
      $.on3(
        'click',
        '.selector_item.device_item:not(.sold_out)',
        NessCustomMakerFrameShareF.bpDeviceSelectEvent,
      ),
    )(tab);
  },
};

const bp_selector_item = (bp) => {
  return html`
    <li
      class="selector_item device_item i_item ${UtilTmplS.selectIf(
        bp.id === box().maker.product_color.base_product_id,
      )}"
      data-fx-json="${$dataStr(bp)}"
    >
      ${bp.name}
    </li>
  `;
};
