import { html } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { htmlIf2 } from '../../../../../../Util/S/Function/util.js';
import { MShopStudioDigitalProductCreatorConstantS } from '../../../../../Studio/DigitalProductCreator/S/Constant/module/MShopStudioDigitalProductCreatorConstantS.js';
import { isEbookByCateItemId } from '../../../../../Studio/DigitalProductCreator/S/Function/util.js';
import { DTG_LICENSE_DESCRIPTION, DTG_LICENSE_MESSAGE } from '../../constant.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';

export const makeAudioTagHtml = (src) => {
  return html`
    <audio class="preview__player" controls>
      <source src="${src}" type="audio/ogg" />
      <source src="${src}" type="audio/mpeg" />
      Your browser does not support the audio tag.
    </audio>
  `;
};
export const customAudioHtml = () => {
  return html`
    <div class="preview">
      <div class="preview__title">
        ${UtilS.isNessApp() ? 'Preview' : ET('product_detail::options::preview')}
      </div>
      <div class="preview__custom-player">
        <div class="preview__play-buttons">
          <button class="preview__play-button pause" type="button">
            <span class="play-icon"> ${MShopCoreTmplS.playIcon()} </span>
            <span class="pause-icon none"> ${MShopCoreTmplS.pauseIcon()} </span>
          </button>
        </div>
        <div class="preview__progress-container">
          <div class="preview__progress-background">
            <span class="preview__progress"></span>
          </div>
        </div>
        <div class="preview__time-container">
          <span class="preview__time current-time">0:00</span>
          <span class="preview__time"> / </span>
          <span class="preview__time duration">0:00</span>
        </div>
      </div>
    </div>
  `;
};
export const makeDtgInfoHtml = (product_detail) => {
  const {
    _: { stores_product },
    dtg_resources_info,
  } = product_detail;
  const {
    _: { digital_product },
  } = stores_product;
  const { sp_cate_item_id, is_purchased_dtg = false } = stores_product;
  const { license_type, is_hyper_link } = digital_product;
  const { file_type_msg } = dtg_resources_info;

  /* TODO 카테고리 하드코딩 */
  const is_good_note =
    sp_cate_item_id === MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE;

  return html`
    ${htmlIf2(isEbookByCateItemId(sp_cate_item_id), () => makeEbookInfoHtml(digital_product))}
    <div class="file-type info-wrap">
      <div class="title">${ET('product_detail::dtg::file_type')}</div>
      <div class="content">${file_type_msg}</div>
    </div>
    ${is_good_note
      ? html`
          <div class="is-hyper-link info-wrap">
            <div class="title">${ET('product_detail::dtg::hyper_link')}</div>
            <div class="content">${is_hyper_link ? T('있음') : T('없음')}</div>
          </div>
        `
      : ''}
    <div class="license info-wrap">
      <div class="title">${ET('product_detail::dtg::license')}</div>
      <div class="contents">
        <div class="content">${DTG_LICENSE_MESSAGE[license_type]}</div>
        <div class="content__description">${DTG_LICENSE_DESCRIPTION[license_type]}</div>
      </div>
    </div>
    ${is_purchased_dtg
      ? html`
          <div class="dtg-disabled">
            ${MShopCoreTmplS.infoIcon()}
            <div class="pd-submit__disabled-text">
              이미 구매한 상품으로 다운로드 가능 기간이 지나면 재구매가 가능해요.
            </div>
          </div>
        `
      : ''}
  `;
};

const makeEbookInfoHtml = (digital_product) => {
  const { illustrator, translator, publisher, publication_date } = digital_product._.ebook_info;

  const i18n = {
    illustrator: ET('mps2::product_detail::ebook_illustrator'),
    translator: ET('mps2::product_detail::ebook_translator'),
    publisher: ET('mps2::product_detail::ebook_publisher'),
    publication_date: ET('mps2::product_detail::ebook_publication_date'),
  };

  return html`
    ${htmlIf2(
      illustrator,
      () => html`
        <div class="illustrator info-wrap">
          <div class="title">${i18n.illustrator}</div>
          <div class="content">${illustrator}</div>
        </div>
      `,
    )}
    ${htmlIf2(
      translator,
      () => html`
        <div class="translator info-wrap">
          <div class="title">${i18n.translator}</div>
          <div class="content">${translator}</div>
        </div>
      `,
    )}
    ${htmlIf2(
      publisher,
      () => html`
        <div class="publisher info-wrap">
          <div class="title">${i18n.publisher}</div>
          <div class="content">${publisher}</div>
        </div>
      `,
    )}
    ${htmlIf2(
      publication_date,
      () => html`
        <div class="publication_date info-wrap">
          <div class="title">${i18n.publication_date}</div>
          <div class="content">${publication_date}</div>
        </div>
      `,
    )}
  `;
};

export const makeDtgInfoHtmlPcNess = (product_detail) => {
  const {
    _: { stores_product },
    dtg_resources_info,
  } = product_detail;
  const {
    _: { digital_product },
  } = stores_product;
  const { sp_cate_item_id } = stores_product;
  const { license_type, is_hyper_link } = digital_product;
  const { preview_signed_url, file_type_msg } = dtg_resources_info;
  /* TODO 카테고리 하드코딩 */
  const is_good_note =
    sp_cate_item_id === MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE;

  return html`
    ${htmlIf2(isEbookByCateItemId(sp_cate_item_id), () => makeEbookInfoHtml(digital_product))}
    <div class="file-type">
      <div class="title">${ET('product_detail::options::type')}</div>
      <div class="content">${file_type_msg}</div>
    </div>
    ${is_good_note
      ? html`
          <div class="is-hyper-link">
            <div class="title">${ET('product_detail::options::hyper_link')}</div>
            <div class="content">${is_hyper_link ? T('있음') : T('없음')}</div>
          </div>
        `
      : ''}
    <div class="license">
      <div class="title">${ET('product_detail::options::license')}</div>
      <div class="contents">
        <div class="content">${DTG_LICENSE_MESSAGE[license_type]}</div>
        <div class="content__description">${DTG_LICENSE_DESCRIPTION[license_type]}</div>
      </div>
    </div>
    ${preview_signed_url ? customAudioHtml() : ''}
  `;
};

export const makeDtgInfoHtmlMoNess = (product_detail) => {
  const {
    _: { stores_product },
    dtg_resources_info,
  } = product_detail;
  const {
    _: { digital_product },
  } = stores_product;
  const { sp_cate_item_id, is_purchased_dtg = false } = stores_product;
  const { license_type, is_hyper_link } = digital_product;
  const { file_type_msg } = dtg_resources_info;

  /* TODO 카테고리 하드코딩 */
  const is_good_note =
    sp_cate_item_id === MShopStudioDigitalProductCreatorConstantS.DIGITAL_PRODUCT_CATE_ITEM_ID.GOOD_NOTE;

  return html`
    ${htmlIf2(isEbookByCateItemId(sp_cate_item_id), () => makeEbookInfoHtml(digital_product))}
    <div class="file-type">
      <div class="title">${ET('product_detail::options::type')}</div>
      <div class="content">${file_type_msg}</div>
    </div>
    ${is_good_note
      ? html`
          <div class="is-hyper-link">
            <div class="title">${ET('product_detail::options::hyper_link')}</div>
            <div class="content">${is_hyper_link ? T('있음') : T('없음')}</div>
          </div>
        `
      : ''}
    <div class="license">
      <div class="title">${ET('product_detail::options::license')}</div>
      <div class="contents">
        ${DTG_LICENSE_MESSAGE[license_type]}
        <div class="content__description">${DTG_LICENSE_DESCRIPTION[license_type]}</div>
      </div>
    </div>
    ${is_purchased_dtg
      ? html`
          <div class="dtg-disabled">
            <img
              src="//s3.marpple.co/files/u_2308668/2023/7/original/0a51f2fdd47d99617b2888003f1bcdf7e87385cc1.webp"
              alt="caution icon image"
            />
            <div class="pd-submit__disabled-text">
              이미 구매한 상품으로 다운로드 가능 기간이 지나면 재구매가 가능해요.
            </div>
          </div>
        `
      : ''}
  `;
};
