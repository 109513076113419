import dataStr from 'fxdom/es/dataStr.js';
import { html, strMap, head, rest, go, map, extend, take, zipWithIndexL } from 'fxjs/es';
import { MShopUtilConstantS } from '../../../../Util/S/Constant/module/MShopUtilConstantS.js';
import { MShopAppProductItemTmplS } from '../../../Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { MShopAppLiveTmplS } from './module/MShopAppLiveTmplS.js';
import { getMonth, getWeekOfMonth, format, endOfWeek } from 'date-fns';

const makePeriodTitle = (date, text) => {
  return html`
    <h2>
      <a class="app-live__period" data-date="${format(date, 'yyyy.MM.dd')}">
        <span>
          ${T(`mshop::몇월 몇째주`, {
            month: getMonth(date) + 1,
            weeks: T(`mshop::week${getWeekOfMonth(date)}`),
          })}
        </span>
      </a>
      ${text}
    </h2>
  `;
};

export const make_top_product_html = (top_products) => {
  if (!top_products.products.length) return '';

  const tp_date = new Date(top_products.start_at);
  const end_of_week = endOfWeek(tp_date, { weekStartsOn: 1 });
  const today = new Date();
  const base_date = end_of_week > today ? today : end_of_week;

  return html`
    <div class="app-live__title">
      <div class="app-live__title--left">
        ${makePeriodTitle(tp_date, T('mshop::live::제일 많이 팔린 상품을 보여줘.'))}
      </div>
      <div class="app-live__title--right">
        ${T('mshop::언제 기준', { date: format(base_date, 'yyyy.MM.dd') })}
      </div>
    </div>

    <div class="app-live__tp">
      <div class="app-live__tp-head">
        ${go(
          head(top_products.products),
          (item) => extend({ rank: 1 }, item),
          MShopAppProductItemTmplS.item(['v1', 'popular']),
        )}
      </div>
      <div class="app-live__tp-rest">
        ${go(
          rest(top_products.products),
          zipWithIndexL,
          map(([index, item]) => extend({ rank: index + 2 }, item)),
          strMap(MShopAppProductItemTmplS.item(['v1', 'popular'])),
        )}
      </div>
    </div>
  `;
};

export const make_top_sellers_html = (top_sellers, length = 10) => {
  const this_week = top_sellers.weekly[1] ? top_sellers.weekly[1] : top_sellers.weekly[0];
  const ts_date = new Date(this_week.start_at);
  const end_of_week = endOfWeek(ts_date, { weekStartsOn: 1 });
  const today = new Date();
  const base_date = end_of_week > today ? today : end_of_week;

  return html`
    <div class="app-live__title">
      <div class="app-live__title--left">
        ${makePeriodTitle(ts_date, T('mshop::live::제일 인기있는 셀러를 보여줘.'))}
      </div>
      <div class="app-live__title--right">
        ${T('mshop::언제 기준', { date: format(base_date, 'yyyy.MM.dd') })}
      </div>
    </div>

    <div class="app-live__ts" data-fx-json="${dataStr(top_sellers)}">
      ${go(
        this_week.rank,
        take(length),
        zipWithIndexL,
        strMap(([index, seller_id]) => {
          const seller = top_sellers.sellers[seller_id];
          const current_rank = index + 1;
          const last_week = top_sellers.weekly[0] || { rank: [] };
          const last_rank = last_week.rank.findIndex((id) => id == seller_id) + 1;
          // const open_div = index === 0 || !((index - 1) % 3);
          // const close_div = index === 0 || !(index % 3);
          const open_div = current_rank === 1 || current_rank === 2 || current_rank === 11;
          const close_div =
            current_rank === 1 || current_rank === 10 || current_rank === this_week.rank.length;

          return `
            ${open_div ? `<div class="index-${index}">` : ''}
            ${MShopAppLiveTmplS.popular_seller({ seller, current_rank, last_rank })}
            ${close_div ? '</div>' : ''}
          `;
        }),
      )}
    </div>

    <div class="app-live__more">
      <button type="button" class="app-live__more-btn">${T('mshop::live::50위까지 펼쳐보기')}</button>
    </div>
  `;
};

const make_top_review_sellers_html = (top_review_sellers) => html`
  <div class="app-live__title">
    <div class="app-live__title--left">
      <p>${T('mshop::live::월간 랭킹')}</p>
      <h2>${T('mshop::live::내가 이 구역 리뷰왕!')}</h2>
    </div>
  </div>

  <div class="app-live__trs">
    ${go(
      top_review_sellers,
      zipWithIndexL,
      strMap(([index, store]) => {
        const rank = index + 1;
        return html`${MShopAppLiveTmplS.review_king({ rank, store })}`;
      }),
    )}
  </div>
`;

export const make_review_main_html = (top_review_sellers, is_mobile) => html`
  ${go(
    top_review_sellers,
    zipWithIndexL,
    strMap(([index, store]) => {
      const rank = index + 1;
      return html`
        <div class="community-review-king${is_mobile ? ' swiper-slide' : ''}">
          <div class="community-review-king__item">
            ${MShopAppLiveTmplS.communityReviewKing({ rank, store })}
          </div>
        </div>
      `;
    }),
  )}
`;

export const make_swiper_more_btn = () => {
  return html`
    <div class="swiper-slide swiper__more">
      <button type="button" class="swiper__more-btn">
        <span class="swiper__icon">${MShopUtilConstantS.svg.chevron_left}</span>
        ${T('mshop::live::30개까지 보기')}
      </button>
    </div>
  `;
};

export const make_recent_sold_products_html = (recent_sold_products, is_mobile, length = 6) => {
  const _products = recent_sold_products.slice(0, length);

  return html`
    <div class="app-live__title">
      <div class="app-live__title--left">
        <h2>${T('mshop::live::지금 막! 팔렸어요.')}</h2>
      </div>
    </div>
    ${is_mobile
      ? `
      <div class="app-live__rsp swiper-container" data-fx-json="${dataStr(recent_sold_products)}">
        <div class="swiper-wrapper swiper__wrapper">
          ${go(_products, strMap(MShopAppProductItemTmplS.item(['v2'], 'swiper-slide')))}
          ${make_swiper_more_btn()}
        </div>
      </div>
    `
      : `
      <div class="app-live__rsp" data-fx-json="${dataStr(recent_sold_products)}">
        ${go(_products, strMap(MShopAppProductItemTmplS.item(['v2'], 'swiper-slide')))}
      </div>
      
      <div class="app-live__more">
        <button type="button" class="app-live__more-btn">${T('mshop::live::30개까지 보기')}</button>
      </div>
    `}
  `;
};

export const tmpl = ({ data, is_mobile }) => {
  const [live, top_products, top_sellers, top_review_sellers, recent_sold_products] = data;

  return html`
    <div class="app-live">
      <div class="app-live__section top_sellers">${make_top_sellers_html(top_sellers)}</div>

      <div class="app-live__section resent_sold">
        ${make_recent_sold_products_html(recent_sold_products, is_mobile)}
      </div>

      <div class="app-live__section top_products">${make_top_product_html(top_products)}</div>

      <div class="app-live__section">${make_top_review_sellers_html(top_review_sellers)}</div>

      <div class="app-live__videos" data-section-hide="${!live.length}">
        ${MShopAppLiveTmplS.live(T('mshop::live::지금 총알 쏘러 갑니다.'), live, '', true)}
      </div>
    </div>
  `;
};
