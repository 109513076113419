import { html, strMap } from 'fxjs/es';
import { UtilImageS } from '../../../../../../../Util/Image/S/Function/module/UtilImageS.js';

export const editor = ({ article, store_id, type }) => {
  const txt1 = ET('mps2::cheerup::txt1');
  const txt2 = ET('mps2::cheerup::txt2');
  const txt3 = ET('mps2::cheerup::txt3');

  const btn_txt1 = ET('mps2::cheerup::btn_txt1');
  const btn_txt2 = ET('mps2::cheerup::btn_txt2');

  return html`
    <div
      class="article-editor"
      data-article_type="${type || ''}"
      data-store_id="${store_id}"
      data-article_id="${article?.id || ''}"
    >
      <div class="article-editor__body">
        <textarea class="article-editor__textarea" name="body" cols="30" rows="10" placeholder="${txt1}">
${article?.body || ''}</textarea
        >
        <p class="article-editor__body-p">${txt2}</p>
      </div>
      <div class="article-editor__upload">
        <h3 class="article-editor__upload_title">
          ${T('community::이미지 첨부')} <span class="article-editor__require">*</span>
          <span class="article-editor__upload_txt1">${txt3}</span>
        </h3>
        <ul class="article-editor__upload_list">
          ${strMap(uploadItem, article?.photos)}
          <li class="article-editor__btn_add">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_8944_267188)">
                <path
                  d="M14.0802 7.41203H8.58016V1.9187C8.58016 1.5987 8.32016 1.33203 7.9935 1.33203C7.66683 1.33203 7.40683 1.59203 7.40683 1.9187V7.4187H1.92016C1.60016 7.4187 1.3335 7.6787 1.3335 8.00536C1.3335 8.33203 1.5935 8.59203 1.92016 8.59203H7.42016V14.092C7.42016 14.412 7.68016 14.6787 8.00683 14.6787C8.3335 14.6787 8.5935 14.4187 8.5935 14.092V8.59203H14.0935C14.4135 8.59203 14.6802 8.33203 14.6802 8.00536C14.6802 7.6787 14.4202 7.4187 14.0935 7.4187L14.0802 7.41203Z"
                  fill="#D9D9D9"
                />
              </g>
              <defs>
                <clipPath id="clip0_8944_267188">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </li>
        </ul>
        <input type="file" class="article-editor__file_input" multiple accept=".jpg,.png,.jpeg,.gif" />
      </div>
      <div class="article-editor__btn_area">
        <button type="button" class="article-editor__submit article-editor__submit--on">
          ${article ? T('community::수정') : btn_txt1}
        </button>
      </div>
    </div>
  `;
};

export const uploadItem = (file) => {
  return html`
    <li
      class="article-editor__upload_item"
      data-file_id="${file.id}"
      data-url="${file.url}"
      data-width="${file.width}"
      data-height="${file.height}"
    >
      <img
        src="${UtilImageS.getResizedUrl({
          url: file.url || '',
          quality: 70,
          width: 100,
          format: 'webp',
          is_ie,
        })}"
        alt=""
      />
      <span class="article-editor__upload_item_del"
        ><img
          src="//s3.marpple.co/files/u_218933/2021/5/original/253214e745bcc6c132e7ddc1464eaab9395029b31.svg"
          alt="X"
      /></span>
    </li>
  `;
};
