import { flat, go, html, strMap } from 'fxjs/es';
import { NessCoreConstantS } from '../Constant/module/NessCoreConstantS.js';
import {
  getCloseIcon,
  getDropdownIcon,
  getLeftArrowIcon,
  getNessIcon,
  getNessSimpleIcon,
  getNextIcon,
  getPlusColorCircleLogo,
} from './icon.js';
import { NessCoreTmplS } from './module/NessCoreTmplS.js';
import { makeMenuHref } from '../../../Util/S/Function/util.js';
import { customProductHeaderTmpl } from './custom.js';

/**
 *
 * @param {number} [active_menu_idx]
 * @return {string}
 */
export const getDefaultHeaderCenter = (active_menu_idx) => {
  const component = NessCoreConstantS.layout_components.header;

  return html`
    <div class="${component}__main-menu-list">
      <a
        href="${G.NESS_MAIN_LINK}/@/editorials"
        class="${component}__button"
        data-active="${active_menu_idx === 0}"
        >Editorial</a
      >
      <a
        href="${G.NESS_MAIN_LINK}/@/collection"
        class="${component}__button"
        data-active="${active_menu_idx === 1}"
        >Collection</a
      >
      <a
        href="${G.NESS_MAIN_LINK}/@/stores"
        class="${component}__button"
        data-active="${active_menu_idx === 2}"
        >Store</a
      >
    </div>
  `;
};

/**
 * @dev default-header center 에 들어갈 수 있는 것, 하나의 메뉴로 들어간 경우에 사용하는 컴포넌트
 * @param {string} from
 * @param {string} middle
 * @param {string} to
 * @param {string} title
 * @param {'white' | 'black'} color
 * @param {string} prev_path
 * @return {string}
 */
export const getDefaultHeaderCenter_selected = ({ from, middle, to, title, color, prev_path }) => {
  const component = NessCoreConstantS.layout_components.header;

  return html`
    <div class="${component}__selected">
      <button class="${component}__back-btn" data-path="${prev_path}">
        <span class="${component}__back-icon">${NessCoreTmplS.getBackIcon()}</span>
      </button>
      <a
        class="${component}__button ${component}__selected-button"
        data-active="true"
        href="${G.NESS_MAIN_LINK}/@/${prev_path}"
      >
        <span data-hide="true">${from}</span>
        <span class="${component}__selected-arrow-icon" data-hide="true">${getNextIcon({ color })}</span>
        ${!middle
          ? ''
          : html`<span data-hide="true">${middle}</span>
              <span class="${component}__selected-arrow-icon" data-hide="true">
                ${getNextIcon({ color })}
              </span>`}
        <span>${to}</span>
      </a>
      <span class="${component}__selected-title">${title}</span>
    </div>
  `;
};

/**
 *
 * @param {boolean} is_simple
 * @param {'white' | 'black'} color
 * @param {boolean} height_base height 에 맞춰서 사이즈 조정할지 여부, 보통은 width 기준으로
 * @return {string}
 */
export const getDefaultHeaderLogo = (is_simple, color, height_dependent = true) => {
  const component = NessCoreConstantS.layout_components.header;

  return html`
    <a class="${component}__logo-wrapper" href="${G.NESS_MAIN_LINK}">
      ${is_simple ? getNessSimpleIcon({ color, height_dependent }) : getNessIcon({ color, height_dependent })}
    </a>
  `;
};

/**
 *
 * @param {number} [cart_count]
 * @param {boolean} is_mobile
 * @param {boolean} is_logged_in
 * @return {string}
 */
export const getDefaultHeaderRight = (cart_count, is_mobile, is_logged_in) => {
  const component = NessCoreConstantS.layout_components.header;
  const ness_cart_count = cart_count > 99 ? '99+' : cart_count;
  return html`
    <nav class="${component}__right-nav">
      ${is_mobile
        ? html`<a class="${component}__right-link" href="${G.NESS_MAIN_LINK}/@/cart">
              Cart &nbsp<span class="${component}__cart-count">${ness_cart_count || ''}</span>
            </a>
            <button class="${component}__right-menu">Menu</button>`
        : html` ${is_logged_in
              ? `<a class="${component}__right-link" href="${G.NESS_MAIN_LINK}/@/mypage">My page</a>`
              : `<a class="${component}__right-link ${component}__login" href="${G.NESS_MAIN_LINK}/@/login">Login</a>`}
            <a class="${component}__right-link" href="${G.NESS_MAIN_LINK}/@/cart">
              Cart &nbsp<span class="${component}__cart-count">${ness_cart_count || ''}</span>
            </a>`}
    </nav>
  `;
};

/**
 *
 * @param {string} from
 * @param {string} to
 * @return {string}
 */
export const getDefaultHeaderBottom_storeTitle = ({ from, to }) => {
  const component = NessCoreConstantS.layout_components.bottom_header_store_title;

  return html`
    <div class="${component}">
      <span class="${component}__title">${from} Store</span>
      <span class="${component}__icon">${getNextIcon()}</span>
      <span class="${component}__title">${to} Store</span>
    </div>
  `;
};

/**
 * @typedef menu
 * @property {string} name
 * @property {string} id
 */

/**
 * @param {menu[]} special_menus 카테고리 이외에 메뉴
 * @param {menu[]} cate_lists
 * @oaram {preview_menu} [selected_preview_menu]
 * @param {string} title 플러스 이름, 좀 더 포괄적인 영역으로 두기 위해 title 이라고 함
 * @param {string | number} [selected_id]
 * @param {boolean} [use_anchor=true] menu를 anchor로 사용할지 여부
 * @param {boolean} [is_mobile]
 * @param {object} [custom]}
 * @return {string}
 */
export const getDefaultHeaderBottom_category = ({
  special_menus,
  cate_lists,
  title,
  selected_id = '',
  use_anchor = true,
  is_mobile,
  custom,
} = {}) => {
  const component = NessCoreConstantS.layout_components.bottom_header_category;
  const open_input_el_id = 'default-header-bottom-category-open-input';
  const { is_custom_product_list_type: is_hide_custom_header, custom_level, custom_data } = custom || {};

  const makeMenuHtml = (menu) => {
    const href =
      menu?.href ??
      `${makeMenuHref(menu.type, T.lang)}${
        menu.type === 'all' && menu.value ? `?cate_list_id=${menu.value}` : ''
      }`;

    return html`
    <${use_anchor ? html`a` : html`span`}
      href="${href}"
      class="${component}__menu"
      data-active="${menu.value == selected_id}"
      data-cate_list_id="${menu.value}"
    >
      ${menu.name}
    </${use_anchor ? html`a` : html`span`}>
  `;
  };

  return html`<div class="${component}">
    <input id="${open_input_el_id}" class="${component}__open-input" type="checkbox" autocomplete="off" />
    <div class="${component}__core">
      <div class="${component}__info-wrapper">
        <div class="${component}__title">
          <a class="${component}__title-text" href="/${T.lang}">${title || ''}</a>
        </div>
        <div class="${component}__menus" data-is_hidden="${!use_anchor && is_mobile ? 'true' : 'false'}">
          ${go([special_menus, cate_lists], flat, strMap(makeMenuHtml))}
        </div>
      </div>
      <label class="${component}__menu-more" for="${open_input_el_id}" data-is_hidden="true">
        <span class="${component}__menu-more-text ${component}__menu-more-text--folded"
          >${ET('ness::common::see_more')}</span
        >
        <span class="${component}__menu-more-text ${component}__menu-more-text--expanded"
          >${ET('ness::common::close')}</span
        >
        <span class="${component}__menu-more-icon"> ${getDropdownIcon()} </span>
      </label>
    </div>
    <div class="${component}__custom-header ${is_hide_custom_header ? '' : 'hidden'}">
      ${is_mobile && custom ? customProductHeaderTmpl(custom_data, custom_level, is_mobile) : ''}
    </div>
  </div>`;
};

/**
 *
 * @param {boolean} is_logged_in
 * @return {string}
 */
export const makeMobileGlobalMenuPopup = (is_logged_in) => {
  const component = 'header-global-menu-popup';

  return html`
    <div class="${component}">
      <div class="${component}__head">
        <a class="${component}__head-logo" href="${G.NESS_MAIN_LINK}">
          ${getNessSimpleIcon({ color: 'white', height_dependent: true })}
        </a>
        <span class="${component}__head-close-icon">${getCloseIcon({ color: 'white' })}</span>
      </div>
      <div class="${component}__main-page-list">
        <a class="${component}__main-page" href="${G.NESS_MAIN_LINK}/@/editorials">Editorial</a>
        <a class="${component}__main-page" href="${G.NESS_MAIN_LINK}/@/collection">Collection</a>
        <a class="${component}__main-page" href="${G.NESS_MAIN_LINK}/@/stores"> Store </a>
      </div>
      <div class="${component}__sub-page-list">
        ${is_logged_in
          ? `<a href="${G.NESS_MAIN_LINK}/@/mypage" class="${component}__sub-page">My page</a>`
          : `<a href="${G.NESS_MAIN_LINK}/@/login" class="${component}__sub-page ${component}__login">Login</a>`}
        <a href="${G.NESS_MAIN_LINK}/@/cart" class="${component}__sub-page">Cart</a>
      </div>
    </div>
  `;
};

export const getDefaultHeaderLeft_withArrow = ({ title, color, logo }) => {
  const component = NessCoreConstantS.layout_components.header_left_arrow;
  return html`
    <div class="${component}">
      <div class="${component}__icon mshop-history-back">${getLeftArrowIcon({ color })}</div>
      ${title ? `<div class="${component}__title">${title}</div>` : ''}
      <div class="${component}__logo">${logo}</div>
    </div>
  `;
};

export const getCustomMakerHeaderLeft_withArrowMo = ({ is_store_product_asset, title, color, logo }) => {
  const component = NessCoreConstantS.layout_components.header_left_arrow;

  return html` <div class="${component}">
    <div class="${component}__icon ${is_store_product_asset ? 'custom-history-back' : 'mshop-history-back'}">
      ${getLeftArrowIcon({ color })}
    </div>
    ${title ? `<div class="${component}__title">${title}</div>` : ''}
    <div class="${component}__logo">${logo}</div>
  </div>`;
};

export const getCustomMakerHeaderLeft_withArrow = ({ title, logo }) => {
  const component = NessCoreConstantS.layout_components.header_left_custom_maker_arrow;
  return html`
    <div class="${component}">
      <a
        class="${component}__icon custom-history-back"
        href="/${T.lang}/@/product/list/custom?custom_level=asset"
      >
        <img
          src="//s3.marpple.co/files/u_193535/2024/11/original/a9d1de36d984b1676d3013e6efc7a2b9fc7914681.png"
          alt=""
        />
      </a>
      ${title ? `<div class="${component}__title">${title}</div>` : ''}
      <div class="${component}__logo">${logo}</div>
    </div>
  `;
};

/**
 * @dev header 에서 쓸 용도로 plus logo 랩핑 (size, padding 스타일링)
 * @param {string}  bg_color
 * @param {string} icon
 * @param {string} [use_icon_self] 기존 플러스 원형 로고 이외에 예외적인 케이스
 * @return {string}
 */
export const makeLeftHeaderPlusLogo = ({ bg_color, icon, use_icon_self }) => {
  const component = `ness-default-header-plus-main-logo`;
  return html`
    <a class="${component}" href="/">
      ${use_icon_self
        ? html`<img class="${component}__icon-self" src="${icon}" alt="logo" />`
        : getPlusColorCircleLogo({
            bg_color,
            icon,
          })}
    </a>
  `;
};

export const makeLeftHeaderCustomMakerLogoPc = () => {
  const component = `ness-custom-maker-header-custom-maker-logo-pc`;
  return html`
    <a class="${component}" href="/@/product/list/custom">
      <span>My MapleStory</span><span>Custom Item</span>
    </a>
  `;
};
export const makeLeftHeaderCustomMakerLogoMobile = () => {
  const component = `ness-custom-maker-header-custom-maker-logo-mo`;
  return html`
    <a class="${component}" href="/@/product/list/custom">
      <span>Custom Item</span>
    </a>
  `;
};
