import { flatten, go, map } from 'fxjs/es';

export const getFlattenCvDesigns = (cv_objs) => {
  return go(
    cv_objs,
    map((cv_obj) =>
      cv_obj.objects?.length || cv_obj._objects?.length
        ? getFlattenCvDesigns(cv_obj.objects || cv_obj._objects)
        : cv_obj,
    ),
    flatten,
  );
};
