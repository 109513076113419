import { $closest, $delegate, $find, $on, $qs, $remove, $toggleClass } from 'fxdom/es';
import { go } from 'fxjs/es';
import { openMakerTipModal } from './openMakerTipModal.js';
import { initNessMpMaker } from './private/initNessMpMaker.js';
import '../../../Maker/F/CvPattern/frame.js';
import { vectorEditorTabAppendingPc } from '../../Core/F/Function/core.js';
import { NessCustomMakerMarpplizerF } from '../../Marpplizer/F/Function/module/NessCustomMakerMarpplizerF.js';
import { handleOpenFrameAsset, handleProductLinkShare } from './handler.js';
import { NewMakerCheckConditionF } from '../../../NewMaker/CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { NewMakerConditionPopupF } from '../../../NewMaker/ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';
import { mpMakerSelectFaceActiveTippy } from '../../../Maker/F/select_faces.js';
// CvPattern Frame 사용을 위해 필요

export const startNessCustomForPc = async (el) => {
  G.mp = G.mp || {};
  G.mp.maker = G.mp.maker || {};
  $.add_class([$1('html'), $1('body')], 'mp-maker-page ness-custom-maker');
  G.mp.maker.window_height = $.height(window);
  G.mp.maker.window_width = $.width(window);
  G.mp.maker.canvas_container_height = G.mp.maker.window_height;
  $.attr($1('html'), {
    body_height: G.mp.maker.window_height - (G.mp.maker.window_height % 100),
  });
  G.mp.maker.CANVAS_WIDTH_ORIGIN = 860;
  G.mp.maker.NSCREEN_ZOOM = 1;
  G.mp.maker.CANVAS_WIDTH = G.mp.maker.CANVAS_HEIGHT = G.mp.maker.CANVAS_WIDTH_ORIGIN;
  G.mp.maker.vectorEditorTabAppendingPc = vectorEditorTabAppendingPc;

  await initNessMpMaker(el, false);
  /*event*/
  const mp_maker_el = $qs('.mp_maker');
  if (mp_maker_el.dataset.is_vector != 'true') {
    /* NESS 커스텀하는 방법 모달  */
    openMakerTipModal();
  }
  NessCustomMakerMarpplizerF.generalOptionEvents($find('#marpplizer', mp_maker_el));
  mpMakerSelectFaceActiveTippy();
  go(
    mp_maker_el,
    $delegate('click', '.decoration_menu_for_pc .ness-custom-maker__asset-library', handleOpenFrameAsset),
    $delegate('click', '.ness-custom-maker__share', handleProductLinkShare),
    $delegate('click', '.condition_tooltip_text .toggle_img', (e) => {
      const type = NewMakerCheckConditionF.is_full_fill_or_out_bounds();
      if (!type) return;
      const condition_tooltip_el = $closest('.condition_tooltip', e.currentTarget);
      $toggleClass('is_closed', condition_tooltip_el);
    }),
    $delegate('click', '.condition_tooltip_text .show_detail', (e) => {
      const type = NewMakerCheckConditionF.is_full_fill_or_out_bounds();
      if (!type) return;
      return NewMakerConditionPopupF.open(type);
    }),
  );
  $on('resize', () => {
    const wrapper = $qs('.image_selector_wrap');
    if (wrapper) $remove(wrapper);
  })(window);
};
