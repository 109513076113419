import format from 'date-fns/format/index.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, range, sel, strMap, takeL, zipWithIndexL } from 'fxjs/es';
import { staticFont } from '../../../../../../services/MarppleShop/shared/typography/index.ts';
import { staticTypo, typo } from '../../../../../../services/MarppleShop/shared/typography/typo.js';
import { formatTimeElapsed } from '../../../../../../services/MarppleShop/shared/util/date.ts';
import { isAllCrew } from '../../../../../Creator/Crew/S/util.js';
import { MpShopProductTmplS } from '../../../../../MpShop/Product/S/Tmpl/module/MpShopProductTmplS.js';
import { creatorPriceOfProduct } from '../../../../../ProductColorPrice/S/fs.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopCoreTmplS } from '../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopAppLiveTmplS } from '../../../Live/S/Tmpl/module/MShopAppLiveTmplS.js';
import { ARTICLE_TEXT_TYPES } from '../../../Store/Community/S/Constant/community.js';
import { MShopAppUpItemS } from '../../../UpItem/S/Function/module/MShopAppUpItemS.js';
import {
  getCreatorShopLinkKlass,
  getCreatorShopUrl,
  getUserProfileUrl,
  makeUserNameChildrenHtml,
} from '../Function/community.js';
import { MShopAppCommunityS } from '../Function/module/MShopAppCommunityS.js';

export const mallList = ({ king_of_reviews, plus_crew, is_mobile }) => {
  const is_ness = UtilS.isNessApp();
  const texts = {
    review: ET('community::review'),
  };
  return html`
    <div class="mall-communities" data-is_ness="${is_ness}">
      ${isAllCrew(plus_crew) && king_of_reviews?.length
        ? html`
            <div class="mall-communities__title">
              <p>${T('mshop::live::월간 랭킹')}</p>
              <h2>${T('mshop::live::내가 이 구역 리뷰왕!')}</h2>
            </div>
            <div class="mall-communities__king_of_reviews${is_mobile ? ' swiper-container' : ''}">
              <div class="swiper-wrapper">
                ${MShopAppLiveTmplS.make_review_main_html(king_of_reviews, is_mobile)}
              </div>
            </div>
          `
        : ''}
      ${is_mobile || plus_crew?.id !== 1
        ? ''
        : html`<h2 class="community-recent-review-title">${T('community::최신글')}</h2>`}
      ${is_ness
        ? html`
            <div class="ness-review-title">
              <h2 class="ness-review-title__review">Review</h2>
              <span class="ness-review-title__sub" lang="${ET.lang}">${texts.review}</span>
            </div>
          `
        : ''}
      <div class="community-recent-list community-list"></div>
    </div>
  `;
};

export const item = ({ article, store, is_store, is_swiper, is_mp_worker, is_my, is_mobile }) => {
  if (!article || !store) return '';
  else if (article.type == 'review_photo')
    return reviewItem({
      article,
      store,
      is_store,
      is_swiper,
      is_mp_worker,
      is_my,
      css_type: is_mobile ? 'small' : '',
      is_mobile,
    });
  return defaultItem({ article, store, is_store, is_swiper });
};

export const defaultItem = ({ article, store, is_swiper }) => {
  const img_alt = go(void 0, () => {
    if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
      return `마플샵 굿즈, 굿즈 추천, ${store.name} 커뮤니티`;
    } else {
      return store.name;
    }
  });
  return html`
    <div
      class="community-item${is_swiper ? ' swiper-slide' : ''}"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      article_id="${article.id}"
    >
      ${sel('photos.length', article)
        ? html`
            <div class="community-item__photo">
              <div class="community-item__photo_img">
                <img
                  src="${UtilImageS.getResizedUrl({
                    url: sel('photos.0.url', article),
                    width: 350,
                    format: 'webp',
                  })}"
                  alt="${img_alt}"
                />
              </div>
              <div
                class="community-item__more"
                is_more="${sel('photos.length', article) > 1 ? 'true' : 'false'}"
              ></div>
              <div class="community-item__user">${sel('_.user.email', article) || 'NONAME'}</div>
            </div>
          `
        : ''}
      <div class="community-item__body">
        ${ARTICLE_TEXT_TYPES.includes(article.type)
          ? `
            <div class="community-item__body-text">${UtilS.linkify(article.body)}</div>
            <div class="community-item__body-user">
              ${UtilS.escape(sel('_.user.email', article)) || 'NONAME'}
            </div>
          `
          : `
            <div class="community-item__body-text">${UtilS.escape(sel('body', article))}</div>
          `}
      </div>
      <div class="community-item__controls">${articleListControls(article)}</div>
    </div>
  `;
};

export const pdReviewItem = ({ article, store }) => {
  const review = sel('_.review', article);
  const user_profile_url = getUserProfileUrl(article);
  const creator_shop_url = getCreatorShopUrl(article);
  const user_product = sel('_.user_product', review);
  const option_name = MShopAppUpItemS.makeUpOptionName(user_product);
  const is_sns = !!review.sns_url;

  return html`
    <div
      class="pd-review-item"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      article_id="${article.id}"
    >
      <div class="pd-review-item__wrapper">
        <div class="pd-review-item__info">
          <div class="pd-review-item__user">
            <div class="pd-review-item__profile ${getCreatorShopLinkKlass(creator_shop_url)}">
              ${MShopCoreTmplS.userProfile(user_profile_url, creator_shop_url)}
            </div>
            <div>
              <p class="pd-review-item__name ${getCreatorShopLinkKlass(creator_shop_url)}">
                ${makeUserNameChildrenHtml(article, typo('14_bold'))}
              </p>
              ${UtilS.isNessApp()
                ? ''
                : `<div class="pd-review-item__star">${reviewScore(review.score)}</div>`}
            </div>
          </div>
          <div class="pd-review-item__date">${formatTimeElapsed(article.created_at)}</div>
        </div>
        <div class="pd-review-item__content">
          <div class="pd-review-item__body">
            ${option_name
              ? html`
                  <p class="pd-review-item__option">
                    ${ET('product_detail::review::option', { option_name })}
                  </p>
                `
              : ''}
            ${reviewBodyStr(review)}
            ${is_sns
              ? `<div class='pd-review-item__sns'>${MShopCoreTmplS.reviewSNSIcon()} ${ET(
                  'product_detail::review::sns_review',
                )}</div>`
              : ''}
          </div>
          <div class="pd-review-item__images">
            <div class="pd-review-item__img">
              ${review.files.length >= 2 ? `<div class="dim">+${review.files.length - 1}</div>` : ''}
              <img
                src="${UtilImageS.getResizedUrl({
                  url: sel('files.0.url', review),
                  width: 320,
                  format: 'webp',
                })}"
              />
            </div>
          </div>
        </div>
        ${UtilS.isNessApp() ? `` : `<div class="pd-review-item__meta">${articleListControls(article)}</div>`}
      </div>
    </div>
  `;
};

export const pdReviewItemNess = ({ article, store, is_mobile }) => {
  const review = sel('_.review', article);
  const user = sel('_.user', article);
  const user_profile_url = user?._?.store?._?.thumbnail_img || user.profile_image || null;

  return html`
    <div
      class="pd-review-item"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      article_id="${article.id}"
    >
      <div class="pd-review-item__wrapper">
        <div class="pd-review-item__info">
          <div class="pd-review-item__user">
            <div class="pd-review-item__profile">${MShopCoreTmplS.userProfile(user_profile_url)}</div>
            <p class="pd-review-item__name">
              ${sel('_.user.nick_name', article) || sel('_.user.email', article) || 'NONAME'}
            </p>
          </div>
          ${UtilS.isNessApp() ? '' : `<div class="pd-review-item__star">${reviewScore(review.score)}</div>`}
        </div>

        <div class="pd-review-item__content">
          <div class="pd-review-item__body">
            ${reviewBodyStr(review)}
            ${is_mobile
              ? ``
              : `<div class="pd-review-item__date">${MShopAppCommunityS.dateFromNow(
                  article.created_at,
                )}</div>`}
          </div>
          <div class="pd-review-item__images">
            ${is_mobile
              ? go(
                  review.files,
                  takeL(2),
                  zipWithIndexL,
                  strMap(([index, file]) => {
                    return `
                      <div class="pd-review-item__img">
                        ${
                          index === 1 && review.files > 2
                            ? `<div class="dim">+${review.files.length - 2}</div>`
                            : ''
                        }
                        <img src="${UtilImageS.getResizedUrl({
                          url: file.url,
                          width: 320,
                          format: 'webp',
                        })}" alt="" />
                      </div>`;
                  }),
                )
              : `
            <div class="pd-review-item__img">
              ${review.files.length >= 2 ? `<div class="dim">+${review.files.length - 1}</div>` : ''}
              <img src="${UtilImageS.getResizedUrl({
                url: sel('files.0.url', review),
                width: 320,
                format: 'webp',
              })}" />
            </div>`}
          </div>
          ${!is_mobile
            ? ``
            : `<div class="pd-review-item__date">${MShopAppCommunityS.dateFromNow(article.created_at)}</div>`}
        </div>
      </div>
    </div>
  `;
};

const reviewItemMiddle = ({ is_mobile, review, is_store, css_type, article, is_swiper, img_alt }) => {
  if (is_mobile) {
    return html`
      <div class="community-review-item__middle">
        <div class="community-review-item__middle-top">
          <div class="community-review-item__body-wrap">
            <div class="community-review-item__body ${typo('14_medium')}">${reviewBodyStr(review)}</div>
          </div>
          <div class="community-review-item__photo">
            <div
              class="community-review-item__photo_img"
              data-is_swiper="${is_swiper}"
              ${is_swiper
                ? `style="background-image: url(${UtilImageS.getResizedUrl({
                    url: sel('files.0.url', review),
                    width: 750,
                    format: 'webp',
                    quality: 50,
                  })})"`
                : ''}
            >
              ${!is_swiper
                ? `<img
            src="${UtilImageS.getResizedUrl({
              url: sel('files.0.url', review),
              width: 750,
              format: 'webp',
            })}"
            alt="${img_alt}"
          />`
                : ''}
            </div>
            <div
              class="community-review-item__more"
              is_more="${sel('files.length', review) > 1 ? 'true' : 'false'}"
            ></div>
          </div>
        </div>
        <div class="community-review-item__product">
          ${articleProduct({ review, is_store, css_type, created_at: article.created_at })}
        </div>
      </div>
    `;
  }

  return html`
    <div class="community-review-item__middle">
      <div class="community-review-item__middle-left">
        <div class="community-review-item__body-wrap">
          <div class="community-review-item__body ${typo('14_medium')}">${reviewBodyStr(review)}</div>
        </div>
        <div class="community-review-item__product">
          ${articleProduct({ review, is_store, css_type, created_at: article.created_at })}
        </div>
      </div>
      <div class="community-review-item__photo">
        <div
          class="community-review-item__photo_img"
          data-is_swiper="${is_swiper}"
          ${is_swiper
            ? `style="background-image: url(${UtilImageS.getResizedUrl({
                url: sel('files.0.url', review),
                width: 750,
                format: 'webp',
                quality: 50,
              })})"`
            : ''}
        >
          ${!is_swiper
            ? `<img
            src="${UtilImageS.getResizedUrl({
              url: sel('files.0.url', review),
              width: 750,
              format: 'webp',
            })}"
            alt="${img_alt}"
          />`
            : ''}
        </div>
        <div
          class="community-review-item__more"
          is_more="${sel('files.length', review) > 1 ? 'true' : 'false'}"
        ></div>
      </div>
    </div>
  `;
};

export const reviewItem = ({ article, store, is_store, is_swiper = false, is_my, css_type, is_mobile }) => {
  const review = sel('_.review', article);
  const product_name =
    sel('_.review._.products.0.name', article) || sel('_.review._.base_product.name', article);
  const img_alt = go(void 0, () => {
    if (UtilS.isMarppleShopApp() && T.lang == 'kr') {
      return `${store.name} 굿즈, 굿즈 추천, ${store.name} ${product_name}, ${store.name} ${product_name} 리뷰`;
    } else {
      return `${store.name} ${product_name} Review`;
    }
  });
  const user_profile_url = getUserProfileUrl(article);
  const creator_shop_url = getCreatorShopUrl(article);

  return html`
    <div
      class="community-review-item${is_swiper ? ' swiper-slide' : ''}"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      article_id="${article.id}"
      css_type="${css_type}"
    >
      <div class="community-review-item__user-info">
        <div class="community-review-item__user-profile-wrap ${getCreatorShopLinkKlass(creator_shop_url)}">
          ${MShopCoreTmplS.userProfile(user_profile_url, creator_shop_url)}
        </div>
        <div class="community-review-item__user-info-middle">
          <div class="community-review-item__user_name ${getCreatorShopLinkKlass(creator_shop_url)}">
            ${makeUserNameChildrenHtml(article, typo('14_bold'))}
          </div>
          <div class="community-review-item__score">${reviewScore(sel('score', review))}</div>
        </div>
        <div class="article-item-control__date ${typo('12_medium')}">
          ${format(new Date(article.created_at), 'yyyy.MM.dd')}
        </div>
      </div>
      ${reviewItemMiddle({ is_mobile, review, is_store, css_type, article, is_swiper, img_alt })}
      <div class="community-review-item__controls">
        ${articleListControls(article, css_type, is_my, review.id)}
      </div>
    </div>
  `;
};

const reviewBodyStr = (review) => {
  return UtilS.linkify(
    review['comment' + G._en] || review.comment || review.comment_en || review.comment_jp || '',
  );
};

export const articleProduct = ({ review, css_type, created_at, is_detail }) => {
  if (css_type == 'small_fix') css_type = 'small';

  const user_product = sel('_.user_product', review);
  const option_name = is_detail && MShopAppUpItemS.makeUpOptionName(user_product);
  const texts = {
    option: ET('mps2::review::option'),
  };

  return html`
    <div class="article-product" css_type="${css_type}">
      <a
        href="/${T.lang}/${UtilS.escape(sel('_.products.0._.store.domain_name', review))}/products/${sel(
          '_.products.0.id',
          review,
        )}"
        data-post-message="${$dataStr({
          screen: {
            type: 'util',
            title: T('mshop::상품상세'),
            uri: `/${T.lang}/${UtilS.escape(sel('_.products.0._.store.domain_name', review))}/products/${sel(
              '_.products.0.id',
              review,
            )}`,
          },
        })}"
      >
        <span class="article-product__thumb">
          ${MpShopProductTmplS.thumbnail(sel('_.products.0', review), {
            width: 96,
          })}
        </span>
        <span class="article-product__store_name"
          >${UtilS.escape(sel('_.products.0._.store.name', review))}</span
        >
        <span class="article-product__name ${typo('14_medium')}">
          ${sel('_.products.0.name', review) || sel(`_.base_product.name`, review) || ''}
        </span>
        ${is_detail && option_name
          ? html`
              <span class="article-detail-review__product-option-name ${typo('12_medium')}">
                ${texts.option} : ${option_name}
              </span>
            `
          : ''}
        <span class="article-product__price ${staticTypo('unica_14_medium')}" lang="${T.lang}">
          ${UtilS.commify(creatorPriceOfProduct(sel('_.products.0', review)))}
        </span>
        <span class="community-review-item__date"> ${formatTimeElapsed(created_at)} </span>
      </a>
    </div>
  `;
};

export const reviewScore = (score) => {
  return html`
    <div class="article-review-score">
      ${strMap((i) => html`<div class="star" is="${score > i}" rate="${i + 1}"></div>`, range(5))}
    </div>
  `;
};

export const articleCheerUpControls = (article, css_type, is_my) => {
  if (!article) return '';
  if (css_type == 'small_fix') css_type = 'small';
  return html`
    <div class="article-cheer-up-item-control" css_type="${css_type}">
      ${UtilS.isNessApp()
        ? ''
        : `
        <div class="article-item-control__btn_comment article-cheer-up-item-control__btn_comment ${staticFont(
          'unica_16_regular',
        )}">${article.comment_count}</div>
        <div class="article-item-control__btn_like article-cheer-up-item-control__btn_like ${staticFont(
          'unica_16_regular',
        )}" is_like="${sel('_.like.id', article) ? 'true' : 'false'}">
          ${article.like_count}
        </div>
      `}
      ${is_my
        ? html`
            <div
              class="article-item-control__remove article-cheer-up-item-control__remove"
              style="display: none;"
            >
              <button
                type="button"
                class="article-item-control__remove-btn ${typo('14_medium')}"
                data-article_id="${article.id}"
              >
                ${T('Delete')}
              </button>
            </div>
          `
        : ''}
    </div>
  `;
};

export const articleListControls = (article, css_type, is_my, review_id) => {
  if (!article) return '';
  if (css_type == 'small_fix') css_type = 'small';
  return html`
    <div class="article-item-control" css_type="${css_type}">
      ${UtilS.isNessApp()
        ? ''
        : `
        <div class="article-item-control__btn_like ${staticTypo('unica_14_regular')}" is_like="${
            sel('_.like.id', article) ? 'true' : 'false'
          }">
          ${article.like_count}
        </div>
        <div class="article-item-control__btn_comment">${article.comment_count}</div>
      `}
      ${is_my
        ? html`
            <div class="community-review-item__remove">
              <button
                type="button"
                class="community-review-item__remove-btn ${typo('14_medium')}"
                data-review_id="${review_id}"
              >
                ${T('Delete')}
              </button>
            </div>
          `
        : ''}
    </div>
  `;
};

export const nessReviewItem = ({ article, store, is_store, is_swiper = false, is_my, css_type }) => {
  const LIMIT_NAME_NUMBER = 5;
  const review = sel('_.review', article);
  const user_profile_url = article._.user._?.store?._?.thumbnail_img || article._.user.profile_image || null;
  const user_email =
    sel('_.user.email', article) && MShopAppCommunityS.userSubstr(sel('_.user.email', article));
  const user_nick_name = sel('_.user.nick_name', article);
  const user_name = user_nick_name || user_email.slice(0, LIMIT_NAME_NUMBER) || 'NONAME';

  return html`
    <div
      class="community-review-item${is_swiper ? ' swiper-slide' : ''}"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      article_id="${article.id}"
      css_type="${css_type}"
    >
      <div class="community-review-item__photo">
        <div
          class="community-review-item__photo_img"
          data-is_swiper="${is_swiper}"
          ${is_swiper
            ? `style="background-image: url(${UtilImageS.getResizedUrl({
                url: sel('files.0.url', review),
                width: 750,
                format: 'webp',
                quality: 50,
              })})"`
            : ''}
        >
          ${!is_swiper
            ? `<img
            src="${UtilImageS.getResizedUrl({
              url: sel('files.0.url', review),
              width: 750,
              format: 'webp',
            })}"
            alt=""
          />`
            : ''}
        </div>
        <div
          class="community-review-item__more"
          is_more="${sel('files.length', review) > 1 ? 'true' : 'false'}"
        >
          + ${sel('files.length', review) - 1}
        </div>
      </div>
      <div class="community-review-item__user-info">
        <div class="community-review-item__user-profile-wrap">
          ${MShopCoreTmplS.userProfile(user_profile_url)}
        </div>
        <div class="community-review-item__user_name">${user_name}</div>
        ${is_my
          ? html`
              <div class="community-review-item__remove">
                <button type="button" class="community-review-item__remove-btn" data-review_id="${review.id}">
                  ${T('Delete')}
                </button>
              </div>
            `
          : ''}
      </div>
      <div class="community-review-item__body-wrap">
        <div class="community-review-item__body">${reviewBodyStr(review)}</div>
      </div>
      <div class="community-review-item__product">
        ${articleProduct({ review, is_store, css_type, created_at: article.created_at })}
      </div>
    </div>
  `;
};

export const cheerUpItem = ({ article, store, is_swiper = false, is_my, css_type }) => {
  const creator_shop_url = getCreatorShopUrl(article);
  return html`
    <div
      class="cheer-up-item${is_swiper ? ' swiper-slide' : ''}"
      article_type="${sel('type', article)}"
      store_id="${store?.id}"
      data-store_id="${store?.id}"
      article_id="${article.id}"
      data-article_id="${article.id}"
      css_type="${css_type}"
    >
      <div class="cheer-up-item__user-info">
        <div class="cheer-up-item__user-profile-wrap ${getCreatorShopLinkKlass(creator_shop_url)}">
          ${MShopCoreTmplS.userProfile(getUserProfileUrl(article), creator_shop_url)}
        </div>
        <div class="cheer-up-item__user-info-middle">
          <div class="cheer-up-item__user_name ${getCreatorShopLinkKlass(creator_shop_url)}">
            ${makeUserNameChildrenHtml(article, typo('14_bold'))}
          </div>
        </div>
        <div class="article-item-control__date ${typo('12_medium')}">
          ${format(new Date(article.created_at), 'yyyy.MM.dd')}
        </div>
      </div>
      <div class="cheer-up-item__body">
        <div class="cheer-up-item__body-text">${article.body}</div>
        ${article.type == 'community_photo'
          ? html`
              <div class="cheer-up-item__photo">
                <img
                  class="cheer-up-item__photo_img"
                  src="${UtilImageS.getResized70Webp(article.photos[0].url, 96)}"
                  alt=""
                />
              </div>
            `
          : ''}
      </div>
      <div class="cheer-up-item__controls">${articleCheerUpControls(article, css_type, is_my)}</div>
    </div>
  `;
};
