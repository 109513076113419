import { curry, go1, reduce } from 'fxjs/es';

export const recursiveL = curry(function* (f, start) {
  while (true) yield (start = go1(start, f));
});

export const replaceUrl = (url) => {
  if (url == location.href) return;

  history.replaceState(history.state, '', url);
};

export const toFragment = (els) => {
  return reduce((frag, el) => (frag.appendChild(el), frag), document.createDocumentFragment(), els);
};
