import { on, View } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { sendDocumentEvent } from '../../../../../shared/util/event';

export class ButtonShowChangeEvent extends CustomEvent<{ show: boolean }> {}

export type FloatingButtonBaseState = {
  show_itself: boolean; // 자체적으로 가려졌는지 여부, 스스로가 통제했으면 true, 스스로는 가리지 않았으면 false (ex, 외부에서 가리거나 않거나)
  show: boolean;
};

export class FloatingButtonBase<D extends { show?: boolean }> extends View<D> {
  state: FloatingButtonBaseState;

  constructor(data: D) {
    super(data);

    this.state = {
      show_itself: data.show ?? true,
      show: data.show ?? true,
    };
  }

  override onMount() {
    this.prepareToggleDisplay();
  }

  @on('click')
  handleClick() {
    sendDocumentEvent('floating_box_button_click', { name: this.toString() });
  }

  setShowStatus(should_show: boolean) {
    if (this.state.show == should_show) return;

    this.state.show = should_show;
    this.dispatchEvent(ButtonShowChangeEvent, {
      bubbles: true,
      detail: {
        show: should_show,
      },
    });
  }

  prepareToggleDisplay() {
    const self_element = this.element();

    self_element.addEventListener('transitionend', () => {
      if (!this.state.show_itself) {
        self_element.classList.add(klass.hidden);
      }
    });
  }

  // prepareToggleDisplay 참고
  toggleDisplay(should_show: boolean, instant = false) {
    const self_element = this.element();

    if (this.state.show !== should_show) return;
    if (this.state.show_itself == should_show) return;

    this.state.show_itself = should_show;

    if (instant) {
      if (should_show) {
        self_element.classList.remove(klass.hidden);
      } else {
        self_element.classList.add(klass.hidden);
      }

      return;
    }

    if (should_show) {
      self_element.classList.remove(klass.hidden);
      self_element.offsetHeight; // force layout
      self_element.classList.remove(klass.zero_height);
    } else {
      self_element.classList.add(klass.zero_height);
    }
  }
}
