import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { filter, go, html, reject, delay } from 'fxjs/es';
import { NessCustomMakerTmplS } from '../../S/Tmpl/module/NessCustomMakerTmplS.js';
import {
  $addClass,
  $appendTo,
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $qs,
  $removeClass,
  $setAttr,
  $setCss,
  $setHTML,
  $siblings,
} from 'fxdom/es';
import { vectorEditorTabAppendingMobile } from '../../Core/F/Function/core.js';
import { initNessMpMaker } from './private/initNessMpMaker.js';
import { NessCustomMakerMobileFakeMpMakerF } from '../../Mobile/FakeMpMaker/F/Function/module/NessCustomMakerMobileFakeMpMakerF.js';
import { NessCustomMakerMobileFooterButtonF } from '../../Mobile/FooterButton/F/Function/module/NessCustomMakerMobileFooterButtonF.js';
import { NessCustomMakerMobileProductOptionF } from '../../Mobile/ProductOption/F/Function/module/NessCustomMakerMobileProductOptionF.js';
import { BpOptionS } from '../../../BpOption/S/Function/module/BpOptionS.js';
import { MuiF } from '../../../Mui/F/Function/module/MuiF.js';
import { NessCustomMakerFrameBaseProductTotalSelectorMuiF } from '../../Frame/BaseProductTotalSelector/F/Mui/module/NessCustomMakerFrameBaseProductTotalSelectorMuiF.js';
import { NessCustomMakerCoreS } from '../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { NessCustomMakerCoreConstantS } from '../../Core/S/Constant/module/NessCustomMakerCoreConstantS.js';
import { NessCustomMakerCoreF } from '../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { NessCustomMakerMobileProductOptionTmplS } from '../../Mobile/ProductOption/S/Tmpl/module/NessCustomMakerMobileProductOptionTmplS.js';
import { NessCustomMakerF } from './module/NessCustomMakerF.js';
import { handleOpenFrameAsset } from './handler.js';
import { NewMakerLegacyF } from '../../../NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { changeProductBpcA, getBpcTitle } from '../../../Maker/F/Product/bpc_fs.js';
import { fcanvasChanging } from '../../../Maker/F/util.js';
import { errorHandlingEventWrapper } from '../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { NewMakerCheckConditionF } from '../../../NewMaker/CheckCondition/F/Function/module/NewMakerCheckConditionF.js';

const mobile_main_icon_menu_tmpl = html`
  <div class="mp-maker__left-icon mp-maker__mo-main-icon circle_icon">
    <div class="icon product_option_icon"></div>
    <div class="text">상품 옵션</div>
  </div>
  <div class="mp-maker__right-icon mp-maker__mo-main-icon circle_icon">
    <div class="icon custom_asset_icon"></div>
    <div class="text">커스텀 에셋</div>
  </div>
`;
export const startNessCustomForMobile = async (ness_custom_maker_wrapper_el) => {
  $.add_class([$1('html'), $1('body')], 'mp-maker-page ness-custom-maker');
  G.mp.maker.window_height = $.height(window);
  G.mp.maker.window_width = $.width(window);
  G.mp.maker.canvas_container_height = G.mp.maker.window_height;
  $.attr($1('html'), {
    body_height: G.mp.maker.window_height - (G.mp.maker.window_height % 100),
  });
  G.mp.maker.CANVAS_WIDTH_ORIGIN = 860;
  G.mp.maker.NSCREEN_ZOOM = 1;
  G.mp.maker.CANVAS_WIDTH = G.mp.maker.CANVAS_HEIGHT = G.mp.maker.CANVAS_WIDTH_ORIGIN;
  G.mp.maker.vectorEditorTabAppendingMobile = vectorEditorTabAppendingMobile;
  G.mp.maker.fakeMpMaker_SwiperProgressUi = NessCustomMakerMobileFakeMpMakerF.progress_move;
  G.mp.maker.condition_tooltip_mo_tmpl = html`
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99961 14.3996C11.5342 14.3996 14.3996 11.5342 14.3996 7.99961C14.3996 4.46499 11.5342 1.59961 7.99961 1.59961C4.46499 1.59961 1.59961 4.46499 1.59961 7.99961C1.59961 11.5342 4.46499 14.3996 7.99961 14.3996ZM10.1413 8.44999C11.0177 7.99627 11.9996 7.48796 11.9996 6.40951C11.9996 5.2981 11.0672 3.99961 8.02664 3.99961C5.45907 3.99961 4.08069 4.95697 3.99961 6.68462H5.94556C5.97258 5.54019 7.06718 5.16605 7.95907 5.16605C8.83745 5.16605 9.97258 5.40814 9.97258 6.31048C9.97258 7.01167 9.28503 7.36947 8.56382 7.74479C7.7684 8.15872 6.93204 8.59396 6.93204 9.53469V9.71075H8.79691C8.79691 9.14596 9.43485 8.81571 10.1413 8.44999ZM6.81042 10.437V11.9996H8.91853V10.437H6.81042Z"
        fill="black"
      />
    </svg>
    <div class="condition_tooltip_text">옆면까지 꽉 찬 인쇄를 원하시나요?</div>
  `;
  G.mp.maker.CANVAS_HEIGHT = Math.round(
    G.mp.maker.window_height * (G.mp.maker.CANVAS_WIDTH / $.width(window)),
  );
  if (NewMakerCheckConditionF.ConditionChecker.canInstantiate()) {
    G.mp.maker.conditionCheckerInstance = new NewMakerCheckConditionF.ConditionChecker();
  } else {
    G.mp.maker.conditionCheckerInstance = null;
  }

  const product_color = box().maker.product_color;
  const is_vector_editor = VectorEditorConstantS.EDITOR_TYPES.includes(
    product_color._.base_product.maker_type,
  );
  go(document.body, $addClass(is_vector_editor ? 'ness_vector_editor' : 'ness_maker_editor'));
  go(NessCustomMakerTmplS.mobileHtml(box(), is_vector_editor), $el, $appendTo(ness_custom_maker_wrapper_el));
  const ness_header = go(
    ness_custom_maker_wrapper_el,
    $closest('.don_frame'),
    $find('> .don_wrapper > .header'),
  );
  const maker_wrapper_el = $qs('.custom-product-detail-mo__mp-maker');
  const wrapper_el_siblings = $siblings(ness_custom_maker_wrapper_el);
  const maker_wrapper_el_siblings = $siblings(maker_wrapper_el);
  const etc_els = go(
    [...wrapper_el_siblings, ...maker_wrapper_el_siblings, ness_header],
    reject((el) => el === maker_wrapper_el),
  );
  await initNessMpMaker($qs('.custom-product-detail-mo__mp-maker'), false, mobile_main_icon_menu_tmpl);
  NewMakerLegacyF.makerMobileSwiper($closest('.don_tab', ness_custom_maker_wrapper_el));
  NessCustomMakerMobileFooterButtonF.event(ness_custom_maker_wrapper_el, etc_els);
  NessCustomMakerMobileProductOptionF.makeEvent(ness_custom_maker_wrapper_el);
  NessCustomMakerF.refreshCustomProductDetailHead(ness_custom_maker_wrapper_el);
  // window.onresize = () => NessCustomMakerMobileProductOptionF.checkFoldedColorChip();
  /* 만들기툴 아이콘 이벤트 */
  go(
    ness_custom_maker_wrapper_el,
    $delegate('click', '.img_pattern', () => {
      $.frame.open(
        {
          frame_name: 'maker.m_image_editor',
          page_name: 'm_image_pattern_page',
          height: 280,
          appended: ($frame) => {
            go(
              $frame,
              $addClass('ness-custom-maker-frame-mo'),
              $.find1('.header'),
              $setHTML(html`<div class="line"></div>`),
            );
          },
        },
        {
          page_name: 'm_image_pattern_page',
          appended: ($page) => {
            go($page, $.find1('.header'), $setCss({ display: 'none' }));
          },
        },
      );
    }),
    $delegate('click', '.mp-maker__left-icon', () => {
      const { product_color, phone_cases } = box().maker;
      const base_product = product_color._.base_product;
      const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(base_product)?.length;
      const is_bpc_show = !base_product.is_bpc_hidden; // 색상 정보 보이기
      const is_bps_show = !has_bp_option_groups && !base_product.is_bps_hidden; // 사이즈 정보 보이기

      MuiF.openFrame(
        NessCustomMakerFrameBaseProductTotalSelectorMuiF.frame,
        async (frame, page, [device_tab, color_tab, size_tab, ...option_tabs]) => {
          const device_tab_off = !(is_bpc_show && phone_cases?.length);
          const size_tab_off = !(is_bps_show && !phone_cases.length);

          device_tab.selected = !device_tab_off;
          color_tab.selected = is_bpc_show;
          size_tab.selected = !size_tab_off;

          const options = NessCustomMakerCoreS.filteredBpOptionGroupsForUi(product_color, true);
          const selectableOptions = options.filter(
            (opt) => opt.ui_type !== NessCustomMakerCoreConstantS.BP_OPTION_GROUPS_TYPE.FIXED,
          );
          const unused_tab_names = [
            !device_tab.selected && device_tab.tab_name,
            !color_tab.selected && color_tab.tab_name,
            !size_tab.selected && size_tab.tab_name,
          ].filter(Boolean);

          // 기본 [디바이스, 컬러, 사이즈] 설정 외에 옵션 설정이 필요할 경우 option_tab 들을 을 활성화 시킴
          option_tabs.forEach((opt_tab, idx) => {
            if (selectableOptions.length === 0 || selectableOptions.length <= idx) {
              opt_tab.selected = false;
              unused_tab_names.push(opt_tab.tab_name);
            } else {
              const opt = selectableOptions[idx];
              opt_tab.title = opt.option_group.name;
              opt_tab.makeData = () => ({
                product: product_color,
                option_id: opt.option_group.id,
                ui_type: opt.ui_type,
              });
            }
          });
          if (color_tab.selected) color_tab.title = getBpcTitle();

          // 설정하지 못하는 설정 탭은 숨김
          page.appended = (pageEl$) => {
            unused_tab_names.forEach((tab_name) => {
              go(pageEl$, $.find1(`button[tab_name="${tab_name}"]`), $setCss({ display: 'none' }));
            });

            const visible_tab_buttons = go(
              pageEl$,
              $.find1('.tab_buttons'),
              (el) => el.children,
              filter((child) => $.css(child, 'display') !== 'none'),
            );
            if (visible_tab_buttons.length === 1) {
              go(visible_tab_buttons[0], $setCss({ 'border-bottom': '0px' }));
            }
          };
        },
      );
    }),
    $delegate('click', '.mp-maker__right-icon', handleOpenFrameAsset),
    $delegate(
      'click',
      '.pd-radio.color',
      fcanvasChanging(
        errorHandlingEventWrapper(async function (e) {
          return _p.go(e.currentTarget, $data, async function (bpc) {
            $.don_loader_start();
            await delay(10, () => {});
            await changeProductBpcA(bpc);
            NessCustomMakerF.refreshCustomProductDetailHead();
            $.don_loader_end();
          });
        }),
      ),
    ),
    $delegate('click', '.expand-btn', (e) => {
      $setAttr({ 'data-folded': true }, $qs('#for_detail_change'));
      $removeClass('fold', $qs('.pd-field.colors .dynamic-html'));
    }),
  );
};

export const refreshCustomProductDetailHead = (tab_el$ = document.body, box_maker = box().maker) => {
  const { custom_level } = box().product._.stores_product;
  return go(
    tab_el$,
    $find('.custom-product-detail-mo__product-option-wrapper'),
    $setHTML(NessCustomMakerMobileProductOptionTmplS.customProductDetailTmpl(box_maker, custom_level)),
    NessCustomMakerMobileProductOptionF.checkFoldedColorChip,
    (el) =>
      NessCustomMakerCoreF.registerNoticeTippy(el, {
        trigger: 'click',
      }),
  );
};
