export const SELECTOR = {
  NOT_EDITABLE: '[contenteditable=false]',
};

export const KEY_CODE = {
  ENTER: 13,
  DELETE: 46,
  BACKSPACE: 8,
};

export const MARPPLE_EMBED_SELECTOR = '.marpple_embed';
export const EMBED_SHOW_SELECTOR = '.embed_show';
export const RENEWAL_MARPPLE_EMBED_PREVIEW_CLASS = 'renewal_marpple_embed_preview';
export const POPUP_STORE_PRODUCTS_EMBED_PREVIEW_CLASS = 'popup-store-products-embed-preview';
export const POPUPSTORE_EMBED_CLASS = '.popup_store_embed';
export const RENEWAL_MARPPLE_EMBED_ADD_MODAL_CLASS = 'renewal_marpple_embed_add_modal';

export const EMBED_SERVICE = {
  renewal_marpple: 'renewal_marpple',
  popup_store_products: 'popup_store_products',
  youtube: 'youtube',
  vimeo: 'vimeo',
  instagram: 'instagram',
  twitter: 'twitter',
  facebook: 'facebook',
  marpple: 'marpple',
  ness: 'ness',
};
