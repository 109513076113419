import { FloatingButtonBase } from './FloatingButtons/FloatingButtonBase';
import { type Html, html } from 'rune-ts';
import button_klass from './FloatingButtons/FloatingButton.module.scss';

/**
 * @dev scroll 이 viewport * n 만큼 되었는지 확인 하는 함수
 */
const isScrollViewportN = (n: number) => {
  return window.scrollY > window.innerHeight * n;
};

/**
 * @dev scroll 된 양이 viewport 에 비례해서 조건을 넘으면 보여주고, 아니면 가림
 */
export const toggleButtonAtScrollRelativeToViewport = (
  button_view: FloatingButtonBase<object>,
  multiple: number,
) => {
  let reserved = false;
  window.addEventListener('scroll', () => {
    if (reserved) return;
    requestAnimationFrame(() => {
      button_view.setShowStatus(isScrollViewportN(multiple));

      reserved = false;
    });
  });
};

export const makeFloatingButtonTemplate = (template: Html, initial_show = true) => {
  return html`<div
    class="${button_klass.wrapper} ${initial_show
      ? ''
      : `${button_klass.zero_height} ${button_klass.hidden}`} "
  >
    ${template}
  </div> `;
};
