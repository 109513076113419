import { html, isNil } from 'fxjs/es';
import { html as runeHtml, on, View } from 'rune-ts';
import klass from '../../F/Style/feed_detail_admin.module.scss';
import { typo } from '../../../../../services/MarppleShop/shared/typography/typo';
import { map, pipe, toArray } from '@fxts/core';
import { htmlIf } from '../../../../Util/S/Function/util';
import {
  PRODUCT_CARD_THEME,
  ProductCardData,
  ProductCardOption,
} from '../../../../../services/MarppleShop/features/ProductList/type';
import {
  ProductCard,
  ProductLikeToggleEvent,
} from '../../../../../services/MarppleShop/renderApp/components/cells/ProductCard/ProductCard';
import {
  Modal,
  ModalClosedEvent,
} from '../../../../../services/MarppleShop/renderApp/components/atoms/Modal/Modal';
import { ProductThumbnail } from '../../../../../services/MarppleShop/renderApp/components/cells/ProductThumbnail/ProductThumbnail';
import { marppleProductRegex } from '../../../../Creator/Editor/S/util';
import format from 'date-fns/format/index.js';
import { popupStoreField } from '../../../PopupStores/Detail/S/Tmpl/_sections';
import { textareaControl } from '../../../PopupStores/Detail/S/Tmpl/_controls';
import { ProductListApiS } from '../../../../../services/MarppleShop/features/ProductList/outbound/share';
// import Swiper from 'swiper';

type MakeFormSectionBaseArgs = {
  title: string;
  sub_title?: string;
  name: string;
};

type TextArgs = {
  type: 'text' | 'textarea';
  placeholder: string;
  line_limit?: number;
  value: string;
};

type IntlArgs = {
  type: 'text' | 'textarea';
  placeholder: string;
  value: {
    kr: string;
    en: string;
    jp: string;
  };
  length: {
    kr: number;
    en: number;
    jp: number;
  };
  optional_text_input?: string;
};

type SelectArgs = {
  options: Array<{
    name: string;
    value: string;
  }>;
  selected_option_value?: string;
};

type ImageArgs = {
  image_url?: string;
  size_limit: [number, number];
  extensions: string[];
  etc: string;
  placeholder: string;
};

type HashtagArgs = {
  tags: string[];
  placeholder: string;
};
type PublicStatusArgs = {
  start_at: string;
  end_at: string;
  is_public: boolean;
  no_reserve: boolean;
};

type MakeSelectSectionArgs = MakeFormSectionBaseArgs & SelectArgs;
type MakeTextSectionArgs = MakeFormSectionBaseArgs & TextArgs;
type MakeIntlTextSectionArgs = MakeFormSectionBaseArgs & IntlArgs;
type MakeImaageSectionArgs = MakeFormSectionBaseArgs & ImageArgs;
type MakeHashtagFormSectionArgs = MakeFormSectionBaseArgs & HashtagArgs;
type MakePublicStatusFormSectionArgs = MakeFormSectionBaseArgs & PublicStatusArgs;
type TextData = { [name: string]: string };

const makeBaseSection = ({
  title,
  body,
  sub_title,
  name,
}: {
  title: string;
  sub_title?: string;
  body: string;
  name: string;
}) => {
  return html`
    <div class="${klass.form_section}" data-name="${name}">
      <p class="${klass.section_title} ${typo('14_bold')}">
        ${title} <span class="${klass.section_sub_title} ${typo('12_medium')}">${sub_title}</span>
      </p>
      <div class="${klass.section_body}">${body}</div>
    </div>
  `;
};

export const makeTextSection = (args: MakeTextSectionArgs) => {
  const { title, sub_title, name, type, placeholder, value, line_limit } = args;

  const text_html = html`<div class="${klass.input_item}">
    <input
      ${htmlIf(isNil(line_limit), `data-line_limit="${line_limit}"`)}
      class="${klass.form_text}"
      type="text"
      name="${name}"
      value="${value}"
      placeholder="${placeholder}"
    />
  </div>`;

  const textarea_html = html`<div class="${klass.input_item}">
    <textarea
      ${htmlIf(isNil(line_limit), `data-line_limit="${line_limit}"`)}
      class="${klass.form_text}"
      name="${name}"
      id=""
      cols="30"
      rows="${line_limit}"
      placeholder="${placeholder}"
    >
${value}</textarea
    >
  </div>`;

  return makeBaseSection({ title, sub_title, name, body: type == 'text' ? text_html : textarea_html });
};

export const makeIntlSection = (args: MakeIntlTextSectionArgs) => {
  const { title, name, type, placeholder, length, sub_title, value, optional_text_input } = args; // const

  const body = html`
    <div class="${klass.intl_inputs_container}">
      ${optional_text_input}
      ${makeTextInputHtml({
        name,
        placeholder,
        length_limit: length.kr,
        value: value.kr || '',
        lang: 'kr',
        icon: `<img
          style="width: 100%"
          src="//s3.marpple.co/files/u_1396787/2020/11/original/ecd38341eb6b9e0e820a28f1f2203956a23a0ad61.png"
          alt=""
        />`,
        type,
      })}
      ${makeTextInputHtml({
        name,
        placeholder: placeholder.replace('국문', '영문'),
        length_limit: length.en,
        value: value.en || '',
        lang: 'en',
        icon: `<img
          style="width: 100%"
          src="//s3.marpple.co/files/u_1396787/2020/11/original/ce04b2abdf2d685ed86edc7f2d6390c5e6fe1f8f1.png"
          alt=""
        />`,
        type,
      })}
      ${makeTextInputHtml({
        name,
        placeholder: placeholder.replace('국문', '일문'),
        length_limit: length.jp,
        value: value.jp || '',
        lang: 'jp',
        icon: `<img
          style="width: 100%"
          src="//s3.marpple.co/files/u_1396787/2020/11/original/4a057f84e5d2e2a9dde8f481a75b70581835940c1.png"
          alt=""
        />`,
        type,
      })}
    </div>
  `;

  return makeBaseSection({ title, sub_title, name, body });
};

export const makeSelectSection = (args: MakeSelectSectionArgs) => {
  const { title, name, options, selected_option_value, sub_title } = args;

  const select_html = html`
    <div class="${klass.input_item}">
      <select class="${klass.form_select}" name="${name}">
        ${pipe(
          options,
          map(
            (option) =>
              html`<option ${selected_option_value == option.value ? 'selected' : ''} value="${option.value}">
                ${option.name}
              </option>`,
          ),
          toArray,
          (xs) => xs.join(''),
        )}
      </select>
    </div>
  `;

  return makeBaseSection({ title, sub_title, name, body: select_html });
};

export const makeImageSection = (args: MakeImaageSectionArgs) => {
  const { title, sub_title, size_limit, name, extensions, image_url, placeholder, etc } = args;

  const image_html = html`
    <div class="${klass.input_item}" style="aspect-ratio: ${size_limit[0]}/${size_limit[1]}">
      <img class="${klass.image_preview} ${image_url ? klass.on : ''}" src="${image_url}" alt="" />
      <input class="${klass.image_input}" name="${name}" type="file" />
      <div class="${klass.image_form_replacer}">
        <div class="${klass.input_plus_button}">
          <img
            src="//s3.marpple.co/files/u_1165777/2020/9/original/81c91fe418fc9ceefcc1c3d951a63e57ca0378f71.png"
            alt=""
          />
        </div>
        <p class="${klass.image_form_placeholder} ${typo('14_medium')}">${placeholder}</p>
      </div>
    </div>
    <div class="${klass.image_form_description_table} ${typo('14_medium')}">
      <span>사이즈 ${size_limit[0]} * ${size_limit[1]}px</span>
      <span>|</span>
      <span>확장자 ${extensions.map((str) => str.toUpperCase()).join(', ')} 가능</span>
      <span>|</span>
      <span>${etc}</span>
    </div>
  `;

  return makeBaseSection({ title, name, sub_title, body: image_html });
};

export const makeHashtagFormSection = (args: MakeHashtagFormSectionArgs) => {
  const { title, name, sub_title, tags, placeholder } = args;

  const body = html`
    <div class="${klass.hashtag_container}">
      <div class="${klass.hashtag_input_row}">
        <input class="${klass.hashtag_input}" name="${name}" type="text" placeholder="${placeholder}" />
        <button class="${klass.hashtag_add_button}">${plus_icon}</button>
      </div>
      <div class="${klass.hashtags_preview}">${tags.map(makeHashTagItem).join('')}</div>
    </div>
  `;

  return makeBaseSection({ title, sub_title, name, body });
};

export const makeHashTagItem = (tag: string) => {
  return html`<button class="${klass.hashtag_preview_item} ${typo('14_medium')}" data-tag="${tag}">
    <span>#${tag}</span>
    <svg
      class="${klass.hashtag_preview_x_icon}"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1143_19448)">
        <path
          d="M6.62913 6.00003L10.3689 2.25503C10.5437 2.08003 10.5437 1.80003 10.3689 1.63003C10.1942 1.45503 9.91456 1.45503 9.73981 1.63003L6 5.37003L2.26019 1.63003C2.08544 1.45503 1.80583 1.45503 1.63107 1.63003C1.45631 1.80503 1.45631 2.08503 1.63107 2.25503L5.37087 6.00003L1.63107 9.74503C1.45631 9.92003 1.45631 10.2 1.63107 10.37C1.71595 10.455 1.83079 10.5 1.94563 10.5C2.06047 10.5 2.17032 10.455 2.26019 10.37L6 6.63003L9.73981 10.37C9.82469 10.455 9.93953 10.5 10.0544 10.5C10.1692 10.5 10.2791 10.455 10.3689 10.37C10.5437 10.195 10.5437 9.91503 10.3689 9.74503L6.62913 6.00003Z"
          fill="#6B6B6B"
        />
      </g>
    </svg>
  </button>`;
};

export const makePublicStatusFormSection = (args: MakePublicStatusFormSectionArgs) => {
  const { start_at, end_at, name, is_public, no_reserve } = args;

  const no_end = isNil(end_at);

  const status = start_at ? 'reserve' : is_public ? 'public' : 'private';

  const body = html`
    <div class="${klass.public_status_form}">
      <div class="${klass.public_status_form_radio_container}">
        <label
          ><input name="status" type="radio" value="public" ${htmlIf(status == 'public', 'checked')} /> 공개
        </label>
        <label
          ><input name="status" type="radio" value="private" ${htmlIf(status == 'private', 'checked')} />
          비공개
        </label>
        ${no_reserve
          ? ''
          : html`<label>
              <input name="status" type="radio" value="reserve" ${htmlIf(status == 'reserve', 'checked')} />
              예약 공개
            </label>`}
      </div>
      <div class="${klass.show_term_container}" data-show="${status == 'reserve'}">
        <div class="${klass.show_term_input_container}">
          <input
            class="${klass.term_input}"
            name="show_start_at"
            type="text"
            value="${start_at ? format(new Date(start_at), 'yyyy-MM-dd HH:mm:ss') : undefined}"
          />
          ~
          <input
            class="${klass.term_input}"
            name="show_end_at"
            type="text"
            value="${end_at ? format(new Date(end_at), 'yyyy-MM-dd HH:mm:ss') : undefined}"
            ${htmlIf(no_end, 'disabled')}
          />
        </div>
        <div class="${klass.no_end_container} ${typo('14_medium')}">
          <label>
            <input
              ${htmlIf(no_end, 'checked')}
              class="${klass.term_end_checkbox}"
              name="no_end"
              type="checkbox"
            />
            종료일 없음
          </label>
        </div>
        <p class="${klass.public_status_caution}">
          <span class="${klass.public_status_caution_icon}"> ${caution_icon} </span>한국 표준 시간(KST)
          기준이며, 설정한 게시일 전까지 비공개 상태로 유지됩니다.
        </p>
      </div>
    </div>
  `;

  return makeBaseSection({ title: '공개 상태', name, body });
};

const caution_icon = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  viewBox="0 0 16 16"
  fill="none"
>
  <g clip-path="url(#clip0_831_35611)">
    <path
      d="M8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325ZM8.83337 11.2533C8.83337 11.7133 8.46004 12.0866 8.00004 12.0866C7.54004 12.0866 7.16671 11.7133 7.16671 11.2533V7.75325C7.16671 7.29325 7.54004 6.91992 8.00004 6.91992C8.46004 6.91992 8.83337 7.29325 8.83337 7.75325V11.2533ZM8.00004 5.58659C7.54004 5.58659 7.16671 5.21325 7.16671 4.75325C7.16671 4.29325 7.54004 3.91992 8.00004 3.91992C8.46004 3.91992 8.83337 4.29325 8.83337 4.75325C8.83337 5.21325 8.46004 5.58659 8.00004 5.58659Z"
      fill="#A2A2A2"
    />
  </g>
  <defs>
    <clipPath id="clip0_831_35611">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</svg>`;

export const link_icon = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  viewBox="0 0 18 18"
  fill="none"
>
  <path
    d="M8.22755 11.205C7.38005 11.205 6.53255 10.8825 5.89505 10.245C5.27255 9.62249 4.92755 8.79749 4.92755 7.91249C4.92755 7.02749 5.27255 6.20249 5.89505 5.57999L9.00755 2.46749C10.2901 1.18499 12.3826 1.18499 13.6726 2.46749C14.9551 3.75749 14.9551 5.84249 13.6726 7.13249L13.4026 7.40249C13.1476 7.65749 12.7276 7.65749 12.4726 7.40249C12.2176 7.14749 12.2176 6.72749 12.4726 6.47249L12.7426 6.20249C13.5151 5.42999 13.5151 4.16999 12.7426 3.39749C11.9701 2.62499 10.7101 2.62499 9.93755 3.39749L6.82505 6.50999C6.45005 6.88499 6.24005 7.37999 6.24005 7.91249C6.24005 8.44499 6.45005 8.93999 6.82505 9.31499C7.55255 10.0425 8.73005 10.0875 9.51005 9.42749C9.78755 9.19499 10.2001 9.22499 10.4326 9.50249C10.6651 9.77999 10.6351 10.1925 10.3576 10.425C9.75005 10.9425 8.98505 11.1975 8.23505 11.1975L8.22755 11.205Z"
    fill="#141414"
  />
  <path
    d="M6.66755 16.5C5.79005 16.5 4.95755 16.155 4.33505 15.5325C3.71255 14.91 3.36755 14.085 3.36755 13.2C3.36755 12.315 3.71255 11.49 4.33505 10.8675L4.60505 10.5975C4.86005 10.3425 5.28005 10.3425 5.53505 10.5975C5.79005 10.8525 5.79005 11.2725 5.53505 11.5275L5.26505 11.7975C4.89005 12.1725 4.68005 12.675 4.68005 13.2C4.68005 13.725 4.89005 14.2275 5.26505 14.6025C6.01505 15.3525 7.32005 15.3525 8.07005 14.6025L11.1826 11.49C11.9551 10.7175 11.9551 9.45749 11.1826 8.68499C10.4551 7.95749 9.27755 7.91249 8.49755 8.57249C8.22005 8.80499 7.80755 8.77499 7.57505 8.49749C7.34255 8.21999 7.37255 7.80749 7.65005 7.57499C8.94755 6.47249 10.9051 6.55499 12.1126 7.76249C13.3951 9.04499 13.3951 11.1375 12.1126 12.4275L9.00005 15.54C8.37755 16.1625 7.55255 16.5075 6.66755 16.5075V16.5Z"
    fill="#141414"
  />
</svg>`;

const plus_icon = `<svg
  class="${klass.plus_button_icon}"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M16.0009 10.0009L3.9991 10.0009M9.99999 16.0018L9.99999 4.00001"
    stroke="currentColor"
    stroke-linecap="round"
  />
</svg>`;

type TextInputHtmlArg = {
  placeholder?: string;
  icon?: string;
  name: string;
  value: string;
  length_limit?: number;
  type: 'text' | 'textarea';
  lang: 'kr' | 'en' | 'jp';
};
export const makeTextInputHtml = ({
  placeholder,
  lang,
  icon,
  name,
  value,
  length_limit,
  type,
}: TextInputHtmlArg) => html`
  <div
    class="${klass.input_item} ${icon ? klass.with_icon : ''}"
    data-type="${type}"
    ${length_limit !== undefined ? html`data-limit="${length_limit}"` : ''}
  >
    <span class="${klass.input_icon}">${icon || ''}</span>
    ${type == 'text'
      ? html`<input
          data-lang="${lang}"
          class="${klass.form_text}"
          value="${value}"
          name="${name}"
          placeholder="${placeholder}"
          type="text"
          ${length_limit !== undefined ? html`maxlength="${length_limit}"` : ''}
        />`
      : html`<textarea
          data-lang="${lang}"
          name="${name}"
          placeholder="${placeholder}"
          ${length_limit !== undefined ? html`maxlength="${length_limit}"` : ''}
        >
${value}</textarea
        >`}
    ${length_limit !== undefined
      ? html`<span class="${klass.typo_limit}"
          ><span class="current_count">${value?.length || 0}</span> / ${length_limit}자</span
        >`
      : ''}
  </div>
`;

export class FeedProductListPreviewView extends View<
  {
    products: ProductCardData[];
    text?: TextData;
    klass: string;
    is_popupstore: boolean;
    is_mobile: boolean;
    is_horizontal?: boolean;
  } & Pick<ProductCardOption, 'hide_badges' | 'hide_tag'>
> {
  override onMount() {
    const { is_mobile, is_popupstore } = this.data;

    if (!is_popupstore) {
      this.delegate(ProductLikeToggleEvent, ProductCard, async (_, view) => {
        await view.toggleLikeButton();
      });
    }

    const element = this.element();

    if (this.data.is_popupstore) {
      const products_element = element.querySelector('.popup-store-products-preview__list');

      if (!is_mobile && products_element && this.data.products.length > 4) {
        const base = is_mobile ? 10 : 16;
        const toRem = (num) => (num / base) * base;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Swiper(products_element, {
          loop: false,
          spaceBetween: toRem(24),
          slidesPerView: is_mobile ? 'auto' : 4,
          allowTouchMove: true,
          pagination: {
            el: `.popup-store-products-preview__list .swiper-pagination`,
            type: 'progressbar',
          },
        });
      }
    }
  }

  makePopupStoreProductsTmpl() {
    const { text, products } = this.data;

    return runeHtml`
      <div class="${this.data.klass} popup-store-products-preview" contenteditable="false">
        <div class="popup-store-products-preview__text">
          ${
            text?.section_title
              ? runeHtml.preventEscape(`
              <h3 class="popup-store-products-preview__title" contenteditable="false">
                ${text.section_title.replaceAll('\n', '<br/>')}
              </h3>`)
              : ''
          }
          ${
            text?.section_description
              ? runeHtml.preventEscape(`
              <p class="popup-store-products-preview__description" contenteditable="false">
                ${text.section_description.replaceAll('\n', '<br/>')}
              </p>`)
              : ''
          }
        </div>
        
        <div class="popup-store-products-preview__products">
          <div class="popup-store-products-preview__list swiper">
            <div class="swiper-wrapper">
              ${products.map((product) => {
                if (!product) return runeHtml``;
                return runeHtml`
                  <div class="popup-store-products-preview__item swiper-slide">
                    ${new ProductCard(product, {
                      is_mobile: this.data.is_mobile ?? false,
                      theme: PRODUCT_CARD_THEME.dark,
                      resize_width: 326 * 2,
                      type: 'normal',
                    })}
                  </div>
                `;
              })}
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    `;
  }

  makeFeedProductsTmpl() {
    return runeHtml`
      <div class="${this.data.klass} ${this.data.is_horizontal ? 'horizontal' : ''}" >
        ${this.data.products.map((product) => {
          return new ProductCard(product, {
            is_mobile: this.data.is_mobile ?? false,
            resize_width: 500,
            type: 'normal',
            is_horizontal: this.data.is_horizontal ?? false,
            hide_badges: this.data.hide_badges,
            hide_tag: this.data.hide_tag,
          });
        })}
      </div>
    `;
  }

  override template() {
    const { is_popupstore } = this.data;
    return is_popupstore ? this.makePopupStoreProductsTmpl() : this.makeFeedProductsTmpl();
  }
}

class FeedProductAddModalBodyView extends View<{
  products: ProductCardData[];
  max_count: number;
  footer_hidden?: boolean;
}> {
  override template() {
    const { products, max_count, footer_hidden } = this.data;
    return runeHtml`
      <div class="${klass.add_products_modal}">
        <div class="${klass.modal_header}">
          <p class="${klass.section_title} ${typo('14_bold')}">
            상품 추가
            <span class="${klass.section_sub_title} ${typo('12_medium')}">
              최대 ${max_count}개까지 등록할 수 있습니다.
            </span>
          </p>
          <div class="${klass.product_url_input_container}">
            <input class="${klass.product_url_input}" type="text" placeholder="상품의 URL을 입력해 주세요." />
            <button type="button" class="${klass.product_add_button}">${runeHtml.preventEscape(
      plus_icon,
    )}</button>
          </div>
        </div>
        <div class="${klass.add_product_thumbnails}">
          ${products.map(
            (product, idx) => runeHtml`
            <div class="${klass.product_thumbnail_item}" data-id="${product.id}" data-idx="${idx}">
              ${new ProductThumbnail(product, {
                resize_width: 300,
              })}
            </div>
          `,
          )}
        </div>
        ${
          !footer_hidden
            ? runeHtml`
              <div class="${klass.add_products_modal_footer}">
                <button type="button" name="cancel" class="${klass.add_product_footer_button} ${typo(
                '14_medium',
              )}">취소</button>
                  <button type="button" name="save" class="${klass.add_product_footer_button} ${
                klass.blue
              } ${typo('14_medium')}">
                  추가하기
                </button>
              </div>
              `
            : ''
        }
        
      </div>
    `;
  }
}

type FeedProductAddModalContainerViewData = {
  products: ProductCardData[];
  klass: string;
  max_count: number;
};
export class FeedProductAddModalContainerView extends View<FeedProductAddModalContainerViewData> {
  modal: Modal;
  modalBody: FeedProductAddModalBodyView;
  state: { original: ProductCardData[] };

  open() {
    this.refresh();
    this.modal.open();
    this.state.original = this.data.products;
  }

  @on('click', `.${klass.product_add_button}`)
  async addProduct() {
    const input_element = this.element().querySelector(`.${klass.product_url_input}`) as HTMLInputElement;

    if (!input_element.value) return;

    const product_id = marppleProductRegex.exec(input_element.value)?.[1];
    if (!product_id) return alert('잘못된 상품 주소이거나 상품 id를 파악할 수 없습니다.');
    if (this.data.products.length == this.data.max_count)
      return alert(`상품은 최대 ${this.data.max_count}개 까지만 가능합니다.`);

    const { product } = await ProductListApiS.getProductApi(+product_id);
    if (!product) return alert('없는 상품이거나 비공개 혹은 마플샵 공개 심사가 통과되지 않은 제품입니다. ');

    this.data.products = [...this.data.products, product];
    this.refresh();
  }

  @on('click', `.${klass.product_thumbnail_item}`)
  removeProduct(e) {
    this.data.products = this.data.products.filter((product) => product.id != e.currentTarget.dataset.id);
    this.refresh();
  }

  @on('click', `button[name="cancel"]`)
  cancel() {
    this.modal.close();
  }

  @on('click', 'button[name="save"]')
  saveModal() {
    this.state.original = this.data.products;
    this.modal.close();
  }

  @on(ModalClosedEvent)
  onCloseModal() {
    // save 했을 때는 original 을 최종본으로 바꿔놓음, 그외에 닫히는 것들은 모두 초기화
    this.data.products = this.state.original;
  }

  refresh() {
    this.modalBody.data.products = this.data.products;
    this.modalBody.redraw();
  }

  constructor(data: FeedProductAddModalContainerViewData) {
    super(data);

    this.modalBody = new FeedProductAddModalBodyView({ products: data.products, max_count: data.max_count });

    this.state = { original: data.products };

    this.modal = new Modal({
      isOpen: false,
      size: 'free',
      title: '콘텐츠 상품 추가하기',
      body: this.modalBody,
      backdrop_not_close: true,
    });
  }

  override template() {
    return runeHtml`
      <div class="${this.data.klass}">${this.modal}</div> 
    `;
  }
}

// 팝업스토어 상품 추가 모달
type PopupStoreProductAddModalContainerViewData = {
  products: ProductCardData[];
  text: TextData;
  klass: string;
  max_count: number;
};
class PopupStoreProductAddModalBodyView extends View<{
  productAddView: FeedProductAddModalBodyView;
  text_data: TextData;
}> {
  override template() {
    return runeHtml`
      <div class="popup-store-products-embed-modal">
        <div class="popup-store-products-embed-modal__products">
          ${this.data.productAddView}
        </div>
        
        <div class="popup-store-products-embed-modal__texts">
          <div class="popup-store-products-embed-modal__inputs">
            ${runeHtml.preventEscape(
              popupStoreField({
                title: '상품 타이틀',
                description: undefined,
                controls: html`
                  <div class="popup-store-control-group">
                    ${textareaControl({
                      name: 'section_title',
                      value: this.data.text_data?.section_title || '',
                      placeholder: '상품 섹션의 국문 타이틀을 입력해 주세요.',
                      rows: 1,
                      max_length: 50,
                      flag: 'ko',
                    })}
                    ${textareaControl({
                      name: 'section_title_en',
                      value: this.data.text_data?.section_title_en || '',
                      rows: 1,
                      placeholder: '상품 섹션의 영문 타이틀을 입력해 주세요.',
                      max_length: 100,
                      flag: 'en',
                    })}
                    ${textareaControl({
                      name: 'section_title_jp',
                      value: this.data.text_data?.section_title_jp || '',
                      rows: 1,
                      placeholder: '상품 섹션의 일문 타이틀을 입력해 주세요.',
                      max_length: 50,
                      flag: 'jp',
                    })}
                  </div>
                `,
              }),
            )}
            
            ${runeHtml.preventEscape(
              popupStoreField({
                title: '상품 설명',
                description: undefined,
                controls: html`
                  <div class="popup-store-control-group">
                    ${textareaControl({
                      name: 'section_description',
                      value: this.data.text_data?.section_description || '',
                      placeholder: '상품 섹션에 대한 국문 설명을 입력해 주세요.',
                      rows: 1,
                      max_length: 50,
                      flag: 'ko',
                    })}
                    ${textareaControl({
                      name: 'section_description_en',
                      value: this.data.text_data?.section_description_en || '',
                      rows: 1,
                      placeholder: '상품 섹션에 대한 영문 설명을 입력해 주세요.',
                      max_length: 100,
                      flag: 'en',
                    })}
                    ${textareaControl({
                      name: 'section_description_jp',
                      value: this.data.text_data?.section_description_jp || '',
                      rows: 1,
                      placeholder: '상품 섹션에 대한 일문 설명을 입력해 주세요.',
                      max_length: 50,
                      flag: 'jp',
                    })}
                  </div>
                `,
              }),
            )}
          </div>
          <div class="popup-store-products-embed-modal__buttons">
            <button type="button" class="add-products-cancel-btn">취소</button>
            <button type="button" class="add-products-confirm-btn">등록하기</button>
          </div>
        </div>
      </div>
    `;
  }
}
export class PopupStoreProductAddModalContainerView extends View<PopupStoreProductAddModalContainerViewData> {
  popupStoreModalBody: PopupStoreProductAddModalBodyView;
  modalBody: FeedProductAddModalBodyView;
  modal: Modal;
  state: {
    original: ProductCardData[];
    originalTextData: TextData;
  };

  text_data: TextData = {};

  constructor(data: PopupStoreProductAddModalContainerViewData) {
    super(data);

    this.text_data = data.text;
    this.modalBody = new FeedProductAddModalBodyView({
      products: data.products,
      max_count: data.max_count,
      footer_hidden: true,
    });

    this.popupStoreModalBody = new PopupStoreProductAddModalBodyView({
      productAddView: this.modalBody,
      text_data: this.text_data,
    });

    this.state = { original: data.products, originalTextData: {} };

    this.modal = new Modal({
      isOpen: false,
      size: 'free',
      title: '한정 상품 등록',
      body: this.popupStoreModalBody,
      backdrop_not_close: true,
    });
  }

  open() {
    this.refresh();
    this.modal.open();
    this.state.original = this.data.products;
    this.state.originalTextData = this.text_data;
  }

  @on('click', `.${klass.product_add_button}`)
  async addProduct() {
    const input_element = this.element().querySelector(`.${klass.product_url_input}`) as HTMLInputElement;

    if (!input_element.value) return;

    const product_id = marppleProductRegex.exec(input_element.value)?.[1];
    if (!product_id) return alert('잘못된 상품 주소이거나 상품 id를 파악할 수 없습니다.');
    if (this.data.products.length == this.data.max_count)
      return alert(`상품은 최대 ${this.data.max_count}개 까지만 가능합니다.`);

    const { product } = await ProductListApiS.getProductApi(+product_id);

    if (!product) return alert('없는 상품이거나 비공개 혹은 마플샵 공개 심사가 통과되지 않은 제품입니다. ');

    this.data.products = [...this.data.products, product];
    this.refresh();
  }

  @on('click', `.${klass.product_thumbnail_item}`)
  removeProduct(e) {
    this.data.products = this.data.products.filter((product) => product.id != e.currentTarget.dataset.id);
    this.refresh();
  }

  @on('change', '.popup-store-control-group textarea')
  onChangeTextInput(e) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    this.text_data = { ...this.text_data, [name]: value };
  }

  @on('click', `.add-products-cancel-btn`)
  cancel() {
    this.modal.close();
  }

  @on('click', '.add-products-confirm-btn')
  saveModal() {
    this.state.original = this.data.products;
    this.state.originalTextData = { ...this.text_data };
    this.modal.close();
  }

  @on(ModalClosedEvent)
  onCloseModal() {
    this.data.products = this.state.original;
    this.text_data = { ...this.state.originalTextData };
  }

  refresh() {
    this.modalBody.data.products = this.data.products;
    this.popupStoreModalBody.data.text_data = this.text_data;
    this.modalBody.redraw();
    this.popupStoreModalBody.redraw();
  }

  override template() {
    return runeHtml`
      <div class="${this.data.klass}">${this.modal}</div>
    `;
  }
}
