import {
  $addClass,
  $appendTo,
  $closest,
  $delegate,
  $find,
  $findAll,
  $on,
  $remove,
  $removeClass,
} from 'fxdom/es';
import { addAlignClass } from './medium-editor-text-align.js';
import { isLinkEl, showLinkPrompt } from './medium-editor-link-plugin.js';
import { createCustomToolbar } from './common/custom-toolbar.js';
import { calcDistance, highlight_action } from './common/util.js';
import { showAltPrompt } from './medium-editor-alt-plugin.js';

const closeToolbarHandler = ($custom_toolbar) => (e) => {
  const $resize_wrapper = $closest('.resize_wrapper', e.target);
  if ($resize_wrapper) return;
  $removeClass('medium-editor-toolbar-active', $custom_toolbar);
};

export const openToolbar = ($container, $toolbar, distance) => {
  $appendTo($container, $toolbar);
  $addClass('medium-editor-toolbar-active', $toolbar);
  $toolbar.style.left = `${distance.left}px` || '50%';
};

export const createImageToolbar = (editor) => {
  const $editor = editor.elements[0];
  let selected_img$;
  const custom_toolbar$ = createCustomToolbar({
    klass: 'custom_image_toolbar',
    actions: [
      { icon: 'fa fa-align-left', name: 'left-action' },
      { icon: 'fa fa-align-center', name: 'center-action' },
      { icon: 'fa fa-link', name: 'link-action' },
      { icon: 'fa-solid fa-quote-left', name: 'description-action' },
      { icon: 'fa fa-times', name: 'close-action' },
    ],
  });

  const link_action$ = $find('.link-action', custom_toolbar$);

  // close
  $on('click', closeToolbarHandler(custom_toolbar$))(document);

  // open
  $delegate('click', '.image', (e) => {
    if (!editor.isActive) return;
    selected_img$ = $closest('.image', e.target);
    const link$ = $closest('a.image', e.target);
    const $target = link$ || selected_img$;
    const img_container$ = $closest('.img_container', e.target);
    const distance = calcDistance(img_container$, $target);
    highlight_action(!!link$, link_action$);
    openToolbar(img_container$, custom_toolbar$, distance);
    e.originalEvent.preventDefault();
  })($editor);

  // actions
  $delegate('drag', '.image', (e) => {
    $removeClass('medium-editor-toolbar-active', custom_toolbar$);
  })($editor);

  $delegate('click', '.close-action', (e) => {
    $remove(isLinkEl(selected_img$) || selected_img$);
    $removeClass('medium-editor-toolbar-active', custom_toolbar$);
    const $imgs = $findAll('.image', custom_toolbar$.parentNode);
    if (!$imgs.length) $remove($closest('.img_container', custom_toolbar$));
  })(custom_toolbar$);

  $delegate('click', '.center-action', (e) => {
    const $image_container = $closest('.img_container', custom_toolbar$);
    addAlignClass('align-center', $image_container);
    $removeClass('medium-editor-toolbar-active', custom_toolbar$);
  })(custom_toolbar$);

  $delegate('click', '.left-action', (e) => {
    const $image_container = $closest('.img_container', custom_toolbar$);
    addAlignClass('align-left', $image_container);
    $removeClass('medium-editor-toolbar-active', custom_toolbar$);
  })(custom_toolbar$);

  $delegate('click', '.link-action', async (e) => {
    await showLinkPrompt(selected_img$, 'image');
  })(custom_toolbar$);

  $delegate('click', '.description-action', async (e) => {
    const img_container$ = $closest('.img_container', e.target);
    await showAltPrompt(img_container$, selected_img$);
  })(custom_toolbar$);

  return custom_toolbar$;
};
