const reg = /(^[+-]?\d+)(\d{3})/;

export const commify = function (n?: number | string): string {
  if (!n && n != 0) return '';

  n = n.toString();

  const s_n = n.split('.');

  n = s_n[0];

  const n2 = s_n[1];

  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

  return !n2 ? n : [n, n2.slice(0, 2)].join('.');
};
