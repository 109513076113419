import { MShopStudioEcommerceInfoConstantS } from '../Constant/module/MShopStudioEcommerceInfoConstantS.js';

/**
 * @param {string} ecommerce_type
 * @return {boolean}
 */
export const isValidEcommerceType = (ecommerce_type) => {
  return (
    ecommerce_type === MShopStudioEcommerceInfoConstantS.types.person ||
    ecommerce_type === MShopStudioEcommerceInfoConstantS.types.company
  );
};
