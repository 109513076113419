/*
 * 크리에이터 위키 개발 이후 사용
 */
import { html } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { FilterIcon } from '../../../atoms/Icon';
import { FloatingButtonBase } from './FloatingButtonBase';
import { listenDocumentEvent } from '../../../../../shared/util/event';
import { makeFloatingButtonTemplate } from '../util';

export type FloatingButtonFilterData = {
  active: boolean;
};

export class FloatingButton_filter extends FloatingButtonBase<FloatingButtonFilterData & { show?: boolean }> {
  // constructor(data) {
  //   super(data);
  // }

  override onMount() {
    listenDocumentEvent('floating_bot_button_filter_active', (e) => {
      this.data.active = e.detail.active;
      this.redraw();
    });
  }

  override template() {
    return makeFloatingButtonTemplate(
      html`<div class="${klass.floating_button}">
        <span class="${klass.button_icon}"> ${FilterIcon} </span>
        <span class="${klass.button_blue_dot}" data-active="${this.data.active}"></span>
      </div> `,
      this.state.show_itself,
    );
  }
}
