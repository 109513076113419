import { $closest, $delegate, $on } from 'fxdom/es';
import { go } from 'fxjs/es';
import {
  removeEmbedToolbarHandler,
  openEmbedToolbarHandler,
  closeToolbar,
  closeToolbarHandler,
} from './common/embed.js';
import { createCustomToolbar } from './common/custom-toolbar.js';
import { openMarppleEmbedEditor } from '../custom-insert-plugin/marpple.js';

export const initMarppleEmbedToolbar = (editor, is_popupstore) => {
  const $editor = editor.elements[0];
  const custom_toolbar$ = createCustomToolbar({
    klass: 'custom_embed_toolbar',
    actions: [
      { icon: 'fas fa-pen', name: 'edit-action' },
      { icon: 'fas fa-times', name: 'close-action' },
    ],
  });

  // close

  $on('click', closeToolbarHandler(custom_toolbar$))(document);

  // open
  $delegate(
    'click',
    '.embed_show.marpple_embed_show',
    openEmbedToolbarHandler(true, custom_toolbar$, editor),
  )($editor);

  // actions
  go(
    custom_toolbar$,
    $delegate('click', '.close-action', removeEmbedToolbarHandler),
    $delegate('click', '.edit-action', (e) => {
      const prev_embed_show$ = $closest('.embed_show', e.target);
      closeToolbar(custom_toolbar$);
      openMarppleEmbedEditor(editor, prev_embed_show$, is_popupstore);
    }),
  );

  return custom_toolbar$;
};
