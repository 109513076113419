import { $findAll, $remove, $siblings } from 'fxdom/es';
import { each, go, head } from 'fxjs/es';
import tippy from 'tippy.js';
import { MShopCoreConstantS } from '../../MShop/Core/S/Constant/module/MShopCoreConstantS.js';

const tootipSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
    <g fill="none" fill-rule="evenodd">
      <g fill="#FFF" stroke="${MShopCoreConstantS.colors.gray_800}">
        <path d="M1 6L5 0 9 6"
              transform="translate(-1604 -233) translate(1531 213) translate(73 20) matrix(-1 0 0 1 10 0)"/>
      </g>
    </g>
  </svg>
`;

// const tooltipSvg = `
//   <svg width="16" height="6">
//     <path class="svg-arrow" d="M0 6s1.796-.013 4.67-3.615C5.851.9 6.93.006 8 0c1.07-.006 2.148.887 3.343 2.385C14.233 6.005 16 6 16 6H0z"/>
//     <path class="svg-content" d="m0 7s2 0 5-4c1-1 2-2 3-2 1 0 2 1 3 2 3 4 5 4 5 4h-16z"/>
//   </svg>
// `;

export const initTooltips = ($tab_el, custom = {}) => {
  go(
    $tab_el,
    $findAll('.tooltip-container'),
    each(($container) => {
      const $icon = head($siblings('.tooltip-icon', $container));
      tippy($icon, {
        content: $container.outerHTML,
        arrow: tootipSvg,
        allowHTML: true,
        placement: 'bottom-start',
        offset: [-100, 10],
        maxWidth: 'none',
        interactive: true,
        appendTo: document.body,
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 10000);
        },
        ...custom,
      });
    }),
    each($remove),
  );
  return $tab_el;
};

// tooltip-icon2
export const initTooltips2 = ({ container_el, custom = {} }) => {
  go(
    container_el,
    $findAll('.tooltip-container'),
    each(($container) => {
      const $icon = head($siblings('.tooltip-icon2', $container));
      tippy($icon, {
        content: $container.outerHTML,
        // arrow: tootipSvg,
        allowHTML: true,
        placement: 'bottom-start',
        offset: [-100, 10],
        maxWidth: 'none',
        interactive: true,
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 10000);
        },
        ...custom,
      });
    }),
    each($remove),
  );
  return container_el;
};

export const initMpsTooltips = ({ container_el, custom = {} }) => {
  go(
    container_el,
    $findAll('.mps-tooltip-container'),
    each(($container) => {
      const $icon = head($siblings('.mps-tooltip-icon', $container));
      tippy($icon, {
        content: $container.outerHTML,
        trigger: 'click',
        hideOnClick: true,
        allowHTML: true,
        placement: 'bottom-start',
        offset: [-100, 10],
        maxWidth: 'none',
        interactive: true,
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 10000);
        },
        ...custom,
      });
    }),
    each($remove),
  );
  return container_el;
};
