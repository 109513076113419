import { html, View } from 'rune-ts';
import klass from './FloatingBottomContainer.module.scss';
import { typo } from '../../../../shared/typography/typo';
import { FloatingBox, FloatingBoxData } from '../FloatingBox/FloatingBox';
import { BottomTabBar } from '../BottomTabBar/BottomTabBar';
import { Locals } from '../../../../shared/type/locals';
import { bottomTabBarSafetyAreaHtml } from '../../../../shared/util/helper';

export interface FloatingBottomContainerData {
  floating_box: FloatingBoxData;
  bottom_tab_bar: {
    on: boolean;
    selected_idx: number;
  };
  app_info: Locals['webviewapp'];
  is_mobile: boolean;
  is_logged_in: boolean;
}

interface FloatingBottomContainerOption {
  klass?: string;
  bottom?: string; // css size value, 위로 밀고 싶은 경우 넣기
}

export class FloatingBottomContainer extends View<FloatingBottomContainerData> {
  state: FloatingBottomContainerOption & {
    bottom: string;
  };

  floating_box: FloatingBox;

  constructor(data: FloatingBottomContainerData, option: FloatingBottomContainerOption = {}) {
    super(data);

    this.state = {
      klass: option.klass,
      bottom: option.bottom || '0',
    };

    this.floating_box = new FloatingBox(data.floating_box);
  }

  override template() {
    const { is_mobile, bottom_tab_bar, is_logged_in, app_info } = this.data;
    const is_app = !!app_info;

    return html`<div class="${this.state.klass} ${klass.floating_bottom_container} ${typo('14_medium')}">
      <div class="${klass.floating_part}" style="bottom: ${this.state.bottom};">
        ${this.floating_box}

        <!-- browser bottom tab bar : 앱일때는 앱에서 그림 -->
        ${!is_app && is_mobile && bottom_tab_bar.on
          ? new BottomTabBar({ selected_tab_idx: bottom_tab_bar.selected_idx, is_logged_in })
          : ''}

        <!-- bottom tab bar + safe area 만큼 미는 함수인데, browser 에서 그리는 경우에는 아래 함수에서 safe area 만 밀어야 함  -->
        ${bottomTabBarSafetyAreaHtml({
          app_info,
          has_bottom_tab_bar: false, // app 일때는 내부에서 tab screen 일때만 tab bar 만큼 밀거고, 브라우저일 때는 tab bar 안밀어야 해서 무조건 false
          is_mobile,
          is_rune: true,
        })}
      </div>

      <!--  relative 영역에서 bottom tab bar 있으면 있는 만큼 마진 생성    -->
      ${bottom_tab_bar.on ? html`<div style="margin-bottom: ${BottomTabBar.getHeight()}px"></div>` : ''}
    </div> `;
  }
}
