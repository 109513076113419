import { getResizedImage100WebpUrl } from './getResizedImageUrl';
import { BottomTabBar } from '../../renderApp/components/cells/BottomTabBar/BottomTabBar';
import { html as fxHtml } from 'fxjs/es';
import { html } from 'rune-ts';

export const makeDefaultProfileImg = (width: number) => {
  return getResizedImage100WebpUrl(
    '//s3.marpple.co/files/u_2283830/2024/4/original/66bc95a0c03a6e46c874809d11e592760e7423ae1.png',
    width,
  );
};

export const bottomTabBarSafetyAreaHtml = ({ is_rune, is_mobile, has_bottom_tab_bar, app_info }) => {
  const htmlFn = is_rune ? html : fxHtml;
  return !!app_info || (is_mobile && has_bottom_tab_bar)
    ? htmlFn`<div
        style="margin-bottom: ${
          ((app_info && app_info?.safe_area_bottom) || 0) +
          (has_bottom_tab_bar || app_info?.is_tab_screen ? BottomTabBar.getHeight() : 0)
        }px;"
      ></div>`
    : '';
};

export const parseCustomEvent = <T extends { name: string }>(customEvent: T): string => {
  return customEvent.name.replace(/([a-z])([A-Z])/g, '$1:$2').toLowerCase();
};
