import { addCvImage } from '../../../../../Maker/F/CvImage/fs.js';
import { getCurrentFcanvas } from '../../../../../Maker/F/Fcanvas/cv_object.js';
import { BpOptionConstantS } from '../../../../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerFitPrintWidthF } from '../../../../FitPrintWidth/F/Function/module/NewMakerFitPrintWidthF.js';
import { BpOptionF } from '../../../../../BpOption/F/Function/module/BpOptionF.js';
import { OMPDosuF } from '../../../../../OMP/Dosu/F/Function/module/OMPDosuF.js';
import { NewMakerPropertyBaseProductF } from '../../../../Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { NewMakerModalConfirmF } from '../../../../Modal/Confirm/F/Function/module/NewMakerModalConfirmF.js';
import { getBaseProductInMaker } from '../../../../../Maker/F/getSth.js';
import { NewMakerCallConditionPopupF } from '../../../../CallConditionPopup/F/Function/module/NewMakerCallConditionPopupF.js';
import { NewMakerProductStyleS } from '../../../../ProductStyle/S/Function/module/NewMakerProductStyleS.js';

export const addCvImageToMpMaker = async (image) => {
  const is_ai_not_support_bp = NewMakerProductStyleS.isAiNotSupportedBp(getBaseProductInMaker());
  if (is_ai_not_support_bp && !image.image_color_id && !!image.original_url) {
    /* ai 서포트 안하는 상품의 경우, 유저의 이미지의 경우, 오리지날 이미지는 쓸수 없다. (original_url -> ai or tiff(?))*/
    $.alert(
      T('maker::This product does not support ai file. Please convert the file into png or jpeg format.'),
    );
    return;
  }
  const cv_image = await addCvImage({
    image,
    before_add_to_fcanvas: (cv_image) => {
      const fcanvas = getCurrentFcanvas();
      if (
        !G.mp.maker.is_asset_studio &&
        fcanvas?.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR
      ) {
        NewMakerFitPrintWidthF.activateFitToPrintWidth(cv_image, fcanvas);
      }
    },
  });
  const canvas = G.mp.maker.editing_canvas();
  const dosu_ctn = BpOptionF.biz.get.currentFace.maxDosuCount(canvas.bpf_id);
  if (cv_image && dosu_ctn) {
    const dosu_confirm = await OMPDosuF.dosu_confirm.isMoreThanLimitedDosu(canvas.bpf_id, [cv_image]);
    if (dosu_confirm !== undefined) {
      if (dosu_confirm) {
        await G.mp.maker.modified();
        await NewMakerPropertyBaseProductF.linkToBpId.goTo();
      } else {
        cv_image.remove();
      }
      $.don_loader_end();
      return;
    }
  }
  if (cv_image) {
    await G.mp.maker.modified();
    NewMakerCallConditionPopupF.setAndRender(cv_image);
  }
  setTimeout(() => {
    NewMakerModalConfirmF.openConfirmModal(getBaseProductInMaker());
  }, 50);
  return cv_image;
};
