import { strMap, html } from 'fxjs/es';
import { MShopAppTodayTmplS } from '../../../Today/S/Tmpl/module/MShopAppTodayTmplS.js';
import $dataStr from 'fxdom/es/dataStr.js';

export const mdPick = (title, mds, is_mobile) => {
  const category_id = mds[0]?.feed_category_id;
  const today_item_htmls = strMap(
    (md) =>
      MShopAppTodayTmplS.makeTodayItem(md, {
        not_lazy: true,
        klass: 'app-home-mdpick__item swiper-slide',
        category_query: category_id,
      }),
    mds,
  );

  const post_message_option = {
    screen: {
      type: 'util',
      title: T('mshop::Menu::Contents'),
      uri: `/@/todays_frame?feed_category_id=${category_id}`,
    },
  };

  return html`
    <div class="app-home-mdpick">
      <a
        class="app-home__more-anchor app-home__more-anchor--arrow"
        href="/@/todays_frame?feed_category_id=${mds[0].feed_category_id}"
        data-post-message="${$dataStr(post_message_option)}"
        ><div>MD'S PICK</div></a
      >
      <h2 class="app-home__mid-title">${title}</h2>
      <div class="app-home-mdpick__items">
        ${is_mobile ? html`<div class="swiper-wrapper">${today_item_htmls}</div>` : today_item_htmls}
      </div>
    </div>
  `;
};
