import { $addClass, $delegate, $find, $qs, $removeClass, $trigger } from 'fxdom/es';
import { go } from 'fxjs/es';
import { ComponentsInputConstantS } from '../../../../../Components/Input/S/Constant/module/ComponentsInputConstantS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { creatorPriceOfProduct } from '../../../../../ProductColorPrice/S/fs.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { PreDiscountPriceInterval } from '../../S/Constant/preDiscountConst.js';
import { MShopStudioPreDiscountProductS } from '../../S/Function/module/MShopStudioPreDiscountProductS.js';
import { calcDiscountedRateByPercent } from '../../S/Function/preDiscountProduct.js';

const component = 'pre-discount-component';

export const initPreDiscountPriceFEvents = (tab_el) => {
  if (!MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
    return;
  }

  return go(
    tab_el,
    $delegate('change', '#pre-discount-checkbox', function togglePreDiscountPriceForm(e) {
      const is_checked = e.currentTarget.checked;
      const pre_discount_settings_el = $find('.pre-discount-settings', tab_el);

      if (is_checked) {
        $removeClass('hidden', pre_discount_settings_el);
      } else {
        $addClass('hidden', pre_discount_settings_el);
        const pre_discount_price_el = $find('#pre-discount-price', tab_el);
        const discounted_rate_el = $find('#discounted-rate', tab_el);

        // 마케팅 가격 flush
        pre_discount_price_el.value = '';
        updateInputSuffix({
          input_el: pre_discount_price_el,
          message: null,
        });

        // 할인률 flush
        discounted_rate_el.value = '';
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: null,
        });
      }
    }),
    $delegate('input', '#pre-discount-price', function handleInputPreDiscountPrice(e) {
      const pre_discount_price = PriceS.price(e.currentTarget.value);
      const min_pre_discount_price = PriceS.price(e.currentTarget.min);
      const price = PriceS.price(e.currentTarget.dataset.price);
      const is_pod = e.currentTarget.dataset.is_pod === 'true';

      const pre_discount_price_el = e.currentTarget;
      const discounted_rate_el = $find('#discounted-rate', tab_el);

      // 마케팅 가격 업데이트
      // 3자리마다 콤마 추가
      e.currentTarget.value = pre_discount_price > 0 ? UtilS.commify(pre_discount_price) : '';

      // 올바른 값이 아닐 때
      if (Number.isNaN(parseInt(pre_discount_price))) {
        updateInputSuffix({
          input_el: pre_discount_price_el,
          message: null,
        });
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: null,
        });
        return;
      }

      // 최소 가격 미만일 때
      if (pre_discount_price < min_pre_discount_price) {
        // 마케팅 가격에 에러 메세지 표시
        updateInputSuffix({
          input_el: pre_discount_price_el,
          message: is_pod
            ? T('마케팅 가격은 소비자 가격에서 최소 100원 이상이어야 합니다.')
            : T('마케팅 가격은 상품 가격에서 최소 100원 이상이어야 합니다.'),
          type: 'error',
        });
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: null,
        });
        return;
      }

      // 100원 단위 아닐 때
      if (pre_discount_price % 100) {
        updateInputSuffix({
          input_el: pre_discount_price_el,
          message: T('마케팅 가격은 100원 단위로 입력해야 합니다.'),
          type: 'error',
        });
      } else {
        updateInputSuffix({
          input_el: pre_discount_price_el,
          message: null,
        });
      }

      // 마케팅 할인률 업데이트
      const discounted_rate_by_percent = calcDiscountedRateByPercent(pre_discount_price, price);
      // 1. 숫자가 아님
      if (Number.isNaN(discounted_rate_by_percent)) {
        discounted_rate_el.value = '';
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: null,
        });
        // 2. 1% 미만
      } else if (discounted_rate_by_percent < 1) {
        discounted_rate_el.value = '-';
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: T('1% 미만의 할인률은 화면에 표기되지 않습니다.'),
          type: 'info',
        });
      } else {
        discounted_rate_el.value = discounted_rate_by_percent;
        updateInputSuffix({
          input_el: discounted_rate_el,
          message: null,
        });
      }
    }),
    $delegate('change', '#pre-discount-price', function autoFixPreDiscountPrice(e) {
      const min_price = PriceS.price(e.currentTarget.min);
      const pre_discount_price = PriceS.price(e.currentTarget.value);

      const auto_fixed_price = getAutoFixedPreDiscountPrice(pre_discount_price, min_price);
      e.currentTarget.value = Number.isNaN(auto_fixed_price) ? '' : UtilS.commify(auto_fixed_price);
      $trigger('input', e.currentTarget); // input 트리거해서 suffix update
    }),
    $delegate('keydown', '#pre-discount-price', function updatePreDiscountPriceByArrowUpAndDown(e) {
      if (
        e.code !== ComponentsInputConstantS.KEY_CODE.ARROW_UP &&
        e.code !== ComponentsInputConstantS.KEY_CODE.ARROW_DOWN
      ) {
        return;
      }
      e.originalEvent.preventDefault();
      const diff =
        e.code === ComponentsInputConstantS.KEY_CODE.ARROW_UP
          ? PreDiscountPriceInterval
          : -1 * PreDiscountPriceInterval;
      e.currentTarget.value = Math.max(0, PriceS.add(e.currentTarget.value, diff));
      $trigger('input', e.currentTarget);
    }),
  );
};

const updateInputSuffix = ({ input_el, message, type = 'info' } = {}) => {
  if (!input_el) {
    throw new Error(`input_el is required.`);
  }

  const suffix_el = $qs(`.${component}__input-suffix[data-input_id="${input_el.id}"]`);
  if (!suffix_el) {
    throw new Error(`suffix_el is invalid.`);
  }
  suffix_el.innerText = message || '';
  suffix_el.dataset.type = type;
  input_el.dataset.suffix_type = type;
};

/**
 * @param pre_discount_price
 * @param min_price
 * @return {number|NaN}
 */
const getAutoFixedPreDiscountPrice = (pre_discount_price, min_price) => {
  if (pre_discount_price < min_price) {
    return min_price;
  }
  return Math.round(pre_discount_price / 100) * 100;
};

export const setPreDiscountPriceAndRateForPOD = (tab_el) => {
  if (!MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
    return;
  }

  const pre_discount_checkbox_el = $find('#pre-discount-checkbox', tab_el);
  const pre_discount_price_el = $find('#pre-discount-price', tab_el);
  const price = creatorPriceOfProduct(tab_el.tab_opt.product_color);

  pre_discount_price_el.dataset.price = price;
  pre_discount_price_el.min = PriceS.add(price, 100);

  if (pre_discount_checkbox_el.checked) {
    $trigger('input', pre_discount_price_el);
  }
};

export const calculatePreDiscountPriceAndRateForPB = (tab_el, changeEvent) => {
  const price_input_el = changeEvent.currentTarget;
  const pre_discount_checkbox_el = $find('#pre-discount-checkbox', tab_el);
  const pre_discount_price_el = $find('#pre-discount-price', tab_el);
  const price = PriceS.price(price_input_el.value);

  pre_discount_price_el.dataset.price = price;
  pre_discount_price_el.min = PriceS.add(price, 100);

  if (pre_discount_checkbox_el.checked) {
    $trigger('input', pre_discount_price_el);
  }
};
