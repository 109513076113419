import { compact, go, join } from 'fxjs/es';

export function isStringIncludedToArray(str, array) {
  const lowerCaseSubstring = str.toLowerCase();

  return array.some((item) => lowerCaseSubstring.includes(item.toLowerCase()));
}

// @description 문자열을 chunkSize 단위로 쪼개서 원하는 문자열을 삽입해서 돌려주는 함수
export function insertHyphens({ input_str, chunk_size, insert_str = '-' }) {
  const regexPattern = new RegExp(`.{1,${chunk_size}}`, 'g');
  const parts = input_str.match(regexPattern);

  if (!parts) {
    return input_str;
  }

  return parts.join(insert_str);
}

// @description 문자열의 첫번째와 마지막 문자를 추출
export function getFirstAndLastCharacter({ str }) {
  if (str == null || str.length === 0 || typeof str !== 'string') {
    return { first: null, last: null };
  }

  return {
    first: str[0],
    last: str[str.length - 1],
  };
}

export function generateRandomNumberStringWithoutZero({ length }) {
  let result = '';
  const characters = '123456789';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

// @description string 이 한국어로만 구성되어 있는지 확인
export function hasKorean({ input_str }) {
  const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
  return koreanRegex.test(input_str);
}

// @description 문자열에서 숫자만 추출해서 반환
export function extractNumbersFromString(inputString) {
  if (inputString == null) return '';
  return inputString.replace(/\D+/g, '');
}

// @description 문자열이 숫자로만 이루어져 있는지 체크
export function isNumericString(inputString) {
  const numberPattern = /^[-+]?(\d+(\.\d*)?|\.\d+)$/;
  return numberPattern.test(inputString);
}

export function isString(val) {
  return typeof val === 'string';
}

export function isEmNil(str) {
  return str == null || str === '';
}

export function isNotEmpty(str) {
  return !isEmNil(str);
}

export function isValidHttpUrlStr(str) {
  return /^(http|https):\/\/[^ "]+$/.test(str);
}

export function removeSpaces(str) {
  return str.replace(/\s/g, '');
}

export function removeSpecialCharacter(str) {
  const pattern = /[^\w\s]/gi;
  return str.replace(pattern, '');
}

export function sanitizeInputEnglishOnly(input_string) {
  const is_valid = /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+$/.test(input_string);

  if (!is_valid) {
    return input_string.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\/-]+/g, '');
  }
  return input_string;
}

export function formatPhoneNumber(phoneNumber) {
  // 전화번호에서 숫자만 추출
  const numbersOnly = phoneNumber.replace(/\D/g, '');

  // 전화번호 길이에 따라 형식 결정
  if (numbersOnly.length === 11) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 7)}-${numbersOnly.slice(7, 11)}`;
  } else if (numbersOnly.length === 10) {
    return `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 6)}-${numbersOnly.slice(6, 10)}`;
  } else if (numbersOnly.length === 8) {
    return `${numbersOnly.slice(0, 4)}-${numbersOnly.slice(4, 8)}`;
  } else {
    // 다른 길이의 전화번호는 그대로 반환
    return phoneNumber;
  }
}

export function formatWaybillNumber(waybillNumber) {
  if (waybillNumber.toString().length === 12) {
    return waybillNumber.toString().replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    // 12자리가 아닌 경우 그대로 반환
    return waybillNumber;
  }
}

export function joinArrStr(strArr, delimiter) {
  return go(strArr, compact, join(delimiter ?? '_'));
}

export function emptyStringToNullAndTrim(str) {
  if (typeof str !== 'string') return str;

  return str === '' ? null : str.trim();
}

export function removeExtension(filename) {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return filename;
  } else {
    return filename.slice(0, lastDotIndex);
  }
}

// Mapping object for Korean keyboard letters to English counterparts
const KR_TO_EN_KEYBOARD_MAP = {
  ㅂ: 'q',
  ㅃ: 'Q',
  ㅈ: 'w',
  ㅉ: 'W',
  ㄷ: 'e',
  ㄸ: 'E',
  ㄱ: 'r',
  ㄲ: 'R',
  ㅅ: 't',
  ㅆ: 'T',
  ㅛ: 'y',
  ㅕ: 'u',
  ㅑ: 'i',
  ㅐ: 'o',
  ㅒ: 'O',
  ㅔ: 'p',
  ㅖ: 'P',
  ㅁ: 'a',
  ㄴ: 's',
  ㅇ: 'd',
  ㄹ: 'f',
  ㅎ: 'g',
  ㅗ: 'h',
  ㅓ: 'j',
  ㅏ: 'k',
  ㅣ: 'l',
  ㅋ: 'z',
  ㅌ: 'x',
  ㅊ: 'c',
  ㅍ: 'v',
  ㅠ: 'b',
  ㅜ: 'n',
  ㅡ: 'm',
};

export function convertKrToEnKeyboardInput(kr_input) {
  let eng_output = '';
  let is_shifted = false;
  for (const char of kr_input) {
    // Check if the character is uppercase
    if (
      char === 'ㅂ' ||
      char === 'ㅈ' ||
      char === 'ㄷ' ||
      char === 'ㄱ' ||
      char === 'ㅅ' ||
      char === 'ㅛ' ||
      char === 'ㅕ' ||
      char === 'ㅑ' ||
      char === 'ㅐ' ||
      char === 'ㅔ' ||
      char === 'ㅃ' ||
      char === 'ㅉ' ||
      char === 'ㄸ' ||
      char === 'ㄲ' ||
      char === 'ㅆ' ||
      char === 'ㅒ' ||
      char === 'ㅖ'
    ) {
      is_shifted = true;
    } else {
      is_shifted = false;
    }

    if (char in KR_TO_EN_KEYBOARD_MAP) {
      if (is_shifted) {
        eng_output += KR_TO_EN_KEYBOARD_MAP[char.toUpperCase()];
      } else {
        eng_output += KR_TO_EN_KEYBOARD_MAP[char];
      }
    } else {
      eng_output += char;
    }
  }
  return eng_output;
}

export function isNotEngNorSpecial(input) {
  // eslint-disable-next-line no-control-regex
  const pattern = /[^\x00-\x7F]|[^a-zA-Z0-9\s*.,_-]/;
  return pattern.test(input);
}

/**
 * @param {string} text - 처리할 스토어 이름
 * @param {number} len - 제한할 문자열 길이
 * @returns {string} - 제한된 이름 (넘치면 ... 붙여줌),
 */
export function truncateStr(text, len) {
  if (text.length <= len) {
    return text;
  } else {
    return text.slice(0, len) + '...';
  }
}

/**
 * 두 문자열을 공백 제거 및 소문자 변환 후 비교
 * @param {string} str1 - 첫번째 문자열
 * @param {string} str2 - 두번째 문자열
 * @returns {boolean} - 문자열이 동일하면 true, 다르면 false
 */
export function compareStrings(str1, str2) {
  // 타입 체크
  if (typeof str1 !== 'string' || typeof str2 !== 'string') {
    return false;
  }

  // 공백 제거 후 소문자 변환하여 비교
  return str1.replace(/\s+/g, '').toLowerCase() === str2.replace(/\s+/g, '').toLowerCase();
}
