import axios from 'axios';
import {
  BestProductListFetchOption,
  PaginatedProductListResponse,
  ProductCardData,
  ProductCardListData,
  ProductIncludeOption,
  ProductResponse,
  ShopFilterOption,
  ShopProductListFetchOption,
  ShopRetrieveOption,
  UserLikedProductListFetchOption,
} from '../type';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS';

export const getPaginatedProductListApi = async (
  option: ShopProductListFetchOption,
): Promise<PaginatedProductListResponse> => {
  const { data } = await axios.get<PaginatedProductListResponse>(`/${T.lang}/@api/products/page`, {
    params: option,
  });
  return data;
};

export const getProductListApi = async (
  option: ShopFilterOption & ShopRetrieveOption,
): Promise<ProductCardListData> => {
  const { data } = await axios.get<ProductCardListData>(`/${T.lang}/@api/products`, {
    params: option,
  });
  return data;
};

/**
 * `products.id`로 상품 하나만 조회하는 api 입니다.
 */
export const getProductApi = async (id: number, option?: ProductIncludeOption): Promise<ProductResponse> => {
  const { data } = await axios.get<ProductResponse>(`/${T.lang}/@api/products/${id}`, {
    params: option,
  });
  return data;
};

const getUserLikeUrl = () => {
  if (UtilS.isMarppleShopApp()) {
    return `/${T.lang}/@api/user_like_products`;
  }
  if (UtilS.isNessApp()) {
    return `/${T.lang}/@api/my_like/products`;
  }

  // 나중에 서비스 또 추가되면 활용하게 될 것 같으나 우선 이렇게..
  return `/${T.lang}/@api/my_like/products`;
};

export const getUserLikeProductListApi = async (
  option: UserLikedProductListFetchOption,
): Promise<ProductCardData[]> => {
  const { data } = await axios.get<ProductCardListData>(getUserLikeUrl(), {
    params: option,
  });

  return data.products || data;
};

export const getBestProductListApi = async (
  option: BestProductListFetchOption,
): Promise<ProductCardData[]> => {
  const { data: best_products } = await axios.get<ProductCardData[]>(`/${T.lang}/@api/best_products`, {
    params: option,
  });
  return best_products;
};
