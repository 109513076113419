import { $delegate, $hide, $qs, $removeClass, $setCss, $show } from 'fxdom/es';
import { GoodsTypeS } from '../../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopAppProductDetailMobileOptionFormMuiS } from '../../S/Mui/module/MShopAppProductDetailMobileOptionFormMuiS.js';
import { page } from './page.js';
import { go } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
/*
 * 프론트엔드에서 사용될 frame 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#b847a14a76274bff9aaa6d549c5bef88
 * */
const is_mobile = MShopUtilF.isMobile();

export const frame = {
  ...MShopAppProductDetailMobileOptionFormMuiS.frame, // S의 Mui에서 프레임옵션을 가져옵니다.

  header_height: 58,
  root_page: page, // /F/page 옵션객체 - 필수

  height: undefined, // 모바일 전용, frame 높이
  prev_frame_show: true, // openFrame시에 바로 하단의 frame을 html에서 삭제안함
  always_remove: false, // closeFrame 때 무조건 삭제
  animation: true, // frame을 열거나 닫을때 애니메이션 (기본 - ios면 false 이외 true)
  is_modal: !is_mobile, // frame 을 모달로 열때
  appending(frame_el$) {}, // frame 엘리먼트가 만들어지면 울리는 함수
  appended(frame_el$) {}, // frame 엘리먼트가 html에 붙으면 울리는 함수
  showing() {
    setTimeout(() => {
      $hide($qs('.pd-submit__action'));
      $hide($qs('.pd-submit__btn--form'));
      if ($qs('.pd-submit__btn[data-action="cart"]')) {
        $show($qs('.pd-submit__btn[data-action="cart"]'));
      }
      const directBtn$ = $qs('.pd-submit__btn[data-action="checkout"]');
      if (directBtn$) {
        $show(directBtn$);
      }
    }, 100);
  }, // frame 엘리먼트 show하기 전 울리는 함수
  showed(frame_el$) {
    go(
      frame_el$,
      $delegate('click', '.pd-form-modal__close', () => {
        setDataMobileFormHeight(frame_el$);
        const goods_type_id = box.sel('product_detail->stores_product->goods_type_id');

        if (GoodsTypeS.isPbg(goods_type_id) || GoodsTypeS.isTpl(goods_type_id)) {
          MuiF.closeFrame();
        } else {
          MuiF.removeFrame();
        }
      }),
    );

    requestAnimationFrame(() => {
      $setCss({ height: 'auto' }, frame_el$);
    });
  }, // frame 엘리먼트 show하고 나서 울리는 함수
  /* frame을 닫을때 울리는 함수 */
  closing() {
    setTimeout(() => {
      $removeClass('open', $qs('.pd-submit'));
      $show($qs('.pd-submit__action'));
      $show($qs('.pd-submit__btn--form'));
      if ($qs('.pd-submit__btn[data-action="cart"]')) {
        $hide($qs('.pd-submit__btn[data-action="cart"]'));
      }
      const directBtn$ = $qs('.pd-submit__btn[data-action="checkout"]');
      if (directBtn$) {
        $hide(directBtn$);
      }
    }, 300);
  }, // frame이 닫히기 전 울리는 함수
  hiding(frame_el$, val) {}, // frame이 가려지기 전 울리는 함수
  hided(frame_el$, val) {}, // frame이 가려진 후 울리는 함수
  removing(frame_el$, val) {}, // frame이 삭제되기 전 울리는 함수
  removed(frame_el$, val) {}, // frame이 삭제된 후 울리는 함수
  closed(frame_el$, val) {
    setTimeout(() => {
      MShopUtilF.bodyFixed$(false);
    }, 100);
  }, // frame이 닫힌 후 울리는 함수
};

/* 멀티 옵션에 의해 변화하는 height를 저장하기 위함 */
const setDataMobileFormHeight = (form_el) => {
  if (!MShopUtilF.isMobile()) return;
  form_el.frame_opt.height = form_el.getBoundingClientRect().height;
};
