import { $qs } from 'fxdom/es';
import { debounce } from 'fxjs/es';

/**
 *
 * @param {HTMLElement[]} icon_els
 * @param {string} color
 */
export const changeIconColor = (icon_els, color) => {
  icon_els.forEach((icon_el) => {
    icon_el.dataset.color = color;
  });
};

export const initTopArrowButton = () => {
  const component = 'ness-layout-top-arrow';
  const upper_trigger_el = $qs(`.${component}__upper-trigger`);
  const trigger_el = $qs(`.${component}__trigger`);
  const btn_el = $qs(`.${component}__btn`);

  const displayButton = () => {
    const should_show = window.scrollY > window.innerHeight;
    btn_el.style.opacity = should_show ? '1' : '0';
  };

  const debouncedShow = debounce(displayButton, 100);

  const observer = new IntersectionObserver(
    () => {
      displayButton();
      debouncedShow();
    },
    {
      rootMargin: '0px',
    },
  );

  const upper_observer = new IntersectionObserver(
    () => {
      displayButton();
      debouncedShow();
    },
    {
      rootMargin: '80%',
    },
  );

  observer.observe(trigger_el);
  upper_observer.observe(upper_trigger_el);

  btn_el.addEventListener('click', () => {
    window.scrollTo(0, 0);
  });
};
