import MetaMaskOnboarding from '@metamask/onboarding';
import axios from 'axios';
import { $addClass, $off, $on, $qs, $qsa, $remove, $removeClass, $setHTML, $setScrollTop } from 'fxdom/es';
import { delay, each, go } from 'fxjs/es';
import { MuiF } from '../../../../../../Mui/F/Function/module/MuiF.js';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { submitDisabledBtn } from '../../../../ProductDetail/S/Tmpl/partials/productDetailForm.js';
import { MShopAppTokenGateStoresProductConstantS } from '../../S/Constant/module/MShopAppTokenGateStoresProductConstantS.js';
import { MShopAppTokenGateStoresProductS } from '../../S/Function/module/MShopAppTokenGateStoresProductS.js';
import { MShopAppTokenGateStoresProductTmplS } from '../../S/Tmpl/module/MShopAppTokenGateStoresProductTmplS.js';
import { MShopAppTokenGateStoresProductMuiF } from '../Mui/module/MShopAppTokenGateStoresProductMuiF.js';

export const certifyNft = async (stores_product, e) => {
  if (e.currentTarget.disabled) return;
  try {
    if (MShopUtilF.isMobile() && e.currentTarget.dataset.action !== 'nft') {
      return openTokenGateFrame();
    }

    await go(
      axios.post(`/${T.lang}/@api/token_gate/bridge`, {
        stores_product_id: stores_product.id,
      }),
      async ({ data: { uuid, nonce } }) => {
        if (MShopUtilF.isMobile()) {
          await mobileMetaMask({ uuid, nonce, stores_product }, e);
        } else {
          await pcMetaMask({ uuid, nonce, stores_product }, e);
        }
      },
    );
  } catch (e) {
    const message = e.response?.data?.message || e.message;
    if (message.indexOf('MetaMask') == -1) {
      await $.alert(message);
    }
  } finally {
    $.don_loader_end();
  }
};

const updateVerifyList$ = (storesProductsTokenGates) => {
  $setHTML(MShopAppTokenGateStoresProductTmplS.verifyList(storesProductsTokenGates), $qs('.token-gate-list'));
};

const getEthereum = () =>
  window.ethereum?.providers ? window.ethereum?.providers?.find((e) => e.isMetaMask) : window.ethereum;

export const signMetaMask = async ({ nonce, uuid }) => {
  const metaMaskProvider = getEthereum();
  const chainId = parseInt(metaMaskProvider.chainId, 16);

  const [address] = await metaMaskProvider.request({
    method: 'eth_requestAccounts',
  });

  const { result: signature } = await metaMaskProvider.send(`eth_signTypedData_v4`, [
    address,
    JSON.stringify(
      MShopAppTokenGateStoresProductS.signatureMessage({
        chainId,
        address,
        nonce,
      }),
    ),
  ]);

  await axios.post(`/${T.lang}/@api/token_gate/sign`, { address, chainId, signature, uuid });

  return address;
};

const verifyTokenGate = async (stores_product_id) => {
  const {
    data: { is_pass, stores_products_token_gates },
  } = await axios.get(`/${T.lang}/@api/token_gate/verify`, {
    params: { stores_product_id },
  });
  updateVerifyList$(stores_products_token_gates);
  return is_pass;
};

const removeTokenGateBtn = (is_pass) => {
  go(
    $qsa('.token-gate__btn'),
    each($remove),
    () => $qs('.pd-submit__btns'),
    $removeClass('pd-submit__btns--hide'),
    ($btns) => {
      if (!is_pass) {
        $setHTML(
          submitDisabledBtn(
            `${MShopAppTokenGateStoresProductConstantS.METAMASK_FOX_SVG} ${T('tokenGate::tg011')}`,
          ),
          $btns,
        );
      }
    },
  );
};

const pcMetaMask = async ({ uuid, nonce, stores_product: { id: stores_product_id } }) => {
  const onboarding = new MetaMaskOnboarding();
  if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
    return onboarding.startOnboarding();
  } else {
    onboarding.stopOnboarding();
  }
  $.don_loader_start();
  await signMetaMask({ uuid, nonce });
  const is_pass = await verifyTokenGate(stores_product_id);
  removeTokenGateBtn(is_pass);
};

const mobileMetaMask = async ({ uuid, nonce, stores_product: { id: stores_product_id, product_id } }, e) => {
  $setScrollTop(0, window);
  e.delegateTarget.tab_opt.tab_top = 0;
  await go(
    getEthereum(),
    async (ethereum) => {
      if (ethereum) {
        await signMetaMask({ uuid, nonce });
        $.don_loader_start();
        return verifyTokenGate(stores_product_id);
      } else {
        const deepLink = `https://metamask.app.link/dapp/${location.host}/${T.lang}/${box.sel(
          'domain_name',
        )}/token_gate/metamask/${uuid}`;

        if (MShopUtilF.isAndroid() && parseFloat(MShopUtilF.getOSVersion()) >= 12) {
          if (!(await $.confirm(T(`tokenGate::tg007`)))) {
            throw Error();
          }
        }

        location.href = deepLink;

        return new Promise((rs, rj) => {
          let i = 0;
          const visibilitychangeCb = async () => {
            $.don_loader_start();
            if (document.visibilityState === 'visible') {
              const {
                data: { retry, is_pass, stores_products_token_gates },
              } = await axios.get(`/${T.lang}/@api/token_gate/verify`, {
                params: { stores_product_id },
              });
              if (retry) {
                if (i++ > 10) {
                  $off('visibilitychange', visibilitychangeCb)(document);
                  return rj(Error(T('report::인증 문제가 있습니다. 다시 시도 부탁드립니다.')));
                }
                await delay(1000)();
                return visibilitychangeCb();
              }
              $off('visibilitychange', visibilitychangeCb)(document);
              updateVerifyList$(stores_products_token_gates);
              rs(is_pass);
            }
          };

          $on('visibilitychange', visibilitychangeCb)(document);
        });
      }
    },
    async (is_pass) => {
      if (is_pass) {
        $qs('.token-gate__btn').disabled = true;
        await delay(2500)();
        await MuiF.closeFrame();
      } else {
        $qs('.token-gate__btn').disabled = true;
      }
      removeTokenGateBtn(is_pass);
    },
  );
};

const openTokenGateFrame = () => {
  return MuiF.openFrame(MShopAppTokenGateStoresProductMuiF.frame, async (frame, page, [tab]) => {
    frame.title = T('tokenGate::tg010');
    frame.el_class = 'token-gate-frame';
    frame.header_height = 84;
    tab.makeData = () => {
      return {
        stores_products_token_gates: box.sel(
          'product_detail->stores_product->_->stores_products_token_gates',
        ),
      };
    };

    $addClass('mobile-cart-form-open', document.body);
    await MuiF.makeFrameHeight(frame, tab);
  });
};
