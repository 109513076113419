import { $before, $el, $find, $findAll, $on, $prependTo, $prev, $qsa, $remove } from 'fxdom/es';
import { each, go, html, pipe } from 'fxjs/es';
import { MShopUtilF } from '../../../../../MShop/Util/F/Function/module/MShopUtilF.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { CreatorEditorMarppleEmbedMuiF } from '../../../MarppleEmbed/F/Mui/module/CreatorEditorMarppleEmbedMuiF.js';
import {
  MARPPLE_EMBED_SELECTOR,
  POPUP_STORE_PRODUCTS_EMBED_PREVIEW_CLASS,
  RENEWAL_MARPPLE_EMBED_ADD_MODAL_CLASS,
  RENEWAL_MARPPLE_EMBED_PREVIEW_CLASS,
} from '../../../S/constant.js';
import { getCursorEl } from '../medium-editor.js';
import { initMarppleEmbedToolbar } from '../toolbar/medium-editor-marpple-embed-toolbar.js';
import { hideInsert } from './index.js';
import { createActionStack } from '../features/bug_tracker.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import {
  FeedProductAddModalContainerView,
  FeedProductListPreviewView,
  PopupStoreProductAddModalContainerView,
} from '../../../../../Plus/Event/S/Tmpl/form.ts';
import { rune } from 'rune-ts';
import {
  makeRenewalMarppleEmbedInner,
  makeRenewalPopupStoreEmbedInner,
  wrapEmbedShow,
} from '../../../S/util.js';

const product_embed_modal_class = 'editor_product_modal';

const makeFeatureBtnEl = () =>
  $el(`<div class="feature_btn marpple_embed_button">
          <i class="lnr lnr-gift"></i>
          <span class="feature-name">상품</span>
        </div>`);

export let product_modal_view;

export const initMarppleEmbedPlugin = (registerBtnToInsertBox, option = {}, editor) => {
  const is_popupstore = option?.is_popupstore;

  initMarppleEmbedToolbar(editor, is_popupstore);

  // register btn
  const btn_el = makeFeatureBtnEl();
  registerBtnToInsertBox(btn_el);

  if (!UtilS.isNessApp()) {
    const preview_els = $qsa(
      `.${is_popupstore ? POPUP_STORE_PRODUCTS_EMBED_PREVIEW_CLASS : RENEWAL_MARPPLE_EMBED_PREVIEW_CLASS}`,
    );

    preview_els.forEach((el) => {
      FeedProductListPreviewView.createAndHydrate(el);
    });

    product_modal_view = option.is_popupstore
      ? new PopupStoreProductAddModalContainerView({
          products: [],
          text: null,
          max_count: 16,
          klass: RENEWAL_MARPPLE_EMBED_ADD_MODAL_CLASS,
        })
      : new FeedProductAddModalContainerView({
          products: [],
          max_count: 3,
          klass: RENEWAL_MARPPLE_EMBED_ADD_MODAL_CLASS,
        });

    const wrapper_element = document.createElement('div');
    wrapper_element.classList.add(product_embed_modal_class);
    wrapper_element.setAttribute('contenteditable', 'false');
    wrapper_element.appendChild(product_modal_view.render());
    editor.elements[0].appendChild(wrapper_element);
  }

  $on('click', (e) => {
    hideInsert();
    openMarppleEmbedEditor(editor, null, is_popupstore);
    // open frame
  })(btn_el);
};

export const openMarppleEmbedEditor = (editor, prev_embed_show_el, is_popupstore) => {
  const current_cursor_element = getCursorEl(editor);
  const is_create = !prev_embed_show_el;
  const prev_embed_el = !is_create && $prev(prev_embed_show_el);

  if (UtilS.isNessApp()) {
    // ness 앱은 기존 처럼 동작
    MuiF.openFrame(CreatorEditorMarppleEmbedMuiF.frame, (frame, page, [tab]) => {
      tab.makeData = () =>
        prev_embed_show_el &&
        go(prev_embed_show_el, $find(MARPPLE_EMBED_SELECTOR), (embed$) => embed$.innerHTML);

      frame.removed = (_, { el$: embed_container_el, urls } = {}) => {
        if (!embed_container_el || !urls) return;
        if (is_create) {
          const embed$ = $el(`<div class="embed" contenteditable="false">${urls}</div>`);
          $before(embed$, current_cursor_element);
          $before(embed_container_el, current_cursor_element);
          createActionStack('create marpple embed', { embed_container$: embed_container_el });
        } else {
          prev_embed_el.innerHTML = urls;
          $before(embed_container_el, prev_embed_show_el);
          $remove(prev_embed_show_el);
          createActionStack('update marpple embed', { embed_container$: embed_container_el });
        }
      };
    });
  } else {
    const prev_preview_view = is_create
      ? null
      : rune.getView(
          prev_embed_show_el.querySelector(`.${FeedProductListPreviewView}`),
          FeedProductListPreviewView,
        );

    product_modal_view.data.products = is_create ? [] : prev_preview_view.data.products;
    product_modal_view.text_data = is_create ? null : prev_preview_view.data.text;
    product_modal_view.open();

    const onCloseModal = (e) => {
      const products = product_modal_view.data.products;
      const text_data = product_modal_view.text_data;

      let preview_view;

      if (is_create) {
        preview_view = new FeedProductListPreviewView({
          is_mobile: MShopUtilF.isMobile(),
          products,
          text: text_data,
          is_popupstore,
          klass: is_popupstore
            ? POPUP_STORE_PRODUCTS_EMBED_PREVIEW_CLASS
            : RENEWAL_MARPPLE_EMBED_PREVIEW_CLASS,
          hide_badges: !is_popupstore,
          hide_tag: !is_popupstore,
        });
        preview_view.render();
        preview_view.data.products = products;

        const product_ids = products.map((p) => p.id);

        if (!product_ids.length) return;

        const embed_el = $el(
          is_popupstore
            ? `<div class="embed" contenteditable="false">${makeRenewalPopupStoreEmbedInner({
                product_ids,
                text_data,
              })}</div>`
            : `<div class="embed" contenteditable="false">${makeRenewalMarppleEmbedInner(product_ids)}</div>`,
        );
        const embed_show_el = $el(wrapEmbedShow('', true));
        embed_show_el.appendChild(preview_view.element());

        $before(embed_el, current_cursor_element);
        $before(embed_show_el, current_cursor_element);
      } else {
        preview_view = prev_preview_view;
        preview_view.data.products = products;
        preview_view.data.text = text_data;

        const product_ids = products.map((p) => p.id);
        prev_embed_el.innerHTML = is_popupstore
          ? makeRenewalPopupStoreEmbedInner({
              product_ids,
              text_data,
            })
          : makeRenewalMarppleEmbedInner(product_ids);
        preview_view.redraw();
      }
      product_modal_view.removeEventListener('modal:closed:event', onCloseModal);
    };
    product_modal_view.addEventListener('modal:closed:event', onCloseModal);
  }
};

export const addEditMenuContainer = (container$) => {
  go(container$, $findAll('.app-product-item'), each(addEditMenu));
};

export const addEditMenu = (product$) => {
  const optionEl = $el(
    html`
      <button class="embed_option">
        <img
          src="//s3.marpple.co/files/u_1165777/2020/11/original/7d101e39c642cac9cf5e5d01435a402040d326d11.png"
          alt=""
        />
        <div class="options">
          <div class="option update-option">수정</div>
          <div class="option delete-option">삭제</div>
        </div>
      </button>
    `,
  );
  $prependTo(product$, optionEl);
  return product$;
};

export const removeEditMenus = pipe($findAll('.embed_option'), each($remove));

export const removeProductModal = pipe($findAll(`.${product_embed_modal_class}`), each($remove));
