import {
  compact,
  each,
  every,
  filter,
  find,
  flatten,
  go,
  map,
  mapC,
  not,
  reject,
  sel,
  selEq,
  some,
  take,
  tap,
  uniq,
  values,
} from 'fxjs/es';
import { BpOptionConstantS } from '../../BpOption/S/Constant/module/BpOptionConstantS.js';
import { NewMakerGuideAlertMuiF } from '../../NewMaker/Guide/Alert/F/Mui/module/NewMakerGuideAlertMuiF.js';
import { minusStrokeWidth } from './Fcanvas/stroke.js';

import { marpplizerProxy } from './Marpplizer/marpplizer.js';
import {
  deepFindCvObjects,
  get_coord,
  getFonts,
  in_temp_group,
  is_temp_group,
  orderFaceBackgroundInMaker,
  setPfColllaboTypeBpsId,
  somePFDesigns,
} from './util.js';
import {
  getBaseProductInMaker,
  getFcanvasByBpfId,
  getFcanvasOfBpfId,
  getItsSf,
  getPfByFcanvas,
  getProductColorInMaker,
  getProductFaces2InMaker,
  getRealFcanvass,
  isCanOverflow,
  isCvImage,
  isEmbro,
  isOnlyFlex,
} from './getSth.js';

import { hasSthInCanvas } from './canvas_size.js';
import { makeDataCmAndChangeCvTextResetBoxDataA } from './text.js';
import { checkAllFcanvasDesignSize } from './check_image.js';
import { selectFaceInitA } from './select_faces.js';
import { isBpStrapCase } from '../S/categorize.js';
import { makeCvPrintAreaInMaker } from './CvPrintArea/cv_object.js';
import { getCurrentFcanvas, getCvDesigns, getCvObj, makeFilterCvObj } from './Fcanvas/cv_object.js';
import { arosegida_reject_fonts, isArosegidaBp, isBpMousePad } from './categorize.js';
import { NewMakerCheckerF } from '../../NewMaker/Checker/F/Function/module/NewMakerCheckerF.js';
import { MuiF } from '../../Mui/F/Function/module/MuiF.js';
import { NewMakerGuideImageQualityMuiF } from '../../NewMaker/Guide/ImageQuality/F/Mui/module/NewMakerGuideImageQualityMuiF.js';
import { NewMakerGuideAmbiguousAreaMuiF } from '../../NewMaker/Guide/AmbiguousArea/F/Mui/module/NewMakerGuideAmbiguousAreaMuiF.js';
import { NewMakerGuideParallelMuiF } from '../../NewMaker/Guide/Parallel/F/Mui/module/NewMakerGuideParallelMuiF.js';
import { NewMakerLegacyF } from '../../NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { isCheckWrongTextPass } from '../../NewMaker/Checker/F/Function/isCheckWrongTextPass.js';
import { $qs } from 'fxdom/es';
import { NewMakerWeS } from '../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { MShopUtilF } from '../../MShop/Util/F/Function/module/MShopUtilF.js';
import { NewMakerCvObjectUtilF } from '../../NewMaker/CvObject/Util/F/Function/module/NewMakerCvObjectUtilF.js';
import { NewMakerUtilF } from '../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerCheckerS } from '../../NewMaker/Checker/S/Function/module/NewMakerCheckerS.js';
import { NewMakerGuideAmbiguousAreaS } from '../../NewMaker/Guide/AmbiguousArea/S/Function/module/NewMakerGuideAmbiguousAreaS.js';
import { NewMakerGuideImageQualityS } from '../../NewMaker/Guide/ImageQuality/S/Function/module/NewMakerGuideImageQualityS.js';
import { NewMakerGuideParallelS } from '../../NewMaker/Guide/Parallel/S/Function/module/NewMakerGuideParallelS.js';
import {
  hasWrongFittedObj,
  isGoodHorizontalStatus,
  resetCloneHorizontalCvObj,
} from '../../NewMaker/MaskingTape/F/Function/maskingtape.js';
import { BpOptionF } from '../../BpOption/F/Function/module/BpOptionF.js';
import { NewMakerPreviewModalMuiF } from '../../NewMaker/Preview/Modal/F/Mui/module/NewMakerPreviewModalMuiF.js';
import { NewMakerPreviewModalF } from '../../NewMaker/Preview/Modal/F/Function/module/NewMakerPreviewModalF.js';
import { NewMakerGuideConfirmF } from '../../NewMaker/Guide/Confirm/F/Function/module/NewMakerGuideConfirmF.js';
import { drawProductFace, makeOnlyDesignFaceCanvasByPrintArea } from './draw_product_faces.js';
import { getJustifyImageSize } from './canvas_trim.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import axios from 'axios';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBpfF } from '../../NewMaker/Property/Bpf/F/Function/module/NewMakerPropertyBpfF.js';
import { NewMakerSmallModalF } from '../../NewMaker/SmallModal/F/Function/module/NewMakerSmallModalF.js';
import { PrintTemplateS } from '../../PrintTemplate/S/Function/module/PrintTemplateS.js';
import { PrintTemplateF } from '../../PrintTemplate/F/Function/module/PrintTemplateF.js';
import { OMPCoreUtilS } from '../../OMP/Core/Util/S/Function/module/OMPCoreUtilS.js';
import { OMPCoreUtilF } from '../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { addCvPreview } from './CvPreview/cv_object.js';
import { MakerDebugF } from '../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';
import { NewMakerPropertyBaseProductF } from '../../NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { NewMakerPropertyBaseProductS } from '../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { BpOptionUploadTypeS } from '../../BpOption/UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { alertProEditorCsError } from '../../VectorEditor/F/Function/pro_editor.error.js';
import { createCanvasElement } from '../../Canvas/S/util.js';
import { NewMakerCheckConditionF } from '../../NewMaker/CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { UtilS } from '../../Util/S/Function/module/UtilS.js';
import { NewMakerCompositePreviewF } from '../../NewMaker/CompositePreview/F/Function/module/NewMakerCompositePreviewF.js';

function _hasUnvisibleCvObjs() {
  return !!_p.find(
    box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces'),
    function (v, idx) {
      return _p.go(
        G.mp.maker.designs(box.sel('maker->canvass')[idx]),
        _p.find(function (cv_object) {
          if (cv_object._data._visible === true) return false;
          return cv_object.visible === false;
        }),
      );
    },
  );
}

function _removeAllUnvisibleCvObjs() {
  return _p.each(
    box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces'),
    function (v, idx) {
      return _p.go(
        G.mp.maker.designs(box.sel('maker->canvass')[idx]),
        _p.filter(function (cv_object) {
          if (cv_object._data._visible === true) return false;
          return cv_object.visible === false;
        }),
        _p.each(function (cv_object) {
          cv_object?.canvas?.remove(cv_object);
        }),
        function () {
          box.sel('maker->canvass')[idx].renderAll();
        },
      );
    },
  );
}

function isOverflowCoords(coords, print_area) {
  if ($.attr($1('html'), 'mp_worker_policy')) return;
  return go(
    [
      function (point) {
        return point.y < print_area.top;
      },
      function (point) {
        return point.y > print_area.bottom;
      },
      function (point) {
        return point.x < print_area.left;
      },
      function (point) {
        return point.x > print_area.right;
      },
    ],
    some((func) => {
      return every(func, coords);
    }),
  );
}

function isCvObjsFullOverBp(fcanvas) {
  const cv_mask = G.mp.maker.cv_mask1(fcanvas);
  const last_visible = cv_mask.visible;
  const globalCompositeOperation = cv_mask.globalCompositeOperation;
  cv_mask.visible = true;
  cv_mask.globalCompositeOperation = 'source-out';

  const is_not_designs_to_be_hidden = go(
    G.mp.maker.not_designs(fcanvas),
    reject((cv_obj) => cv_obj._data.cv_type === 'cv_background'),
    filter((cv_obj) => cv_obj.visible === true),
    reject((cv_obj) => cv_obj === cv_mask),
  );
  go(
    is_not_designs_to_be_hidden,
    each((cv_obj) => (cv_obj.visible = false)),
  );
  fcanvas.renderAll();

  const canvas = fcanvas.lowerCanvasEl;
  const ctx = canvas.getContext('2d');
  const bool = hasSthInCanvas(ctx.getImageData(0, 0, canvas.width, canvas.height));
  cv_mask.visible = last_visible;
  cv_mask.globalCompositeOperation = globalCompositeOperation;
  go(
    is_not_designs_to_be_hidden,
    each((cv_obj) => (cv_obj.visible = true)),
  );
  fcanvas.renderAll();
  return !bool;
}

export function checkCvObjFullOverBp() {
  const pc = getProductColorInMaker();
  go(
    pc.product_faces2.value,
    map((pf) => getFcanvasOfBpfId(pf.bpf_id)),
    each((fcanvas) => {
      const pf = getPfByFcanvas(fcanvas);
      if (!isCanOverflow()) {
        pf.is_design_over_bpf = false;
        return;
      }
      pf.is_design_over_bpf = isCvObjsFullOverBp(fcanvas);
    }),
  );

  const bp = getBaseProductInMaker();
  if (!isCanOverflow() || isBpStrapCase(bp) || !bp.can_full_cover) {
    pc.product_faces2.can_not_change_color = false;
  } else {
    go(
      getRealFcanvass(),
      every((fcanvas) => {
        const pf = getPfByFcanvas(fcanvas);
        return pf && pf.is_design_over_bpf;
      }),
      function (bool) {
        pc.product_faces2.can_not_change_color = bool;
      },
    );
  }
}

function _trimed_checker(f_canvas, sf) {
  try {
    let bool;
    const not_designs = go(
      f_canvas,
      G.mp.maker.not_designs,
      reject((o) => o._data.cv_type === 'cv_print_area'),
      filter((o) => o.visible),
    );

    makeCvPrintAreaInMaker(f_canvas, sf);
    const cv_print_area = G.mp.maker.cv_print_area(f_canvas);
    const new_attr = {
      fill: 'rgba(0,0,0,1)',
      visible: true,
      globalCompositeOperation: 'destination-out',
    };

    cv_print_area.set(new_attr);
    orderFaceBackgroundInMaker(f_canvas);

    go(
      not_designs,
      each((o) => (o.visible = false)),
      tap(function () {
        f_canvas.renderAll();
        const canvas = f_canvas.lowerCanvasEl;
        const ctx = canvas.getContext('2d');
        bool = hasSthInCanvas(ctx.getImageData(0, 0, canvas.width, canvas.height));
      }),
      each((o) => (o.visible = true)),
      function () {
        makeCvPrintAreaInMaker(f_canvas, getItsSf(f_canvas));
        orderFaceBackgroundInMaker(f_canvas);
        if (bool) G.mp.maker.cv_print_area_mask(f_canvas).visible = false;
        // cv_print_area.set(last_attr);
        f_canvas.renderAll();
      },
    );
    return bool;
  } catch (e) {
    console.error(e);
    axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
      json_memo: {
        error_stack: e.stack,
      },
      name: 'canvas_trimed_checker',
    });
    makeCvPrintAreaInMaker(f_canvas, getItsSf(f_canvas));
    orderFaceBackgroundInMaker(f_canvas);
  }
}

export function trimed_checker(f_canvas, sf) {
  // const has_warning = go(
  //   f_canvas._objects,
  //   some((cv_obj) => cv_obj.is_warning),
  // );
  // if (has_warning) {
  //   $.alert('WARNING::경고 표시가 있는 이미지는 통과 될수 없습니다.');
  // }
  if (f_canvas.preview) {
    const designs = go(
      getCvDesigns(f_canvas._objects),
      reject((cv_obj) => cv_obj.clone_parent_cid),
      filter((cv_obj) => cv_obj._data._visible),
    );
    if (!designs.length) return;
    each((cv_obj) => (cv_obj.visible = true))(designs);
    getCvObj(f_canvas._objects, 'cv_preview').visible = false;
    f_canvas.renderAll();
    const a = _trimed_checker(f_canvas, getItsSf(f_canvas));
    each((cv_obj) => (cv_obj.visible = false))(designs);
    getCvObj(f_canvas._objects, 'cv_preview').visible = true;
    f_canvas.renderAll();
    return a;
  }
  return _trimed_checker(f_canvas, sf);
}

export const checkIsOverflowOfObjInPrintArea = (obj, cv_print_area) => {
  if ($.attr($1('html'), 'mp_worker_policy')) return;
  cv_print_area = cv_print_area || G.mp.maker.cv_print_area();
  if (!cv_print_area) return;
  if (cv_print_area?.canvas?.fcanvas_data?.maker_type === BpOptionConstantS.MASKING_TAPE_EDITOR) {
    return;
  }
  if (cv_print_area?._data?.cv_type !== 'cv_print_area') return;
  const print_px = _go(cv_print_area, minusStrokeWidth, get_coord);
  if (in_temp_group(obj)) return;

  const obj_coords = values(obj.calcCoords(true));
  obj.is_overflow = isOverflowCoords(obj_coords, print_px);
  if (is_temp_group(obj)) {
    each(function (o) {
      o.is_overflow = false;
    }, obj._objects);
  }
};

export const syncIsOverflowFromDesignsToLayer = () => {
  if (UtilF.isLegacyMobile()) return;
  const design_els = $('#marpplizer .design_list .design');
  const designs = G.mp.maker.designs();

  if (designs.length !== design_els.length) return;
  each(function (v, i) {
    $.attr(v, {
      is_overflow: designs[i].is_overflow,
    });
  }, design_els);
};

export const checkIsOverflowInCurrentFace = (f_canvas) => {
  f_canvas = f_canvas || G.mp.maker.editing_canvas();
  const cv_print_area = G.mp.maker.cv_print_area(f_canvas);
  go(
    G.mp.maker.designs(f_canvas),
    reject((cv_obj) => cv_obj._data.clone_parent_cid),
    each(function (obj) {
      if ($.attr($1('html'), 'mp_worker_policy')) {
        obj.is_overflow = false;
        return;
      }
      checkIsOverflowOfObjInPrintArea(obj, cv_print_area);
    }),
  );
  syncIsOverflowFromDesignsToLayer();
  f_canvas.renderAll();
  G.mp.maker.reset_box_data();
};

async function isOverflowedAlignments() {
  const base_product_size_id = box.sel('maker->product_color->base_product_size_id');
  return go(
    box.sel('maker->product_color->product_faces2->value'),
    filter((pf) => pf.designs?.length),
    some(async (pf) => {
      const sf = go(
        pf.size_faces,
        find((sf) => sf.base_product_size_id === base_product_size_id),
      );
      return go(
        sf.cv_obj_alignments_attrs,
        some(async (cv_obj_alignments_attr) => {
          const margin = 5;
          const canvas = await makeOnlyDesignFaceCanvasByPrintArea({
            product_face: pf,
            print_area: go(undefined, () => {
              return {
                top: cv_obj_alignments_attr.top - margin,
                left: cv_obj_alignments_attr.left - margin,
                width: cv_obj_alignments_attr.width + margin * 2,
                height: cv_obj_alignments_attr.height + margin * 2,
              };
            }),
            width: cv_obj_alignments_attr.width + margin * 2,
          });
          const ctx = canvas.getContext('2d');
          ctx.clearRect(margin, margin, cv_obj_alignments_attr.width, cv_obj_alignments_attr.height);
          const bool = hasSthInCanvas(ctx.getImageData(0, 0, canvas.width, canvas.height));
          return bool;
        }),
      );
    }),
  );
}

const has_overflowed_in_current_size = () => {
  const mp_maker_el = $qs('#maker_frame');
  if (mp_maker_el?.dataset?.is_embroidery_app === 'true') {
    return isOverflowedAlignments();
  }
  return go(
    getRealFcanvass(),
    filter((fcanvas) => {
      const designs = G.mp.maker.designs(fcanvas);
      return designs.length;
    }),
    find(async function (f_canvas) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      return trimed_checker(f_canvas, getItsSf(f_canvas));
    }),
    async function (f_canvas) {
      if (!f_canvas) return false;
      if (f_canvas.preview) return true;
      G.mp.maker.editing_canvas(box.sel('maker->canvass').indexOf(f_canvas));
      G.mp.maker.cv_print_area(f_canvas).visible = true;
      f_canvas.renderAll();
      await selectFaceInitA(false, true);
      if (UtilF.isLegacyMobile()) {
        NewMakerLegacyF.swiperInit();
      }
      return true;
    },
  );
};

export const is_hidden_image_checker_passed = () => {
  /*히든 이미지, 자동지움*/
  if (!_hasUnvisibleCvObjs()) return true;
  return go($.confirm(T('Hidden images are automatically deleted.')), function (bool) {
    if (bool) {
      return go(
        _removeAllUnvisibleCvObjs(),
        () => G.mp.maker.reset_layer(),
        () => G.mp.maker.reset_box_data(),
        () => marpplizerProxy(),
        () => true,
      );
    }
  });
};

function getCvObjIndex(cv_obj) {
  return cv_obj.canvas._objects.indexOf(cv_obj);
}

function isSthOnCvObjA(cv_obj, is_on_and_under) {
  return new Promise(function (resolve) {
    const base64_url = cv_obj.toDataURL();
    const fcanvas = cv_obj.canvas;
    const bounding_rect = cv_obj.getBoundingRect(true);
    fabric.Image.fromURL(
      base64_url,
      function (cv_mask_obj) {
        cv_mask_obj.set(bounding_rect);
        cv_mask_obj.angle = 0;
        cv_mask_obj.scale(1);
        cv_obj.visible = false;
        cv_mask_obj._data.cv_type = 'cv_mask_obj';
        cv_mask_obj.globalCompositeOperation = 'destination-in';
        G.mp.maker.cleared_pass = true;
        G.mp.maker.selected_pass = true;

        const cv_objs = G.mp.maker.designs(fcanvas);
        const cv_obj_idx = cv_objs.indexOf(cv_obj);
        const cv_objs_before_cv_obj = take(cv_obj_idx, cv_objs);
        const arr_visible_false = is_on_and_under
          ? []
          : go(
              cv_objs_before_cv_obj,
              filter((cv_obj) => cv_obj.visible),
              each((cv_obj) => (cv_obj.visible = false)),
              tap(() => fcanvas.renderAll()),
            );
        const arr_visible_false2 = go(
          G.mp.maker.not_designs(fcanvas),
          filter((cv_obj) => cv_obj.visible),
          each((cv_obj) => {
            cv_obj.visible = false;
          }),
          tap(() => fcanvas.renderAll()),
        );

        fcanvas.insertAt(cv_mask_obj, getCvObjIndex(_p.last(G.mp.maker.designs(fcanvas))) + 1);

        let has_sth = false;

        const canvas = fcanvas.lowerCanvasEl;
        const ratio = canvas.width / G.mp.maker.CANVAS_WIDTH_ORIGIN;
        const ctx = canvas.getContext('2d');
        const top = cv_mask_obj.top * ratio;
        const left = cv_mask_obj.left * ratio;
        const imageData = ctx.getImageData(
          Math.ceil(left),
          Math.ceil(top),
          parseInt(cv_mask_obj.width * ratio),
          parseInt(cv_mask_obj.height * ratio),
        );

        go(
          arr_visible_false,
          each((cv_obj) => {
            cv_obj.visible = true;
          }),
          () => fcanvas.renderAll(),
        );

        go(
          arr_visible_false2,
          each((cv_obj) => {
            cv_obj.visible = true;
          }),
          () => fcanvas.renderAll(),
        );

        for (let i = 0; i < imageData.data.length; ) {
          if (imageData.data[i + 3] > 200) {
            has_sth = true;
            break;
          }
          i += 4;
        }
        cv_obj.visible = true;
        fcanvas.remove(cv_mask_obj);
        G.mp.maker.cleared_pass = false;
        G.mp.maker.selected_pass = false;

        resolve(has_sth);
      },
      cv_obj.toObject(),
    );
  });
}

function getCvObjEmbros(fcanvas) {
  return go(
    fcanvas._objects,
    filter((cv_obj) => cv_obj._data.is_embro),
  );
}

function getCvObjOnlyFlex(fcanvas) {
  return go(
    fcanvas._objects,
    filter((cv_obj) => cv_obj._data.only_flex),
  );
}

function hasSthOnCvObjEmbroInFcanvassA(getFunc, is_on_and_under) {
  return go(
    getRealFcanvass(),
    filter((fcanvas) => getFunc(fcanvas).length),
    find((fcanvas) => {
      return go(
        getFunc(fcanvas),
        some(async (cv_obj_embro) => {
          return isSthOnCvObjA(cv_obj_embro, is_on_and_under);
        }),
      );
    }),
    async (fcanvas) => {
      if (!fcanvas) return false;
      G.mp.maker.editing_canvas(box.sel('maker->canvass').indexOf(fcanvas));
      await selectFaceInitA(false, true);
      if (UtilF.isLegacyMobile()) NewMakerLegacyF.swiperInit();
      return true;
    },
  );
}

const isEmbroCheckerPassedA = async () => {
  if (await hasSthOnCvObjEmbroInFcanvassA(getCvObjEmbros)) {
    $.alert(T('maker_error_alert::패치 위에 다른 다지인을 올릴 수 없습니다.'));
    return false;
  } else return true;
};

const isSpecialCharacterCheckerPassedA = async () => {
  if (await hasSthOnCvObjEmbroInFcanvassA(getCvObjOnlyFlex)) {
    $.alert(T('maker_text_editor::특수 색상 글씨 위에 다른 다지인을 올릴 수 없습니다.'));
    return false;
  } else return true;
};

const is_out_of_print_passed = () => {
  /*영역 아예 밖으로 나간것, 자동지움*/
  if (
    !_p.some(box.sel('maker->canvass'), function (v) {
      return _p.some(G.mp.maker.designs(v), function (cv_obj) {
        return cv_obj.is_overflow;
      });
    })
  )
    return true;

  return _p.go(
    $.confirm(
      T(
        'The image placed on the non-printable area will be deleted automatically. Would you like to proceed?',
      ),
    ),
    function (bool) {
      if (bool) {
        _p.each(box.sel('maker->canvass'), function (v) {
          return _p.each(G.mp.maker.designs(v), function (cv_obj) {
            if (cv_obj.is_overflow) cv_obj.canvas.remove(cv_obj);
          });
        });
        G.mp.maker.reset_box_data();
        G.mp.maker.editing_canvas().renderAll();
        // if (typeof G.mp.maker.exitTempSvgApplication === 'function') {
        //   G.mp.maker.exitTempSvgApplication();
        // }
        marpplizerProxy();
        return true;
      }
    },
  );
};

export async function moveToFcanvasIncludingSwiper(f_canvas) {
  G.mp.maker.editing_canvas(box.sel('maker->canvass').indexOf(f_canvas));
  f_canvas.renderAll();
  await selectFaceInitA(false, true);
  if (UtilF.isLegacyMobile()) NewMakerLegacyF.swiperInit();
}

const is_oversized_checker_passed = () => {
  /*사이즈 40cm 이상*/
  if (isCanOverflow()) return true;

  return _p.go(
    box.sel('maker->canvass'),
    _p.find(function (v) {
      return G.mp.maker.cv_objects_deep_find(G.mp.maker.designs(v), function (vv) {
        return vv.is_oversized;
      });
    }),
    async function (f_canvas) {
      if (!f_canvas) return true;
      await moveToFcanvasIncludingSwiper(f_canvas);
      $.alert(T('Some image size is too large. Please set the image size smaller.'));
      /*'oversized'*/
    },
  );
};

export const is_overflow_on_line_checker_passed = async () => {
  /*영역에 걸쳐있는 것*/
  if (isCanOverflow()) return true;
  if (!(await has_overflowed_in_current_size())) return true;
  $.alert(T('Please check if the image is on the printable area.'));
  /*'overflowed'*/
};

const hasDesignsInOneFaceInJp = () => {
  if (T.lang !== 'jp') return true;
  if (
    !box().maker.product_color._.base_product_color._.base_product_color_faces[0]._.base_product_face.price_jp
  )
    return true;

  return go(
    box().maker.canvass,
    find((f_canvas) => G.mp.maker.designs(f_canvas).length),
    tap(function (has_design) {
      if (!has_design) {
        $.alert('イメージを入れてください。<br>(この商品は無地での購入ができません。)');
      }
    }),
  );
};

function checkFontColors() {
  if (!isBpMousePad()) return true;
  return not(
    somePFDesigns((cv_obj) => {
      if (cv_obj._data.cv_type === 'cv_text_image') {
        const is_navy_color = cv_obj._data.press_color_id === 40;
        if (is_navy_color) {
          const color_name = cv_obj._data['press_color_name' + G._en];
          $.alert(
            G._en === '_en'
              ? `${color_name} color is not available for this product. Please choose the others.`
              : G._en === '_jp'
              ? `
          こちらの商品の文字には${color_name}が適用されないので、他の色をお選びください。
          `
              : `${color_name} 색상은 이 제품에 사용 하실 수 없습니다. 다른 색상을 선택해 주세요.`,
          );
          return is_navy_color;
        }
      }
    }),
  );
}

async function checkFontFamily() {
  const is_arosegida = isArosegidaBp();
  const fonts = await getFonts();
  return not(
    somePFDesigns((cv_obj) => {
      if (cv_obj._data.cv_type === 'cv_text_image') {
        if (is_arosegida) {
          const font_family = sel('text_info.fontFamily', cv_obj._data);
          const _font = find(selEq('fontFamily', font_family), arosegida_reject_fonts);
          if (_font) {
            const font = go(fonts, find(selEq('id', _font.id)));
            $.alert(
              G._en === ''
                ? `${font.name} 폰트는 이 제품에 사용 하실 수 없습니다. 다른 색상을 선택해 주세요.`
                : `${font.name_en} font is not available for this product. Please choose the others.`,
            );
          }
          return !!_font;
        }
      }
    }),
  );
}

export const checkAboutBeads = () => {
  if (getBaseProductInMaker().id === 4386) {
    return go(getProductFaces2InMaker(), find(selEq('bpf_id', 8179)), (pf) => {
      if (!pf)
        return $.confirm(
          T(
            'maker::Message::If you dont put an image on the bottom of it, its made in white. Would you like to proceed with it?',
          ),
        );
      return true;
    });
  }
  return true;
};

export const isPassNotdesignedImage = async (base_product) => {
  if (
    !base_product?.maker_features?.[NewMakerPropertyBaseProductConstantS.CONFIRM_HAS_NOT_DESIGNED_FACE] &&
    !NewMakerProductStyleS.NEED_NOT_DESIGNED_BP_IDS.includes(base_product?.id) &&
    !NewMakerWeS.isWeBaseProduct(base_product?.id)
  )
    return true;
  if (NewMakerCheckerF.isAllFacesDesignPass()) return true;
  if (await $.confirm(T('maker::Message::go_to_not_designed_fcanvas'))) return true;
  await NewMakerUtilF.goToNotDesignedFcanvas();
  return false;
};

export const isProductColorInGood = async () => {
  if (collabo_type === 'creator' && box.sel('store_id') === 9442) return true;
  if (box.sel('is_user->_->policies->mp_design_policy') && $1('html#dream_factory')) return true;
  try {
    const maker_frame_el = $qs('#maker_frame');
    const bp_option_groups_el = $qs('.bp_option_groups');
    const product = getProductColorInMaker();
    if (maker_frame_el?.dataset?.is_vector === 'true') {
      if (bp_option_groups_el?.dataset?.is_designed !== 'true') {
        $.alert(T('maker_error_alert::앗! 제품을 만들지 않으셨어요.'));
        return;
      }
    } else if (!(await isPassNotdesignedImage(product._.base_product))) return;
    const bp = getBaseProductInMaker();
    const is_we_product = NewMakerWeS.isWeBaseProduct(bp?.id);
    const is_vector = $qs('#maker_frame')?.dataset?.is_vector === 'true';
    if (is_vector) {
      if (box().maker.product_color._.base_product.maker_type === 'KEYRING') {
        const isNotNormal = BpOptionF.isVirtualBpOptionNotNormal(bp_option_groups_el);
        if (isNotNormal) {
          $.alert(
            T(
              'maker_error_alert::We are very sorry, but there is a problem with loading the chosen design. It would be appreciated if you redesign the item and order again.',
            ),
          );
          return;
        }
      }
      return true;
    }
    const is_ai_not_support_bp = NewMakerProductStyleS.isAiNotSupportedBp(getBaseProductInMaker());
    const is_condition_popup = NewMakerCheckConditionF.isConfirmPopupMapping();
    return go(
      [
        /* maker-validation 숨김 이미지 자동 지우기 */
        is_hidden_image_checker_passed,
        async () => {
          const pdf_printing_file = box.sel('maker->product_color->_->pdf_printing_file');
          if (!pdf_printing_file?.id) return true;
          const product = getProductColorInMaker();
          if (pdf_printing_file && OMPCoreUtilS.isExpired(pdf_printing_file)) {
            await OMPCoreUtilF.alertPdfFileExpired({ product });
            $.don_loader_start();
            return;
          }
          const lang = T.lang;
          const bp_id = product.base_product_id;
          const { is_OK } = PrintTemplateS.validatePdfPrintingFile({
            template: await PrintTemplateF.getPrintTemplate({
              bp_id,
              lang,
            }),
            pdf_printing_file,
          });

          if (is_OK) {
            return true;
          } else {
            return NewMakerSmallModalF.confirm.proceedPreviousPdfVersion();
          }
        },
        async () => {
          const product = getProductColorInMaker();
          if (!BpOptionUploadTypeS.UPLOAD_TYPE.isDirectType(product)) return true;
          if (!NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product._.base_product)) return true;
          const is_pass = NewMakerPropertyBaseProductF.pdfFilePossible.isPassUploadTypeDirect(product);
          if (is_pass) return true;
          await $.alert(TT('maker_upload_type::no_file'));
        },
        async () => {
          /* maker-validation 자수 상품 면 디자인 안했을때 물어보기 */
          const config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
            NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
            bp.id,
          );
          if (!config) return true;
          const design_length = go(
            box.sel('maker->product_color->product_faces2->value->0'),
            sel('designs.length'),
          );
          if (design_length === config.obj_length) return true;
          if (await $.confirm(T('maker::Message::go_to_not_designed_fcanvas'))) return true;
        },

        async () => {
          if (NewMakerProductStyleS.needEveryFaceDesign(bp)) {
            /* maker-validation 모든 면 디자인 있어야함 */
            return go(
              getRealFcanvass(),
              find((f_canvas) => !getCvDesigns(f_canvas._objects)?.length),
              async function (f_canvas) {
                if (!f_canvas) return true;
                if (f_canvas) {
                  $.alert(T('maker::Message::there_is_no_design_message'));
                  await moveToFcanvasIncludingSwiper(f_canvas);
                  return false;
                }
              },
            );
          }
          if (
            is_we_product ||
            G.collabo_type === 'creator' ||
            NewMakerProductStyleS.atLeastOneFaceDesign(bp)
          ) {
            /* maker-validation 적어도 한개는 디자인이 있어야함 */
            return go(
              getRealFcanvass(),
              some((f_canvas) => getCvDesigns(f_canvas._objects)?.length),
              tap(function (has_design) {
                if (!has_design) {
                  $.alert(T('maker::Message::there_is_no_design_message'));
                }
              }),
            );
          } else return true;
        },
        async () => {
          if (box().maker.product_color._.base_product.maker_type !== 'MASKING_TAPE') return true;
          const { product_faces2 } = box().maker.product_color;
          const pf = product_faces2.value[0];
          if (!pf.designs?.length) return true;
          /* maker-validation 마스킹테이프 현재 메이커가 제대로 패턴 처리가 되었는지 확인 */
          if (
            isGoodHorizontalStatus({
              cv_objs: pf.designs,
              cv_print_area: pf.cv_print_area,
            })
          )
            return true;
          $.alert(T('maker_error_alert::문제가 발생했습니다. 디자인을 다시 확인해주세요.'));
          await resetCloneHorizontalCvObj();
        },
        async () => {
          if (is_condition_popup) return true;
          if (!is_we_product) {
            return true;
          }

          const base_product_faces = go(
            box().maker.product_color._.base_product_color._.base_product_color_faces,
            map(sel('_.base_product_face')),
          );
          const { product_faces2, base_product_size_id } = box().maker.product_color;

          const frame_data = NewMakerGuideAmbiguousAreaS.findBp(getBaseProductInMaker().id);
          if (!frame_data) return true;
          /* maker-validation WE 완전히 프린트 영역을 다 채우지 않는 애매한 경우 확인  */
          const pf = await NewMakerCheckerF.findSthInAmbiguousArea({
            product_faces2,
            base_product_size_id,
            base_product_faces,
            ratio: 0.2,
          });
          return (
            !pf ||
            new Promise((resolve) => {
              $.don_loader_end();
              MuiF.openFrame(NewMakerGuideAmbiguousAreaMuiF.frame, (frame, page, [tab]) => {
                frame.is_modal = !MShopUtilF.isMobile();
                tab.makeData = () => {
                  return {
                    base_product_id: getBaseProductInMaker().id,
                    is_mobile: UtilF.isLegacyMobile(),
                  };
                };
                frame.closed = function (frame, data) {
                  if (data) {
                    $.don_loader_start();
                  }
                  resolve(data);
                };
              });
            })
          );
        },
        () => {
          const folding_bp = find(({ bp_id }) => bp_id == bp?.id)(NewMakerWeS.FOLDING_BPS);
          if (folding_bp) {
            /* maker-validation WE 폴딩 접는 부분 관련 */
            return (
              NewMakerCheckerF.isWeFolidngPass() ||
              new Promise((resolve, reject) => {
                $.don_loader_end();
                MuiF.openFrame(NewMakerGuideAlertMuiF.frame, (frame, page, [tab]) => {
                  const is_mobile = MShopUtilF.isMobile();
                  tab.makeData = () => {
                    return {
                      src: is_mobile
                        ? folding_bp['folding_m_url' + G._en]
                        : folding_bp['folding_pc_url' + G._en],
                    };
                  };
                  frame.closed = function (frame, data) {
                    if (data) {
                      $.don_loader_start();
                    }
                    resolve(data);
                  };
                });
              })
            );
          } else return true;
        },
        () => {
          if (!is_ai_not_support_bp) return true;
          const ai_cv_design = NewMakerCheckerF.findAiDesignInOnlyUser();
          /* maker-validation ai 서포트 상품이 아닌데 ai파일 있는 경우 */
          if (ai_cv_design) {
            $.alert(
              T(
                'maker::This product does not support ai file. Please convert the file into png or jpeg format.',
              ),
            );
            const fcanvas = ai_cv_design.canvas;
            go(getRealFcanvass().indexOf(fcanvas), G.mp.maker.editing_canvas, () =>
              G.mp.maker.multiple_select(ai_cv_design),
            );
            return false;
          } else {
            return true;
          }
        },
        () => {
          if (!is_we_product) return true;
          const frame_data = NewMakerGuideImageQualityS.findBp(getBaseProductInMaker().id);
          if (!frame_data) return true;
          /* maker-validation WE 이미지 화소 크기 체크 */
          return (
            NewMakerCheckerF.isImageQualityPass() ||
            new Promise((resolve) => {
              $.don_loader_end();
              MuiF.openFrame(NewMakerGuideImageQualityMuiF.frame, (frame, page, [tab]) => {
                const is_mobile = MShopUtilF.isMobile();
                frame.is_modal = !is_mobile;
                tab.makeData = () => {
                  return { base_product_id: getBaseProductInMaker().id, is_mobile };
                };
                frame.closed = function (frame, data) {
                  if (data) {
                    $.don_loader_start();
                  }
                  resolve(data);
                };
              });
            })
          );
        },
        async () => {
          if (!is_we_product) return true;
          const cv_obj_alignments = makeFilterCvObj(getCurrentFcanvas()._objects, 'cv_obj_alignment');

          const frame_data = NewMakerGuideParallelS.findBp(getBaseProductInMaker().id);
          if (!frame_data) return true;

          /* maker-validation WE 절취선 부분 쪽에 평행한 이미지 있는지 체크 */
          if (cv_obj_alignments?.length > 0) {
            return (
              (await NewMakerCheckerF.isFoldingCardParallalPass(getRealFcanvass())) ||
              new Promise((resolve) => {
                $.don_loader_end();
                MuiF.openFrame(NewMakerGuideParallelMuiF.frame, (frame, page, [tab]) => {
                  const is_mobile = MShopUtilF.isMobile();
                  frame.is_modal = !is_mobile;
                  tab.makeData = () => {
                    return { base_product_id: getBaseProductInMaker().id, is_mobile };
                  };
                  frame.closed = function (frame, data) {
                    if (data) {
                      $.don_loader_start();
                    }
                    resolve(data);
                  };
                });
              })
            );
          }
          return (
            (await NewMakerCheckerF.isParallelCheckPassOnlyWe(getRealFcanvass())) ||
            new Promise((resolve) => {
              $.don_loader_end();
              MuiF.openFrame(NewMakerGuideParallelMuiF.frame, (frame, page, [tab]) => {
                const is_mobile = MShopUtilF.isMobile();
                frame.is_modal = !is_mobile;
                tab.makeData = () => {
                  return { base_product_id: getBaseProductInMaker().id, is_mobile };
                };
                frame.closed = function (frame, data) {
                  if (data) {
                    $.don_loader_start();
                  }
                  resolve(data);
                };
              });
            })
          );
        },
        // NewMakerCheckerF.isCheckWrongTextPass,
        /* maker-validation JP의 경우 한 면이라도 디자인을 해야 함 */
        hasDesignsInOneFaceInJp,
        /* maker-validation 마우스 패드 색상 체크 */
        checkFontColors,
        /* maker-validation 글꼴 체크 */
        checkFontFamily,
        /* maker-validation 프린트 영역 밖에 있는 디자인들 자동 지우기 */
        is_out_of_print_passed,
        /* maker-validation 프린트 영역 안에만 디자인 해야 하는 제품의 경우 40cm 이상인것들 안됌 */
        is_oversized_checker_passed,

        async () => {
          /* 현재
                    '아웃도어 스테인리스 보틀 12oz (핸들 타입)', '아웃도어 스테인리스 보틀 12oz (스포츠 타입)', '아웃도어 스테인리스 보틀 18oz (스포츠 타입)', '아웃도어 스테인리스 보틀 18oz (핸들 타입)'
                     5796, 5795, 5793, 5794
                     상품만 해당됌 */
          /*바깥쪽으로 나간게 있는지 검사*/
          const base_product_faces = go(
            box().maker.product_color._.base_product_color._.base_product_color_faces,
            map(sel('_.base_product_face')),
          );
          const { product_faces2, base_product_size_id } = box().maker.product_color;
          const pf = await NewMakerCheckerF.findSthInOuterPrintArea({
            product_faces2,
            base_product_size_id,
            base_product_faces,
          });
          if (!pf) return true;
          const sf = NewMakerCheckerS.getSizeFace({
            base_product_faces,
            base_product_size_id,
            base_product_face_id: pf.bpf_id,
          });
          const { overflow_of_print_area_m_url, overflow_of_print_area_pc_url } = sf.print.etc_meta;
          /* overflow_of_print_area_m_url, overflow_of_print_area_pc_url 뭔지 알아보자 */
          if (!overflow_of_print_area_m_url && !overflow_of_print_area_pc_url) {
            return go($.confirm('점선 밖은 잘립니다.(알림창 필요)'), async (bool) => {
              if (!bool) return true;
              else {
                await moveToFcanvasIncludingSwiper(getFcanvasByBpfId(pf.bpf_id));
                return false;
              }
            });
          }
          return new Promise((resolve, reject) => {
            $.don_loader_end();
            MuiF.openFrame(NewMakerGuideAlertMuiF.frame, (frame, page, [tab]) => {
              const is_mobile = MShopUtilF.isMobile();
              tab.makeData = () => {
                return {
                  src: is_mobile
                    ? sf.print.etc_meta['overflow_of_print_area_m_url' + G._en]
                    : sf.print.etc_meta['overflow_of_print_area_pc_url' + G._en],
                };
              };
              frame.closed = function (frame, data) {
                if (data) {
                  $.don_loader_start();
                }
                resolve(data);
              };
            });
          });
        },
        async () => {
          if (BpOptionUploadTypeS.UPLOAD_TYPE.isEditorType(getProductColorInMaker())) return true;
          /* 현재
                    '아웃도어 스테인리스 보틀 12oz (핸들 타입)', '아웃도어 스테인리스 보틀 12oz (스포츠 타입)', '아웃도어 스테인리스 보틀 18oz (스포츠 타입)', '아웃도어 스테인리스 보틀 18oz (핸들 타입)'
                     5796, 5795, 5793, 5794
                     상품만 해당됌 */
          /*프린트 영역 100px 안쪽으로 이미지가 있는지 있는지 검사*/
          const base_product_faces = go(
            box().maker.product_color._.base_product_color._.base_product_color_faces,
            map(sel('_.base_product_face')),
          );
          const { product_faces2, base_product_size_id } = box().maker.product_color;
          const pf = await NewMakerCheckerF.findSthInInnerPrintArea({
            product_faces2,
            base_product_size_id,
            base_product_faces,
          });
          if (!pf) return true;
          const sf = NewMakerCheckerS.getSizeFace({
            base_product_faces,
            base_product_size_id,
            base_product_face_id: pf.bpf_id,
          });
          const { possible_of_fitted_print_area_m_url, possible_of_fitted_print_area_pc_url } =
            sf.print.etc_meta;
          /* possible_of_fitted_print_area_m_url, possible_of_fitted_print_area_pc_url 뭔지 알아보자 */
          if (!possible_of_fitted_print_area_m_url && !possible_of_fitted_print_area_pc_url)
            return go(
              $.confirm(
                T(
                  'maker::Message::Please fill in the design to the outer dotted line to full print the image.',
                ),
              ),
              async (bool) => {
                if (!bool) return true;
                else {
                  await moveToFcanvasIncludingSwiper(getFcanvasByBpfId(pf.bpf_id));
                  return false;
                }
              },
            );
          return new Promise((resolve, reject) => {
            $.don_loader_end();
            MuiF.openFrame(NewMakerGuideAlertMuiF.frame, (frame, page, [tab]) => {
              const is_mobile = MShopUtilF.isMobile();
              tab.makeData = () => {
                return {
                  src: is_mobile
                    ? sf.print.etc_meta['possible_of_fitted_print_area_m_url' + G._en]
                    : sf.print.etc_meta['possible_of_fitted_print_area_pc_url' + G._en],
                };
              };
              frame.closed = function (frame, data) {
                if (data) {
                  $.don_loader_start();
                }
                resolve(data);
              };
            });
          });
        },
        /* maker-validation 패치 위에 디자인을 올렸는지 체크 */
        isEmbroCheckerPassedA,
        /* maker-validation 특수 색깔 위에 디자인을 올렸는지 체크 */
        isSpecialCharacterCheckerPassedA,
        /* maker-validation 점선 밖으로 나가면 안되는 제품에서 점선에 걸쳐 있는것들 체크 */
        is_overflow_on_line_checker_passed,
        /* maker-validation 비즈 상품 바닥면 관련 체크 */
        checkAboutBeads,
        /* maker-validation 문제 있는 글자 체크 */
        isCheckWrongTextPass,
        () => {
          const config = NewMakerBaseProductsEmbroideryConstantS.PROPERTY.getConf(
            NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
            bp.id,
          );
          if (!config) return true;
          if (!config.char_length_in_line) return true;
          const check_char_length = go(
            getProductFaces2InMaker(),
            every((pf) => {
              return go(
                pf.designs,
                every((design) => {
                  return NewMakerBaseProductsEmbroideryConstantS.PROPERTY.checkCharLengthInLine(
                    design._data.text_info.text,
                    config.char_length_in_line,
                  );
                }),
              );
            }),
          );
          if (check_char_length) return true;
          $.alert(
            NewMakerBaseProductsEmbroideryConstantS.PROPERTY.lengthAlertMessage(config.char_length_in_line),
          );
        },
      ],
      every((func) => func()),
      tap(async function () {
        G.mp.maker.reset_box_data();
        await G.mp.maker.reset_layer();
      }),
    );
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(
      getProductColorInMaker(),
      e,
      MakerDebugConstantS.Category.PostMaker,
      'Validation',
    );
    $.don_loader_end();
  }
};

// export const check_all_in_all_sizes2 = async () => {
//   const can_overflow = isCanOverflow();
//   box.sel('maker->product_color').overflow_sizes = [];
//   if (can_overflow) {
//     return;
//   }
//   const designed_bpf_ids = go(
//     getRealFcanvass(),
//     filter((fcanvas) => {
//       const designs = G.mp.maker.designs(fcanvas);
//       return designs.length;
//     }),
//     pluck('bpf_id'),
//   );
//   const target_bpcfs = go(
//     box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces'),
//     filter((bpcf) => designed_bpf_ids.includes(bpcf.base_product_face_id) && bpcf.no < 3),
//   );
//   const has_overflow_size = await some(function (bpcf) {
//     return go(
//       bpcf,
//       sel('_.base_product_face.size_faces'),
//       take1,
//       some(async function (sf) {
//         await new Promise((resolve) => setTimeout(resolve, 200));
//         return trimed_checker(
//           go(
//             box.sel('maker->canvass'),
//             find((fcanvas) => fcanvas.bpf_id === bpcf.base_product_face_id),
//           ),
//           sf,
//         );
//       }),
//     );
//   }, target_bpcfs);
//   if (has_overflow_size) {
//     await go(
//       target_bpcfs,
//       map(function (bpcf) {
//         return go(
//           bpcf,
//           sel('_.base_product_face.size_faces'),
//           map(async function (sf) {
//             await new Promise((resolve) => setTimeout(resolve, 200));
//             return {
//               base_product_size_id: sf.base_product_size_id,
//               is_overflow: trimed_checker(
//                 go(
//                   box.sel('maker->canvass'),
//                   find((fcanvas) => fcanvas.bpf_id === bpcf.base_product_face_id),
//                 ),
//                 sf,
//               ),
//             };
//           }),
//         );
//       }),
//       flatten,
//       filter(sel('is_overflow')),
//       map(sel('base_product_size_id')),
//       uniq,
//       function (arr) {
//         box.sel('maker->product_color').overflow_sizes = arr;
//       },
//     );
//   } else {
//     box.sel('maker->product_color').overflow_sizes = [];
//   }
// };

function getOverflowedBpSizes() {
  return go(
    box.sel('maker->product_color->product_faces2->value'),
    map(function (pf) {
      const base_product_size_id = box.sel('maker->product_color->base_product_size_id');
      const sf = go(
        pf.size_faces,
        find((sf) => sf.base_product_size_id === base_product_size_id),
      );
      const size_faces = pf.size_faces;
      if (
        sf.start_point_cm != null &&
        sf.start_point_cm > 0 &&
        Number.isFinite(pf.design_size?.from_print_top_to_design_cm)
      ) {
        const {
          from_print_top_to_design_cm: y,
          from_print_left_to_design_cm: x,
          width_cm: width,
          height_cm: height,
        } = pf.design_size;
        const basic_sf_center_x = sf.print.cm.width / 2;
        const left_x = Math.floor(basic_sf_center_x - x);
        const right_x = Math.floor(x + width - basic_sf_center_x);
        const top_y = y + height;
        if (Number.isNaN(left_x) || Number.isNaN(right_x) || Number.isNaN(top_y)) {
          axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
            json_memo: {
              base_product_size_id,
            },
            name: 'maker_extra_getOverflowedBpSizes',
          });
          return [];
        }
        return go(
          size_faces,
          map(async function (sf) {
            const { width: sf_width, height: sf_height } = sf.print.cm;
            return {
              base_product_size_id: sf.base_product_size_id,
              is_overflow: go(
                [sf_width / 2 - left_x, sf_width / 2 - right_x, sf_height - top_y],
                some((n) => n < 0),
              ),
              base_product_face_id: pf.bpf_id,
            };
          }),
        );
      }
    }),
    flatten,
    compact,
    filter(sel('is_overflow')),
  );
}

export const check_all_in_all_sizes3 = () => {
  try {
    const can_overflow = isCanOverflow();
    box.sel('maker->product_color').overflow_sizes = [];
    if (can_overflow) {
      return;
    }
    go(getOverflowedBpSizes(), map(sel('base_product_size_id')), uniq, function (arr) {
      box.sel('maker->product_color').overflow_sizes = arr;
    });
  } catch (e) {
    box.sel('maker->product_color').overflow_sizes = [];
    axios.post(`/${T.lang}/@api/prerequisite_maker/error_logs`, {
      json_memo: {
        stack: e.stack && JSON.stringify(e.stack),
      },
      name: 'maker_extra_check_all_in_all_sizes3',
    });
  }
};

export const hasEmbroInMaker = function (cv_object) {
  return deepFindCvObjects(cv_object, isEmbro);
};

export const hasOnlyFlexInMaker = function (cv_object) {
  return deepFindCvObjects(cv_object, isOnlyFlex);
};

export const hasCvImageInMaker = function (cv_object) {
  return deepFindCvObjects(cv_object, isCvImage);
};

export const isSameDesignSize = ({ design_size, canvas, dpi }) => {
  const trimmed_size = getJustifyImageSize({ canvas });
  const width = trimmed_size.w / (dpi / 2.54);
  const height = trimmed_size.h / (dpi / 2.54);
  return Math.abs(design_size.width_cm - width) <= 0.2 && Math.abs(design_size.height_cm - height) <= 0.2;
};

async function makeDesignSize({ pf, sf }) {
  const design_size = {};
  const px_per_1cm = sf.px_per_1cm;
  const canvas = await makeOnlyDesignFaceCanvasByPrintArea({
    product_face: pf,
    print_area: sf.print.px,
    width: sf.print.px.width,
  });
  const trimmed_size = getJustifyImageSize({ canvas });
  const ctx = canvas.getContext('2d');
  /* 시보리 수치 계산 */
  if (sf.start_point_cm != null && sf.start_point_cm > 0) {
    const top = trimmed_size.y / px_per_1cm;
    const left = trimmed_size.x / px_per_1cm;
    design_size.distance_cm_from_rib = parseFloat((top + sf.start_point_cm).toFixed(1));
    design_size.from_print_top_to_design_cm = parseFloat(top.toFixed(1));
    design_size.from_print_left_to_design_cm = parseFloat(left.toFixed(1));
  }

  /* justify 이미지 사이즈 계산 */
  if (trimmed_size.w > 0 && trimmed_size.h > 0) {
    const width = trimmed_size.w / px_per_1cm;
    const height = trimmed_size.h / px_per_1cm;

    const width_cm = parseFloat(width.toFixed(1));
    const height_cm = parseFloat(height.toFixed(1));

    design_size.width_cm = width_cm;
    design_size.height_cm = height_cm;
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  return design_size;
}

export async function setPfDesignSize({ product_faces2, base_product_size_id }) {
  try {
    await go(
      product_faces2.value,
      filter((pf) => pf.designs?.length),
      each(async (pf) => {
        const sf = go(
          pf.size_faces,
          find((sf) => sf.base_product_size_id === base_product_size_id),
        );
        pf.design_size = await makeDesignSize({ pf, sf });
      }),
    );
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(
      getProductColorInMaker(),
      e,
      MakerDebugConstantS.Category.PostMaker,
      'setPfDesignSize',
    );
  }
}

export async function isMakerDone() {
  const is_don_loader_wrap_started = !!$qs('.don_loader_wrap');
  G.mp.maker.cleared_pass = true;
  G.mp.maker.unselect_all();
  G.mp.maker.cleared_pass = false;
  await G.mp.maker.cleared();
  await go(
    getRealFcanvass(),
    each(async (fcanvas) => {
      await addCvPreview(fcanvas);
    }),
  );
  G.mp.maker.remove_all_empty_text();
  checkAllFcanvasDesignSize();
  G.mp.maker.all_reset_data_cm();
  await makeDataCmAndChangeCvTextResetBoxDataA();
  if (is_don_loader_wrap_started) $.don_loader_start();

  const product_color = box.sel('maker->product_color');

  if (
    BpOptionUploadTypeS.UPLOAD_TYPE.isEditorType(product_color) &&
    NewMakerPropertyBaseProductS.pdfFilePossible.isIt(product_color._.base_product)
  ) {
    const is_pdf_pass = await NewMakerPropertyBaseProductF.pdfFilePossible.mpMakerUpload(
      getProductColorInMaker(),
    );
    if (!is_pdf_pass) {
      await alertProEditorCsError({ title: TT('pro_editor::alert::title::dev') });
      return;
    }
  }

  return go(isProductColorInGood(), async function (is_good) {
    if (!is_good) {
      return false;
    }

    await NewMakerCvObjectUtilF.convertAllBase64ToFcanvas();
    await makeDataCmAndChangeCvTextResetBoxDataA();

    const can_overflow = isCanOverflow();
    if (!can_overflow) {
      await setPfDesignSize({
        product_faces2: box().maker.product_color.product_faces2,
        base_product_size_id: box().maker.product_color.base_product_size_id,
      });
    }
    // eslint-disable-next-line no-async-promise-executor
    const is_masking_pass_preview = await new Promise(async (resolve) => {
      const product_color = box().maker.product_color;
      if (product_color._.base_product.maker_type !== BpOptionConstantS.MASKING_TAPE_EDITOR)
        return resolve(true);
      // const need_check = await trimed_checker(
      //   box.sel('maker->canvass')[0],
      //   product_color.product_faces2.value[0].size_faces[0],
      // );
      const has_wrong_fitted_object = hasWrongFittedObj(product_color.product_faces2.value);
      if (has_wrong_fitted_object) {
        await resetCloneHorizontalCvObj();
        G.mp.maker.reset_box_data();
      }

      const canvas = await NewMakerPreviewModalF.makeMaskingTapePreviewThumbnail({
        product_face: product_color.product_faces2.value[0],
        base_product_size_id: product_color.base_product_size_id,
        canvas_width: 900,
      });
      const data_url = canvas.toDataURL();
      if (UtilS.isNessApp() && $qs('html.ness-custom-maker')) {
        return NewMakerCompositePreviewF.openTempMaskingTapePreview(product_color, data_url, resolve);
      }
      MuiF.openFrame(NewMakerPreviewModalMuiF.frame, (frame, page, [tab]) => {
        frame.is_modal = !UtilF.isLegacyMobile();
        tab.makeData = () => {
          return {
            data_url,
          };
        };
        frame.closed = async (frame_el, is_good) => {
          $.don_loader_start();
          if (!is_good) {
            resolve(false);
            setTimeout(() => {
              const confirm_popup = NewMakerGuideConfirmF.makeConfirmPopupPrams(getBaseProductInMaker());
              if (confirm_popup) return NewMakerGuideConfirmF.open(confirm_popup);
            }, 100);
            return;
          }
          if (is_good) {
            product_color.product_faces2.value[0].preview_thumbnail_url = await go(
              $.uploadFileToUrl(data_url, 'preview_image', 'png'),
              sel('url'),
            );
            resolve(true);
          } else {
            $.alert('Error');
            resolve(false);
          }
        };
      });
    });
    if (!is_masking_pass_preview) return false;
    await go(
      box.sel('maker->product_color->product_faces2->value'),
      filter(sel('cv_preview')),
      each(async (pf) => {
        const { url } = await $.uploadFileToUrl(pf.cv_preview.src, 'cv_preview');
        pf.cv_preview.src = url;
        if (pf.cv_preview._data.shade_material) {
          await NewMakerPropertyBpfF.common.shadeMaterial.uploadShadeMaterialData(pf.cv_preview);
        }
      }),
    );

    const product_color = getProductColorInMaker();
    /*이 밑으로는 reset_data_box가 울리면 안된다. 마지막 후 처리 */
    if (
      product_color._.base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.NEED_PRINT_IMAGE_URL]
    ) {
      await NewMakerPropertyBaseProductF.bpOptionMakerMaterial.setProductFaces2PrintImageUrl(product_color);
    }
    /* ganging_spec 과 printable_info 가 maker_meta 에 있는 제품만 가능*/

    const maker_material_info =
      NewMakerPropertyBaseProductF.bpOptionMakerMaterial.getMakerMaterialInfo(product_color);
    if (maker_material_info) {
      await go(
        product_color.product_faces2.value,
        mapC(async (pf) => {
          const data_url = await go(
            createCanvasElement({ width: 800, height: 800 }),
            (c) => drawProductFace(c, pf, undefined, false, true),
            (c) => c.toDataURL(),
          );
          pf.preview_thumbnail_url = await go(
            $.uploadFileToUrl(data_url, 'preview_image', 'png'),
            sel('url'),
          );
          pf.cv_bpcf.src = maker_material_info.base_product_color_face.url;
          pf.cv_bpcf.cv_shading = maker_material_info.base_product_face.shading_url;
          if (JSON.stringify(pf).includes('base64')) throw new Error('base64 is included');
        }),
      );
    }

    checkCvObjFullOverBp();
    setPfColllaboTypeBpsId(
      box.sel('maker->product_color'),
      collabo_type,
      box.sel('maker->product_color->base_product_size_id'),
    );

    if (
      go(
        box.sel('maker->product_color->product_faces2->value'),
        filter((pf) => pf.is_design_over_bpf),
        (arr) =>
          arr.length &&
          box.sel('maker->product_color->product_faces2->value').length !== getRealFcanvass().length,
      )
    ) {
      if (!(await $.confirm(T('maker::Message::go_to_not_designed_fcanvas')))) {
        await NewMakerUtilF.goToNotDesignedFcanvas();
        $.don_loader_end();
        return;
      }
    }

    /* 마지막 중요한것들 체크 */
    if (
      product_color._.base_product.maker_features?.[NewMakerPropertyBaseProductConstantS.NEED_PRINT_IMAGE_URL]
    ) {
      if (!NewMakerPropertyBaseProductS.needPrintImageUrl.validate(product_color))
        throw new Error('문제가 발생했습니다. [NPIU]');
    }
    /*여기*/
    return true;
  });
}
