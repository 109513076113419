import axios from 'axios';
import {
  $addClass,
  $attr,
  $closest,
  $find,
  $qs,
  $remove,
  $setAttr,
  $setCss,
  $setText,
  $text,
} from 'fxdom/es';
import { go, each, extend, map, sel } from 'fxjs/es';
import querystring from 'querystring';
import { rune } from 'rune-ts';
import { CheerUpCard } from '../../../../../../services/MarppleShop/renderApp/components/cells/CheerUpCard/CheerUpCard.ts';
import { Confirm } from '../../../../../../services/MarppleShop/renderApp/components/cells/Confirm/Confirm.ts';
import { Header } from '../../../../../../services/MarppleShop/renderApp/components/cells/Header/Header.ts';
import { LayoutView } from '../../../../../../services/MarppleShop/renderApp/components/cells/Layout/LayoutView.ts';
import { preventEscape } from '../../../../../../services/MarppleShop/shared/util/preventEscape.ts';
import { pushLoginStack } from '../../../../../Creator/Login/F/fs.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { UtilF } from '../../../../../Util/F/Function/module/UtilF.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { makeApiUrl } from '../../../../../Util/S/Function/util.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { ARTICLE_TEXT_TYPES } from '../../../Store/Community/S/Constant/community.js';
import { MShopAppCommunityDetailMuiF } from '../../Detail/F/Mui/module/MShopAppCommunityDetailMuiF.js';
import { MShopAppCommunityTmplS } from '../../S/Tmpl/module/MShopAppCommunityTmplS.js';

export const makeInfiniteOptions = ({
  limit,
  api_url,
  container_el,
  is_ie,
  is_mobile,
  is_store,
  column_count,
  hooks,
  initial_load,
  makeItemFunc,
  pause,
  item_margin,
  is_ness,
  is_my,
}) => {
  return {
    getItems: async (page) => {
      try {
        return await go(
          axios.get(api_url, {
            params: {
              limit,
              offset: page * limit,
            },
          }),
          ({ data: { articles, is_mp_worker } }) => {
            return map((a) => extend(a, { is_mp_worker }), articles);
          },
        );
      } catch (e) {
        return [];
      }
    },
    makeItemHtml:
      makeItemFunc ||
      ((article) => {
        if (is_ness) {
          return MShopAppCommunityTmplS.nessReviewItem({
            article,
            is_ie,
            store: sel('_.store', article),
            is_store,
            is_mp_worker: article.is_mp_worker,
            is_mobile,
            is_my,
          });
        }
        return MShopAppCommunityTmplS.item({
          article,
          is_ie,
          store: sel('_.store', article),
          is_store,
          is_mp_worker: article.is_mp_worker,
          is_mobile,
          is_my,
        });
      }),
    item_margin: item_margin || (is_mobile ? 7 : 10),
    initial_load,
    uniqueSel: sel('id'),
    getColumnCount: () => column_count,
    container_el,
    hooks,
    pause,
    makeDividerHtml: () => {},
  };
};

const setArticleInfo = ({ article, article_el }) => {
  if (!article_el || !article) return;

  $setAttr({ is_like: article.is_like }, $find('.article-item-control__btn_like', article_el));
  $setText(article.like_count, $find('.article-item-control__btn_like', article_el));
  $setText(article.comment_count, $find('.article-item-control__btn_comment', article_el));
};

export const hideParentEl = (el) => $setCss({ opacity: 0 }, $closest('.don_frame', el));
export const showParentEl = (el) => $setCss({ opacity: 1 }, $closest('.don_frame', el));

export const openDetail = async ({
  article_id,
  parent_items,
  parent_cont_el,
  no_replace_url,
  title,
  is_product_review,
  is_list,
  is_rune,
}) => {
  try {
    const url = makeApiUrl('/:lang/@api/articles/:article_id', {
      lang: T.lang,
      article_id,
    });

    const {
      data: { result, article },
    } = await axios.get(url);
    if (!result) {
      location.replace(location.pathname);
      await Promise.reject(new Error('no data'));
    }

    MuiF.openFrame(MShopAppCommunityDetailMuiF.frame, async (f, p, [t]) => {
      const is_mobile = MShopUtilF.isMobile();
      const header_el = document.querySelector('.Header');
      const header_view = header_el && rune.getView(header_el, Header);

      const layout_options = rune.getView($qs(`.${LayoutView}`), LayoutView).state;

      t.makeData = async () => {
        return {
          article,
          type: article.type,
          title,
          store_id: article.store_id,
          login_user: box.sel('is_user'),
          store_user_id: sel('_.store.user_id', article),
          is_mobile,
          is_android: MShopUtilF.isCreatorAndroidApp(),
          is_ness: false,
          is_product_review,
          is_list,
          can_edit: article.user_id == box.sel('is_user->id'),
          layout_options,
        };
      };

      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
      f.is_modal = !is_mobile;

      f.appending = () => {
        // fixme @yjj 좋은 방법 있으면 바꾸고싶다.
        /* 헤더가 나올때까지 감시하고, 나오면 강제로 unpinned */
        if (header_view && header_view.state.headroom.use) {
          UtilF.runFunctionUntilTrueRaf(() => {
            return header_view?.setForceUnpinned();
          }, 100);
        }
      };

      f.appended = (frame_el) => {
        if (ARTICLE_TEXT_TYPES.includes(article.type))
          return $addClass('article-detail-text-frame', frame_el);
        if (is_product_review) return $addClass('article-detail-product-frame', frame_el);

        return frame_el;
      };

      f.closing = (frame_el) => {
        if (is_rune) {
          if (is_mobile) showParentEl(parent_cont_el);
          return;
        }

        const article_id = $attr('data-article_id', $find('.article-detail', frame_el));
        const is_like = $attr('is_like', $find('.article-detail-info__btn_like', frame_el)) === 'true';
        const like_count = UtilS.trim($text($find('.article-detail-info__btn_like', frame_el)));
        const comment_count = UtilS.trim($text($find('.article-detail-comments__count', frame_el)));

        const article = {
          id: article_id,
          is_like,
          like_count,
          comment_count,
        };

        each((item) => {
          setArticleInfo({ article, article_el: item });
        }, parent_items);

        if (is_mobile) showParentEl(parent_cont_el);

        if ($qs('.detail-control-layer')) {
          $remove($qs('.detail-control-layer'));
        }

        CheerUpCard.redrawCheerUpControls(article_id, is_like, like_count, comment_count);

        return frame_el;
      };

      f.showed = (frame_el) => {
        if (is_mobile) hideParentEl(parent_cont_el);
        const query = querystring.parse(location.search.slice(1));
        if (!MShopUtilF.isApp() && !no_replace_url)
          MuiF.extendHistoryState(
            null,
            null,
            `${location.pathname}?${querystring.stringify(extend(query, { a_id: article_id }))}`,
            true,
          );
      };

      f.closed = async (frame_el, close_data) => {
        if (close_data?.reload) {
          location.reload();
        }
      };
    });
  } catch (err) {
    location.replace(location.pathname);
  }
};

export const tsOpenDetail = async (data) => {
  await openDetail(data);
};

export const openDetailNess = async ({
  article_id,
  parent_cont_el,
  no_replace_url,
  title,
  is_product_review,
  is_list,
}) => {
  try {
    const url = makeApiUrl('/:lang/@api/articles/:article_id', {
      lang: T.lang,
      article_id,
    });

    const {
      data: { result, article },
    } = await axios.get(url);
    if (!result) {
      location.replace(location.pathname);
      await Promise.reject(new Error('no data'));
    }

    MuiF.openFrame(MShopAppCommunityDetailMuiF.frame, async (f, p, [t]) => {
      const is_mobile = MShopUtilF.isMobile();
      t.makeData = async () => {
        return {
          article,
          type: article.type,
          title,
          store_id: article.store_id,
          login_user: box.sel('is_user'),
          store_user_id: sel('_.store.user_id', article),
          is_mobile,
          is_android: MShopUtilF.isCreatorAndroidApp(),
          is_ness: true,
          is_product_review,
          is_list,
        };
      };

      f.always_remove = true;
      f.hide_frame_button_position = 'RIGHT';
      f.hide_frame_button_type = 'X';
      f.is_modal = !is_mobile;
      f.header_height = is_mobile ? 60 : 72;
      f.title = 'Review Detail';

      f.appended = (frame_el) => {
        if (ARTICLE_TEXT_TYPES.includes(article.type))
          return $addClass('article-detail-text-frame', frame_el);
        if (is_product_review) return $addClass('article-detail-product-frame', frame_el);
        return frame_el;
      };

      f.closing = (frame_el) => {
        if (is_mobile) showParentEl(parent_cont_el);

        if ($qs('.detail-control-layer')) {
          $remove($qs('.detail-control-layer'));
        }

        return frame_el;
      };

      f.showed = (frame_el) => {
        if (is_mobile) hideParentEl(parent_cont_el);
        const query = querystring.parse(location.search.slice(1));
        if (!MShopUtilF.isApp() && !no_replace_url)
          MuiF.extendHistoryState(
            null,
            null,
            `${location.pathname}?${querystring.stringify(extend(query, { a_id: article_id }))}`,
            true,
          );
      };
    });
  } catch (err) {
    location.replace(location.pathname);
  }
};

const createLikeHandler = () => {
  let timer = null;
  let initialState = null;

  const sendApiRequest = async ({ store_id, article_id, button_el }) => {
    try {
      const post_url = makeApiUrl(`/@api/stores/:store_id/like`, { store_id });
      const currentState = $attr('is_like', button_el) === 'true';

      // 초기 상태와 현재 상태가 다를 때만 API 호출
      if (currentState !== initialState) {
        const response = await $.post(post_url, {
          is_like: currentState,
          attached_id: article_id,
          attached_type: 'articles',
        });

        if (!response.result) throw new Error('API call failed');
      }
    } catch (error) {
      // 에러 시 초기 상태로 복구
      $setAttr({ is_like: initialState }, button_el);
      $setText(Number($text(button_el)) + (initialState ? 1 : -1), button_el);
      MShopUtilF.popToastMsg(T('community::실패'), 'error');
    } finally {
      timer = null;
      initialState = null;
    }
  };

  return async (e) => {
    const button_el = e.currentTarget;
    const container_el = $closest('.cheer-up-item', button_el) || $find('.article-detail', e.delegateTarget);
    const store_id = $attr('data-store_id', container_el);
    const article_id = $attr('data-article_id', container_el);

    // 타이머 없을 때만 초기 상태 저장
    if (!timer) {
      initialState = $attr('is_like', button_el) === 'true';
    } else {
      clearTimeout(timer);
    }

    if (!box.sel('is_user->id')) {
      if (
        await Confirm.open({
          title: ET('mps2::login::need_login::title'),
          message: preventEscape(ET('mps2::login::need_login::message')),
          confirmText: ET('mps2::login::login'),
          cancelText: ET('mps2::signup::close'),
        })
      ) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else if (box.sel('store_url')) {
          location.href = `${box.sel('store_url')}/login?url=${box.sel(
            'store_url',
          )}/community?a_id=${article_id}`;
        } else {
          location.href = `/${T.lang}/login?url=/${T.lang}/?a_id=${article_id}`;
        }
      }
      return;
    }

    const new_is_liked = !($attr('is_like', button_el) === 'true');
    $setAttr({ is_like: new_is_liked }, button_el);
    $setText(Number($text(button_el)) + (new_is_liked ? 1 : -1), button_el);

    timer = setTimeout(() => {
      sendApiRequest({
        store_id,
        article_id,
        button_el,
      });
    }, 300);
  };
};

export const articleLike = createLikeHandler();
