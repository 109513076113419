import { map, html, includes, reject, sel, strMap } from 'fxjs/es';
import {
  makeCloseIcon,
  makeFAQIcon,
} from '../../../../services/MarppleShop/marppleShopStudioApp/components/atoms/Icon/index.ts';
import { typo } from '../../../../services/MarppleShop/shared/typography/typo.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import {
  LNB_BOTTOM_MENUS,
  LNB_INVITE_MENU,
  LNB_IS_NOT_AGREE_MENUS,
  LNB_MID_MENUS,
  LNB_TOP_CUSTOM_SWITCH_MENU,
  LNB_TOP_MENUS,
  MPS_LNB_MENUS,
} from './lnb-data.ts';
import { messages } from './messages_id.js';
import settings_klass from '../F/setting_menu.module.scss';
import { CreatorOnboardingBanner } from '../../../../services/MarppleShop/marppleShopStudioApp/components/cells/CreatorOnboardingBanner/CreatorOnboardingBanner.ts';

/**
 * @param link
 * @param user
 * @param domain_name
 * @param active_page_name
 * @param title
 * @param title_suffix {string}
 * @param page_name
 * @param icon
 * @param dataShow
 * @param blank
 * @param is_app
 * @param question_count
 * @return {*}
 */
const makeLi = ({
  link,
  user,
  domain_name,
  active_page_name,
  title,
  title_suffix,
  page_name,
  icon,
  dataShow,
  blank,
  is_app,
  question_count,
  klass,
}) => {
  const is_question_count_show = question_count > 0 && page_name === 'question-list';

  return html`<li data-show="${!dataShow || dataShow(user, is_app)}">
    <a
      class="menu${page_name == 'question-list' ? ' question-list-a' : ''} ${settings_klass.menu} ${klass ??
      ''}"
      href="${blank
        ? blank(domain_name) + '" target="_blank'
        : `/${T.lang}/${UtilS.escape(domain_name)}/settings/${link}`}"
      data-active="${active_page_name === page_name}"
    >
      ${icon ? html`<span class="${settings_klass.icon}">${icon}</span>` : ''}
      <span class="${typo('14_medium')}">
        ${typeof title === 'string' ? T(title, { br: '<br />' }) : title}
      </span>
      ${title_suffix ? html`<span class="${settings_klass.title_suffix}">${title_suffix}</span>` : ''}
      ${is_question_count_show ? html`<span class="setting-menu-count">${question_count}</span>` : ''}
    </a>
  </li>`;
};

const makeMenu =
  ({ question_count, domain_name, active_page_name, user, is_app, klass = '' }) =>
  ({ title, title_suffix, page_name, icon, dataShow, collapses, blank, link }) => {
    if (collapses?.length) {
      return html`<li data-show="${!dataShow || dataShow(user, is_app)}">
        <div
          is="collapse"
          class="collapse${includes(active_page_name, collapses.map(sel('page_name')))
            ? ' opened'
            : ''} ${klass}"
        >
          <a class="menu collapse-anchor ${settings_klass.menu}">
            <span class="${typo('14_medium')}">
              ${icon ? html`<span class="${settings_klass.icon}">${icon}</span>` : ''}
              ${typeof title === 'string' ? T(title, { br: '<br />' }) : title}
              ${title_suffix ? html`<span class="${settings_klass.title_suffix}">${title_suffix}</span>` : ''}
            </span>
          </a>
          <div class="collapse-content">
            <ul>
              ${strMap((data) => {
                return makeLi({
                  user,
                  domain_name,
                  active_page_name,
                  is_app,
                  question_count,
                  ...data,
                });
              }, collapses)}
            </ul>
          </div>
        </div>
      </li>`;
    } else {
      return makeLi({
        user,
        domain_name,
        active_page_name,
        title,
        title_suffix,
        link,
        page_name,
        icon,
        dataShow,
        blank,
        is_app,
        question_count,
      });
    }
  };

const hide_menu_ids_for_afreeca_crew = [
  'seller_products',
  'digital_product',
  'question_list',
  'shop_management',
  'my_shop',
  'youtube',
  'seller_guide',
  'solution',
];
const makeMenusHtml = ({
  question_count,
  domain_name,
  page_name: active_page_name,
  user,
  is_app,
  is_user_custom_enable,
  creator_studio,
}) => {
  const is_afreeca_crew = user._.crew.id === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_ID;
  const is_ness = UtilS.isNessApp();

  if (sel('_.store.invited_store_id', user) && !sel('_.store.is_invite_info', user)) {
    return makeMenu({ domain_name, active_page_name, user, is_app })(LNB_INVITE_MENU);
  }

  if (!sel('_.store.is_agree', user)) {
    return strMap(
      makeMenu({ question_count, domain_name, active_page_name, user, is_app }),
      LNB_IS_NOT_AGREE_MENUS,
    );
  }

  if (is_ness) {
    return html`
      ${strMap(
        makeMenu({ question_count, domain_name, active_page_name, user, is_app }),
         is_user_custom_enable
          ? map((menu) => (menu.id === 'make_products' ? LNB_TOP_CUSTOM_SWITCH_MENU : menu), LNB_TOP_MENUS)
          : LNB_TOP_MENUS,
      )}
      <li class="divider"></li>
      ${strMap(makeMenu({ question_count, domain_name, active_page_name, user, is_app }), LNB_MID_MENUS)}
      <li class="divider"></li>
      <li class="for-the-seller">FOR THE CREATOR</li>
      ${strMap(
        ({ dataShow, blank, link, title, title_suffix, klass = '' }) => html` <li
          data-show="${!dataShow || dataShow(user, is_app)}"
        >
          <a
            class="menu-bottom ${klass}"
            href="${blank
              ? blank(domain_name) + '" target="_blank'
              : `/${T.lang}/${UtilS.escape(domain_name)}/settings/${link}`}"
          >
            ${T(title, { br: '<br />' })}
            ${title_suffix ? html`<span class="${settings_klass.title_suffix}">${title_suffix}</span>` : ''}
          </a>
        </li>`,
        LNB_BOTTOM_MENUS,
      )}
      <li class="menu-live-chat live_chat">${T(messages.menus.contact_marppleshop)}</li>
    `;
  }

  return html`
    <ul class="${settings_klass.menu_top}">
      ${strMap(
        makeMenu({ question_count, domain_name, active_page_name, user, is_app }),
        is_afreeca_crew
          ? reject((menu) => {
              return hide_menu_ids_for_afreeca_crew.includes(menu.id);
            }, MPS_LNB_MENUS)
          : MPS_LNB_MENUS,
      )}
    </ul>
    <ul class="${settings_klass.menu_bottom}">
      ${creatorStudioOnboardingHtml({ creator_studio, domain_name })}
      <li class="live_chat ${settings_klass.live_chat} ${typo('14_medium')}">
        <span class="${settings_klass.icon}">${makeFAQIcon({ html })}</span>${T(
          messages.menus.contact_marppleshop,
        )}
      </li>
    </ul>
  `;
};

/**
 * @param data {{ domain_name: string; creator_studio?: Locals['creator_studio']  }}
 * */
const creatorStudioOnboardingHtml = ({ creator_studio, domain_name }) => {
  if (!creator_studio?.onboarding || creator_studio.onboarding.is_completed || T.lang !== 'kr') {
    return '';
  }

  return html` <li class="${settings_klass.onboarding}">
    ${new CreatorOnboardingBanner({
      ...creator_studio.onboarding,
      link: `/${T.lang}/${UtilS.escape(domain_name)}/settings/welcome`,
    }).toHtml()}
  </li>`;
};

/**
 * @param domain_name
 * @param page_name
 * @param user
 * @param data {{ question_count: number; creator_studio?: Locals['creator_studio']  }}
 * @return {*}
 */
export const makeStoreSettingsMenuHtml = ({ domain_name, page_name = '', user }, data = {}) => {
  const { is_app = false, question_count, creator_studio } = data;

  const is_user_custom_enable = data?.store?.is_user_custom_enable ?? false;

  return html`
    <nav id="creator_settings_menu" class="setting_aside">
      <div class="only-mobile close-btn">
        <button type="button" class="btn btn-icon">${makeCloseIcon({ html })}</button>
      </div>
      <div class="inner_menu_wrapper ${settings_klass.menu_items_wrapper}">
        <ul class="menu_items ${settings_klass.menu_items}">
          ${makeMenusHtml({
            question_count,
            domain_name,
            page_name,
            user,
            is_app,
            creator_studio,
            is_user_custom_enable
          })}
        </ul>
      </div>
    </nav>
  `;
};
