import { html } from 'fxjs/es';

export const iscroll_wrapper_tmpl = (
  tmpl,
  attrs = {
    class: '',
  },
) => {
  /**
   * IScroll 의 height 은 styl 을 통해 지정해주세요.
   */
  return html`
    <div class="iscroll_container">
      <div class="iscroll_wrapper">
        <ul class="wrapper iscroll_ul_wrapper ${attrs.class}">
          ${tmpl}
        </ul>
      </div>
    </div>
  `;
};
