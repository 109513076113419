import { $attr, $closest, $delegate, $find, $findAll, $setAttr, $setText } from 'fxdom/es';
import UAParser from 'ua-parser-js';
import { CateListConstantS } from '../../../../../modules/CateList/S/Constant/module/CateListConstantS.js';
import { makeImageUploadDescHtml } from '../../../../../modules/Maker/F/maker_tmpl.js';
import { resetUserCounts } from '../../../../../modules/MPay/Cart/F/fs.js';
import { MShopUtilF } from '../../../../../modules/MShop/Util/F/Function/module/MShopUtilF.js';
import { NewMakerGtagF } from '../../../../../modules/NewMaker/Gtag/F/Function/module/NewMakerGtagF.js';
import { NewMakerGtagConstantS } from '../../../../../modules/NewMaker/Gtag/S/Constant/module/NewMakerGtagConstantS.js';
import { legacyHtml, makeApiUrl } from '../../../../../modules/Util/S/Function/util.js';
import { each, find, go, html, isUndefined, strMap } from 'fxjs/es';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { $qs } from 'fxdom';
import { NewMakerBaseProductsKeyCapConstantS } from '../../../../../modules/NewMaker/BaseProducts/KeyCap/S/Constant/module/NewMakerBaseProductsKeyCapConstantS.js';
import { NewMakerUtilF } from '../../../../../modules/NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';
import { errorHandlingEventWrapper } from '../../../../../modules/NewMaker/Error/F/Function/errorHandlingEventWrapper.js';
import { MakerDebugConstantS } from '../../../../../modules/MakerDebug/S/Constant/module/MakerDebugConstantS.js';

!(function () {
  G.mp.maker = G.mp.maker || {};

  const parser = new UAParser();
  const IS_IOS = !MShopUtilF.isCreatorApp() && /IOS/i.test(parser.getOS()?.name);
  const IS_SAMSUNG_BROWSER = parser.getBrowser().name == 'Samsung Browser';

  $.frame.defn_frame([
    {
      frame_name: 'maker_soop_asset_lib',
      page_name: 'maker_soop_asset_lib_page',
      title: 'SOOP 스티커',
      el_class: 'maker_soop_asset_lib_frame shrink-modal maker_sticker_frame',
      hide_frame_button_type: 'X',
      showing: function () {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      },
      closed: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker_soop_asset_lib_page',
      el_class: 'sticker_header_style1 maker_soop_asset_lib_page2',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      // custom_header: ({ title }) => {
      //   return html`
      //     <span class="don_back_page">
      //       <span class="btn_back"></span>
      //     </span>
      //
      //     <span class="name">${title || ''}</span>
      //     <button type="button" class="don_hide_frame"></button>
      //   `;
      // },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_soop_asset_lib_page_tab',
          template: __(
            function (folder) {
              box.extend({ soop_group_asset_images: [] });
              return folder;
            },
            (folder) => {
              return html`
                <div class="folder_detail_area" folder_id="${folder.id}">
                  <div
                    class="sticker_image_list maker_soop_asset_image_list"
                    _sel="soop_group_asset_images"
                  ></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              const folder = find((asset) => {
                return asset.id === image.folder_id;
              }, box.sel('assets'));

              $.frame.add_page({
                page_name: 'maker_soop_asset_lib_page2',
                loader: true,
                folder,
                image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_soop_asset_lib_page2_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return { folder, image };
                    },
                  },
                ],
              });
            }),
            $.on('click', '.image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);
            return $.frame.infi($.find1(don_tab, '.maker_soop_asset_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };
                return _p.go(
                  $.get(
                    '/@api/sticker_image/list/' +
                      $.attr($.find1(don_tab, '.folder_detail_area'), 'folder_id'),
                    condition(),
                  ),
                  function (image) {
                    window.box.push.apply(null, ['soop_group_asset_images'].concat(image));
                    return image;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_soop_asset_lib_page2',
      el_class: 'sticker_header_style1 maker_soop_asset_lib_page2',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      custom_header: ({ folder, image }) => {
        return html`
          <span class="don_back_page">
            <span class="btn_back"></span>
          </span>

          <span class="name">${image['name' + _en] || ''}</span>
          <button type="button" class="don_hide_frame"></button>
        `;
      },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_soop_asset_lib_page2_tab',
          template: __(
            function ({ folder, image, soop_asset_images }) {
              box.extend({ soop_asset_images: [] });
              return { folder, image };
            },
            ({ folder, image }) => {
              return html`
                <div class="folder_detail_area" image_id="${image.id}" folder_id="${folder.id}">
                  <div
                    class="sticker_image_list maker_soop_asset_group_image_list"
                    _sel="soop_asset_images"
                  ></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '.maker_soop_asset_group_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, ['soop_asset_images'].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
  ]);

  $.frame.defn_frame([
    {
      frame_name: 'maker_custom_asset_lib',
      page_name: 'maker_custom_asset_lib_page',
      title: ET('maker::icon::custom_asset'),
      el_class: 'maker_custom_asset_lib_frame shrink-modal maker_sticker_frame',
      hide_frame_button_type: 'X',
      showing: function () {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      },
      closed: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker_custom_asset_lib_page',
      el_class: 'maker_custom_asset_lib_page',
      hide_frame_button_type: '',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      appending: function (tab) {
        box.extend({ stickers: [], sticker_images: [], group_images: [], assets: [] });
        return tab;
      },
      tabs: [
        {
          tab_name: 'maker_custom_asset_lib_tab',
          title: ET('maker::icon::custom_asset'),
          template: () => {
            return html`
              <div class="maker_custom_asset_list">
                <div class="maker_custom_asset_folders" _sel="assets"></div>
              </div>
            `;
          },
          showing: (don_tab) => {
            const don_page = $closest('.don_page', don_tab);
            const status_txt = ET('maker::icon::status_txt1');
            each((el) => {
              const used_folder = find((f) => {
                return $attr('_id', el) == f.id;
              }, box.sel('maker->used_folders'));
              if (used_folder) {
                $setText(status_txt, $find('.status', el));
              } else {
                $setText('Free', $find('.status', el));
              }
            }, $findAll(`.custom_asset_folder_item`, don_page));
          },
          appended: (tab_el) => {
            return go(
              tab_el,
              $delegate('click', '.custom_asset_folder_item', (e) => {
                const folder = box.sel(e.currentTarget);
                $.frame.add_page({
                  page_name: 'maker_custom_asset_lib_page2',
                  loader: true,
                  folder,
                  hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                  tabs: [
                    {
                      tab_name: 'maker_custom_asset_lib_page2_tab',
                      title: folder.name || '',
                      selected: true,
                      data_func: function () {
                        return folder;
                      },
                    },
                  ],
                });
              }),
            );
          },
          infinite: (tab_el) => {
            return $.frame.infi($.find1(tab_el, '.maker_custom_asset_folders'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(tab_el, '.body') : window,
              template: (folder) => {
                const find_has_folder = find((_f) => {
                  return _f.id == folder.id;
                }, box.sel('maker->used_folders'));

                const status_txt = ET('maker::icon::status_txt1');
                return html`
                  <div class="infi_item custom_asset_folder_item" _sel="./(#${folder.id})" _id="${folder.id}">
                    <div class="image don_img_wrap"><img src="${G.to_350(folder.url)}" alt="" /></div>
                    <div class="name">${folder['name' + _en] || '이름 없음'}</div>
                    <div class="status" ${find_has_folder ? '' : ' is_badge="free"'}>
                      ${find_has_folder ? status_txt : 'Free'}
                    </div>
                  </div>
                `;
              },

              data_func: function (offset, limit) {
                const get_custom_asset_lib_api_url = '/@api/ness/custom_asset/folder';
                // TODO bjk ness-custom 테스트 끝나면 아래 주석으로 변경해둬야 함.
                // const store_id = 91473;
                const store_id = box(tab_el)?.store_id;

                return _p.go(
                  $.get(get_custom_asset_lib_api_url, {
                    offset: offset,
                    limit: limit,
                    store_id,
                  }),
                  function (folder) {
                    window.box.push.apply(null, ['assets'].concat(folder));
                    return folder;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_custom_asset_lib_page2',
      el_class: 'sticker_header_style1 maker_custom_asset_lib_page2',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      custom_header: ({ folder }) => {
        // TODO 사용중인지 체크하기
        const find_has_folder = find((_f) => {
          return _f.id == folder.id;
        }, box.sel('maker->used_folders'));
        const status_txt = ET('maker::icon::status_txt1');

        return html`
          <span class="don_back_page">
            <span class="btn_back"></span>
          </span>
          <span class="name">${folder['name' + _en] || ''}</span>
          <span class="status" ${find_has_folder ? '' : ' is_badge="free"'}>
            ${find_has_folder ? status_txt : 'Free'}
          </span>
        `;
      },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_custom_asset_lib_page2_tab',
          template: __(
            function (folder) {
              box.extend({ asset_images: [] });
              return folder;
            },
            (folder) => {
              return html`
                <div class="folder_detail_area" folder_id="${folder.id}">
                  <div class="maker_custom_asset_image_list" _sel="asset_images"></div>
                </div>
              `;
            },
          ),
          showing: (don_tab) => {
            const don_page = $closest('.don_page', don_tab);
            const folder_id = $attr('folder_id', $find('.folder_detail_area', don_page));
            const find_has_folder = find((_f) => {
              return _f.id == folder_id;
            }, box.sel('maker->used_folders'));
            const status_txt = ET('maker::icon::status_txt1');

            const status_el = $find('.header .status', don_page);
            if (find_has_folder) {
              $setAttr({ is_badge: '' }, status_el);
              $setText(status_txt, status_el);
            } else {
              $setAttr({ is_badge: 'free' }, status_el);
              $setText('Free', status_el);
            }
          },
          appended: __(
            // $.on('click', '.btn_prev', function (e) {
            //   // $.frame2.back();
            // }),
            $.on('click', '.custom_asset_image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              const folder = find((asset) => {
                return asset.id === image.folder_id;
              }, box.sel('assets'));

              $.frame.add_page({
                page_name: 'maker_custom_asset_lib_page3',
                loader: true,
                folder,
                image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_custom_asset_lib_page3_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return { folder, image };
                    },
                  },
                ],
              });
            }),
            $.on('click', '.custom_asset_image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);
            return $.frame.infi($.find1(don_tab, '.maker_custom_asset_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$custom_asset_image_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };
                return _p.go(
                  $.get(
                    '/@api/sticker_image/list/' +
                      $.attr($.find1(don_tab, '.folder_detail_area'), 'folder_id'),
                    condition(),
                  ),
                  function (image) {
                    window.box.push.apply(null, ['asset_images'].concat(image));
                    return image;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_custom_asset_lib_page3',
      el_class: 'sticker_header_style1 maker_custom_asset_lib_page3',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      custom_header: ({ folder, image }) => {
        const find_has_folder = find((_f) => {
          return _f.id == folder.id;
        }, box.sel('maker->used_folders'));
        const status_txt = ET('maker::icon::status_txt1');

        return html`
          <span class="don_back_page">
            <span class="btn_back"></span>
          </span>
          <span class="name">${folder['name' + _en] || ''}</span>
          <span class="status" ${find_has_folder ? '' : ' is_badge="free"'}>
            ${find_has_folder ? status_txt : 'Free'}
          </span>
          <span class="group_image_name">${image.name}</span>
        `;
      },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_custom_asset_lib_page3_tab',
          template: __(
            function ({ folder, image, asset_group_images }) {
              box.extend({ asset_group_images: [] });
              return { folder, image };
            },
            ({ folder, image }) => {
              return html`
                <div class="folder_detail_area" image_id="${image.id}" folder_id="${folder.id}">
                  <div class="maker_custom_asset_group_image_list" _sel="asset_group_images"></div>
                </div>
              `;
            },
          ),
          showing: (don_tab) => {
            const don_page = $closest('.don_page', don_tab);
            const folder_id = $attr('folder_id', $find('.folder_detail_area', don_page));
            const find_has_folder = find((_f) => {
              return _f.id == folder_id;
            }, box.sel('maker->used_folders'));
            const status_txt = ET('maker::icon::status_txt1');

            const status_el = $find('.header .status', don_page);
            if (find_has_folder) {
              $setAttr({ is_badge: '' }, status_el);
              $setText(status_txt, status_el);
            } else {
              $setAttr({ is_badge: 'free' }, status_el);
              $setText('Free', status_el);
            }
          },
          appended: __(
            $.on('click', '.custom_asset_image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '.maker_custom_asset_group_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$custom_asset_group_image_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, ['asset_group_images'].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
  ]);

  $.frame.defn_frame([
    {
      frame_name: 'maker_custom_user_asset_lib',
      page_name: 'maker_custom_user_asset_lib_page',
      title: ET('maker::icon::custom_user_asset'),
      el_class: 'maker_custom_user_asset_lib_frame shrink-modal maker_sticker_frame',
      hide_frame_button_type: 'X',
      showing: function () {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      },
      closed: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker_custom_user_asset_lib_page',
      el_class: 'sticker_header_style1 maker_custom_user_asset_lib_page2',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      // custom_header: ({ folder }) => {
      //   const find_has_folder = find((_f) => {
      //     return _f.id == folder.id;
      //   }, box.sel('maker->used_folders'));
      //   const status_txt = ET('maker::icon::status_txt1');
      //
      //   return html`
      //     <span class="don_back_page">
      //       <span class="btn_back"></span>
      //     </span>
      //     <span class="name">${folder['name' + _en] || ''}</span>
      //     <span class="status" ${find_has_folder ? '' : ' is_badge="free"'}>
      //       ${find_has_folder ? status_txt : 'Free'}
      //     </span>
      //   `;
      // },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_custom_user_asset_lib_page_tab',
          template: __(
            function (folder) {
              box.extend({ user_asset_images: [] });
              return folder;
            },
            (folder) => {
              return html`
                <div class="folder_detail_area" folder_id="${folder.id}">
                  <div class="maker_custom_user_asset_image_list" _sel="user_asset_images"></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.custom_asset_image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              const folder = find((asset) => {
                return asset.id === image.folder_id;
              }, box.sel('assets'));

              $.frame.add_page({
                page_name: 'maker_custom_user_asset_lib_page2',
                loader: true,
                folder,
                image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_custom_user_asset_lib_page2_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return { folder, image };
                    },
                  },
                ],
              });
            }),
            $.on('click', '.custom_asset_image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);
            return $.frame.infi($.find1(don_tab, '.maker_custom_user_asset_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$custom_asset_image_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };
                return _p.go(
                  $.get(
                    '/@api/sticker_image/list/' +
                      $.attr($.find1(don_tab, '.folder_detail_area'), 'folder_id'),
                    condition(),
                  ),
                  function (image) {
                    window.box.push.apply(null, ['user_asset_images'].concat(image));
                    return image;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_custom_user_asset_lib_page2',
      el_class: 'sticker_header_style1 maker_custom_user_asset_lib_page2',
      animation: !(IS_IOS || IS_SAMSUNG_BROWSER),
      custom_header: ({ folder, image }) => {
        const find_has_folder = find((_f) => {
          return _f.id == folder.id;
        }, box.sel('maker->used_folders'));
        const status_txt = ET('maker::icon::status_txt1');

        return html`
          <span class="don_back_page">
            <span class="btn_back"></span>
          </span>
          <span class="name">${folder['name' + _en] || ''}</span>
          <span class="status" ${find_has_folder ? '' : ' is_badge="free"'}>
            ${find_has_folder ? status_txt : 'Free'}
          </span>
          <span class="group_image_name">${image.name}</span>
        `;
      },
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_custom_user_asset_lib_page2_tab',
          template: __(
            function ({ folder, image, custom_user_asset_group_images }) {
              box.extend({ custom_user_asset_group_images: [] });
              return { folder, image };
            },
            ({ folder, image }) => {
              return html`
                <div class="folder_detail_area" image_id="${image.id}" folder_id="${folder.id}">
                  <div
                    class="maker_custom_user_asset_group_image_list"
                    _sel="custom_user_asset_group_images"
                  ></div>
                </div>
              `;
            },
          ),
          appended: __(
            $.on('click', '.custom_asset_image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '.maker_custom_user_asset_group_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$custom_asset_group_image_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, ['custom_user_asset_group_images'].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
  ]);

  // defn_frame maker.sticker2
  $.frame.defn_frame([
    {
      frame_name: 'maker.sticker2',
      page_name: 'maker_sticker_page',
      el_class: 'maker_sticker_frame shrink-modal',
      title: T('Design'),
      showing: function () {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      },
      closed: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker_sticker_page',
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_sticker_tab',
          title: T('Design'),
          template: _p.t$(
            '\
          #select_sticker_list.sticker_list[_sel="stickers"]\
        ',
          ),
          appending: function (tab) {
            box.extend({ stickers: [], sticker_images: [], group_images: [] });
            return tab;
          },
          appended: _p.pipe(
            $.on('click', '.sticker_list .sticker_item', function (e) {
              const folder = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker_sticker_page2',
                loader: true,
                folder2: folder,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_sticker_page2_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return folder;
                    },
                  },
                ],
              });
            }),
          ),
          infinite: function (don_ui_tab) {
            const api_url = go(void 0, () => {
              const base_product_id = box.sel('maker->product_color->base_product_id');
              if (base_product_id == 6949) {
                return '/@api/sm/sticker/list';
              } else {
                return '/@api/sticker/list';
              }
            });

            return $.frame.infi($.find1(don_ui_tab, '#select_sticker_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get(api_url, {
                    offset: offset,
                    limit: limit,
                  }),
                  function (sticker) {
                    window.box.push.apply(null, ['stickers'].concat(sticker));
                    return sticker;
                  },
                );
              },
            });
          },
        },
        {
          tab_name: 'maker_embro_tab',
          title: T('Wappens'),
          data_func: __(
            function () {
              return $.get('/@api/sticker_embro'); //373 embro
            },
            function (embro_folder) {
              box.extend({ embro_images: [] });
              return {
                embro_folder: embro_folder,
              };
            },
          ),
          template: _p.t$(
            '\
          .folder_detail_area[embro_folder_id="{{ $.embro_folder.id }}"]\
            #select_embro_image_list.sticker_image_list[_sel="embro_images"]',
          ),
          appended: __(
            $.on('click', '.image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker_embro_page2',
                loader: true,
                image: image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_embro_page2_tab',
                    title: image.name || '',
                    selected: true,
                    data_func: function () {
                      return image;
                    },
                  },
                ],
              });
            }),
            $.on('click', '.image_item[is_group="false"]', function (e) {
              if (!G.mp.maker.can_embro())
                return $.alert(T('Embroidery patches are not available for this product'));
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            return $.frame.infi($.find1(don_ui_tab, '#select_embro_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get(
                    '/@api/sticker_embro_image/list/' +
                      $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'embro_folder_id'),
                    {
                      offset: offset,
                      limit: limit,
                    },
                  ),
                  function (sticker) {
                    window.box.push.apply(null, ['embro_images'].concat(sticker));
                    return sticker;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_embro_page2',
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
        sp.don_back_page\
          sp.btn_back\
        sp.name {{ $.image["name"+_en] || "" }}\
        button[type=button].don_hide_frame\
      ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_embro_page2_tab',
          template: __(
            function (image, group_images) {
              box.extend({ embro_group_images: [] });
              return { image: image };
            },
            _p.t$(
              '\
            .folder_detail_area[image_id="{{ $.image.id }}"]\
              #select_embro_group_images.sticker_image_list[_sel="embro_group_images"]',
            ),
          ),
          appended: __(
            $.on('click', '.image_item', function (e) {
              if (!G.mp.maker.can_embro())
                return $.alert(T('Embroidery patches are not available for this product'));
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '#select_embro_group_images'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$layer_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, ['embro_group_images'].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_sticker_page2',
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
        sp.don_back_page\
          sp.btn_back\
        sp.name {{ $.folder2["name"+_en] || "" }}\
        button[type=button].don_hide_frame\
      ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_sticker_page2_tab',
          template: __(
            function (folder2) {
              box.extend({ sticker_images: [] });
              return { folder2: folder2 };
            },
            _p.t$(
              '\
            .folder_detail_area[folder2_id="{{ $.folder2.id }}"]\
              #select_sticker_image_list.sticker_image_list[_sel="sticker_images"]',
            ),
          ),
          appended: __(
            $.on('click', '.btn_prev', function (e) {
              // $.frame2.back();
            }),
            $.on('click', '.image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker_sticker_page3',
                loader: true,
                image: image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker_sticker_page3_tab',
                    title: image.name || '',
                    selected: true,
                    data_func: function () {
                      return image;
                    },
                  },
                ],
              });
            }),
            $.on('click', '.image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);
            return $.frame.infi($.find1(don_tab, '#select_sticker_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };
                return _p.go(
                  $.get(
                    '/@api/sticker_image/list/' +
                      $.attr($.find1(don_tab, '.folder_detail_area'), 'folder2_id'),
                    condition(),
                  ),
                  function (sticker) {
                    window.box.push.apply(null, ['sticker_images'].concat(sticker));
                    return sticker;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker_sticker_page3',
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
        sp.don_back_page\
          sp.btn_back\
        sp.name {{ $.image["name"+_en] || "" }}\
        button[type=button].don_hide_frame\
      ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker_sticker_page3_tab',
          template: __(
            function (image, group_images) {
              box.extend({ group_images: [] });
              return { image: image };
            },
            _p.t$(
              '\
            .folder_detail_area[image_id="{{ $.image.id }}"]\
              #select_sticker_group_images.sticker_image_list[_sel="group_images"]',
            ),
          ),
          appended: __(
            $.on('click', '.image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '#select_sticker_group_images'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$layer_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (sticker) {
                  window.box.push.apply(null, ['group_images'].concat(sticker));
                  return sticker;
                });
              },
            });
          },
        },
      ],
    },
  ]);
  // end defn_frame maker.sticker2

  // defn_frame maker my_lib
  $.frame.defn_frame([
    {
      frame_name: 'maker.my_lib',
      page_name: 'maker_my_lib_page',
      el_class: 'maker_my_lib_frame maker_sticker_frame shrink-modal',
      title: T('Library'),
      always_remove: true,
      showing: function (frame$) {
        if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
        go(
          frame$,
          $delegate('dragover', '>.don_wrapper', (e) => {
            e.originalEvent.preventDefault();
            $.addClass(e.currentTarget, 'drop');
          }),
          $delegate('dragleave', '>.don_wrapper', (e) => {
            e.originalEvent.preventDefault();
            $.removeClass(e.currentTarget, 'drop');
          }),
          $delegate('drop', '>.don_wrapper', (e) => {
            e.originalEvent.preventDefault();
            const file$ = $.find1(e.currentTarget, 'input[type="file"]');
            file$.files = e.dataTransfer.files;
            $.trigger(file$, 'change');
            $.removeClass(e.currentTarget, 'drop');
          }),
        );
      },
      closing: function () {
        if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      },
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker_my_lib_page',
      el_class: 'sticker_header_style1',
      hide_frame_button_type: 'v',
      custom_header: _p.t$(
        '\
        sp.name {{T("Library")}}\
        button[type=button].don_hide_frame\
      ',
      ),
      tabs: [
        {
          tab_name: 'maker_my_lib_tab',
          title: T('Library'),
          data_func: function () {
            box.extend({ my_lib_group_images: [] });
            return {};
          },
          template: () => {
            const maker_type = box.sel('maker->product_color->_->base_product->maker_type');
            const bp = box.sel('maker->product_color->_->base_product');
            const is_not_image_task =
              !NewMakerBaseProductsKeyCapConstantS.bp_ids.includes(bp.id) &&
              (!!bp?.is_not_image_task ||
                !!bp?.has_template ||
                /* TODO 카테고리 하드코딩 */
                bp.cate_list_id === CateListConstantS.PHONE_ACC_LIST_ID ||
                !!$qs('#dream_factory'));
            const vector = 'image/jpeg,image/png,image/svg+xml';
            const not_image_task = 'image/jpeg,image/png';
            const accept = VectorEditorConstantS.EDITOR_TYPES.includes(maker_type)
              ? vector
              : is_not_image_task === true
              ? not_image_task
              : 'image/jpeg,image/png,image/pjpeg,image/tiff,application/postscript';
            return legacyHtml`
            <div class="folder_detail_area">
              <div class="my_images_wrap">
                <div id="my_lib_group_images" class="sticker_image_list" _sel="my_lib_group_images"></div>
              </div>
            </div>
            <div class="btn_area">
              <input
                type="file"
                accept="${accept}"
              />
              <button type="button" class="btn_upload">${T('Upload Your Design')}</button>
              ${makeImageUploadDescHtml()}
            </div>
          `;
          },
          appended: __(
            $.on('click', '.image_item .image', function (e) {
              NewMakerGtagF.handleFirstImageButtonClick(NewMakerGtagConstantS.gtag.UploadLib);
              $.frame.close(box.sel($.closest(e.currentTarget, '.image_item')));
            }),
            $.on2('click', '.image_item .delete', function (e) {
              const image_item_el = $.closest(e.currentTarget, '.image_item');
              return _p.go(
                T('sticker::Are you sure to delete this?'),
                $.confirm,
                _p.if2(_p.idtt)(
                  function () {
                    return $.post('/@api/my_lib/image/delete', {
                      image_id: box.sel(image_item_el).id,
                    });
                  },
                  function (data) {
                    if (!data.result) return $.alert(T('sticker::Delete failed. Please try again.'));

                    resetUserCounts();
                    box.remove2(image_item_el);
                    e.delegateTarget.tab_opt.infi.remove(
                      image_item_el,
                      function (offset, limit) {
                        return $.get('/@api/my_lib_images', {
                          offset: offset,
                          limit: limit,
                        });
                      },
                      window.box.sel('my_lib_group_images'),
                    );
                  },
                  function () {
                    $.attr(
                      $.find1(e.delegateTarget, '#my_lib_group_images'),
                      'is_empty',
                      !box.sel('my_lib_group_images').length,
                    );
                  },
                ),
              );
            }),
            $.on2(
              'change',
              'input[type="file"]',
              errorHandlingEventWrapper(
                async function (e) {
                  $.don_loader_start();
                  const dt = e.delegateTarget;
                  const image = await NewMakerUtilF.uploadFile(e.currentTarget);
                  if (!image) return $.don_loader_end();
                  await dt.tab_opt.infi.prepend(image, box.sel('my_lib_group_images'));
                  $.attr(
                    $.find1(e.delegateTarget, '#my_lib_group_images'),
                    'is_empty',
                    !box.sel('my_lib_group_images').length,
                  );
                  await resetUserCounts();
                  $.don_loader_end();
                },
                MakerDebugConstantS.Category.ImageUpload,
                'my_lib_input',
              ),
            ),
            $.on('click', '.btn_upload', function (e) {
              return $.trigger($.find1(e.delegateTarget, 'input[type="file"]'), 'click');
            }),
            $.on('mouseenter', '.image_upload_desc', function (e) {
              if (UtilF.isLegacyMobile()) return;
              $.attr(e.$currentTarget, 'is_enter', 'true');
            }),
            $.on('mouseleave', '.image_upload_desc', function (e) {
              if (UtilF.isLegacyMobile()) return;
              $.attr(e.$currentTarget, 'is_enter', 'false');

              setTimeout(function () {
                if ($.attr(e.$currentTarget, 'is_enter') == true) return;
                $.hide(e.$currentTarget);
              }, 1000);
            }),

            $.on('mouseenter', '.loc_opt_desc', function (e) {
              if (UtilF.isLegacyMobile()) return;
              $.attr(e.$currentTarget, 'is_enter', 'true');
            }),
            $.on('mouseleave', '.loc_opt_desc', function (e) {
              if (UtilF.isLegacyMobile()) return;
              $.attr(e.$currentTarget, 'is_enter', 'false');

              setTimeout(function () {
                if ($.attr(e.$currentTarget, 'is_enter') == true) return;
                $.hide(e.$currentTarget);
              }, 1000);
            }),
            $.on('mouseenter', '.btn_upload', function (e) {
              if (UtilF.isLegacyMobile()) return;

              const image_up_desc = $.find1(e.delegateTarget, '.image_upload_desc');
              $.attr(image_up_desc, 'is_enter', true);
              $.show(image_up_desc);
            }),
            $.on('mouseleave', '.btn_upload', function (e) {
              if (UtilF.isLegacyMobile()) return;

              const image_up_desc = $.find1(e.delegateTarget, '.image_upload_desc');
              $.attr(image_up_desc, 'is_enter', 'false');
              setTimeout(function () {
                if ($.attr(image_up_desc, 'is_enter') == true) return;
                $.hide(image_up_desc);
              }, 1000);
            }),
          ),
          infinite: function (don_tab) {
            return $.frame.infi($.find1(don_tab, '#my_lib_group_images'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$layer_image_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get('/@api/my_lib_images', {
                    offset: offset,
                    limit: limit,
                  }),
                  function (sticker) {
                    window.box.push.apply(null, ['my_lib_group_images'].concat(sticker));
                    $.attr(
                      $.find1(don_tab, '#my_lib_group_images'),
                      'is_empty',
                      !box.sel('my_lib_group_images').length,
                    );
                    return sticker;
                  },
                );
              },
            });
          },
        },
      ],
    },
  ]);
  // end defn_frame maker my_lib

  // defn_frame maker worker_folder
  $.frame.defn_frame([
    {
      frame_name: 'maker.worker_folder_frame',
      page_name: 'maker.worker_folder_page',
      el_class: 'maker_sticker_frame maker_worker_folder_frame',
      title: 'Worker Folders',
    },
  ]);

  $.frame.defn_page([
    {
      page_name: 'maker.worker_folder_page',
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker.worker_folder_tab',
          title: 'Worker Folders',
          template: _p.t$(
            '\
        .title Worker Folders\
        #select_worker_folders.sticker_list[_sel="worker_folders"]\
      ',
          ),
          appending: function (tab) {
            box.extend({
              worker_folders: [],
              worker_sticker_images: [],
              worker_group_images: [],
            });
            return tab;
          },
          appended: _p.pipe(
            $.on('click', '.sticker_list .sticker_item', function (e) {
              const folder = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker.worker_folder_page2',
                loader: true,
                folder2: folder,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker.worker_folder_page2_tab',
                    title: folder.name || '',
                    selected: true,
                    data_func: function () {
                      return folder;
                    },
                  },
                ],
              });
            }),
          ),
          infinite: function (don_ui_tab) {
            return $.frame.infi($.find1(don_ui_tab, '#select_worker_folders'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$sticker_list_item,
              data_func: function (offset, limit) {
                return _p.go(
                  $.get('/@api/worker_folders', {
                    offset: offset,
                    limit: limit,
                  }),
                  function (worker_folders) {
                    window.box.push.apply(null, ['worker_folders'].concat(worker_folders));
                    return worker_folders;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker.worker_folder_page2',
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
      sp.don_back_page\
        sp.btn_back\
      sp.name {{ $.folder2.name || "" }}\
      button[type=button].don_hide_frame\
    ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker.worker_folder_page2_tab',
          template: __(
            function (folder2) {
              box.extend({ worker_sticker_images: [] });
              return { folder2: folder2 };
            },
            _p.t$(
              '\
          .folder_detail_area[folder2_id="{{ $.folder2.id }}"]\
            #select_worker_image_list.sticker_image_list[_sel="worker_sticker_images"]',
            ),
          ),
          appended: __(
            $.on('click', '.image_item[is_group="true"]', function (e) {
              const image = box.sel(e.$currentTarget);
              $.frame.add_page({
                page_name: 'maker.worker_folder_page3',
                loader: true,
                image: image,
                hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
                tabs: [
                  {
                    tab_name: 'maker.worker_folder_page3_tab',
                    title: image.name || '',
                    selected: true,
                    data_func: function () {
                      return image;
                    },
                  },
                ],
              });
            }),
            $.on('click', '.image_item[is_group="false"]', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_tab) {
            const frame_el = $closest('.don_frame', don_tab);
            return $.frame.infi($.find1(don_tab, '#select_worker_image_list'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
              template: G.$t_$sticker_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };
                return _p.go(
                  $.get(
                    '/@api/sticker_image/list/' +
                      $.attr($.find1(don_tab, '.folder_detail_area'), 'folder2_id'),
                    condition(),
                  ),
                  function (worker_sticker_images) {
                    window.box.push.apply(null, ['worker_sticker_images'].concat(worker_sticker_images));
                    return worker_sticker_images;
                  },
                );
              },
            });
          },
        },
      ],
    },
    {
      page_name: 'maker.worker_folder_page3',
      el_class: 'sticker_header_style1',
      custom_header: _p.t$(
        '\
      sp.don_back_page\
        sp.btn_back\
      sp.name {{ $.image.name || "" }}\
      button[type=button].don_hide_frame\
    ',
      ),
      hide_frame_button_type: 'v',
      tabs: [
        {
          tab_name: 'maker.worker_folder_page3_tab',
          template: __(
            function (image, worker_group_images) {
              box.extend({ worker_group_images: [] });
              return { image: image };
            },
            _p.t$(
              '\
          .folder_detail_area[image_id="{{ $.image.id }}"]\
            #select_worker_group_images.sticker_image_list[_sel="worker_group_images"]',
            ),
          ),
          appended: __(
            $.on('click', '.image_item', function (e) {
              $.frame.close(box.sel(e.$currentTarget));
            }),
          ),
          infinite: function (don_ui_tab) {
            const frame_el = $closest('.don_frame', don_ui_tab);
            return $.frame.infi($.find1(don_ui_tab, '#select_worker_group_images'), {
              limit: 40,
              scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
              template: G.$t_$layer_image_list_item,
              data_func: function (offset, limit) {
                const only_flex = frame_el?.dataset?.only_flex;
                const condition = () => {
                  if (isUndefined(only_flex)) {
                    return {
                      offset,
                      limit,
                    };
                  } else {
                    return {
                      offset,
                      limit,
                      only_flex,
                    };
                  }
                };

                const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                  image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
                });

                return _p.go($.get(get_url, condition()), function (worker_group_images) {
                  window.box.push.apply(null, ['worker_group_images'].concat(worker_group_images));
                  return worker_group_images;
                });
              },
            });
          },
        },
      ],
    },
  ]);
  // end defn_frame maker worker_folder

  // line sticker list
  $.frame.defn_frame({
    frame_name: 'maker.line.sticker',
    page_name: 'maker_line_sticker_page',
    el_class: 'maker_sticker_frame maker_line_sticker_frame',
    title: T('Design'),
    showing: function () {
      if (UtilF.isLegacyMobile()) $.add_class($1('body'), 'body_scroll');
      document.oncontextmenu = function () {
        $.alert(T('line_maker::Right click is not available.'));
        return false;
      };
    },
    closed: function () {
      if (UtilF.isLegacyMobile()) $.remove_class($1('body'), 'body_scroll');
      document.oncontextmenu = function () {};
    },
  });

  $.frame.defn_page({
    page_name: 'maker_line_sticker_page',
    tabs: [
      {
        tab_name: 'maker_line_bnf_tab',
        title: 'LINE FRIENDS',
        appending: function (tab) {
          box.extend({
            line_bnf_stickers: [],
            line_sticker_images: [],
            line_sticker_group_images: [],
          });
          return tab;
        },
        template: _p.t$(
          '\
        #select_line_bnf_sticker_list.sticker_list[_sel="line_bnf_stickers"]\
      ',
        ),
        appended: _p.pipe(
          $.on('click', '.sticker_list .sticker_item', function (e) {
            const folder = box.sel(e.$currentTarget);
            $.frame.add_page({
              page_name: 'maker_sticker_line_page2',
              loader: true,
              folder2: folder,
              hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
              tabs: [
                {
                  tab_name: 'maker_sticker_line_page2_tab',
                  title: folder.name || '',
                  selected: true,
                  data_func: function () {
                    return folder;
                  },
                },
              ],
            });
          }),
        ),
        infinite: function (don_ui_tab) {
          return $.frame.infi($.find1(don_ui_tab, '#select_line_bnf_sticker_list'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
            template: G.$t_$sticker_list_item,
            data_func: function (offset, limit) {
              return _p.go(
                $.get('/@api/line_sticker/list/bnf', {
                  offset: offset,
                  limit: limit,
                }),
                function (line_bnf_stickers) {
                  window.box.push.apply(null, ['line_bnf_stickers'].concat(line_bnf_stickers));
                  return line_bnf_stickers;
                },
              );
            },
          });
        },
      },
      {
        tab_name: 'maker_line_bt21_tab',
        title: 'BT21',
        appending: function (tab) {
          box.extend({
            line_bt21_stickers: [],
            line_sticker_images: [],
            line_sticker_group_images: [],
          });
          return tab;
        },
        template: _p.t$(
          '\
        #select_line_bt21_sticker_list.sticker_list[_sel="line_bt21_stickers"]\
      ',
        ),
        appended: __(
          $.on('click', '.sticker_list .sticker_item', function (e) {
            const folder = box.sel(e.$currentTarget);
            $.frame.add_page({
              page_name: 'maker_sticker_line_page2',
              loader: true,
              folder2: folder,
              hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
              tabs: [
                {
                  tab_name: 'maker_sticker_line_page2_tab',
                  title: folder.name || '',
                  selected: true,
                  data_func: function () {
                    return folder;
                  },
                },
              ],
            });
          }),
        ),
        infinite: function (don_ui_tab) {
          return $.frame.infi($.find1(don_ui_tab, '#select_line_bt21_sticker_list'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
            template: G.$t_$sticker_list_item,
            data_func: function (offset, limit) {
              return _p.go(
                $.get('/@api/line_sticker/list/bt21', {
                  offset: offset,
                  limit: limit,
                }),
                function (line_bt21_stickers) {
                  window.box.push.apply(null, ['line_bt21_stickers'].concat(line_bt21_stickers));
                  return line_bt21_stickers;
                },
              );
            },
          });
        },
      },
      {
        tab_name: 'maker_line_bs_tab',
        title: 'Brawl Stars',
        appending: function (tab) {
          box.extend({
            line_bs_stickers: [],
            line_sticker_images: [],
            line_sticker_group_images: [],
          });
          return tab;
        },
        template: _p.t$(
          '\
          #select_line_bs_sticker_list.sticker_list[_sel="line_bs_stickers"]\
        ',
        ),
        appended: __(
          $.on('click', '.sticker_list .sticker_item', function (e) {
            const folder = box.sel(e.$currentTarget);
            $.frame.add_page({
              page_name: 'maker_sticker_line_page2',
              loader: true,
              folder2: folder,
              hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
              tabs: [
                {
                  tab_name: 'maker_sticker_line_page2_tab',
                  title: folder.name || '',
                  selected: true,
                  data_func: function () {
                    return folder;
                  },
                },
              ],
            });
          }),
        ),
        infinite: function (don_ui_tab) {
          return $.frame.infi($.find1(don_ui_tab, '#select_line_bs_sticker_list'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
            template: G.$t_$sticker_list_item,
            data_func: function (offset, limit) {
              return _p.go(
                $.get('/@api/line_sticker/list/brawlstars', {
                  offset: offset,
                  limit: limit,
                }),
                function (line_bs_stickers) {
                  window.box.push.apply(null, ['line_bs_stickers'].concat(line_bs_stickers));
                  return line_bs_stickers;
                },
              );
            },
          });
        },
      },
      {
        tab_name: 'maker_line_truz_tab',
        title: 'TRUZ',
        appending: function (tab) {
          box.extend({
            line_truz_stickers: [],
            line_sticker_images: [],
            line_sticker_group_images: [],
          });
          return tab;
        },
        template: _p.t$(
          '\
          #select_line_truz_sticker_list.sticker_list[_sel="line_truz_stickers"]\
        ',
        ),
        appended: __(
          $.on('click', '.sticker_list .sticker_item', function (e) {
            const folder = box.sel(e.$currentTarget);
            $.frame.add_page({
              page_name: 'maker_sticker_line_page2',
              loader: true,
              folder2: folder,
              hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
              tabs: [
                {
                  tab_name: 'maker_sticker_line_page2_tab',
                  title: folder.name || '',
                  selected: true,
                  data_func: function () {
                    return folder;
                  },
                },
              ],
            });
          }),
        ),
        infinite: function (don_ui_tab) {
          return $.frame.infi($.find1(don_ui_tab, '#select_line_truz_sticker_list'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
            template: G.$t_$sticker_list_item,
            data_func: function (offset, limit) {
              return _p.go(
                $.get('/@api/line_sticker/list/truz', {
                  offset: offset,
                  limit: limit,
                }),
                function (line_truz_stickers) {
                  window.box.push.apply(null, ['line_truz_stickers'].concat(line_truz_stickers));
                  return line_truz_stickers;
                },
              );
            },
          });
        },
      },
    ],
  });

  $.frame.defn_page({
    page_name: 'maker_sticker_line_page2',
    el_class: 'sticker_header_style1',
    custom_header: _p.t$(
      '\
      sp.don_back_page\
        sp.btn_back\
      sp.name {{ $.folder2["name"+_en] || "" }}\
      button[type=button].don_hide_frame\
    ',
    ),
    hide_frame_button_type: 'v',
    tabs: [
      {
        tab_name: 'maker_sticker_line_page2_tab',
        template: __(
          function (folder2) {
            box.extend({ line_sticker_images: [] });
            return { folder2: folder2 };
          },
          _p.t$(
            '\
          .folder_detail_area[folder2_id="{{ $.folder2.id }}" design_collection_type="{{ $.folder2.type.toLocaleLowerCase() }}"]\
            #select_sticker_image_list.sticker_image_list[_sel="line_sticker_images"]',
          ),
        ),
        appended: __(
          $.on('click', '.btn_prev', function (e) {
            // $.frame2.back();
          }),
          $.on('click', '.image_item[is_group="true"]', function (e) {
            const image = box.sel(e.$currentTarget);
            $.frame.add_page({
              page_name: 'maker_sticker_line_page3',
              loader: true,
              image: image,
              hide_frame_button_type: !UtilF.isLegacyMobile() ? 'X' : 'v',
              tabs: [
                {
                  tab_name: 'maker_sticker_line_page3_tab',
                  title: image.name || '',
                  selected: true,
                  data_func: function () {
                    return _p.extend({}, box.sel(e.$currentTarget), {
                      _: {
                        design_collection_type: $.attr(
                          $.closest(e.currentTarget, '[design_collection_type]'),
                          'design_collection_type',
                        ),
                      },
                    });
                  },
                },
              ],
            });
          }),
          $.on('click', '.image_item[is_group="false"]', function (e) {
            $.frame.close(
              _p.extend({}, box.sel(e.$currentTarget), {
                _: {
                  design_collection_type: $.attr(
                    $.closest(e.currentTarget, '[design_collection_type]'),
                    'design_collection_type',
                  ),
                },
              }),
            );
          }),
        ),
        infinite: function (don_tab) {
          const frame_el = $closest('.don_frame', don_tab);
          return $.frame.infi($.find1(don_tab, '#select_sticker_image_list'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_tab, '.body') : window,
            template: G.$t_$sticker_image_list_item,
            data_func: function (offset, limit) {
              const only_flex = frame_el?.dataset?.only_flex;
              const condition = () => {
                if (isUndefined(only_flex)) {
                  return {
                    offset,
                    limit,
                  };
                } else {
                  return {
                    offset,
                    limit,
                    only_flex,
                  };
                }
              };

              return _p.go(
                $.get(
                  '/@api/sticker_image/list/' + $.attr($.find1(don_tab, '.folder_detail_area'), 'folder2_id'),
                  condition(),
                ),
                function (line_sticker_images) {
                  window.box.push.apply(null, ['line_sticker_images'].concat(line_sticker_images));
                  return line_sticker_images;
                },
              );
            },
          });
        },
      },
    ],
  });

  $.frame.defn_page({
    page_name: 'maker_sticker_line_page3',
    el_class: 'sticker_header_style1',
    custom_header: _p.t$(
      '\
      sp.don_back_page\
        sp.btn_back\
      sp.name {{ $.image["name"+_en] || "" }}\
      button[type=button].don_hide_frame\
    ',
    ),
    hide_frame_button_type: 'v',
    tabs: [
      {
        tab_name: 'maker_sticker_line_page3_tab',
        template: __(
          function (image, group_images) {
            box.extend({ line_sticker_group_images: [] });
            return { image: image };
          },
          _p.t$(
            '\
          .folder_detail_area[image_id="{{ $.image.id }}" design_collection_type="{{_.v($.image, "_.design_collection_type")}}"]\
            #select_sticker_group_images.sticker_image_list[_sel="line_sticker_group_images"]',
          ),
        ),
        appended: __(
          $.on('click', '.image_item', function (e) {
            // $.frame.close(box.sel(e.$currentTarget));

            $.frame.close(
              _p.extend({}, box.sel(e.$currentTarget), {
                _: {
                  design_collection_type: $.attr(
                    $.closest(e.currentTarget, '[design_collection_type]'),
                    'design_collection_type',
                  ),
                },
              }),
            );
          }),
        ),
        infinite: function (don_ui_tab) {
          return $.frame.infi($.find1(don_ui_tab, '#select_sticker_group_images'), {
            limit: 40,
            scroll_target: !UtilF.isLegacyMobile() ? $.closest(don_ui_tab, '.body') : window,
            template: G.$t_$layer_image_list_item,
            data_func: function (offset, limit) {
              const get_url = makeApiUrl(`/@api/sticker_image/:image_id/group_images`, {
                image_id: $.attr($.find1(don_ui_tab, '.folder_detail_area'), 'image_id'),
              });

              return _p.go(
                $.get(get_url, {
                  offset: offset,
                  limit: limit,
                }),
                function (line_sticker_group_images) {
                  window.box.push.apply(
                    null,
                    ['line_sticker_group_images'].concat(line_sticker_group_images),
                  );
                  return line_sticker_group_images;
                },
              );
            },
          });
        },
      },
    ],
  });
  // end line sticker list

  G.$t_layer_folder_detail = _p.t(
    'folder',
    '\
    .folder_detail\
      .head\
        sp.btn_prev\
        sp.text 목록으로\
      .close\
        sp.btn_close\
      .body\
  ',
  );

  G.$t_$layer_folder_list_item = _p.t(
    'folder',
    '\
    .don_is_item.folder_item[_sel="./(#{{folder.id}})" folder_id="{{folder.id}}"]\
      .image.don_img_wrap\
        sp.l1\
          img[src={{G.to_350(folder.url)}}]\
        sp.l2\
        sp.l3\
      .name {{folder["name"+_en] || "이름 없음"}}\
  ',
  );

  G.$t_$sticker_list_item = _p.t(
    'folder2',
    '\
    .infi_item.sticker_item[_sel="./(#{{folder2.id}})" _id="{{folder2.id}}"]\
      .image.don_img_wrap\
        img[src={{G.to_350(folder2.url)}}]\
      .name {{folder2["name"+_en] || "이름 없음"}}\
  ',
  );

  G.$t_$sticker_image_list_item = _p.t(
    'image',
    '\
    .infi_item.image_item[_sel="./(#{{image.id}})" image_id="{{image.id}}" is_group="{{image.is_group}}"]\
      .thumbs.don_img_wrap[group_length={{image.group_thumb ? image.group_thumb.length : 0}}]\
        {{_.go(image, ',
    function (image) {
      if (image.is_group)
        return _sum(
          image.group_thumb,
          _p.t(
            'thumb',
            '\
            sp.thumb[style="background-image: url({{G.to_350(thumb.url)}});"]\
          ',
          ),
        );
    },
    ')}}\
      .image.don_img_wrap\
        img[src={{G.to_350(image.url)}}]\
  ',
  );

  G.$t_$layer_image_list_item = _p.t(
    'image',
    '\
    .infi_item.image_item[_sel="./(#{{image.id}})" image_id={{image.id}} image_type={{image.type}}]\
      .image.don_img_wrap\
        img[src={{G.to_350(image.url)}}]\
      .name {{image.name || "이름 없음"}}\
      .delete\
        sp\
        sp\
  ',
  );

  G.$t_$layer_shape_list_item = _p.t(
    'image',
    '\
    .don_is_item.image_item[_sel="shapes->(#{{image.id}})" image_id={{image.id}}]\
      .image.don_img_wrap\
        img[src={{G.to_350(image.url)}}]\
      .name {{image.name || "이름 없음"}}\
  ',
  );

  G.$t_$layer_my_image_list_item = _p.t(
    'image',
    '\
    .don_is_item.image_item[_sel="my_images->(#{{image.id}})" image_id={{image.id}}]\
      .image.don_img_wrap\
        img[src={{G.thumb(image.url, "original")}}]\
      .name {{image.name || "이름 없음"}}\
  ',
  );

  const white_image_group_ids = [11851722, 11851910];
  const white_image_ids = [
    11850493, 11850355, 11850358, 11850360, 11850363, 11850367, 11850369, 11850371, 11850374, 11850377,
    11850381, 11850385, 11850387, 11850389, 11850393, 11850398, 11850400, 11850405, 11850407, 11851476,
    11851476, 11851478, 11851490, 11529726, 11851713, 11851871, 11851872, 11851873, 11851964, 11851965,
    11852001, 11851639, 11851639, 11851642, 11529709, 11529713, 11529716, 11529719, 11529722, 11529728,
    11529728, 12229595, 12229598, 12229549, 12229562, 12229565, 12229570, 12229576, 12229578,
  ];

  G.$t_$sticker_image_list_item_custom = (image) => {
    const is_require = go(void 0, () => {
      if (image.name.indexOf('_sh_x') != -1) return true;
      return false;
    });

    const is_white_group = white_image_group_ids.includes(image.id);
    const is_white_image = white_image_ids.includes(image.id);

    return html`
      <div
        class="infi_item image_item"
        _sel="./(#${image.id})"
        image_id="${image.id}"
        is_group="${image.is_group}"
        is_require="${is_require}"
        is_white_group="${is_white_group}"
        is_white_image="${is_white_image}"
      >
        <div class="thumbs don_img_wrap" group_length="${image.group_thumb ? image.group_thumb.length : 0}">
          ${go(void 0, () => {
            if (image.is_group) {
              return strMap((thumb) => {
                return html`
                  <span class="thumb" style="background-image: url(${G.to_350(thumb.url)});"></span>
                `;
              }, image.group_thumb);
            }
          })}
        </div>
        <div class="image don_img_wrap">
          <div class="white-image"><img src="${G.to_350(image.url)}" alt="" /></div>
        </div>
        ${go(void 0, () => {
          if (!is_require) return;
          return html` <div class="is_require">필수</div> `;
        })}
      </div>
    `;
  };

  G.$t_$layer_image_list_item_custom = (image) => {
    const is_white_image = white_image_ids.includes(image.id);

    return html`
      <div
        class="infi_item image_item"
        _sel="./(#${image.id})"
        image_id="${image.id}"
        image_type="${image.type}"
        is_white_image="${is_white_image}"
      >
        <div class="image don_img_wrap">
          <div class="white-image">
            <img src="${G.to_350(image.url)}" alt="" />
          </div>
        </div>
        <div class="name">${image.name || '이름 없음'}</div>
        <div class="delete">
          <span></span>
          <span></span>
        </div>
      </div>
    `;
  };

  G.$t_$custom_asset_image_item = (image) => {
    return html`
      <div
        class="infi_item custom_asset_image_item"
        _sel="./(#${image.id})"
        image_id="${image.id}"
        is_group="${image.is_group}"
      >
        <div class="thumbs don_img_wrap" group_length="${image.group_thumb ? image.group_thumb.length : 0}">
          ${(() => {
            if (image.is_group) {
              return strMap((thumb) => {
                return html`
                  <span class="thumb" style="background-image: url(${G.to_350(thumb.url)});"></span>
                `;
              }, image.group_thumb);
            }
          })()}
        </div>
        <div class="name">${image.name || '이름 없음'}</div>
        <div class="image don_img_wrap">
          <img src="${G.to_350(image.url)}" alt="" />
        </div>
      </div>
    `;
  };

  G.$t_$custom_asset_group_image_item = (image) => {
    return html`
      <div class="infi_item custom_asset_image_item" _sel="./(#${image.id})" image_id="${image.id}">
        <div class="image don_img_wrap">
          <img src="${G.to_350(image.url)}" alt="" />
        </div>
      </div>
    `;
  };
})();
