import { sel, strMap, html } from 'fxjs/es';
import { MShopStudioPbProductConstantS } from '../../../MShop/Studio/PbProduct/S/Constant/module/MShopStudioPbProductConstantS.js';
import { MShopUtilConstantS } from '../../../MShop/Util/S/Constant/module/MShopUtilConstantS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { makeProductStoryImageHtml } from '../../ProductStory/S/tmpl.js';
import { PRODUCT_STORY_MAX_HEIGHT, PRODUCT_STORY_MAX_WIDTH } from './width.js';

export const makeInstagramEmbedHtml = (ins_embed) =>
  ins_embed ? html` <div class="ins-embed" data-href="${UtilS.escape(ins_embed)}"></div> ` : '';

export const makeTwitterEmbedHtml = (tw_embed) =>
  tw_embed ? html` <div class="tw-embed" data-href="${UtilS.escape(tw_embed)}"></div> ` : '';

export const makeProductStoryImageRemoveHtml = (img) => html`
  <div class="product-story-image-option">
    <div class="button-wrap">
      <button type="button" class="btn btn-yellow up-down" data-check="prev" data-action="before">
        <img
          src="//s3.marpple.co/files/u_1504988/2021/5/original/b073bc0395ab2c9fd307cc5ca6a28924e8d1f9c42.png"
        />
        ${T('translation::Up')}
      </button>
      <button type="button" class="btn btn-yellow up-down" data-check="next" data-action="after">
        <img
          src="//s3.marpple.co/files/u_1504988/2021/5/original/e3ba69a7eb684ca3ab2cdd551199fcd6991af29e1.png"
        />
        ${T('translation::Down')}
      </button>
      <button type="button" class="btn btn-remove remove" data-id="${img.id || 0}">
        <img
          src="//s3.marpple.co/files/u_1504988/2021/5/original/029e6068523f48ff8256cfb9f7b1d5aeea0371fd3.png"
        />
        ${T('translation::Remove')}
      </button>
    </div>
  </div>
`;

export const makeProductStoryEditorHtml = (product_story = {}, crew_domain_name) => {
  const is_afreeca = crew_domain_name === MShopUtilConstantS.SOOP_MARPPLESHOP_CREW_DOMAIN;
  return html`
    <div id="product-story-editor">
      <h1>
        <span>${T('translation::상품 상세 작성하기')}</span>
      </h1>
      <div class="product-story-wrap">
        <div style="${is_afreeca ? 'display:none;' : ''}">
          <div class="form-item lang-selector ${MShopStudioPbProductConstantS.GLOBAL_TOGGLE_CLASSNAME}">
            <span class="title">${T('translation::언어별 스토리 설정')}</span>
            <div class="product-story-radio-buttons">
              <label>
                <input type="radio" name="product-story-lang" value="kr" checked />
                <span>${T('translation::한국어')}</span>
              </label>
              <label>
                <input type="radio" name="product-story-lang" value="en" />
                <span>${T('translation::영어')}</span>
              </label>
              <label>
                <input type="radio" name="product-story-lang" value="jp" />
                <span>${T('translation::일본어')}</span>
              </label>
            </div>
          </div>
          <div class="product-story-lang selected" data-lang="kr">
            <div class="title-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    type="text"
                    name="title"
                    placeholder="${T('제목을 작성해주세요.')}"
                  >
${UtilS.escape(product_story.title) || ''}</textarea
                  >
                </label>
              </div>
            </div>
            <div class="description-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    name="description"
                    placeholder="${T(
                      '상품을 설명할 수 있도록 글과 사진, SNS 링크를 활용하여 내용을 작성해주세요.',
                    )}"
                  >
${UtilS.escape(product_story.description) || ''}</textarea
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="product-story-lang" data-lang="en">
            <div class="title-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    type="text"
                    name="title_en"
                    placeholder="${T('제목을 작성해주세요.')}"
                  >
${UtilS.escape(product_story.title_en) || ''}</textarea
                  >
                </label>
              </div>
            </div>
            <div class="description-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    name="description_en"
                    placeholder="${T(
                      '상품을 설명할 수 있도록 글과 사진, SNS 링크를 활용하여 내용을 작성해주세요.',
                    )}"
                  >
${UtilS.escape(product_story.description_en) || ''}</textarea
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="product-story-lang" data-lang="jp">
            <div class="title-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    type="text"
                    name="title_jp"
                    placeholder="${T('제목을 작성해주세요.')}"
                  >
${UtilS.escape(product_story.title_jp) || ''}</textarea
                  >
                </label>
              </div>
            </div>
            <div class="description-section story-section">
              <div class="textarea-wrap">
                <label>
                  <textarea
                    class="box-product_story"
                    name="description_jp"
                    placeholder="${T(
                      '상품을 설명할 수 있도록 글과 사진, SNS 링크를 활용하여 내용을 작성해주세요.',
                    )}"
                  >
${UtilS.escape(product_story.description_jp) || ''}</textarea
                  >
                </label>
              </div>
            </div>
          </div>

          <div class="form-item sns-section only-mobile">
            <span class="title">${T('SNS에 올린 글 가져오기')}</span>
          </div>

          <div id="youtube-section" class="story-section">
            <h4>
              <img
                src="//s3.marpple.co/files/u_29089/2020/1/original/144674394ef9459e371e999c1f62137841e76a66baf5692.png"
                alt=""
              />
              <span>${T('유튜브 비디오의 링크를 입력해 주세요.')}</span>
            </h4>
            <label>
              <input
                value="${UtilS.escape(product_story.youtube_embed) || ''}"
                class="box-product_story"
                type="text"
                name="youtube_embed"
                placeholder="ex) https://youtu.be/KOOnaGsvaL4"
              />
            </label>
            <div class="sns-wrap"></div>
          </div>
          <div id="instagram-section" class="story-section">
            <h4>
              <img
                src="//s3.marpple.co/files/u_29089/2020/1/original/1446740b909ae0aef67cd70c9516c09f43db3162c1aec2e.png"
                alt=""
              />
              <span>${T('인스타그램 글의 링크를 입력해 주세요.')}</span>
            </h4>
            <label>
              <input
                value="${UtilS.escape(product_story.ins_embed) || ''}"
                class="box-product_story"
                type="text"
                name="ins_embed"
                placeholder="ex) https://www.instagram.com/p/B6SMBvIgxzk"
              />
            </label>
            <div class="sns-wrap"></div>
          </div>
          <div id="twitter-section" class="story-section">
            <h4>
              <img
                src="//s3.marpple.co/files/u_29089/2020/1/original/144674190c3e7c905defd26f5add57f36c31eaba5896912.png"
                alt=""
              />
              <span>${T('트위터 글의 링크를 입력해 주세요.')}</span>
            </h4>
            <label>
              <input
                value="${UtilS.escape(product_story.tw_embed) || ''}"
                class="box-product_story"
                type="text"
                name="tw_embed"
                placeholder="ex) https://twitter.com/marpple_life/status/1222814622656262144"
              />
            </label>
            <div class="sns-wrap"></div>
          </div>
          <div id="facebook-section" class="story-section">
            <h4>
              <img
                src="//s3.marpple.co/files/u_29089/2020/1/original/144674216fad442cd4edb6e965d1cc51e3b7605a6be5ca1.png"
                alt=""
              />
              <span>${T('페이스북 글의 링크를 입력해 주세요.')}</span>
            </h4>
            <label>
              <input
                value="${UtilS.escape(product_story.fb_embed) || ''}"
                class="box-product_story"
                type="text"
                name="fb_embed"
                placeholder="ex) https://www.facebook.com/marpplecom/videos/708901416264847/"
              />
            </label>
            <div class="sns-wrap"></div>
          </div>
        </div>
        <div id="image-section" class="story-section">
          <div class="form-item sns-section only-mobile">
            <span class="title">${T('이미지 첨부')}</span>
          </div>
          <h4>
            <img
              src="//s3.marpple.co/files/u_29089/2020/1/original/1446744a7049e2b857e205f923213c947afa23dc8d87995.png"
              alt=""
            />
            <span
              >${T('이미지를 업로드해 주세요.')} max:
              ${PRODUCT_STORY_MAX_WIDTH}x${PRODUCT_STORY_MAX_HEIGHT}</span
            >
          </h4>
          <div id="story-file-upload" class="story-file-upload-pc">
            <div class="file_wrap">
              <div class="file-description">JPG, JPEG, PNG</div>
              <button type="button" class="file-button">${T('파일선택')}</button>
            </div>
            <label>
              <input type="file" name="file" accept="image/jpeg,image/png,image/pjpeg" multiple />
            </label>
          </div>
          <div id="story-file-upload" class="story-file-upload-mobile">
            <div class="file_wrap">
              <img
                src="//s3.marpple.co/files/u_2308668/2023/4/original/bac4a05e1fcfb48f44727778cfe1586cf6fbc3601.webp"
                alt=""
              />
              <div class="file-description">파일 형식: JPG, JPEG, PNG</div>
            </div>
            <button type="button" class="file-button">${T('파일선택')}</button>
            <label>
              <input type="file" name="file" accept="image/jpeg,image/png,image/pjpeg" multiple />
            </label>
          </div>
          <div class="product-story-images">
            ${strMap(
              makeProductStoryImageHtml(makeProductStoryImageRemoveHtml),
              sel('_.product_story_images', product_story),
            )}
          </div>
        </div>
      </div>
    </div>
  `;
};
