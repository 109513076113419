import { html, go, strMap, sortBy } from 'fxjs/es';
import { STORE_PRODUCT_CUSTOM_LEVEL } from '../../../../../Creator/Product/S/constant.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { BpOptionS } from '../../../../../BpOption/S/Function/module/BpOptionS.js';
import { BpOptionTmplS } from '../../../../../BpOption/S/Tmpl/module/BpOptionTmplS.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { UtilTmplS } from '../../../../../Util/S/Tmpl/module/UtilTmplS.js';
import { soldOutSizeIds } from '../../../../../ProductColor/S/fs.js';
import { getBaseProductColorsInMaker, getProductColorInMaker } from '../../../../../Maker/F/getSth.js';
import { NessCustomMakerCoreS } from '../../../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { NessCustomMakerTmplS } from '../../../../S/Tmpl/module/NessCustomMakerTmplS.js';
import { $addClass, $attr, $qs, $removeAttr, $removeClass, $setAttr } from 'fxdom/es';
import { getBpcTitle } from '../../../../../Maker/F/Product/bpc_fs.js';
import { isNeedPreview, isWatchBand } from '../../../../../Maker/S/categorize.js';

export const customProductDetailTmpl = (box_maker, custom_level) => {
  const { product_color, phone_cases } = box_maker;
  const base_product = product_color._.base_product;
  const has_bp_option_groups = BpOptionS.getBaseProductsListBpOptionGroups(base_product)?.length;
  const is_bpc_show = !base_product.is_bpc_hidden; // 색상 정보 보이기
  const is_bps_show = !has_bp_option_groups && !base_product.is_bps_hidden; // 사이즈 정보 보이기
  const is_bpc_preview = isWatchBand(base_product) || isNeedPreview(base_product);

  return html`
    ${BpOptionTmplS.bpAllOptions({
      product: box_maker.product_color,
    })}
    <div class="product_color">
      <div class="custom-product-detail-option">
        ${makeCustomProductDetailInfo(product_color, custom_level)}
        <div class="custom-product-detail-option__product-option">
          ${go(product_color, (product_color) => {
            return html`
              ${is_bpc_show && !is_bpc_preview ? productDetailOption.color(product_color) : ''}
              ${is_bpc_show && phone_cases?.length ? productDetailOption.device(product_color) : ''}
              ${is_bps_show && !phone_cases.length ? productDetailOption.size(product_color) : ''}
              ${makeBpOption(product_color)}
              ${is_bpc_show && is_bpc_preview ? productDetailOption.color(product_color, true) : ''}
            `;
          })}
        </div>
      </div>
    </div>
  `;
};

const makeBpOption = (product_color) => {
  const options = go(
    NessCustomMakerCoreS.filteredBpOptionGroupsForUi(product_color, true),
    sortBy((option) => {
      const typeOrder = {
        FIXED: 3,
        SELECT: 2,
        IMAGE_SELECT: 1,
      };
      return typeOrder[option.ui_type] || 4;
    }),
  );

  return go(
    options,
    strMap(function (option) {
      if (option.ui_type === 'FIXED') {
        return productDetailOption.fixedOption(option);
      } else if (option.ui_type === 'SELECT') {
        return productDetailOption.selectOption(option);
      } else if (option.ui_type === 'IMAGE_SELECT') {
        return productDetailOption.selectOption(option);
      }
      return html``;
    }),
  );
};

const makeCustomProductDetailInfo = (product_color, custom_level) => {
  const title = NessCustomMakerCoreS.getProductName(product_color, G._en);

  const _price = NessCustomMakerCoreS.getProductPrice(
    product_color,
    1,
    product_color._.base_product.base_product_size_id,
  );
  const price = go(NessCustomMakerCoreS.getProductPrice(product_color, 1), (price) =>
    UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang),
  );

  return html`
    <div class="custom-product-detail-option__product-info">
      <div class="custom-product-detail-option__product-info-header">
        <div class="custom-product-detail-option__title">
          ${custom_level && custom_level != STORE_PRODUCT_CUSTOM_LEVEL.STATIC
            ? html`
                <div class="custom-product-detail-option__tag">
                  <div class="custom-product-detail-option__tag-box">${ET('ness::product::custom')}</div>
                </div>
              `
            : ''}
          <h2 class="custom-product-detail-option__product-name">${title}</h2>
        </div>
        <div class="custom-product-detail-option__extension">${NessCustomMakerTmplS.makeShareHtml()}</div>
      </div>
      <div class="custom-product-detail-option__product-info-body">
        <input type="hidden" name="price" value="${_price}" />
        <span class="custom-product-detail-option__product-price">${price}</span>
        ${NessCustomMakerTmplS.makeQuestionMarkHtml()}
      </div>
    </div>
  `;
};

const productDetailOption = {
  color: (product_color, show_label = false) => {
    if (product_color._.base_product_color.name.length === 0) return '';

    const base_product_colors = product_color._.base_product._.base_product_colors;
    const base_product_color = product_color._.base_product_color;

    const max_count = 8;
    const fdc = $qs('#for_detail_change');
    const hasFold = (fdc && $attr('data-folded', fdc)) || false;
    const needExpand = base_product_colors.length > max_count && !hasFold;

    return html` <div class="pd-contents__colors">
      ${UtilS.htmlIf(
        show_label,
        html` <div class="custom-product-detail-option__product-dynamic-label">${getBpcTitle()}</div> `,
      )}
      <div class="pd-field colors">
        <div class="dynamic-html ${needExpand ? `fold max_count_${max_count}` : ''}" max_count="${max_count}">
          <div class="pd-radio-group color">
            ${strMap(
              (bpc) =>
                html` <label
                  class="pd-radio color ${checkDisabled(bpc)}"
                  ${getBorderColor(bpc)}
                  data-fx-json="${$dataStr(bpc)}"
                >
                  <input
                    type="radio"
                    name="color"
                    ${UtilTmplS.checkIf(bpc.id === base_product_color.id)}
                    data-price="0"
                    value="${bpc.id}"
                  />
                  <span class="pd-radio__face color ${checkDisabled(bpc)}" title="${bpc.name}">
                    <span
                      class="code pd-radio__face--color"
                      style="background: ${bpc.color_code2
                        ? 'linear-gradient(135deg, ' + bpc.color_code + ' 50%, ' + bpc.color_code2 + ' 50%)'
                        : bpc.color_code}"
                    ></span>
                  </span>
                  ${UtilS.htmlIf(
                    bpc.name,
                    `<span class="pd-radio__text" title="${bpc.name}">${bpc.name}</span>`,
                  )}
                </label>`,
              base_product_colors,
            )}
            <button type="button" class="expand-btn pd-radio">
              <span class="pd-radio__face">
                <span class="pd-radio__face--color"></span>
              </span>
              <span class="pd-radio__text">${ET('mps2::gnb::more')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>`;
  },
  device: (product_color) => {
    if (product_color._.base_product_color.name.length === 0) return '';

    return html`
      <div class="custom-product-detail-option__product-option-selector selector-device" data-option="device">
        ${product_color._.base_product.name}
        <input type="hidden" name="device" value="${product_color.base_product_id}" />
      </div>
    `;
  },
  size: (product_color) => {
    const sizes = product_color._.base_product._.base_product_sizes;
    if (sizes.length === 0) return html``;

    const select_size_id = product_color.base_product_size_id;
    const selected_bps = sizes.find((size) => size.id === select_size_id);
    if (!selected_bps) return html``;

    const sold_out_size_ids = soldOutSizeIds(getProductColorInMaker(), getBaseProductColorsInMaker());
    const sold_out = sold_out_size_ids.includes(selected_bps.id) ? 'sold_out' : '';

    go($qs('#ness-cart-button'), ($cartButton) => {
      if (sold_out === 'sold_out' && $cartButton) {
        return go($cartButton, $addClass('disabled'), $setAttr({ disabled: true }));
      } else if ($cartButton) {
        return go($cartButton, $removeClass('disabled'), $removeAttr('disabled'));
      }
    });

    if (sizes.length === 1)
      return productDetailOption.fixed({
        label: ET('product_detail::options::size'),
        value: selected_bps.name,
      });

    return html` <div
        class="custom-product-detail-option__product-option-selector selector-size ${sold_out
          ? 'sold_out_selector'
          : ''}"
        data-option="size"
        data-fx-json="${$dataStr(selected_bps)}"
      >
        ${selected_bps.name}
        ${UtilS.htmlIf(sold_out, `<span class="sold_out_no_stock" >${T('cart_modal::일시품절')}</span>`)}
      </div>
      <div style="display: none;">
        ${strMap(
          (bpc) =>
            html`<input
              type="radio"
              name="size"
              ${UtilTmplS.checkIf(bpc.id === selected_bps.id)}
              data-price="0"
              value="${bpc.id}"
            />`,
          sizes,
        )}
      </div>`;
  },
  selectOption: (option) => {
    const selectedOption = NessCustomMakerCoreS.selectedBpOption(option);
    return html`<div
      class="custom-product-detail-option__product-option-selector selector-option"
      data-option="${option.option_group.id}"
      data-ui_type="${option.ui_type}"
    >
      ${selectedOption.name}
    </div>`;
  },
  fixedOption: (option) => {
    const selectedOption = NessCustomMakerCoreS.selectedBpOption(option);
    return productDetailOption.fixed({
      label: option.option_group.name,
      value: selectedOption.name,
    });
  },
  fixed: ({ label, value }) => {
    return html`
      <div class="custom-product-detail-option__product-fixed">
        <div class="custom-product-detail-option__product-fixed-label">${label}</div>
        <b class="custom-product-detail-option__product-fixed-value">${value}</b>
      </div>
    `;
  },
};

const getBorderColor = (item) => {
  if (!item.color_code) return '';
  let border_color = item.color_code;
  if (item.color_code.indexOf('gradient') > -1) {
    border_color = item.color_code.match(/(#[a-z0-9])\w+/g)[0];
  }
  return `style="border-color: ${border_color}"`;
};
const checkDisabled = (item) => (item.disabled ? 'disabled' : '');

export const makeHtml = (box_maker, custom_level) => {
  return html`
    <div id="for_detail_change" class="custom-product-detail-mo__product-option-wrapper">
      ${customProductDetailTmpl(box_maker, custom_level)}
    </div>
  `;
};
