import { find, go, map, omit } from 'fxjs/es';
import { $findAll } from 'fxdom/es';
import { removeAllTempLocalStorage } from '../../../Maker/F/mp_maker.js';
import { makeApiUrl } from '../../../Util/S/Function/util.js';
import { makeCompositeThumbnailsWrapperWithDesignReady } from '../../Thumbnail/F/fs.js';
import { getCustomLevelFromBox } from '../../../Creator/Setting/F/custom.js';

export async function compositeTemplateSelectionPageNext(e) {
  $.don_loader_start();
  const don_tab = e.delegateTarget;
  const { assoc_composite_templates, product, svg_product_materials, selected_option_group } =
    don_tab.tab_opt;
  const selected_assoc_composite_templates = go(
    don_tab,
    $findAll('.checked'),
    map((el) => {
      const { composite_template_id } = el.dataset;
      return find(
        (assoc_composite_template) => assoc_composite_template.id === parseInt(composite_template_id),
        assoc_composite_templates,
      );
    }),
  );
  if (!selected_assoc_composite_templates.length) {
    $.alert(T('marpple_shop_select_page::Please select at least one thumbnail.'));
    $.don_loader_end();
  } else if (selected_assoc_composite_templates.length > 5) {
    $.alert(T('marpple_shop_select_page::Please select 5 or fewer thumbnails.'));
    $.don_loader_end();
  } else {
    product.thumbnails = {
      value: makeCompositeThumbnailsWrapperWithDesignReady(selected_assoc_composite_templates, product),
    };

    const product_color = await $.post(
      G.collabo_type === ''
        ? `/@api/design_collection/product/create`
        : makeApiUrl('/:lang/@api/stores/:store_id/product_color/create', {
            lang: T.lang,
            store_id: box.sel('store_id'),
          }),
      {
        pc: { ...omit(['_', 'id', 'product_id'], product), store_id: box.sel('store_id') },
        svg_product_materials,
        selected_option_group,
        custom_level: getCustomLevelFromBox(),
      },
    );
    don_tab.tab_opt.next_func(product_color);
  }
  removeAllTempLocalStorage();
}
