import { html, type Html, rune, View } from 'rune-ts';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import {
  makeEmptyScreenNavigate,
  makeSignUpSellerNavigate,
  makeSubScreenNavigate,
  makeUtilScreenNavigate,
} from '../../../../shared/app/navigate';
import { isApp } from '../../../../shared/app/util';
import { loading } from '../../../../shared/client/global';
import { dataStr } from '../../../../shared/util/dataStr';

import c from './Footer.module.scss';
import { Dropdown, DropdownChangeEvent, type DropdownOption } from '../Dropdown/Dropdown';
import {
  BlogIcon,
  country_icon_urls,
  DropdownDownIcon,
  InstagramIcon,
  MarppleShopLogo,
  TiktokIcon,
  XIcon,
  YoutubeIcon,
} from '../../atoms/Icon';
import { changeUrlLang, htmlIf } from '../../../../shared/util';
import { MPS_ADDRESS_LANG, MPS_CUSTOMER_CENTER_NUMBER } from '../../../../shared/constant';
import { getTypoRaw, type Lang } from '../../../../shared/typography';
import { typo } from '../../../../shared/typography/typo';
import { isNil } from 'fxjs/es';

export interface FooterData {
  out_links: {
    marpple_corporation: string;
    creator: string;
    plus: string;
    popup_store: string;
    offline_popup_store: string;
    online_popup_store: string;
    brand_commerce: string;
    marppleshop_x_youtube: string;
    handmade_creator: string;

    customer_center: string;
    report_center: string;
    terms: string;
    privacy: string;
    specific_commerce_laws: string;
  };
  sns: {
    youtube?: string;
    instagram?: string;
    twitter?: string;
    naver_blog?: string;
    tiktok?: string;
  };
  lang: Lang;
  is_mobile: boolean;
}
export interface FooterState {
  is_info_btn_opened: boolean;
  lang_options: DropdownOption<Lang>[];
}

interface Texts {
  company_name: string;
  ceo: string;
  ceo_name: string;
  company_registration_info: string;
  company_registration_number: string;
  address_info: string;
  privacy_charge: string;
  privacy_charge_name: string;
  customer_center: string;
  customer_center_number: string;
  customer_center_info: string;
  inicis_info: string;
  inicis_button: string;
  notice: string;
  business_info: string;
  product_delivery_cs: string;
  product_delivery_email: string;
  shop_operation_cs: string;
  shop_operation_email: string;
  business_alliance_cs: string;
  business_alliance_email: string;
}

type makeLayoutHtml = (texts: Texts, data: FooterData & FooterState) => Html;

const makeLangArray = (out_links: FooterData['out_links']) => {
  const links = [
    { name: ET('mps2::footer::마플코퍼레이션'), link: out_links.marpple_corporation },
    {
      name: ET('mps2::footer::마플샵 크리에이터'),
      link: out_links.creator,
      post_message: makeSignUpSellerNavigate(out_links.creator),
    },
    {
      name: ET('mps2::footer::마플샵 플러스'),
      link: out_links.plus,
      post_message: makeUtilScreenNavigate(out_links.plus),
    },
    //
    // {
    //   name: ET('mps2::footer::마플샵 팝업스토어'),
    //   link: out_links.popup_store,
    //   post_message: makePopupScreenNavigate(out_links.popup_store),
    // },
    {
      name: ET('mps2::footer::온라인 팝업스토어'),
      link: out_links.online_popup_store,
      post_message: makeEmptyScreenNavigate(out_links.online_popup_store),
    },
    {
      name: ET('mps2::footer::오프라인 팝업스토어'),
      link: out_links.offline_popup_store,
      post_message: makeEmptyScreenNavigate(out_links.offline_popup_store),
    },
    {
      name: ET('mps2::footer::브랜드 커머스'),
      link: out_links.brand_commerce,
      post_message: makeEmptyScreenNavigate(out_links.brand_commerce),
    },
    {
      name: ET('mps2::footer::유튜브 쇼핑'),
      link: out_links.marppleshop_x_youtube,
      post_message: makeEmptyScreenNavigate(out_links.marppleshop_x_youtube),
    },
    {
      name: ET('mps2::footer::핸드메이드 크리에이터'),
      link: out_links.handmade_creator,
      post_message: makeEmptyScreenNavigate(out_links.handmade_creator),
    },
    {
      name: ET('mps2::footer::고객센터'),
      link: out_links.customer_center,
      post_message: makeSubScreenNavigate(out_links.customer_center),
    },
    {
      name: ET('mps2::footer::신고센터'),
      link: out_links.report_center,
      post_message: makeSubScreenNavigate(out_links.report_center),
    },
    {
      name: ET('mps2::footer::이용약관'),
      link: out_links.terms,
      post_message: makeSubScreenNavigate(out_links.terms),
    },
    {
      name: html`<b>${ET('mps2::footer::개인정보처리방침')}</b>`,
      link: out_links.privacy,
      post_message: makeSubScreenNavigate(out_links.privacy),
    },
  ];
  return [
    ...links,
    ...(ET.lang == 'jp'
      ? [
          {
            name: '特定商取引法',
            link: out_links.specific_commerce_laws,
            post_message: makeSubScreenNavigate(out_links.specific_commerce_laws),
          },
        ]
      : []),
  ];
};

const makeLangOptionInner = (lang: Lang, name: string) => {
  return html`
    <span class="${c.dropdown_option}">
      <img class="${c.lang_icon}" src="${country_icon_urls[lang]}" />
      <span class="${typo('14_medium')}">${name}</span>
    </span>
  `;
};
const makePcHtml: makeLayoutHtml = (texts, data) => {
  const { sns } = data;
  const info_row_html = html`
    <span>${texts.company_name}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.ceo} ${texts.ceo_name}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.company_registration_info} ${texts.company_registration_number}</span>
    <span class="${c.info_splitter}"></span>
    ${T.lang === 'kr'
      ? html`<span>통신판매업신고번호 2021-서울금천-1710</span> <span class="${c.info_splitter}"></span>`
      : ''}
    <span>${texts.address_info}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.privacy_charge} ${texts.privacy_charge_name}</span>
  `;

  return html`
    <div class="${c.footer} ${typo('12_medium')}">
      <div class="${c.section1}">
        <div class="${c.left}">
          <span class="${c.marpple_logo}">${MarppleShopLogo}</span>
          <nav class="${c.nav} ${typo('14_medium')}">
            ${makeLangArray(data.out_links).map(
              ({ link, name }) => html`<a href="${link}" target="_blank">${name}</a>`,
            )}
          </nav>
          <div class="${c.info_container}">
            <p class="${c.info_first_row}">${info_row_html}</p>
            ${ET.lang === 'kr'
              ? html`
                  <p>
                    ${texts.inicis_info}
                    <a
                      href="https://mark.inicis.com/mark/escrow_popup_v3.php?mid=MOImarpple"
                      class="${c.inicis_button}"
                    >
                      ${texts.inicis_button}</a
                    >
                  </p>
                  <p>${texts.notice}</p>
                `
              : ''}
          </div>
        </div>
        <div class="${c.right}">
          <p class="${c.customer_center_phone_row} ${typo('16_bold')}">
            <span>${texts.customer_center}</span>
            <span>${texts.customer_center_number}</span>
          </p>
          <p class="${c.customer_center_info}">
            <span>${texts.customer_center_info}</span>
            <span>${texts.product_delivery_cs} ${texts.product_delivery_email}</span>
            <span>${texts.shop_operation_cs} ${texts.shop_operation_email}</span>
            <span>${texts.business_alliance_cs} ${texts.business_alliance_email}</span>
          </p>
          <div class="${c.sns_container}">
            ${htmlIf(
              html`<a href="${sns.youtube}" class="${c.sns_icon}" target="_blank">${YoutubeIcon}</a>`,
              !!sns.youtube,
            )}
            ${htmlIf(
              html`<a href="${sns.instagram}" class="${c.sns_icon}" target="_blank">${InstagramIcon}</a>`,
              !!sns.instagram,
            )}
            ${htmlIf(
              html`<a href="${sns.twitter}" class="${c.sns_icon}" target="_blank">${XIcon}</a>`,
              !!sns.twitter,
            )}
            ${htmlIf(
              html`<a href="${sns.naver_blog}" class="${c.sns_icon}" target="_blank">${BlogIcon}</a>`,
              !!sns.naver_blog,
            )}
            ${htmlIf(
              html`<a href="${sns.tiktok}" class="${c.sns_icon}" target="_blank">${TiktokIcon}</a>`,
              !!sns.tiktok,
            )}
          </div>
        </div>
      </div>
      <div class="${c.divider}"></div>
      <div class="${c.section2}">
        <span class="${getTypoRaw('unica_12_regular')}">© 2024 Marpple Corporation.</span>
        ${new Dropdown(
          { selected_option_key: data.lang, options: data.lang_options },
          {
            horizontal: 'right',
            vertical: 'top',
            klass: c.lang_dropdown,
            button_klass: c.lang_dropdown_button,
            has_arrow: true,
          },
        )}
      </div>
    </div>
  `;
};
const makeMobileHtml: makeLayoutHtml = (texts, data) => {
  const { sns } = data;
  const info_row_html = html`
    <span>${texts.company_name}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.ceo} ${texts.ceo_name}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.company_registration_info} ${texts.company_registration_number}</span>
    <span class="${c.info_splitter}"></span>
    ${T.lang === 'kr'
      ? html`<span>통신판매업신고번호 2021-서울금천-1710</span> <span class="${c.info_splitter}"></span>`
      : ''}
    <span>${texts.address_info}</span>
    <span class="${c.info_splitter}"></span>
    <span>${texts.privacy_charge} ${texts.privacy_charge_name}</span>
  `;

  return html`
    <div class="${c.footer} ${typo('14_medium')}">
      <div class="${c.section1}">
        <div class="${c.left}">
          <span class="${c.marpple_logo}">${MarppleShopLogo}</span>
          <nav class="${c.nav} ${typo('14_medium')}">
            ${makeLangArray(data.out_links).map(
              ({ link, name, post_message }) =>
                html`<a
                  href="${link}"
                  ${post_message ? html`data-post-message="${dataStr(post_message)}"` : ''}
                  target="_blank"
                  >${name}</a
                >`,
            )}
          </nav>
          <div
            class="${c.info_container} ${typo('12_medium')} ${htmlIf(c.is_opened, data.is_info_btn_opened)}"
          >
            <button class="${c.info_open_btn} ${typo('12_bold')}">
              ${texts.company_name} ${texts.business_info}
              <span class="${c.info_open_icon}">${DropdownDownIcon}</span>
            </button>
            <p class="${c.info_first_row} ${c.info_row}">${info_row_html}</p>
            <p class="${c.info_row}">
              ${texts.inicis_info}
              <a
                href="https://mark.inicis.com/mark/escrow_popup_v3.php?mid=MOImarpple"
                class="${c.inicis_button}"
              >
                ${texts.inicis_button}</a
              >
            </p>
            <p class="${c.info_row}">${texts.notice}</p>
          </div>
        </div>
        <div class="${c.right}">
          <p class="${c.customer_center_phone_row} ${typo('16_bold')}">
            <span>${texts.customer_center}</span>
            <span>${texts.customer_center_number}</span>
          </p>
          <p class="${c.customer_center_info} ${typo('12_medium')}">
            <span>${texts.customer_center_info}</span>
            <span>${texts.product_delivery_cs} ${texts.product_delivery_email}</span>
            <span>${texts.shop_operation_cs} ${texts.shop_operation_email}</span>
            <span>${texts.business_alliance_cs} ${texts.business_alliance_email}</span>
          </p>
          <div class="${c.sns_container}">
            ${htmlIf(
              html`<a href="${sns.youtube}" class="${c.sns_icon}" target="_blank">${YoutubeIcon}</a>`,
              !!sns.youtube,
            )}
            ${htmlIf(
              html`<a href="${sns.instagram}" class="${c.sns_icon}" target="_blank">${InstagramIcon}</a>`,
              !!sns.instagram,
            )}
            ${htmlIf(
              html`<a href="${sns.twitter}" class="${c.sns_icon}" target="_blank">${XIcon}</a>`,
              !!sns.twitter,
            )}
            ${htmlIf(
              html`<a href="${sns.naver_blog}" class="${c.sns_icon}" target="_blank">${BlogIcon}</a>`,
              !!sns.naver_blog,
            )}
            ${htmlIf(
              html`<a href="${sns.tiktok}" class="${c.sns_icon}" target="_blank">${TiktokIcon}</a>`,
              !!sns.tiktok,
            )}
          </div>
        </div>
      </div>
      <div class="${c.section2}">
        <span class="${getTypoRaw('unica_12_regular')}">© 2024 Marpple Corporation.</span>
        ${new Dropdown(
          { selected_option_key: data.lang, options: data.lang_options },
          {
            horizontal: 'right',
            vertical: 'top',
            klass: c.lang_dropdown,
            button_klass: c.lang_dropdown_button,
            has_arrow: true,
          },
        )}
      </div>
    </div>
  `;
};

export class FooterView extends View<FooterData> {
  state: FooterState = {
    is_info_btn_opened: false,
    lang_options: [
      {
        key: 'kr',
        value: 'kr',
        name: makeLangOptionInner('kr', '한국어'),
      },
      { key: 'en', value: 'en', name: makeLangOptionInner('en', 'English') },
      { key: 'jp', value: 'jp', name: makeLangOptionInner('jp', '日本語') },
    ],
  };

  override onMount() {
    this.delegate(DropdownChangeEvent<Lang>, Dropdown, (e) => {
      if (!e.detail.option) return;

      if (isApp()) {
        loading();
        MShopUtilF.postMessage({ locale: e.detail.option.value });
        return;
      }

      location.href = changeUrlLang(location.href, e.detail.option.value);
    });

    this.delegate('click', `.${c.info_open_btn}`, (e) => {
      this.state.is_info_btn_opened = !this.state.is_info_btn_opened;
      const container_el = (e.currentTarget as HTMLElement)?.closest(`.${c.info_container}`);
      if (!container_el) return;

      container_el.classList.toggle(c.is_opened);
    });
  }

  getIsMobile(): boolean {
    const is_mobile = rune.getSharedData(this)?.is_mobile ?? this.data.is_mobile;
    if (isNil(is_mobile)) throw new Error('is_mobile is not set');
    return is_mobile;
  }

  override template() {
    const texts = {
      company_name: T('mshop::Footer::CompanyName'),
      ceo: T('mshop::Footer::CEO'),
      ceo_name: T('mshop::Footer::CeoName'),
      company_registration_info: T('mshop::Footer::CompanyRegistrationInfo'),
      company_registration_number: T('mshop::Footer::CompanyRegistrationNumber'),
      address_info: MPS_ADDRESS_LANG[ET.lang],
      privacy_charge: ET('mps2::footer::privacy_charge'),
      privacy_charge_name: ET('mps2::footer::privacy_charge_name'),
      //
      customer_center: T('mshop::Footer::CustomerCenter'),
      customer_center_number: MPS_CUSTOMER_CENTER_NUMBER[ET.lang],
      customer_center_info: ET('mps2::footer::customer_center_info'),

      product_delivery_cs: T('mshop::Footer::product_delivery_cs'),
      product_delivery_email: 'cs@marppleshop.com',
      shop_operation_cs: T('mshop::Footer::shop_operation_cs'),
      shop_operation_email: 'partner@marppleshop.com',
      business_alliance_cs: T('mshop::Footer::business_alliance_cs'),
      business_alliance_email: 'biz@marppleshop.com',

      //
      inicis_info: ET('mps2::footer::inicis_info'),
      inicis_button: ET('mps2::footer::inicis_button'),
      notice: ET('mps2::footer::notice'),
      business_info: ET('mps2::footer::business_info'),
    };

    return this.getIsMobile()
      ? makeMobileHtml(texts, { ...this.data, ...this.state })
      : makePcHtml(texts, { ...this.data, ...this.state });
  }
}
