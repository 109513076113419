import { go } from 'fxjs/es';
import { $delegate, $toggleClass } from 'fxdom/es';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { NessCustomMakerFrameBaseProductColorSelectorMuiF } from '../../../../Frame/BaseProductColorSelector/F/Mui/module/NessCustomMakerFrameBaseProductColorSelectorMuiF.js';
import { NessCustomMakerFrameBaseProductDeviceSelectorMuiF } from '../../../../Frame/BaseProductDeviceSelector/F/Mui/module/NessCustomMakerFrameBaseProductDeviceSelectorMuiF.js';
import { NessCustomMakerFrameBaseProductOptionSelectorMuiF } from '../../../../Frame/BaseProductOptionSelector/F/Mui/module/NessCustomMakerFrameBaseProductOptionSelectorMuiF.js';
import { NessCustomMakerFrameBaseProductSizeSelectorMuiF } from '../../../../Frame/BaseProductSizeSelector/F/Mui/module/NessCustomMakerFrameBaseProductSizeSelectorMuiF.js';
import { NessCustomMakerF } from '../../../../F/Function/module/NessCustomMakerF.js';

export const makeEvent = (el) => {
  return go(
    el,
    $delegate('click', '.ness-custom-maker__share', NessCustomMakerF.handleProductLinkShare),
    $delegate('click', '.custom-product-detail-option__product-option-selector.selector-color', async (e) => {
      $toggleClass('open', e.currentTarget);
      MuiF.openFrame(NessCustomMakerFrameBaseProductColorSelectorMuiF.frame, async (frame, page, tabs) => {
        frame.hided = () => {
          $toggleClass('open', e.currentTarget);
        };
      });
    }),
    $delegate(
      'click',
      '.custom-product-detail-option__product-option-selector.selector-device',
      async (e) => {
        $toggleClass('open', e.currentTarget);
        MuiF.openFrame(NessCustomMakerFrameBaseProductDeviceSelectorMuiF.frame, async (frame, page, tabs) => {
          frame.hided = () => {
            $toggleClass('open', e.currentTarget);
          };
        });
      },
    ),
    $delegate(
      'click',
      '.custom-product-detail-option__product-option-selector.selector-option',
      async (e) => {
        $toggleClass('open', e.currentTarget);
        MuiF.openFrame(
          NessCustomMakerFrameBaseProductOptionSelectorMuiF.frame,
          async (frame, page, [tab]) => {
            frame.hided = () => {
              $toggleClass('open', e.currentTarget);
            };
            tab.makeData = () => {
              return {
                product: box().maker.product_color,
                option_id: e.currentTarget.getAttribute('data-option'),
                ui_type: e.currentTarget.getAttribute('data-ui_type'),
              };
            };
          },
        );
      },
    ),
    $delegate('click', '.custom-product-detail-option__product-option-selector.selector-size', async (e) => {
      $toggleClass('open', e.currentTarget);
      MuiF.openFrame(NessCustomMakerFrameBaseProductSizeSelectorMuiF.frame, async (frame, page, tabs) => {
        frame.hided = () => {
          $toggleClass('open', e.currentTarget);
        };
      });
    }),
  );
};
