import { html } from 'fxjs/es';
import { messages } from '../../../S/message_id.js';
import { MShopUtilS } from '../../../../../Util/S/Function/module/MShopUtilS.js';

export const makeAncRetractionHtml = (crew_id) => html`
  <div class="pd-modal-guide">
    <div class="pd-modal-guide__content">
      <p>${T(messages.retraction_modal.p1)}</p>
      <p>${T(messages.retraction_modal.p2)}</p>
      <ul>
        <li>${T(messages.retraction_modal.li1)}</li>
        <li>${T(messages.retraction_modal.li2)}</li>
        <li>${T(messages.retraction_modal.li3)}</li>
      </ul>
    </div>

    <div class="pd-modal-guide__contact">
      <div class="pd-modal-guide__contact-item">
        <span>${T(messages.purchaseInfo.cs_title)}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).tel}
      </div>
      <div class="pd-modal-guide__contact-item">
        <span>${T(messages.purchaseInfo.email_title)}</span>
        ${MShopUtilS.getCustomerServiceInfo(crew_id).email}
      </div>
    </div>
  </div>
`;

export const makeNessAncRetractionHtml = () => html`
  <div class="pd-modal-guide">
    <div class="pd-modal-guide__content">
      <p>${ET('product_detail::retraction_modal::p1')}</p>
      <p>${ET('product_detail::retraction_modal::p2')}</p>
      <ul>
        <li>${ET('product_detail::retraction_modal::li1')}</li>
        <li>${ET('product_detail::retraction_modal::li2')}</li>
        <li>${ET('product_detail::retraction_modal::li3')}</li>
      </ul>
    </div>

    <div class="pd-modal-guide__contact">
      <div class="pd-modal-guide__contact-item">
        <span>${ET('product_detail::quality_modal::cs_title')}</span>
        ${MShopUtilS.getCustomerServiceInfo().tel}
      </div>
      <div class="pd-modal-guide__contact-item">
        <span>${ET('product_detail::quality_modal::email_title')}</span>
        ${MShopUtilS.getCustomerServiceInfo().email}
      </div>
    </div>
  </div>
`;
