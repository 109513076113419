import { $addClass, $hide, $qs, $removeClass } from 'fxdom/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';

export const hideLoading = ({ duration, container_el } = {}) => {
  const DefaultLoadingDuration = 1500;
  const body_el = $qs('body');

  const loading_el = $qs('.ness-loading', container_el || body_el);
  if (!loading_el) {
    return;
  }

  history.replaceState(null, '', UtilF.updateUrlParams({ is_loading: null }));

  setTimeout(() => {
    $addClass('hidden', loading_el);
  }, duration || DefaultLoadingDuration);

  loading_el.addEventListener('transitionend', (e) => {
    if (e.propertyName !== 'opacity') {
      return;
    }

    $hide(loading_el);
    $removeClass('ness-loading__body-fixed', $qs('body'));
  });
};
