import { html, type Html, View } from 'rune-ts';
import klass from './Tooltip.module.scss';
import tippy from 'tippy.js';
import { makeInfoIcon } from '../Icon';
import { typo } from '../../../../shared/typography/typo';

export interface TooltipProps {
  isOpen?: boolean;
  id: string;
  content: string | Html | View;
  triggerClass?: string;
  contentClass?: string;
  size?: 'small' | 'medium';
}

export class Tooltip extends View<TooltipProps> {
  private readonly trigger_id: string;

  constructor(data: TooltipProps) {
    super(data);
    this.trigger_id = `${this.data.id}_trigger`;
  }

  protected override template(data: TooltipProps): Html {
    const { id, content, triggerClass = '', contentClass = '' } = data;

    return html`
      <div class="${klass.tooltip}" data-size="${this.data.size ?? 'medium'}">
        ${makeInfoIcon({
          id: this.trigger_id,
          klass: `${klass.trigger} ${triggerClass}`,
        })}
      </div>
      <script id="${id}" type="text/html">
        <div class="${klass.content} ${contentClass} ${typo('14_medium')}">${content}</div>
      </script>
    `;
  }

  override onMount() {
    const { id } = this.data;
    const content = document.getElementById(id);
    tippy(`#${this.trigger_id}`, {
      content: content!.innerHTML,
      trigger: 'click',
      allowHTML: true,
      appendTo: 'parent',
      placement: 'bottom-start',
      offset: [0, 10],
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 16,
              boundariesElement: 'viewport',
            },
          },
        ],
      },
      hideOnClick: true,
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 10000);
      },
    });
  }
}
