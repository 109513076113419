import { zip } from '@fxts/core';
import { every, filter, go, identity, map, sel } from 'fxjs/es';
import { $closest, $findAll, $qs, $val } from 'fxdom/es';
import { rune } from 'rune-ts';
import { ProductMultiOptionSelector } from '../../../../../../../services/MarppleShop/renderApp/components/cells/ProductOptionQuantitySelector/ProductMultiOptionSelector.ts';
import { MShopUtilF } from '../../../../../Util/F/Function/module/MShopUtilF.js';
import { getDataFromProductMultiOptionSelector } from '../../../../ProductDetail/F/Mui/handles.js';

export const makePbUpMaterial = async ({
  product_id,
  profit,
  spo_items,
  spo_option_els,
  spo_text_option_els,
  spo_custom_option_els,
  quantity_el,
  is_cart,
}) => {
  const quantity = quantity_el ? parseInt($val(quantity_el)) : 1;

  if (quantity < 1) throw new Error(T('product_detail::수량은 1개 이상이어야 합니다.'));

  const selected_spo_option_value_ids = go(
    spo_option_els,
    map((spo_option_el) => parseInt(spo_option_el.value)),
  );

  if (spo_option_els?.length && !every(identity, selected_spo_option_value_ids)) {
    const is_mobile = MShopUtilF.isMobile();
    throw new Error(
      JSON.stringify({
        title: ET('mps2::product_detail::option_not_selected'),
        message: is_mobile
          ? ET('product_detail::errors::option_required_mo')
          : ET('product_detail::errors::option_required'),
      }),
    );
  }

  const spo_item =
    spo_items.length === 1
      ? spo_items[0]
      : spo_items.find((spo_item) =>
          spo_item.spo_option_value_ids.every((spo_option_value_id) =>
            selected_spo_option_value_ids.includes(spo_option_value_id),
          ),
        );

  if (!spo_item?.id)
    throw new Error(
      JSON.stringify({
        title: '',
        message: T('product_detail::옵션으로 선택된 상품이 더 이상 존재하지 않습니다.'),
      }),
    );

  const spo_text_option_values = go(void 0, () => {
    if (!spo_custom_option_els?.length) {
      return go(
        spo_text_option_els,
        map((spo_text_option_el) => ({
          spo_text_option_id: spo_text_option_el.dataset.spo_text_option_id,
          value: $val(spo_text_option_el).trim(),
        })),
        filter(sel('value')),
      );
    } else {
      const values = go(
        spo_custom_option_els,
        map((spo_custom_option_el) => {
          if (!spo_custom_option_el.value) return '';
          return `${spo_custom_option_el.value}`;
        }),
        filter((val) => !!val),
      );

      if (values.length != spo_custom_option_els.length) {
        throw new Error('모든 옵션을 선택해주세요.');
      }

      const spo_text_option_id = go(spo_custom_option_els[0], $closest('.spo_custom_options'), (el) => {
        return el.dataset.spo_text_option_id;
      });

      return [
        {
          spo_text_option_id,
          value: values.join(', '),
        },
      ];
    }
  });

  if (!spo_custom_option_els?.length) {
    if (spo_text_option_els?.length > 0 && spo_text_option_els?.length !== spo_text_option_values?.length) {
      throw new Error(
        JSON.stringify({
          title: ET('mps2::product_detail::option_not_selected'),
          message: MShopUtilF.isMobile()
            ? ET('product_detail::errors::option_required_mo')
            : ET('product_detail::errors::option_required'),
        }),
      );
    }
  }

  return {
    product_id,
    profit,
    quantity,
    option_price: spo_item[`price${_en}`],
    spo_item_id: spo_item.id,
    spo_item,
    spo_text_option_values,
    type: is_cart ? 'cart' : 'direct',
  };
};

const makePbUpMaterialByProductMultiOptionSelector = (is_cart) => {
  const productMultiOptionSelectorView = rune.getView(
    $qs(`.${ProductMultiOptionSelector}`),
    ProductMultiOptionSelector,
  );

  if (!productMultiOptionSelectorView) {
    throw new Error('productMultiOptionSelectorView가 존재하지 않습니다.');
  }

  return go(
    zip(
      productMultiOptionSelectorView.getProductOptions(),
      productMultiOptionSelectorView.getAllProductOptionExtraInfos(),
    ),
    map(([view_option, pb_material]) => {
      return { ...pb_material, quantity: view_option.quantity, type: is_cart ? 'cart' : 'direct' };
    }),
  );
};

export const makePbUpMaterialService = async ({
  quantity_els,
  product_color,
  spo,
  form,
  is_cart,
  is_spo_options,
}) => {
  if (quantity_els.length) {
    return go(
      quantity_els,
      map((q_el) => {
        return makePbUpMaterial({
          product_id: product_color.id,
          profit: product_color[`profit${_en}`],
          spo_items: spo._.spo_items,
          spo_option_els: go(form, $findAll('.spo_option')),
          spo_text_option_els: go(form, $findAll('.spo_text_option')),
          spo_custom_option_els: go(form, $findAll('.spo_custom_option')),
          quantity_el: q_el,
          is_cart,
        });
      }),
    );
  } else if (is_spo_options) {
    return getDataFromProductMultiOptionSelector(is_cart);
  } else {
    return { quantity: 1 };
  }
};
