import dataStr from 'fxdom/es/dataStr.js';
import { html, sel, strMap } from 'fxjs/es';
import { PageTitle } from '../../../../../../../services/MarppleShop/renderApp/components/atoms/PageTitle/PageTitle.ts';
import { CreatorCheerUpProfile } from '../../../../../../../services/MarppleShop/renderApp/components/cells/CreatorCheerUpProfile/CreatorCheerUpProfile.ts';
import { UtilImageS } from '../../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { MShopAppCommunityTmplS } from '../../../../Community/S/Tmpl/module/MShopAppCommunityTmplS.js';
import { ARTICLE_TYPES } from '../Constant/community.js';
import { MShopAppStoreCommunityConstantS } from '../Constant/module/MShopAppStoreCommunityConstantS.js';

export const community = ({
  articles,
  community_articles,
  is_mobile,
  store,
  is_ie,
  already_like,
  is_store_owner,
}) => {
  const btn_txt1 = ET('mps2::cheerup::btn_txt1');
  const wikiEditIcon = html`
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_1128_230489)">
        <path
          d="M1.35346 13.7267L1.84679 11.3667C1.88012 11.22 1.95346 11.08 2.06012 10.9733L11.4735 1.56C11.7801 1.25334 12.2735 1.25334 12.5735 1.56L14.4401 3.42667C14.7468 3.73334 14.7468 4.22667 14.4401 4.52667L5.02012 13.9467C4.91346 14.0533 4.78012 14.1267 4.62679 14.16L2.27346 14.6533C1.72679 14.7667 1.24012 14.28 1.35346 13.7333V13.7267Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1128_230489">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `;

  const btn_label = () => {
    if (is_mobile) {
      return html`
        <span class="mps2_action_button__wrap">
          <span class="mps2_action_button__icon">${wikiEditIcon}</span>
        </span>
      `;
    } else {
      return html`
        <span class="mps2_action_button__wrap">
          <span class="mps2_action_button__txt">${btn_txt1}</span>
          <span class="mps2_action_button__icon">${wikiEditIcon}</span>
        </span>
      `;
    }
  };

  const sub_title = ET('mps2::cheerup::sub_title1');

  return html`
    <div
      class="store-community2"
      store_id="${store.id}"
      data-fx-json="${dataStr(store)}"
      data-is_store_owner="${!!is_store_owner}"
    >
      <div class="store-community2__seller-area">
        <div class="store-community2__creator-profile">
          ${new CreatorCheerUpProfile({ ...store, is_mobile, is_store_owner }).toHtmlSSR()}
        </div>
      </div>
      <div
        class="store-community2__articles"
        ${is_store_owner && !community_articles.length ? ' style="display: none;"' : ''}
      >
        <div class="store-community2__articles-header">
          <div class="store-community2__articles-header-title">
            ${new PageTitle({
              is_mobile,
              custom_klass: '',
              subTitle: sub_title,
              title: 'CHEER UP',
            }).toHtmlSSR()}
          </div>
          <div class="store-community2__articles-buttons">
            <button
              type="button"
              class="store-community2__btn-cheer-up mps2_action_button ${is_mobile
                ? 'type_blue size_medium'
                : 'type_white size_large'}"
              article_type="${ARTICLE_TYPES.community_photo}"
            >
              <span>${btn_label()}</span>
            </button>
          </div>
        </div>
        <div class="community-article-list"></div>
      </div>
    </div>
  `;
};

// export const community = ({ articles, reviews, is_mobile, store, is_ie, is_store_owner }) => {
//   if (is_store_owner) return ownerCommunity({ articles, reviews, is_mobile, store, is_ie });
//   return customerCommunity({ articles, is_mobile, store, is_ie, is_store_owner });
// };

// const ownerCommunity2 = ({ articles, reviews, is_mobile, store, is_ie }) => html`
//   <div class="store-community" store_id="${store.id}">
//     ${ownerArticles({ articles, is_mobile, is_ie, store })}
//     ${reviews?.length
//       ? html`
//           <h2 class="store-community__sub_title">${T('community::리뷰')}</h2>
//           <div class="review-recent-list community-list"></div>
//         `
//       : ''}
//   </div>
// `;

const storeArticles = ({ store, articles, is_mobile, is_ie }) => html`
  <div class="store-articles">
    <div class="store-article-list">
      <div class="store-community__seller" style="display: none;">${sellerProfile({ store, is_ie })}</div>
      <div class="store-recent-articles">
        <div class="store-recent-articles__container swiper-container">
          <div class="swiper-wrapper community-list">
            ${strMap(
              (article) =>
                MShopAppCommunityTmplS.item({
                  article,
                  store: sel('_.store', article),
                  is_store: false,
                  is_ie,
                  is_swiper: true,
                }),
              articles,
            )}
          </div>
        </div>
        ${is_mobile
          ? ''
          : html`
              <div class="button swiper-button-next community-popular-list__next"></div>
              <div class="button swiper-button-prev community-popular-list__prev"></div>
            `}
      </div>
    </div>
  </div>
`;

export const sellerProfile = ({ store, is_ie, klass }) => html`
  <div class="seller-profile">
    <span class="seller-profile__img${!store.thumbnail_img ? ' seller-profile__img--empty' : ''}"
      ><img
        src="${UtilImageS.getResizedUrl({
          url: store.thumbnail_img || MShopAppStoreCommunityConstantS.EMPTY_STORE_IMG,
          quality: 70,
          width: 100,
          format: 'webp',
          is_ie,
        })}"
        alt=""
    /></span>
    <span class="seller-profile__name${klass ? ` ${klass}` : ''}">${store.name}</span>
    <span class="seller-profile__mps"
      ><img
        src="//s3.marpple.co/files/u_1504988/2024/2/original/1ed71b2ae0f93a8ba3d8d7db76e331946f2cb37d1.svg"
        alt=""
    /></span>
  </div>
`;

export const emptySellerArticles = (store) => html`
  <div class="empty-community-articles">
    <div class="empty-community-articles__icon">
      <img
        src="//s3.marpple.co/files/u_218933/2021/5/original/e29b915d0145b33e9fe44a9f1771e34a58691aaa1.svg"
        alt=""
      />
    </div>
    <p class="empty-community-articles__text">
      <b>${T.lang == 'kr' ? `${store?.name}님` : store?.name}</b><br />
      ${T('mshop::게시물을 등록해 보세요!')}
    </p>
  </div>
`;

export const emptyCommunityArticles = () => html`
  <div class="empty-community-articles">
    <div class="empty-community-articles__icon">
      <img
        src="//s3.marpple.co/files/u_218933/2021/5/original/e29b915d0145b33e9fe44a9f1771e34a58691aaa1.svg"
        alt=""
      />
    </div>
    <p class="empty-community-articles__text">
      <b>${T('mshop::첫 번째 게시물이 될 기회')}</b><br />
      ${T('mshop::게시물을 첫번째로 작성하고 셀러의 댓글을 받아보세요!')}
    </p>
  </div>
`;
