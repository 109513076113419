import { map, go } from 'fxjs/es';
import { makeCanvasByUrl } from '../../../../../Canvas/S/util.js';
import { VectorEditorConstantS } from '../../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';

import { makeTrimCanvas3 } from '../../../../../Maker/F/canvas_trim.js';
import { MakerDebugF } from '../../../../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../../../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';

const fileToCanvas = (file) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
      window.URL.revokeObjectURL(img.src);
    };

    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

export class SameDesignChecker {
  constructor(maker) {
    try {
      this.is_from_original =
        maker.original_product_id == maker.current_product_id ||
        maker.product_color.is_same_design_with_original;
      this.maker = maker;
      this.setDesign();
    } catch (e) {
      MakerDebugF.api.MakerDebugPost(
        this.maker.product_color,
        e,
        MakerDebugConstantS.Category.SameDesignChecker,
        'constructor',
      );
    }
  }

  reset() {
    try {
      if (!this.is_from_original) return;
      this.setDesign();
    } catch (e) {
      MakerDebugF.api.MakerDebugPost(
        this.maker.product_color,
        e,
        MakerDebugConstantS.Category.SameDesignChecker,
        'reset',
      );
    }
  }

  setDesign() {
    try {
      const maker_type = this.maker.product_color._.base_product.maker_type;
      if (
        maker_type === VectorEditorConstantS.KEYRING_EDITOR ||
        maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR
      ) {
        this.original_design = this.maker.product_color._.svg_product._.svg_product_pages[0].printable_url;
      } else {
        this.original_design_stringified = JSON.stringify(
          go(
            this.maker.product_color.product_faces2.value,
            map((pf) => pf.designs),
          ),
        );
      }
    } catch (e) {
      MakerDebugF.api.MakerDebugPost(
        this.maker.product_color,
        e,
        MakerDebugConstantS.Category.SameDesignChecker,
        'setDesign',
      );
    }
  }

  async isSameDesign() {
    try {
      if (!this.is_from_original) return false;
      const maker_type = this.maker.product_color._.base_product.maker_type;
      const is_vector =
        maker_type === VectorEditorConstantS.KEYRING_EDITOR ||
        maker_type === VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR;

      // svg_product_materials가 없는 경우는 디자인 수정 안한경우
      if (is_vector) {
        const svg_product_materials = this.maker.product_color._.svg_product_materials;
        if (!svg_product_materials) return true;
        const current_design = svg_product_materials[0].printable_file;
        const current_canvas = await fileToCanvas(current_design);
        const original_canvas = await makeCanvasByUrl(this.original_design);
        const is_same_design =
          makeTrimCanvas3(original_canvas).toDataURL() === makeTrimCanvas3(current_canvas).toDataURL();
        this.is_from_original = is_same_design;
        return is_same_design;
      } else {
        const is_not_moved = G.mp.maker.state.getCurrentStateIndex() === 0;
        const current_design = go(
          this.maker.product_color.product_faces2.value,
          map((pf) => pf.designs),
        );
        const is_same_design =
          is_not_moved || this.original_design_stringified === JSON.stringify(current_design);
        this.is_from_original = is_same_design;
        return is_same_design;
      }
    } catch (e) {
      MakerDebugF.api.MakerDebugPost(
        this.maker.product_color,
        e,
        MakerDebugConstantS.Category.SameDesignChecker,
        'isSameDesign',
      );
      return false;
    }
  }
}
