import { html } from 'fxjs/es';
import { MShopAppLiveTmplS } from '../../../Live/S/Tmpl/module/MShopAppLiveTmplS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

export const fn = ([
  { is_mobile, is_all_crew, cate_lists, is_android, plus_crew },
  banners,
  cate_list_html,
  today_list,
  { popular_products, limit_products },
  communities = [],
  new_sellers,
  live,
  top_sellers,
  styles,
]) =>
  html`
    <div class="app-home">
      ${banners?.length
        ? html`<div class="app-home__section app-home__section--kv">
            ${MShopAppHomeTmplS.kv({ banners, is_mobile, is_all_crew })}
          </div>`
        : html` <div class="app-home__section"></div>`}
      <div class="app-home__section app-home__section--quick">
        ${MShopAppHomeTmplS.quickMenu(is_android, is_mobile)}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!today_list.length}">
        ${MShopAppHomeTmplS.today(
          T('mshop::HomeTitle::관심 있을 만한 걸 가져왔어요 💘'),
          today_list,
          is_mobile,
          is_all_crew,
        )}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!styles?.length}">
        ${MShopAppHomeTmplS.styles(styles, is_mobile)}
      </div>
      <div class="app-home__section" data-max-width="true">
        ${MShopAppHomeTmplS.topSeller(null, top_sellers, is_mobile)}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!popular_products?.length}">
        ${MShopAppHomeTmplS.popularProduct({
          title: T('mshop::HomeTitle::당신의 취향을 저격할 인기 상품 😎', {
            br: is_mobile ? '<br>' : ' ',
          }),
          sub_title: T('mshop::인기 상품'),
          popular_products,
          is_mobile,
        })}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!communities.length}">
        ${MShopAppHomeTmplS.community({
          title: T('mshop::HomeTitle::직접 구매한 사람들의 후기 ✏️'),
          sub_title: T('mshop::PhotoReview'),
          communities,
          is_all_crew,
          is_mobile,
        })}
      </div>
      <div class="app-home__section" data-section-hide="${!limit_products?.length}">
        ${MShopAppHomeTmplS.limitProduct({
          title: T('mshop::HomeTitle::늦기 전에 데려가세요! 🏃‍♂️'),
          sub_title: T('mshop::HomeTitle::한정판 & 마감임박 상품'),
          limit_products,
        })}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!live?.length}">
        ${MShopAppLiveTmplS.live(T('mshop::HomeTitle::최애는 지금 영업 중 🕺'), live)}
      </div>
      <div class="app-home__section" data-max-width="true" data-section-hide="${!new_sellers?.length}">
        ${MShopAppHomeTmplS.newSeller(
          T('mshop::HomeTitle::당신의 마음을 훔치러 온 뉴페이스 👋'),
          new_sellers,
          is_mobile,
          plus_crew,
        )}
      </div>
      <div
        class="app-home__section app-home__section--category"
        data-max-width="true"
        data-section-fake="${cate_list_html?.is_fake}"
      >
        ${is_mobile ? MShopAppHomeTmplS.selectCategory(cate_lists, cate_lists[0].id) : ''}
        <div class="app-home-cate-list">
          ${cate_list_html?.is_fake ? cate_list_html.html : cate_list_html}
        </div>
      </div>
    </div>
  `;
/*
 * 5,000 포인트 지급 팝업 나중에 쓸일 있으면 쓰려고 일단 주석 처리
 * is_mobile ? MShopAppHomeTmplS.popupApplySeller() : ''
 * */
