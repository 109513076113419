export const ARTICLE_LIMIT = 30;
export const ARTICLE_COMMENT_LIMIT = 6;
export const COMMENT_LIMIT_OFFSET = 20;

export const INFINITE_DIRECTION = {
  prev: 'prev',
  next: 'next',
};

export const CREATOR_SHOP_LINK_KLASS = 'marppleshop_creator_link';
