import { html } from 'fxjs/es';

export const makeHtml = (is_vector_editor) => {
  return html`
    <div class="custom-product-detail-mo__footer-buttons footer_buttons pd-submit">
      <div class="pd-submit__action" style="">
        ${is_vector_editor
          ? `<div class="footer-button pd__action-btn customize-button" id="to_vector_editor">Customize</div>`
          : `<div class="footer-button pd__action-btn customize-button" id="to_maker">Customize</div>`}
      </div>
      <div class="pd-submit__btns">
        <div
          class="footer-button primary-button pd-submit__btn pd-submit__btn--form"
          id="ness-cart-button"
          style=""
        >
          Add to Cart
        </div>
        <div
          class="footer-button pd-submit__btn pd-submit__btn--cart"
          data-action="cart"
          style="display: none;"
          id="to_cart"
        >
          Add to Cart
        </div>
        <div
          class="footer-button primary-button pd-submit__btn"
          data-action="checkout"
          style="display: none;"
          id="to_buy"
        >
          Buy
        </div>
      </div>
    </div>
  `;
};
