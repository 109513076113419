import { reject, sumBy, go, take } from 'fxjs/es';
import {
  CollectionBadge,
  CustomBadge,
  LimitedProductBadge,
  NewBadge,
  ProductBadgeValidationError,
  RankingBadge,
  SaleBadge,
} from '../../../../../MShop/App/Product/Item/S/Function/nessProductBadge.js';

// export const getNessProductBadgeForSellerStudio = ({
//   quantity,
//   order_count,
//   is_quantity_public,
//   sell_start_at,
//   sell_end_at,
// }) => {
//   const limitedBadge = new LimitedProductBadge({
//     quantity,
//     order_count,
//     is_quantity_public,
//     sell_start_at,
//     sell_end_at,
//   });
//   return go(
//     [limitedBadge],
//     filter(({ isActive }) => isActive),
//     map((badge) => ({
//       ...badge,
//       name: badge.getNameByLang(ET.lang),
//     })),
//   );
// };

export const getNessProductBadge = ({
  spo_item_stocks,
  public_at,
  rank,
  pre_discount_rate,
  quantity,
  order_count,
  is_quantity_public,
  sell_start_at,
  sell_end_at,
  is_custom,
}) => {
  const MaxStockForDisplay = 99;
  const is_no_spo_item_stock = isNoSpoItemStock(spo_item_stocks);

  let limited_badge = null;
  // 뱃지 생성
  try {
    limited_badge = new LimitedProductBadge({
      quantity,
      order_count,
      is_quantity_public,
      max_stock: MaxStockForDisplay,
      sell_start_at,
      sell_end_at,
      is_no_spo_item_stock,
    });
  } catch (e) {
    if (!(e instanceof ProductBadgeValidationError)) {
      throw e;
    }
  }

  const new_badge = new NewBadge(public_at);
  const ranking_badge = new RankingBadge(rank);
  const sale_badge = new SaleBadge(pre_discount_rate);
  const collectionBadge = new CollectionBadge();
  const customBadge = new CustomBadge();

  if (is_custom) {
    return [customBadge];
  }

  // TODO: @kjj 컬렉션 기획 정리되면 수정
  // 컬렉션 상품이 가질 수 있는 뱃지는 두 종류 뿐이다
  // CASE1. Collection
  // CASE2. Ranking + Collection
  if (collectionBadge.isActive) {
    if (ranking_badge.isActive) return [ranking_badge, collectionBadge];
    return [collectionBadge];
  }

  if (ranking_badge.isActive) {
    if (limited_badge?.isActive) return [ranking_badge, ...take(1, limited_badge.badges)];
    if (sale_badge.isActive) return [ranking_badge, sale_badge];
    return [ranking_badge];
  }

  // 우선순위에 따라 뱃지 추가
  const badges = [];
  if (limited_badge?.isActive) badges.push(...limited_badge.badges);
  if (sale_badge.isActive) badges.push(sale_badge);
  if (new_badge.isActive) badges.push(new_badge);
  return go(
    badges,
    reject((badge) => !badge.name),
    take(2),
  );
};

const isProductSoldOut = ({ sell_start_at, sell_end_at, spo_item_stocks, quantity, order_count }) => {
  const is_no_spo_item_stock =
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) === 0;
  const is_no_quantity = quantity > 0 && quantity <= order_count;
  const is_sale_ended = sell_start_at && sell_end_at && new Date(sell_end_at) < new Date();
  return Boolean(is_no_spo_item_stock || is_no_quantity || is_sale_ended);
};

const isNoSpoItemStock = (spo_item_stocks) => {
  return (
    spo_item_stocks?.length &&
    go(
      spo_item_stocks,
      sumBy(({ quantity }) => quantity),
    ) === 0
  );
};
