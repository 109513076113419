import { $data, $hasClass, $qs } from 'fxdom/es';
import { delay } from 'fxjs/es';
import { NessCustomMakerF } from '../../../../F/Function/module/NessCustomMakerF.js';
import { NessCustomMakerCoreF } from '../../../../Core/F/Function/module/NessCustomMakerCoreF.js';
import { MuiF } from '../../../../../Mui/F/Function/module/MuiF.js';
import { fcanvasChanging } from '../../../../../Maker/F/util.js';
import { errorHandlingEventWrapper } from '../../../../../NewMaker/Error/F/Function/errorHandlingEventWrapper.js';

const handleSelectableEvent = (e, callback) => {
  return fcanvasChanging(
    errorHandlingEventWrapper(async function (e) {
      return _p.go(e, $.selectable, $data, async function (data) {
        $.don_loader_start();
        await delay(10, () => {});
        const scroll_wrapper_el = $.closest(e.$currentTarget, '.iscroll_wrapper');
        $.iscroll_to(scroll_wrapper_el, e.currentTarget);

        await callback(data);

        NessCustomMakerF.refreshCustomProductDetailHead();
        $.don_loader_end();

        const is_editing_mode = $hasClass('maker_editor_mode', $1('html'));
        if (!is_editing_mode) MuiF.closeFrame();
      });
    }),
  )(e);
};

export const bpColorSelectEvent = (e) => {
  return handleSelectableEvent(e, async (bpc) => {
    await NessCustomMakerCoreF.changeProductBaseProductColor(bpc);
  });
};

export const bpDeviceSelectEvent = (e) => {
  return handleSelectableEvent(e, async (bp) => {
    await NessCustomMakerCoreF.changePhoneCaseBpByBpId(bp.id);
  });
};

export const bpSizeSelectEvent = (e) => {
  return handleSelectableEvent(e, async (base_product_size) => {
    await NessCustomMakerCoreF.changeProductBaseProductSize(Number(base_product_size.id));
  });
};

export const bpOptionSelectEvent = (e) => {
  return handleSelectableEvent(e, async (bp) => {
    const selectElement = $qs(`select[name="${e.currentTarget.getAttribute('name')}"]`);
    if (selectElement) {
      await NessCustomMakerCoreF.bpSelectOptionHandling(selectElement, bp.id);
    }
  });
};
