export const messages = {
  producing_period: `mshop::ProductDetail::producing_period`,
  shipping_period: `mshop::ProductDetail::shipping_period`,
  format: {
    short_date: 'format::date::short',
  },
  tabs: {
    story: `mshop::ProductDetail::tabs::story`,
    review: `mshop::ProductDetail::tabs::review`,
    bp: `mshop::ProductDetail::tabs::bp`,
    purchaseInfo: `mshop::ProductDetail::tabs::purchaseInfo`,
    faq: `mshop::ProductDetail::tabs::faq`,
  },
  purchaseInfo: {
    delivery_title: `mshop::ProductDetail::purchaseInfo::title`,
    seller_info_title: `mshop::ProductDetail::purchaseInfo::seller_info_title`,
    ness_seller_info_title: `mshop::ProductDetail::purchaseInfo::ness_seller_info_title`,
    ness_seller_name: `mshop::ProductDetail::purchaseInfo::ness_seller_name`,
    seller_email: `mshop::ProductDetail::purchaseInfo::seller_email`,
    seller_company_name: `mshop::ProductDetail::purchaseInfo::seller_company_name`,
    seller_business_license_number: `mshop::ProductDetail::purchaseInfo::seller_business_license_number`,
    seller_company_tel: `mshop::ProductDetail::purchaseInfo::seller_company_tel`,
    delivery_desc: `mshop::ProductDetail::purchaseInfo::desc`,
    delivery_expect: `mshop::ProductDetail::purchaseInfo::expect`,
    tpl_delivery_expect: `mshop::ProductDetail::purchaseInfo::tpl_expect`,
    producing_period: `mshop::ProductDetail::purchaseInfo::producing_period`,
    refund_title: `mshop::ProductDetail::purchaseInfo::refund_title`,
    take_back_mobile: `mshop::ProductDetail::purchaseInfo::take_back_mobile`,
    take_back_title: `mshop::ProductDetail::purchaseInfo::take_back_title`,
    refund_desc1: `mshop::ProductDetail::purchaseInfo::refund_desc1`,
    refund_desc2: `mshop::ProductDetail::purchaseInfo::refund_desc2`,
    take_back_price: `mshop::ProductDetail::purchaseInfo::take_back_price`,
    take_back_address: `mshop::ProductDetail::purchaseInfo::take_back_address`,
    cs_title: `mshop::ProductDetail::purchaseInfo::cs_title`,
    email_title: `mshop::ProductDetail::purchaseInfo::email_title`,
    btn_guide1: `mshop::ProductDetail::purchaseInfo::btn_guide1`,
    btn_guide2: `mshop::ProductDetail::purchaseInfo::btn_guide2`,
  },
  review_title: `mshop::ProductDetail::review_title`,
  choose: `mshop::ProductDetail::choose`,
  faq: {
    group1: `mshop::ProductDetail::faq::group1`,
    group2: `mshop::ProductDetail::faq::group2`,
    group3: `mshop::ProductDetail::faq::group3`,
    q1: `mshop::ProductDetail::faq::q1`,
    a1: `mshop::ProductDetail::faq::a1`,
    q2: `mshop::ProductDetail::faq::q2`,
    a2: `mshop::ProductDetail::faq::a2`,
    q3: `mshop::ProductDetail::faq::q3`,
    a3: `mshop::ProductDetail::faq::a3`,
    q4: `mshop::ProductDetail::faq::q4`,
    a4: `mshop::ProductDetail::faq::a4`,
    q5: `mshop::ProductDetail::faq::q5`,
    a5: `mshop::ProductDetail::faq::a5`,
    q6: `mshop::ProductDetail::faq::q6`,
    a6: `mshop::ProductDetail::faq::a6`,
    q7: `mshop::ProductDetail::faq::q7`,
    a7: `mshop::ProductDetail::faq::a7`,
    q8: `mshop::ProductDetail::faq::q8`,
    a8: `mshop::ProductDetail::faq::a8`,
    q9: `mshop::ProductDetail::faq::q9`,
    a9: `mshop::ProductDetail::faq::a9`,
    q10: `mshop::ProductDetail::faq::q10`,
    a10: `mshop::ProductDetail::faq::a10`,
    q11: `mshop::ProductDetail::faq::q11`,
    a11: `mshop::ProductDetail::faq::a11`,
    q12: `mshop::ProductDetail::faq::q12`,
    a12: `mshop::ProductDetail::faq::a12`,
  },
  tpl_faq: {
    a1: `mshop::ProductDetail::tpl_faq::a1`,
    a2: `mshop::ProductDetail::tpl_faq::a2`,
    a4: `mshop::ProductDetail::tpl_faq::a4`,
    a5: `mshop::ProductDetail::tpl_faq::a5`,
  },
  preview_by_device: `mshop::ProductDetail::preview_by_device`,
  color: `mshop::ProductDetail::color`,
  size: `mshop::ProductDetail::size`,
  quantity: `mshop::ProductDetail::quantity`,
  sold_out: `mshop::ProductDetail::sold_out`,
  device: `mshop::ProductDetail::device`,
  max_purchase_per_user: `mshop::ProductDetail::max_purchase_per_user`,
  possible_quantity: `mshop::ProductDetail::possible_quantity`,
  already_possible_quantity_title: `mshop::ProductDetail::already_possible_quantity_title`,
  already_possible_quantity: `mshop::ProductDetail::already_possible_quantity`,
  check_size_guide: `mshop::ProductDetail::check_size_guide`,
  expect: `mshop::ProductDetail::expect`,
  total_count: `mshop::ProductDetail::total_count`,
  left_count: `mshop::ProductDetail::left_count`,
  coming_soon: `mshop::ProductDetail::coming_soon`,
  not_approve: `mshop::ProductDetail::not_approve`,
  end_sale: `mshop::ProductDetail::end_sale`,
  sold_out_products: `mshop::ProductDetail::sold_out_products`,
  only_pc: `mshop::ProductDetail::only_pc`,
  purchased_dtg: `mshop::ProductDetail::purchased_dtg`,
  size_guide: `mshop::ProductDetail::size_guide`,
  product_base_info: `mshop::ProductDetail::product_base_info`,
  check_cart_in: `mshop::ProductDetail::check_cart_in`,
  opt_prod: `mshop::ProductDetail::opt_prod`,
  preparing: `mshop::ProductDetail::preparing`,
  sold_out_size: `mshop::ProductDetail::sold_out_size`,
  quality_modal: {
    title: `mshop::ProductDetail::quality_modal::title`,
    p1: `mshop::ProductDetail::quality_modal::p1`,
    p2: `mshop::ProductDetail::quality_modal::p2`,
    p3: `mshop::ProductDetail::quality_modal::p3`,
    p4: `mshop::ProductDetail::quality_modal::p4`,
  },
  retraction_modal: {
    title: `mshop::ProductDetail::retraction_modal::title`,
    p1: `mshop::ProductDetail::retraction_modal::p1`,
    p2: `mshop::ProductDetail::retraction_modal::p2`,
    li1: `mshop::ProductDetail::retraction_modal::li1`,
    li2: `mshop::ProductDetail::retraction_modal::li2`,
    li3: `mshop::ProductDetail::retraction_modal::li3`,
  },
  status: {
    prohibited: `mshop::ProductStatus::prohibited`,
    checking: `mshop::ProductStatus::checking`,
    public: `mshop::ProductStatus::public`,
    private: `mshop::ProductStatus::private`,
    ready_confirm: `mshop::ProductStatus::ready_confirm`,
    not_confirmed: `mshop::ProductStatus::not_confirmed`,
    not_use_product: `mshop::ProductStatus::not_use_product`,
  },
  end_of_sale: `mshop::ProductStatus::end_of_sale`,
  until_close: `mshop::ProductStatus::until_close`,
  until_open: `mshop::ProductStatus::until_open`,
};
