export const makeProductDetailMakerCanvasSize = function makeProductDetailMakerCanvasSize(size_info) {
  const product_padding_top = (G.mp.maker.fake_mp_maker_size.inner_height - size_info.height) / 2;
  const origin_x = size_info.left + size_info.width / 2;
  const origin_y = size_info.top + size_info.height / 2;
  return {
    translateY: -size_info.top + G.mp.maker.fake_mp_maker_size.header_height + product_padding_top,
    scale: G.mp.maker.fake_mp_maker_size.scale_for_canvas,
    translateX: size_info.x_diff,
    origin_x,
    origin_y,
  };
};
