import { compact, go, join, map, reject, sort, filter, find, some } from 'fxjs/es';
import { $data, $find, $findAll } from 'fxdom/es';
import { BpOptionSelectedOptionGroupS } from '../../SelectedOptionGroup/S/Function/module/BpOptionSelectedOptionGroupS.js';
import { getAllBpOptions, getDefaultBpOptionIdFromBpOption } from './get.js';
import { BpOptionS } from '../../S/Function/module/BpOptionS.js';

export const getSelectedOptionGroupBpOptions = (selected_option_group) => {
  return go(
    getAllBpOptions(),
    filter((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
};

export const getBpOptionById = (bp_option_id) => {
  return go(
    getAllBpOptions(),
    find((bp_option) => bp_option.id == bp_option_id),
  );
};

// export const setSelectedOptionGroupBpOptions = (selected_option_group) => {
//   /* 시간 될때 지워보자 _ 없어도 될듯 */
//   selected_option_group._ = selected_option_group._ || {};
//   // selected_option_group._.bp_options = getSelectedOptionGroupBpOptions(selected_option_group);
//   return selected_option_group;
// };

export const setSelectedOptionGroupWithDefaultBpOptionIds = (product) => {
  product._.selected_option_group = product._.selected_option_group || {};
  product._.selected_option_group._ = product._.selected_option_group._ || {};
  const default_bp_option_ids = BpOptionS.getDefaultBpOptionIds(product);
  if (default_bp_option_ids?.length) {
    product._.selected_option_group.bp_option_ids = default_bp_option_ids;
    // setSelectedOptionGroupBpOptions(product._.selected_option_group);
  } else {
    // console.log('bp_option 이 없는 경우');
    /* bp_options 가 없는 상품이라면 */
    product._.selected_option_group = {};
  }
};
export const makeSelectedOptionGroup = (bp_option_groups_el) => {
  const bp_option_ids = go(
    bp_option_groups_el,
    $findAll('.bp_option_group'),
    map($find('select')),
    map((select_el) => parseInt(select_el.value)),
    sort,
  );

  const title_option_els = go(
    bp_option_groups_el,
    $findAll('.bp_option_group[data-is_name="true"] select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => $find(`option[value="${select_el.value}"]`)(select_el)),
    reject((el) => el.dataset?.name === '추가 안함'),
    compact,
  );
  const selected_bp_options = go(
    bp_option_groups_el,
    $findAll('.bp_option_group select'),
    reject((el) => el.dataset.id == 89),
    compact,
    map((select_el) => {
      const bp_option_group = $data(select_el);
      const option = $data($find(`option[value="${select_el.value}"]`)(select_el));
      return {
        ...option,
        _: {
          bp_option_group,
        },
      };
    }),
  );
  const option_names = BpOptionSelectedOptionGroupS.makeOptionNames(
    go(
      selected_bp_options,
      filter((bp_option) => bp_option.is_name),
    ),
  );

  return {
    bp_option_ids,
    title:
      go(
        title_option_els,
        map((el) => el?.dataset?.name),
        join('-'),
      ) || '',
    title_en:
      go(
        title_option_els,
        map((el) => el?.dataset?.name_en),
        join('-'),
      ) || '',
    title_jp:
      go(
        title_option_els,
        map((el) => el?.dataset?.name_jp),
        join('-'),
      ) || '',
    option_names,
    _: {
      bp_options: selected_bp_options,
    },
  };
};

export const getSelectedBpOptionIncludingHiddenBpOption = (selected_option_group) => {
  if (!selected_option_group?.bp_option_ids?.length) return;
  return go(
    getAllBpOptions(),
    filter((bp_option) => !bp_option.is_public),
    filter((bp_option) => selected_option_group.bp_option_ids.includes(bp_option.id)),
  );
};

export const setSelectedBpOptionGroupsHiddenBpOptionIdsWithDefault = (
  selected_option_group,
  hidden_bp_options,
) => {
  selected_option_group.bp_option_ids = go(
    selected_option_group.bp_option_ids,
    reject((bp_option_id) =>
      go(
        hidden_bp_options,
        some((hidden_bp_option) => hidden_bp_option.id == bp_option_id),
      ),
    ),
  ).concat(go(hidden_bp_options, map(getDefaultBpOptionIdFromBpOption)));
};
