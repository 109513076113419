import anime from 'animejs';
import {
  $qs,
  $appendTo,
  $addClass,
  $after,
  $delegate,
  $el,
  $find,
  $findAll,
  $hide,
  $is,
  $next,
  $remove,
  $removeClass,
  $show,
} from 'fxdom/es';
import { each, go, html, pipe, tap } from 'fxjs/es';
import { getRootEl } from '../../../../Feed/F/util.js';
import { getCursorEl } from '../medium-editor.js';
import { initEmbedPlugin, removeRemainEmbedUrl } from './embed.js';
import {
  destroyImageResizable,
  initImagePlugin,
  removeDraggableAttr,
  replaceImgWidthRelative,
} from './image.js';
import { initMarppleEmbedPlugin, removeProductModal } from './marpple.js';
import { initButtonPlugin } from './button.js';
import { removeToolbarHtml } from '../toolbar/common/util.js';

const isNotEmptyLine = ($p) => $p.innerText.trim() != '';

const createInsertEl = () =>
  $el(html`
    <div class="insert_buttons" contenteditable="false">
      <div class="open_btn insert_button" style="transform: rotate(0);">
        <img
          src="//s3.marpple.co/files/u_1165777/2020/11/original/56a6f6ca2cd615cfa81138604483042525b49eea1.png"
          alt=""
        />
      </div>
      <div class="feature_btns" style="display: none; opacity: 0;"></div>
    </div>
  `);

const bindToggleFeatures = ($insert_box, $feature_btns, $open_btn) => (is_open, immediate) => {
  is_open ? $addClass('open', $insert_box) : $removeClass('open', $insert_box);

  const opacity = is_open ? 1 : 0;
  const rotate = is_open ? '45deg' : 0;

  const anime_option = {
    duration: immediate ? 0 : 300,
    easing: 'linear',
  };

  if (is_open) $show($feature_btns);

  anime({
    targets: $feature_btns,
    opacity,
    ...anime_option,
  });
  anime({
    targets: $open_btn,
    rotate,
    ...anime_option,
    complete() {
      if (!is_open) $hide($feature_btns);
    },
  });
};

const isInertBox = (el$) => $is('.insert_buttons', el$);

export const registerBtn = (insert_box_el) => (button_el) => {
  const feature_btn_container_el = $find('.feature_btns', insert_box_el);
  $appendTo(feature_btn_container_el, button_el);
};

export const hideInsert = () => {
  const $insert_box = $qs('.insert_buttons');
  $insert_box && $insert_box.parentNode && $remove($insert_box);
};

export const initInsertPlugin = async (editor, options) => {
  const $insert_box = createInsertEl();
  const $editable = editor.elements[0];

  // SOLUTION : 혹시 다른 옵션이 필요하다면 더 넣어서 쓰면 될 것 같습니다.
  const { image_option, marpple_embed_option, button_align } = options;

  const $open_btn = $find('.open_btn', $insert_box);
  const $feature_btns = $find('.feature_btns', $insert_box);
  const toggleFeatures = bindToggleFeatures($insert_box, $feature_btns, $open_btn);

  const showInsert = ($current) => {
    toggleFeatures(false, true);
    $current.getBoundingClientRect();
    $after($insert_box, $current);
    $show($insert_box);
  };

  const startInsert = (e) => {
    const $current = getCursorEl(editor, e);
    const $cur_root = getRootEl($current);

    if (!$cur_root || isInertBox($cur_root)) return;

    if (isNotEmptyLine($cur_root)) return hideInsert();

    const next_sibling = $next($cur_root);
    const already_shown = $is('.insert_buttons', next_sibling) && next_sibling.style.display != 'none';
    if (already_shown) return;

    toggleFeatures(false, true);
    showInsert($cur_root);
  };

  go(
    $editable.parentNode,
    $delegate('click', '.editable', startInsert),
    $delegate('keyup', '.editable', startInsert),
    $delegate('focus', '.editable', startInsert),
    $delegate('focusout', '.editable', (e) => hideInsert()),
  );

  $delegate('click', '.open_btn', (e) => {
    const is_open = !$insert_box.classList.contains('open');
    toggleFeatures(is_open);
  })($insert_box);

  // init image plugin
  const registerBtnToInsertBox = registerBtn($insert_box);
  initImagePlugin(registerBtnToInsertBox, image_option, editor);
  if (!marpple_embed_option?.disable)
    initMarppleEmbedPlugin(registerBtnToInsertBox, marpple_embed_option, editor);
  initEmbedPlugin(registerBtnToInsertBox, editor);
  initButtonPlugin(registerBtnToInsertBox, editor, { button_align });
};

const removeInsertButtons = pipe($findAll('.insert_buttons'), each($remove));

export const insertPluginPreprocessing = pipe(
  tap(destroyImageResizable),
  tap(removeToolbarHtml),
  tap(removeRemainEmbedUrl),
  tap(removeInsertButtons),
  tap(replaceImgWidthRelative),
  tap(removeDraggableAttr),
  tap(removeProductModal),
);
