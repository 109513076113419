import { html } from 'fxjs/es';

export const makeHtml = () => {
  return html`<div class="fake_mp_maker">
    <div class="wrapper"></div>
    <div class="progress">
      <span class="progress-bar"></span>
    </div>
  </div>`;
};
