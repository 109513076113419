import { html } from 'fxjs/es';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { NewMakerCheckConditionF } from '../../../CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { NewMakerConditionPopupF } from '../../../ConditionPopup/F/Function/module/NewMakerConditionPopupF.js';
import { $closest, $toggleClass } from 'fxdom/es';

export const pc = () => {
  if (UtilF.isLegacyMobile()) return html``;

  return html`<div class="condition_tooltip" style="display: none;">
    <div class="condition_tooltip_text">
      ${TT('maker::tooltip::pc_title')}

      <img
        src="//s3.marpple.co/files/u_193535/2024/10/original/26fd9e8fe891169ecbb3731f5452fb5ad427e5511.png"
        alt=""
      />
    </div>
    <div class="condition_tooltip_img">
      <img
        src="https://s3.marpple.co/files/u_3928205/2024/7/original/38001bef832cea80a0e41c2a8513a2895f1846c91.png"
        alt="툴팁"
      />
    </div>
  </div> `;
};

export const clickEvent = () =>
  $.on('click', '.condition_tooltip_text', async (e) => {
    if (e.target.closest('.toggle_img') || e.target.closest('.show_detail')) {
      return;
    }
    const type = NewMakerCheckConditionF.is_full_fill_or_out_bounds();
    if (!type) return;
    const condition_tooltip_el = $closest('.condition_tooltip', e.currentTarget);
    $toggleClass('is_closed', condition_tooltip_el);
    return NewMakerConditionPopupF.open(type);
  });
