import { equals, go, sel, selEquals, some, reject, map, not, filter } from 'fxjs/es';
import { CateListConstantS } from '../../../../CateList/S/Constant/module/CateListConstantS.js';
import { getAvailableColors, getProductStatus } from '../../../../Creator/Setting/S/fs.js';
import { GoodsTypeS } from '../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { isBpStrapCase } from '../../../../Maker/S/categorize.js';
import { NewMakerProductStyleS } from '../../../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { NewMakerWeS } from '../../../../NewMaker/We/S/Function/module/NewMakerWeS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductPbOptionS } from '../../Product/PbOption/S/Function/module/MShopAppProductPbOptionS.js';
import { HOTEL_KEYRING_ID, PRODUCT_STATUS } from './constant.js';

export const checkHasDigitalProduct = (base_products) =>
  /* TODO 카테고리 하드코딩 */
  some(selEquals('cate_list_id', CateListConstantS.DIGITAL_LIST_ID), base_products);

export const isAccRingBp = (bp) => {
  /* TODO 카테고리 하드코딩 */
  return some(
    equals(bp.id),
    [
      4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452, 4634, 4644, 4692, 4702, 4703, 4553, 4554, 4555,
      4556, 4401, 4395, 4498, 4402, 4399, 4400, 4349, 4350, 4351, 4352, 4348, 4347,
    ],
  );
};

export const checkIsDisabled = ({ isApp, product_detail, store, sales_info }) => {
  const { is_sold_out, is_selling_product } = sales_info;
  const { can_selling /* user_id: owner_id */ } = store;
  const stores_product = product_detail._.stores_product;
  const base_product = product_detail._.base_product;
  const is_digital_product = sel('_.stores_product.goods_type_id', product_detail) === 'DTG';
  const { is_use_creator, is_use_creator_en, is_use_creator_jp } = base_product;

  const {
    is_prohibited,
    is_confirmed,
    is_confirm_req,
    is_check_design,
    is_purchased_dtg = false,
  } = stores_product;

  const product_status = getProductStatus({
    is_public: product_detail.is_public,
    is_prohibited,
    is_confirmed,
    is_confirm_req,
    is_digital_product,
    is_check_design,
    is_use_creator,
    is_use_creator_en,
    is_use_creator_jp,
  });

  const disabled =
    !can_selling || // 스토어의 판매상태
    is_sold_out || // 스토어 오너가 아니고, 솔드아웃인 경우
    !is_selling_product || // 스토어 오너가 아니고, 판매중이 아닌경우
    (isApp && is_digital_product) || // 디지털 상품이고, 환경이 앱인경우
    (is_digital_product && ![PRODUCT_STATUS.PB_ME, PRODUCT_STATUS.PUBLIC].includes(product_status)) || // 디지털상품이고, 상품의 상태가 공개중이 아닌경우
    (is_digital_product && is_purchased_dtg); // 디지털 상품이고 구매하고 7일이 지나지 않은 경우

  return disabled;
};

export const checkProductSaleStatus = ({ isApp, product_detail, store, sales_info }) => {
  const { is_before_sale, is_after_sale, is_sold_out } = sales_info;
  const stores_product = product_detail._.stores_product;
  const can_selling = store.can_selling;
  const is_digital_product = sel('_.stores_product.goods_type_id', product_detail) === 'DTG';
  const is_purchased_dtg = stores_product?.is_purchased_dtg || false;

  let status = 'ABLE';
  if (!can_selling) status = 'NOT_APPROVE';
  else if (is_sold_out) status = 'SOLD_OUT';
  else if (is_before_sale) status = 'COMING_SOON';
  else if (is_after_sale) status = 'END_SALE';
  else if (isApp && is_digital_product) status = 'ONLY_PC';
  else if (is_digital_product && is_purchased_dtg) status = 'PURCHASED_DTG';

  return status;
};

const isDeviceMultiOption = ({ devices, base_product, hyundai_n_products }) => {
  const options_disabled = !!hyundai_n_products;
  const has_devices = devices.length > 0;
  const device_option_enable = has_devices && !base_product.is_composite_publish;
  if (!has_devices || !device_option_enable) return;

  if (has_devices && !options_disabled && device_option_enable) {
    const device_options = go(
      devices,
      reject(({ is_composite_publish }) => is_composite_publish),
      map((item) => ({
        label: item.name,
        value: item.id,
        thumb: item.model_thumb_url,
        selected: item.id === base_product.id,
      })),
    );

    return device_options.length > 1;
  }

  return false;
};

const isColorMultiOption = ({
  base_product,
  stores_product,
  product_faces2,
  is_sticker,
  is_hotel_keyring,
  hyundai_n_products,
}) => {
  const options_disabled = !!hyundai_n_products;
  const can_not_change_color = sel('can_not_change_color', product_faces2) && !isBpStrapCase(base_product);
  const color_option_enabled =
    !NewMakerProductStyleS.GALAXY_TRANSPARENT_ZFLIP_BP_IDS.includes(base_product?.id) &&
    not(can_not_change_color) &&
    not(NewMakerWeS.isWeBaseProduct(base_product.id));

  if (color_option_enabled && !options_disabled && (!is_sticker || is_hotel_keyring)) {
    const base_product_colors = sel('_.base_product_colors', base_product);
    const store_product_colors = sel('_.store_product_colors', stores_product);
    const colors = getAvailableColors(base_product_colors, store_product_colors);
    return (
      go(
        colors,
        filter((color) => color.is_public),
      ).length > 1
    );
  }

  return false;
};

const isSizeMultiOption = ({ use_size_option, selected_bpc, hyundai_n_products }) => {
  if (!use_size_option) return false;
  const options_disabled = !!hyundai_n_products;

  if (!options_disabled) {
    const { base_product_sizes2 } = sel('_', selected_bpc) || {};

    return (
      go(
        base_product_sizes2,
        filter((bp_size) => {
          return bp_size.is_public && !bp_size._is_not_stock;
        }),
      ).length > 1
    );
  }

  return false;
};

export const isPodMultiOptionCompleted = () => {
  // TODO @yjj pod 멀티옵션 개발 완료되면 사용부 지우기
  return false;
};

export const isMultiOption = (
  product_detail,
  { devices = [], hyundai_n_products, selected_bpc, disabled } = {},
) => {
  // TODO @yjj Ness 멀티옵션 지원하면 풀기
  if (UtilS.isNessApp()) {
    return false;
  }

  if (disabled) {
    return false;
  }

  /* Front에서 Box로 넣은 경우 */
  const {
    base_product: box_base_product,
    stores_product: box_stores_product,
    spo: box_spo,
    product_color: box_product_color,
    selected_option_group,
  } = product_detail;

  /* Back에서 넣은 경우 */
  const {
    _: { stores_product: back_stores_product, spo: back_spo, base_product: back_base_product } = {},
    is_sticker: back_is_sticker,
  } = product_detail;

  const stores_product = box_stores_product || back_stores_product;
  const goods_type_id = stores_product?.goods_type_id;

  if (GoodsTypeS.isPod(goods_type_id)) {
    const base_product = box_base_product || back_base_product;
    const product_faces2 = box_product_color?.product_faces2 || product_detail?.product_faces2;
    const is_sticker =
      back_is_sticker ||
      (!UtilS.is_empty_object(selected_option_group || {}) && !!base_product['name' + _en]);
    const is_hotel_keyring = base_product.id == HOTEL_KEYRING_ID;
    const use_size_option = !devices.length && (!is_sticker || is_hotel_keyring);

    return (
      isPodMultiOptionCompleted() &&
      (isDeviceMultiOption({ devices, base_product, hyundai_n_products }) ||
        isColorMultiOption({
          base_product,
          stores_product,
          hyundai_n_products,
          product_faces2,
          is_sticker,
        }) ||
        isSizeMultiOption({ use_size_option, selected_bpc, hyundai_n_products }))
    );
  } else if (GoodsTypeS.isPbg(goods_type_id) || GoodsTypeS.isTpl(goods_type_id)) {
    const spo = box_spo || back_spo;

    return MShopAppProductPbOptionS.isSpoOptions(spo);
  } else {
    return false;
  }
};
