import { CREW_DOMAIN_NAME } from '../../../../Creator/Crew/S/constant.js';
import { EMOJI_REGX, SPECIAL_CHARACTERS_REGX } from '../../../../Util/S/constant.js';
import { NessInboundConstantS } from '../../../Inbound/S/Constant/module/NessInboundConstantS.js';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';

export const APP_RESET_NAVIGATE_TYPE = {
  HOME: 'Home',
  LIVE: 'Live',
  REVIEW: 'Community',
  SEARCH: 'Search',
  MYPAGE: 'Mypage',
};

// FIXME: 앱 공지 필요할 때 주석 제거 (아래 코드는 예시입니다)
// eslint-disable-next-line no-unused-vars
const NOTICE = {
  id: 'NOTICE_2023060700',
  title: { kr: '공지', en: 'Notice', jp: '通知する' },
  description: { kr: '공지입니다.', en: 'This is notice.', jp: 'これは通知です' },
  iconFilePath:
    'https://s3.marpple.co/files/u_14345/2021/6/original/913490c997da447298f1b0c53cda2dc4ac0daf921.png',
  buttons: [
    {
      text: { kr: '닫기', en: 'Close', jp: '閉まる' },
      close: true,
      setDoNotShowLater: true,
    },
    {
      text: { kr: '이동', en: 'Go', jp: '行く' },
      url: {
        kr: 'https://www.marpple.com/kr',
        en: 'https://www.marpple.com/en',
        jp: 'https://www.marpple.com/jp',
      },
      close: true,
      setDoNotShowLater: true,
    },
  ],
};

const SINGLE_QUOTE = '&#39;';

// MEMO: 버전 기록은 아래 링크에서 확인 가능합니다.
// MEMO: https://www.notion.so/marpple/07af015590404b6eb997860af9d718ae?pvs=4#29ceeea7c57a47308a95a99be8a38a8b

const NEED_UPDATE = {
  version: '1.5.14', // 해당 버전 "미만"일 때 강제 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '마플샵이 여러분의 의견을 반영하여\\n검색, 바로 구매하기 추가 등으로 더욱 좋아졌어요!\\n지금 바로 업데이트하고 만나보세요.',
    en: 'We have added \\"Search\\" and\\n\\"Buy Now\\" buttons based on your feedback!\\nPlease update our app to see these features.',
    jp: 'MarppleShopが皆さんの意見を反映して\\n検索、すぐに購入するなどが便利になりました！\\n今すぐアップデートしてご覧ください。',
  },
};

const RECOMMEND_UPDATE = {
  version: '1.6.0', // 해당 버전 "미만"일 때 권장 업데이트 화면이 노출됩니다.
  title: {
    kr: '마플샵 업데이트 안내',
    en: 'MarppleShop Update',
    jp: 'MarppleShop アップデート',
  },
  description: {
    kr: '마플샵의 검색 기능이 더욱 좋아졌어요!\\n지금 바로 업데이트하고 내가 원하는 상품을\\n쉽고 빠르게 찾아보세요.',
    en: `We${SINGLE_QUOTE}ve improved our search feature for you!\\nPlease update our app right away for quick,\\neasy access to what you${SINGLE_QUOTE}re looking for.`,
    jp: 'MarppleShopの検索機能がさらに良くなりました!\\n今すぐアップデートして欲しい商品を\\n簡単に早く探してみてください。',
  },
};

export const isNeedUpdateForDtg = (version) => {
  if (!version) return true;
  return compareVersion(version, '1.6.3') === -1;
};

const isNeedUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, NEED_UPDATE.version) === -1;
};

const isRecommendUpdate = (version) => {
  if (!version) return true;
  return compareVersion(version, RECOMMEND_UPDATE.version) === -1;
};

export const notice = (version, platform) => {
  // 1.6.2 이상 버전에서만 공지 화면 노출이 가능합니다.
  // 1.6.2 미만 버전 대응을 위해 추가한 코드이니 `NEED_UPDATE.version`이 1.6.2 이상으로 올라간다면 아래 if 문을 삭제해주세요.
  // 그 전까지 절대 삭제하시면 안 됩니다!
  if (compareVersion(version, '1.6.2') === -1) {
    return null;
  }
  // FIXME: 앱 공지 필요할 때 주석 제거
  // if (platform === 'Android' || platform === 'iOS') {
  //   return JSON.stringify({
  //     ...NOTICE,
  //     ...getUpdate(version, platform),
  //   });
  // }
  return null;
};

// eslint-disable-next-line no-unused-vars
const getUpdate = (version, platform) => {
  if (isNeedUpdate(version)) {
    return { needUpdate: needUpdate(version, platform) };
  }
  if (isRecommendUpdate(version)) {
    return { recommendUpdate: recommendUpdate(version, platform) };
  }
  return {};
};

export const needUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isNeedUpdate(version)) {
        return { platform, ...NEED_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const recommendUpdate = (version, platform) => {
  try {
    if (platform === 'Android' || platform === 'iOS') {
      if (isRecommendUpdate(version)) {
        return { platform, ...RECOMMEND_UPDATE };
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const is_sharable = (version, platform) => {
  return !(platform === 'Android' && version < '1.1.0');
};

/*
 * 나중에 추상화
 * */
export const getSellerWording = (crew, is_seller) =>
  crew.domain_name == CREW_DOMAIN_NAME.NAVER_WEBTOON ||
  crew.domain_name == CREW_DOMAIN_NAME.NAVER_WEBTOON_FAKE
    ? T('mshop::Menu::작가')
    : is_seller
    ? T('Seller')
    : T('mshop::Menu::Creator');

/**
 * version을 비교하는 함수
 * @param {string} version1
 * @param {string} version2
 * @return {number}
 * `version1` > `version2`이면 `1`
 *
 * `version1` < `version2`이면 `-1`
 *
 * `version1` = `version2`이면 `0`
 */
export const compareVersion = (version1, version2) => {
  const v1 = parseVersion(version1);
  const v2 = parseVersion(version2);

  if (v1.major > v2.major) return 1;
  if (v1.major < v2.major) return -1;

  if (v1.minor > v2.minor) return 1;
  if (v1.minor < v2.minor) return -1;

  if (v1.patch > v2.patch) return 1;
  if (v1.patch < v2.patch) return -1;

  return 0;
};

/**
 * @typedef {Object} ParsedVersion
 * @property {number} major
 * @property {number} minor
 * @property {number} patch
 */

/**
 *
 * @param {string} version
 * @return ParsedVersion
 */
export const parseVersion = (version) => {
  const versionFormat = /^(\d+).(\d+).(\d+)$/;
  const match = version.match(versionFormat);
  if (!match) {
    throw new Error('version is not valid.');
  }

  const [, major, minor, patch] = match;
  return {
    major: Number.parseInt(major),
    minor: Number.parseInt(minor),
    patch: Number.parseInt(patch),
  };
};

export const isPermitSpecialCharProductName = (value) => {
  if (SPECIAL_CHARACTERS_REGX.test(value) || EMOJI_REGX.test(value)) {
    return false;
  }
  return true;
};

export const moneySign = (price) => {
  if (T.lang === 'kr') {
    return `${price} 원`;
  }
  if (T.lang === 'en') {
    return `$ ${price}`;
  }
  if (T.lang === 'jp') {
    return `¥ ${price}`;
  }
};

export const isNessCrew = ({ domain_name }) => {
  return domain_name == NessInboundConstantS.NESS_CREW_DOMAIN_NAME.NESS;
};

export const makeMenuHref = (type, lang) => {
  return `${UtilS.makeApiUrl('/:lang/@/product/list/:type', {
    lang,
    type,
  })}`;
};
