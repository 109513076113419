import { every, filter, find, flatMap, go, pluck, sel } from 'fxjs/es';

/*
 * 옵션 선택할때마다, 그에 맞는 조합의 상황에 맞는 spo_options 를 내보내줌
 * */

export const pGetSelectedSpoItemId = ({ current_spo_options, spo_items }) => {
  if (spo_items.length === 1) return spo_items[0].id;
  const spo_option_value_ids = go(
    current_spo_options,
    flatMap((spo_option) => spo_option._.spo_option_values),
    filter((spo_option_value) => spo_option_value.is_selected),
    pluck('id'),
  );
  if (spo_option_value_ids.length !== current_spo_options.length) throw new Error('옵션을 선택해주세요.');
  const spo_item_id = go(
    spo_items,
    find((spo_item) =>
      every((bp_option_id) => spo_option_value_ids.includes(bp_option_id))(spo_item.bp_option_ids),
    ),
    sel('id'),
  );
  if (!spo_item_id) throw new Error('문제가 발생했습니다.');
  return spo_item_id;
};
