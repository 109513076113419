import { compact, html, strMap } from 'fxjs/es';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { PriceS } from '../../../Price/S/Function/module/PriceS.js';
import { VectorEditorConstantS } from '../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NessCustomMakerMobileFakeMpMakerTmplS } from '../../Mobile/FakeMpMaker/S/Tmpl/module/NessCustomMakerMobileFakeMpMakerTmplS.js';
import { NessCustomMakerMobileProductOptionTmplS } from '../../Mobile/ProductOption/S/Tmpl/module/NessCustomMakerMobileProductOptionTmplS.js';
import { NessCustomMakerMobileFooterButtonTmplS } from '../../Mobile/FooterButton/S/Tmpl/module/NessCustomMakerMobileFooterButtonTmplS.js';
import { NessCustomMakerCoreS } from '../../Core/S/Function/module/NessCustomMakerCoreS.js';
import { NessCustomMakerTmplS } from './module/NessCustomMakerTmplS.js';
import { NessOutboundTmplS } from '../../../Ness/Outbound/S/Tmpl/module/NessOutboundTmplS.js';
import { MShopAppProductItemS } from '../../../MShop/App/Product/Item/S/Function/module/MShopAppProductItemS.js';
import { MShopStudioPreDiscountProductS } from '../../../MShop/Studio/PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { creatorPriceOfProduct } from '../../../ProductColorPrice/S/fs.js';
import { isStoreProductCustom } from '../../../Creator/Product/S/fs.js';
import { NewMakerProductS } from '../../../NewMaker/Product/S/Function/module/NewMakerProductS.js';
import { UtilTmplS } from '../../../Util/S/Tmpl/module/UtilTmplS.js';

const makeNoticeContent = (data) => html`
  <td class="items">
    ${strMap(
      ({ title, price_tmpl }) =>
        `<div class="item"><div class="title"> ${title} 테마</div>${price_tmpl}</div>`,
      data,
    )}
  </td>
`;

export const makeNoticeHtml = ({ notice_infos }) => {
  return html` <div class="tippy-ness_notice">
    <div class="tippy-ness_notice__title">사용 에셋 안내</div>
    <table class="tippy-ness_notice__contents">
      ${strMap(
        (info) => html` <tr>
          <td class="item-title">${info?.title}</td>
          ${makeNoticeContent(info?.content)}
        </tr>`,
        compact(notice_infos),
      )}
    </table>
  </div>`;
};

export const makePricingAndCartSectionHtml = (product, maker_props = {}) => {
  const cnt = maker_props.quantity || 1;
  const base_product = product._.base_product;
  const price = NessCustomMakerCoreS.getProductPrice(product);

  const is_vector_editor = VectorEditorConstantS.EDITOR_TYPES.includes(base_product.maker_type);
  const btn_disabled =
    maker_props.is_condition_agree === false || NewMakerProductS.isUnavailableSize(product);

  return html`
    <div class="ness_option_wrap">
      ${is_vector_editor ? html` <button id="to_vector_editor" type="button">Customize</button>` : ``}
      <div class="ness_quantity">
        <span>Quantity</span>
        <div class="counter">
          <button class="counter__button minus"></button>
          <input class="counter__quantity" name="quantity" readonly type="text" value="${cnt}" />
          <button class="counter__button plus"></button>
        </div>
      </div>
      <div class="ness_divide"></div>
      <div class="ness_total_price">
        <div class="total_count">Total <span class="count">${cnt}</span></div>
        <div class="total_price">
          <span class="price">${UtilS.makeCurrencyPrice(PriceS.pricify(price * cnt), T.lang)}</span>
          ${NessCustomMakerTmplS.makeQuestionMarkHtml()}
        </div>
      </div>
      <div class="ness_buttons">
        <button class="ness_add_to_cart ${UtilTmplS.disableIf(btn_disabled)}">Add to Cart</button>
        <button class="ness_buy ${UtilTmplS.disableIf(btn_disabled)}">Buy</button>
      </div>
    </div>
  `;
};

export const mobileHtml = (ness_custom_maker, is_vector_editor) => {
  const { maker, product_detail, product } = ness_custom_maker;
  const sp = product_detail.stores_product;
  const custom_level = sp.custom_level;

  const pre_discount_price = product._.pre_discount_product.pre_discount_price;
  const origin_price = creatorPriceOfProduct(maker.product_color);
  const pre_discount_rate = pre_discount_price
    ? MShopStudioPreDiscountProductS.calcDiscountedRateByPercent(pre_discount_price, origin_price)
    : null;
  const is_custom = isStoreProductCustom(custom_level);

  const badge = MShopAppProductItemS.setBadge(
    {
      spo_item_stocks: [],
      is_sptg: !!sp?._?.stores_products_token_gates.length,
      ...sp,
      pre_discount_rate,
      is_custom,
    },
    false,
  );

  return html`
    <div class="custom-product-detail-mo" data-is_vector_editor="${is_vector_editor}">
      ${badge
        ? `<div class="custom-product-detail-mo__badge app-product-item--NESS">
            ${NessOutboundTmplS.makeNessProductBadgeTmpl(badge, 'app-product-item', true)}
           </div>`
        : ''}
      <div class="custom-product-detail-mo__mp-maker"></div>
      ${NessCustomMakerMobileFakeMpMakerTmplS.makeHtml()}
      ${NessCustomMakerMobileProductOptionTmplS.makeHtml(maker, custom_level)}
      ${NessCustomMakerMobileFooterButtonTmplS.makeHtml(is_vector_editor)}
    </div>
  `;
};

export const makeShareHtml = () => html` <button type="button" class="ness-custom-maker__share">
  <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip1_3923_28060)">
      <path
        d="M22.0887 9.02203C20.3705 9.02203 18.9776 7.62914 18.9776 5.91092C18.9776 4.1927 20.3705 2.7998 22.0887 2.7998C23.8069 2.7998 25.1998 4.1927 25.1998 5.91092C25.1998 7.62914 23.8069 9.02203 22.0887 9.02203Z"
        fill="currentColor"
      ></path>
      <path
        d="M5.91092 17.1109C4.1927 17.1109 2.7998 15.718 2.7998 13.9998C2.7998 12.2816 4.1927 10.8887 5.91092 10.8887C7.62914 10.8887 9.02203 12.2816 9.02203 13.9998C9.02203 15.718 7.62914 17.1109 5.91092 17.1109Z"
        fill="currentColor"
      ></path>
      <path
        d="M22.0887 25.1998C20.3705 25.1998 18.9776 23.8069 18.9776 22.0887C18.9776 20.3705 20.3705 18.9776 22.0887 18.9776C23.8069 18.9776 25.1998 20.3705 25.1998 22.0887C25.1998 23.8069 23.8069 25.1998 22.0887 25.1998Z"
        fill="currentColor"
      ></path>
      <path
        d="M6.89453 14.7963L21.0563 21.8897M21.1061 6.09766L6.99409 13.1537"
        stroke="currentColor"
        stroke-miterlimit="10"
      ></path>
    </g>
  </svg>
</button>`;

export const makeQuestionMarkHtml = () => {
  return html` <span class="ness-custom-maker__question-mark"></span> `;
};
