export const FEED_LIST_SETTINGS_FRAME = '@FRAME/Creator/Feed/setting_feed_list';
export const FEED_LIST_SETTINGS_PAGE = '@PAGE/Creator/Feed/setting_feed_list';
export const FEED_LIST_SETTINGS_TAB = '@TAB/Creator/Feed/setting_feed_list';

export const FEED_DETAIL_SETTINGS_FRAME = '@FRAME/Creator/Feed/setting_feed_detail';
export const FEED_DETAIL_SETTINGS_PAGE = '@PAGE/Creator/Feed/setting_feed_detail';
export const FEED_DETAIL_SETTINGS_TAB = '@TAB/Creator/Feed/setting_feed_detail';

export const FEED_LIST_CREW_FRAME = '@FRAME/Creator/Feed/crew_feed_list';
export const FEED_LIST_CREW_PAGE = '@PAGE/Creator/Feed/crew_feed_list';
export const FEED_LIST_CREW_TAB = '@TAB/Creator/Feed/crew_feed_list';

export const FEED_DETAIL_CREW_FRAME = '@FRAME/Creator/Feed/crew_feed_detail';
export const FEED_DETAIL_CREW_PAGE = '@PAGE/Creator/Feed/crew_feed_detail';
export const FEED_DETAIL_CREW_TAB = '@TAB/Creator/Feed/crew_feed_detail';

export const MEDIUM_EDITOR_CDN = {
  JQUERY_UI: `
      <link rel="stylesheet" href="//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css">
  `,
  FONTAWESOME6: `
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/regular.min.css"
    />
  `,
  FONTAWESOME: `<link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
    />`,
  CSS: `<link
      rel="stylesheet"
      href="//cdn.jsdelivr.net/npm/medium-editor@latest/dist/css/medium-editor.min.css"
      type="text/css"
      media="screen"
      charset="utf-8"
    />`,
  THEME: ` 
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/themes/default.min.css"
    />`,
  IMAGE_PLUGIN_STYLE: `<link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/medium-editor-insert-plugin/2.5.1/css/medium-editor-insert-plugin.min.css"
    />`,
  scripts: [
    // `https://cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/js/medium-editor.js`,
    `https://code.jquery.com/ui/1.12.1/jquery-ui.min.js`,
    `https://www.instagram.com/embed.js`,
    'https://platform.twitter.com/widgets.js',
  ],
};

export const FEED_TYPE = {
  seller_tip: 'SELLER_TIP',
  mps_content: 'MPS_CONTENT',
  ness_branded_content: 'NESS_BRANDED_CONTENT',
  ness_editorial: 'NESS_EDITORIAL',
  popup_store: 'POPUP_STORE',
};

export const SELLER_TIP_KLASSES = {
  admin: 'seller_tip_admin_tab',
};

export const SELLER_TIP_SELECTORS = {
  admin: '.seller_tip_admin_tab',
};
