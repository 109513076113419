import { $qs } from 'fxdom/es';
import { compact, map, go, filter } from 'fxjs/es';
import { getBaseProductInMaker } from '../../../../Maker/F/getSth.js';
import { MuiF } from '../../../../Mui/F/Function/module/MuiF.js';
import { UtilF } from '../../../../Util/F/Function/module/UtilF.js';
import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';
import { NewMakerCheckConditionF } from '../../../CheckCondition/F/Function/module/NewMakerCheckConditionF.js';
import { NewMakerConditionPopupMuiF } from '../Mui/module/NewMakerConditionPopupMuiF.js';

export const getMappingConfirmPopupData = (type) => {
  return getBaseProductInMaker()._?.confirm_popups?.find((confirm_popup) => confirm_popup.type === type);
};

export const getTooltipConfirmPopups = (_en) => {
  return go(
    getBaseProductInMaker()._?.confirm_popups,
    filter((confirm_popup) => confirm_popup['tooltip' + _en]),
  );
};

const makeConditionPopupData = async (type) => {
  const confirm_popup = getMappingConfirmPopupData(type);

  return go(confirm_popup, (confirm) => {
    if (!confirm) return;
    const is_mobile = UtilF.isLegacyMobile();

    const [src1, src2] = go(
      [
        is_mobile ? confirm['m' + G._en] : confirm['pc' + G._en],
        is_mobile ? confirm['m2' + G._en] : confirm['pc2' + G._en],
      ],
      compact,
      map((src) => {
        if (src) {
          return UtilImageS.getResizedUrl({
            url: src,
            width: is_mobile ? 800 : 1760,
            format: 'webp',
            quality: 85,
          });
        }
      }),
    );

    const title = confirm['title' + G._en];
    const ok_text = confirm['ok_text' + G._en];
    const cancel_text = confirm['cancel_text' + G._en];
    const is_agreement = !!confirm.is_agreement;
    const type = confirm.type;

    if (!src1) return;
    return { src1, src2, title, ok_text, cancel_text, is_agreement, type };
  });
};

const conditionPopupOpen = (popup_data) => {
  const is_mobile = UtilF.isLegacyMobile();

  setTimeout(() => {
    const frame = $qs('.don_frame[frame_name="/modules/NewMaker/ConditionPopup/S/Mui/frame.js"]');
    if (!frame) {
      conditionPopupOpen(popup_data);
    }
  }, 100);

  return new Promise((resolve) => {
    return MuiF.openFrame(NewMakerConditionPopupMuiF.frame, (frame, page, [tab]) => {
      frame.prev_frame_show = is_mobile;
      frame.is_modal = !is_mobile;
      frame.closed = resolve;

      tab.makeData = () => {
        return popup_data;
      };
    });
  });
};

export const open = async (confirm_popup_condition) => {
  const popup_data = await makeConditionPopupData(confirm_popup_condition);
  if (!popup_data) return;
  return conditionPopupOpen(popup_data);
};

export const checkCondition = async (type, cv_target) => {
  return NewMakerCheckConditionF.is_condition_pass(type, cv_target);
};
