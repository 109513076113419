import { html, View } from 'rune-ts';
import klass from './LocalStoragePopup.module.scss';
import { typo } from '../../../../shared/typography/typo';
import Swiper from 'swiper';
import { EffectFade, Pagination } from 'swiper/modules';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { htmlIf } from '../../../../shared/util';
import { CloseIcon } from '../../../../shared/components/atoms/Icon';
import { pipe, map, toArray, filter, find } from '@fxts/core';
import { getResizedUrl } from '../../../../shared/util/image';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import format from 'date-fns/format/index.js';
import { $attr } from 'fxdom/es';

interface clickEventImage {
  (): void;
}

export interface LocalStoragePopupSlide {
  image_url: string;
  id?: number;
  key: string;
  link_url?: string;
  target?: string;
  click_event_image?: clickEventImage;
  click_event_image_after_close?: boolean;
  close_color_code: string;
  button_bg_color_code: string;
  button_text_color_code: string;
}

export interface LocalStoragePopupProps {
  popups?: LocalStoragePopupSlide[];
  custom_klass?: string;
  is_preview?: boolean;
  crew_id?: number;
  product_id?: number;
  store_id?: number;
}

export interface LocalStorageClientPopupProps {
  popups: LocalStoragePopupSlide[];
  custom_klass?: string;
  is_preview?: boolean;
  crew_id?: number;
  product_id?: number;
  store_id?: number;
}

export class LocalStoragePopup extends View<LocalStoragePopupProps> {
  current_popup?: LocalStoragePopupSlide;
  swiper?: Swiper;

  constructor(props: LocalStoragePopupProps) {
    super(props);

    if (props.popups && props.popups.length > 0) {
      this.current_popup = props.popups[0];
    }
  }

  override template() {
    const { custom_klass = '', is_preview = false } = this.data;

    const {
      close_color_code = '',
      button_bg_color_code = '',
      button_text_color_code = '',
    } = this.current_popup || {};

    return html`
      <div class="${klass.container} ${custom_klass}" data-preview="${is_preview}">
        <div class="${klass.backdrop}"></div>

        <div class="${klass.popup_content}">
          <div class="swiper ${klass.swiper}">
            <div class="swiper-wrapper ${klass.swiper_wrapper}">${this._renderPopupList()}</div>
            ${htmlIf(
              html`<div class="swiper-pagination ${klass.pagination}"></div>`,
              !!this.data.popups && this.data.popups?.length > 1,
            )}
            <div class="${klass.close_icon}" style="color:${close_color_code}">${CloseIcon}</div>
          </div>
          <div
            class="${klass.button_container}"
            style="background-color:${button_bg_color_code};color:${button_text_color_code}"
          >
            <span class="${klass.show_today} ${typo('14_medium')}">오늘 보지 않기</span>
            <span class="${klass.close} ${typo('14_bold')}">닫기</span>
          </div>
        </div>
      </div>
    `;
  }

  protected override onUnmount() {
    if (!this.data.is_preview && MShopUtilF.isMobile()) {
      MShopUtilF.allowScroll();
    }
  }

  protected override onMount() {
    this.swiper = this._onLoadPopupSwiper();

    if (!this.data.is_preview && MShopUtilF.isMobile()) {
      MShopUtilF.preventScroll();
    }

    this.delegate('click', `.${klass.close}, .${klass.close_icon}`, () => {
      this.closeCurrentPopup();
    });

    this.delegate('click', `.${klass.show_today}`, async () => {
      const key = this.current_popup?.key;
      this.closeCurrentPopup();

      const now_date = new Date();
      window.localStorage.setItem(`${key}`, format(now_date, 'yyyy-MM-dd'));
    });

    if (
      find((popup) => {
        return !!popup.click_event_image;
      }, this.data.popups || [])
    ) {
      this.delegate('click', `.${klass.swiper_slide}[is_click_event_image="true"]`, async (e) => {
        const _key = $attr('key', e.currentTarget);
        const find_popup = find((popup) => {
          return popup.key == _key;
        }, this.data.popups || []) as LocalStoragePopupSlide;

        if (find_popup?.click_event_image) {
          find_popup.click_event_image();
          if (find_popup.click_event_image_after_close) {
            this.closeCurrentPopup();
          }
        }
      });
    }
  }

  private _onLoadPopupSwiper() {
    return new Swiper(`.${klass.swiper}`, {
      modules: [Pagination, EffectFade],
      slidesPerView: 1,
      loop: !!(this.data.popups && this.data.popups.length > 1),
      pagination: {
        el: '.swiper-pagination',
        bulletClass: klass.bullet,
        bulletActiveClass: klass.active_bullet,
        clickable: true,
      },
      on: {
        slideChange: (swiper) => {
          const { popups } = this.data;
          if (popups) {
            this.current_popup = popups[swiper.realIndex] || popups[0];
            this.setPopupData(this.current_popup, popups);
          }
        },
      },
    });
  }

  private _renderPopupList() {
    return pipe(
      this.data.popups || [],
      map(({ image_url, link_url, target, click_event_image, key }) => {
        return {
          image_url: getResizedUrl({
            url: image_url,
            width: 1120,
            height: 840,
            quality: 85,
            format: 'webp',
          }),
          link_url: link_url ? UtilS.unEscaper(link_url) : '',
          target,
          is_click_event_image: !!click_event_image,
          key,
        };
      }),
      map(
        ({ image_url, link_url, target, is_click_event_image, key }) =>
          html`
            <${link_url ? 'a' : 'div'}
              class="swiper-slide ${klass.swiper_slide}"
              is_click_event_image="${!!is_click_event_image}"
              key="${key}"
              ${link_url ? html`href="${link_url}" ${target ? 'target="_blank"' : ''}` : ''}
            >
              <img src="${image_url}" alt="Popup Image" />
            </${link_url ? 'a' : 'div'}>
          `,
      ),
      toArray,
    );
  }

  setPopupData(custom_popup_slide?: LocalStoragePopupSlide, popups?: LocalStoragePopupProps['popups']) {
    this.data.popups = popups;
    this.current_popup = custom_popup_slide;
    const $swiper_container = this.element().querySelector(`.${klass.swiper}`) as HTMLElement;
    const $button_container = this.element().querySelector(`.${klass.button_container}`) as HTMLElement;
    const $close_icon_wrapper = this.element().querySelector(`.${klass.close_icon}`) as HTMLElement;

    $swiper_container.style.backgroundColor = this.current_popup
      ? this.current_popup.button_bg_color_code
      : '';
    $button_container.style.backgroundColor = this.current_popup
      ? this.current_popup.button_bg_color_code
      : '';
    $button_container.style.color = this.current_popup ? this.current_popup.button_text_color_code : '';
    $close_icon_wrapper.style.color = this.current_popup ? this.current_popup.close_color_code : '';
  }

  closeCurrentPopup() {
    if (this.data.popups) {
      if (this.data.popups?.length > 1) {
        this.data.popups = pipe(
          this.data.popups,
          filter((popup) => popup.id !== this.current_popup?.id),
          toArray,
        );
        this.redraw();
      } else {
        this.element().remove();
      }
    }
  }

  override redraw() {
    super.redraw();
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = this._onLoadPopupSwiper();
    }
    return this;
  }
}
