// 상품상세 - 구매 안내
import format from 'date-fns/format/index.js';
import addDays from 'date-fns/fp/addDays/index.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { go, html, identity, ifElse, sel } from 'fxjs/es';
import { UtilS } from '../../../../../../Util/S/Function/module/UtilS.js';
import { messages } from '../../message_id.js';
import { getFqaType, getRefundHgId, getShippingHgId } from './faq.js';
import {
  makeDeliveryInfoTable_POD_TPL,
  sellerInfo,
  storesDeliveryCompanyInfo,
  takeBackInfo,
} from './storesDeliveryCompanyInfo.js';
import { UtilConstantS } from '../../../../../../Util/S/Constant/module/UtilConstantS.js';
import date_fns_tz from 'date-fns-tz';
import { MShopUtilS } from '../../../../../Util/S/Function/module/MShopUtilS.js';
import { MShopCoreTmplS } from '../../../../../Core/S/Tmpl/module/MShopCoreTmplS.js';
import { MShopCoreConstantS } from '../../../../../Core/S/Constant/module/MShopCoreConstantS.js';
import { makeEmptyScreenNavigate } from '../../../../../../../services/MarppleShop/shared/app/navigate.ts';

const { utcToZonedTime } = date_fns_tz;

export const makeTplPurchaseInfoHtml = ({ is_mobile, plus_crew, product_detail, store }) => {
  if (UtilS.isNessApp()) {
    return makeTplPurchaseInfoHtmlNess({ plus_crew, product_detail, store });
  }

  const {
    _: { stores_product },
    stores_delivery_company,
  } = product_detail;

  const now = Date.now();

  // TODO: 2024 추석배송 일정 변경 하드코딩 (https://www.notion.so/marpple/3cafd1c489674f8a928b17832d9df529)
  const is_2024_chuseok_step1 =
    now > utcToZonedTime('2024-09-09 00:00:00', UtilConstantS.TZ_SEOUL) &&
    now < utcToZonedTime('2024-09-16 00:00:00', UtilConstantS.TZ_SEOUL);

  const is_2024_chuseok_step2 =
    now > utcToZonedTime('2024-09-16 00:00:00', UtilConstantS.TZ_SEOUL) &&
    now < utcToZonedTime('2024-09-23 00:00:00', UtilConstantS.TZ_SEOUL);

  const is_2024_chuseok_step3 =
    now > utcToZonedTime('2024-09-23 00:00:00', UtilConstantS.TZ_SEOUL) &&
    now < utcToZonedTime('2024-09-30 00:00:00', UtilConstantS.TZ_SEOUL);

  const chuseok_deliver_dates = {
    start: [
      utcToZonedTime('2024-09-23 00:00:00', UtilConstantS.TZ_SEOUL),
      utcToZonedTime('2024-09-25 00:00:00', UtilConstantS.TZ_SEOUL),
      utcToZonedTime('2024-10-04 00:00:00', UtilConstantS.TZ_SEOUL),
    ],
    end: [
      utcToZonedTime('2024-09-30 00:00:00', UtilConstantS.TZ_SEOUL),
      utcToZonedTime('2024-10-04 00:00:00', UtilConstantS.TZ_SEOUL),
      utcToZonedTime('2024-10-14 00:00:00', UtilConstantS.TZ_SEOUL),
    ],
  };

  const transformAtChuseok = (transformed_dates) => (date) => {
    if (is_2024_chuseok_step1) {
      return transformed_dates[0];
    } else if (is_2024_chuseok_step2) {
      return transformed_dates[1];
    } else if (is_2024_chuseok_step3) {
      return transformed_dates[2];
    } else {
      return date;
    }
  };

  const isSaesonOnTshirt = () => sel('_.stores_product.product_id', product_detail) == 7053483;
  const addIfSeasonOn = ifElse(isSaesonOnTshirt, () => new Date('2021-03-17'), identity);
  const deliver_date = go(
    now,
    UtilS.getSalesPeriodOf,
    sel('sell_end_at'),
    addDays(3),
    addIfSeasonOn,
    transformAtChuseok(chuseok_deliver_dates.start),
  );
  const deliver_until_date = go(addDays(7)(deliver_date), transformAtChuseok(chuseok_deliver_dates.end));
  const date_format = T(messages.format.short_date);

  // TODO bjk
  //  잠뜰
  //  상수리
  //  끝나면 삭제
  const is_nbox = UtilS.isNboxApp();

  const makeUrl = ({ type, hg_id }) => {
    return `/${T.lang}/@/help?type=${type}&hg_id=${hg_id}`;
  };

  return html`
    <div class="pd-purchase-info">
      <h3 class="pd-purchase-info__title">${ET('product_detail::tab03')}</h3>

      <!--  크리에이터 정보  -->
      <div class="pd-purchase-info__section">${sellerInfo(store)}</div>
      <div class="pd-purchase-info__divider"></div>

      <!-- 배송 정보 -->
      <div class="pd-purchase-info__section">
        <div class="pd-purchase-info__header">
          <h4>${ET('product_detail::purchase_info::delivery_info')}</h4>
          <div class="arrow">${MShopCoreTmplS.arrowDownIcon()}</div>
        </div>

        <div class="pd-purchase-info__body">
          <div class="pd-purchase-info__body--inner">
            ${stores_product?.shipping_date_hidden
              ? ''
              : html`
                  <div class="pd-purchase-info__body__desc">
                    <p>
                      ${ET('product_detail::purchase_info::delivery_desc_tpl', {
                        start: format(deliver_date, date_format),
                        end: format(deliver_until_date, date_format),
                        color: MShopCoreConstantS.colors.blue,
                      })}
                    </p>
                  </div>
                `}
            ${makeDeliveryInfoTable_POD_TPL({ stores_delivery_company, is_tpl: true })}
            <div class="pd-purchase-info__links pd-links">
              <a
                href="${makeUrl({
                  domain: store?.domain_name,
                  type: getFqaType({ is_nbox }),
                  hg_id: getShippingHgId({ is_nbox }),
                })}"
                class="pd-links__item"
                data-post-message="${$dataStr({
                  screen: {
                    title: `FAQ`,
                    type: 'sub',
                    uri: makeUrl({
                      domain: store?.domain_name,
                      type: getFqaType({ is_nbox }),
                      hg_id: getShippingHgId({ is_nbox }),
                    }),
                  },
                })}"
              >
                ${ET('product_detail::purchase_info::delivery_faq_btn')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="pd-purchase-info__divider"></div>

      <!-- 교환 환불정보 -->
      <div class="pd-purchase-info__section">
        <div class="pd-purchase-info__header">
          <h4>${ET('product_detail::purchase_info::exchange_info')}</h4>
          <div class="arrow">${MShopCoreTmplS.arrowDownIcon()}</div>
        </div>
        <div class="pd-purchase-info__body">
          <div class="pd-purchase-info__body--inner">
            <div class="pd-purchase-info__body__desc">
              ${stores_product['refund_info' + G._en]
                ? UtilS.linkify(stores_product['refund_info' + G._en])
                : html`
                    <p>${T(messages.purchaseInfo.refund_desc1)}</p>
                    <p>${T(messages.purchaseInfo.refund_desc2)}</p>
                  `}
            </div>
            <table class="pd-purchase-info__table">
              <tr>
                <th>${T(messages.purchaseInfo.cs_title)}</th>
                <td>${MShopUtilS.getCustomerServiceInfo(plus_crew.id).tel}</td>
              </tr>
              <tr>
                <th>${T(messages.purchaseInfo.email_title)}</th>
                <td>${MShopUtilS.getCustomerServiceInfo(plus_crew.id).email}</td>
              </tr>
            </table>
            ${takeBackInfo(stores_delivery_company)}
            <div class="pd-purchase-info__links pd-links">
              <a
                href="mailto:${MShopUtilS.getCustomerServiceInfo(plus_crew.id).email}"
                class="pd-links__item"
              >
                <span>${ET('product_detail::purchase_info::qna_btn')}</span>
              </a>
              <a
                href="${makeUrl({
                  domain: store?.domain_name,
                  type: getFqaType({ is_nbox }),
                  hg_id: getRefundHgId({ is_nbox }),
                })}"
                class="pd-links__item"
                data-post-message="${$dataStr({
                  screen: {
                    title: `FAQ`,
                    type: 'sub',
                    uri: makeUrl({
                      domain: store?.domain_name,
                      type: getFqaType({ is_nbox }),
                      hg_id: getRefundHgId({ is_nbox }),
                    }),
                  },
                })}"
              >
                ${ET('product_detail::purchase_info::exchange_faq_btn')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="pd-purchase-info__divider"></div>

      <!--  신고안내  -->
      <div class="pd-purchase-info__section">
        <div class="pd-purchase-info__header">
          <h4>${ET('product_detail::purchase_info::report_info')}</h4>
          <div class="arrow">${MShopCoreTmplS.arrowDownIcon()}</div>
        </div>
        <div class="pd-purchase-info__body">
          <div class="pd-purchase-info__body--inner">
            <div class="pd-purchase-info__body__desc">
              <p>${ET('product_detail::purchase_info::report_desc')}</p>
            </div>
            <div class="pd-purchase-info__anchor">
              <a
                href="/${ET.lang}/@/solution/add"
                data-post-message="${$dataStr(makeEmptyScreenNavigate(`/${ET.lang}/@/solution/add`))}"
                class="product_report_btn"
              >
                ${MShopCoreTmplS.reportIcon()} ${ET('product_detail::purchase_info::report_btn')}
                ${MShopCoreTmplS.chevronRightSmall()}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="pd-purchase-info__divider"></div>
    </div>
  `;
};

const makeTplPurchaseInfoHtmlNess = ({ plus_crew, product_detail, store }) => {
  const {
    _: { stores_product },
    stores_delivery_company,
  } = product_detail;
  const is_nbox = UtilS.isNboxApp();

  const makeUrl = ({ domain, type, hg_id }) => {
    return `/${T.lang}/${domain}/help?type=${type}&hg_id=${hg_id}`;
  };

  return html`
    <div class="pd-purchase-info">
      <div class="pd-purchase-info__section">
        <div class="pd-purchase-info__header">
          <h4>${T(messages.purchaseInfo.delivery_title)}</h4>
        </div>
        ${stores_product?.shipping_date_hidden
          ? ''
          : `
            <div class="pd-purchase-info__body__desc">
              <p>${ET('product_detail::purchase_delivery::pd03')}</p>
            </div>
          `}
        ${storesDeliveryCompanyInfo({ stores_delivery_company, stores_product })}
        <div class="pd-purchase-info__links pd-links">
          <a
            href="${makeUrl({
              domain: store?.domain_name,
              type: getFqaType({ is_nbox }),
              hg_id: getShippingHgId({ is_nbox }),
            })}"
            class="pd-links__item"
            data-post-message="${$dataStr({
              screen: {
                title: `FAQ`,
                type: 'sub',
                uri: makeUrl({
                  domain: store?.domain_name,
                  type: getFqaType({ is_nbox }),
                  hg_id: getShippingHgId({ is_nbox }),
                }),
              },
            })}"
            >${ET('product_detail::link_delivery')}</a
          >
        </div>
        <div class="pd-purchase-info__divider"></div>
        <div class="pd-purchase-info__section">
          <div class="pd-purchase-info__header">
            <h4>${T(messages.purchaseInfo.refund_title)}</h4>
          </div>
          ${UtilS.isNessApp()
            ? html`
                <div class="pd-purchase-info__body__desc">
                  <p>${ET('product_detail::purchase_delivery::info::a_01')}</p>
                  <p>${ET('product_detail::purchase_delivery::info::a_02')}</p>
                  <p>${ET('product_detail::purchase_delivery::info::01')}</p>
                  <p>${ET('product_detail::purchase_delivery::info::02')}</p>
                  <p>${ET('product_detail::purchase_delivery::info::03')}</p>
                  <p>${ET('product_detail::purchase_delivery::info::04')}</p>
                </div>
                <div class="pd-purchase-info__body__desc">
                  <h4>${ET('product_detail::purchase_delivery::case::title')}</h4>
                  <ul>
                    <li>${ET('product_detail::purchase_delivery::case::01')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::02')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::03')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::04')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::05')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::06')}</li>
                    <li>${ET('product_detail::purchase_delivery::case::07')}</li>
                  </ul>
                </div>
              `
            : html` <div class="pd-purchase-info__body__desc">
                ${stores_product['refund_info' + G._en]
                  ? UtilS.linkify(stores_product['refund_info' + G._en])
                  : html`
                      <p>${T(messages.purchaseInfo.refund_desc1)}</p>
                      <p>${T(messages.purchaseInfo.refund_desc2)}</p>
                    `}
              </div>`}

          <div class="pd-purchase-info__body">
            <table>
              <tr>
                <th>${T(messages.purchaseInfo.cs_title)}</th>
                <td>${MShopUtilS.getCustomerServiceInfo(plus_crew.id).tel}</td>
              </tr>
              <tr>
                <th>${T(messages.purchaseInfo.email_title)}</th>
                <td>${MShopUtilS.getCustomerServiceInfo(plus_crew.id).email}</td>
              </tr>
            </table>
          </div>
          <div class="pd-purchase-info__links pd-links">
            <a href="mailto:${MShopUtilS.getCustomerServiceInfo(plus_crew.id).email}" class="pd-links__item">
              <span>${ET('product_detail::link_chat')}</span>
            </a>
            <a
              href="${makeUrl({
                domain: store?.domain_name,
                type: getFqaType({ is_nbox }),
                hg_id: getRefundHgId({ is_nbox }),
              })}"
              class="pd-links__item"
              data-post-message="${$dataStr({
                screen: {
                  title: `FAQ`,
                  type: 'sub',
                  uri: makeUrl({
                    domain: store?.domain_name,
                    type: getFqaType({ is_nbox }),
                    hg_id: getRefundHgId({ is_nbox }),
                  }),
                },
              })}"
              >${ET('product_detail::link_change')}</a
            >
          </div>
        </div>
        ${takeBackInfo(stores_delivery_company)}
        <div class="pd-purchase-info__divider"></div>
      </div>
    </div>
  `;
};
