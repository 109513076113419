import { html, on } from 'rune-ts';
import klass from './FloatingButton.module.scss';
import { ProductListTypeS } from '../../../../../features/ProductList/outbound/share';
import { ProductThumbnail } from '../../ProductThumbnail/ProductThumbnail';
import { FloatingButtonBase } from './FloatingButtonBase';
import { makeFloatingButtonTemplate } from '../util';

export type FloatingButtonRecentProductData = {
  product_thumbnail: {
    data: ProductListTypeS.ProductThumbnailData;
    option: ProductListTypeS.ProductThumbnailOption;
  };
  product_page_url: string;
};

export class FloatingButton_recent_product extends FloatingButtonBase<
  FloatingButtonRecentProductData & { show?: boolean }
> {
  // constructor(data) {
  //   super(data);
  // }

  @on('click')
  override handleClick() {
    super.handleClick();
    alert('상품 상세 연결!');
  }

  override template() {
    return makeFloatingButtonTemplate(
      html`<div class="${klass.floating_button}">
        ${new ProductThumbnail(this.data.product_thumbnail.data, this.data.product_thumbnail.option)}
      </div> `,
      this.state.show_itself,
    );
  }
}
