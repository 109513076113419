export const replaceBreakLine = (target) => (target || '').replace(/\n/g, '<br />');

export const getYoutubeId = (url) => {
  const embed_regexp = /embed\/([^&]+)/;
  const match_regexp = /watch\?v=([^&]+)/;
  const short_regexp = /shorts\/([^&|?]+)/;

  if (embed_regexp.test(url)) {
    const [, youtube_id] = url.match(embed_regexp);
    return youtube_id;
  }
  if (match_regexp.test(url)) {
    const [, youtube_id] = url.match(match_regexp);
    return youtube_id;
  }
  if (short_regexp.test(url)) {
    const [, youtube_id] = url.match(short_regexp);
    return youtube_id;
  }

  return url.replace(/(https?:\/\/(www\.)?)?youtube\.com\/watch\?v=|(https?:\/\/(www\.)?)?youtu\.be\//, '');
};

export const makeYoutubeEmbedUrl = (youtube_id = '') => {
  const embed_url = 'https://www.youtube.com/embed/';
  return embed_url + youtube_id.split('?')[0];
};

export const makeYoutubeThumbUrl = (youtube_id = '') => {
  const thumb_url = 'https://img.youtube.com/vi/';
  const max_file_name = '/maxresdefault.jpg';
  return thumb_url + youtube_id.split('?')[0] + max_file_name;
};
