import $dataStr from 'fxdom/es/dataStr.js';
import { chunk, go, html, map, strMap, zipWithIndexL } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppProductItemTmplS } from '../../../Product/Item/S/Tmpl/module/MShopAppProductItemTmplS.js';
import { MShopAppProductSubConstantS } from '../../../Product/Sub/S/Constant/module/MShopAppProductSubConstantS.js';

export const selectCategory = (cate_lists, selected_id) => html`
  <div class="app-home-select-category">
    <ul class="app-home-select-category__wrapper">
      ${strMap(
        ({ id, name }) => html`
          <li class="app-home-select-category__item">
            <button
              class="app-home-select-category__btn${id == selected_id
                ? ' app-home-select-category__btn--selected'
                : ''}"
              type="button"
              data-id="${id}"
            >
              ${UtilS.escape(name)}
            </button>
          </li>
        `,
        cate_lists,
      )}
    </ul>
  </div>
`;

const categoryFeed = (feed) => {
  const link = `/${T.lang}/@/todays/${feed.id}`;
  const image_url = feed.thumbnail?.includes('data:image')
    ? feed.thumbnail
    : UtilImageS.getResizedUrl({
        url: UtilS.escape(feed.thumbnail),
        width: 500,
        format: 'webp',
      });

  const post_message = {
    screen: {
      type: 'util',
      title: T('mshop::Content Detail'),
      uri: link,
    },
  };

  const title = feed[`title${G._en}`];
  const description = feed[`description${G._en}`];

  return html`
    <div class="app-home-cate-list__feed-item">
      <a href="${link}" data-post-message="${$dataStr(post_message)}">
        <div class="app-home-cate-list__feed-img lazy-src" data-background="${image_url}"></div>
        <div class="app-home-cate-list__feed-content">
          <div class="app-home-cate-list__feed-title">${UtilS.escape(title, true) || ''}</div>
          <div class="app-home-cate-list__feed-description">${UtilS.escape(description, true) || ''}</div>
        </div>
      </a>
    </div>
  `;
};

export const categoryForMo = (cate_lists) =>
  strMap(({ name, emoticon, id, _: { products, feeds } }) => {
    const uri = `/${T.lang}/@/product/list/all?cate_list_id=${id}`;
    const post_msg = {
      screen: { title: MShopAppProductSubConstantS.SUB_TYPE.all.title, uri, type: 'util' },
    };
    return html`
      <div class="app-home-cate-list__item" data-id="${id}">
        <h2 class="app-home__mid-title">${emoticon} ${name}</h2>
        <div class="app-home-cate-list__product-list">
          ${strMap(MShopAppProductItemTmplS.item(['v1', 'v1-category'], '', true), products)}
        </div>
        <div class="app-home-cate_list__feed-list">${strMap(categoryFeed, feeds)}</div>
        <a class="app-home__more-box" href="${uri}" data-post-message="${$dataStr(post_msg)}"
          >${name} ${T('mshop::더보기')}</a
        >
      </div>
    `;
  }, cate_lists);

export const categoryForPc = (cate_lists) =>
  strMap(({ name, emoticon, id, _: { products, feeds } }) => {
    const uri = `/${T.lang}/@/product/list/all?cate_list_id=${id}`;
    const post_msg = {
      screen: { title: MShopAppProductSubConstantS.SUB_TYPE.all.title, uri, type: 'util' },
    };
    const chunk_products = chunk(4, products);
    const feed_product_html = go(
      feeds,
      zipWithIndexL,
      map(([i, f]) => ({ ...f, _: { products: chunk_products[i] } })),
      strMap((f) =>
        f && f._.products?.length
          ? html`
              <div class="app-home-cate-list__feed-product">
                <div class="app-home-cate-list__feed">${categoryFeed(f)}</div>
                <div class="app-home-cate-list__product">
                  ${strMap(
                    (p) => (p ? MShopAppProductItemTmplS.item(['v1', 'v1-category'], '', true)(p) : {}),
                    f._.products,
                  )}
                </div>
              </div>
            `
          : '',
      ),
    );

    return feed_product_html
      ? html`
          <div class="app-home-cate-list__item" data-id="${id}">
            <h2 class="app-home__mid-title">${emoticon} ${name}</h2>
            <div class="app-home-cate-list__product-list">${feed_product_html}</div>
            <a class="app-home__more-box" href="${uri}" data-post-message="${$dataStr(post_msg)}"
              >${name} ${T('mshop::더보기')}</a
            >
          </div>
        `
      : '';
  }, cate_lists);
