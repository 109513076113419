import { $delegate } from 'fxdom/es';
import {
  defnProductStoryEvent,
  setProductStoryTextArea,
} from '../../../../../Creator/ProductStoryEditor/F/event.js';
import {
  snsInitFacebookEditor,
  snsInitInstagramEditor,
  snsInitTwitterEditor,
  snsInitYoutubeEditor,
} from '../../../../../Creator/ProductStoryEditor/F/fs.js';
import { initTooltips2 } from '../../../../../Util/F/tooltip.js';
import { MShopUtilF } from '../../../../Util/F/Function/module/MShopUtilF.js';
import { MShopStudioPreDiscountProductF } from '../../../PreDiscountProduct/F/Function/module/MShopStudioPreDiscountProductF.js';
import { MShopStudioPreDiscountProductS } from '../../../PreDiscountProduct/S/Function/module/MShopStudioPreDiscountProductS.js';
import { MShopStudioPbProductCreatorMuiS } from '../../S/Mui/module/MShopStudioPbProductCreatorMuiS.js';
import { go, noop } from 'fxjs/es';
import { MShopStudioPbProductCreatorF } from '../Function/module/MShopStudioPbProductCreatorF.js';
import { EventLogConstantS } from '../../../../../../services/MarppleShop/features/EventLog/outbound/share.ts';
import {
  OnboardingApiS,
  OnboardingValidationS,
} from '../../../../../../services/MarppleShop/marppleShopStudioApp/features/Onboarding/outbound/share.ts';

/*
 * 프론트에서 사용될 tab 옵션입니다.
 * https://www.notion.so/marpple/Marpple-UI-80875a30a63e436382a02e2d820f4695#2fecac9aa5f8457c92fa359c5ac71ef8
 * */
export const tab = {
  ...MShopStudioPbProductCreatorMuiS.tab,

  inner_scroll_target: '.pb-editor__right, .pb-editor__left', // modal 일때 이너 스크롤 엘리먼트 셀렉터

  makeData(tab) {}, // template 함수 인자에 들어갈 값을 리턴하는 함수,

  appending(tab_el) {}, // tab 엘리먼트가 만들어지면 울리는 함수
  appended(tab_el) {
    MShopStudioPbProductCreatorF.initLimitedSalesPeriod(tab_el);
    box.set('im_pb_stores_product', box.sel('im_pb_stores_product') || {});
    box.set('pb_thumbnail_files', box.sel('pb_thumbnail_files') || []);
    box.set('pb_thumbnail_photos', box.sel('pb_thumbnail_photos') || []);

    initTooltips2({
      container_el: tab_el,
      custom: {
        placement: 'bottom',
        offset: undefined,
        animation: 'shift-toward',
        maxWidth: MShopUtilF.isMobile() ? 500 : 340,
        interactive: true,
        trigger: 'mouseenter click',
      },
    });

    go(
      tab_el,
      MShopStudioPbProductCreatorF.thumbnailEvents,
      MShopStudioPbProductCreatorF.editorOptionEvents,
      MShopStudioPbProductCreatorF.editorEvents,
      defnProductStoryEvent,
    );

    if (MShopStudioPreDiscountProductS.isPreProductDiscountSupported()) {
      go(
        tab_el,
        MShopStudioPreDiscountProductF.initPreDiscountPriceFEvents,
        $delegate('change', '.pb-editor-form__body-input[name="profit"]', (changeEvent) => {
          MShopStudioPreDiscountProductF.calculatePreDiscountPriceAndRateForPB(tab_el, changeEvent);
        }),
      );
    }

    if (OnboardingValidationS.checkServiceCanCreateCreatorStudioOnboardingEvent()) {
      OnboardingApiS.logCreatorStudioOnboardingAction(
        { store_id: box.sel('store_id') },
        {
          action_type: EventLogConstantS.ACTION_TYPE.CREATOR_STUDIO_ONBOARDING.FIRST_PB_PRODUCT_REGISTER,
          user_id: box.sel('store_user_id'),
        },
      ).catch(noop);
    }
  }, // tab 엘리먼트가 html에 붙으면 울리는 함수
  showing(tab_el) {
    MShopUtilF.isMobile() && MShopUtilF.channelTalkOff();
  }, // tab 엘리먼트 show하기 전 울리는 함수
  showed(tab_el) {
    setProductStoryTextArea(tab_el);
  }, // tab 엘리먼트 show하고 나서 울리는 함수
  rendered() {
    snsInitYoutubeEditor();
    snsInitInstagramEditor();
    snsInitTwitterEditor();
    snsInitFacebookEditor();
  }, // tab 에 관련된 모든 메소드와 엘리먼트 작업이 끝날때 울리는 함수

  hiding(tab_el, v) {
    MShopUtilF.isMobile() && MShopUtilF.channelTalkOn();
  }, // tab이 가려지기 전 울리는 함수
  hided(tab_el, v) {}, // tab이 가려진 후 울리는 함수
  removing(tab_el, v) {}, // tab이 삭제되기 전 울리는 함수
  removed(tab_el, v) {}, // tab이 삭제된 후 울리는 함수

  infinite(tab_el) {}, // tab 엘리먼트에 무한스크롤을 사용할때 쓰는 함수, 사용시 반드시 return MuiF.tabInfinite(...)
};
