import { CustomEventWithDetail, html, View } from 'rune-ts';
import { makeSubScreenNavigate, PostMessageNavigate } from '../../../../shared/app/navigate';
import { preventEscape } from '../../../../shared/util/preventEscape';
import { Confirm } from '../Confirm/Confirm';
import klass from './ProductCard.module.scss';
import { StarIcon } from '../../atoms/Icon';
import { staticTypo, typo } from '../../../../shared/typography/typo';
import $dataStr from 'fxdom/es/dataStr.js';
import { htmlIf } from '../../../../shared/util';
import { ProductTag } from '../ProductTag/ProductTag';
import { ProductThumbnail } from '../ProductThumbnail/ProductThumbnail';
import {
  PRODUCT_CARD_THEME,
  ProductCardData,
  ProductCardOption,
  ProductCardTheme,
} from '../../../../features/ProductList/type';
import { ProductStoreName } from './view/ProductStoreName';
import { ProductLikeButton } from './view/ProductLikeButton/ProductLikeButton';
import { MShopUtilF } from '../../../../../../modules/MShop/Util/F/Function/module/MShopUtilF';
import { pushLoginStack } from '../../../../../../modules/Creator/Login/F/fs';
import { updateLike } from '../../../../../../modules/MShop/App/Product/Item/F/Function/item';
import { AdultVerificationThumbnail } from '../ProductThumbnail/AdultVerificationThumbnail';
import { product_tag } from '../../../../features/ProductList/constant';
import { differenceInMilliseconds } from 'date-fns';
import { ProductCardHelper } from './ProductCardHelper';
import { ProductBadgeList } from '../ProductBadgeList/ProductBadgeList';
import { ProductCardPrice } from '../ProductCardPrice/ProductCardPrice';

export class ProductLikeToggleEvent extends CustomEventWithDetail<{
  toggleLikeButton: () => void;
}> {}

// TODO: @kjj app 개발할 때 backForward 추가
// TODO: @kjj 폰케이스 이름 수정하는 로직 추가
export class ProductCard extends View<ProductCardData> {
  state: {
    img_alt: string;
    post_message: PostMessageNavigate;
    stores_product_id: number;
  };

  LikeButton: ProductLikeButton;

  BadgeListView?: ProductBadgeList;

  TagView?: ProductTag;

  timer: any | null = null;

  // 기간한정 상품인지
  readonly is_period_limited: boolean;

  // 수량한정 상품인지
  readonly is_quantity_limited: boolean;

  horizontal = {
    store_name: typo('14_bold'),
    product_name: typo('14_medium'),
    price: staticTypo('unica_14_medium'),
    currency: typo('14_medium'),
  } as const;

  vertical_pc_normal = {
    store_name: typo('14_bold'),
    product_name: typo('16_medium'),
    price: staticTypo('unica_20_bold'),
    currency: typo('20_bold'),
  } as const;

  vertical_pc_compact = {
    store_name: typo('14_bold'),
    product_name: typo('16_medium'),
    price: staticTypo('unica_16_bold'),
    currency: typo('16_bold'),
  } as const;

  vertical_mo_normal = {
    store_name: typo('14_bold'),
    product_name: typo('16_medium'),
    price: staticTypo('unica_16_bold'),
    currency: typo('16_bold'),
  } as const;

  vertical_mo_compact = {
    store_name: typo('12_bold'),
    product_name: typo('14_medium'),
    price: staticTypo('unica_14_bold'),
    currency: typo('14_bold'),
  } as const;

  constructor(data: ProductCardData, private option: ProductCardOption) {
    if (!ProductCard.isTypeValid(option)) {
      throw new TypeError(`Invalid type: ${option.type}`);
    }

    super(
      {
        ...data,
        sell_start_at: data.sell_start_at ? new Date(data.sell_start_at) : null,
        sell_end_at: data.sell_end_at ? new Date(data.sell_end_at) : null,
      },
      option,
    );

    this.LikeButton = new ProductLikeButton(this.data, {
      theme: option.theme ?? PRODUCT_CARD_THEME.light,
      klass: `${klass.like_btn} ${this.getLikeButtonPositionKlass()}`,
    });

    this.is_period_limited = Boolean(this.data.sell_start_at && this.data.sell_end_at);
    this.is_quantity_limited = Boolean(this.data.quantity > 0);

    this.setBadges();
    this.setTag();

    this.state = {
      img_alt: this.makeImgAlt(),
      post_message: this.makePostMessage(),
      stores_product_id: this.data.stores_product_id,
    };
  }

  static isTypeValid(option: { is_horizontal?: boolean; type: string }): boolean {
    const Type = ProductCard.Type;
    if (option.is_horizontal) {
      return option.type === Type.normal || option.type == Type.compact;
    }
    return option.type === Type.normal || option.type == Type.compact;
  }

  static Type = {
    normal: 'normal',
    compact: 'compact',
  } as const;

  private getThemeKlass(): string {
    const theme: Record<ProductCardTheme, string> = {
      light: klass.theme_light,
      dark: klass.theme_dark,
      color: klass.theme_color,
    };
    return theme[this.option.theme ?? PRODUCT_CARD_THEME.light];
  }

  override template() {
    const theme_klass = this.getThemeKlass();

    return html`<div
      data-type="${this.option.type}"
      data-is_horizontal="${this.option.is_horizontal ?? false}"
      class="${klass.product_card} ${this.option.klass ?? ''} ${htmlIf(
        klass.sold_out,
        this.isSoldOut() && this.isSoldOutStyleVisible(),
      )} ${theme_klass}"
    >
      <a
        class="${klass.thumbnail_container} ${htmlIf(klass.adult, !!this.data.adult_check_needed)}"
        href="${this.makeLink()}"
        data-post-message="${$dataStr(this.state.post_message)}"
      >
        ${this.BadgeListView ?? ''}${this.thumbnail}

        <!-- hide_info: true => 좋아요 버튼 우측 상단에 위치 -->
        ${this.option.hide_info ? this.LikeButton : ''}
      </a>

      ${this.option.hide_info
        ? ''
        : html`
            <div class="${klass.info}">
              <!-- hide_info: false => 좋아요 버튼 info에 위치 -->
              ${this.LikeButton}
              <div class="${klass.store_name_container}">
                ${new ProductStoreName(this.data, { klass: this.fontSize.store_name })}
              </div>

              ${new ProductCardName(this.data, {
                klass: this.fontSize.product_name,
              })}
              ${new ProductCardPrice(
                { price: this.data.price },
                { price_klass: this.fontSize.price, currency_klass: this.fontSize.currency },
              )}
              <div class="${klass.review_and_tag_container}">
                <a
                  href="${this.makeLink()}"
                  data-post-message="${$dataStr(this.state.post_message)}"
                  class="${klass.review} ${theme_klass}"
                >
                  ${htmlIf(
                    html`
                      <span class="${klass.star_icon}">${StarIcon}</span>
                      <span class="${staticTypo('unica_14_regular')}"
                        >${this.data.review_score.toFixed(1)}</span
                      >
                      <span class="${staticTypo('unica_14_regular')}">(${this.data.review_count})</span>
                    `,
                    this.isReviewVisible(),
                  )}
                </a>
                ${this.isTagVisible() ? html`<div class="${klass.tag_container}">${this.TagView}</div>` : ''}
              </div>
            </div>
          `}
    </div>`;
  }

  private makeLink() {
    return `/${this.data.domain_name}/products/${this.data.id}`;
  }

  private makeImgAlt(): string {
    const { name, store_name, cate_item_name } = this.data;
    const kr_suffix = T.lang === 'kr' ? ' 굿즈, 굿즈 판매, 굿즈샵' : '';
    return `${store_name} ${cate_item_name}, ${name}${kr_suffix}`;
  }

  override onRender() {
    if (this.isUpcoming()) {
      const diff_ms = differenceInMilliseconds(this.data.sell_start_at, new Date());
      setTimeout(() => {
        this.setBadges();
        this.setTag();
        this.redraw();
      }, diff_ms + 1000);
    } else if (this.isPeriodLimited() && !this.isSoldOut()) {
      const diff_ms = differenceInMilliseconds(this.data.sell_end_at, new Date());
      setTimeout(() => {
        this.setBadges();
        this.setTag();
        this.redraw();
      }, diff_ms + 1000);
    }

    this.delegate('click', `.${klass.like_btn}`, (e) => {
      e.originalEvent.preventDefault();
      this.dispatchEvent(ProductLikeToggleEvent, {
        bubbles: true,
        detail: {
          toggleLikeButton: this.toggleLikeButton.bind(this),
        },
      });
    });
  }

  async toggleLikeButton() {
    // TODO: window.box 로직 제거
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!this.data.user_id || window.box.sel('is_user->type') == 'TEMP') {
      const confirmed = (await Confirm.open({
        title: ET('mps2::login::need_login::title'),
        message: preventEscape(ET('mps2::login::need_login::message')),
        confirmText: ET('mps2::login::login'),
        cancelText: ET('mps2::signup::close'),
      })) as boolean;

      if (confirmed) {
        if (MShopUtilF.isApp()) {
          pushLoginStack();
        } else {
          location.href = `/${T.lang}/@/login?url=${location.pathname + location.search}`;
        }
      }
    } else {
      this.data.is_liked = !this.data.is_liked;
      this.LikeButton.setActivation(this.data.is_liked);
      this.LikeButton.redraw();
      updateLike(!this.data.is_liked, this.data.stores_product_id);
    }
  }

  private get thumbnail() {
    // 1. 성인인증 필요 없는 상품
    if (!this.data.adult_check_needed && this.data.thumbnails) {
      return new ProductThumbnail(this.data, { ...this.option, img_alt: this.state.img_alt });
    }

    // 2. 성인인증 필요한 상품인데, 성인인증 안 한 경우
    if (!this.data.thumbnails) {
      return new AdultVerificationThumbnail();
    }

    // 3. 성인인증 필요한 상품인데, 성인인증 한 경우
    return new ProductThumbnail(this.data, { ...this.option, img_alt: this.state.img_alt });
  }

  private get fontSize(): {
    store_name: string;
    product_name: string;
    price: string;
    currency: string;
  } {
    const { is_horizontal, type } = this.option;
    if (is_horizontal) {
      // 지금은 pc, mo 공통
      return this.horizontal;
    }

    // pc vertical
    if (!this.option.is_mobile) {
      if (type === 'normal') return this.vertical_pc_normal;
      if (type === 'compact') return this.vertical_pc_compact;

      // fallback
      return this.vertical_pc_normal;
    }

    // mobile vertical
    if (this.option.is_mobile) {
      if (type === 'normal') return this.vertical_mo_normal;
      if (type === 'compact') return this.vertical_mo_compact;

      // fallback
      return this.vertical_mo_normal;
    }
    throw new TypeError();
  }

  private setBadges(): void {
    if (!this.option.hide_badges) {
      const { is_mobile } = this.option;
      this.BadgeListView = new ProductBadgeList(this.data, {
        is_mobile,
        max_length: this.data.ranking ? 3 : 2, // 랭킹은 최대 3개까지 노출
        klass: klass.badge_container,
      });
    }
  }

  private setTag(): void {
    if (this.option.hide_tag || !this.data.is_public) {
      return;
    }

    const option = {
      is_mobile: this.option.is_mobile,
    };

    if (this.isSoldOut()) {
      this.TagView = new ProductTag({ type: product_tag.sold_out }, option);
      return;
    }

    if (this.isUpcoming()) {
      this.TagView = new ProductTag(
        { type: product_tag.upcoming, value: new Date(this.data.sell_start_at) },
        option,
      );
      return;
    }

    const quantity_visible = ProductTag.isQuantityLimitedTagVisible(this.data);
    const period_visible = ProductTag.isPeriodLimitedTagVisible(this.data);

    if (quantity_visible) {
      this.TagView = new ProductTag(
        { type: product_tag.quantity_limited, value: this.data.quantity - this.data.order_count },
        option,
      );
      return;
    } else if (this.isPeriodLimited() && period_visible) {
      this.TagView = new ProductTag(
        { type: product_tag.period_limited, value: this.data.sell_end_at },
        option,
      );
      return;
    }

    this.TagView = undefined;
  }

  private isUpcoming(): this is ProductCard & {
    data: { sell_start_at: string; sell_end_at: string };
  } {
    return ProductCardHelper.isUpcoming(this.data);
  }

  /**
   * 품절인지 아닌지 판별하는 함수
   */
  private isSoldOut(): boolean {
    const { out_of_stock, quantity, order_count } = this.data;

    const now = new Date();
    return (
      out_of_stock || // 수량관리 다 팔림
      (quantity > 0 && order_count >= quantity) || // 한정수량 다 팔림
      (this.isPeriodLimited() && this.data.sell_end_at < now) /* 기간한정 종료됨 */
    );
  }

  /**
   * 품절일 때 스타일을 적용할지 말지 컨트롤하는 함수
   * `this.isSoldOut`에서는 품절인지 아닌지 판단하는 로직만 넣기로 한다.
   */
  private isSoldOutStyleVisible(): boolean {
    return !this.option.hide_badges;
  }

  private isPeriodLimited(): this is ProductCard & {
    data: { sell_start_at: string; sell_end_at: string };
  } {
    return this.is_period_limited;
  }

  override redraw() {
    this.option.is_lazy = false;
    return super.redraw();
  }

  private getLikeButtonPositionKlass(): string {
    const { type, is_mobile, is_horizontal, hide_info } = this.option;

    if (is_mobile) {
      if (is_horizontal) {
        // mobile horizontal
        if (type === 'normal') return klass.hidden;
        return klass.right;
      } else {
        // mobile vertical
        if (type === 'compact') return klass.hidden;
        if (hide_info) return klass.right_top_with_padding;
        return klass.right_top;
      }
    } else {
      // pc horizontal
      if (is_horizontal) {
        return klass.right;
      } else {
        // pc vertical
        if (hide_info) return klass.right_top_with_padding;
        return klass.right_top;
      }
    }
  }

  private getProductDetailUrl() {
    return `/${this.data.domain_name}/products/${this.data.id}`;
  }

  private makePostMessage(): PostMessageNavigate {
    return makeSubScreenNavigate(this.getProductDetailUrl());
  }

  private isReviewVisible() {
    const { review_count } = this.data;
    return review_count > 0;
  }

  private isTagVisible() {
    return !this.option.hide_tag && this.TagView;
  }
}

type ProductCardNameData = Pick<ProductCardData, 'domain_name' | 'id' | 'name'>;

export class ProductCardName extends View<ProductCardNameData> {
  private readonly post_message: PostMessageNavigate;

  constructor(
    data: ProductCardNameData,
    private option: {
      klass?: string;
    } = {},
  ) {
    super(data, option);

    this.post_message = this.makePostMessage();
  }

  // FIXME: @kjj 폰케이스 이름 수정하는 로직 추가 -->
  override template() {
    const { name } = this.data;

    return html`
      <a
        href="${this.getProductDetailUrl()}"
        data-post-message="${$dataStr(this.post_message)}"
        class="${this.option.klass} ${klass.product_name}"
      >
        ${html.preventEscape(name)}
      </a>
    `;
  }

  private getProductDetailUrl() {
    return `/${this.data.domain_name}/products/${this.data.id}`;
  }

  private makePostMessage(): PostMessageNavigate {
    return makeSubScreenNavigate(this.getProductDetailUrl());
  }
}
