import { UtilS } from '../../../Util/S/Function/module/UtilS.js';
import { MINIMUM_PROFIT, MINIMUM_PROFIT_UNDER_5000, STORE_PRODUCT_CUSTOM_LEVEL } from './constant.js';

export const formatFullTime = (t) => (`${t}`.length == 1 ? `0${t}` : t);

export const makeMinProfit = ({ price = 5000, is_digital_product }) => {
  if (UtilS.isNboxApp()) {
    return 0;
  }
  if (UtilS.isNessApp()) {
    return 100;
  }
  return is_digital_product || price >= 5000 ? MINIMUM_PROFIT : MINIMUM_PROFIT_UNDER_5000;
};

export const isStoreProductCustom = (custom_level) => {
  if (custom_level == null) return false;
  if (typeof custom_level !== 'string') return false;

  return [STORE_PRODUCT_CUSTOM_LEVEL.ASSET, STORE_PRODUCT_CUSTOM_LEVEL.USER].includes(
    custom_level.toUpperCase(),
  );
};

export const isStoreProductCustomLevelAsset = (custom_level) => {
  if (custom_level == null) return false;
  if (typeof custom_level !== 'string') return false;

  return custom_level.toUpperCase() === STORE_PRODUCT_CUSTOM_LEVEL.ASSET;
};

export const isStoreProductCustomLevelUser = (custom_level) => {
  if (custom_level == null) return false;
  if (typeof custom_level !== 'string') return false;

  return custom_level.toUpperCase() === STORE_PRODUCT_CUSTOM_LEVEL.USER;
};
