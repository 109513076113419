import { each, every, find, go, map, sel, some, sumBy } from 'fxjs/es';
import { CateListConstantS } from '../../../../../CateList/S/Constant/module/CateListConstantS.js';
import { GoodsTypeS } from '../../../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { OrderShippingOrderS } from '../../../../../Order/ShippingOrder/S/Function/module/OrderShippingOrderS.js';
import { PriceS } from '../../../../../Price/S/Function/module/PriceS.js';
import { MShopAppUpItemConstantS } from '../Constant/module/MShopAppUpItemConstantS.js';
import { makeMainName } from './makeMainName.js';
import { makeMpUpSecondName } from './makeMpSecondName.js';
import { makeOptionInfoStatus } from './makeOptionInfoStatus.js';
import { makePbUpSecondName } from './makePbUpSecondName.js';
import { pMakeOptionInfo } from './pMakeOptionInfo.js';
import { pMakeQuantity } from './pMakeQuantity.js';
import { isStoreProductCustomLevelAsset } from '../../../../../Creator/Product/S/fs.js';

export const pSetUpItemListStyleData = (store_orders, is_cart_or_checkout, _en = G._en) => {
  return go(
    store_orders,
    each((store_order) => {
      store_order.n_redirect_url = `/${T.lang}/${store_order._.store.domain_name}`;
      go(
        store_order._.shipping_orders,
        each((shipping_order) => {
          go(
            shipping_order._.item_order_up_cs,
            each((item_order_up_c) => {
              const store_product = item_order_up_c._.product_color._.stores_product;
              const redirect_to_product_detail = `/${T.lang}/${store_order._.store.domain_name}/products/${
                store_product.original_product_id
              }?bpc_id=${item_order_up_c.base_product_color_id}&bp_id=${
                item_order_up_c.base_product_id
              }&find_plus=1${
                isStoreProductCustomLevelAsset(store_product.custom_level)
                  ? `&pc_id=${store_product.product_id}`
                  : ``
              }`;
              item_order_up_c.n_thumb = {
                product_face: sel('_.product_color.product_faces2.value.0', item_order_up_c),
                thumbnail_url: go(
                  sel('_.product_color.thumbnails.value', item_order_up_c),
                  find(sel('is_thumb')),
                  sel('url'),
                ),
                redirect_to_product_detail,
              };
              const goods_type_id = item_order_up_c.goods_type_id;
              const product_color = item_order_up_c._.product_color;
              const base_product = product_color._.base_product;
              const base_product_color = product_color._.base_product_color;
              const { cate_list, cate_item } = base_product._;
              const osp =
                is_cart_or_checkout &&
                item_order_up_c._.product_color._.stores_product._.original_stores_product;
              const remaining_limited_quantity =
                is_cart_or_checkout && osp.quantity > 0 && osp.quantity - osp.order_count;
              item_order_up_c.n_text_option_infos = go(
                item_order_up_c._.spo_text_option_values,
                map(({ value, _: { spo_text_option } }) => ({
                  name: spo_text_option['name' + G._en] || spo_text_option['name' + _en],
                  is_deleted: !!spo_text_option.deleted_at,
                  value,
                })),
              );
              item_order_up_c.n_option_infos = go(
                item_order_up_c._.up_c_ss,
                map((up_c_s) => {
                  const quantity = pMakeQuantity(item_order_up_c.order_process_step_id, up_c_s.quantity);
                  const price = up_c_s['discounted_price' + _en];
                  return {
                    up_c_s_id: up_c_s.id,
                    name: GoodsTypeS.isMps(goods_type_id)
                      ? up_c_s._name
                      : pMakeOptionInfo(up_c_s._.spo_item.group_names_option_names),
                    quantity,
                    price,
                    original_price: up_c_s['price' + _en],
                    total_price: PriceS.pricify_by(price * quantity, _en),
                    status: is_cart_or_checkout
                      ? makeOptionInfoStatus({
                          goods_type_id,
                          _is_not_stock: up_c_s._is_not_stock,
                          no_spo_item: !!up_c_s._?.spo_item?.deleted_at,
                          spo_item_stock_quantity: up_c_s._?.spo_item?._?.spo_item_stock?.quantity,
                          quantity: up_c_s.quantity,
                          remaining_limited_quantity,
                          text_option_changed: go(
                            item_order_up_c.n_text_option_infos,
                            some(sel('is_deleted')),
                          ),
                          discontinued: up_c_s._is_discontinued,
                        })
                      : MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL,
                  };
                }),
              );

              item_order_up_c.n_other_info = {
                is_need_option_btn:
                  /* TODO 카테고리 하드코딩 */
                  cate_list.id !== CateListConstantS.DIGITAL_LIST_ID &&
                  go(
                    item_order_up_c.n_option_infos,
                    every(({ status }) =>
                      [
                        MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NORMAL,
                        MShopAppUpItemConstantS.N_OPTION_INFO_STATUS.NOT_ENOUGH_STOCK,
                      ].includes(status),
                    ),
                  ),
              };
              const main_name = makeMainName(item_order_up_c._.product_color);
              const second_name = GoodsTypeS.isMps(goods_type_id)
                ? makeMpUpSecondName({
                    product_color,
                    base_product,
                    base_product_color,
                    is_color_show: base_product._?.base_product_colors?.length > 1,
                    selected_option_group: product_color._.selected_option_group,
                  })
                : makePbUpSecondName({ cate_item });
              const hyundai_n_product_second_name = GoodsTypeS.isMps(goods_type_id)
                ? product_color.product_faces2?.hyundai_n_products?.option_to_string || ''
                : '';
              item_order_up_c.n_info = {
                up_c_id: item_order_up_c.up_c_id,
                name: main_name || second_name,
                second_name: second_name + hyundai_n_product_second_name,
                price: go(
                  item_order_up_c.n_option_infos,
                  sumBy(({ quantity, price }) => quantity * price),
                ),
                original_price: go(
                  item_order_up_c.n_option_infos,
                  sumBy(({ quantity, price }) => quantity * price),
                ),
              };
            }),
          );
          const products_price = go(
            shipping_order._.item_order_up_cs,
            sumBy((item_order_up_c) => item_order_up_c.n_info.price),
          );
          /* TODO 정익님 products_price 랑 같습니다 */
          const original_products_price = go(
            shipping_order._.item_order_up_cs,
            sumBy((item_order_up_c) => item_order_up_c.n_info.price),
          );
          const shipping_price = OrderShippingOrderS.pGetShippingPrice(shipping_order);
          const additional_shipping_price = OrderShippingOrderS.pGetShippingAdditionalPrice(shipping_order);
          shipping_order.n_price_info = {
            products_price,
            original_products_price,
            shipping_price: PriceS.pricify_by(OrderShippingOrderS.pGetShippingPrice(shipping_order), _en),
            additional_shipping_price: PriceS.pricify_by(
              OrderShippingOrderS.pGetShippingAdditionalPrice(shipping_order),
              _en,
            ),
            total_shipping_price: PriceS.pricify_by(shipping_price + additional_shipping_price, _en),
          };
        }),
      );
    }),
  );
};
