import { keys, curry, curry2, entries, go, map, pick, values, difference } from 'fxjs/es';
import { produce } from 'immer';

export const rename = curry2((prev_key, key, object) =>
  produce(object, (obj) => {
    if (!obj.hasOwnProperty(prev_key)) return obj;
    obj[key] = obj[prev_key];
    delete obj[prev_key];
  }),
);

export const renameMultiple = curry((rename_object, target_object) =>
  produce(target_object, (draft) => {
    const prev_keys = keys(rename_object);
    const new_keys = values(rename_object);
    const origin_part = pick(prev_keys, draft);

    go(
      rename_object,
      entries,
      map(([prev_key, new_key]) => {
        if (!draft.hasOwnProperty(prev_key)) return;
        draft[new_key] = origin_part[prev_key];
      }),
    );

    difference(new_keys, prev_keys).forEach((removed_key) => {
      delete draft[removed_key];
    });
  }),
);
