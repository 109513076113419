import { html } from 'rune-ts';

export const CloseIcon = html`
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_132_1042)">
      <path
        d="M13.2583 12.0001L20.7379 4.51006C21.0874 4.16006 21.0874 3.60006 20.7379 3.26006C20.3884 2.91006 19.8291 2.91006 19.4796 3.26006L12 10.7401L4.52039 3.26006C4.17087 2.91006 3.61165 2.91006 3.26214 3.26006C2.91262 3.61006 2.91262 4.17006 3.26214 4.51006L10.7417 12.0001L3.26214 19.4901C2.91262 19.8401 2.91262 20.4001 3.26214 20.7401C3.4319 20.9101 3.66158 21.0001 3.89126 21.0001C4.12094 21.0001 4.34064 20.9101 4.52039 20.7401L12 13.2601L19.4796 20.7401C19.6494 20.9101 19.8791 21.0001 20.1087 21.0001C20.3384 21.0001 20.5581 20.9101 20.7379 20.7401C21.0874 20.3901 21.0874 19.8301 20.7379 19.4901L13.2583 12.0001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_132_1042">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

export const ImportantIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_6030_18975)">
      <path
        d="M9.99935 1.6665C5.39935 1.6665 1.66602 5.39984 1.66602 9.99984C1.66602 14.5998 5.39935 18.3332 9.99935 18.3332C14.5993 18.3332 18.3327 14.5998 18.3327 9.99984C18.3327 5.39984 14.5993 1.6665 9.99935 1.6665ZM11.041 14.0665C11.041 14.6415 10.5743 15.1082 9.99935 15.1082C9.42435 15.1082 8.95768 14.6415 8.95768 14.0665V9.6915C8.95768 9.1165 9.42435 8.64984 9.99935 8.64984C10.5743 8.64984 11.041 9.1165 11.041 9.6915V14.0665ZM9.99935 6.98317C9.42435 6.98317 8.95768 6.5165 8.95768 5.9415C8.95768 5.3665 9.42435 4.89984 9.99935 4.89984C10.5743 4.89984 11.041 5.3665 11.041 5.9415C11.041 6.5165 10.5743 6.98317 9.99935 6.98317Z"
        fill="#6B6B6B"
      />
    </g>
    <defs>
      <clipPath id="clip0_6030_18975">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

export const MarkIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
    <circle cx="2" cy="2" r="2" fill="#1C75FF" />
  </svg>
`;

export const ListMarkIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none">
    <circle cx="1" cy="1" r="1" fill="#6B6B6B" />
  </svg>
`;

export const FormRowAsteriskIcon = html` <svg
  xmlns="http://www.w3.org/2000/svg"
  width="4"
  height="4"
  viewBox="0 0 4 4"
  fill="none"
>
  <circle cx="2" cy="2" r="2" fill="#1C75FF" />
</svg>`;

export const DropDownIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M2 4L6 8L10 4" stroke="#141414" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
`;

export const StudioDropDownIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.4301 11.6671C7.74672 12.1424 8.44506 12.1424 8.76168 11.6671L13.041 5.24337C13.3952 4.71171 13.014 3.99984 12.3752 3.99984H3.81659C3.17776 3.99984 2.79662 4.71171 3.1508 5.24337L7.4301 11.6671Z"
      fill="#141414"
    />
  </svg>
`;

export const CheckIcon = html`<svg
  width="100%"
  height="100%"
  viewBox="0 0 15 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x="3.08716"
    y="7.07153"
    width="1.5"
    height="4"
    transform="rotate(-45 3.08716 7.07153)"
    fill="currentColor"
  />
  <rect
    x="5.91553"
    y="9.8999"
    width="8"
    height="1.5"
    transform="rotate(-45 5.91553 9.8999)"
    fill="currentColor"
  />
</svg>`;
