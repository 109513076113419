import { getMonth, getWeekOfMonth } from 'date-fns';
import $dataStr from 'fxdom/es/dataStr.js';
import { chunk, compact, drop, go, html, map, strMap, take, zipWithIndexL } from 'fxjs/es';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { MShopAppLiveTmplS } from '../../../Live/S/Tmpl/module/MShopAppLiveTmplS.js';
import { MShopAppHomeTmplS } from './module/MShopAppHomeTmplS.js';

const top3Html = (seller, rank, tmpl) => {
  if (!seller) return '';
  const post_msg = {
    screen: {
      type: 'util',
      title: seller.name.trim() || 'No name',
      titleImage: seller.logo_img,
      uri: `/${T.lang}/${UtilS.escape(seller.domain_name)}`,
    },
  };
  return html`
    <a
      class="top-seller-item top-seller-item--${rank}"
      href="${post_msg.screen.uri}"
      data-post-message="${$dataStr(post_msg)}"
    >
      <div class="top-seller-item__wrapper">
        <div
          class="top-seller-item__img top-seller-item__img--${rank} lazy-src"
          data-background="${UtilImageS.getResizedUrl({
            url:
              seller.thumbnail_img ||
              '//s3.marpple.co/files/u_218933/2021/5/original/8404c29322a6e28947da63fb886b3422e05bb0181.png',
            quality: 70,
            width: 272,
            format: 'jpg',
          })}"
        ></div>
        ${tmpl}
      </div>
      <div class="top-seller-item__name top-seller-item__name--${rank}">${UtilS.escape(seller.name)}</div>
    </a>
  `;
};

export const topSeller = (title, top_sellers, is_mobile) => {
  const msg_data = { tabNavigate: 'LiveStack' };
  const live_url = `/${T.lang}/@/live`;
  const this_week = top_sellers.weekly[1] ? top_sellers.weekly[1] : top_sellers.weekly[0];
  const ts_date = new Date(this_week.start_at);
  const [top1, top2, top3] = go(
    this_week.rank,
    take(3),
    map((id) => top_sellers.sellers[id]),
  );
  return html` <div class="app-home-top-seller">
    ${MShopAppHomeTmplS.homeTitle({
      title: `${T(`mshop::몇월 몇째주`, {
        month: getMonth(ts_date) + 1,
        weeks: T(`mshop::week${getWeekOfMonth(ts_date)}`),
      })}
      ${T('mshop::live::제일 인기있는 셀러')}`,
      sub_title: T('mshop::Live'),
      link: msg_data,
    })}
    <div class="app-home-top-seller__list">
      <div class="app-home-top-seller__top3">
        ${is_mobile
          ? html`${top3Html(top2, 2)}${top3Html(
              top1,
              1,
              html`<div class="top-seller-item__bg top-seller-item__bg1"></div>
                <div class="top-seller-item__bg top-seller-item__bg2"></div>
                <div class="top-seller-item__bg top-seller-item__bg3"></div>
                <div class="top-seller-item__bg top-seller-item__bg4"></div>
                <div class="top-seller-item__bg top-seller-item__bg5"></div>
                <div class="top-seller-item__bg top-seller-item__bg6"></div>`,
            )}${top3Html(top3, 3)}`
          : ''}
      </div>
      <div class="app-live__ts app-home-top-seller__another">
        ${is_mobile
          ? html`<div style="display: none;"></div>` +
            go(
              this_week.rank,
              take(9),
              drop(3),
              zipWithIndexL,
              chunk(3),
              strMap(
                (chunk_seller) =>
                  html`<div class="app-home-top-seller__item-group">
                    ${strMap(([index, seller_id]) => {
                      const seller = top_sellers.sellers[seller_id];
                      const current_rank = index + 4;
                      const last_week = top_sellers.weekly[0] || { rank: [] };
                      const last_rank = last_week.rank.findIndex((id) => id == seller_id) + 1;
                      return MShopAppLiveTmplS.popular_seller({ seller, current_rank, last_rank });
                    }, chunk_seller)}
                  </div>`,
              ),
            )
          : go(
              this_week.rank,
              take(10),
              zipWithIndexL,
              map(([index, seller_id]) => {
                const current_rank = index + 1;
                const last_week = top_sellers.weekly[0] || { rank: [] };
                return {
                  seller: top_sellers.sellers[seller_id],
                  index,
                  current_rank,
                  last_rank: last_week.rank.findIndex((id) => id == seller_id) + 1,
                  open_div: current_rank === 1 || current_rank === 2 || current_rank === 11,
                  close_div:
                    current_rank === 1 || current_rank === 10 || current_rank === this_week.rank.length,
                };
              }),
              // ([first, ...another]) => [first, ...flat(unzip(chunk(3, another)))],
              compact,
              strMap(
                ({ seller, index, current_rank, last_rank, open_div, close_div }) =>
                  html` ${open_div ? `<div class="index-${index}">` : ''}
                  ${MShopAppLiveTmplS.popular_seller({ seller, current_rank, last_rank })}
                  ${close_div ? '</div>' : ''}`,
              ),
            )}
      </div>
    </div>
    ${is_mobile
      ? html`<a class="app-home__more-box" href="${live_url}" data-post-message="${$dataStr(msg_data)}"
          >${T('mshop::더보기')}</a
        >`
      : ''}
  </div>`;
};
